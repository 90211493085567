import { Component, Inject, OnInit } from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployerService } from 'src/app/core/services/modules/employer.service';
import { environment } from 'src/environments/environment';
import { DialogTimeWorkProjectComponent } from '../dialog-time-work-project/dialog-time-work-project.component';
import {FechasService} from '../../../services/utils/fechas.service';
import {ProjectService} from '../../../services/modules/project.service';
import {WorkShiftConfiguration} from '../../../interfaces/interfaces';

export interface DialogData {
  title: string;
  CompanyInfoId: string;
  ProjectId: string;
  wShift: WorkShiftConfiguration;
}

@Component({
  selector: 'app-dialog-work-shifts',
  templateUrl: './dialog-work-shifts.component.html',
  styleUrls: ['./dialog-work-shifts.component.scss']
})
export class DialogWorkShiftsComponent implements OnInit {
  s3Bucket:string;
  public hrs = [];
  public mins = ['00', '30'];
  public selectedQuantity: any;

  diasSemana: string[] = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];

  horario: any[] = [
    {Name: 'Domingo', start: 'DomingoEntry', finisht: 'DomingoLeave', breakTime: 'DomingoBreak', extraTimeBreakL: 'DomingoExtraTimeDiscountL', extraTimeBreakR: 'DomingoExtraTimeDiscountR', extraTimeTrdL: 'DomingoTrdL', extraTimeTrdR: 'DomingoTrdR', NameEnd: 'DomingoEnd'},
    {Name: 'Lunes', start: 'LunesEntry', finisht: 'LunesLeave', breakTime: 'LunesBreak', extraTimeBreakL: 'LunesExtraTimeDiscountL', extraTimeBreakR: 'LunesExtraTimeDiscountR', extraTimeTrdL: 'LunesTrdL', extraTimeTrdR: 'LunesTrdR', NameEnd: 'LunesEnd'},
    {Name: 'Martes', start: 'MartesEntry', finisht: 'MartesLeave', breakTime: 'MartesBreak', extraTimeBreakL: 'MartesExtraTimeDiscountL', extraTimeBreakR: 'MartesExtraTimeDiscountR' , extraTimeTrdL: 'MartesTrdL', extraTimeTrdR: 'MartesTrdR', NameEnd: 'MartesEnd'},
    {Name: 'Miercoles', start: 'MiercolesEntry', finisht: 'MiercolesLeave', breakTime: 'MiercolesBreak', extraTimeBreakL: 'MiercolesExtraTimeDiscountL', extraTimeBreakR: 'MiercolesExtraTimeDiscountR' , extraTimeTrdL: 'MiercolesTrdL', extraTimeTrdR: 'MiercolesTrdR', NameEnd: 'MiercolesEnd'},
    {Name: 'Jueves', start: 'JuevesEntry', finisht: 'JuevesLeave', breakTime: 'JuevesBreak', extraTimeBreakL: 'JuevesExtraTimeDiscountL', extraTimeBreakR: 'JuevesExtraTimeDiscountR', extraTimeTrdL:  'JuevesTrdL', extraTimeTrdR: 'JuevesTrdR', NameEnd: 'JuevesEnd'},
    {Name: 'Viernes', start: 'ViernesEntry', finisht: 'ViernesLeave', breakTime: 'ViernesBreak', extraTimeBreakL: 'ViernesExtraTimeDiscountL', extraTimeBreakR: 'ViernesExtraTimeDiscountR', extraTimeTrdL: 'ViernesTrdL', extraTimeTrdR: 'ViernesTrdR', NameEnd: 'ViernesEnd'},
    {Name: 'Sabado', start: 'SabadoEntry', finisht: 'SabadoLeave', breakTime: 'SabadoBreak', extraTimeBreakL: 'SabadoExtraTimeDiscountL', extraTimeBreakR: 'SabadoExtraTimeDiscountR', extraTimeTrdL: 'SabadoTrdL', extraTimeTrdR: 'SabadoTrdR', NameEnd: 'SabadoEnd'}
  ];

  form: FormGroup;
  imgCropperResult: string;

  time = [];

  constructor(public dialogRef: MatDialogRef<DialogTimeWorkProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private projectService: ProjectService,
              public fechasService: FechasService,
    private fb: FormBuilder) {
      this.form = this.fb.group({
        ViernesEntry: new FormControl(null),
        ViernesLeave: new FormControl(null),
        LunesEntry: new FormControl(null),
        LunesLeave: new FormControl(null),
        SabadoEntry: new FormControl(null),
        SabadoLeave: new FormControl(null),
        DomingoEntry: new FormControl(null),
        DomingoLeave: new FormControl(null),
        JuevesEntry: new FormControl(null),
        JuevesLeave: new FormControl(null),
        MartesEntry: new FormControl(null),
        MartesLeave: new FormControl(null),
        MiercolesEntry: new FormControl(null),
        MiercolesLeave: new FormControl(null),
        DomingoBreak: new FormControl(null),
        LunesBreak: new FormControl(null),
        MartesBreak: new FormControl(null),
        MiercolesBreak: new FormControl(null),
        JuevesBreak: new FormControl(null),
        ViernesBreak: new FormControl(null),
        SabadoBreak: new FormControl(null),
        Name: new FormControl(null),
        isActive: new FormControl(null),
        StartDate: new FormControl(null),
        EndDate: new FormControl(null),
        Schedule: new FormControl(null),
        ExtraTimeDiscountL: new FormControl('00', Validators.required),
        ExtraTimeDiscountR: new FormControl('00', Validators.required),
        SlackTimeL: new FormControl('00', Validators.required),
        SlackTimeR: new FormControl('00', Validators.required),
        ExtraTimeL: new FormControl('00', Validators.required),
        ExtraTimeR: new FormControl('00', Validators.required),
        DomingoExtraTimeDiscountL: new FormControl('00', Validators.required),
        DomingoExtraTimeDiscountR: new FormControl('00', Validators.required),
        LunesExtraTimeDiscountL: new FormControl('00', Validators.required),
        LunesExtraTimeDiscountR: new FormControl('00', Validators.required),
        MartesExtraTimeDiscountL: new FormControl('00', Validators.required),
        MartesExtraTimeDiscountR: new FormControl('00', Validators.required),
        MiercolesExtraTimeDiscountL: new FormControl('00', Validators.required),
        MiercolesExtraTimeDiscountR: new FormControl('00', Validators.required),
        JuevesExtraTimeDiscountL: new FormControl('00', Validators.required),
        JuevesExtraTimeDiscountR: new FormControl('00', Validators.required),
        ViernesExtraTimeDiscountL: new FormControl('00', Validators.required),
        ViernesExtraTimeDiscountR: new FormControl('00', Validators.required),
        SabadoExtraTimeDiscountL: new FormControl('00', Validators.required),
        SabadoExtraTimeDiscountR: new FormControl('00', Validators.required),
        DomingoTrdL: new FormControl('00', Validators.required),
        DomingoTrdR: new FormControl('00', Validators.required),
        LunesTrdL: new FormControl('00', Validators.required),
        LunesTrdR: new FormControl('00', Validators.required),
        MartesTrdL: new FormControl('00', Validators.required),
        MartesTrdR: new FormControl('00', Validators.required),
        MiercolesTrdL: new FormControl('00', Validators.required),
        MiercolesTrdR: new FormControl('00', Validators.required),
        JuevesTrdL: new FormControl('00', Validators.required),
        JuevesTrdR: new FormControl('00', Validators.required),
        ViernesTrdL: new FormControl('00', Validators.required),
        ViernesTrdR: new FormControl('00', Validators.required),
        SabadoTrdL: new FormControl('00', Validators.required),
        SabadoTrdR: new FormControl('00', Validators.required),
        Domingo: new FormControl(''),
        Lunes: new FormControl(''),
        Martes: new FormControl(''),
        Miercoles: new FormControl(''),
        Jueves: new FormControl(''),
        Viernes: new FormControl(''),
        Sabado: new FormControl(''),
        DomingoEnd: new FormControl('Domingo',Validators.required),
        LunesEnd: new FormControl('Lunes',Validators.required),
        MartesEnd: new FormControl('Martes',Validators.required),
        MiercolesEnd: new FormControl('Miercoles',Validators.required),
        JuevesEnd: new FormControl('Jueves',Validators.required),
        ViernesEnd: new FormControl('Viernes',Validators.required),
        SabadoEnd: new FormControl('Sabado',Validators.required),
    });
      for (let i = 0; i < 60; i++) {
        this.time.push(i < 10 ? '0'+i : i);
      }
    }


  ngOnInit(): void {
    this.s3Bucket=environment.api.s3Bucket;
    this.hrsData();
    if (this.data.wShift != null){
      this.getDataEdit();
    }
  }

  deleteValue(value) {
    this.form.get(value).setValue(null);
  }

  getDataEdit(){
    this.form.get('Name').setValue(this.data.wShift.Name);
    this.form.get('isActive').setValue(this.data.wShift.IsActive);

    for(let i = 0; i < this.data.wShift.Schedule.length; i++) {
      const schedule = this.data.wShift.Schedule[i];
      const dayEntry = `${this.diasSemana[schedule.Day]}Entry`;
      const dayLeave = `${this.diasSemana[schedule.Day]}Leave`;
      const endDay = `${this.diasSemana[schedule.Day]}End`;
      const breakTimeDiscountL = `${this.diasSemana[schedule.Day]}ExtraTimeDiscountL`;
      const breakTimeDiscountR = `${this.diasSemana[schedule.Day]}ExtraTimeDiscountR`;
      const timeTrdL = `${this.diasSemana[schedule.Day]}TrdL`;
      const timeTrdR = `${this.diasSemana[schedule.Day]}TrdR`;
      this.form.get(dayEntry).setValue(schedule.StartHour);
      this.form.get(dayLeave).setValue(schedule.EndHour);
      if (schedule.EndDay == null){
        this.form.get(endDay).setValue(this.convertirNumeroADiaTexto(schedule.Day));
      }else{
        this.form.get(endDay).setValue(this.convertirNumeroADiaTexto(schedule.EndDay));
      }
      this.form.get(breakTimeDiscountL).setValue(schedule.BreakTime.split(':')[0]);
      this.form.get(breakTimeDiscountR).setValue(schedule.BreakTime.split(':')[1]);
      this.form.get(timeTrdL).setValue(schedule.WorkedTimeBeforeBreakTime.split(':')[0]);
      this.form.get(timeTrdR).setValue(schedule.WorkedTimeBeforeBreakTime.split(':')[1]);
    }

  }

  convertirNumeroADiaTexto(numeroDia: number): string {
    return this.diasSemana[numeroDia];
  }

  getTotalTime(name, nameEnd, startHour, finishHour): string {
    let difference = 0;
    const startTimeValue = this.form.get(startHour).value;
    const finishTimeValue = this.form.get(finishHour).value;
    const nameEndValue = this.form.get(nameEnd).value;

    if (startTimeValue != null && finishTimeValue != null) {
      const startHours = parseInt(startTimeValue.slice(0, 2));
      const startMinutes = parseInt(startTimeValue.slice(3, 5));
      const finishHours = parseInt(finishTimeValue.slice(0, 2));
      const finishMinutes = parseInt(finishTimeValue.slice(3, 5));
      const startTotalMinutes = startHours * 60 + startMinutes;
      const finishTotalMinutes = finishHours * 60 + finishMinutes;
      difference = (finishTotalMinutes - startTotalMinutes) / 60;

      // Calcular la cantidad de días entre el día inicial y el día final
      const dayDifference = this.getDayDifference(name, nameEndValue);
      difference += dayDifference * 24;
    }
    const hours = Math.floor(difference);
    const minutes = Math.round((difference - hours) * 60);

    let result = '';
    if (hours < 0 || minutes < 0) {
      result = '00:00';
    } else {
      result = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
    }

    return result;
  }

  getDayDifference(name, nameEnd): number {
    // Asignar valores numéricos a los días de la semana
    const daysOfWeek = this.diasSemana;
    const startIndex = daysOfWeek.findIndex(day => day === name);
    const endIndex = daysOfWeek.findIndex(day => day === nameEnd);

    // Calcular la diferencia de días teniendo en cuenta los posibles casos
    if (startIndex === -1 || endIndex === -1) {
      return 0;
    } else if (startIndex <= endIndex) {
      return endIndex - startIndex;
    } else {
      return (7 - startIndex) + endIndex;
    }
  }



  getHoursWorkTime(name, nameEnd, startHour, finishHour, restL, restR, extraTrdR, extraTrdL): string {
    let total = this.getTotalTime(name, nameEnd, startHour, finishHour);
    let restTime = this.form.get(restL).value + ':' + this.form.get(restR).value;
    let extraTrdTime = this.form.get(extraTrdL).value + ':' + this.form.get(extraTrdR).value;


    const totalMinutes = this.parseTimeToMinutes(total);
    const restMinutes = this.parseTimeToMinutes(restTime);
    const extraTrdMinutes = this.parseTimeToMinutes(extraTrdTime);

    // Calcular la resta en minutos
    let differenceMinutes = totalMinutes;

    if (totalMinutes > extraTrdMinutes){
      differenceMinutes = totalMinutes - restMinutes;
    }

    // Convertir el resultado de la resta nuevamente a formato de tiempo (HH:mm)
    const hours = Math.floor(differenceMinutes / 60);
    const minutes = differenceMinutes % 60;
    let result = '';

    if (hours < 0 || minutes < 0) {
      result = '00:00';
    } else {
      result = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
    }

    return result;
  }

  getTotalHoursWork(): string {
    let totalMinutes = 0;
    for (let item of this.horario) {
      const hoursWorked = this.getHoursWorkTime(item.Name, item.NameEnd, item.start, item.finisht, item.extraTimeBreakL, item.extraTimeBreakR, item.extraTimeTrdR, item.extraTimeTrdL);
      const [hours, minutes] = hoursWorked.split(':').map(Number);
      totalMinutes += hours * 60 + minutes;
    }
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${this.formatNumber(hours)}:${this.formatNumber(minutes)}`;
  }

  private formatNumber(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  parseTimeToMinutes(time: string): number {
    const hours = parseInt(time.slice(0, 2));
    const minutes = parseInt(time.slice(3, 5));
    return hours * 60 + minutes;
  }

  save() {
    const datos: any[] = [];
    let dataSend= {};

    this.diasSemana.forEach(dia => {
      const entry = this.form.get(`${dia}Entry`).value;
      const leave = this.form.get(`${dia}Leave`).value;
      const breakTime = this.form.get(`${dia}ExtraTimeDiscountL`).value + ':' + this.form.get(`${dia}ExtraTimeDiscountR`).value;
      const timeBeforeBreak = this.form.get(`${dia}TrdL`).value + ':' + this.form.get(`${dia}TrdR`).value;
      const endDay = this.form.get(`${dia}End`).value;

      datos.push({
          Day: this.convertirDiaEnNumero(dia),
          EndDay: this.convertirDiaEnNumero(endDay),
          StartHour: entry,
          EndHour: leave,
          BreakTime: breakTime,
          WorkedTimeBeforeBreakTime: timeBeforeBreak
        });
    });

    const name = this.form.get('Name').value;
    const startDate = this.fechasService.getDateFormatDatePikerDATEHORANOW();
    const endDate = null;
    const schedule = datos;

    dataSend = {
      Name: name,
      StartDate: startDate,
      EndDate: endDate,
      IsActive: this.form.get('isActive').value,
      Schedule: schedule,
      CompanyInfoId : this.data.CompanyInfoId,
      ProjectId: this.data.ProjectId
    };
    console.log('this.form',this.form);
    if (this.form.status === 'VALID') {
      if (this.data.wShift == null){
        this.projectService.postWorkShift(dataSend).subscribe(resPost => {
          this.dialogRef.close(true);
        });
      }else{
        this.projectService.updateWorkShift(this.data.wShift.Id, dataSend).subscribe(resPost => {
          this.dialogRef.close(true);
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  convertirDiaEnNumero(dia: string): number {
    return this.diasSemana.indexOf(dia);
  }

  getImg(logo) {
    let img = (logo === null || logo === '' ? 'assets/images/personblue.png': logo);
    return this.s3Bucket+img;
  }

  close() {
    this.dialogRef.close(false);
  }

  clear(start, finist) {
    this.form.get(start).setValue(null);
    this.form.get(finist).setValue(null);
  }

  hrsData() {
    for (let i = 1; i <= 23; i++) {
      this.mins.forEach(minute => {
        let str = `${i}:${minute}`;
        this.hrs.push(str);
      });
    }
  }

}
