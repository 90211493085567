import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Back, CommonOptions } from 'src/app/core/interfaces/interfaces';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { environment } from 'src/environments/environment';
import { FechasService } from 'src/app/core/services/utils/fechas.service';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';
import { CommonMetodService } from 'src/app/core/services/utils/commonMetod.service';
import { CurrencyPipe } from '@angular/common';
import { PersonalService } from '../../../services/modules/personal.service';
import { ContratingService } from '../../../services/modules/contrating.service';
import { ProjectService } from 'src/app/core/services/modules/project.service';

export interface DialogData {
  PersonalCompanyInfoId: string;
  EmployerId: string;
  CompanyInfoId: string;
  companyIdUserLogin: string;
}

@Component({
  selector: 'app-dialog-create-contract-labor',
  templateUrl: './dialog-create-contract-labor.component.html',
  styleUrls: ['./dialog-create-contract-labor.component.scss']
})
export class DialogCreateContractLaborComponent implements OnInit {
  s3Bucket: string;
  form: FormGroup;

  projects: any[] = [];
  workShift: any[] = [];
  allEmployers: any[] = [];

  bancks: Back[] = [];
  bankAccountType: CommonOptions[] = [];
  individualContractsType: CommonOptions[] = [];

  date = new Date();
  formDatosContrato: FormGroup;
  minDateVigenciaContract = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());

  constructor(public dialogRef: MatDialogRef<DialogCreateContractLaborComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private commonMetodService: CommonMetodService,
              private commonService: CommonService,
              private personalService: PersonalService,
              private fechasService: FechasService,
              private currencyPipe: CurrencyPipe,
              private contratingService: ContratingService,
              private snackBService: SnackBarService,
              private projectService: ProjectService,
              private fb: FormBuilder) {
      const company = this.data.companyIdUserLogin === undefined || this.data.companyIdUserLogin === '' ||
      this.data.companyIdUserLogin === null ? undefined : this.data.companyIdUserLogin;
      this.formDatosContrato = this.fb.group({
        IndividualContractTypeId: new FormControl(''),
        PersonalEmployerInfoId: new FormControl(''),
        // PositionId: new FormControl(''),
        PositionName: new FormControl('', company ? [Validators.required] : []),
        Salary: ['', company ? [Validators.min(1), Validators.required] : []],
        PayPeriodId: new FormControl(''),
        StartDate: new FormControl(''),
        EndDate: new FormControl(''),
        ContractId: '',
        examType: '',
        EmployerInfoId: new FormControl('', Validators.required),
        EmployerName: '',
        EmployerDocumentType: '',
        EmployerDocumentNumber: '',
        EmployerLogo: '',
        AuxiliaryTransport: ['', company ? [Validators.min(1), Validators.required] : []],
        // Datos bancarios
        BankAccount: new FormControl('', company ? [Validators.required] : []),
        BankId: new FormControl('', company ? [Validators.required] : []),
        BankAccountTypeId: new FormControl('', company ? [Validators.required] : []),
        ContractNumber: new FormControl('', company ? [Validators.required] : []),
        ContractTypeId: new FormControl('', company ? [Validators.required] : []),
        // Pre nomina
        ProjectId: '',
        WorkShiftId: ''
      });
      this.formDatosContrato.valueChanges.subscribe(form => {
        if (form.Salary && this.commonMetodService._validarDateMoney(form.Salary)
          ) {
          this.formDatosContrato.patchValue({ Salary: this.currencyPipe.transform(form.Salary.toString().replace(/\D/g, '').replace(/^0+/, ''),
              'USD', 'symbol', '1.0-0').replace(/,/gi, '.')
          }, {emitEvent: false});
        }
        if (form.AuxiliaryTransport && this.commonMetodService._validarDateMoney(form.AuxiliaryTransport)
        ) {
          this.formDatosContrato.patchValue({ AuxiliaryTransport: this.currencyPipe.transform(
            form.AuxiliaryTransport.toString().replace(/\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '1.0-0').replace(/,/gi, '.')
          }, {emitEvent: false});
        }
      });
      this.formDatosContrato.get('EmployerInfoId').setValue(this.data.EmployerId);

      this.personalService.getPersonalEmployerGenerateContractNumber().subscribe((rest: any) => {
        if (rest !== undefined && rest !== null) {
          this.formDatosContrato.get('ContractNumber').setValue(rest.ContractNumber);
        }
      });
    }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    this.getIndividualContractsServices();
    this.getEmployerAll();
    this.getProjects();
  }

  close() {
    this.dialogRef.close(false);
  }

  getProjects() {
    this.projectService.getProjects().subscribe((rest: any) => {
      this.projects = rest;
    });
  }

  getPersonalWorkShiftrprojectId(ProjectId) {
    this.personalService.getPersonalWorkShiftrprojectId(ProjectId).subscribe((rest: any) => {
      this.workShift = rest;
    });
  }

  changeEmployer(Id) {
    this.personalService.getPersonalEmployerByPersonalAndEmployer(this.data.PersonalCompanyInfoId,
      Id).subscribe((restExitContract: any) => {
      this.snackBService.openSnackBar('Ya existe un contrato laboral con el empleador ' + restExitContract.EmployerName, 'X', 4000);
      this.formDatosContrato.get('EmployerInfoId').setValue('');
    }, error => {
    });
  }

  deleteEndDateLaboral() {
    this.formDatosContrato.get('EndDate').setValue(null);
  }

  getIndividualContractsServices() {
    this.commonService.getCommonOptions('IndividualContractsType').subscribe(rest => {
      this.individualContractsType = rest;
    });
    this.contratingService.getBanks().subscribe((rest: any) => {
      this.bancks = rest;
    });
    this.commonService.getCommonOptions('BankAccountType').subscribe(rest => {
      this.bankAccountType = rest;
    });
  }

  getEmployerAll() {
    this.commonService.getAllEmployersCompanyId(this.data.CompanyInfoId).subscribe((data: any) => {
      this.allEmployers = data.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
    });
  }

  save() {
    if (this.formDatosContrato.status === 'VALID' && this.formDatosContrato.status === 'VALID')  {
      const model = {
        Salary: this.formDatosContrato.get('Salary').value.toString().replace(/\$/g, '').replace(/\./g, ''),
        StartDate: this.formDatosContrato.get('StartDate').value,
        EndDate: this.formDatosContrato.get('EndDate').value,
        // PositionId: this.formDatosContrato.get('PositionId').value,
        PositionName: this.formDatosContrato.get('PositionName').value,
        ContractNumber: this.formDatosContrato.get('ContractNumber').value,
        ContractTypeId: this.formDatosContrato.get('ContractTypeId').value,
        BankAccount: this.formDatosContrato.get('BankAccount').value,
        BankId: this.formDatosContrato.get('BankId').value,
        BankAccountTypeId: this.formDatosContrato.get('BankAccountTypeId').value,
        IsActive: true,
        TransportationAllowance: this.formDatosContrato.get('AuxiliaryTransport').value.toString().replace(
          /\$/g, '').replace(/\./g, ''),
        // IMPORTAN
        EmployerInfoId: this.data.EmployerId,
        PersonalCompanyInfoId: this.data.PersonalCompanyInfoId,
        // Datos de la prenomina
        ProjectId: this.formDatosContrato.get('ProjectId').value,
        WorkShiftId: this.formDatosContrato.get('WorkShiftId').value,
      };
      this.personalService.postPersonalEmployer(model).subscribe(postPersonalEmployer => {
        this.dialogRef.close(true);
      }, error => {
        this.snackBService.openSnackBar(error.error.Error, 'X', 4000);
      });
    } else {
      this.formDatosContrato.markAllAsTouched();
    }
  }

}

