import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonOptions, DashBoardFiltersBase } from 'src/app/core/interfaces/interfaces';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { DashBoardService } from 'src/app/core/services/modules/dashboard.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { JsonDashboardFiltersService } from 'src/app/core/services/json-service/json-dashboardFilters.service';
import { Router, ActivatedRoute } from '@angular/router';

export interface Total {
  Accomplish: number;
  Active: number;
  Expiry: number;
  Inactive: number;
  NewPersonal: number;
  NoCumple: number;
  Presence: number;
  Total: number;
  TotalLast: number;
}

@Component({
  selector: 'app-custom-page',
  templateUrl: './custom-page.component.html',
  styleUrls: ['./custom-page.component.scss']
})
export class CustomPageComponent implements OnInit {

  production:boolean = environment.production;

  companies: any[] = [];
  proyects: any[] = [];
  employers: any[] = [];
  contracts: any[] = [];
  person: any[] = [];

  controlCompanies = new FormControl();
  controlProyects = new FormControl();
  controlEmployers = new FormControl();
  controlContracts = new FormControl();
  controlPerson = new FormControl();
  controlFilter = new FormControl();

  formFilters: FormGroup;

  dashboardPersonalFilter: CommonOptions[] = [];

  dashboardPersonalFilterValue: string = 'byPersona';

  @ViewChild('scrollSummaryContent') scrollSummaryContent: ElementRef;
  @ViewChild('scrollSummaryNames') scrollSummaryNames: ElementRef;

  data: any[] = [];
  jsonDashboardFilters: DashBoardFiltersBase[];
  total: Total;

  constructor(private dashboardService: DashBoardService, 
              private commonService:CommonService,
              private jsonDashboardFiltersService: JsonDashboardFiltersService,
              private fb: FormBuilder,
              private router: Router,
              private ar: ActivatedRoute) {
    this.formFilters = this.fb.group({
      estado: 'Active',
      variaciones: '-1',
      asistencia: '-1',
      cumplimiento: '-1',
      categorias: '-1',
      sexo: '-1',
      rango: '-1'
    });
    this.jsonDashboardFilters = this.jsonDashboardFiltersService.dashBoardFilters;
    this.ar.params.subscribe((rest:any) => {
      this.token = rest!.token;
      this.getContractValidateExist();
      this.commonService.getCommonOptions("DashboardPersonalFilter", this.token).subscribe((data: CommonOptions[]) => {
        this.dashboardPersonalFilter = data;
        this.controlFilter.setValue('byPersona');
      });
    });
    // this.getContractValidateExist();
   }

   token = ''

  ngOnInit(): void {
    // Filter person
    this.person.push({Name: "Todas las personas", Id: '-1'});
    this.controlPerson.setValue('-1');
  }

  goPerson(item) {
    if(item.PersonalId){
      this.router.navigate(['/admin/personal/person',item?.PersonalId]);
    }
  }

  onScrollSummaryContent(e) {
    this.scrollSummaryNames.nativeElement.scrollTop = e.target.scrollTop;
  }

  onScrollSummaryNames(e) {
    this.scrollSummaryContent.nativeElement.scrollTop = e.target.scrollTop;
  }

  getContractValidateExist() {
    this.dashboardService.getContractValidateExist(this.token).subscribe(rest => {
      
      this.companies = rest;
      if(this.companies.length === 1) {
        this.controlCompanies.setValue(rest[0].Id);
        // Select
        this.getProjectSelectCompanyInfoId(rest[0].Id);
        this.getEmployerSelectCompanyInfoId(rest[0].Id, '');
        this.getContractSelectCompanyInfoId(rest[0].Id, '', '');
        // Main Dashboard
        this.getDashBoardMainCompanyInfoId(rest[0].Id, '','','');
        this.getDashBoardMainCompanyInfoIdisCount(rest[0].Id, '','','');
        this.getDashBoardMainFilter(rest[0].Id, '','','');
      } else if(this.companies.length > 1) {
        this.companies.push({Name: "Todas las administradoras", Id: '-1'});
        this.companies.reverse();
        this.controlCompanies.setValue('-1');
        // Select
        this.getProjectSelectCompanyInfoId(rest[0].Id);
        this.getEmployerSelectCompanyInfoId(rest[0].Id, '');
        this.getContractSelectCompanyInfoId(rest[0].Id, '', '');
        // Main Dashboard
        this.getDashBoardMainCompanyInfoId(rest[0].Id, '','','');
        this.getDashBoardMainCompanyInfoIdisCount(rest[0].Id, '','','');
        this.getDashBoardMainFilter(rest[0].Id, '','','');
      }
      
    });
  }

  // Select Filter
  // Proyectos
  getProjectSelectCompanyInfoId(companyInfoId) {
    this.dashboardService.getProjectSelectCompanyInfoId(this.token, companyInfoId).subscribe(rest => {
      this.proyects = rest.filter(x => x.IsActive === true);
      this.proyects.push({Name: "Todos los proyectos", Id: '-1', IsActive: true});
      this.controlProyects.setValue('-1');
      this.proyects.sort((a,b) => a.Name === 'Todos los proyectos' ? -1 : (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
    });
  }

  // empresas
  getEmployerSelectCompanyInfoId(companyInfoId, projectId) {
    this.dashboardService.getEmployerSelectCompanyInfoId(this.token, companyInfoId, projectId).subscribe(rest => {
      this.employers = rest !== null ? rest.filter(x => x.IsActive === true) : [];
      this.employers.push({Name: "Todas las empresas", Id: '-1', IsActive: true});
      this.controlEmployers.setValue('-1');
      this.employers.sort((a,b) => a.Name === 'Todas las empresas' ? -1 : (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
    });
  }

  // contratos
  getContractSelectCompanyInfoId(companyInfoId, employerId, projectId) {
    this.dashboardService.getContractSelectCompanyInfoId(this.token, companyInfoId, employerId, projectId).subscribe(rest => {
      this.contracts = rest.filter(x => x.IsActive === true);
      this.contracts.push({ContractReview: "Todos los contratos", ContractNumber: '', Id: '-1', IsActive: true});
      this.controlContracts.setValue('-1');
      this.contracts.sort((a,b) => a.ContractReview === 'Todos los contratos' ? -1 : (a.ContractReview > b.ContractReview) ? 1 : ((b.ContractReview > a.ContractReview) ? -1 : 0));
    });
  }
  

  // DashBoard Main
  // total
  getDashBoardMainCompanyInfoId(companyInfoId, contractId, employerId, projectId) {
    this.dashboardService.getDashBoardMainCompanyInfoId(this.token, companyInfoId,  contractId, employerId, projectId).subscribe((rest: Total) => {
      this.total = rest;
    });
  }
  // count
  getDashBoardMainCompanyInfoIdisCount(companyInfoId, contractId, employerId, projectId) {
    this.dashboardService.getDashBoardMainCompanyInfoIdisCount(this.token, companyInfoId, contractId, employerId, projectId).subscribe(rest => {
    });
  }

  // filter final data
  getDashBoardMainFilter(companyInfoId, contractId, employerId, projectId) {
    this.dashboardService.getDashBoardMainCompanyInfoIdbyPersona(this.token, companyInfoId, this.dashboardPersonalFilterValue, contractId, employerId, projectId).subscribe(rest => {
      this.data = rest;
      this.changeSubFilter('Active');
    });
  }


  // change selected

// Cambiar Company
  onChangeSelectedCompany(e) {
    this.getProjectSelectCompanyInfoId(e);
    this.getEmployerSelectCompanyInfoId(this.controlCompanies.value, '');
    this.getContractSelectCompanyInfoId(this.controlCompanies.value, 
      this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '', e);
    
    this.getDashBoardMainCompanyInfoId(
          this.controlCompanies.value, 
          this.controlContracts.value !== null && this.controlContracts.value !== '-1' ? this.controlContracts.value: '',
          this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '',
          this.controlProyects.value !== null && this.controlProyects.value !== '-1' ? this.controlProyects.value: '');
    this.getDashBoardMainCompanyInfoIdisCount(
          this.controlCompanies.value, 
          this.controlContracts.value !== null && this.controlContracts.value !== '-1' ? this.controlContracts.value: '',
          this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '',
          this.controlProyects.value !== null && this.controlProyects.value !== '-1' ? this.controlProyects.value: '');
    this.getDashBoardMainFilter(
          this.controlCompanies.value, 
          this.controlContracts.value !== null && this.controlContracts.value !== '-1' ? this.controlContracts.value: '',
          this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '',
          this.controlProyects.value !== null && this.controlProyects.value !== '-1' ? this.controlProyects.value: '');
    this.clearformFilters();
  }

  // Cambiar proyecto
  onChangeSelectedPro(e) {
    this.getEmployerSelectCompanyInfoId(this.controlCompanies.value, e);
    this.getContractSelectCompanyInfoId(this.controlCompanies.value, 
      this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '', e);
    
    this.getDashBoardMainCompanyInfoId(
          this.controlCompanies.value, 
          this.controlContracts.value !== null && this.controlContracts.value !== '-1' ? this.controlContracts.value: '',
          this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '',
          this.controlProyects.value !== null && this.controlProyects.value !== '-1' ? this.controlProyects.value: '');
    this.getDashBoardMainCompanyInfoIdisCount(
          this.controlCompanies.value, 
          this.controlContracts.value !== null && this.controlContracts.value !== '-1' ? this.controlContracts.value: '',
          this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '',
          this.controlProyects.value !== null && this.controlProyects.value !== '-1' ? this.controlProyects.value: '');
    this.getDashBoardMainFilter(
          this.controlCompanies.value, 
          this.controlContracts.value !== null && this.controlContracts.value !== '-1' ? this.controlContracts.value: '',
          this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '',
          this.controlProyects.value !== null && this.controlProyects.value !== '-1' ? this.controlProyects.value: '');
    this.clearformFilters();
  }
  // Cambiar Empresa
  onChangeSelectedEmp(e) {
    this.getContractSelectCompanyInfoId(this.controlCompanies.value, e,  this.controlProyects.value !== null && this.controlProyects.value !== '-1' ? this.controlProyects.value: '' );

    this.getDashBoardMainCompanyInfoId(
          this.controlCompanies.value, 
          this.controlContracts.value !== null && this.controlContracts.value !== '-1' ? this.controlContracts.value: '',
          this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '',
          this.controlProyects.value !== null && this.controlProyects.value !== '-1' ? this.controlProyects.value: '');
    this.getDashBoardMainCompanyInfoIdisCount(
          this.controlCompanies.value, 
          this.controlContracts.value !== null && this.controlContracts.value !== '-1' ? this.controlContracts.value: '',
          this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '',
          this.controlProyects.value !== null && this.controlProyects.value !== '-1' ? this.controlProyects.value: '');
    this.getDashBoardMainFilter(
          this.controlCompanies.value, 
          this.controlContracts.value !== null && this.controlContracts.value !== '-1' ? this.controlContracts.value: '',
          this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '',
          this.controlProyects.value !== null && this.controlProyects.value !== '-1' ? this.controlProyects.value: '');
    this.clearformFilters();
  }
  // Cambiar Contrato
  onChangeSelectedContract(e) {
    this.controlContracts.setValue(e);
    this.getDashBoardMainCompanyInfoId(
          this.controlCompanies.value, 
          this.controlContracts.value !== null && this.controlContracts.value !== '-1' ? this.controlContracts.value: '',
          this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '',
          this.controlProyects.value !== null && this.controlProyects.value !== '-1' ? this.controlProyects.value: '');
    this.getDashBoardMainCompanyInfoIdisCount(
          this.controlCompanies.value, 
          this.controlContracts.value !== null && this.controlContracts.value !== '-1' ? this.controlContracts.value: '',
          this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '',
          this.controlProyects.value !== null && this.controlProyects.value !== '-1' ? this.controlProyects.value: '');
    this.getDashBoardMainFilter(
          this.controlCompanies.value, 
          this.controlContracts.value !== null && this.controlContracts.value !== '-1' ? this.controlContracts.value: '',
          this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '',
          this.controlProyects.value !== null && this.controlProyects.value !== '-1' ? this.controlProyects.value: '');
    this.clearformFilters();
  }

  changeFilter(e) {
    this.dashboardPersonalFilterValue = e;
    this.getDashBoardMainFilter(
      this.controlCompanies.value, 
      this.controlContracts.value !== null && this.controlContracts.value !== '-1' ? this.controlContracts.value: '',
      this.controlEmployers.value !== null && this.controlEmployers.value !== '-1' ? this.controlEmployers.value: '',
      this.controlProyects.value !== null && this.controlProyects.value !== '-1' ? this.controlProyects.value: '');
    this.clearformFilters();      
  }

  flagChangeFilter: boolean = false;
  dataFilter: any[] = [];
  changeSubFilter(e) {
    this.dataFilter = [];
    this.flagChangeFilter = true;
    if(e === '-1') {
      this.dataFilter = [];
      this.flagChangeFilter = false;
    } else if(e === 'Active') { // STATE
      this.dataFilter = this.data.filter(x => x.Active > 0);
    } else if(e === 'Inactive') {
      this.dataFilter = this.data.filter(x => x.Inactive > 0);
    } else if(e === 'Expiry') {
      this.dataFilter = this.data.filter(x => x.Expiry > 0);
    } else if(e === 'Activated') { // VARIACIONES  
      this.dataFilter = this.data.filter(x => x.Variaciones.Activated > 0);
    } else if(e === 'Reactivated') {
      this.dataFilter = this.data.filter(x => x.Variaciones.Reactivated > 0);
    } else if(e === 'Inactivated') {
      this.dataFilter = this.data.filter(x => x.Variaciones.Inactivated > 0);
    } else if(e === 'NotAssistant') { // ASISTENCIA  
      this.dataFilter = this.data.filter(x => x.NotAssistant > 0);
    } else if(e === 'Assistant') {
      this.dataFilter = this.data.filter(x => x.Assistant > 0);
    } else if(e === 'Cumple') { // CUMPLIMIENTO  
      this.dataFilter = this.data.filter(x => x.Cumple > 0);
    } else if(e === 'NoCumple') {
      this.dataFilter = this.data.filter(x => x.NoCumple > 0);
    } else if(e === 'AD') {  // CATEGORY
      this.dataFilter = this.data.filter(x => x.AD > 0);
    } else if(e === 'AS') {
      this.dataFilter = this.data.filter(x => x.AS > 0);
    } else if(e === 'CO') {
      this.dataFilter = this.data.filter(x => x.CO > 0);
    } else if(e === 'PR') {
      this.dataFilter = this.data.filter(x => x.PR > 0);
    } else if(e === 'FU') {
      this.dataFilter = this.data.filter(x => x.FU > 0);
    } else if(e === 'VI') {
      this.dataFilter = this.data.filter(x => x.VI > 0);
    } else if(e === 'OT') {
      this.dataFilter = this.data.filter(x => x.OT > 0);
    } else if(e === 'SexMale') { //SEXO
      this.dataFilter = this.data.filter(x => x.SexMale > 0);
    } else if(e === 'SexFemale') { 
      this.dataFilter = this.data.filter(x => x.SexFemale > 0);
    } else if(e === 'Menor18') { //RANGE YEARS
      this.dataFilter = this.data.filter(x => x.Menor18 > 0);
    } else if(e === 'Menor30') {
      this.dataFilter = this.data.filter(x => x.Menor30 > 0);
    } else if(e === 'Menor40') {
      this.dataFilter = this.data.filter(x => x.Menor40 > 0);
    } else if(e === 'Menor50') {
      this.dataFilter = this.data.filter(x => x.Menor50 > 0);
    } else if(e === 'Menor60') {
      this.dataFilter = this.data.filter(x => x.Menor60 > 0);
    } else if(e === 'Menor70') {
      this.dataFilter = this.data.filter(x => x.Menor70 > 0);
    } else if(e === 'Menor80') {
      this.dataFilter = this.data.filter(x => x.Menor80 > 0);
    }
  }

  clearformFilters() {
    this.formFilters.get('estado').setValue('-1');
    this.formFilters.get('variaciones').setValue('-1');
    this.formFilters.get('asistencia').setValue('-1');
    this.formFilters.get('cumplimiento').setValue('-1');
    this.formFilters.get('categorias').setValue('-1');
    this.formFilters.get('sexo').setValue('-1');
    this.formFilters.get('rango').setValue('-1');
    this.flagChangeFilter = false;
    this.dataFilter = [];
  }


  summaryData(campo, campoMore?): number {
    let sum = 0;
    if(this.flagChangeFilter === true) {
      this.dataFilter.filter(x => {
        if(campoMore) {
          if(campoMore === 'Variaciones'){
            sum += (x.Variaciones[campo]);
          }else {
            sum += (x[campo] + x[campoMore]);
          }
        } else {
          sum += x[campo];
        }
      });
    } else {
      this.data.filter(x => {
        if(campoMore) {
          if(campoMore === 'Variaciones'){
            sum += (x.Variaciones[campo]);
          }else {
            sum += (x[campo] + x[campoMore]);
          }
        } else {
          sum += x[campo];
        }
      });
    }
    return sum; 
  }


  getPercentage(a, b, c?): string {
    let value: number = 0;
    if(a !== null && a !== undefined && b !== null && b !== undefined && (a !== 0 && b !== 0)){
      value = (a / b) * 100;
    }
    return value.toFixed()+'%';
  }
}
