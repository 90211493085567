import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppColor, TypeCard } from '../../services/utils/utils.types';
import { SocialSecurity } from '../../models/SocialSecurity';
import { CompanyModel } from '../../models/CompanyModel';

@Component({
  selector: 'app-card-information',
  templateUrl: './card-information.component.html',
  styleUrls: ['./card-information.component.scss']
})



export class CardInformationComponent implements OnInit {

  s3Bucket:string;
  planillas: SocialSecurity[] = [];
  company: CompanyModel;

  //configCard  CARD 
  @Input() configCard: ConfigCard;

  // CONFIG CARD INFORMATION ''
  // TITLE AND PHOTO
  @Input() title: string;
  @Input() photo: string;
  // DESCRIPTION 2 ITEMS
  @Input() description: Description;
  // SUBTITLE 3 ITEMS
  @Input() subDescription?: SubDescription;
  // FINAL-SUBTITLE 3 ITEMS
  @Input() subDescriptionEnd?: SubDescriptionEnd;

  @Input() notListDescription: string;
  

  //Actions
  @Output() actionRouter = new EventEmitter<string>();

  // @Input() planillas: SocialSecurity[];

  @Input() set _company(value: CompanyModel) {
    this.company = value;
  }

  constructor() { }

  ngOnInit(): void {
    this.s3Bucket=environment.api.s3Bucket;
  }

  public actionRt(Id) {
    this.actionRouter.emit(Id);
  }

}

//MODELO
export interface Description {
  key1?: string;
  key2?: string;
  item1?: string;
  item2?: string;
}
export interface ConfigCard {
  title?: string;
  color?: AppColor;
  typeCard?: TypeCard;
  Id?: string;
  IdOther?: string;
}

export interface SubDescription {
  title?: string;
  item1?: string;
  item2?: string;
  item3?: string;
  icon1?: string;
  icon2?: string;
  icon3?: string;
}

export interface SubDescriptionEnd {
  title: string;
  item1?: string;
  item2?: string;
  item3?: string;
  icon1?: string;
  icon2?: string;
  icon3?: string;
}
