import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonOptions, ModelDocumentsShow } from '../../../interfaces/interfaces';
import { DialogService } from '../../../services/utils/dialog.service';
import { EmployerService } from '../../../services/modules/employer.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/services/modules/auth.service';
import { User } from 'src/app/core/models/User';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { FechasService } from 'src/app/core/services/utils/fechas.service';
import {CropperComponent} from 'angular-cropperjs';

export interface DialogData {
  title: string;
  docs: ModelDocumentsShow[];
  typeOperator: string;
  idPlanilla: number;
  typeGestor: string;
  cropper?: boolean;
  desc: string;
  proveedorId: boolean;
  permissionCompany: string[];
  isFromPersonal: boolean;
}

@Component({
  selector: 'app-dialog-gestor-img-personal',
  templateUrl: './dialog-gestor-img-personal.component.html',
  styleUrls: ['./dialog-gestor-img-personal.component.scss']
})
export class DialogGestorImgPersonalComponent implements OnInit {
  @ViewChild('angularCropper') angularCropper: CropperComponent;

  userAuth: User;
  s3Bucket: string;
  email = '';
  DocsJSON: any = {};
  imgCropper: string;
  _file: File = null;
  formFile: FormGroup;
  upDateDocuments: any;
  commentary = '';
  imgCropperResult: string;
  cropper = false;

  files: File[] = [];
  documentos: any[] = [];
  proveedor: CommonOptions[] = [];

  formData = new FormData();
  controProveedor = new FormControl();

  constructor(
    private dialogRef: MatDialogRef<DialogGestorImgPersonalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private employerService: EmployerService,
    private fb: FormBuilder,
    private authService: AuthService,
    private commonService: CommonService,
    private fechasService: FechasService,
  ) {
    this.userAuth = this.authService.getUser();
    this.email = this.userAuth?.Email !== null && this.userAuth?.Email !== undefined ? this.userAuth?.Email : '';
    this.formFile = this.fb.group({
      File: ''
    });
    if (this.data.cropper) {
      this.cropper = this.data.cropper;
    }
    if (this.data.typeOperator === 'uploadProveedor'){
      this.commonService.getCommonOptions('PlanillaProvider').subscribe((data: CommonOptions[]) => {
        this.proveedor = data;
      });
    }
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    this.data.docs.forEach(doc => {
      if (this.getName(doc).includes('commentary_fileAnalityco.txt')){
        const archivo = new XMLHttpRequest();
        archivo.open('GET', this.s3Bucket + this.getUrl(doc), false);
        archivo.send(null);
        const data = this.getSplitDoc(doc);
        for (let index = 0; index < data.length; index++) {
          if (index === 0) {
            doc.urlDoc = data[index];
          }
          if (index === 1) {
            setTimeout(() => {
              doc.nameFile = archivo.responseText;
            }, 300);
          }
          if (index === 2) {
            doc.user = data[index];
          }
          if (index === 3) {
            doc.date = data[index];
          }
        }
        doc.isCommentary = true;
      } else {
        const data = this.getSplitDoc(doc);
        for (let index = 0; index < data.length; index++) {
          if (index === 0) {
            doc.urlDoc = data[index];
          }
          if (index === 1) {
            doc.nameFile = data[index];
          }
          if (index === 2) {
            doc.user = data[index];
          }
          if (index === 3) {
            doc.date = data[index];
          }
          if (index === 4) {
            doc.module = data[index];
          }
          if (index === 5) {
            doc.module_id = data[index];
          }
          if (index === 6) {
            doc.module_state = data[index];
          }
          if (index === 7) {
            doc.module_comment = data[index];
          }
          if (index === 8) {
            doc.module_date = data[index];
          }
        }
        doc.isCommentary = false;
      }
      this.documentos.push(doc);
    });
    this.documentos.reverse();
  }

  exitPermissionCompany(permiso: string): boolean {
    let exit = false;
    const permisionsUserInfo  = this.data.permissionCompany.find(x => x === permiso);
    permisionsUserInfo !== undefined ? exit = true : exit = false;
    return exit;
  }

  openImgVideo(doc: ModelDocumentsShow) {
    window.open(this.s3Bucket + this.getUrl(doc), '_blank');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getDateFormatDateYYYYMMDDdatePiker(date): string {
    let response = '';
    if (date !== null && date !== null) {
      let data;
      data = this.fechasService.formatDateYYYYMMDDdatePiker(date);
      response = this.fechasService.getDateFormatR(data);
    }
    return response;
  }

  getDateFormatDateGestorDocuemntsUpdate(date): string {
    let response = '';
    if (date !== null && date !== null && date !== undefined) {
      response = this.fechasService.formatDateYYYYMMDDdatePikerWhitMinutes(date);
    }
    return response;
  }

  getSplitDoc(doc: ModelDocumentsShow) {
    let name: any = '';
    if (doc) {
      const a = doc.urlDoc.split('||');
      for (let index = 0; index < a.length; index++) {
        if (index === 1)  {
          a[index] = a[index].replace('name=', '');
        }
        if (index === 2) {
          a[index] = a[index].replace('user=', '');
        }
        if (index === 3) {
          a[index] = a[index].replace('date=', '');
        }
        if (index === 4) {
          a[index] = a[index].replace('module=', '');
        }
        if (index === 5) {
          a[index] = a[index].replace('module_id=', '');
        }
        if (index === 6) {
          a[index] = a[index].replace('module_state=', '');
        }
        if (index === 7) {
          a[index] = a[index].replace('module_comment=', '');
        }
        if (index === 8) {
          a[index] = a[index].replace('module_date=', '');
        }
        if (index === a.length -1) {
          name = a;
        }
      }
    }
    return name;
  }

  getName(doc: ModelDocumentsShow) {
    let name = '';
    if (doc) {
      const a = doc.urlDoc.split('||name=');
      name = a[1];
    }
    return name;
  }

  getUrl(doc: ModelDocumentsShow): string {
    let url = '';
    const a = doc?.urlDoc?.split('||name=');
    if (a !== undefined) {
      url = a[0];
    }
    return url;
  }

  jsonUpdateDeleteDoc(doc: ModelDocumentsShow, index) {
    const DocsJSON = `{"${doc.name}": "${doc.urlDoc}"}`;
    const a = JSON.parse(DocsJSON);
    Object.assign(this.DocsJSON, a);
    delete this.documentos[index];
  }

  jsonDocExitValue(): boolean {
    return Object.entries(this.DocsJSON).length === 0;
  }

  upDateDocs(DocsJSON) {
    this.createFile();
    if (this.data.typeOperator === 'showDelete' || this.data.typeOperator === 'showUpdate') {
      this.dialogRef.close({DocsJSON, files: this.files, commentary: this.commentary});
    } else if (!this.jsonDocExitValue() && this.data.typeOperator === 'showUpdatePlanilla') {
      this.upDateDocuments = {Id: this.data.idPlanilla,  DocsJSON};
      this.employerService.updateDocsJsonPlanilla(this.data.idPlanilla, this.upDateDocuments).subscribe((rest: any) => {
        if (this.files.length > 0 || this.commentary !== '') {
          this.saveFiles();
        }
        this.dialogRef.close(rest.DocsJSON);
      });
    } else if (this.files.length > 0 || this.commentary !== '') {
      this.saveFiles();
    }
  }

  saveFiles() {
    for (let i = 0; i < this.files.length; i++) {
      const formData = new FormData();
      if (this.commentary !== '' && this.commentary !== null && this.commentary !== undefined) {
        formData.append('commentary', this.commentary);
      }
      formData.append('File', this.files[i]);
      this.employerService.updateFilesocialSecurity(this.data.idPlanilla, formData).subscribe((rest: any) => {
        if (i === this.files.length -1) {
          this.dialogRef.close(rest.DocsJSON);
        }
      });
    }
    if (this.files.length === 0 && (this.commentary !== '' && this.commentary !== null && this.commentary !== undefined)) {
      this.employerService.updateCommentsocialSecurityCommentary(this.data.idPlanilla, this.commentary).subscribe((rest: any) => {
        this.dialogRef.close(rest.DocsJSON);
      });
    }
  }

  // METODOS PARA CORTAR UNA IMAGEN Y GUARDARLA
  onSelect(event) {
    if (this.cropper === false) {
      const selectedFiles: FileList = event.addedFiles;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.files.push(selectedFiles[i]);
      }
    } else if (this.cropper === true && (this.data.typeOperator === 'showDelete' || this.data.typeOperator === 'showUpdate')) {
      const selectedFiles: FileList = event.addedFiles;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.files.push(selectedFiles[i]);
      }
    } else {
      const selectedFiles: FileList = event.addedFiles;
      const reader = new FileReader();
      reader.readAsDataURL(selectedFiles[0]);
      reader.onload = () => {
        this.imgCropper = reader.result as string;
        this.imgCropperResult = reader.result as string;
      };
    }
  }

  updatePhoto() {
    this.cropper = false;
    if (this.imgCropper !== undefined){
      this.imgCropperResult = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
      this.angularCropper.cropper.getCroppedCanvas().toBlob((blob) => {
        this.cropper = false;
        const formData = new FormData();
        formData.append('File', blob);
        this.dialogRef.close({FormData: formData, imgResult: this.imgCropperResult, commentary: this.commentary});
      }, 'iamge/jpeg', 0.95);
    } else {
      if (this.files.length === 1){
        if (this.data.typeOperator === 'uploadProveedor') {
          this.dialogRef.close({files: this.files, ProviderId: this.controProveedor.value});
        } else {
          const formData = new FormData();
          formData.append('File', this.files[0]);
          this.dialogRef.close(formData);
        }
      }
    }
  }

  isEqualsUserRemoveFile(doc): boolean {
    let equal = false;
    this.userAuth.username === doc?.user ? equal = true : equal = false;
    return equal;
  }

  // METODOS PARA CORTAR UNA IMAGEN Y GUARDARLA FIN
  removeFile(index) {
    // delete this.files[index];
    this.files.splice(index, 1);
  }

  isXLSX(doc: any): boolean {
    return this.getUrl(doc).slice(-5).toUpperCase() === '.XLSX';
  }

  getUrlFile(url) {
    const urlFile = this.s3Bucket + this.getUrl(url);
    return urlFile;
  }

  createFile() {
    // if(this.commentary !== '') {
    //   const file = new File([this.commentary], 'commentary_fileAnalityco.txt',{ type: 'text/plain' });
    //   this.files.push(file);
    // }
  }

  selectedProveedor(e) {
    const data = this.arrayURLSProveedor(e);
    this.dialogService.openDialogDialogConfirmImg('REQUERIMIENTOS DEL DOCUMENTO A CARGAR',
      [ '1. Solo cargar documentos en PDF',
        '2. Solo cargar documentos generado directamente con la plataforma del operador PILA, No se aceptan fotocopias.',
        '3. Solo cargar documentos en fomato indicado por la imagen.'
      ],
      data.urlComplet,
      data.urlAssets
    ).afterClosed().subscribe(confirm => {

    });
  }

  arrayURLSProveedor(Id): URLproveedor {
    let URLproveedor: URLproveedor;
    const dataProveedor = this.proveedor.find(x => x.Id === Id);
    const dataProveedorURLS = this.proveedorURLS.find(x => x.Value === dataProveedor.Value);
    dataProveedorURLS !== undefined ? URLproveedor = dataProveedorURLS : URLproveedor = {Value: '', urlAssets: '', urlComplet: ''};
    return URLproveedor;
  }

  proveedorURLS = [
    {Value: 'ARU', urlAssets: 'assets/images/ImagenFormatoArus.png', urlComplet: 'https://analityco-acceso.s3.amazonaws.com/assets/images/ImagenFormatoArus.png'},
    {Value: 'APT', urlAssets: 'assets/images/ImagenFormatoAportesEnLinea.png', urlComplet: 'https://analityco-acceso.s3.amazonaws.com/assets/images/ImagenFormatoAportesEnLinea.png'},
    {Value: 'MIP', urlAssets: 'assets/images/ImagenFormatoMiPlanilla.png', urlComplet: 'https://analityco-acceso.s3.amazonaws.com/assets/images/ImagenFormatoMiPlanilla.png'},
    {Value: 'ASO', urlAssets: 'assets/images/ImagenFormatoAsopagos.png', urlComplet: 'https://analityco-acceso.s3.amazonaws.com/assets/images/ImagenFormatoAsopagos.png'}
  ];

}

export interface URLproveedor {
  Value: string;
  urlComplet: string;
  urlAssets: string;
}

