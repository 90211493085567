
import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpClient } from '@angular/common/http';
import { Contract } from '../../models/Contract/Contract';
import { Observable } from 'rxjs';
import { PersonalInfoContract } from '../../models/Personal/personalInfoContract';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

    constructor(
        private api: ApiService,
        private _http: HttpClient
    ) { }

    postCreateContract(modelo) {
        return this._http.post(this.api.getUrl(`api/Contract`), modelo);
    }

    postCreateContractPATCH(id, modelo) {
        return this._http.patch(this.api.getUrl(`api/Contract/${id}`), modelo);
    }

    getIsContractPersonalCompanyInfoId(ContracId, personalCompanyInfoId) {
        return this._http.get(this.api.getUrl(`api/Contract/${ContracId}/IsContract/${personalCompanyInfoId}`));
    }

    postMovePersonalInfoContract(ContracId, modelo) {
        return this._http.post(this.api.getUrl(`api/Contract/${ContracId}/MovePersonalInfo`), modelo);
    }

    // GET Contract filter
    getContractFilter(adminInfoId?, isActive?, ids?, self?): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Contract?${adminInfoId !== undefined ? 'adminInfoId='+adminInfoId:''}`));
    }

    // GET:ID Contract
    getContractId(id): Observable<Contract>{
        return this._http.get<Contract>(this.api.getUrl('api/Contract/'+id));
    }

    getProjects( id ): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Contract/${id}/Project`));
    }

    getSubContractor( id ): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Contract/${id}/SubContractor`));
    }

    getContractUsers( id ): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Contract/${id}/Users`));
    }

    getContractPersonalInfo( id, parameter ): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Contract/${id}/PersonalInfo${parameter !== undefined ? '?blackList=true' : ''}`));
    }

    getProyectsJoinCompany( id ): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Project?joinCompanyId=${id}`));
    }

    postContractPersonalInfo(id, model) {
        return this._http.post(this.api.getUrl(`api/Contract/${id}/PersonalInfo`), model);
    }

    postVehicleContract(move, model) {
         return this._http.post(this.api.getUrl(`api/Vehicle/Contract/${move}`), model);
    }

    // get PersonalsInfo Contract
    updatePersonalsInfoContract(contractId, PersonalInfo, personalInfo: PersonalInfoContract) {
        return this._http.post(this.api.getUrl(`api/Contract/${contractId}/PersonalInfo?Id=${PersonalInfo}`), personalInfo);
    }

    getContractType(): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Contract/GetContractType`));
    }

    getRolesCompany(id): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Roles/Company/${id}?contract=true`));
    }

    getProjectStageEtapas(id): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/ProjectStage/Project/${id}`));
    }

    postContractProject(contractId, model, multipleEmployers?) {
        return this._http.post(this.api.getUrl(`api/Contract/${contractId}/Project${multipleEmployers !== undefined ? '?multipleEmployers=true' : ''}`), model);
    }

    postContractProjectId(contractId, Id, model, multipleEmployers, blockPersonalUpdate) {
        return this._http.post(this.api.getUrl(`api/Contract/${contractId}/Project?Id=${Id}${multipleEmployers !== undefined ? '&multipleEmployers=true' : ''}${blockPersonalUpdate !== undefined ? '&blockPersonalUpdate=false' : '&blockPersonalUpdate=true'}`), model);
    }

    postContractRequirement(contractId, model) {
        return this._http.post(this.api.getUrl(`api/Contract/${contractId}/Requirements`), model);
    }

    deleteContractRequirement(contractId) {
        return this._http.delete(this.api.getUrl(`api/Contract/${contractId}/Requirements`));
    }

    getCountContract(companyInfoId): Observable<any>{
        return this._http.get<any>(this.api.getUrl(`api/Contract/CountContract/${companyInfoId}`));
    }

    getFormImageContract(): Observable<any>{
        return this._http.get<any>(this.api.getUrl(`api/FormImage?Select=true`));
    }

    getContractJoinCompanies(companyInfoId): Observable<any>{
        return this._http.get<any>(this.api.getUrl(`api/Company/${companyInfoId}/JoinCompanies`));
    }

    getContractValidateExist(companyInfoId, ContractNumber): Observable<any>{
        return this._http.get<any>(this.api.getUrl(`api/Contract/Exist/${companyInfoId}/${ContractNumber}`));
    }

    patchChangeContract(id, model) {
        return this._http.patch<any>(this.api.getUrl(`api/Contract/${id}`), model);
    }

    deletePersonalInfoOfContract(contractId, PersonalInfo) {
        return this._http.delete(this.api.getUrl(`api/Contract/${contractId}/PersonalInfo/${PersonalInfo}`));
    }

    deleteUserInfoOfContract(contractId, userId) {
        return this._http.delete(this.api.getUrl(`api/Contract/${contractId}/Users/${userId}`));
    }

    deleteProyectInContract(contractId, ProjectId) {
        return this._http.delete(this.api.getUrl(`api/Contract/${contractId}/Project/${ProjectId}`));
    }

    putContractUpdateFile(ContractId, file) {
        return this._http.put(this.api.getUrl(`api/Contract/UpdateFile/${ContractId}`), file);
    }

    getHasContractorProject(projectId, employerId) {
        return this._http.get(this.api.getUrl(`api/Project/HasContractor/${projectId}?employerId=${employerId}`));
    }

    getContractIds(ids) {
        return this._http.get(this.api.getUrl(`api/Contract/ByIds?Ids=${ids}`));
    }

    getPersonalBlackList(DocumentType, DocumentNumber, CompanyInfoId): Observable<boolean>{
    return this._http.get<boolean>(this.api.getUrl(`api/PersonalBlackList/ByDoc?DocumentType=${DocumentType}&DocumentNumber=${DocumentNumber}${CompanyInfoId === ''? '': '&adminInfoId='+CompanyInfoId}`));
    }

    getPositions() {
        return this._http.get(this.api.getUrl(`api/Position`));
    }

    getNotificationsSchedule(adminInfoId): Observable<any> {
        return this._http.get(this.api.getUrl(`api/NotificationSchedule?adminInfoId=${adminInfoId}`));
    }

    getNotificationsScheduleProject(adminInfoId, id?): Observable<any> {
        return this._http.get(this.api.getUrl(`api/Project/Select?companyInfoId=${adminInfoId}&isActive=${id}`));
    }

    postNotificationSchedule(model) {
        return this._http.post(this.api.getUrl(`api/NotificationSchedule`), model);
    }

    putNotificationSchedule(id, model) {
        return this._http.put(this.api.getUrl(`api/NotificationSchedule/${id}`), model);
    }

    putNotificationScheduleProject(model) {
        return this._http.put(this.api.getUrl(`api/NotificationSchedule/Project`), model);
    }


}
