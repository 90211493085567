import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CommonOptions } from '../../../interfaces/interfaces';
import { CommonService } from '../../../services/modules/common.service';
import { SnackBarService } from '../../../services/utils/snackBar.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmployerService } from 'src/app/core/services/modules/employer.service';
import { TypeNovelty } from 'src/app/core/constants/common-constansts';
import { ContratingService } from 'src/app/core/services/modules/contrating.service';

export interface DialogData {
  message: string;
  employer: any;
  period: string
  edit: boolean;
  novelty: any;
  motivos: [];
}

export interface EmployerManger{
  CompanyId: string;
  DocumentNumber: string;
  DocumentType: string;
  Logo: string;
  Name: string;
}


@Component({
  selector: 'app-dialog-create-novelty-dialog',
  templateUrl: './dialog-create-novelty-dialog.component.html',
  styleUrls: ['./dialog-create-novelty-dialog.component.scss']
})
export class DialogCreateNoveltyDialogComponent implements OnInit {
  s3Bucket:string;

  projectLaboral: any= [];
  personalLaboral: any= [];

  controlEmployer = new FormControl();
  controlNoveltySelected = new FormControl();

  formNovelty: FormGroup;
  formNoveltyMotivo: FormGroup;

  documentType: string = '';
  documentNumber: string = '';

  employers: EmployerManger[] = [];
  contracts: any[] = [];
  employerSelected: any [] = [];
  personalSelected: any [] = [];

  noveltyTypes: CommonOptions[] = [];
  companyDocumentType: CommonOptions[] = [];

  maxCaracter: number = 11;

  typeNovelty: TypeNovelty = '';
  // parseInt(this.data.period.substr(-2))
  minDate = new Date(2021, parseInt(this.data.period.substr(-2))-1, 1);
  maxDate = new Date(2021, parseInt(this.data.period.substr(-2)), 0);

  constructor(
    public dialogRef: MatDialogRef<DialogCreateNoveltyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private commonService: CommonService,
    private employerService: EmployerService,
    private snackBService: SnackBarService,
  ) { 
    this.formNovelty = this.fb.group({
      StartDate: new FormControl('',Validators.required),
      Duration: new FormControl('',Validators.required),
      PersonalEmployerInfoId: new FormControl('',Validators.required),
      NoveltyTypeId: new FormControl('',Validators.required),
      ProjectId: new FormControl('',Validators.required),
      PersonalEmployerId: new FormControl('',Validators.required),
    });
    this.formNoveltyMotivo = this.fb.group({
      PersonalEmployerInfoId: new FormControl('',Validators.required),
      NoveltyTypeId: new FormControl('',Validators.required),
      StartDate: new FormControl('',Validators.required),
      EndDate: new FormControl('',Validators.required),
    });
    if(data.edit === true) {
      this.personalSelected.push(
        {
          Logo: data.novelty.PersonalEmployerInfo.Photo,
          Name: data.novelty.PersonalEmployerInfo.Personal.Name,
          LastName: data.novelty.PersonalEmployerInfo.Personal.LastName,
          DocumentType: data.novelty.PersonalEmployerInfo.Personal.DocumentType,
          DocumentNumber: data.novelty.PersonalEmployerInfo.Personal.DocumentNumber
        }
      )
      this.formNovelty.get('StartDate').setValue(data.novelty.StartDate);
      this.formNovelty.get('Duration').setValue(data.novelty.Duration);
      this.formNovelty.get('PersonalEmployerInfoId').setValue(data.novelty.PersonalEmployerInfoId);
      this.formNovelty.get('NoveltyTypeId').setValue(data.novelty.NoveltyTypeId);
    }
    // this.contratingService.getProyectSelect().subscribe((data: any[]) => {
    //   this.projectLaboral = data.filter(x => x.IsActive === true);
    // });

    this.motivos = this.data.motivos.sort((a:any,b:any) => parseInt(a.OrderNum) > parseInt(b.OrderNum) ? 1 :-1);
  }

  motivos: any[] = [];

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    
    setTimeout(() => {
      this.getServices();
      this.getLugarTrabajoNoveltyCreate();
    }, 300);
  }

  getLugarTrabajoNoveltyCreate() {  
    this.employerService.getLugarTrabajoNoveltyCreate(this.data.employer.Id).subscribe(rest => {
      this.projectLaboral = rest;
    });
  }
  


  setValue() {
    if (this.controlEmployer.value !== undefined) {
      this.controlEmployer.setValue('');
      this.employerSelected = [];
      this.personalSelected = [];
      this.documentNumber = '';
      this.documentType = 'CC';
    }
  }

  setValueContract() {
    if (this.controlNoveltySelected.value !== undefined) {
      this.controlNoveltySelected.setValue('');
    }
  }

  setValuePersonal() {
    this.personalSelected = [];
    this.formNovelty.get('PersonalEmployerId').setValue('');
  }

  setValueProject() {
    this.employerSelected = [];
    this.formNovelty.get('ProjectId').setValue('');
  }

  changeType(e) {
    if(e === 'PE') {
      this.maxCaracter = 16;
    } else if(e === 'CC') {
      this.maxCaracter = 11;
    } else {
      this.maxCaracter = 20;
    }
  }

  getPersonalReportProjectLabor() {
    let data = this.projectLaboral.find(x => x.ProjectId === this.formNovelty.get('ProjectId').value);
    if(this.employerSelected.length === 0) {
      this.employerSelected.push(data);
      this.personalLaboral = data.Personal; 
    } else {
      this.employerSelected = [];
      this.employerSelected.push(data);
      this.personalLaboral = data.Personal;
    }
  }

  getPersonalReportPersonalLabor() {
    let data = this.personalLaboral.find(x => x.PersonalEmployerId === this.formNovelty.get('PersonalEmployerId').value);
    if(this.personalSelected.length === 0) {
      this.personalSelected.push(data);
    } else {
      this.personalSelected = [];
      this.personalSelected.push(data);
      
    }
  }

  saveNovelty() {
    let model = {
      PersonalCompanyInfoId: this.personalSelected[0].PersonalCompanyInfoId,
      EmployerInfoId: this.personalSelected[0].EmployerInfoId,
      Position: this.personalSelected[0].Position,
      PlaceOfWorkId: this.employerSelected[0].ProjectId,
      PersonalEmployerId: this.formNovelty.get('PersonalEmployerId').value,
      IsOpen: true,
      StartDate: this.formNoveltyMotivo.get('StartDate').value,
      EndDate: this.formNoveltyMotivo.get('EndDate').value,
      NoveltyTypeId: this.formNoveltyMotivo.get('NoveltyTypeId').value,
    };  
    this.employerService.postNoveltyDialog(model).subscribe((rest: any) => {
      let modelMessage = {
        NoveltyId: rest.Id,
        Comment: this.getNameNoveltySelected()
      }
      this.employerService.postNoveltyMessage(modelMessage).subscribe((restPut:any) => {
        this.dialogRef.close(true); 
      });
    }, error => {
      this.snackBService.openSnackBar(error.error.Error,'X',3000);
    });
  }

  getNameNoveltySelected(): string {
    let name = '';  
      let data = this.motivos.find(x => this.formNoveltyMotivo.get('NoveltyTypeId').value === x.Id);
      data !== undefined ? name = data.Name : '';
    return name;
  }


  valueSelected(e) {
    this.employerSelected = [];
    this.contracts = [];
    this.employers.forEach((employer: any) => {
      employer.CompanyId === e ? this.employerSelected.push(employer): ''
    });
  }

  getServices() {
    this.commonService.getCommonOptions("CompanyDocumentType").subscribe((data: CommonOptions[]) => {
      this.companyDocumentType = data.filter(documents => documents.Value === 'CC' || documents.Value === 'CE' || documents.Value === 'PE')
            .sort((a,b) => a.OrderNum > b.OrderNum ? 1 : -1);
      this.documentType = 'CC';
    });
    this.commonService.getCommonOptions('NoveltyType').subscribe(rest => {
      this.noveltyTypes = rest.filter(novelties => novelties.IsActive === true).sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
      if(this.data.edit === true){
        this.onSelectionChangeNovelty( this.formNovelty.get('NoveltyTypeId').value);
      }
    });
  }


  getPersonalDocument() {
    if(this.documentType === '' || this.documentNumber === '') {
      this.snackBService.openSnackBar('LOS CAMPOS TIPO DE DOCUMENTO Y NUMERO DE DOCUMENTO SON REQUERIDOS', 'X', 4000);
    } else {
      this.employerService.getContractsIndividualByDocuments(this.documentType, this.documentNumber).subscribe((data: any[]) => {
        if(data.length !== 0) {
          let contracts = data.filter(contract => (contract.IndividualContractStage.Description === 'CONTRATADO' 
                                      || contract.IndividualContractStage.Description === 'RETIRADO')
                                      && this.data.employer.CompanyId === contract.personalemployerinfo.Employer.Id );
          if(contracts.length !== 0) {
            this.personalSelected = [];
            this.formNovelty.get('PersonalEmployerInfoId').setValue(contracts[0].personalemployerinfo.Id);
            this.personalSelected.push(
              {
                Logo: contracts[0].personalemployerinfo.Photo,
                Name: contracts[0].personalemployerinfo.Personal.Name,
                LastName: contracts[0].personalemployerinfo.Personal.LastName,
                DocumentType: contracts[0].personalemployerinfo.Personal.DocumentType,
                DocumentNumber: contracts[0].personalemployerinfo.Personal.DocumentNumber
              }
            )
          }
        }
        // if(data !== null) {
        //   this.personalSelected = [];
        //   this.personalSelected.push(data); 
        // }
      });
    }
    
  }

  onSelectionChangeNovelty(e) {
    let selectNoveltyType = this.noveltyTypes.find(x => x.Id === e);
    if(selectNoveltyType !== undefined) {
      if(selectNoveltyType.Value === TypeNovelty.ING) {
        this.typeNovelty = selectNoveltyType.Value;
      } else if(selectNoveltyType.Value === TypeNovelty.RET) {
        this.typeNovelty = selectNoveltyType.Value;
      }else if(selectNoveltyType.Value === TypeNovelty.SLN) {
        this.typeNovelty = selectNoveltyType.Value;
      }else if(selectNoveltyType.Value === TypeNovelty.VACLR) {
        this.typeNovelty = selectNoveltyType.Value;
      }
    }
    // console.log('contractsSelected',this.contractsSelected);
  }

  getDisabled(): boolean {
    let enabled: boolean = false;
    this.personalSelected.length === 1 && this.employerSelected.length === 1
    && this.formNoveltyMotivo.get('NoveltyTypeId').status === 'VALID'
    && this.formNoveltyMotivo.get('StartDate').status === 'VALID'
    && this.formNoveltyMotivo.get('EndDate').status === 'VALID'? enabled = true: enabled = false;
    return enabled;
  }

  getImg(logo) {
    let img = (logo === null || logo === '' || logo === undefined ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg': logo); 
    return img;
  }

}