import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../models/User';
import {CommonOptions} from '../../../interfaces/interfaces';
import {FechasService} from '../../../services/utils/fechas.service';
import {CropperComponent} from 'angular-cropperjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AuthService} from '../../../services/modules/auth.service';
import {EmployerService} from '../../../services/modules/employer.service';
import {CommonService} from '../../../services/modules/common.service';
import {SnackBarService} from '../../../services/utils/snackBar.service';
import {environment} from '../../../../../environments/environment';
import {DialogService} from '../../../services/utils/dialog.service';
import {PersonalService} from '../../../services/modules/personal.service';
import {HttpErrorResponse} from '@angular/common/http';
import {TerminationService} from '../../../services/modules/termination-service';

export interface DialogData {
  docs: any;
  module: string;
  title: string;
  typeToShow: number;
  typeGestor: string;
  adminInfoId: string;
  typeOperator: string;
}

@Component({
  selector: 'app-dialog-carge-masivo',
  templateUrl: './dialog-carge-masivo.component.html',
  styleUrls: ['./dialog-carge-masivo.component.scss']
})
export class DialogCargeMasivoComponent implements OnInit {
  @ViewChild('angularCropper') angularCropper: CropperComponent;

  s3Bucket: string;
  imgCropperResult: string;

  email = '';
  module = '';
  urlFormat = '';
  commentary = '';
  stateString = '';
  typeShowDialog = 0;
  commentaryDocReq = '';
  commentaryDateReq = '';
  commentaryTermination = '';

  cropper = false;
  isProcessing = false;
  showFormatLoad = false;
  showDocumentLoad = false;
  showDateReqSelect = false;
  showEmployerSelect = false;
  showDateReqFormatLoad = false;
  showBtnSaveTermination = false;
  showTerminationFormatLoad = false;
  showTerminationSelectFinalState = false;

  stories: any[] = [];
  documentos: any[] = [];
  storiesFiles: any[] = [];
  storiesFilesDoc: any[] = [];
  storiesFilesDateReq: any[] = [];
  storiesFilesTermination: any[] = [];

  _file: File = null;
  DocsJSON: any = {};
  massUploadInfo: any = {};

  state;
  stateCurrent;
  userAuth: User;
  stateCurrentDocReq;
  stateCurrentDateReq;
  formFile: FormGroup;
  stateCurrentTermination;
  stateCurrentCommentsDocReq;
  stateCurrentCommentsDateReq;

  files: File[] = [];
  typesUpdate = [];
  filesDateReq: File[] = [];
  DateReqToSelect = [];
  filesFormatLoad: File[] = [];
  employerToSelect = [];
  filesTermination: File[] = [];
  filesLoadDateReq: File[] = [];
  TerminationToSelect = [];
  filesLoadDocument: File[] = [];
  requirementToSelect = [];
  proveedor: CommonOptions[] = [];
  lastStages: CommonOptions[] = [];
  filesLoadTermination: File[] = [];
  typesUpdateTermination = [];
  DateReqEmployerToSelect = [];
  TerminationEmployerToSelect = [];

  permissions: string[] = [];
  permissionsCompany: string[] = [];

  date = new Date();
  formData = new FormData();
  controlSearchDateReq = new FormControl();
  controlSearchEmployer = new FormControl();
  controlSearchTypUpdate = new FormControl();
  controlSearchTermination = new FormControl();
  controlSearchDateReqEmployer = new FormControl();
  controlSearchTerminationEmployer = new FormControl();
  controlSearchRequirementSelect = new FormControl();
  controlSearchTypUpdateTermination = new FormControl();
  minDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());

  constructor(
    private dialogRef: MatDialogRef<DialogCargeMasivoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private authService: AuthService,
    private fechasService: FechasService,
    private commonService: CommonService,
    private snackBService: SnackBarService,
    private dialogService: DialogService,
    private personalService: PersonalService,
    private employerService: EmployerService,
    private terminationService: TerminationService
  ) {

    this.userAuth = this.authService.getUser();
    this.permissions = this.userAuth.claims;
    this.email = this.userAuth?.Email !== null && this.userAuth?.Email !== undefined ? this.userAuth?.Email : '';
    this.formFile = this.fb.group({
      File: '',
      Date: ''
    });
    this.typeShowDialog = this.data.typeToShow;
    this.module = this.data.module;
    this.stateCurrent = this.data.docs;
    this.stateCurrentDocReq = this.data.docs;
    this.stateCurrentDateReq = this.data.docs;
    this.stateCurrentCommentsDocReq = this.data.docs;
    this.stateCurrentCommentsDateReq = this.data.docs;
    this.state = this.stateCurrent.State;
    this.getUrlFormat();

    this.commonService.getCommonOptions('PersonalContractStage').subscribe((data: CommonOptions[]) => {
      this.lastStages = data;
      if (this.state !== undefined) {
        const data = this.lastStages.find(x => x.Value === this.state);
        data !== undefined ? this.stateString = data.Description?.toLocaleUpperCase() : '';
      }
    });
    // Fecha state current
    if (this.data.docs.StateId !== null && this.data.docs.StateId !== '' && this.data.docs.StateId !== undefined) {
      this.formFile.get('Date').setValue(this.stateCurrent?.Date);
    }
    if (this.module === 'Personal') {
      this.consumeApis();
    }else if (this.module === 'Termination') {
      this.consumeApisTermination();
    }
  }

  getLoadDocumentsByType(){
    switch (this.typeShowDialog) {
      case 1:
        this.loadDocuments();
        break;
      case 2:
        this.loadDocumentsDocReq();
        break;
      case 3:
        this.loadDocumentsDateReq();
        break;
    }
  }

  getUrlFormat() {
    switch (this.module){
      case 'Personal':
        this.urlFormat = 'https://analityco-acceso.s3.amazonaws.com/assets/files/Form+Agregar+Personal.xlsx';
        switch (this.typeShowDialog){
          case 2:
            this.urlFormat = 'https://analityco-acceso.s3.us-east-1.amazonaws.com/assets/documents/Form%2BActualizar%2BDoc%2BRequisitos.xlsx';
            break;
          case 3:
            this.urlFormat = 'https://analityco-acceso.s3.us-east-1.amazonaws.com/assets/documents/Form%2BActualizar%2BFec%2BRequisitos.xlsx';
            break;
        }
        break;
      case 'Employer':
        this.urlFormat = 'https://analityco-acceso.s3.amazonaws.com/assets/documents/FormatoAgregarEmpresas.xlsx';
        break;
      case 'Termination':
        this.urlFormat = 'https://analityco-acceso.s3.us-east-1.amazonaws.com/assets/documents/Form%252BActualizar%252BEstado.xlsx';
        break;
    }
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    this.getInfoAdmin();
  }

  consumeApis(){
    this.personalService.getMassUploadType().subscribe((data: any) => {
      this.typesUpdate = data;
      this.controlSearchTypUpdate.setValue(this.typeShowDialog);
    });
    this.personalService.getMassUploadInfo(this.typeShowDialog).subscribe((data: any) => {
      this.massUploadInfo = data;
      this.getLoadDocumentsByType();
    });
    this.commonService.getRequirement().subscribe((data: any) => {
      this.requirementToSelect = data.filter(x => x.IsActive === true && (x.ByPersonal === true || x.ByEmployerPersonal === true) &&
        x.ByEntry === false && x.BySurvey === false && x.EnableInputEntry === false &&
        x.BySecuritySocial === false).sort((a, b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ?
        b.OrderNum : Infinity) ? 1 : -1);
      this.DateReqToSelect = data.filter(x => x.IsActive === true && (x.ByPersonal === true ||
        x.ByEmployerPersonal === true) && x.ByEntry === false && x.BySurvey === false && x.EnableInputEntry === false &&
        x.BySecuritySocial === false).sort((a, b) => (a.OrderNum !== null ?
        a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 : -1);
    });
    this.personalService.getEmployerSelect().subscribe((data: any) => {
      this.employerToSelect = data;
      this.DateReqEmployerToSelect = data;
    });
  }

  changeTypeUpdate(type){
    this.typeShowDialog = type;
    this.getLoadDocumentsByType();
    this.consumeApis();
  }

  consumeApisTermination(){
    this.terminationService.getMassUpload().subscribe((data: any) => {
      this.typesUpdateTermination = data;
      this.controlSearchTypUpdateTermination.setValue(Number(this.typeShowDialog));
      this.massUploadInfo = data.find(x => x.Id === Number(this.typeShowDialog));
      this.loadDocumentsTermination();
      this.personalService.getEmployerSelect().subscribe((data: any) => {
        this.TerminationEmployerToSelect = data;
      });

      this.personalService.getMassUploadInfo(this.typeShowDialog).subscribe((data: any) => {
        this.massUploadInfo = data;
      });

      this.terminationService.getTerminationStages().subscribe((data: any) => {
        this.TerminationToSelect = data;
      });
    });
  }

  changeTypeUpdateTermination(event){
  }

  changeRequirementSelect(requirement?) {
    if (!requirement) { return; }

    this.showEmployerSelect = requirement.ByEmployerPersonal === true;
    this.showFormatLoad = requirement.ByEmployerPersonal === true || requirement.ByPersonal === true;
  }

  changeEmployerSelect(requirement){
    this.showFormatLoad = true;
  }

  changeDateReqSelect(requirement?) {
    if (!requirement) { return; }

    this.showDateReqSelect = requirement.ByEmployerPersonal === true;
    this.showDateReqFormatLoad = requirement.ByEmployerPersonal === true || requirement.ByPersonal === true;
  }

  changeTerminationSelect(requirement){
    this.showTerminationFormatLoad = true;
    this.showBtnSaveTermination = true;
  }

  changeDateReqEmployer(requirement){
    this.showDateReqFormatLoad = true;
  }

  changeTerminationEmployer(requirement){
    this.showTerminationFormatLoad = true;
  }

  getInfoAdmin() {
    this.commonService.getUserInfo().subscribe(userinfo => {
      this.permissionsCompany = userinfo.Companies.find(x => x.Id === this.data.adminInfoId).Permissions;
    });
  }

  getDateFormatR(date): string {
    let response = '';
    if (date !== null) {
      response = this.fechasService.getDateFormatR(date);
    }
    return response;
  }

  getDateFormatRMinutes(date): string {
    let response: string;
    response = this.fechasService.getDateFormatRMinutes(date);
    return response;
  }

  openImgVideoUrl(url: any) {
    window.open(this.s3Bucket + url, '_blank');
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  // METODOS PARA CORTAR UNA IMAGEN Y GUARDARLA

  onSelect(event) {
    this.files = [];
    const selectedFiles: FileList = event.addedFiles;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.files.push(selectedFiles[i]);
    }
  }

  onSelectFormatLoad(event) {
    this.filesFormatLoad = [];
    const selectedFiles: FileList = event.addedFiles;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.filesFormatLoad.push(selectedFiles[i]);
    }
    this.showDocumentLoad = true;
  }

  onSelectLoadDocument(event) {
    this.filesLoadDocument = [];
    const selectedFiles: FileList = event.addedFiles;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.filesLoadDocument.push(selectedFiles[i]);
    }
  }

  onSelectDateReq(event) {
    this.filesDateReq = [];
    const selectedFiles: FileList = event.addedFiles;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.filesDateReq.push(selectedFiles[i]);
    }
  }

  onSelectTermination(event) {
    this.filesTermination = [];
    const selectedFiles: FileList = event.addedFiles;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.filesTermination.push(selectedFiles[i]);
    }
    this.showTerminationSelectFinalState = true;
  }

  submitInitDocument(){
    const formData = new FormData();
    formData.append('File', this.files[0]);
    this.isProcessing = true;

    if (this.module === 'Personal') {
      this.personalService.putExtractFromDocument(this.data.adminInfoId, formData).subscribe((data) => {
        this.isProcessing = false;
        this.loadDocuments();
        this.files = [];
        this.snackBService.openSnackBar('Archivo subido correctamente.', 'X', 3000);

      }, (error: HttpErrorResponse) => {
        if (error.status === 420) {
          this.isProcessing = false;
          this.dialogService.openDialogGeneric('Lo sentimos, no se puede iniciar la actualizacion', 'se recomienda revisar el archivo cargado.', 'CANCELAR');
        }
      });
    }else if (this.module === 'Employer') {
      this.employerService.putExtractFromDocumentEmployer(this.data.adminInfoId, formData).subscribe((data) => {
        this.isProcessing = false;
        this.loadDocuments();
        this.files = [];
        this.snackBService.openSnackBar('Archivo subido correctamente.', 'X', 3000);
      }, (error: HttpErrorResponse) => {
        if (error.status === 420) {
          this.isProcessing = false;
          this.dialogService.openDialogGeneric('Lo sentimos, no se puede iniciar la actualizacion', 'se recomienda revisar el archivo cargado.', 'CANCELAR');
        }
      });
    }
  }

  submitInitDocreq(){
    const formData = new FormData();
    formData.append('File', this.filesFormatLoad[0]);

    const formDataDocument = new FormData();
    formDataDocument.append('File', this.filesLoadDocument[0]);
    this.isProcessing = true;

    const requirementId = this.controlSearchRequirementSelect.value.Id;
    const employerId = this.controlSearchEmployer.value;

    const body = {
      TypeId: this.controlSearchTypUpdate.value,
      AdminInfoId: this.data.adminInfoId,
      RequirementId: requirementId,
      EmployerInfoId: employerId,
      Comments: this.commentaryDocReq
    };
    this.personalService.postMassUploadInit(body).subscribe(() => {
      this.personalService.putMassUploadInfoFile(this.massUploadInfo.Id, formData).subscribe(() => {
        this.filesFormatLoad = [];
        this.personalService.putMassUploadPayloadFile(this.massUploadInfo.Id, formDataDocument).subscribe(() => {
          this.personalService.postProcess(this.massUploadInfo.Id).subscribe(() => {
            this.isProcessing = false;
            this.filesLoadDocument = [];
            this.controlSearchRequirementSelect.setValue('');
            this.controlSearchEmployer.setValue('');
            this.snackBService.openSnackBar('Archivo subido correctamente.', 'X', 3000);
            this.loadDocumentsDocReq();
            this.commentaryDocReq = '';
          }); } , (error: HttpErrorResponse) => {
          if (error.status === 420) {
            this.isProcessing = false;
            this.dialogService.openDialogGeneric('Lo sentimos, no se puede iniciar la actualizacion', 'se recomienda revisar el archivo cargado.', 'CANCELAR');
          }
        });
      });
    });
  }

  submitInitFechreq(){
    const formData = new FormData();
    formData.append('File', this.filesDateReq[0]);

    const formDataDocument = new FormData();
    formDataDocument.append('File', this.filesLoadDateReq[0]);
    this.isProcessing = true;

    const requirementId = this.controlSearchDateReq.value.Id;
    const employerId = this.controlSearchDateReqEmployer.value;

    const body = {
      TypeId: this.controlSearchTypUpdate.value,
      AdminInfoId: this.data.adminInfoId,
      RequirementId: requirementId,
      EmployerInfoId: employerId,
      Comments: this.commentaryDateReq
    };

    this.personalService.postMassUploadInit(body).subscribe((data) => {
      this.personalService.putMassUploadInfoFile(this.massUploadInfo.Id, formData).subscribe(() => {
        this.filesDateReq = [];
        this.personalService.postProcess(this.massUploadInfo.Id).subscribe(() => {
            this.isProcessing = false;
            this.filesLoadDateReq = [];
            this.controlSearchDateReq.setValue('');
            this.controlSearchDateReqEmployer.setValue('');
            this.snackBService.openSnackBar('Archivo subido correctamente.', 'X', 3000);
            this.loadDocumentsDateReq();
            this.commentaryDateReq = '';
          }, (error: HttpErrorResponse) => {
          if (error.status === 420) {
            this.isProcessing = false;
            this.dialogService.openDialogGeneric('Lo sentimos, no se puede iniciar la actualizacion', 'se recomienda revisar el archivo cargado.', 'CANCELAR');
          }
        });
      });
    });
  }

  submitInitTermination(){
    const formData = new FormData();
    formData.append('File', this.filesTermination[0]);

    const formDataDocument = new FormData();
    formDataDocument.append('File', this.filesTermination[0]);
    this.isProcessing = true;

    const employerId = this.controlSearchTerminationEmployer.value;
    const Id = this.controlSearchTermination.value.Id;

    const body = {
        TypeId: this.controlSearchTypUpdateTermination.value,
        AdminInfoId: this.data.adminInfoId,
        EmployerInfoId: employerId,
        Comments: this.commentaryTermination,
        NewStateId: Id
      };
    this.personalService.postMassUploadInit(body).subscribe((dataInit: any) => {
        this.personalService.putMassUploadInfoFile(dataInit.Id, formData).subscribe(() => {
            this.filesTermination = [];
            this.personalService.postProcess(dataInit.Id).subscribe(() => {
            this.snackBService.openSnackBar('Archivo subido correctamente.', 'X', 3000);
            this.isProcessing = false;
            this.filesLoadTermination = [];
            this.controlSearchTermination.setValue('');
            this.controlSearchTerminationEmployer.setValue('');
            this.loadDocumentsTermination();
            this.commentaryTermination = '';
            this.showBtnSaveTermination = false;
            });
        }, (error: HttpErrorResponse) => {
          if (error.status === 420) {
            this.isProcessing = false;
            this.dialogService.openDialogGeneric('Lo sentimos, no se puede iniciar la actualizacion', 'se recomienda revisar el archivo cargado.', 'CANCELAR');
          }
        });
      });
  }

  removeFile() {
    this.files = [];
  }

  removeFileFormatLoad() {
    this.filesFormatLoad = [];
  }

  removeFileLoadDocument() {
    this.filesLoadDocument = [];
  }

  removeFileDateReq() {
    this.filesDateReq = [];
  }

  removeFileTermination() {
    this.filesTermination = [];
  }

  loadDocuments(){
    if (this.module === 'Personal') {
      this.personalService.getExtractFromDocument(this.data.adminInfoId).subscribe((data: any) => {
        this.stateCurrent = data;
        this.stories.push(data);
        this.storiesFiles = data.Files;
      });
    }else if (this.module === 'Employer') {
      this.employerService.getExtractFromDocumentEmployer(this.data.adminInfoId).subscribe((data: any) => {
        this.stateCurrent = data;
        this.stories.push(data);
        this.storiesFiles = data.Files;
      });
    }
  }

loadDocumentsDocReq() {
    this.personalService.getMassUploadProcessId(this.massUploadInfo.Id).subscribe((data: any) => {
      this.stateCurrentDocReq = data;
      this.storiesFilesDoc = data.map((story: any) => {
        if (story.InfoDocJson) {
          try {
            const infoDocJson = JSON.parse(story.InfoDocJson);
            if (infoDocJson['DOC-1']) {
              const docInfoArray = infoDocJson['DOC-1'].split('||');
              story.docPath = docInfoArray[0];
              const namePart = docInfoArray.find(part => part.startsWith('name='));
              if (namePart) {
                story.docName = namePart.split('=')[1];
              }
            }
          } catch (error) {
          }
        }
        return story;
      });
    });
  }

  loadDocumentsDateReq(){
      this.personalService.getMassUploadProcessId(this.massUploadInfo.Id).subscribe((data: any) => {
        this.stateCurrentDateReq = data;
        this.storiesFilesDateReq = data.map((story: any) => {
          if (story.InfoDocJson) {
            try {
              const infoDocJson = JSON.parse(story.InfoDocJson);
              if (infoDocJson['DOC-1']) {
                const docInfoArray = infoDocJson['DOC-1'].split('||');
                story.docPath = docInfoArray[0];
                const namePart = docInfoArray.find(part => part.startsWith('name='));
                if (namePart) {
                  story.docName = namePart.split('=')[1];
                }
              }
            } catch (error) {}
          }
          return story;
        });
      });
  }

loadDocumentsTermination(){
  this.personalService.getMassUploadProcessId(this.massUploadInfo.Id).subscribe((data: any) => {
    this.stateCurrentTermination = data;
    this.storiesFilesTermination = data.Files;
  });
}

  exitPermissionCompany(permiso: string): boolean {
    let exit: boolean;
    const permisionsUserInfo  = this.permissionsCompany.find(x => x === permiso);
    permisionsUserInfo !== undefined ? exit = true : exit = false;
    return exit;
  }
}
