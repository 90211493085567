<div class="content-print">
    <div class="horizontal" *ngIf="position === 'horizontal'">
        <div class="first">
            <div class="title">{{'LOS DEMOLEDORES SAS'}}</div>
            <div class="content-horizontal">
                <img src="https://s3.amazonaws.com/analityco-acceso/upload/images/companyInfo/personal/15171/file_0ae4967b-f476-402b-af64-1a069cbec951.jpg" alt="">
                <div class="information">
                    <div class="field">Nombre:</div>
                    <div class="field" style="text-align: center; font-weight: 700;">{{'Alberlo Luis'}}</div>
                    <div class="field">Apellido:</div>
                    <div class="field" style="text-align: center; font-weight: 700;">{{'ARANZAZU DASDASD HERNANDEZ'}}</div>
                    <div class="field">Cargo:</div>
                    <div class="field" style="text-align: center; font-weight: 700;">{{'Director de Obra'}}</div>
                    <div class="field" style="text-align: center; font-weight: 700;">{{'ID'}}: {{'1000000'}}</div>
                </div>
            </div>
        </div>
        <div class="second">
            <div class="terms">
                Esta autorización de ingreso es de uso obligatorio y debe portarse en un lugar visible para poder ingresar al área restringida. En cualquier momento se podrá solicitar y en caso de no portarlo será retirado inmediatamente de las instalaciones
            </div>
            <div class="content-horizontal">
                <div class="information">
                    <div class="information-data">
                        <div class="filed">EPS: <label style="text-align: center; font-weight: 700;">{{'Famisanar'}}</label></div>
                        <div class="filed">ARL: <label style="text-align: center; font-weight: 700;">{{'Positiva Compañía de Seguros'}}</label></div>
                        <div class="filed">RH: <label style="text-align: center; font-weight: 700;">{{'o+'}}</label></div>
                    </div>
                    <div class="information-autoriza">
                        <hr>
                        <div class="filed">Autoriza: </div>
                    </div>
                </div>
                <div class="content-qr">
                    <div class="qr">Espacio para QR</div>
                </div>
            </div>
        </div>
    </div>

    <div class="vertical" *ngIf="position === 'vertical'">
        <div class="first">
            <div class="title">{{'LOS DEMOLEDORES SAS'}}</div>
            <div class="content-vertical">
                <img src="https://s3.amazonaws.com/analityco-acceso/upload/images/companyInfo/personal/15171/file_0ae4967b-f476-402b-af64-1a069cbec951.jpg" alt="">
                <div class="information">
                    <div class="field" style="text-align: center; font-weight: 700;">{{'Alberlo Luis'}} {{'ARANZAZU DASDASD'}}</div>
                    <div class="field" style="text-align: center; font-weight: 700;">{{'Director de Obra'}}</div>
                    <div class="field" style="text-align: center; font-weight: 700;">{{'ID'}}: {{'1000000'}}</div>
                    <div class="field">RH: <label style="text-align: center; font-weight: 700;">{{'o+'}}</label></div>
                </div>
                <div class="information">
                    <div class="information-data">
                        <div class="field">EPS: <label style="text-align: center; font-weight: 700;">{{'Famisanar'}}</label></div>
                        <div class="field">ARL: <label style="text-align: center; font-weight: 700;">{{'Positiva Compañía de Seguros'}}</label></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="second">
            <div class="content-vertical">
                <div class="content-qr">
                    <div class="qr">Espacio para QR</div>
                </div>
                <hr>
                <div class="field">Autoriza: </div>
                <div class="terms">
                    Esta autorización de ingreso es de uso obligatorio y debe portarse en un lugar visible para poder ingresar al área restringida. En cualquier momento se podrá solicitar y en caso de no portarlo será retirado inmediatamente de las instalaciones
                </div>
            </div>
        </div>
    </div>
</div>