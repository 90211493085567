<div class="box">
  <title-dialog [title]="'SANCION'" [color]="'greendAnalityco'"  [width]="'360'"></title-dialog>
  <div class="content-card">
    <div class="content-show">
      <div class="content-dialog">
        <h3>La persona quedara inhabilitada para ingresar a los proyectos.</h3>
      </div>
      <div class="content-list">
        <div class="fields-group">
          <app-select-search *ngIf="data"
                             class="selected"
                             [_dataSource]="datasource"
                             [textInput]="'Motivo o Causal'"
                             [dataShow]="'Name'"
                             [otherFilter]="'Description'"
                             [dataValue]="'Id'"
                             [isCard]="false"
                             [isCardImage]="false"
                             [isDescription]="true"
                             (onSelectionChange)="selected($event)"
                             [siteCtrl]="controlSelected"
                             [inputType]="'text'"
                             [placeholder]="''">
          </app-select-search>
        </div>
        <br>
        <div class="fields-group" >
          <div class="fields-group">
            <div class="labeTimePiecker" style="display: flex; flex-direction: column; align-items: flex-start;">
              <mat-label class="classLabel">INICIO SANCION</mat-label>
              <div class="input-field-medium-date-datetime" matTooltip="INICIO SANCION">
                <mat-form-field appearance="outline">
                  <input matInput type="datetime-local"
                         style="max-width: 149px !important;
                         height: 20px !important;
                         display: flex;
                         flex-direction: column;
                         font-size: 14px !important;
                         letter-spacing: -1px !important;"
                         #inputDateStartDate
                         (change)="changeDate(inputDateStartDate?.value, false)"
                         [min]="minDateNowStart"
                         [value]="dateNowStart"
                         [disabled]="disableStartDate()">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="fields-group">
            <div class="fields-group" style="margin-left: 4px;">
              <div class="labeTimePiecker" style="display: flex; flex-direction: column;">
                <mat-label class="classLabel">FIN SANCION</mat-label>
                <div class="input-field-medium-date-datetime" matTooltip="FIN SANCION">
                  <mat-form-field appearance="outline">
                    <input matInput type="datetime-local"
                           style="max-width: 149px !important;
                           height: 20px !important;
                           display: flex;
                           flex-direction: column;
                           font-size: 14px !important;
                           letter-spacing: -1px !important;"
                           #inputDateFinishDate
                           (change)="changeDate(inputDateFinishDate?.value, true)"
                           [min]="minDateNowFinish"
                           [value]="dateNowFinish">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ngx-dropzone (change)="onSelect($event)" class="dropzone" style="margin-top: 15px">
          <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
        </ngx-dropzone>
        <div class="item" *ngFor="let file of files; let i = index">
          <div *ngIf="file">{{file.name || 'SIN NOMBRE'}}</div>
          <div class="red" *ngIf="!file">{{'Archivo Removido'}}</div>
          <mat-icon *ngIf="file" (click)="removeFile(i)">highlight_off</mat-icon>
        </div>

        <mat-form-field class="input-field">
          <label><div>COMENTARIO</div></label>
          <textarea matInput [(ngModel)]="commentary" maxlength="200" rows="3"></textarea>
          <mat-error>Requerido</mat-error>
        </mat-form-field>

        <div class="empleadorContratista" style="margin-bottom: 2px; margin-top: 15px;">
          <mat-label class="classLabel">EMPLEADOR</mat-label>
        </div>
        <div class="fields-group" style="margin-bottom: 20px;">
          <app-select-search
                             class="selected"
                             [appearance]="'outline'"
                             [_dataSource]="allEmployers" [textInput]="''" [inputType]="'text'"
                             [dataShow]="'Name'" [dataValue]="'Id'" [otherFilter]="'DocumentNumber'" [siteCtrl]="employerControl"
                             [isCard]="true"
                             [_value]="allEmployers[0]?.Id"
                             (onSelectionChange)="onSelectedAllEmployer($event)"
                             [card]="{img: 'Logo', title: 'Name', itemKey: 'DocumentType', itemValue:'DocumentNumber', finalLeft: 'Rol'}"
                             [onErrorUrl]="'employer'">
          </app-select-search>
        </div>

        <div class="actions-dialog" style="margin: 5px;">
          <button mat-button class="btn-cancelar" (click)="close()" >{{data?.textClose}}</button>&nbsp;&nbsp;
          <button mat-button class="btn-generar" (click)="save()">{{data?.textConfirm}}</button>
        </div>

        <div class="subtitle" *ngIf="infoHistory.length !== 0">ACTUALIZACIONES</div>

        <div class="list">

          <div class="documents" *ngFor="let doc of infoHistory; let i = index">
            <div style="width: 100%">
              <div class="state" style="color: #a0a0a0 !important; width: 100% !important;">
                <div class="state-description">
                  <div>{{getDescriptionDoc(doc.Description) || 'Actualizado'}} :</div>
                </div>
                <div class="state-description">
                  <div>{{getDateFormatDateYYYYMMDD(doc?.UpdateDate) || 'Fecha no reportada' }}</div>
                </div>
                <div class="state-description">
                  <div>Por:</div>
                  <mat-icon
                    matTooltipPosition="above"
                    [matTooltip]="'Usuario: ' + (doc?.UserName !== undefined ? doc?.UserName : 'No reportado')"
                  >account_circle</mat-icon>
                </div>
              </div>

              <div class="state" style="color: #a0a0a0 !important; width: 100% !important;" *ngIf="doc?.DocsJSONParsed !== null">
                <div class="state-description">
                  <div>Documento: </div>
                </div>
                <div class="state-description">
                  <div class="truncate-text">{{doc?.DocsJSONParsed?.name || ''}}</div>
                </div>
                <div class="state-description">
                  <div class="operators" style="width: auto !important;">
                    <mat-icon class="mat-icon-show" *ngIf="!isXLSX(doc.DocsJSONParsed.name) && doc?.DocsJSONParsed.name" (click)="openImgVideo(doc.DocsJSONParsed.originalPath)">visibility</mat-icon>
                    <a *ngIf="isXLSX(doc.DocsJSONParsed.name)" [href]="getUrlFile(doc.DocsJSONParsed.originalPath)" target="_blank"><mat-icon class="mat-icon-show">visibility</mat-icon></a>
                  </div>
                </div>
              </div>
              <div class="document-doc" style="margin-top: 4px;">
                <div>Inicio:&nbsp;</div>
                <div class="isComentary">{{getDateFormatDateYYYYMMDD(doc?.StartDate) || ''}}</div>
              </div>
              <div class="document-doc" style="margin-top: 4px;">
                <div>Fin:&nbsp;</div>
                <div class="isComentary">{{getDateFormatDateYYYYMMDD(doc?.EndDate) || ''}}</div>
              </div>
              <div class="document-doc" style="margin-top: 4px;">
                <div>Motivo:&nbsp;</div>
                <div class="isComentary">{{getDescriptionById(doc?.ReasonId) || ''}}</div>
              </div>
              <div class="document-doc" style="margin-top: 4px;">
                <div>Empleador:&nbsp;</div>
                <div class="isComentary">{{getEmployerNameById(doc?.EmployerId) || ''}}</div>
              </div>
              <div class="document-doc" style="margin-top: 4px;" *ngIf="doc?.Observation !== null && doc?.Observation !== ''">
                <div>Observacion:&nbsp;</div>
                <div class="isComentary">{{doc?.Observation || ''}}</div>
              </div>
            </div>
          </div>
          <div class="item" *ngIf="infoHistory.length === 0">{{'0 SANCIONES'}}</div>
        </div>
      </div>
    </div>
  </div>



</div>
