import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Poll } from 'src/app/core/interfaces/interfaces';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';

export interface DialogData {
  poll: Poll;
  answers: any; 
  answer: boolean;
}
export interface Answers {
  QuestionId: number,
  answer: any,
  MultipleAnswers: string[]
}

@Component({
  selector: 'app-dialog-poll',
  templateUrl: './dialog-poll.component.html',
  styleUrls: ['./dialog-poll.component.scss']
})
export class DialogPollComponent implements OnInit {

  form = [];
  answer: boolean = false;
  answers: Answers[] = []
  constructor(
    public dialogRef: MatDialogRef<DialogPollComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBService: SnackBarService,
  ) {
    if(data.answer === true) {
      this.answer = true;
      this.data.answers.forEach(ans => {
        this.answers.push({
          QuestionId: ans.QuestionId,
          answer: this.typeAnswer(ans.QuestionTypeName, ans.Answer)  ,
          MultipleAnswers: ans.MultipleAnswers
        });
      });
      // console.log('answers',this.answers);
      // console.log('poll',data.poll);
      // console.log('poll',data.answers);
    } else {
      let arraySelectedNinguno = data.poll.Questions.find(x => x.QuestionType === 2);
      if(arraySelectedNinguno !== undefined) {
        arraySelectedNinguno.choices.forEach(choice => {
          if(choice === 'Ninguna') {
            this.answers.push({QuestionId: arraySelectedNinguno.Id, MultipleAnswers: ['Ninguna'], answer: null });
          }
        });
      }
    }
  }

  ngOnInit(): void { }

  typeAnswer(Type, answer): any {
    let dato = ''
    if(Type === 'YesNo') {
      if(answer === 0) {
        dato = 'No'
      } else {
        dato = 'Si'
      }
    } else if(Type === 'Integer') {
      dato = answer;
    }
    // console.log('dato',dato);
    return dato;
  }

  savePoll() {
    // console.log('this.answers',this.answers);
    // console.log('this.data.poll',this.data.poll);
    if(this.answers.length === this.data.poll.Questions.length) {
      this.dialogRef.close(this.answers);
    } else {
      this.snackBService.openSnackBar('Por favor completar todos los campos', 'X', 4000);
    }
  }


  pushToArrayString(Id, value) {
    if(this.data.answer === false) {
      // console.log(value);
      if(value === 'Ninguna' || value === 'Voy a trabajar en casa') {
        if(this.answers.length === 0) {
          this.answers.push({QuestionId: Id, MultipleAnswers: [value], answer: null });
        } else {
          let exit = this.answers.find(ans => ans.QuestionId === Id);
          if(exit !== undefined) {
            this.answers.forEach(ans => {
              if(ans.QuestionId === Id) {
                ans.MultipleAnswers = [value];
              }
            });
          } else {
            this.answers.push({QuestionId: Id, MultipleAnswers: [value], answer: null });
          }
        }
      } else {
        if(!this.existQuestionAnswer(Id)) {
          this.answers.push({QuestionId: Id, MultipleAnswers: [value], answer: null });
        } else {
          this.answers.forEach(ans => {
            if(ans.QuestionId === Id && ans.MultipleAnswers.includes(value)) {
              ans.MultipleAnswers.forEach((multAns, index) => {
                if(multAns === value) {
                  ans.MultipleAnswers.splice(index, 1);
                }
              });
            } else if(ans.QuestionId === Id && !ans.MultipleAnswers.includes(value)) {
              ans.MultipleAnswers.forEach((multAns, index) => {
                if(multAns === 'Ninguna' || multAns === 'Voy a trabajar en casa'){
                  ans.MultipleAnswers.splice(index, 1);
                }
              });
              ans.MultipleAnswers.push(value);
            }
          });
        }
      }
    }
    // console.log('this.answers',this.answers);
  }

  pushToAnswerSONO(Id, value) {
    if(this.data.answer === false) {
      if(!this.existQuestionAnswer(Id)) {
        this.answers.push({QuestionId: Id, MultipleAnswers: null, answer: value });
      } else {
        this.answers.forEach(ans => {
          if(ans.QuestionId === Id) {
            ans.answer = value;
          }
        })
      }
    }
  }

  changeNumberInput(Id, value) {
    if(this.data.answer === false) {
      if(!this.existQuestionAnswer(Id)) {
        this.answers.push({QuestionId: Id, MultipleAnswers: null, answer: value });
      } else {
        this.answers.forEach(ans => {
          if(ans.QuestionId === Id) {
            ans.answer = parseInt(value);
          }
        })
      }
    }
    // console.log('this.answers',this.answers);
  }

  getValueNumber(Id): any {
    let value = '';
    let answer = this.answers.find(x => x.QuestionId === Id);
    answer !== undefined ? value = answer.answer : value = '';
    return value;
  }

  existQuestionAnswer(Id): boolean {
    let exit: boolean = false;
    let answer = this.answers.find(x => x.QuestionId === Id);
    answer !== undefined ? exit = true: exit = false;
    return exit;
  }

  existQuestionAnswerArray(Id, value): boolean {
    let exit: boolean = false;
    let answer = this.answers.find(x => x.QuestionId === Id && x.MultipleAnswers.includes(value));
    answer !== undefined ? exit = true: exit = false;
    return exit;
  }

  existQuestionAnswerSINO(Id, value): boolean {
    let exit: boolean = false;
    let answer = this.answers.find(x => x.QuestionId === Id && x.answer === value);
    answer !== undefined ? exit = true: exit = false;
    return exit;
  }


  cancelar() {
    this.dialogRef.close(undefined);
  }

}
