<div class="search-paginator" [ngClass]="production === false ? 'color-search-paginator-pruebas': 'color-search-paginator-pro'">
    <div class="search">
         <input class="input-search" type="text"placeholder="Buscar..."(keyup)="searchLiquidacion($event.target.value)">
    </div>
    <mat-paginator #paginatorLiquidaciones [pageSize]="20" [pageSizeOptions]="[7, 15, 20]" showFirstLastButtons></mat-paginator>
    
</div>
<div class="actions" [ngClass]="production === false ? 'color-actions-pruebas': 'color-actions-pro'">
    <div style="color: white; margin-right: 5px;">Periodo de trabajo:</div>
    <app-select-search class="select-component" style="width: 100px;"
                    [_dataSource]="dataRange" [textInput]="''" [inputType]="'text'" 
                    [dataShow]="'dateList'" [dataValue]="'dateList'" [siteCtrl]="controlFormControl"
                    (onSelectionChange)="selectedPeriod($event)">
    </app-select-search>
    <div class="actionFilters">
        <button mat-button (click)="close()" class="btn-close" matTooltip="Cerrar pestaña" type="button">Cerrar Pestaña</button>
    </div>
</div>

<div class="plantilla" [ngClass]="production === false ? 'planilla-pruebas': 'planilla-prod'">
    <div class="mat-elevation-z8">
            <table mat-table [dataSource]="tableDataSourceLiquidaciones">
                <ng-container matColumnDef="DocumentType">
                    <th mat-header-cell class="header-table" *matHeaderCellDef>TIPO DE DOCUMENTO</th>
                    <td mat-cell class="header-table" *matCellDef="let employer" > 
                        {{employer.DocumentType}}
                    </td>
                </ng-container> 
                <ng-container matColumnDef="DocumentNumber">
                    <th mat-header-cell class="header-table" *matHeaderCellDef>DOCUMENTO</th>
                    <td mat-cell class="header-table" *matCellDef="let employer" > 
                        {{employer.DocumentNumber}}
                    </td>
                </ng-container> 
                <ng-container matColumnDef="Name">
                    <th mat-header-cell class="header-table" *matHeaderCellDef>NOMBRE</th>
                    <td mat-cell class="textAlingLeft" *matCellDef="let employer" > 
                        <div class="iconIsManaged">
                            <svg xmlns="htp://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 172 172" style=" fill:#000000;">
                                <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="none" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                                    <path d="M0,172v-172h172v172z" fill="none" stroke="none" stroke-width="1"></path>
                                    <g class="iconSVGColorDark" stroke="none" stroke-width="1">
                                        <path d="M158.66427,100.964l-24.58453,-22.446l12.40693,-30.88547c0.76827,-1.91493 0.45293,-4.09933 -0.83707,-5.7104c-1.29,-1.61107 -3.3368,-2.43093 -5.3836,-2.08693l-32.8692,5.22307l-16.4088,-28.95907c-2.03533,-3.5948 -7.94067,-3.5948 -9.976,0l-16.4088,28.9648l-32.87493,-5.2288c-2.04107,-0.33253 -4.0936,0.47587 -5.3836,2.08693c-1.29,1.61107 -1.60533,3.79547 -0.83707,5.7104l12.40693,30.88547l-24.5788,22.446c-1.52507,1.38747 -2.1844,3.4916 -1.72573,5.50973c0.4644,2.0124 1.96653,3.62347 3.94453,4.214l31.8888,9.55173l2.22453,33.2132c0.1376,2.05827 1.37027,3.89293 3.22787,4.78733c1.86907,0.90013 4.06493,0.7224 5.75627,-0.45867l27.348,-18.9716l27.35373,18.97733c0.97467,0.67653 2.12133,1.02627 3.268,1.02627c0.84853,0 1.69707,-0.18347 2.48827,-0.5676c1.8576,-0.8944 3.096,-2.72907 3.22787,-4.78733l2.22453,-33.2132l31.8888,-9.55173c1.978,-0.59627 3.48013,-2.20733 3.94453,-4.214c0.45293,-2.01813 -0.2064,-4.12227 -1.73147,-5.51547z"></path></g><g fill="#ffffff" stroke="none" stroke-width="1"><path d="M107.435,121.19l-6.72,-18.37h-29.48l-6.63,18.37h-9.57l26.88,-70.38h8.12l26.93,70.38zM85.975,62.26l-11.94,32.92h23.93z"></path>
                                    </g>
                                </g>
                            </svg>
                            <div style="margin-left: 5px;">{{employer.Name}}</div>
                        </div>
                        
                    </td>
                </ng-container> 
                <ng-container matColumnDef="state">
                    <th mat-header-cell class="header-table" *matHeaderCellDef>ESTADO</th>
                    <td mat-cell class="header-table" *matCellDef="let employer" > 
                        {{(employer.State === 'Empty' ? 'SIN LIQUIDACIÓN' : (employer.State === 'Liquidated' ? 'LIQUIDADA': 'PAGADA'))}}
                    </td>
                </ng-container> 
                <ng-container matColumnDef="user">
                    <th mat-header-cell class="header-table" *matHeaderCellDef>USUARIO</th>
                    <td mat-cell class="header-table " *matCellDef="let employer" > 
                        <mat-icon class="icon-user" matTooltip="{{employer.Email || 'N/A'}}" matTooltipPosition="above">contact_support</mat-icon>
                    </td>
                </ng-container> 
                <ng-container matColumnDef="actions">
                    <th mat-header-cell class="header-table" *matHeaderCellDef>
                        <div class="btn-actions">
                            <a class="button-success-dark" >LIQUIDAR PLANILLAS
                            </a>&nbsp;
                            <a class="button-warn-dark">BORRAR LIQUIDACIONES
                            </a>&nbsp;
                            <a class="button-info-dark">PLANILLAS PAGADAS
                            </a>
                        </div>
                    </th>
                    <td mat-cell class="header-table" *matCellDef="let employer" > 
                        <div class="btn-actions">
                            <!-- <a class="button-success" *ngIf="employer.State === 'Empty'" (click)="liquidar(employer.CompanyId)">LIQUIDAR PLANILLA</a>&nbsp;
                            <a class="button-default" *ngIf="(employer.State === 'Liquidated' || employer.State === 'Pagado') && employer.State !== 'Empty'">LIQUIDAR PLANILLA</a>&nbsp; -->
                            <a class="button-success" *ngIf="employer.State === 'Empty'" (click)="liquidar(employer.CompanyId)">LIQUIDAR PLANILLA</a>
                            <a class="button-default" *ngIf="employer.State === 'Liquidated' || employer.State === 'Pagado'">LIQUIDAR PLANILLA</a>&nbsp;
                            <a class="button-warn" *ngIf="employer.State !== 'Pagado'">BORRAR LIQUIDACIÓN</a>&nbsp;
                            <a class="button-default" *ngIf="employer.State === 'Pagado'">BORRAR LIQUIDACIÓN</a>&nbsp;
                            <a class="button-info" *ngIf="employer.State !== 'Pagado' && (employer.State === 'Liquidated' || employer.State === 'Empty')" (click)="pagarPlanilla(employer.CompanyId)">PLANILLA PAGADA</a>&nbsp;
                            <a class="button-default" *ngIf="employer.State === 'Pagado'">PLANILLA PAGADA</a>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-icon [ngClass]="employer.State === 'Liquidated' || employer.State === 'Pagado' ? 'icon-dowload': 'icon-dowload-disabled'" 
                                    (click)="openFileUrlFile(employer.url,employer.State)">file_download</mat-icon>
                        </div>
                    </td>
                </ng-container> 
                
                <tr mat-header-row *matHeaderRowDef="columnsLiquidaciones"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsLiquidaciones;"></tr>
                
            </table>
            <!-- <tr class="mat-row" *ngIf="novelties.length === 0" >
                <td class="mat-cell" colspan="4">No hay novedades</td>
            </tr> -->
    </div>
</div>