<div class="content-dialog">
    <h3>{{'DONDE TRABAJA HOY?' | uppercase}}</h3>
    <h3>{{'Seleccione un proyecto' || 'Sin MENSAJE'}}</h3>
    <div *ngFor="let projects of data">
        <div class="card" *ngFor="let proj of projects.Projects" (click)="onNoClick(projects.PersonalCompanyInfoId, projects.PrivacyPolicies, projects.CompanyInfoId)">
          <div class="card-img">
              <img class="img" src="{{s3Bucket+proj.Logo}}" alt="Photo of a Shiba Inu">
          </div>
          <div class="card-content">
              <div class="fields">
                  <h3 class="field">{{proj.ProjectName || 'N/A'}}</h3>
              </div>
          </div>
      </div>
    </div>
</div>