import { Injectable } from '@angular/core';
import { Menu } from '../../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})

export class MenuService {

  menu: Menu[] = [
    // {
    //   title: 'INICIO',
    //   url: '#!/',
    //   icon: 'home',
    //   rolKey: 'dashboard',
    //   urlAngularJS: true
    // },
    {
      title: 'INICIO',
      url: '/admin/dashboard',
      icon: 'home',
      rolKey: 'dashboard',
      urlAngularJS: false
    },
    {
      title: 'MINUTAS',
      url: '/admin/minutas',
      icon: 'find_in_page',
      rolKey: 'memorandums.view',
      urlAngularJS: false,
      model: 'minutas'
    },
    {
      title: 'ADMINISTRADOR',
      url: '/admin/companies',
      icon: 'account_balance',
      rolKey: 'companies.view',
      urlAngularJS: false,
      model: 'administradora'
    },
    {
      title: 'EMPRESAS',
      url: '/admin/employers',
      icon: 'business',
      rolKey: 'employers.view',
      urlAngularJS: false,
      model: 'employer'
    },
    // {
    //   title: 'PROYECTOS',
    //   url: '#!/project',
    //   icon: 'location_city',
    //   rolKey: 'projects.view',
    //   urlAngularJS: true
    // },
    {
      title: 'PROYECTOS',
      url: '/admin/project',
      icon: 'location_city',
      rolKey: 'projects.view',
      urlAngularJS: false,
      model: 'project'
    },
    {
      title: 'CARNETS',
      url: '/admin/carnets',
      icon: 'badge',
      rolKey: 'carnets.view',
      urlAngularJS: false
    },
    // {
    //   title: 'CONTRATOS Y REGISTROS',
    //   url: '#!/contract',
    //   icon: 'gavel',
    //   rolKey: 'contracts.view',
    //   urlAngularJS: true
    // },
    {
      title: 'CONTRATOS',
      url: '/admin/contract',
      icon: 'gavel',
      rolKey: 'contracts.view',
      urlAngularJS: false,
      model: 'contract'
    },
    {
      title: 'PERSONAL',
      url: '/admin/personal',
      icon: 'person',
      rolKey: 'personals.view',
      urlAngularJS: false,
      model: 'personal'
    },
    // {
    //   title: 'SOLICITUDES',
    //   url: '/admin/request',
    //   icon: 'handshake',
    //   rolKey: 'contractstages.view',
    //   urlAngularJS: false,
    //   toggleSubMenu: true,
    //   withSubMenu: true,
    //   subMenu: [{title: 'INGRESO PERSONAL', url: '/admin/request/request-list'},
    //             {title: 'RETIROS PERSONAL', url: '/admin/request/termination-list'}
    //           ]
    // },
    {
      title: 'INGRESOS',
      url: '/admin/request',
      icon: 'how_to_reg',
      rolKey: 'contractstages.view',
      urlAngularJS: false,
      model: 'ingresos'
    },
    {
      title: 'RETIROS',
      url: '/admin/termination',
      icon: 'person_remove',
      rolKey: 'personaltermination.view',
      urlAngularJS: false,
      model: 'termination'
    },
    {     
      title: 'CONTRATOS INDIVIDUALES',
      url: '/admin/contracting',
      icon: 'folder_shared',
      rolKey: 'contracting.view',
      urlAngularJS: false
    },
    {
      title: 'VEHICULOS',
      url: '/admin/vehicle',
      icon: 'time_to_leave',
      rolKey: 'vehicles.view',
      urlAngularJS: false
    },
    {
      title: 'USUARIOS',
      url: '/admin/profile',
      icon: 'account_circle',
      rolKey: 'users.view',
      urlAngularJS: false,
      model: 'profile'
    },
    {
      title: 'INFORMES',
      url: '/admin/info',
      icon: 'text_snippet',
      rolKey: 'informs.view',
      urlAngularJS: false
    },
    {
      title: 'REFERENCIAS',
      url: '/admin/references',
      icon: 'border_all',
      rolKey: 'superuser',
      urlAngularJS: false,
      toggleSubMenu: false,
      withSubMenu: true,
      subMenu: [{title: 'Opciones comunes', url: '/admin/references/commonOptions-list'},
                {title: 'Requisitos de acceso', url: '/admin/references/requirement-list'},
                {title: 'Imagenes de formularios', url: '/admin/references/form-image-list'},
                {title: 'Administradoras Sistema de Seguridad Social y Parafiscales', url: '/admin/references/security-reference-list'},
                {title: 'Actividades Económicas', url: '/admin/references/economicActivity-list'},
                {title: 'Ocupaciones', url: '/admin/references/occupation-list'},
                {title: 'Insumos', url: 'null'},
                {title: 'Grupos insumos', url: '/admin/references/supplyGroup'},
                {title: 'Listas insumos', url: '/admin/references/supplyList'},
                {title: 'Rango de fechas para pago de seguridad social', url: '/admin/references/social-sec-range-list'},
              ]
    },
    {
      title: 'ADMINISTRACÓN',
      url: '/admin/management',
      icon: 'build',
      rolKey: 'superuser',
      urlAngularJS: false,
      toggleSubMenu: false,
      withSubMenu: true,
      subMenu: [{title: 'Perfiles', url: '/admin/management/perfil'}
                // ,{title: 'Permisos', url: '/admin/management/permision'}
      ]
    },
    
    
  ]

  constructor() {}

}

