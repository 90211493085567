<!-- CARD DEFAULT NO PERMISO -->
<div class="box" *ngIf="configCard.typeCard === 'default'">
    <div class="header">
        <div class="content" [ngClass]="configCard.color || 'primary'">{{configCard.title || 'N/A'}}</div>
    </div>
    <div class="empty-message">
        <mat-icon>help</mat-icon>
        <div>{{notListDescription || 'N/A'}}</div>
    </div>
        <!-- Lista planilla -->
</div>
<!-- CARD INFORMACION EMPRESA ADMINISTRADORA -->    
<div class="box" *ngIf="configCard.typeCard === 'information'">
    <div class="header">
        <div class="content" [ngClass]="configCard.color || 'primary'">{{configCard.title || 'N/A'}}</div>
    </div>
    <div class="content-card">
        <!-- TITLE AND PHOTO -->
        <img src="{{s3Bucket+photo}}" alt="">
        <div><strong>{{title || 'Sin Title'}}</strong></div>
        <div class="card-description">
            <!-- DESCRIPTION 2 ITEMS -->
            <div><label><strong>{{description.key1 || ''}}</strong>{{description.item1 || ''}}</label></div>
            <div><label><strong>{{description.key2 || ''}}</strong>{{description.item2 || ''}}</label></div>
            <!-- SUBTITLE 3 ITEMS -->
            <div class="sub-header">
                <div class="sub-content">{{subDescription.title || 'N/A'}}</div>
            </div>
            <div><label><strong><mat-icon>{{subDescription.icon1 || ''}}</mat-icon></strong>{{subDescription.item1 || 'N/A'}}</label></div>
            <div><label><strong><mat-icon>{{subDescription.icon2 || ''}}</mat-icon></strong>{{subDescription.item2 || 'N/A'}}</label></div>
            <div><label><strong><mat-icon>{{subDescription.icon3 || ''}}</mat-icon></strong>{{subDescription.item3 || 'N/A'}}</label></div>
            <!--SUB-SUBTITLE 3 ITEMS -->
            <div class="sub-header">
                <div class="sub-content">{{subDescriptionEnd.title || 'N/A'}}</div>
            </div>
            <div><label><strong><mat-icon>{{subDescriptionEnd.icon1 || ''}}</mat-icon></strong>{{subDescriptionEnd.item1 || 'N/A'}}</label></div>
            <div><label><strong><mat-icon>{{subDescriptionEnd.icon2 || ''}}</mat-icon></strong>{{subDescriptionEnd.item2 || 'N/A'}}</label></div>
            <div><label><strong><mat-icon>{{subDescriptionEnd.icon3 || ''}}</mat-icon></strong>{{subDescriptionEnd.item3 || 'N/A'}}</label></div>
        </div>
    </div>
</div>



