<div class="map-actions">
  <div id='map-drag'>
  </div>
  <div class="actions-dialog">
  <button   
            mat-button class="btn-cancelar" 
            [mat-dialog-close]="data" 
            (click)="onNoClick()">{{'CANCELAR'}}</button>&nbsp;&nbsp;
  <button   
            mat-button class="btn-generar" 
            (click)="save()"
            [disabled]="changeLocations === undefined"
            >{{'GUARDAR'}}</button>
  </div>
</div>