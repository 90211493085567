<div class="btns-actions">
  <div class="btn-save">
      <button mat-mini-fab color="primary" matTooltip="CERRAR" mat-dialog-close>
        <mat-icon>cancel</mat-icon>
      </button>
  </div>
</div>
<mat-dialog-content class="content-img">
    <img *ngIf="data.image && !urlPdf" [src]="getUrlImg(data.image)" alt="">
    <object *ngIf="pdf" [attr.data]="pdf" type='application/pdf' width='60%' height="100%"></object>
    <mat-video *ngIf="data.video" [src]="getUrlImg(data.video)" [autoplay]="true"></mat-video>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>CERRAR</button>
</mat-dialog-actions>
