import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import * as mapbox from 'mapbox-gl';

import { environment } from 'src/environments/environment';

export interface GeoFenceJson {
  coordinates: [];
  type: string;
}

@Component({
  selector: 'app-mapbox-view',
  templateUrl: './mapbox-view.component.html',
  styleUrls: ['./mapbox-view.component.scss']
})
export class MapboxViewComponent implements OnInit {

  geoFenceJson;
  @Input() set _geoFenceJson(value: any) {
    if(value !== undefined && value !== '') {
      this.geoFenceJson = value;
      if(this.Type === 'create') {
        this.mapCreate();
      }
    }
  }

  @Input() permissionEdit: boolean = false;

  Address = '';
  @Input() set _Address(value: any) {
    if(value !== undefined && value !== '') {
      this.Address = value;
    }
  }

  City = '';
  @Input() set _City(value: any) {
    if(value !== undefined && value !== '') {
      this.City = value;
    }
  }

  Type = '';
  @Input() set _Type(value: any) {
    if(value !== undefined && value !== '') {
      this.Type = value;
    }
  }

  @Output() clickOpcion1 = new EventEmitter();

  map: mapbox.Map;
  mapEdit: mapbox.Map;
  mapCreateMap: mapbox.Map;

  constructor() { 
  }


  ngAfterViewInit(): void {
    if(this.Type === 'create') {
      this.mapCreate();
    } else {
      this.mapShow();
      this.mapEditShow();
    }
    
  }

  ngOnInit(): void {}

  clickOpc1(){
    this.clickOpcion1.emit();
  }

  

  mapShow() {
    this.map = new mapboxgl.Map({
      accessToken: environment.api.MapboxAccessToken,
      center: [-74.080833, 4.598889],
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v10',
      zoom: 3
    });
    
    // this.map.getSource('main').setData(this.geoFenceJson);
    // this.map.resize();
    // this.map.fitBounds({ padding: 8, speed: 3.5, offset: [0, 16] });
    
    this.map.on('load', () => {
      // Add a data source containing GeoJSON data.
      this.map.addSource('main', {
        type: 'geojson',
        data: 
      this.geoFenceJson});
      
      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        'id': 'main',
        'type': 'fill',
        'source': 'main', // reference the data source
        'layout': {},
        'paint': {
        'fill-color': '#0080ff', // blue color fill
        'fill-opacity': 0.5
        }
      });
      // Add a black outline around the polygon.
      this.map.addLayer({
        'id': 'outline',
        'type': 'line',
        'source': 'main',
        'layout': {},
        'paint': {
        'line-color': '#5c5c5c',
        'line-width': 1
        }
      });
    });
  }


  mapEditShow() {
    this.mapEdit = new mapboxgl.Map({
      accessToken: environment.api.MapboxAccessToken,
      center: [-74.080833, 4.598889],
      container: 'mapEdit',
      style: 'mapbox://styles/mapbox/streets-v10',
      zoom: 3
    });
    
    // this.map.getSource('main').setData(this.geoFenceJson);
    // this.map.resize();
    // this.map.fitBounds({ padding: 8, speed: 3.5, offset: [0, 16] });
    
    this.mapEdit.on('load', () => {
      // Add a data source containing GeoJSON data.
      this.mapEdit.addSource('mainEdit', {
        type: 'geojson',
        data:  this.geoFenceJson
      });
      
      // Add a new layer to visualize the polygon.
      this.mapEdit.addLayer({
        'id': 'mainEdit',
        'type': 'fill',
        'source': 'mainEdit', // reference the data source
        'layout': {},
        'paint': {
        'fill-color': '#0080ff', // blue color fill
        'fill-opacity': 0.5
        }
      });
      // Add a black outline around the polygon.
      this.mapEdit.addLayer({
        'id': 'outline',
        'type': 'line',
        'source': 'mainEdit',
        'layout': {},
        'paint': {
        'line-color': '#5c5c5c',
        'line-width': 1
        }
      });
    });
  }

  mapCreate() {
    this.mapCreateMap = new mapboxgl.Map({
      accessToken: environment.api.MapboxAccessToken,
      center: [-74.1574237, 4.6455668],
      container: 'mapCreate',
      style: 'mapbox://styles/mapbox/streets-v10',
      zoom: 3
    });
    
    // this.map.getSource('main').setData(this.geoFenceJson);
    // this.map.resize();
    // this.map.fitBounds({ padding: 8, speed: 3.5, offset: [0, 16] });
    
    this.mapCreateMap.on('load', () => {
      // Add a data source containing GeoJSON data.
      this.mapCreateMap.addSource('mapCreate', {
        type: 'geojson',
        data:  this.geoFenceJson
      });
      
      // Add a new layer to visualize the polygon.
      this.mapCreateMap.addLayer({
        'id': 'mapCreate',
        'type': 'fill',
        'source': 'mapCreate', // reference the data source
        'layout': {},
        'paint': {
        'fill-color': '#0080ff', // blue color fill
        'fill-opacity': 0.5
        }
      });
      // Add a black outline around the polygon.
      this.mapCreateMap.addLayer({
        'id': 'outline',
        'type': 'line',
        'source': 'mapCreate',
        'layout': {},
        'paint': {
        'line-color': '#5c5c5c',
        'line-width': 1
        }
      });
    });
  }


}
