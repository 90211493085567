<div class="box">
  <div class="header">
    <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'REGISTRO DE CARGA' || 'N/A'}}</div>
  </div>

  <div class="content-card" autocomplete="off">
    <form [formGroup]="formDatosLoadRecord">
      <div class="custom-field">
        <label class="custom-label"><strong>REMISION O FACTURA</strong></label>
        <input matInput class="custom-input" type="text" formControlName="RemittanceOrInvoice">
      </div>

      <app-select-search class="select-component-medium" style="margin-top: 12px;"
                         [appearance]="'outline'"
                         [_dataSource]="AllCompanyOrSupplier" [textInput]="'EMPRESA O PROVEEDOR'" [inputType]="'text'"
                         [dataShow]="'Name'" [dataValue]="'Id'" [otherFilter]="'DocumentNumber'" [siteCtrl]="companyOrSupplier"
                         [onErrorUrl]="'employer'"
      >
      </app-select-search>

      <mat-form-field class="chip-list" style="margin-top: -10px !important;"  appearance="outline">
        <mat-label class="mat-label-insumos">INSUMOS</mat-label>
        <mat-chip-list #chipListSupply aria-label="Insumos seleccionados">
          <mat-chip
            *ngFor="let values of viewValuesSupply"
            [selectable]="true"
            [removable]="true"
            (removed)="removeSupply(values)">
            {{values.Name}}
            <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="+Seleccionar"
            #InputControlSupply
            [formControl]="controlSupply"
            [matAutocomplete]="autoSupply"
            [matChipInputFor]="chipListSupply"
            (matChipInputTokenEnd)="addActivity($event)"
          >
        </mat-chip-list>
        <mat-autocomplete #autoSupply="matAutocomplete" (optionSelected)="selectedSupply($event)">
          <mat-option *ngFor="let item of SupplyObs" [value]="item.Id">
            {{item.Name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div class="doc-icon-input">
        <mat-form-field class="select-field-full" appearance="outline">
          <label><strong>OBSERVACIÓN</strong></label>
          <textarea matInput type="text" formControlName="VaccineObservations" rows="5"></textarea>
        </mat-form-field>
      </div>
    </form>
  </div>

  <div class="btn-actions"style="margin: 3px; margin-top: -10px;">
    <button mat-button class="btn-cancelar" (click)="close()">{{'CANCELAR'}}</button>&nbsp;&nbsp;
    <button mat-button class="btn-generar" (click)="saveEditMinutes()">{{'GUARDAR'}}</button>
  </div>

</div>
