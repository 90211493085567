import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';
import { EmployerService } from 'src/app/core/services/modules/employer.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from 'src/app/core/services/utils/dialog.service';
import { ContractService } from 'src/app/core/services/modules/contract.service';
import { FechasService } from 'src/app/core/services/utils/fechas.service';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { CardList } from 'src/app/core/interfaces/interfaces';

export interface DataShowVincularContracts {
  personalId: number;
  employerId: string;
}

export interface DialogData {
  personalCompanyInfoId: any;
  data: any;
  proyects: any;
  dataModuleRequest: DataShowVincularContracts;
  isFromVehicle: boolean;
  adminInfoId: any;
}

@Component({
  selector: 'app-dialog-person-associate-contracts',
  templateUrl: './dialog-person-associate-contracts.component.html',
  styleUrls: ['./dialog-person-associate-contracts.component.scss']
})
export class DialogPersonAssociateContractsComponent implements OnInit {
  s3Bucket: string;
  progressQuery: any = null;
  personalCompanyInfoId: any;

  now = new Date();
  controlPosition = new FormControl();
  controlStartDate = new FormControl();
  controlFinisthDate = new FormControl();
  controlSearchEmployer = new FormControl();
  controlSearchContract = new FormControl();
  controlSearchEmpleador = new FormControl();
  minDate = this.fechasService.getDateNow();

  isFromVehicleFlag = false;

  companyInfoId: string;

  projects: any[] = [];
  employers: any[] = [];
  contracts: any[] = [];
  movTranslado: any[] = [];
  contractsCopy: any[] = [];
  contractsSAVED: any[] = [];
  employerSelected: any [] = [];
  contractsSelected: any[] = [];
  positionSujester: string[];

  constructor(
    public dialogRef: MatDialogRef<DialogPersonAssociateContractsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private snackBService: SnackBarService,
    private employerService: EmployerService,
    private contractService: ContractService,
    public fechasService: FechasService,
    private commonService: CommonService
  ) {
    this.isFromVehicleFlag = this.data.isFromVehicle;
    this.projects = this.data.proyects;
    this.personalCompanyInfoId = this.data.personalCompanyInfoId;
    this.companyInfoId = this.data.adminInfoId;
    if (this.isFromVehicleFlag === true){
      this.data.data.forEach(data => {
        this.contractsSAVED.push(
          {
            ProjectStageContracts: [{ProjectName: this.getNameProyect(data?.Projects[0]?.Id)}],
            FormImageLogo: data?.FormImageLogo,
            ContractReview: data?.ContractReview,
            EmployerName: data?.EmployerName,
            ContractNumber: data?.ContractNumber,
            FinishDate: data?.Projects[0]?.FinishDate
          }
        );
      });
    }else{
      this.data.data.forEach(data => {
        this.contractsSAVED.push(
          {
            ProjectStageContracts: [{ProjectName: this.getNameProyect(data?.PersonalProyect?.ProjectId)}],
            FormImageLogo: data?.PersonalContract.FormImageLogo,
            ContractReview: data?.PersonalContract.ContractReview,
            EmployerName: data?.PersonalContract.EmployerName,
            ContractNumber: data?.PersonalContract.ContractNumber,
            FinishDate: data?.PersonalProyect.FinishDate
          }
        );
      });
    }

    this.controlStartDate.setValue(this.fechasService.getDateFormatDatePikerDATEHORANOWNotSecunt());
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    setTimeout(() => {
      this.getServices();
    }, 500);
  }

  getServices() {
    if(this.isFromVehicleFlag){
      this.employerService.getEmployers().subscribe((data: any) => {
        console.log('data', data);
        this.employers = data.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0)).filter(x => x.IsActive === true);
        if (this.data?.dataModuleRequest !== undefined) {
          // Si vienen del modulo request
          this.onSelectedAllEmployer(this.data?.dataModuleRequest.employerId);
          this.controlSearchEmployer.setValue(this.data?.dataModuleRequest.employerId);
        }
        console.log('this.employers', this.employers);
      });
    }else{
      this.employerService.getEmployersAdminId(this.companyInfoId).subscribe((data: any) => {
        this.employers = data.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0)).filter(x => x.IsActive === true);
        if (this.data?.dataModuleRequest !== undefined) {
          // Si vienen del modulo request
          this.onSelectedAllEmployer(this.data?.dataModuleRequest.employerId);
          this.controlSearchEmployer.setValue(this.data?.dataModuleRequest.employerId);
        }
      });
    }
  }

  getContracts(Id) {
    this.employerService.GetContracts(Id, 'Contractor').subscribe((restContract: any[]) => {
      this.contracts = restContract.filter(x => x.IsActive === true && this.exitContractSaveContractNumber(x.ContractNumber) === false);
      this.contracts.sort((a, b) => (a.ProjectStageContracts[0].ProjectName > b.ProjectStageContracts[0].ProjectName) ?
        1 : ((b.ProjectStageContracts[0].ProjectName > a.ProjectStageContracts[0].ProjectName) ? -1 : 0));
      this.contractsCopy = restContract.filter(x => x.IsActive === true && this.exitContractSaveContractNumber(x.ContractNumber) === false);
    });
  }

  getNameProyect(ProyectId: string): string {
    let Name: string;
    this.projects.forEach(info => {
      if (ProyectId === info.ProjectId) {
        Name = info.Name;
      }
    });
    return Name;
  }

  getImg(logo) {
    const img = (logo === null || logo === '' || logo === undefined ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg' : logo);
    return img;
  }

  onSelectedAllEmployer(Id) {
    if (this.employerSelected.length !== 0) {
      this.controlPosition.setValue('');
      this.employerSelected = [];
      this.contractsSelected = [];
    }
    const data = this.employers.find(x => x.Id === Id);
    this.employerSelected = [];
    data !== undefined ? this.employerSelected.push(data) : '';
    data !== undefined ? this.getContracts(Id) : '';
  }

  onSelectedContract(Id) {
    // Si selecciona 0 para seleccionar un contrato por proyecto masivo.
    if (Id === 0) {
      this.contracts.forEach((value, index) => {
        if (index === this.contracts.length -1) {
          this.selectedContract(value.Id, true);
        } else {
          this.selectedContract(value.Id, true);
        }
      });
    } else {
      this.selectedContract(Id, true);
    }
  }

  getSearchEmployerIdCompanyInfoId() {
    if (this.controlSearchEmpleador.value === '') {
      this.snackBService.openSnackBar('Por favor digita nombre o NIT de Empleador', 'X', 3000);
    } else {
      this.commonService.getSearchAllEmployersCompanyInfoId(this.employerSelected[0].CompanyInfoParentId,
        this.controlSearchEmpleador.value).subscribe((data: any) => {
        this.employerSelected = [];
        this.employerSelected.push(
          {
            Name: data.Name,
            EmployerId: data.Id,
            DocumentType: data.DocumentType,
            Logo: data.Logo,
            DocumentNumber: data.DocumentNumber,
            Email: data.Email
          }
        );
      },
        error => {
          this.snackBService.openSnackBar('POR FAVOR VALIDA LA INFORMACIÓN INGRESADA', 'X', 3000);
      });
    }
  }

  selectedContract(Id, oneFinal) {
    if (this.exitContractSave(Id) === true) {
      this.snackBService.openSnackBar('Ya tiene asociado ese contrato!', 'X', 3000);
    } else if (this.exitContractSelected(Id) === false) {
      // Guarda el Seleccioando
      const data = this.contracts.find(x => x.Id === Id);
      // Validacion 'SOLO SE SELECCIONA UNO POR PROYECTO'
      if (this.exitContractSelectedEqualNameProyect(data) === true){
        const contract = this.contractsSelected.find(x => x.ProjectStageContracts[0].ProjectName ===
          data.ProjectStageContracts[0].ProjectName);
        // Remove el que ya esta seleccionado
        const indexContractSelected = this.contractsSelected.indexOf(contract);
        this.contractsSelected.splice(indexContractSelected, 1);
        // Guarda el que esta seleccionado
        this.contractsSelected.push(data);
        // remove de los contratos
        const indexContract = this.contracts.indexOf(data);
        this.contracts.splice(indexContract, 1);
        // el que se removio de los seleccionados se guarda nuevamente en los contratos
        const newContracts = this.contracts.slice(0);
        newContracts.unshift(contract);
        this.contracts = newContracts;
        this.getSelectedContractsPosition();
        if (oneFinal === true) {
          this.setFinisthDate();
        }
      } else {
        this.contractsSelected.push(data);
        // Elimina de contratos el seleccionado y actualiza la lista
        const newContracts = this.contracts.slice(0);
        const index = newContracts.indexOf(data);
        newContracts.splice(index, 1);
        this.contracts = newContracts;
        this.getSelectedContractsPosition();
        if (oneFinal === true) {
          this.setFinisthDate();
        }
      }
    }
    this.contractsSelected.sort((a, b) => (a.ProjectStageContracts[0].ProjectName > b.ProjectStageContracts[0].ProjectName) ?
      1 : ((b.ProjectStageContracts[0].ProjectName > a.ProjectStageContracts[0].ProjectName) ? -1 : 0));
    this.controlSearchContract.setValue('');
  }

  onRemoveSelectedContract(Id) {
    if (this.exitContract(Id) === false) {
      // Agregar el removido de los seleccionado
      const data = this.contractsSelected.find(x => x.Id === Id);
      // push pero en primera posicion
      this.contracts.unshift(data);
      // Elimina de array seleccionado
      const index = this.contractsSelected.indexOf(data);
      this.contractsSelected.splice(index, 1);
      // actualiza la informacion del select
      const newContracts = this.contracts.slice(0);
      this.contracts = newContracts;
      this.getSelectedContractsPosition();
      this.setFinisthDate();
    }
    this.controlSearchContract.setValue('');
  }

  setFinisthDate() {
    let dateMax = '';
    this.contractsSelected.forEach((value, index) => {
      if (dateMax === '') {
        dateMax = value.FinishDate;
      } else if (value.FinishDate > dateMax) {
        dateMax = value.FinishDate;
      }
      if (index === this.contractsSelected.length -1) {
        this.controlFinisthDate.setValue(dateMax);
      }
    });
  }

  changeDateFinishDateContractTime(date, bool) {
  }


  exitContractSelected(Id): boolean {
    let exit = false;
    const data = this.contractsSelected.find(x => x.Id === Id);
    data !== undefined ? exit = true : exit = false;
    return exit;
  }

  exitContractSelectedEqualNameProyect(data): boolean {
    let exit = false;
    const dataExit = this.contractsSelected.find(x => x.ProjectStageContracts[0].ProjectName === data.ProjectStageContracts[0].ProjectName);
    dataExit !== undefined ? exit = true : exit = false;
    return exit;
  }

  exitContractSave(Id): boolean {
    let exit = false;
    const data = this.contractsSAVED.find(x => x.Id === Id);
    data !== undefined ? exit = true : exit = false;
    return exit;
  }

  exitContractSaveContractNumber(ContractNumber): boolean {
    let exit = false;
    const data = this.contractsSAVED.find(x => x.ContractNumber === ContractNumber);
    data !== undefined ? exit = true : exit = false;
    return exit;
  }



  exitContract(Id): boolean {
    let exit = false;
    const data = this.contracts.find(x => x.Id === Id);
    data !== undefined ? exit = true : exit = false;
    return exit;
  }

  postNewPersonalInfoContract() {
    if (this.isFromVehicleFlag === true){
      this.postSaveVehicleContract();
    }else {
      this.progressQuery = true;
      this.contractsSelected.forEach((contract, index) => {
        const pesonalCompany = {
          ContractId: contract.Id,
          EmployerId: this.employerSelected[0].EmployerId === this.controlSearchEmployer.value ?
            contract.EmployerId : this.employerSelected[0].EmployerId,
          FinishDate: contract.ProjectStageContracts[0]?.FinishDate < this.controlFinisthDate.value ?
            contract.ProjectStageContracts[0]?.FinishDate : this.controlFinisthDate.value,
          PersonalCompanyInfoId: this.personalCompanyInfoId,
          Position: this.controlPosition.value,
          StartDate: contract.ProjectStageContracts[0]?.StartDate > this.controlStartDate.value ?
            contract.ProjectStageContracts[0]?.StartDate : this.controlStartDate.value,
        };
        this.contractService.getIsContractPersonalCompanyInfoId(contract.Id, this.personalCompanyInfoId).subscribe(restIsContract => {
          this.contractService.postContractPersonalInfo(contract.Id, pesonalCompany).subscribe(resPostContrac => {
            this.snackBService.openSnackBar('Vinculado correctamente!', 'X', 4000);
            if (this.movTranslado.length === 0 && index === this.contractsSelected.length - 1) {
              this.progressQuery = false;
              this.dialogRef.close(true);
            }
          });
        }, (error: HttpErrorResponse) => {
          this.movTranslado.push(contract.Id);
          error.status === 400 ?
          this.dialogService.openDialogMovePersonalInfo(true, error, 'Desea trasladarlo?', false).afterClosed().subscribe(restDialog => {
              if (restDialog === true) {
                console.log('restDialog', restDialog);
                this.contractService.postMovePersonalInfoContract(contract.Id, pesonalCompany).subscribe((restMOvePersonal: any) => {
                  this.snackBService.openSnackBar('Trasladado correctamente!', 'X', 4000);
                  this.movTranslado.pop();
                  if (this.movTranslado.length === 0) {
                    this.dialogRef.close(true);
                  }
                  console.log('restMOvePersonal', restMOvePersonal);
                });
              } else {
                if (index === this.contractsSelected.length - 1) {
                  this.dialogRef.close(true);
                }
              }
            }, error => {
              if (index === this.contractsSelected.length - 1) {
                this.dialogRef.close(true);
              }
            })
            : '';
        });
      });
    }
  }

    postSaveVehicleContract(){
    this.progressQuery = true;
    this.contractsSelected.forEach((contract, index) => {
      const vehicleCompany = {
        ContractId: contract.Id,
        EmployerId: this.employerSelected[0].EmployerId === undefined ?
          contract.EmployerId : this.employerSelected[0].EmployerId,
        FinishDate: contract.ProjectStageContracts[0]?.FinishDate < this.controlFinisthDate.value ?
          contract.ProjectStageContracts[0]?.FinishDate : this.controlFinisthDate.value,
        VehicleCompanyInfoId: this.personalCompanyInfoId,
        Function: this.controlPosition.value,
        StartDate: contract.ProjectStageContracts[0]?.StartDate > this.controlStartDate.value ?
          contract.ProjectStageContracts[0]?.StartDate : this.controlStartDate.value,
        tillEndOfDay: true,
        Requirements: [0]
      };
      console.log(this.employerSelected[0].EmployerId)
      console.log(this.controlSearchEmployer.value)
      console.log(contract.EmployerId)
      console.log(vehicleCompany);

      this.contractService.postVehicleContract(false, vehicleCompany).subscribe( resPostContrac => {
          this.snackBService.openSnackBar('Vinculado correctamente!', 'X', 4000);
          if (this.movTranslado.length === 0 && index === this.contractsSelected.length -1){
            this.progressQuery = false;
            this.dialogRef.close(true);
          }
      }, (error: HttpErrorResponse) => {

        if (error.status === 412){
          this.dialogService.openDialogMovePersonalInfo(true, error, 'Desea trasladarlo?', false, 'TRANSLADAR VEHICULO', true).afterClosed().subscribe(restDialog => {
            if (restDialog === true) {
              this.contractService.postVehicleContract(true, vehicleCompany).subscribe( resPostContrac => {
                this.snackBService.openSnackBar('Trasladado correctamente!', 'X', 4000);
                if (this.movTranslado.length === 0 && index === this.contractsSelected.length -1){
                  this.progressQuery = false;
                  this.dialogRef.close(true);
                }
              });
            } else {
              if (index === this.contractsSelected.length -1) {
                this.dialogRef.close(true);
              }
            }
          }, error => {
            if (index === this.contractsSelected.length -1) {
              this.dialogRef.close(true);
            }
          })
        }
      });
    });
  }

  getSelectedContractsPosition() {
    this.positionSujester = [];
    this.contractService.getPositions().subscribe((data: any) => {
      console.log(data);
      data.forEach(x => {
        this.positionSujester.push(x.Name);
      });
    });
  }

  changeinputPositionContract(value) {
    this.controlPosition.setValue(value);
    console.log('controlPosition22', this.controlPosition.value);
  }

  close() {
    this.dialogRef.close();
  }

  validFormContract(): boolean {
    let valid = false;
    if (this.employerSelected.length > 0 && this.controlPosition.value !== '' &&
      this.controlPosition.value !== null && this.contractsSelected.length > 0) {
      console.log('this.controlPosition.value 333', this.controlPosition.value);
      valid = true;
    } else {
      valid = false;
    }
    return valid;
  }

  openDialogSelectedVincularContracts() {
    const list = [];
    this.contracts.forEach(v => {
      list.push({
        ...v,
        ProjectName: v.ProjectStageContracts[0].ProjectName
      });
    });
    const listTwo = [];
    this.contractsSAVED.forEach(v => {
      listTwo.push({
        ...v,
        ProjectName: v.ProjectStageContracts[0].ProjectName
      });
    });
    const card: CardList = {
      id: 'Id',
      img: 'FormImageLogo',
      titleFirst: 'ContractReview',
      subTitle: 'EmployerName',
      itemFinalDate: 'FinishDate',
      itemFinalText: 'ContractNumber',
      abodeTextMedium: 'ProjectName'
    };
    const cardSAVE: CardList = {
      id: '',
      img: 'FormImageLogo',
      titleFirst: 'ContractReview',
      subTitle: 'EmployerName',
      itemFinalDate: 'FinishDate',
      itemFinalText: 'ContractNumber',
      abodeTextMedium: 'ProjectName'
    };
    this.dialogService.openDialogSelectedVincularContracts(card, cardSAVE,
      '/admin/personal/person', list, listTwo).afterClosed().subscribe(rest => {
      if (rest !== undefined) {
        if (rest.selectedAll === true) {
          this.onSelectedContract(0);
        } else {
          if (rest.contracts.length !== 0) {
            rest.contracts.forEach(element => {
              this.onSelectedContract(element.Id);
            });
          }
        }
      }
    });
  }

}

