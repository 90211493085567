import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/modules/common.service';

export interface DialogData {
  IsPost: boolean;
  Id: number;
  Name: string;
}

@Component({
  selector: 'app-dialog-crudpermission',
  templateUrl: './dialog-crudpermission.component.html',
  styleUrls: ['./dialog-crudpermission.component.scss']
})
export class DialogCRUDPermissionComponent implements OnInit {

  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<DialogCRUDPermissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonService: CommonService,
    private fb: FormBuilder) { 
      this.form = this.fb.group({
        Id: new FormControl(''),
        Name: new FormControl('',Validators.required),
      });
      if(data.IsPost === false) {
        this.form.get('Id').setValue(data.Id);
        this.form.get('Name').setValue(data.Name);
      }

    }

  ngOnInit(): void {}

  save() {
    if(this.data.IsPost === true) {
      this.commonService.postPermissions({Name: this.form.get('Name').value}).subscribe(rest => {
        this.dialogRef.close(true);
      });
    } else {
      this.commonService.putPermissions(this.form.get('Id').value, this.form.value).subscribe(rest => {
        this.dialogRef.close(true);
      });
    }
  }

}
