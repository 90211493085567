<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'danger' || 'primary'">{{'RETIRO' || 'N/A'}}</div>
    </div>
    <div class="content-card list-card" >
        <div class="sub-header">
            <div class="sub-content greendAnalityco">{{'EMPLEADOR' || 'N/A'}}</div>
        </div>
        <div class="card" *ngFor="let employer of employerSelected">
            <div class="card-option">
                <img src="{{s3Bucket+getImg(employer.Logo)}}" alt="" (error)="commonMetodService._getUrlErrorImg('employer', $event)">
                <div class="card-content">
                    <div class="card-items">
                        <h3 class="title">{{(employer.Name | uppercase) || ''}}</h3>
                        <div class="field">{{employer.Rol || ''}}</div>
                        <div class="field marginTop">{{employer.DocumentType || ''}}: {{employer.DocumentNumber || ''}}</div>
                    </div>
                </div>
            </div>
            <mat-icon class="icon-close" (click)="setValue()">close</mat-icon>
        </div>
        <app-select-search class="select-component" *ngIf="employerSelected.length !== 1 && employers.length !== 0" style="margin-top: 10px;"
                        [_dataSource]="employers" [textInput]="'EMPRESA'" [inputType]="'text'"
                        [dataShow]="'Name'" [dataValue]="'Id'" [otherFilter]="'DocumentNumber'" [siteCtrl]="controlEmployer"
                        [isCard]="true"
                        [card]="{img: 'Logo', title: 'Name', subTitle: 'Rol', itemKey: 'DocumentType', itemValue:'DocumentNumber'}"
                        (onSelectionChange)="valueSelectedProyect($event)"
                        [onErrorUrl]="'employer'">
        </app-select-search>
        <!-- <div class="text-flagExitContractContracts" *ngIf="employers.length === 0">
            Su usuario no esta vinculado a ningún proyecto activo, comuníquese con el usuario operador.
        </div> -->
        <div class="sub-header"  *ngIf="employerSelected.length === 1">
            <div class="sub-content greendAnalityco">{{'EMPLEADO' || 'N/A'}}</div>
        </div>
        <div class="card" *ngFor="let person of personSelected">
            <div class="card-option">
                <img src="{{s3Bucket+getImg(person.PersonalPhoto)}}" alt="">
                <div class="card-content">
                    <div class="card-items">
                        <h3 class="title">{{(person.PersonalName | uppercase) || ''}}</h3>
                        <div class="field">{{person.PositionName}}</div>
                        <div class="field">{{person.PersonalDocumentType || ''}}: {{person.PersonalDocumentNumber || ''}}</div>
                    </div>
                </div>
            </div>
            <mat-icon class="icon-close" (click)="setValueContract()">close</mat-icon>
        </div>
        <app-select-search class="select-component" *ngIf="employerSelected.length === 1 && personSelected.length === 0" style="margin-top: 10px;"
                        [_dataSource]="personals" [textInput]="'Personal'" [inputType]="'text'"
                        [dataShow]="'PersonalName'" [dataValue]="'Id'"  [otherFilter]="'PersonalDocumentNumber'" [siteCtrl]="controlContractSelected"
                        [isCard]="true"
                        [card]="{img: 'PersonalPhoto', title: 'PersonalName', subTitle: 'PositionName', itemKey: 'PersonalDocumentType', itemValue:'PersonalDocumentNumber'}"
                        (onSelectionChange)="onSelectionChangeContract($event)"
                        [onErrorUrl]="'personal'">
        </app-select-search>
        <!-- <div class="text-flagExitContractContracts" *ngIf="flagExitContractContracts === false">
            No se encontro un contrato activo en este proyecto. Comuniquese con el operador del proyecto.
        </div> -->
        <div class="sub-header" *ngIf="employerSelected.length === 1 && personSelected.length === 1">
            <div class="sub-content greendAnalityco">{{'TIPO DE RETIRO' || 'N/A'}}</div>
        </div>
        <div class="fields-group" *ngIf="employerSelected.length === 1 && personSelected.length === 1" style="margin-top: 10px;">
            <app-select-search class="select-component" *ngIf="employerSelected.length === 1"
                        [_dataSource]="typesTermination" [textInput]="'Tipo de retiro'" [inputType]="'text'"
                        [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="controlTypeTermination">
            </app-select-search>
        </div>
        <div style="width: 100%; color: red; font-weight: 500;">Al iniciar esta solicitud, se inhabilitara el acceso del empleado en los proyectos.</div>
        <div class="actions-dialog"style="margin: 3px;">
            <button mat-button class="btn-cancelar" [mat-dialog-close]="data" >CANCELAR</button>&nbsp;&nbsp;
            <button mat-button
                    (click)="validationRemoveAcceso(true)"
                    [ngClass]="getDisabled() === true ? 'btn-generar':'btn-disabled'"
                    [disabled]="!getDisabled()">INICIAR
            </button>
        </div>
    </div>

</div>
