import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../../../services/modules/common.service';
import { CommonOptions, Requirements, ContractIdProyect, RequirementsProyect, RequirementsContract } from '../../../interfaces/interfaces';
import { PersonalContractProyect } from '../../../models/Personal/PersonalContractProyect';
import { TypeAdminAcceso } from '../../../services/utils/utils.types';
import { PersonalService } from '../../../services/modules/personal.service';
import { PersonalInfoContract } from '../../../models/Personal/personalInfoContract';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SnackBarService } from '../../../services/utils/snackBar.service';
import { FilterMultipleService } from 'src/app/core/services/utils/filter-multiple.service';

export interface DialogData {
  personalContractProyectSelected: PersonalContractProyect,
  typeAdmin: TypeAdminAcceso,
  isCrud: boolean,
  Requirements: number[]
}

@Component({
  selector: 'app-dialog-admin-acceso',
  templateUrl: './dialog-admin-acceso.component.html',
  styleUrls: ['./dialog-admin-acceso.component.scss']
})

export class DialogAdminAccesoComponent implements OnInit {
  personalTypes: CommonOptions[] = [];
  
  requirementsDocuments: Requirements[] = [];
  filterRequirementsDocuments: Requirements[] = [];

  requirementsDocumentsVigenci: Requirements[] = [];
  filterRequirementsDocumentsVigenci: Requirements[] = [];

  requirementsInspeccion: Requirements[] = [];
  filterRequirementsInspeccion: Requirements[] = [];

  requirementsInspeccionParam: Requirements[] = [];
  filterRequirementsInspeccionParam: Requirements[] = [];

  requirementsEncuesta: Requirements[] = [];
  filterRequirementsEncuesta: Requirements[] = [];

  contractIdProyects: ContractIdProyect[] = [];
  requirementsProyectOrContract: RequirementsProyect[] | RequirementsContract[]= [];
  requirementsContract: RequirementsContract[] = [];

  requirementsId: number[] = [];

  personalInfoContract: PersonalInfoContract;

  constructor(
    public dialogRef: MatDialogRef<DialogAdminAccesoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonService: CommonService,
    private personalService: PersonalService,
    private snackBService: SnackBarService,
    private filters: FilterMultipleService
  ) { 
    if(this.data.isCrud !== undefined && this.data.Requirements !== null) {
      this.requirementsId = this.data.Requirements;
    }
    if(this.data.typeAdmin === 'Project') {
      this.servicesTypeProyect();
    } else if(this.data.typeAdmin === 'Contract') {
      this.servicesTypePersonal();
    } else if(this.data.typeAdmin === 'Personal') {
      this.getPersonalsInfoContract();
    }
    
  }

  ngOnInit(): void { }

  getPersonalsInfoContract() {
    this.personalService.getPersonalsInfoContract(this.data.personalContractProyectSelected.PersonalProyect.ContractId).subscribe((personals: PersonalInfoContract[]) => {
      personals.forEach(persona =>{
        if(persona.DocumentNumber === this.data.personalContractProyectSelected.PersonalContract.DocumentNumber) {
          this.personalInfoContract = persona;
          persona.Requirements?.forEach(requirementsId =>{
            if(this.exitRequiment(requirementsId) === false) {
              this.requirementsId.push(requirementsId);  
            }
          });
        }
      });
      this.servicesTypePersonal();
    })
  }

  close() {
    this.dialogRef.close(false);
  }

  checked(event: MatSlideToggleChange , requirementId: number) {
    if(event.checked === true) {
      this.requirementsId.push(requirementId);
    } else {
      this.requirementsId.forEach((value, index) => {
        value === requirementId ? this.requirementsId.splice(index, 1): '';
      });
    }
  }


  checkedWithPersonalTypeId(event: MatSlideToggleChange , PersonalTypeId: number, requirementId: number) {
    if(event.checked === true) {
      if(this.exitDataRequirents(PersonalTypeId) === true) {
        this.requirementsIdSave.forEach(personalType => {
          if(PersonalTypeId === personalType.PersonalTypeId) {
            let data = personalType.RequirementIds.find(req => req === requirementId);
            data !== undefined ? '' : personalType.RequirementIds.push(requirementId);
          }
        });
      } else {
        this.requirementsIdSave.push({
          PersonalTypeId: PersonalTypeId,
          ProjectId: this.data.personalContractProyectSelected.PersonalProyect.ProjectId,
          RequirementIds: [requirementId]
        });
      }
    } else {
      this.requirementsIdSave.forEach(personalType => {
        if(PersonalTypeId === personalType.PersonalTypeId) {
          personalType.RequirementIds.forEach((value, index) => {
            value === requirementId ? personalType.RequirementIds.splice(index, 1): '';
          });
        }
      });
    }
  }

  exitDataRequirents(id): boolean {
    let exit = false;
    let data = this.requirementsIdSave.find(x => x.PersonalTypeId === id);
    data !== undefined ? exit = true: exit = false;
    return exit;
  }
  
  //servicesCommont Admin Acceso
  servicesCommonTypesAcceso() {
    this.commonService.getCommonOptions('PersonalType').subscribe((personalTypes: CommonOptions[]) =>{
      this.personalTypes = personalTypes.sort((a,b) => a.OrderNum > b.OrderNum ? 1 :-1);
    });  
  }

  // Services Type Admin Acceso
  requirementsIdSave: any[] = [];
  servicesTypeProyect() {
    this.commonService.getRequirements(this.data.personalContractProyectSelected.PersonalContract.CompanyInfoParentId).subscribe((requirements: Requirements[]) => {
        this.filterRequirementsDocuments = requirements.filter(x => (x.ByPersonal === true || x.ByEmployer === true || x.ByEmployerPersonal) && (x.IsActive === true && x.ByEntry === false && x.BySurvey === false && x.EnableInputEntry === false && x.ByDate === false && x.ByCertification === true)).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsDocuments = requirements.filter(x => (x.ByPersonal === true || x.ByEmployer === true || x.ByEmployerPersonal) && (x.IsActive === true && x.ByEntry === false && x.BySurvey === false && x.EnableInputEntry === false && x.ByDate === false && x.ByCertification === true)).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

        this.filterRequirementsDocumentsVigenci = requirements.filter(x => x.IsActive === true && x.ByDate === true || x.BySecuritySocial === true).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsDocumentsVigenci = requirements.filter(x => x.IsActive === true && x.ByDate === true || x.BySecuritySocial === true).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

        this.filterRequirementsInspeccion = requirements.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === false).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsInspeccion = requirements.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === false).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

        this.filterRequirementsInspeccionParam = requirements.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === true).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsInspeccionParam = requirements.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === true).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

        this.filterRequirementsEncuesta = requirements.filter(x => x.IsActive === true && x.BySurvey === true).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsEncuesta = requirements.filter(x => x.IsActive === true && x.BySurvey === true).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
    });
    this.servicesCommonTypesAcceso();
    this.commonService.getRequirementsProyect(this.data.personalContractProyectSelected.PersonalProyect.ProjectId).subscribe((requirementsProyect: RequirementsProyect[]) => {
      this.requirementsProyectOrContract = requirementsProyect;
      this.requirementsProyectOrContract.forEach(Project => {
        this.requirementsIdSave.push(
          { PersonalTypeId: Project.PersonalTypeId,
            ProjectId: this.data.personalContractProyectSelected.PersonalProyect.ProjectId,
            RequirementIds: this.getArrayNumbersId(Project.RequirementProjects)}
        );
      });
    })
  }

  getArrayNumbersId(array): number[] {
    let a = [];
    array.forEach(x => {
      a.push(x.Id);
    });
    return a;
  }

  cheketRequisitoProyect(personalTypeValue: string, requirementId: number, cheked?: boolean): boolean {
    let exit = false;
    let requirementIds: number[] = [];
    this.requirementsProyectOrContract.forEach(requirementsProyect => {
      if(requirementsProyect.PersonalTypeName === personalTypeValue) {
        requirementsProyect.RequirementProjects.forEach(requirementProject => {
          if(requirementProject.Id === requirementId) {
            requirementIds.push(requirementId);
          }
        });
      }
    });
    if(this.requirementsId.length > 0 && cheked) {
      this.requirementsId.forEach(value => {
        value === requirementId ? exit = true : null;
      });
      return exit;
    } else {
    if(requirementIds.length === this.contractIdProyects.length) {
      return exit = true;
    } else {
        this.requirementsProyectOrContract.forEach(requirementsContract =>{
          if(requirementsContract.RequirementContracts) {
            requirementsContract.RequirementContracts.forEach(RequirementContract => {
              if(RequirementContract.Id === requirementId) {
                return exit = true;
              }
            });
          }
        }); 
      }
    }
    return exit;
  }

  cheketRequisitoPersonal(personalTypeValue: string, requirementId: number, projectId: string): boolean {
    let exit = false;
    this.requirementsProyectOrContract.forEach(requirementsProyect => {
      if(requirementsProyect.PersonalTypeName === personalTypeValue) {
        requirementsProyect.RequirementProjects.forEach(requirementProject => {
          if(requirementProject.Id === requirementId && requirementsProyect.ProjectId === projectId) {
            return exit = true;
          }
        });
      }
      
      if(requirementsProyect.RequirementContracts) {
        requirementsProyect.RequirementContracts.forEach(RequirementContract => {
          if(RequirementContract.Id === requirementId) {
            return exit = true;
          }
        });
      }
      if(this.requirementsId.length > 0) {
        this.requirementsId.forEach(value => {
          value === requirementId ? exit = true : null;
        });
        return exit;
      }
    });
    return exit;
  }



  servicesTypePersonal() {
    if(this.data.personalContractProyectSelected.PersonalContract.CompanyInfoParentId !== null && this.data.personalContractProyectSelected.PersonalContract.CompanyInfoParentId !== '' && this.data.personalContractProyectSelected.PersonalContract.CompanyInfoParentId !== undefined) {
      this.commonService.getRequirements(this.data.personalContractProyectSelected.PersonalContract.CompanyInfoParentId).subscribe((requirements: Requirements[]) => {
        this.filterRequirementsDocuments = requirements.filter(x => (x.ByPersonal === true || x.ByEmployer === true || x.ByEmployerPersonal) && (x.IsActive === true && x.ByEntry === false && x.BySurvey === false && x.EnableInputEntry === false && x.ByDate === false && x.ByCertification === true)).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsDocuments = requirements.filter(x => (x.ByPersonal === true || x.ByEmployer === true || x.ByEmployerPersonal) && (x.IsActive === true && x.ByEntry === false && x.BySurvey === false && x.EnableInputEntry === false && x.ByDate === false && x.ByCertification === true)).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

        this.filterRequirementsDocumentsVigenci = requirements.filter(x => x.IsActive === true && x.ByDate === true || x.BySecuritySocial === true).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsDocumentsVigenci = requirements.filter(x => x.IsActive === true && x.ByDate === true || x.BySecuritySocial === true).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

        this.filterRequirementsInspeccion = requirements.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === false).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsInspeccion = requirements.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === false).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

        this.filterRequirementsInspeccionParam = requirements.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === true).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsInspeccionParam = requirements.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === true).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

        this.filterRequirementsEncuesta = requirements.filter(x => x.IsActive === true && x.BySurvey === true).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsEncuesta = requirements.filter(x => x.IsActive === true && x.BySurvey === true).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
      });
    } else {
      this.commonService.getRequirementsAll().subscribe((requirements: Requirements[]) => {
        this.filterRequirementsDocuments = requirements.filter(x => (x.ByPersonal === true || x.ByEmployer === true || x.ByEmployerPersonal) && (x.IsActive === true && x.ByEntry === false && x.BySurvey === false && x.EnableInputEntry === false && x.ByDate === false && x.ByCertification === true)).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsDocuments = requirements.filter(x => (x.ByPersonal === true || x.ByEmployer === true || x.ByEmployerPersonal) && (x.IsActive === true && x.ByEntry === false && x.BySurvey === false && x.EnableInputEntry === false && x.ByDate === false && x.ByCertification === true)).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

        this.filterRequirementsDocumentsVigenci = requirements.filter(x => x.IsActive === true && x.ByDate === true || x.BySecuritySocial === true).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsDocumentsVigenci = requirements.filter(x => x.IsActive === true && x.ByDate === true || x.BySecuritySocial === true).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

        this.filterRequirementsInspeccion = requirements.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === false).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsInspeccion = requirements.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === false).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

        this.filterRequirementsInspeccionParam = requirements.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === true).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsInspeccionParam = requirements.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === true).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

        this.filterRequirementsEncuesta = requirements.filter(x => x.IsActive === true && x.BySurvey === true).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
        this.requirementsEncuesta = requirements.filter(x => x.IsActive === true && x.BySurvey === true).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
      });
    }
    this.commonService.getContractIdProyect(this.data.personalContractProyectSelected.PersonalProyect.ContractId).subscribe((contractIdProyect: ContractIdProyect[]) => {
      this.contractIdProyects = contractIdProyect;
      this.contractIdProyects.forEach(contractIdProyect => {
        this.commonService.getRequirementsProyect(contractIdProyect.ProjectId).subscribe((requirementsProyect: RequirementsProyect[]) => {
          this.requirementsProyectOrContract = (this.requirementsProyectOrContract as (RequirementsProyect | RequirementsContract)[]).concat(requirementsProyect) as RequirementsProyect[] | RequirementsContract[];
        });    
      });
    });
    
    this.commonService.getRequirementsContract(this.data.personalContractProyectSelected.PersonalProyect.ContractId).subscribe((requirementsContract: RequirementsContract[]) => {
      this.requirementsContract = requirementsContract;
      if(this.data.typeAdmin === 'Personal') {
        this.requirementsProyectOrContract = (this.requirementsProyectOrContract as (RequirementsProyect | RequirementsContract)[]).concat(requirementsContract) as RequirementsProyect[] | RequirementsContract[];
      } else if(this.data.typeAdmin === 'Contract') {
        this.requirementsContract.forEach(req => {
          req?.RequirementContracts.forEach((reqContract: any) => {
            this.requirementsId.push(reqContract.Id);
          });
        });
      }
      this.commonService.getCommonOptions('PersonalType').subscribe((personalTypes: CommonOptions[]) =>{
        this.personalTypes = personalTypes.filter(personalType => personalType.Description === requirementsContract[0].PersonalTypeName);
      }); 
    });
  }

  exitRequiment(requirementsId): boolean {
    let exit: boolean = false;
    let data = this.requirementsId.find(x => x === requirementsId);
    data !== undefined ? exit = true: exit = false;
    return exit;
  }


  save() {
    if(this.data!.isCrud === true) {
      if(this.data!.typeAdmin === 'Project') {
        this.dialogRef.close(this.requirementsIdSave);
      } else {
        this.dialogRef.close(this.requirementsId);
      }
    } else {
      this.personalInfoContract.Requirements = this.requirementsId;
      this.personalService.updatePersonalsInfoContract(
      this.data.personalContractProyectSelected.PersonalProyect.ContractId,
      this.personalInfoContract.Id,
      this.personalInfoContract
      ).subscribe(rest =>{ 
        this.dialogRef.close(true);
        this.snackBService.openSnackBar('REQUISITOS DE ACCESO DE PERSONAL ACTUALIZADOS!', 'X', 5000);
      });
    }
    
  }

  getfilterRequirements(value: string) {
    if(value === '') {
      this.filterRequirementsDocuments = this.requirementsDocuments;
      this.filterRequirementsDocumentsVigenci = this.requirementsDocumentsVigenci;
      this.filterRequirementsInspeccion = this.requirementsInspeccion;
      this.filterRequirementsInspeccionParam = this.requirementsInspeccionParam;
      this.filterRequirementsEncuesta = this.requirementsEncuesta;
    } else {
      this.filterRequirementsDocuments = this.getRequirementsFilterDocuments(value);
      this.filterRequirementsDocumentsVigenci = this.getRequirementsFilterDocumentsVigenci(value);
      this.filterRequirementsInspeccion = this.getRequirementsFilterInspeccion(value);
      this.filterRequirementsInspeccionParam = this.getRequirementsFilterInspeccionParam(value);
      this.filterRequirementsEncuesta = this.getRequirementsFilterEncuesta(value);
    }
  }

  getRequirementsFilterDocuments(value: string): any[] {
      return this.requirementsDocuments.filter(x => {
        return (this.filters.exitFilter(x.Name, value));
                // || this.filters.exitFilter(x.Description, value));
      });
  }

  getRequirementsFilterDocumentsVigenci(value: string): any[] {
    return this.requirementsDocumentsVigenci.filter(x => {
      return (this.filters.exitFilter(x.Name, value));
              // this.filters.exitFilter(x.Description, value));
    });
  }

  getRequirementsFilterInspeccion(value: string): any[] {
    return this.requirementsInspeccion.filter(x => {
      return (this.filters.exitFilter(x.Name, value));
              // this.filters.exitFilter(x.Description, value));
    });
  }

  getRequirementsFilterInspeccionParam(value: string): any[] {
    return this.requirementsInspeccionParam.filter(x => {
      return (this.filters.exitFilter(x.Name, value));
              // this.filters.exitFilter(x.Description, value));
    });
  }

  getRequirementsFilterEncuesta(value: string): any[] {
    return this.requirementsEncuesta.filter(x => {
      return (this.filters.exitFilter(x.Name, value));
              // this.filters.exitFilter(x.Description, value));
    });
  }



}