import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanyModel, CommonModuleSave } from '../../models/CompanyModel';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

    constructor(
        private api: ApiService,
        private _http: HttpClient
    ) { }

    //GET Employers
    getCompanies(){
        return this.api.get('api/Company');
    }

    postCompany(model): Observable<CompanyModel>{
      return this._http.post<CompanyModel>(this.api.getUrl(`api/Company`), model);
    }

    //GET Employer ID
    getCompanyId(id): Observable<CompanyModel>{
        return this._http.get<CompanyModel>(this.api.getUrl(`api/Company/${id}`));
    }

    //GET Employer ID
    putCompanyId(companyModel: CommonModuleSave, Id: string ){
        return this._http.put(this.api.getUrl(`api/Company/${Id}`), companyModel);
    }

    //Remplazar informacion condiciones de servicio
    patchCompanyId(Id: string,replace){
      return this._http.patch(this.api.getUrl(`api/Company/${Id}`), replace);
  }

    // Video o imagen de Condiciones de servicio
    updateFileCondicionesDeServicio(id, file) {
        return this._http.put(this.api.getUrl('api/Company/UpdateFileAdStart/'+id), file);
    }

    // Video o imagen de Politicas de privacidad
    updateFilePoliticasDePrivacidad(id, file) {
        return this._http.put(this.api.getUrl('api/Company/UpdatePrivacyPolicies/'+id), file);
    }

    //GET proyect Companu
    getProyectCompany(id): Observable<any>{
        return this._http.get<any>(this.api.getUrl(`api/Project?Company=${id}`));
    }

    // get user admin
    getUsersCompanyIdVinculados(id): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Company/${id}/AdminUsers`));
    }

    // post user admin
    postUsersCompanyIdVinculados(id, model){
        return this._http.post(this.api.getUrl(`api/Company/${id}/AdminUsers`), model);
    }

    // actualizar img company
    putCompanyUpdateLogo(Id, file) {
        return this._http.put(this.api.getUrl(`api/Company/UpdateLogo/${Id}`), file);
    }

    // actualizar data company
    putCompany(Id, model) {
        return this._http.put(this.api.getUrl(`api/Company/${Id}`), model);
    }

    // get user admin
    getCompanyLimit(adminCompanyInfoId): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Company/Limit?adminCompanyInfoId=${adminCompanyInfoId}`));
    }

    // actualizar data company
    postCompanyLimit(model) {
        return this._http.post(this.api.getUrl(`api/Company/Limit`), model);
    }


    // Get roles for Company
    getUserByAdminCompany(adminCompanyInfoId): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Company/UserByAdminCompany?adminCompanyInfoId=${adminCompanyInfoId}`));
    }

    // Get roles for Company
    getRolesCompany(adminCompanyInfoId): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Company/${adminCompanyInfoId}/Roles`));
    }

    // actualizar data company users
    postRolesCompanyUser(adminCompanyInfoId, model) {
        return this._http.post(this.api.getUrl(`api/Company/UserByAdminCompany/${adminCompanyInfoId}`), model);
    }

    // delete data company users
    deleteRolesCompanyUser(adminCompanyInfoId, userId) {
        return this._http.delete(this.api.getUrl(`api/Company/UserByAdminCompany/${adminCompanyInfoId}/${userId}`));
    }

    // update data schedule
    postScheduleCompany(model) {
      return this._http.post(this.api.getUrl(`api/Company/Schedule`), model);
    }

    // get data schedule
    getScheduleCompany(): Observable<any[]>{
      return this._http.get<any[]>(this.api.getUrl(`api/Company/Schedule`));
    }

    // post payroll news type
    postPayRollNewsType(model) {
      return this._http.post(this.api.getUrl(`api/PayrollNewsType`), model);
    }

    // update payroll news type
    putPayRollNewsType(id, model) {
      return this._http.put(this.api.getUrl(`api/PayrollNewsType/${id}`), model);
    }

    //GET payroll news type
    getPayrollNewsType() : Observable<any[]> {
      const url = this.api.getUrl('api/PayrollNewsType');
      return this._http.get<any[]>(url);
    }

    postFaceRecognition(model) {
      return this._http.post(this.api.getUrl(`api/FaceRecognition`), model);
    }

  getFaceRecognition(adminInfoId): Observable<any[]>{
    return this._http.get<any[]>(this.api.getUrl(`api/FaceRecognition/${adminInfoId}`));
  }

  //APIS TIPO DE RETIRO ADMINISTRADORA
    /**
     * Permite traer tipos de retiros por admionistradora
     */
    getPersonalTerminationRequestTerminationType(CompanyInfoId?) : Observable<any[]> {
        const url = this.api.getUrl(`api/PersonalTerminationRequest/TerminationType${CompanyInfoId !== undefined ? '?CompanyInfoId='+CompanyInfoId: ''}`);
        return this._http.get<any[]>(url);
    }

    /**
     * Permite guardar tipos de retiros por admionistradora
     */
    postTerminationType(model) {
        return this._http.post(this.api.getUrl(`api/PersonalTerminationRequest/TerminationType`), model);
    }

    /**
     * Permite actualizar tipos de retiros por admionistradora
     */
    putTerminationType(positionTypeId, model) {
        return this._http.put(this.api.getUrl(`api/PersonalTerminationRequest/TerminationType/${positionTypeId}`), model);
    }

    /**
     * Permite actualizar los requerimientos por adminsitrador y tipo de retiro
     */
    putRequirementTerminationType(terminationTypeId, model) {
        return this._http.put(this.api.getUrl(`api/PersonalTerminationRequest/TerminationType/Requirement/${terminationTypeId}`), model);
    }

    //APIS TIPO DE RETIRO ADMINISTRADORA FIN

}
