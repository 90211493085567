import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FechasService } from './fechas.service';
import { AuthService } from 'src/app/core/services/modules/auth.service';
import { MenuService } from '../menu/menu.service';
import { Menu } from '../../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class  CommonMetodService {

    s3Bucket:string;
    constructor(
      private fechasService: FechasService,
      private auth: AuthService,
      private menuService: MenuService,
    ) { 
      this.s3Bucket=environment.api.s3Bucket;
    }
    
    _validarDateMoney(value): boolean {
      let flag = false;
      if(value && value !== '' && value !== '$' && value !== 0 && value !== null && value !== '$0'
      && value !== '0' && value !== '$.' && value !== '$.0' && value !== '$.00' && value !== '$.000' && value !== '$.000.000' && value !== '$.000.000.000'
      && value !== '$0.' && value !== '$0.0' && value !== '$0.00' && value !== '$0.000' && value !== '$0.000.000' && value !== '$0.000.000.000'
      && value !== '$00.' && value !== '$00.0' && value !== '$00.00' && value !== '$00.000' && value !== '$00.000.000' && value !== '$00.000.000.000'
      && value !== '$000.' && value !== '$000.0' && value !== '$000.00' && value !== '$000.000' && value !== '$000.000.000' && value !== '$000.000.000.000'
      ){
        flag = true;
      }
      return flag
    }

    // Se utliza el valor retornado con un .includes(value);
    _normalizeValue(value: string): string {
       return value.toLowerCase().replace(/\s/g, '');
    }

    _getContractNumber(): string {
      return `CO-${this.fechasService.getDateFormatDayMontYear(new Date())}${Math.floor(Math.random() * (100 - 999))}`;
    }

    /**
     * Permite quitar los caracteres de un string
     */
    _replaceAll( text, busca, reemplaza ){
      while (text.toString().indexOf(busca) != -1)
          text = text.toString().replace(busca,reemplaza);
      return text;
    }

    /**
     * Permite abrir link en una pestaña nueva
     */
    _goToLink(url: string){
      window.open(url, "_blank");
    }

    _compareNumberRomans(value, ValueCompare): string {
      let result = '';
      let romans = ["I","II","III","IV","V","VI","VII","VIII","IX"];
      let value1 = 0;
      let value2 = 0;
      romans.forEach((num, index) => {
        if(num === value) {
          value1 = index + 1;
        }
        if(num === ValueCompare){
          value2 = index + 1;
        }
      });
      if(value1 > value2) {
        result = '>';
      } else if(value2 > value1) {
        result = '<';
      } else if(value1 === value2) {
        result = '=';
      }
      return result;
    }


    _getUrlErrorImg(module, event) {
      let url = '';
      if(module === 'personal') {
        url = 'assets/images/personblue.png';
      } else if(module === 'contract') {
        url = 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg'
      } else if(module === 'employer') {
        url = 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg'
      } else if(module === 'noImage') {
        url = 'assets/images/sin_imagen.png'
      } else if(module === 'profile') {
        url = 'assets/images/account_circle_dark.svg'
      } else if(module === 'project') {
        url = 'assets/images/gnr-buildings.png'
      } else if(module === 'administradora') {
        url = 'assets/images/panteon.png'
      } else if(module === 'vehicle') {
        url = 'assets/images/ic_unknow_car.png'
      }
      event.target.src = this.s3Bucket+url;
      return url;
    }

    get getManu(): Menu[] {
      return this.menuService.menu.filter(menu => {
        let isPermission: boolean = false;
        this.auth.getUser().roles?.forEach(_rol => {
            let contratingPermission = this.auth.getUser().claims.find(clain => clain === menu.rolKey || menu.rolKey === 'dashboard' || (menu.rolKey === 'superuser' &&  this.auth.getUser().IsSuperUser === true));
            if(contratingPermission !== undefined) {
              return isPermission = true;
            }
          });
        return isPermission;
      });
    } 

    _getExitPermissionModel(model): boolean {
      let exitPer = false;
      let data = this.getManu.find(x => x.model === model);
      data !== undefined ? exitPer = true : exitPer = false;
      return exitPer
    }
  

}


