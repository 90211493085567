import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatesPeriods } from 'src/app/core/interfaces/interfaces';
import { FechasService } from 'src/app/core/services/utils/fechas.service';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';

export interface DialogData {
  title: string;
  description: string;
  // maxdate selecciona hasta donde salen los meses si el mes actual es 6 y le colocar max date como 1 las fechas sera hasta 7
  maxDate: number;
  // itemnumber sera si arriba selecciona hasta el mes 7 y items number sera de 3. el resultado sera [5,6,7]
  itemsNumber: number;
}

@Component({
  selector: 'app-dialog-select-period',
  templateUrl: './dialog-select-period.component.html',
  styleUrls: ['./dialog-select-period.component.scss']
})
export class DialogSelectPeriodComponent implements OnInit {

  form: FormGroup;
  dataRange: DatesPeriods[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogSelectPeriodComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private fechasService: FechasService,
    private snackBService: SnackBarService,
  ) {
    this.dataRange = this.fechasService.getListDateYYYYMM(data.maxDate,data.itemsNumber);

    this.form = this.fb.group({
      date: new FormControl('',Validators.required),
    });
    this.form.get('date').setValue(this.getDateSinceUntil());
   }

  ngOnInit(): void {
  }

  selectedDate() {
    if(this.form.get('date').value === '') {
      this.snackBService.openSnackBar('SELECCIONE FECHA', 'X', 4000);
    } else {
      this.dialogRef.close(this.form.get('date').value);
    }
  }

  getDateSinceUntil(): string {
    let today = new Date();
    let mm = String((today.getMonth()));
    let yyyy = today.getFullYear();
    return yyyy+'-'+(parseInt(mm) === 10 || parseInt(mm) === 11 || parseInt(mm) === 12 ? mm: 0+mm);
  }

}
