<div class="box">
    <div class="title">REQUISITOS DE ACCESO</div>
    <!-- <title-dialog [title]="'REQUISITO DE ACCESO'" [color]="'greendAnalityco'" [_module]="data.typeAdmin"></title-dialog> -->
    <!-- Acceso de Proyecto -->
    <div class="content-acceso">
        <div class="search">
            <input class="input-search" 
                type="text" 
                placeholder="Buscar..." 
                (keyup)="getfilterRequirements($event.target.value)">
        </div>
        <div class="acceso-proyect" *ngIf="data.typeAdmin === 'Project'">
            <table>
                <tbody>
                    <tr>
                        <!-- <th style="min-width: 190px;">Documentos <label class="requisito">({{data.typeAdmin || ''}})</label></th>-->
                        <th style="min-width: 190px;">Documentos </th>
                        <th style="padding: 8px 16px;" *ngFor="let personalType of personalTypes">{{personalType.Description+'('+personalType.Value+')'}}</th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsDocuments" class="array">
                        <div class="icons-requirements">
                                <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                        </div>
                        <td style="text-align: center;" *ngFor="let personalType of personalTypes">
                            <mat-slide-toggle [checked]="!cheketRequisitoProyect(personalType.Value, requirement.Id)" (change)="checkedWithPersonalTypeId($event, personalType.Id, requirement.Id)"></mat-slide-toggle>
                        </td>
                    </tr>
                    <tr>
                        <th style="min-width: 190px;">Documentos con vigencia </th>
                        <th style="padding: 8px 16px;" *ngFor="let personalType of personalTypes">{{personalType.Description+'('+personalType.Value+')'}}</th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsDocumentsVigenci" class="array">
                        <div class="icons-requirements">
                                <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                            </div>
                        <td style="text-align: center;" *ngFor="let personalType of personalTypes">
                            <mat-slide-toggle [checked]="!cheketRequisitoProyect(personalType.Value, requirement.Id)" (change)="checkedWithPersonalTypeId($event, personalType.Id, requirement.Id)"></mat-slide-toggle>
                        </td>
                    </tr>
                    <tr>
                        <th style="min-width: 190px;">Inspecciones </th>
                        <th style="padding: 8px 16px;" *ngFor="let personalType of personalTypes">{{personalType.Description+'('+personalType.Value+')'}}</th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsInspeccion" class="array">
                        <div class="icons-requirements">
                            <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                            </div>
                        <td style="text-align: center;" *ngFor="let personalType of personalTypes">
                            <mat-slide-toggle [checked]="!cheketRequisitoProyect(personalType.Value, requirement.Id)" (change)="checkedWithPersonalTypeId($event, personalType.Id, requirement.Id)"></mat-slide-toggle>
                        </td>
                    </tr>
                    <tr>
                        <th style="min-width: 190px;">Inspecciones con comparación </th>
                        <th style="padding: 8px 16px;" *ngFor="let personalType of personalTypes">{{personalType.Description+'('+personalType.Value+')'}}</th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsInspeccionParam" class="array">
                        <div class="icons-requirements">
                                <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                            </div>
                        <td style="text-align: center;" *ngFor="let personalType of personalTypes">
                            <mat-slide-toggle [checked]="!cheketRequisitoProyect(personalType.Value, requirement.Id)" (change)="checkedWithPersonalTypeId($event, personalType.Id, requirement.Id)"></mat-slide-toggle>
                        </td>
                    </tr>
                    <tr>
                        <th style="min-width: 190px;">Encuestas </th>
                        <th style="padding: 8px 16px;" *ngFor="let personalType of personalTypes">{{personalType.Description+'('+personalType.Value+')'}}</th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsEncuesta" class="array">
                        <div class="icons-requirements">
                                <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                            </div>
                        <td style="text-align: center;" *ngFor="let personalType of personalTypes">
                            <mat-slide-toggle [checked]="!cheketRequisitoProyect(personalType.Value, requirement.Id)" (change)="checkedWithPersonalTypeId($event, personalType.Id, requirement.Id)"></mat-slide-toggle>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Acceso de Personal -->
        <div class="acceso-proyect" *ngIf="data.typeAdmin === 'Personal' || data.typeAdmin === 'Contract'">
            <table>
                <tbody>
                    <tr>
                        <th style="min-width: 190px;">Documentos </th>
                        <th style="padding: 8px 16px;" *ngFor="let personalType of personalTypes">
                            <div class="requisitos-proyects">
                                <div style="padding: 8px 16px;">{{personalType.Description+'('+personalType.Value+')'}}</div>
                                <div style="padding: 8px 16px;" *ngFor="let contractIdProyect of contractIdProyects">
                                    <div>{{contractIdProyect.Name}}</div>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsDocuments" class="array">
                        <div class="icons-requirements">
                                <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                            </div>
                        <td style="text-align: center;" *ngFor="let personalType of personalTypes">
                            <div class="requisitos-proyects th">
                                <mat-slide-toggle style="padding: 8px 16px;"
                                    [disabled]='true'
                                    [checked]="true"
                                    *ngIf='cheketRequisitoProyect(personalType.Value, requirement.Id)'>
                                </mat-slide-toggle>
                                <mat-slide-toggle style="padding: 8px 16px;" 
                                    #slideToggle 
                                    *ngIf='!cheketRequisitoProyect(personalType.Value, requirement.Id)' 
                                    [checked]="cheketRequisitoProyect(personalType.Value, requirement.Id, true)"
                                    (change)="checked($event, requirement.Id)">
                                </mat-slide-toggle>
                                <div style="padding: 8px 16px;" *ngFor="let contractIdProyect of contractIdProyects">
                                    <mat-icon class="close" *ngIf="!cheketRequisitoPersonal(personalType.Value, requirement.Id, contractIdProyect.ProjectId)">close</mat-icon>
                                    <mat-icon class="check_circle" *ngIf="cheketRequisitoPersonal(personalType.Value, requirement.Id, contractIdProyect.ProjectId)">check_circle</mat-icon>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th style="min-width: 190px;">Documentos con vigencia </th>
                        <th style="padding: 8px 16px;" *ngFor="let personalType of personalTypes">
                            <div class="requisitos-proyects">
                                <div style="padding: 8px 16px;">{{personalType.Description+'('+personalType.Value+')'}}</div>
                                <div style="padding: 8px 16px;" *ngFor="let contractIdProyect of contractIdProyects">
                                    <div>{{contractIdProyect.Name}}</div>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsDocumentsVigenci" class="array">
                        <div class="icons-requirements">
                                <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                            </div>
                        <td style="text-align: center;" *ngFor="let personalType of personalTypes">
                            <div class="requisitos-proyects th">
                                <mat-slide-toggle style="padding: 8px 16px;"
                                    [disabled]='true'
                                    [checked]="true"
                                    *ngIf='cheketRequisitoProyect(personalType.Value, requirement.Id)'>
                                </mat-slide-toggle>
                                <mat-slide-toggle style="padding: 8px 16px;" 
                                    #slideToggle 
                                    *ngIf='!cheketRequisitoProyect(personalType.Value, requirement.Id)' 
                                    [checked]="cheketRequisitoProyect(personalType.Value, requirement.Id, true)"
                                    (change)="checked($event, requirement.Id)">
                                </mat-slide-toggle>
                                <div style="padding: 8px 16px;" *ngFor="let contractIdProyect of contractIdProyects">
                                    <mat-icon class="close" *ngIf="!cheketRequisitoPersonal(personalType.Value, requirement.Id, contractIdProyect.ProjectId)">close</mat-icon>
                                    <mat-icon class="check_circle" *ngIf="cheketRequisitoPersonal(personalType.Value, requirement.Id, contractIdProyect.ProjectId)">check_circle</mat-icon>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th style="min-width: 190px;">Inspecciones </th>
                        <th style="padding: 8px 16px;" *ngFor="let personalType of personalTypes">
                            <div class="requisitos-proyects">
                                <div style="padding: 8px 16px;">{{personalType.Description+'('+personalType.Value+')'}}</div>
                                <div style="padding: 8px 16px;" *ngFor="let contractIdProyect of contractIdProyects">
                                    <div>{{contractIdProyect.Name}}</div>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsInspeccion" class="array">
                        <div class="icons-requirements">
                                <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                            </div>
                        <td style="text-align: center;" *ngFor="let personalType of personalTypes">
                            <div class="requisitos-proyects th">
                                <mat-slide-toggle style="padding: 8px 16px;"
                                    [disabled]='true'
                                    [checked]="true"
                                    *ngIf='cheketRequisitoProyect(personalType.Value, requirement.Id)'>
                                </mat-slide-toggle>
                                <mat-slide-toggle style="padding: 8px 16px;" 
                                    #slideToggle 
                                    *ngIf='!cheketRequisitoProyect(personalType.Value, requirement.Id)' 
                                    [checked]="cheketRequisitoProyect(personalType.Value, requirement.Id, true)"
                                    (change)="checked($event, requirement.Id)">
                                </mat-slide-toggle>
                                <div style="padding: 8px 16px;" *ngFor="let contractIdProyect of contractIdProyects">
                                    <mat-icon class="close" *ngIf="!cheketRequisitoPersonal(personalType.Value, requirement.Id, contractIdProyect.ProjectId)">close</mat-icon>
                                    <mat-icon class="check_circle" *ngIf="cheketRequisitoPersonal(personalType.Value, requirement.Id, contractIdProyect.ProjectId)">check_circle</mat-icon>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th style="min-width: 190px;">Inspecciones con comparación </th>
                        <th style="padding: 8px 16px;" *ngFor="let personalType of personalTypes">
                            <div class="requisitos-proyects">
                                <div style="padding: 8px 16px;">{{personalType.Description+'('+personalType.Value+')'}}</div>
                                <div style="padding: 8px 16px;" *ngFor="let contractIdProyect of contractIdProyects">
                                    <div>{{contractIdProyect.Name}}</div>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsInspeccionParam" class="array">
                        <div class="icons-requirements">
                                <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                            </div>
                        <td style="text-align: center;" *ngFor="let personalType of personalTypes">
                            <div class="requisitos-proyects th">
                                <mat-slide-toggle style="padding: 8px 16px;"
                                    [disabled]='true'
                                    [checked]="true"
                                    *ngIf='cheketRequisitoProyect(personalType.Value, requirement.Id)'>
                                </mat-slide-toggle>
                                <mat-slide-toggle style="padding: 8px 16px;" 
                                    #slideToggle 
                                    *ngIf='!cheketRequisitoProyect(personalType.Value, requirement.Id)' 
                                    [checked]="cheketRequisitoProyect(personalType.Value, requirement.Id, true)"
                                    (change)="checked($event, requirement.Id)">
                                </mat-slide-toggle>
                                <div style="padding: 8px 16px;" *ngFor="let contractIdProyect of contractIdProyects">
                                    <mat-icon class="close" *ngIf="!cheketRequisitoPersonal(personalType.Value, requirement.Id, contractIdProyect.ProjectId)">close</mat-icon>
                                    <mat-icon class="check_circle" *ngIf="cheketRequisitoPersonal(personalType.Value, requirement.Id, contractIdProyect.ProjectId)">check_circle</mat-icon>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th style="min-width: 190px;">Encuestas </th>
                        <th style="padding: 8px 16px;" *ngFor="let personalType of personalTypes">
                            <div class="requisitos-proyects">
                                <div style="padding: 8px 16px;">{{personalType.Description+'('+personalType.Value+')'}}</div>
                                <div style="padding: 8px 16px;" *ngFor="let contractIdProyect of contractIdProyects">
                                    <div>{{contractIdProyect.Name}}</div>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsEncuesta" class="array">
                        <div class="icons-requirements">
                                <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                            </div>
                        <td style="text-align: center;" *ngFor="let personalType of personalTypes">
                            <div class="requisitos-proyects th">
                                <mat-slide-toggle style="padding: 8px 16px;"
                                    [disabled]='true'
                                    [checked]="true"
                                    *ngIf='cheketRequisitoProyect(personalType.Value, requirement.Id)'>
                                </mat-slide-toggle>
                                <mat-slide-toggle style="padding: 8px 16px;" 
                                    #slideToggle 
                                    *ngIf='!cheketRequisitoProyect(personalType.Value, requirement.Id)' 
                                    [checked]="cheketRequisitoProyect(personalType.Value, requirement.Id, true)"
                                    (change)="checked($event, requirement.Id)">
                                </mat-slide-toggle>
                                <div style="padding: 8px 16px;" *ngFor="let contractIdProyect of contractIdProyects">
                                    <mat-icon class="close" *ngIf="!cheketRequisitoPersonal(personalType.Value, requirement.Id, contractIdProyect.ProjectId)">close</mat-icon>
                                    <mat-icon class="check_circle" *ngIf="cheketRequisitoPersonal(personalType.Value, requirement.Id, contractIdProyect.ProjectId)">check_circle</mat-icon>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="actions-dialog"style="margin: 3px;">
        <button mat-button class="btn-cancelar" (click)="close()" >CANCELAR</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" (click)="save()">GUARDAR</button>
    </div>
</div> 
