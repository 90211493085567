<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'EMPLEADO' || 'N/A'}}</div>
    </div>
    
    <div class="content-card list-card" >
        <button class="uploadPhoto" [ngClass]="formDataPhoto === undefined ? 'borderBtn': ''" (click)="updatePhotoPersonal()">SUBIR UNA FOTO</button>
        <img style="margin-bottom: 10px; margin-top: 10px;" *ngIf="imgCropperResult" [src]="imgCropperResult" alt="">
            <!-- <div><strong>{{personal.Name + ' ' + personal.LastName || 'Sin Title'}}</strong></div> -->
        <form [formGroup]="formPersonal" (ngSubmit)="onSubmit()">
            <app-select-search class="select-component-medium"
                        [_dataSource]="nationality" [textInput]="'NACIONALIDAD'" [dataShow]="'Description'" [dataValue]="'Value'" [siteCtrl]="formPersonal.get('Nationality')" 
                        [inputType]="'text'">
            </app-select-search>
            <div class="fields-group">
                <mat-form-field class="input-field-medium">
                    <label><strong>TIPO</strong></label>
                    <mat-select formControlName="DocumentType" (selectionChange)="changeType($event.value)">
                        <mat-option *ngFor="let data of companyDocumentType" [value]="data.Value">
                            {{(data.Description === 'C.C.' ? 'Cedula': data.Description)}}
                        </mat-option>
                    </mat-select>
                    <mat-error>Requerido</mat-error>
                </mat-form-field> 
                <mat-form-field class="input-field-medium">
                    <label><strong>NÚMERO</strong></label>
                    <input matInput type="text" formControlName="DocumentNumber" maxlength="{{maxCaracter}}">
                    <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('DocumentNumber').setValue('')">X</div> -->
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
            </div>
            <mat-form-field class="input-field toUpperCaseInput">
                <label><strong>NOMBRE</strong></label>
                <input matInput type="text" formControlName="Name">
                <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('Name').setValue('')">X</div> -->
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="input-field toUpperCaseInput">
                <label><strong>APELLIDOS</strong></label>
                <input matInput type="text" formControlName="LastName">
                <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('LastName').setValue('')">X</div> -->
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <div class="fields-group">
                <mat-icon (click)="picker.open()" style="cursor: pointer;">today</mat-icon>
                <mat-form-field class="input-field-medium">
                    <label><strong>FECH. NACIMIENTO</strong></label>
                    <input matInput [matDatepicker]="picker" formControlName="BirthDate" (click)="picker.open()">
                    <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('BirthDate').setValue('')">X</div> -->
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
                <app-select-search class="select-component-short"
                        [_dataSource]="sex" [textInput]="'SEXO'" [dataShow]="'Value'" [dataValue]="'Value'" [siteCtrl]="formPersonal.get('Sex')" 
                        [inputType]="'text'">
                </app-select-search>
                <app-select-search class="select-component-short"
                        [_dataSource]="rh" [textInput]="'TIPO'" [dataShow]="'Description'" [dataValue]="'Value'" [siteCtrl]="formPersonal.get('RH')" 
                        [inputType]="'text'">
                </app-select-search>
            </div>
            <app-select-search class="select-component-short"
                        [_dataSource]="countrys" [textInput]="'PAIS DE NACIMIENTO'" [dataShow]="'Description'" [dataValue]="'Value'" [siteCtrl]="formPersonal.get('contryNalcionality')" 
                        [inputType]="'text'" (onSelectionChange)="getCitiesNacimiento($event)">
            </app-select-search>
            <app-select-search class="select-component-short"
                        [_dataSource]="citiesNacimiento" [textInput]="'CIUDAD DE NACIMIENTO'" [dataShow]="'Name'" [dataValue]="'Code'" [siteCtrl]="formPersonal.get('CityBirthCode')" 
                        [inputType]="'text'">
            </app-select-search>
            <!-- <app-select-search class="select-component"
                        [_dataSource]="jobs" [textInput]="'OCUAPCIÓN'" [dataShow]="'Name'" [dataValue]="'Code'" [siteCtrl]="formPersonal.get('JobCode')" 
                        [inputType]="'text'">
            </app-select-search>
            <div class="sub-header">
                <div class="sub-content">{{'CONTACTO' || 'N/A'}}</div>
            </div>
            <br>
            <app-select-search class="select-component-short"
                        [_dataSource]="daneCity" [textInput]="'CIUDAD'" [dataShow]="'CityName'" [dataValue]="'CityCode'" [siteCtrl]="formPersonal.get('CityCode')" 
                        [inputType]="'text'">
            </app-select-search>
            <mat-form-field class="input-field">
                <label><strong>DIRECCIÓN</strong></label>
                <input matInput type="text" formControlName="Address">
                <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('Address').setValue('')">X</div>
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <div class="fields-group">
                <mat-form-field class="input-field-50">
                    <label><strong>CORREO</strong></label>
                    <input matInput type="text" formControlName="Email">
                    <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('Email').setValue('')">X</div>
                    <mat-error>formato incorrecto / Requerido</mat-error>
                </mat-form-field>
                <mat-form-field class="input-field-50">
                    <label><strong>TELEFONO</strong></label>
                    <input matInput type="text" formControlName="PhoneNumber">
                    <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('PhoneNumber').setValue('')">X</div>
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
            </div>
            <app-select-search class="select-component-medium" 
                [_dataSource]="eps" [textInput]="'EPS'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formPersonal.get('EpsId')" 
                [inputType]="'text'" >
            </app-select-search>
            <app-select-search class="select-component-medium"
                [_dataSource]="afp" [textInput]="'AFP'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formPersonal.get('AfpId')" 
                [inputType]="'text'">
            </app-select-search>
            <div class="sub-header">
                <div class="sub-content">{{'EN CASO DE EMERGENCIA LLAMAR A' || 'N/A'}}</div>
            </div>
            <br>
            <div class="fields-group">
                <mat-form-field class="input-field-50">
                    <label><strong>NOMBRE</strong></label>
                    <input matInput type="text" formControlName="EmergencyContact">
                    <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('EmergencyContact').setValue('')">X</div>
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
                <mat-form-field class="input-field-50">
                    <label><strong>TELEFONO</strong></label>
                    <input matInput type="text" formControlName="EmergencyContactPhone">
                    <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('EmergencyContactPhone').setValue('')">X</div>
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
            </div> -->
            <div class="actions-dialog"style="margin: 3px;">
                <button mat-button class="btn-cancelar" (click)="close()">CANCELAR</button>
                <button mat-button class="btn-generar" type="submit">GUARDAR</button>
            </div>
        </form>
        
    </div>
   
    
</div>