import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContratingService } from '../../../services/modules/contrating.service';
import { environment } from 'src/environments/environment';
import { CommonOptions } from '../../../interfaces/interfaces';
import { CommonService } from '../../../services/modules/common.service';
import { SnackBarService } from '../../../services/utils/snackBar.service';
import { DialogService } from '../../../services/utils/dialog.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { UtilService } from 'src/app/core/services/utils/utils.service';

export interface DialogData {
  message: string;
  CompanyInfoParentId: any;
}

export interface EmployerManger{
  CompanyInfoParentId: string;
  DocumentNumber: string;
  DocumentType: string;
  Logo: string;
  Name: string;
}

@Component({
  selector: 'app-dialog-search-employer',
  templateUrl: './dialog-search-employer.component.html',
  styleUrls: ['./dialog-search-employer.component.scss']
})
export class DialogSearchEmployerComponent implements OnInit {
  s3Bucket:string;

  controlEmployer = new FormControl();

  documentType: string;
  documentNumber: string = "";
  digito: string;

  employers: EmployerManger[] = [];
  employerSelected: any [] = [];

  companyDocumentType: CommonOptions[] = [];

  maxCaracter: number = 9;
  CompanyInfoParentId: any;

  constructor(
    public dialogRef: MatDialogRef<DialogSearchEmployerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private contratingService: ContratingService,
    private commonService: CommonService,
    private snackBService: SnackBarService,
    private dialogService: DialogService,
    private router: Router,
    private utilService: UtilService
  ) {
    this.CompanyInfoParentId = this.data.CompanyInfoParentId;
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    setTimeout(() => {
      this.getServices();
      this.getManagedEmployers();
    }, 300);
  }


  setValue() {
    if (this.controlEmployer.value !== undefined) {
      this.controlEmployer.setValue('');
      this.employerSelected = [];
      this.documentNumber = '';
      this.documentType = 'CC';
    }
  }

  setValuePersonal() {
    this.digito = '';
    this.documentNumber = '';
    this.documentType = 'NIT';
  }

  valueSelected(e) {
    // console.log('e',e);
    this.employerSelected = [];
    this.employers.forEach((employer: any) => {
      employer.CompanyId === e ? this.employerSelected.push(employer): ''
    });

  }

  cahngeMaxCaracter(e) {
    if(e.value === 'NIT') {
      this.maxCaracter = 9;
      this.documentNumber = '';
    } else {
      this.maxCaracter = 11;
      this.documentNumber = '';
    }
  }

  search() {
    this.validateNit();
    if((this.documentType === 'NIT') && (this.documentNumber === '' || this.digito === undefined || this.flagValidNit === false)) {
      this.snackBService.openSnackBar('Compruebe los datos ingresados', 'X', 4000);
    } else {
      this.commonService.getSearchEmployerDocumentTypeName(this.documentType, this.documentNumber).subscribe(employer => {
        this.router.navigate(['/admin/employers/employer',employer.Id]);
        this.dialogRef.close();
      }, (error: HttpErrorResponse) => {
        if(error.status === 404) {
          this.dialogService.openDialogConfirm(`Lo sentimos, no hay una empresa registrada con este número. Desea crearla?`).afterClosed().subscribe(rest =>{
            // console.log(this.utilService.calcularDigito(this.documentNumber));
            if(rest !== false) {
              this.dialogService.openDialogCreateEmployer('', this.documentNumber, this.documentType, '', (this.digito === '' ? '': this.digito), this.CompanyInfoParentId).afterClosed().subscribe(restDialog =>{
                // console.log(restDialog);
                if(restDialog !== undefined) {
                  this.router.navigate(['/admin/employers/employer',restDialog.Id]);
                  this.dialogRef.close();
                }
              });
            }
          });
        } else if(error.status === 412) {
          this.dialogService.openDialogConfirm(`La empresa esta creada pero no está vinculada a su(s) proyecto(s). Debe crearle un contrato a esta empresa.`, false , true, 'CERRAR').afterClosed().subscribe(restConfirm412 =>{
            if(restConfirm412 !== false) {
              this.router.navigate(['/admin/contract']);
              this.dialogRef.close();
            }
          });
        }else if(error.status === 424) {
          // console.log('error1',error.error);
          this.dialogService.openDialogConfirm(`Lo sentimos, no hay una empresa registrada con este número. Desea crearla?`).afterClosed().subscribe(rest =>{
            // console.log(this.utilService.calcularDigito(this.documentNumber));
            if(rest !== false) {
              this.dialogService.openDialogCreateEmployer(error.error, this.documentNumber, this.documentType, '', (this.digito === '' ? '': this.digito), this.CompanyInfoParentId).afterClosed().subscribe(restDialog =>{
                // console.log(restDialog);
                if(restDialog !== undefined) {
                  this.router.navigate(['/admin/employers/employer',restDialog.Id]);
                  this.dialogRef.close();
                }
              });
            }
          });
        }
      });
    }
  }

  flagValidNit: boolean = true;
  validateNit() {
    if((this.documentType === 'NIT') && (this.documentNumber !== '' && this.digito !== undefined)) {
      if(parseInt(this.digito) !== this.utilService.calcularDigito(this.documentNumber)) {
        this.flagValidNit = false;
      } else {
        this.flagValidNit = true;
      }
    }
  }

  getServices() {
    this.commonService.getCommonOptions("CompanyDocumentType").subscribe((data: CommonOptions[]) => {
      this.companyDocumentType = data.filter(documents => documents.Value === 'CC' || documents.Value === 'NIT')
            .sort((a,b) => a.OrderNum > b.OrderNum ? 1 : -1);
      this.documentType = 'NIT';
    });
  }

  getManagedEmployers() {
    this.contratingService.getManagedEmployers().subscribe((rest: any) => {
      this.employers = rest.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
    });
  }

  getImg(logo) {
    let img = (logo === null || logo === '' || logo === undefined ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg': logo);
    return img;
  }

}
