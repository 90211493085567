import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { SurveySymptomsValidateAds } from '../../../../interfaces/interfaces';

export interface DialogData {
  message: string;
  confirm: boolean;
  buttonText: string;
  surveySymptoms: SurveySymptomsValidateAds;
  color: number;
}

@Component({
  selector: 'app-dialog-ok',
  templateUrl: './dialog-ok.component.html',
  styleUrls: ['./dialog-ok.component.scss']
})
export class DialogOkComponent implements OnInit {

  compnayId: string = '';

  constructor(
    private router: Router,
    private ar: ActivatedRoute,
    public dialogRef: MatDialogRef<DialogOkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
) {
  if(this.router.url !== '/SymptomReport') {
    this.ar.params.subscribe((parametros) => {
      this.compnayId = parametros.CompanyId;
    });
  }
 }

onNoClick(): void {
this.dialogRef.close();
}

routerSesion() {
  if(this.router.url === '/SymptomReport') {
    this.router.navigate(['/auth/AddSessionSymptomReport']);
  } else {
      this.router.navigate(['/admin/companies/companyId', this.compnayId]);
  }
}

ngOnInit() { }

}
