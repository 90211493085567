import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContratingService } from '../../../services/modules/contrating.service';
import { environment } from 'src/environments/environment';
import { CommonOptions } from '../../../interfaces/interfaces';
import { CommonService } from '../../../services/modules/common.service';
import { SnackBarService } from '../../../services/utils/snackBar.service';
import { DialogService } from '../../../services/utils/dialog.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { FechasService } from 'src/app/core/services/utils/fechas.service';
import { CommonMetodService } from 'src/app/core/services/utils/commonMetod.service';
import { PersonalService } from 'src/app/core/services/modules/personal.service';
import { ContractService } from 'src/app/core/services/modules/contract.service';
import { RequestService } from 'src/app/core/services/modules/request.service';

export interface DialogData {
  CompanyInfoId: any;
  IsRequest: boolean;
  isFromPersonalList: boolean;
}

@Component({
  selector: 'app-dialog-enrolar-personal-with-contract',
  templateUrl: './dialog-enrolar-personal-with-contract.component.html',
  styleUrls: ['./dialog-enrolar-personal-with-contract.component.scss']
})
export class DialogEnrolarPersonalWithContractComponent implements OnInit {
  flagExitproyects;
  s3Bucket: string;
  documentType: string;
  documentNumber = '';

  controlEmployer = new FormControl();
  controlContractSelected = new FormControl();

  proyects: any[] = [];
  contracts: any[] = [];
  proyectSelected: any [] = [];
  personalSelected: any [] = [];
  contractsSelected: any [] = [];

  states: CommonOptions[] = [];
  lastStages: CommonOptions[] = [];
  companyDocumentType: CommonOptions[] = [];

  permissionsCompany: string[] = [];
  maxCaracter = 11;
  flagExitContractContracts = true;

  constructor(
    public dialogRef: MatDialogRef<DialogEnrolarPersonalWithContractComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private contratingService: ContratingService,
    private personalService: PersonalService,
    private commonService: CommonService,
    private snackBService: SnackBarService,
    private dialogService: DialogService,
    private router: Router,
    private contractService: ContractService,
    private requestService: RequestService,
    private fechasService: FechasService,
    public commonMetodService: CommonMetodService
  ) {
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    this.commonService.getProyect().subscribe((proyects: any[]) => {
        this.proyects = proyects.filter(proy => proy.CompanyInfoId === this.data.CompanyInfoId && proy.IsActive === true);
        if (this.proyects.length === 1) {
          this.proyectSelected.push(this.proyects[0]);
          this.valueSelectedProyect(this.proyectSelected[0].Id);
        }
        this.proyects.length === 0 ? this.flagExitproyects = true : this.flagExitproyects = false;
      });
    this.getServices();
  }


  setValue() {
    if (this.controlEmployer.value !== undefined) {
      this.controlEmployer.setValue('');
      this.controlContractSelected.setValue('');
      this.proyectSelected = [];
      this.personalSelected = [];
      this.contractsSelected = [];
      this.documentNumber = '';
      this.documentType = 'CC';
      this.flagExitContractContracts = true;
    }
  }

  setValueContract() {
    if (this.controlContractSelected.value !== undefined) {
      this.controlContractSelected.setValue('');
      this.contractsSelected = [];
      this.flagExitContractContracts = true;
    }
  }

  setValuePersonal() {
    this.personalSelected = [];
    this.documentNumber = '';
    this.documentType = 'CC';
  }

  changeType(e) {
    if (e === 'PE') {
      this.maxCaracter = 16;
    } else if (e === 'CC') {
      this.maxCaracter = 11;
    } else {
      this.maxCaracter = 20;
    }
  }
  valueSelectedProyect(e) {
    this.proyectSelected = [];
    this.proyects.forEach((proyect: any) => {
      proyect.Id === e ? this.proyectSelected.push(proyect) : '';
    });
    if (this.proyectSelected.length > 0) {
      this.commonService.getContract().subscribe((contracts: any[]) => {
        this.contracts = [];
        if (this.data.isFromPersonalList){
          this.isFromPersonalContractsPermission(contracts);
        }else {
          contracts.forEach(contrac => {
            if (contrac.IsActive === true) {
              contrac.ProjectIds.forEach(proyect => {
                if (proyect === this.proyectSelected[0].Id) {
                  this.contracts.push(contrac);
                }
              });
            }
          });
        }
        if (this.contracts.length === 1) {
          this.contractsSelected.push(this.contracts[0]);
          this.flagExitContractContracts = true;
        } else if (this.contracts.length === 0) {
          this.flagExitContractContracts = false;
        }
      });
    }

  }

  isFromPersonalContractsPermission(contracts) {
    contracts.forEach(contract => {
      if (contract.IsActive === true && contract.ProjectIds.includes(this.proyectSelected[0].Id)) {
        if (this.exitPermissionCompany('personalsad.add') && contract.ValuePersonalType === 'AD') {
          this.contracts.push(contract);
        } else if (this.exitPermissionCompany('personalsco.add') && contract.ValuePersonalType === 'CO') {
          this.contracts.push(contract);
        } else if (this.exitPermissionCompany('personalsas.add') && contract.ValuePersonalType === 'AS') {
          this.contracts.push(contract);
        } else if (this.exitPermissionCompany('personalsfu.add') && contract.ValuePersonalType === 'FU') {
          this.contracts.push(contract);
        } else if (this.exitPermissionCompany('personalsvi.add') && contract.ValuePersonalType === 'VI') {
          this.contracts.push(contract);
        } else if (this.exitPermissionCompany('personalspr.add') && contract.ValuePersonalType === 'PR') {
          this.contracts.push(contract);
        } else if (this.exitPermissionCompany('personalsot.add') && contract.ValuePersonalType === 'OT') {
          this.contracts.push(contract);
        }
      }
    });
  }

  getServices() {
    this.companyDocumentType = this.commonService.getCommonOptionsLocal('CoPs_CompanyDocumentType')
      .filter(
      documents => documents.Value === 'CC' || documents.Value === 'CE' || documents.Value === 'PE' || documents.Value === 'PPT')
      .sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
    this.documentType = 'CC';
    this.lastStages = this.commonService.getCommonOptionsLocal('CoPs_PersonalContractStage');
    this.commonService.getUserInfo().subscribe(userinfo => {
      this.permissionsCompany = userinfo.Companies.find(x => x.Id === this.data.CompanyInfoId).Permissions;
    });

  }

  getPersonalDocument() {
    if (this.contractsSelected.length > 0) {
      this.contratingService.getPersonalBlackList(this.documentType, this.commonMetodService._replaceAll(this.documentNumber, '.', ''),
        this.data.CompanyInfoId).subscribe(personBlackList => {
        if (personBlackList === true) {
          this.dialogService.openDialogConfirm(`Lo sentimos, no podemos tramitar su solicitud`, false).afterClosed().subscribe(rest => {});
        } else {
          this.personalService.getPersonalDocNumContractId(this.commonMetodService._replaceAll(this.documentNumber, '.', ''),
            this.contractsSelected[0].Id).subscribe((data: any) => {
            if (data !== null) {
              this.personalSelected = [];
              this.personalSelected.push(data);
              this.iniciar();
            }
          }, (error: HttpErrorResponse) => {
              if (error.status === 400 || error.status === 404){
                this.dialogService.openDialogConfirm(
                  `Lo sentimos, no hay una persona registrada con este número. Desea crearla?`).afterClosed().subscribe(rest => {
                  if (rest !== false) {
                    this.dialogService.openDialogCreatePersonInfoCompany(this.contractsSelected[0].FinishDate,
                      this.contractsSelected[0].StartDate, this.contractsSelected[0].Id, this.data.CompanyInfoId,
                      this.commonMetodService._replaceAll(this.documentNumber, '.', ''), this.documentType,
                      undefined, this.data.IsRequest,
                      this.contractsSelected[0].ValueContractType).afterClosed().subscribe((restCreate: any) => {
                      if (this.data.IsRequest === true) {
                        this.requestService.postRequestStage(restCreate.modelResponse.Id,
                          {LastStageId: this.getLastStageId()}).subscribe((restRequestStage: any) => {
                          this.router.navigate(['/admin/request/request', restCreate.modelResponse.PersonalCompanyInfoId,
                            restRequestStage.PersonalContractId]);
                          this.dialogRef.close(true);
                          // });
                        });
                      } else {
                        this.router.navigate(['/admin/personal/person/', restCreate.modelResponse.PersonalCompanyInfoId]);
                        this.dialogRef.close(true);
                      }
                    });
                  }
                });
              }
              if (error.status === 412) {
              this.dialogService.openDialogConfirm(
                `Lo sentimos, no hay una persona registrada con este número. Desea crearla?`).afterClosed().subscribe(rest => {
                if (rest !== false) {
                  const modelCretePersonal = {
                    AfpId: error.error.AfpId,
                    BirthDate: error.error.BirthDate,
                    CityOfBirthCode: error.error.CityOfBirthCode,
                    CreateDate: error.error.CreateDate,
                    DocumentNumber: error.error.DocumentNumber,
                    DocumentRaw: error.error.DocumentRaw,
                    DocumentType: error.error.DocumentType,
                    EpsId: error.error.EpsId,
                    Height: error.error.Height,
                    LastName: error.error.LastName,
                    Name: error.error.Name,
                    Nationality: error.error.Nationality,
                    RH: error.error.RH,
                    SecurityReferenceAFP: error.error.SecurityReferenceAFP,
                    SecurityReferenceEPS: error.error.SecurityReferenceEPS,
                    Sex: error.error.Sex,
                    JobCode: 'D-0000.000',
                    CompanyInfoId: this.data.CompanyInfoId
                  };
                  this.personalService.postPersonalInfoCompany(modelCretePersonal).subscribe((restPostPersonal: any) => {
                    if (this.data.IsRequest === true) {
                      const pesonalCompany = {
                        AfpId: restPostPersonal.AfpId,
                        ContractId: this.contractsSelected[0].Id,
                        EmployerId: null,
                        EpsId: restPostPersonal.EpsId,
                        // FinishDate: this.contractsSelected[0].FinishDate,
                        FinishDate: this.fechasService.getDateFormatDatePikerDATEHORANOWNotSecunt(),
                        // NameARL: "ARL DESCONOCIDA",
                        PersonalCompanyInfoId: restPostPersonal.PersonalCompanyInfoId,
                        Position: 'Función Desconocida',
                        // StartDate: this.contractsSelected[0].StartDate,
                        StartDate: this.fechasService.getDateFormatDatePikerDATEHORANOWNotSecunt(),
                        TypeARL: 'V'
                      };
                      this.personalService.postContractPersonalInfo(this.contractsSelected[0].Id,
                        pesonalCompany, true).subscribe((restContractPersonal: any) => {
                        this.requestService.postRequestStage(restContractPersonal.Id,
                          {LastStageId: this.getLastStageId()}).subscribe((restRequestStage: any) => {
                          this.router.navigate(['/admin/request/request', restPostPersonal.PersonalCompanyInfoId,
                            restRequestStage.PersonalContractId]);
                          this.dialogRef.close(true);
                        });
                      });
                    } else {
                      const pesonalCompany = {
                        AfpId: restPostPersonal.AfpId,
                        ArlId: 81,
                        ContractId: this.contractsSelected[0].Id,
                        EmployerId: null,
                        EpsId: restPostPersonal.EpsId,
                        FinishDate: this.contractsSelected[0].FinishDate,
                        NameARL: 'ARL DESCONOCIDA',
                        PersonalCompanyInfoId: restPostPersonal.PersonalCompanyInfoId,
                        Position: 'Función Desconocida',
                        // StartDate: this.contractsSelected[0].StartDate,
                        StartDate: this.fechasService.getDateFormatDatePikerDATEHORANOWNotSecunt(),
                        TypeARL: 'V'
                      };
                      this.personalService.postContractPersonalInfo(this.contractsSelected[0].Id,
                        pesonalCompany).subscribe((restContractPersonal: any) => {
                        this.router.navigate(['/admin/personal/person/', restContractPersonal.PersonalCompanyInfoId]);
                        this.dialogRef.close(true);
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    } else {
      this.snackBService.openSnackBar('PRIMERO SELECCIONE EL PROYECTO Y CONTRATO', 'X', 4000);
    }
  }

  onSelectionChangeContract(e) {
    this.contractsSelected = [];
    this.contracts.forEach((contract: any) => {
      contract.Id === e ? this.contractsSelected.push(contract) : '';
    });
  }

  getDisabled(): boolean {
    let enabled: boolean;
    this.personalSelected.length === 1 && this.proyectSelected.length === 1 &&
    this.contractsSelected.length === 1 ? enabled = true : enabled = false;
    return enabled;
  }

  iniciar() {
    let personalCompany = {};
    let finishDatePut = '';

    if (this.personalSelected[0].FinishDate !== null && this.personalSelected[0].FinishDate !== '') {
      finishDatePut = this.personalSelected[0].FinishDate;
    }else{
      if (this.contractsSelected[0].FinishDate !== null || this.contractsSelected[0].FinishDate !== '' ||
        this.contractsSelected[0].FinishDate !== undefined) {
        finishDatePut = this.contractsSelected[0].FinishDate;
      }
    }
    if (this.data.IsRequest === true) {
      personalCompany = {
        AfpId: this.personalSelected[0].AfpId,
        ContractId: this.contractsSelected[0].Id,
        EmployerId: null,
        EpsId: this.personalSelected[0].EpsId,
        FinishDate: null,
        // NameARL: "ARL DESCONOCIDA",
        PersonalCompanyInfoId: this.personalSelected[0].PersonalCompanyInfoId,
        Position: 'Función Desconocida',
        StartDate: null,
        TypeARL: 'V'
      };
    }else if (this.data.isFromPersonalList === true){
      personalCompany = {
        AfpId: this.personalSelected[0].AfpId,
        ContractId: this.contractsSelected[0].Id,
        EmployerId: null,
        EpsId: this.personalSelected[0].EpsId,
        FinishDate: this.data.IsRequest === false ? this.contractsSelected[0].FinishDate :
          this.fechasService.getDateFormatDatePikerDATEHORANOWNotSecunt(),
        // NameARL: "ARL DESCONOCIDA",
        PersonalCompanyInfoId: this.personalSelected[0].PersonalCompanyInfoId,
        Position: 'Función Desconocida',
        StartDate: this.fechasService.getDateFormatDatePikerDATEHORANOWNotSecunt(),
        TypeARL: 'V'
      };
    }
    if (this.data.IsRequest === true) {
      // Flujo de enrolar SOlICITUDES
      this.contractService.getIsContractPersonalCompanyInfoId(this.contractsSelected[0].Id,
        this.personalSelected[0].PersonalCompanyInfoId).subscribe(isContract => {
        this.requestService.getRequestStageByContract(this.personalSelected[0].PersonalCompanyInfoId,
        this.contractsSelected[0].Id).subscribe((restgetContract: any) => {
          this.personalService.postContractPersonalInfo(this.contractsSelected[0].Id, personalCompany,
            true).subscribe((restContractPersonal: any) => {
            this.requestService.getRequestStageByContract(restContractPersonal.PersonalCompanyInfoId,
              this.contractsSelected[0].Id).subscribe((restgetContract: any) => {
                if (restgetContract.LastStage.Value === 'SOL') {
                  this.router.navigate(['/admin/request/request', restgetContract.PersonalContract.PersonalCompanyInfoId ,
                    restgetContract.PersonalContract.Id]);
                  this.dialogRef.close(true);
                } else {
                    this.router.navigate(['/admin/request/request', restgetContract.PersonalContract.PersonalCompanyInfoId ,
                      restgetContract.PersonalContract.Id]);
                    this.dialogRef.close(true);
                }
              }, (error: HttpErrorResponse) => {
                if (error.status === 419){
                  this.snackBService.openSnackBar('No podemos tramitar la solictud', 'X', 4000);
                  this.dialogRef.close(true);
                } else if (error.status === 417){
                  // El personal tiene un contracto activo con otro proyecto
                  this.dialogService.openDialogConfirm('Lo sentimos, la persona actualmente esta vinculada y activa en un contrato.',
                    false, true, 'Cancelar', '',
                    'Solicite al administrador el translado o que sea inactivado en el contrato actual.').afterClosed().subscribe(rest => {
                    this.dialogRef.close(true);
                  });
                } else{
                  this.requestService.postRequestStage(restContractPersonal.Id, {LastStageId: this.getLastStageId()}).subscribe(
                    (restRequestStage: any) => {
                    this.router.navigate(['/admin/request/request', this.personalSelected[0].Id , restRequestStage.PersonalContractId]);
                    this.dialogRef.close(true);
                  });
                }
              });
            });
          }, (error: HttpErrorResponse) => {
            if (error.status === 419){
              this.snackBService.openSnackBar('No podemos tramitar la solictud', 'X', 4000);
              this.dialogRef.close(true);
            } else if (error.status === 417){
              this.dialogService.openDialogConfirm('Lo sentimos, la persona actualmente esta vinculada y activa en un contrato.',
                false, true, 'Cancelar', '',
                'Solicite al administrador el translado o que sea inactivado en el contrato actual.').afterClosed().subscribe(rest => {
                this.dialogRef.close(true);
              });
            } else if (error.status === 404){
              this.personalService.postContractPersonalInfo(this.contractsSelected[0].Id, personalCompany,
                true).subscribe((restContractPersonal: any) => {
                this.requestService.postRequestStage(restContractPersonal.Id, {LastStageId:
                    this.getLastStageId()}).subscribe((restRequestStage: any) => {
                  this.router.navigate(['/admin/request/request', this.personalSelected[0].Id , restRequestStage.PersonalContractId]);
                  this.dialogRef.close(true);
                });
              });
            }
          });
        }, (error: HttpErrorResponse) => {
        error.status === 400 ?
          this.dialogService.openDialogMovePersonalInfo(true, error, 'Desea trasladarlo?',
            this.data.IsRequest).afterClosed().subscribe(restDialog => {
            if (restDialog === true) {
              this.contractService.postMovePersonalInfoContract(this.contractsSelected[0].Id,
                personalCompany).subscribe((restMOvePersonal: any) => {
                  this.requestService.postRequestStage(restMOvePersonal.Id, {LastStageId:
                      this.getLastStageId()}).subscribe((restRequestStage: any) => {
                    this.router.navigate(['/admin/request/request', this.personalSelected[0].Id , restRequestStage.PersonalContractId]);
                    this.dialogRef.close(true);
                  });
              });
            }
          })
        : '';
      });
    } else {
       // Flujo de enrolar PERSONAL
      this.contractService.getIsContractPersonalCompanyInfoId(this.contractsSelected[0].Id,
        this.personalSelected[0].PersonalCompanyInfoId).subscribe(isContract => {
        this.personalService.postContractPersonalInfo(this.contractsSelected[0].Id,
          personalCompany).subscribe((restContractPersonal: any) => {
            this.router.navigate(['/admin/personal/person/', this.personalSelected[0].Id]);
            this.dialogRef.close(true);
         });
        }, (error: HttpErrorResponse) => {
        error.status === 400 ?
          this.dialogService.openDialogMovePersonalInfo(true, error, 'Desea trasladarlo?',
            this.data.IsRequest).afterClosed().subscribe(restDialog => {
            if (restDialog === true) {
              this.contractService.postMovePersonalInfoContract(this.contractsSelected[0].Id,
                personalCompany).subscribe((restMOvePersonal: any) => {
                this.router.navigate(['/admin/personal/person/', this.personalSelected[0].Id]);
                this.dialogRef.close(true);
              });
            }
          })
        : '';
      });
    }
  }

  getLastStageId(): number {
    let idStage = 0;
    this.lastStages.forEach((stage: CommonOptions) => {
      if (stage.Value === 'INI') {
        idStage = stage.Id;
      }
    });
    return idStage;
  }

  getImg(logo) {
    const img = (logo === null || logo === '' || logo === undefined ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg' : logo);
    return img;
  }

  getImgEmp(logo) {
    const img = (logo === null || logo === '' || logo === undefined ? 'assets/images/personblue.png' : logo);
    return img;
  }

  exitPermissionCompany(permiso: string): boolean {
    let exit = false;
    const permisionsUserInfo  = this.permissionsCompany.find(x => x === permiso);
    permisionsUserInfo !== undefined ? exit = true : exit = false;
    return exit;
  }

}
