import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {CommonOptions, ModelDocumentsShow, PersonalBlackList} from 'src/app/core/interfaces/interfaces';
import {SnackBarService} from 'src/app/core/services/utils/snackBar.service';
import {environment} from '../../../../../environments/environment';
import {FechasService} from '../../../services/utils/fechas.service';
import {ContratingService} from '../../../services/modules/contrating.service';
import {DocsJsonUtils} from '../../../utils/DocsJsonUtils';
import {CommonService} from '../../../services/modules/common.service';

export interface DialogData {
  textClose: string;
  textConfirm: string;
  documentType: string;
  documentNumber: string;
  companyInfoId: string;
  employers: any[];
}

@Component({
  selector: 'app-dialog-selected-causal',
  templateUrl: './dialog-selected-causal.component.html',
  styleUrls: ['./dialog-selected-causal.component.scss']
})
export class DialogSelectedCausalComponent implements OnInit {
  controlSelected = new FormControl();
  datasource: any[] = [];
  formVigencia: FormGroup;
  files: File[] = [];
  infoHistory: any[] = [];
  s3Bucket: string;
  commentary = '';
  allEmployers: any[] = [];
  minDateNowStart = this.fechasService.getDateTimeNow();
  dateNowStart = this.fechasService.getDateTimeNow();
  dateNowFinish = this.fechasService.getDateTimeNow(true);
  minDateNowFinish = this.fechasService.getDateTimeNow();
  causalesData: CommonOptions[] = [];
  flagBlackList = false;
  flagBlackListFuture = false;
  personalBlackListId = '0';
  endDateBlackList: string;
  employerControl = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<DialogSelectedCausalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBService: SnackBarService,
    private fb: FormBuilder,
    private fechasService: FechasService,
    private contractingService: ContratingService,
    private commonService: CommonService,
  ) {
    this.formVigencia = this.fb.group({
      StartDate: '',
      FinishDate: ''
    });
    this.getEmployerAll();
   }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;

    this.commonService.getCommonOptions('BlacklistReason').subscribe((data: CommonOptions[]) => {
      this.causalesData = data.sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
      this.datasource = this.causalesData;
      this.controlSelected.setValue(this.datasource.map(x => x.Id)[0]);
    });

    this.contractingService.getHistoricalBlackList(this.data.documentType, this.data.documentNumber).subscribe((data: any) => {
      this.infoHistory = data
        .sort((a: any, b: any) => new Date(b.UpdateDate).getTime() - new Date(a.UpdateDate).getTime())
        .map((item: any) => {
          const parsedDocs = DocsJsonUtils.deserializeDocsJSON(item.DocsJSON);
          return {
            ...item,
            DocsJSONParsed: parsedDocs ? parsedDocs['DOC-1'] : null
          };
        });
      this.contractingService.getPersonalBlackListInfo(
        this.data.documentType,
        this.data.documentNumber,
        this.data.companyInfoId).subscribe((rest: any) => {
        this.flagBlackList = rest.BlackList;
        this.flagBlackListFuture = rest.FutureBlackList;
        if (this.flagBlackList === true || this.flagBlackListFuture === true){
          if (this.flagBlackList === true && this.flagBlackListFuture === false){
            this.minDateNowStart = rest.StartDate;
          }
          this.dateNowStart = rest.StartDate;
          this.formVigencia.get('StartDate').setValue(rest.StartDate);
          this.minDateNowFinish = this.fechasService.getDateTimeNow();
          if (rest.EndDate != null){
            this.endDateBlackList = rest.EndDate;
            this.dateNowFinish = rest.EndDate;
            this.formVigencia.get('FinishDate').setValue(rest.EndDate);
          }else{
            this.endDateBlackList = this.fechasService.getDateTimeNow(true);
            this.dateNowFinish = this.fechasService.getDateTimeNow(true);
            this.formVigencia.get('FinishDate').setValue(this.fechasService.getDateTimeNow(true));
          }
          this.personalBlackListId = rest.Id;
          this.controlSelected.setValue(rest.ReasonId);
          this.employerControl.setValue(rest.EmployerId);
          if (this.employerControl.value === null || this.employerControl.value === '00000000-0000-0000-0000-000000000000'){
            this.employerControl.setValue(this.allEmployers[0].Id);
          }
        }
      });
    });
   }

  getEmployerAll() {
    this.commonService.getAllEmployersCompanyId(this.data.companyInfoId).subscribe((data: any) => {
      this.allEmployers = data
        .filter((employer: any) => this.data.employers.includes(employer.Id))
        .sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  async save() {
    this.setDefaultDates();

    const startDatePlus = new Date(this.formVigencia.get('StartDate').value);
    startDatePlus.setMinutes(startDatePlus.getMinutes() + 5);

    const now = new Date(this.fechasService.getDateTimeNow());
    const startDate = new Date(this.formVigencia.get('StartDate').value);
    const finishDate = new Date(this.formVigencia.get('FinishDate').value);

    if (!this.controlSelected.value) {
      this.snackBService.openSnackBar('Debes seleccionar alguna opción para continuar.', 'X', 4000);
      return;
    }
    if (this.employerControl.value === null || this.employerControl.value === '00000000-0000-0000-0000-000000000000'){
      this.snackBService.openSnackBar('Debes seleccionar un empleador.', 'X', 4000);
      return;
    }

    if (this.flagBlackList === false){
      if (startDatePlus < now){
        this.snackBService.openSnackBar('La fecha de inicio no puede ser menor a la fecha actual.', 'X', 4000);
        return;
      }
    }

    if (finishDate < startDate){
      this.snackBService.openSnackBar('La fecha de fin no puede ser menor a la fecha de inicio.', 'X', 4000);
      return;
    }

    const formData = this.createFormData();

    const personalBlack: PersonalBlackList = this.createPersonalBlackList();

    try {
      if (this.flagBlackList === true || this.flagBlackListFuture === true){
        if (this.formVigencia.get('FinishDate').value < this.fechasService.getDateTimeNow()){
          this.snackBService.openSnackBar('La fecha de finalización no puede ser menor a la fecha actual.', 'X', 4000);
          return;
        }
        if (this.endDateBlackList > this.fechasService.getDateTimeNow()){
          const responseBlackList: any = await this.contractingService.putPersonalBlackList(
            this.personalBlackListId, personalBlack).toPromise();
          if (formData) {
            await this.contractingService.putPersonalBlackListFile(responseBlackList.Id, formData).toPromise();
          }
        }
      }else{
        const responseBlackList: any = await this.contractingService.postPersonalBlackList(personalBlack).toPromise();
        if (formData) {
          await this.contractingService.putPersonalBlackListFile(responseBlackList.Id, formData).toPromise();
        }
      }
      this.dialogRef.close(true);
    } catch (error) {
      console.error('Error en la operación:', error);
    }
  }

  private createFormData(): FormData | null {
    if (this.files.length === 0) { return null; }

    const formData = new FormData();
    this.files.forEach(item => formData.append('File', item));
    return formData;
  }

  private setDefaultDates(): void {
    if (!this.formVigencia.get('StartDate').value) {
      this.formVigencia.get('StartDate').setValue(this.fechasService.getDateTimeNow());
    }
    if (!this.formVigencia.get('FinishDate').value) {
      this.formVigencia.get('FinishDate').setValue(this.fechasService.getDateTimeNow(true));
    }
  }

  private createPersonalBlackList(): PersonalBlackList {
    return {
      CompanyInfoId: this.data.companyInfoId,
      DocumentType: this.data.documentType,
      DocumentNumber: this.data.documentNumber,
      StartDate: this.formVigencia.get('StartDate').value,
      EndDate: this.formVigencia.get('FinishDate').value,
      ReasonId: this.controlSelected.value,
      Observation: this.commentary,
      EmployerId: this.employerControl.value
    };
  }

  onSelectedAllEmployer(id) {
    this.employerControl.setValue(id);
  }

  selected(e) {
//    this.dialogRef.close(e);
  }

  changeDate(date, isFinishDate) {
    if (date !== undefined && date !== '' && date !== null){
      if (isFinishDate === true) {
        this.formVigencia.get('FinishDate').setValue(date);
      } else {
        this.formVigencia.get('StartDate').setValue(date);
      }
    }
  }

  onSelect(event: any) {
    const selectedFiles: FileList = event.addedFiles;
    if (selectedFiles.length > 0) {
      this.files = [selectedFiles[0]];
    }
  }

  removeFile(index) {
    this.files.splice(index, 1);
  }

  openImgVideo(doc) {
    window.open(this.s3Bucket + doc, '_blank');
  }


  isXLSX(doc: any): boolean {
    return this.getUrl(doc).slice(-5).toUpperCase() === '.XLSX';
  }

  getUrl(doc: ModelDocumentsShow): string {
    let url = '';
    const a = doc?.urlDoc?.split('||name=');
    if (a !== undefined) {
      url = a[0];
    }
    return url;
  }

  getUrlFile(url) {
    return this.s3Bucket + url;
  }

  getDateFormatDateYYYYMMDD(date): string {
    let response = '';
    if (date !== null) {
      response = this.fechasService.formatDateYYYYMMDD24Hours(date);
    }
    return response;
  }

  getDescriptionDoc(description: string): string{
    if (description === null || description === '' || description === undefined){
      return 'Actualizado';
    }
    if (description === 'Agregado'){
      return 'Creado';
    }
    return description;
  }

  getDescriptionById(reasonId: number): string {
    const reason = this.datasource.find(item => item.Id === reasonId);
    return reason ? reason.Description : 'No encontrado';
  }

  getEmployerNameById(employerId: number): string {
    const employer = this.allEmployers.find(item => item.Id === employerId);
    return employer ? employer.Name : 'No encontrado';
  }

  disableStartDate(): boolean {
    if (this.flagBlackList === true){
      return this.minDateNowStart <= this.fechasService.getDateTimeNow();
    }else{
      return false;
    }
  }


}
