import { Injectable } from '@angular/core';
import { DashBoardFiltersBase } from '../../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})

export class JsonDashboardFiltersService {
  constructor() {}
    dashBoardFilters: DashBoardFiltersBase[] = [
        {
            states: [
                // {
                //     Name: 'Todos',
                //     Value: '-1'
                // },
                {
                    Name: 'Activos',
                    Value: 'Active'
                },
                // {
                //     Name: 'Inactivos',
                //     Value: 'Inactive'
                // },
                {
                    Name: 'Por vencer',
                    Value: 'Expiry'
                }
            ],
            variaciones: [
                {
                    Name: 'Todos',
                    Value: '-1'
                },
                {
                    Name: 'Activado',
                    Value: 'Activated'
                },
                {
                    Name: 'Reactivado',
                    Value: 'Reactivated'
                },
                {
                    Name: 'Inactivado',
                    Value: 'Inactivated'
                }
            ],
            asistencia: [
                {
                    Name: 'Todos',
                    Value: '-1'
                },
                {
                    Name: 'Personal ausente',
                    Value: 'NotAssistant'
                },
                {
                    Name: 'Personal asistente',
                    Value: 'Assistant'
                }
            ],
            cumplimiento: [
                {
                    Name: 'Todos',
                    Value: '-1'
                },
                {
                    Name: 'Cumple',
                    Value: 'Cumple'
                },
                {
                    Name: 'No Cumple',
                    Value: 'NoCumple'
                }
            ],
            categoria: [
                {
                    Name: 'Todos',
                    Value: '-1'
                },
                {
                    Name: 'Administrativo',
                    Value: 'AD'
                },
                {
                    Name: 'Asociado',
                    Value: 'AS'
                },
                {
                    Name: 'Contratista',
                    Value: 'CO'
                },
                {
                    Name: 'Proveedor',
                    Value: 'PR'
                },
                {
                    Name: 'Funcionario',
                    Value: 'FU'
                },
                {
                    Name: 'Visitante',
                    Value: 'VI'
                },
                {
                    Name: 'Personal',
                    Value: 'OT'
                }
            ],
            sexo: [
                {
                    Name: 'Todos',
                    Value: '-1'
                },
                {
                    Name: 'Masculino',
                    Value: 'SexMale'
                },
                {
                    Name: 'Femenino',
                    Value: 'SexFemale'
                }
            ],
            rangoYear: [
                {
                    Name: 'Todos',
                    Value: '-1'
                },
                {
                    Name: 'Menor a 18',
                    Value: 'Menor18'
                },
                {
                    Name: 'Menor a 30',
                    Value: 'Menor30'
                },
                {
                    Name: 'Menor a 40',
                    Value: 'Menor40'
                },
                {
                    Name: 'Menor a 50',
                    Value: 'Menor50'
                },
                {
                    Name: 'Menor a 60',
                    Value: 'Menor60'
                },
                {
                    Name: 'Menor a 70',
                    Value: 'Menor70'
                },
                {
                    Name: 'Igual o mayor a 70',
                    Value: 'Menor80'
                }
            ]
        }
  ]
  

}