import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Options, LabelType } from "@angular-slider/ngx-slider";

export interface DialogData {
  minValue: string;
  maxValue: string;
  arrayWeekDays: number[];
}

@Component({
  selector: 'app-dialog-hours-allowed',
  templateUrl: './dialog-hours-allowed.component.html',
  styleUrls: ['./dialog-hours-allowed.component.scss']
})
export class DialogHoursAllowedComponent implements OnInit {

  minValue: number = 0;
  maxValue: number = 1439;
  arrayWeekDays: number[] = [];
  ngxSlider: boolean = false;
  WeekDaysView = [
    { value: 0, name: 'DOM' },
    { value: 1, name: 'LUN' },
    { value: 2, name: 'MAR' },
    { value: 3, name: 'MIE' },
    { value: 4, name: 'JUE' },
    { value: 5, name: 'VIE' },
    { value: 6, name: 'SAB' }
  ]
  
  options: Options = {
    floor: 0,
    ceil: 1439,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "<strong>Hora de entrada: </strong>" + this.getTimeView(value);
        case LabelType.High:
          return "<strong>Hora de salida: </strong>" + this.getTimeView(value);
        default:
          return "";
      }
    }
  };

  constructor(public dialogRef: MatDialogRef<DialogHoursAllowedComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) 
  { 
    if(data.maxValue !== undefined && data.maxValue !== null && data.maxValue !== '') {
      this.maxValue = this.parseTimeNumber(data.maxValue);
    }
    if(data.minValue !== undefined && data.minValue !== null && data.minValue !== '') {
      this.minValue = this.parseTimeNumber(data.minValue);
    }
    data.arrayWeekDays.forEach(element => {
      this.arrayWeekDays.push(element);
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.ngxSlider = true;
    }, 200);
   }

  close() {
    this.dialogRef.close(false);
  }

  exitDayinData(value): boolean {
    let exit: boolean = false;
    let data = this.arrayWeekDays.find(x => x === value);
    data !== undefined ? exit = true: exit = false;
    return exit
  }

  getTimeView(value) {
    let  temp = value;
    const hours = Math.floor((temp/60));
    const minutes = temp - hours * 60;
    if(hours>=13) {
      return this.lessDate((hours-12)) + ':' + this.lessDate(minutes)+' pm';
    } else {
      if(hours<12) {
        return this.lessDate(hours) + ':' + this.lessDate(minutes)+' am';
      } else {
        return this.lessDate(hours) + ':' + this.lessDate(minutes)+' pm';
      }
    }
  }

  lessDate(date) {
    if(date < 10) {
      return '0'+date;
    } else {
      return date;
    }
  }

  getTime24hour(value) {
    let  temp = value;
    const hours = Math.floor((temp/60));
    const minutes = temp - hours * 60;
    return this.lessDate(hours) + ':' + this.lessDate(minutes)+':00';
  }

  parseTimeNumber(timeApi) {
    let arr = timeApi.split(":");
    let result = parseInt(arr[0])*60+parseInt(arr[1]);
    return result;
  }

  change(index, value){
    if(index !== null) {
      for (let i = 0; i < this.arrayWeekDays.length; i++) {
        if(index === this.arrayWeekDays[i]) {
          this.arrayWeekDays.splice(i, 1);  
        }
      }
    } else {
      this.arrayWeekDays.push(value);
    }
  }

  save() {
    let model = [{op: "replace", path: "/MinHour", value: this.getTime24hour(this.minValue)},
       {op: "replace", path: "/MaxHour", value: this.getTime24hour(this.maxValue)},
       {op: "replace", path: "/WeekDays", value: this.arrayWeekDays.sort()
    }];
    this.dialogRef.close(model);
  }

}
