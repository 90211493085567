<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'CONTRATO LABORAL' || 'N/A'}}</div>
    </div>

    <div class="content-card list-card" autocomplete="off">
        <form [formGroup]="formDatosContrato">
            <div class="subTitle">Datos del contrato laboral</div>
            <br>
            <app-select-search class="select-component-full-employerSelectedContract"
                [appearance]="'outline'"
                [_dataSource]="allEmployers" [textInput]="'EMPLEADOR'" [inputType]="'text'"
                [dataShow]="'Name'" [dataValue]="'Id'" [otherFilter]="'DocumentNumber'" [siteCtrl]="formDatosContrato.get('EmployerInfoId')"
                [isCard]="true"
                [card]="{img: 'Logo', title: 'Name', itemKey: 'DocumentType', itemValue:'DocumentNumber', finalLeft: 'Rol'}"
                (onSelectionChange)="changeEmployer($event)"
                [onErrorUrl]="'employer'"
                >
            </app-select-search>
            <div class="fields-group">
                <strong>CONTRATO No:</strong>
                <mat-form-field class="input-field-medium" appearance="outline">
                    <input matInput type="text" formControlName="ContractNumber">
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
            </div>
            <app-select-search class="select-component-full-employerSelectedContract"
                [appearance]="'outline'" [textInput]="'TIPO DE CONTRATO'"
                [_dataSource]="individualContractsType" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="formDatosContrato.get('ContractTypeId')"
                [inputType]="'text'">
            </app-select-search>
            <div class="fields-group">
                <mat-form-field class="input-field" appearance="outline">
                    <label><strong>CARGO</strong></label>
                    <input matInput type="text" formControlName="PositionName">
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
            </div>
            <div class="fields-group">
                <mat-form-field class="input-field-salary" appearance="outline">
                    <label><strong>SALARIO MENSUAL</strong></label>
                    <input matInput #moneySalary formControlName="Salary" style="text-align: right;">
                    <mat-error>Requerido</mat-error>
                </mat-form-field>

              <div style="margin-left: 5px">
                <mat-form-field class="input-field-salary" appearance="outline">
                  <label><strong>AUXILIO DE TRANS.</strong></label>
                  <input matInput #inputAuxiliaryTransport formControlName="AuxiliaryTransport"
                         style="text-align: right;" >
                  <mat-error>Requerido</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="fields-group">
                <mat-form-field class="input-field-medium" style="margin-top: 10px;" appearance="outline">
                    <label style="margin-left: -3px;"><strong>FECH.INICIO CONTRATO</strong></label>
                    <input matInput [matDatepicker]="picker2" formControlName="StartDate" (click)="picker2.open()"
                        >
                    <mat-datepicker #picker2></mat-datepicker>
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
                <mat-form-field class="input-field-medium" appearance="outline">
                    <label style="margin-left: -3px;"><strong>FECH.FIN DE CONTRATO</strong></label>
                    <input matInput [matDatepicker]="picker3" formControlName="EndDate" (click)="picker3.open()"
                        >
                    <mat-icon matSuffix (click)="deleteEndDateLaboral()">close</mat-icon>
                    <mat-datepicker #picker3></mat-datepicker>
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
            </div>
            <div class="subTitle">Cuenta bancaria</div>
            <br>
            <div>
                <app-select-search class="input-field"
                        [appearance]="'outline'"
                        [_dataSource]="bancks" [textInput]="'Banco'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formDatosContrato.get('BankId')"
                        [inputType]="'text'">
                </app-select-search>
                <div class="fields-group">
                    <div class="fields-group">
                        <mat-form-field class="input-field" appearance="outline">
                            <label><strong>NO CUENTA BANCARIA</strong></label>
                            <input matInput type="text" formControlName="BankAccount">
                            <mat-error>Requerido</mat-error>
                        </mat-form-field>
                    </div>
                    <app-select-search class="select-field-medium-short-contract-labor"
                        [appearance]="'outline'"
                        [_dataSource]="bankAccountType" [textInput]="'Tipo'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="formDatosContrato.get('BankAccountTypeId')"
                        [inputType]="'text'">
                    </app-select-search>
                </div>
            </div>
            <div class="subTitle">Lugar y turno de trabajo (Pre-nomina)</div>
            <br>
            <app-select-search class="input-field"
                [appearance]="'outline'"
                [_dataSource]="projects" [textInput]="'Lugar de trabajo'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formDatosContrato.get('ProjectId')"
                [inputType]="'text'"
                (onSelectionChange)="getPersonalWorkShiftrprojectId($event)">
            </app-select-search>
            <app-select-search class="input-field"
                [appearance]="'outline'"
                [_dataSource]="workShift" [textInput]="'Horario de trabajo'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formDatosContrato.get('WorkShiftId')"
                [inputType]="'text'">
            </app-select-search>
            <div class="ojo">*Verifique el empleador despues de guardar no se podra cambiar.</div>
        </form>
    </div>

    <div class="btn-actions"style="margin: 3px;">
        <button mat-button class="btn-cancelar" (click)="close()">{{'CANCELAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" (click)="save()">{{'GUARDAR'}}</button>
    </div>
</div>
