<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'ENCUESTA' || 'N/A'}}</div>
    </div>
    <div class="content-card">
        <div class="symptom-report" *ngIf="data.poll">
            <form  class="form" autocomplete="off" *ngFor="let question of data.poll.Questions; let i = index" [ngSwitch]="question.QuestionType">
                <div class="step">
                    <div class="sub-title">{{(i+1)+'.' + question.Text}}</div>
                    <div class="step-2" *ngSwitchCase="2">
                        <div class="divTable" style="margin-top: 10px;">
                            <div class="divTableBody" *ngFor="let choice of question.choices">
                                <div class="row s-30">
                                    <div class="divTableCell" 
                                        [ngClass]="existQuestionAnswerArray(question.Id ,choice) === true ? 'divTableCellSelected': ''"
                                        (click)="pushToArrayString(question.Id, choice)">
                                        <div class="text-item">
                                            {{choice}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="step-3" *ngSwitchCase="0">
                        <div class="divTableOther" style="margin-top: 10px;">
                            <div class="divTableBodyOther">
                                <!-- <div class="sub-title">{{option.Question}}?<strong style="color: red;">*</strong></div> -->
                                <div class="row s-30">
                                    <div class="divTableCell" 
                                            [ngClass]="existQuestionAnswerSINO(question.Id, 'Si') === true ? 'divTableCellSelected': ''" 
                                            (click)="pushToAnswerSONO(question.Id, 'Si')">
                                        <div class="text-item">
                                            {{'Si'}}   
                                        </div>
                                    </div>
                                    <div class="divTableCell" 
                                            [ngClass]="existQuestionAnswerSINO(question.Id, 'No') === true ? 'divTableCellSelected': ''" 
                                            (click)="pushToAnswerSONO(question.Id, 'No')">
                                        <div class="text-item">
                                            {{'No'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="step-3" *ngSwitchCase="1">
                        <div class="wrapper">
                            <input type="number" (change)="changeNumberInput(question.Id, $event.target.value)" 
                                        [value]="getValueNumber(question.Id)"
                                        required [disabled]="data.answer === true"
                                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                                        [min]="0"/>
                          </div>
                    </div>
                </div>
            </form>
            <div class="terms">
                <div class="actions-dialog">
                    <button class="button cancel-button" (click)="cancelar()">{{answer === true ? 'CERRAR': 'CANCELAR'}}</button>
                    <button  class="button accept-button" (click)="savePoll()" *ngIf="answer === false">GUARDAR</button>
                </div>
            </div>
        </div>
    </div>
   
    
</div>