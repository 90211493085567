<div class="box">
    <title-dialog [title]="data.title || 'ARCHIVOS Y COMENTARIOS'" [color]="'greendAnalityco'"  [width]="'360'"></title-dialog>
    <div class="content-card">
        <div class="content-show">
            <div class="desc" *ngIf="data.desc">{{data.desc || ''}}</div>
            <div class="subtitle" *ngIf="(data.typeOperator === 'delete' || data.typeOperator === 'showDelete' || data.typeOperator === 'showUpdate' || data.typeOperator === 'showUpdatePlanilla')">ACTUALIZAR</div>
            <app-select-search class="select-component-full"
                                *ngIf="data.typeOperator === 'uploadProveedor'"
                                [appearance]="'outline'"
                                [_dataSource]="proveedor" [textInput]="'PROVEEDOR'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="controProveedor"
                                [inputType]="'text'" (onSelectionChange)="selectedProveedor($event)" [disabled]="files.length !== 0 ? true: false">
            </app-select-search>
            <ngx-dropzone (change)="onSelect($event)" class="dropzone" *ngIf="data.typeOperator === 'delete' || data.typeOperator === 'editPhoto' || data.typeOperator === 'showDelete' || data.typeOperator === 'showUpdate' || data.typeOperator === 'showUpdatePlanilla'" [multiple]="!data.typeGestor">
                <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
            </ngx-dropzone>
            <ngx-dropzone (change)="onSelect($event)" class="dropzone" *ngIf="data.typeOperator === 'uploadProveedor' && controProveedor.value !== null" [multiple]="!data.typeGestor">
                <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
            </ngx-dropzone>
            <div *ngIf="cropper === true && this.imgCropper !== undefined && data.typeOperator !== 'showDelete' && data.typeOperator !== 'showUpdate' && data.typeOperator !== 'showUpdatePlanilla'" >
                <angular-cropper
                    [imageUrl]="imgCropper"
                    [cropperOptions]="{
                        aspectRatio: 12 / 12
                        }"
                    #angularCropper [ngClass]="cropper === true ? '': 'errorAnuglarCropper'"></angular-cropper>
            </div>
            <div class="item" *ngFor="let file of files; let i = index">
                <div *ngIf="file">{{file.name || 'SIN NOMBRE'}}</div>
                <div class="red" *ngIf="!file">{{'Archivo Removido'}}</div>
                <mat-icon *ngIf="file" (click)="removeFile(i)">highlight_off</mat-icon>
            </div>
            <mat-form-field class="input-field" *ngIf="(data.typeOperator === 'delete' || data.typeOperator === 'showDelete' || data.typeOperator === 'showUpdate' || data.typeOperator === 'showUpdatePlanilla')">
                <label><div>COMENTARIO</div></label>
                <textarea matInput [(ngModel)]="commentary" maxlength="280" rows="5"></textarea>
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <div class="actions-dialog border-bottom-actions" *ngIf="!data.typeGestor">
                <button mat-button class="btn-cancelar" (click)="onNoClick()" >{{(data.typeOperator === 'show')? 'CERRAR': 'CANCELAR'}}</button>&nbsp;&nbsp;
                <button mat-button class="btn-generar"
                    *ngIf="data.typeOperator !== 'showUpdatePlanilla' && (!jsonDocExitValue() || files.length > 0 || commentary !== '')" (click)="upDateDocs(DocsJSON)">GUARDAR
                </button>
                <button mat-button class="btn-generar"
                    *ngIf="data.typeOperator === 'showUpdatePlanilla' && (jsonDocExitValue() || files.length > 0 || commentary !== '' || DocsJSON !== {})" (click)="upDateDocs(DocsJSON)">GUARDAR
                </button>

            </div>
            <div class="actions-dialog" *ngIf="data.typeGestor">
                    <button mat-button class="btn-cancelar" (click)="onNoClick()" >{{'CERRAR'}}</button>&nbsp;&nbsp;
                    <button mat-button
                        *ngIf="data.typeOperator !== 'uploadProveedor'"
                        class="btn-generar"
                        type="button"
                        (click)="updatePhoto()">{{'GUARDAR'}}</button>
                    <button mat-button
                        *ngIf="data.typeOperator === 'uploadProveedor'"
                        [disabled]="files.length === 0 ? true: false"
                        [ngClass]="files.length === 0 ? 'btn-disabled' : 'btn-generar'"
                        type="button"
                        (click)="updatePhoto()">{{'GUARDAR'}}</button>
            </div>
            <div class="subtitle" *ngIf="documentos?.length !== 0">ACTUALIZACIONES</div>
            <div class="list">

                <!-- <div class="item" *ngFor="let doc of documentos; let i = index">
                    <div [ngClass]="!doc ? 'red': ''">{{doc?.nameFile || 'Doc. Removido'}}</div>
                    <div>
                        <mat-icon class="mat-icon-show" *ngIf="doc !== undefined && (data.typeOperator === 'show' || data.typeOperator === 'showDelete' || data.typeOperator === 'showUpdate' || data.typeOperator === 'showUpdatePlanilla') && !isXLSX(doc) && doc.isCommentary === false" (click)="openImgVideo(doc)">visibility</mat-icon>
                        <a *ngIf="doc !== undefined && (data.typeOperator === 'show' || data.typeOperator === 'showDelete' || data.typeOperator === 'showUpdate' || data.typeOperator === 'showUpdatePlanilla') && isXLSX(doc)" [href]="getUrlFile(doc)" target="_blank"><mat-icon class="mat-icon-show">visibility</mat-icon></a>
                        <mat-icon class="mat-icon-delete" *ngIf="(data.typeOperator === 'delete' || data.typeOperator === 'showDelete') && doc" (click)="jsonUpdateDeleteDoc(doc, i)">delete</mat-icon>
                    </div>
                </div> -->

                <div class="documents" *ngFor="let doc of documentos; let i = index" [ngSwitch]="doc?.isCommentary">
                    <div *ngSwitchCase="false" style="color: #a0a0a0 !important; width: 270px;">
                        <div class="state" style="color: #a0a0a0 !important;">
                            <div class="state-description" style="min-width: 80px; width: 80px; text-align: start;">
                                <div *ngIf="doc?.module_state === undefined || doc?.module_state === '' || doc?.module_state === 'NA'">Actualizado:</div>
                                <div *ngIf="doc?.module_state !== undefined && doc?.module_state !== ''">{{doc?.module_state === 'SOL'? 'Solicitado el': doc?.module_state === 'APR'? 'Aprobado el':doc?.module_state === 'REC'? 'Rechazado el':''}}:</div>
                            </div>
                            <div class="state-description" [matTooltip]="doc?.nameFile || ''">
                                <div>{{getDateFormatDateGestorDocuemntsUpdate(doc?.date) || 'Fecha no reportada' }}</div>
                            </div>
                            <div class="state-description">
                                <div>Por:</div>
                                <mat-icon
                                    matTooltipPosition="above"
                                    [matTooltip]="'Usuario: ' + (doc?.user !== undefined ? doc?.user : 'No reportado')"
                                    >account_circle</mat-icon>
                            </div>
                        </div>
                        <div class="state-document" *ngIf="doc?.nameFile">
                            <div class="itemState" style="width: 100px !important;">Documento:&nbsp;</div>
                            <div class="itemState-nameFile" [matTooltip]="doc?.nameFile || ''">{{doc?.nameFile}}</div>
                        </div>
                        <div class="state-date" *ngIf="doc?.module_date">
                            <div class="itemState" style="width: 90px !important;">Vencimiento:&nbsp;</div>
                            <div class="itemState">{{getDateFormatDateYYYYMMDDdatePiker(doc?.module_date)}}</div>
                        </div>
                        <div class="state-comment" *ngIf="doc?.module_comment">
                            <div class="itemState-comment" style="width: 96px !important;">Comentario:&nbsp;</div>
                            <div class="itemStateComment">{{doc?.module_comment}}</div>
                        </div>
                    </div>
                    <div *ngSwitchCase="true">
                        <div class="document-doc">
                            <div>Comentario:&nbsp;</div>
                            <div class="isComentary">{{doc?.nameFile || ''}}</div>
                        </div>
                        <div class="state" style="color: #a0a0a0 !important;">
                            <div class="state-description" style="min-width: 80px; width: 80px;">
                                <div>Escrito el</div>
                            </div>
                            <div class="state-description">
                                <div>{{getDateFormatDateGestorDocuemntsUpdate(doc?.date) || 'Fecha no reportada' }}</div>
                            </div>
                            <div class="state-description">
                                <div>Por:</div>
                                <mat-icon
                                    matTooltipPosition="above"
                                    [matTooltip]="'Usuario: ' + (doc?.user !== undefined ? doc?.user : 'No reportado')"
                                    >account_circle</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!doc" [ngClass]="!doc ? 'red': ''">{{'Doc. Removido'}}</div>
                    <div class="operators">
                        <mat-icon class="mat-icon-delete" *ngIf="(data.typeOperator === 'delete' || data.typeOperator === 'showDelete' || (data.typeOperator === 'showUpdatePlanilla' && isEqualsUserRemoveFile(doc))) && doc && !data.isFromPersonal" (click)="jsonUpdateDeleteDoc(doc, i)">delete</mat-icon>
                      <mat-icon class="mat-icon-delete" *ngIf="(data.typeOperator === 'delete' || data.typeOperator === 'showDelete' || (data.typeOperator === 'showUpdatePlanilla' && isEqualsUserRemoveFile(doc))) && doc && data.isFromPersonal && exitPermissionCompany('personalsrequirements.delete')" (click)="jsonUpdateDeleteDoc(doc, i)">delete</mat-icon>
                        <mat-icon class="mat-icon-show" *ngIf="doc !== undefined && (data.typeOperator === 'show' || data.typeOperator === 'showDelete' || data.typeOperator === 'showUpdate' || data.typeOperator === 'showUpdatePlanilla') && !isXLSX(doc) && doc.isCommentary === false && doc?.nameFile" (click)="openImgVideo(doc)">visibility</mat-icon>
                        <a *ngIf="doc !== undefined && (data.typeOperator === 'show' || data.typeOperator === 'showDelete' || data.typeOperator === 'showUpdate' || data.typeOperator === 'showUpdatePlanilla') && isXLSX(doc)" [href]="getUrlFile(doc)" target="_blank"><mat-icon class="mat-icon-show">visibility</mat-icon></a>
                    </div>
                </div>
                <div class="item" *ngIf="data.docs.length === 0 && !data.typeGestor">{{'0 DOCUMETOS GUARDADOS'}}</div>
            </div>
        </div>


    </div>
</div>
