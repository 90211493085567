<div class="personal-empleyer-documents">
    <!-- Personal -->
    <div class="personal" *ngIf="exitPermission('contractingemployee.view')">
        <div class="header" >
            <div class="content" *ngIf="state === 'SOLICITADO'"
                [ngClass]="!exitPermission('contractingdata.requestdelete') || !exitPermission('contractingdata.approvereject')? 'blueAnalityco': 'greendAnalityco'
                ">{{'EMPLEADO' || 'N/A'}}
            </div>
            <div class="content" *ngIf="state === 'APROBADO'"
                [ngClass]="!exitPermission('contractingcontract.generate')? 'blueAnalityco': 'greendAnalityco'
                ">{{'EMPLEADO' || 'N/A'}}
            </div>
            <div class="content" *ngIf="state === 'INICIADO'"
                [ngClass]="!exitPermission('contractingdata.requestdelete')? 'blueAnalityco': 'greendAnalityco'"
                >{{'EMPLEADO' || 'N/A'}}
            </div>
            <div class="content" *ngIf="state === 'CONTRATADO'"
                [ngClass]="!exitPermission('contractingcontract.generate') || !exitPermission('contractingcontract.generate')? 'blueAnalityco': 'greendAnalityco'
                ">{{'EMPLEADO' || 'N/A'}}
            </div>
            <div class="content" *ngIf="state === 'RECHAZADO'"
                [ngClass]="!exitPermission('contractingdata.requestdelete')? 'blueAnalityco': 'greendAnalityco'
                ">{{'EMPLEADO' || 'N/A'}}
            </div>
            <div class="content" *ngIf="state === 'ANULADO'"
                [ngClass]="state === 'ANULADO' ? 'blueAnalityco': 'greendAnalityco'
                ">{{'EMPLEADO' || 'N/A'}}
            </div>
        </div>
        <div class="content-card list-card" >
            
    
                <!-- TITLE AND PHOTO -->
            <!-- <button class="uploadPhoto" [ngClass]="formDataPhoto === undefined ? 'borderBtn': ''" >SUBIR UNA FOTO</button> -->
            <div class="button-container">
                <img src="{{s3Bucket+getImg(formPersonal.get('Photo').value)}}" alt="">
                <a (click)="openDialogPhotoPersonal()"><mat-icon>camera_alt</mat-icon></a>
            </div>
            <mat-icon   class="icon-black-list-add"
                        *ngIf="flagListF=== true && exitPermission('contractingblacklist.view')"
                        matTooltip="Agregar a la lista"
                        [matTooltipPosition]="'right'"
                        (click)="postPersonalBlackList()"
            >person</mat-icon>
            <mat-icon   class="icon-black-list-remove"
                        *ngIf="flagListF=== false && exitPermission('contractingblacklist.view')"
                        matTooltip="Quitar de la lista"
                        [matTooltipPosition]="'right'"
                        (click)="deletePersonalBlackList()"
            >person_off</mat-icon>
                <!-- <div><strong>{{personal.Name + ' ' + personal.LastName || 'Sin Title'}}</strong></div> -->
            <form [formGroup]="formPersonal" (ngSubmit)="onSubmit()">
                <app-select-search class="select-component"
                        [_dataSource]="nationality" [textInput]="'NACIONALIDAD'" [dataShow]="'Description'" [dataValue]="'Value'" [siteCtrl]="formPersonal.get('Nationality')" 
                        [inputType]="'text'" (onSelectionChange)="selectionChangeGetdocumentsForNationalyty($event)">
                </app-select-search>
                <div class="fields-group">
                    <mat-form-field class="input-field-short">
                        <label><strong>TIPO</strong></label>
                        <mat-select formControlName="DocumentType">
                            <mat-option *ngFor="let data of companyDocumentType" [value]="data.Value">
                                {{data.Value === 'CC' ? 'Cédula': (data.Value === 'PE' ? 'Permiso especial de permanecía': 'Cedula de extranjeria')}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Requerido</mat-error>
                    </mat-form-field> 
                    <mat-form-field class="input-field">
                        <label><strong>NÚMERO</strong></label>
                        <input matInput type="text" formControlName="DocumentNumber">
                        <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('DocumentNumber').setValue('')">X</div> -->
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field class="input-field">
                    <label><strong>NOMBRE</strong></label>
                    <input matInput type="text" formControlName="Name">
                    <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('LastName').setValue('')">X</div>
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
                <mat-form-field class="input-field">
                    <label><strong>APELLIDOS</strong></label>
                    <input matInput type="text" formControlName="LastName">
                    <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('LastName').setValue('')">X</div>
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
                <div class="fields-group">
                    <mat-icon (click)="picker.open()" style="cursor: pointer;">today</mat-icon>
                    <mat-form-field class="input-field-medium">
                        <label><strong>FECH. NACIMIENTO</strong></label>
                        <input matInput [matDatepicker]="picker" formControlName="BirthDate" (click)="picker.open()">
                        <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('BirthDate').setValue('')">X</div>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                    <app-select-search class="select-component-short"
                        [_dataSource]="sex" [textInput]="'SEXO'" [dataShow]="'Value'" [dataValue]="'Value'" [siteCtrl]="formPersonal.get('Sex')" 
                        [inputType]="'text'">
                    </app-select-search>
                    <app-select-search class="select-component-short"
                        [_dataSource]="rh" [textInput]="'TIPO'" [dataShow]="'Description'" [dataValue]="'Value'" [siteCtrl]="formPersonal.get('RH')" 
                        [inputType]="'text'">
                    </app-select-search>
                </div>
                <app-select-search class="select-component-medium"
                        [_dataSource]="countrys" [textInput]="'PAIS DE NACIMIENTO'" [dataShow]="'Description'" [dataValue]="'Value'" [siteCtrl]="formPersonal.get('contryNalcionality')" 
                        [inputType]="'text'" (onSelectionChange)="getCitiesNacimiento($event)">
                </app-select-search>
                <app-select-search
                    [_dataSource]="citiesNacimiento" [textInput]="'CIUDAD DE NACIMIENTO'" [dataShow]="'Name'" [dataValue]="'Code'" [siteCtrl]="formPersonal.get('CityOfBirthCode')" 
                    [inputType]="'text'">
                </app-select-search>
                
                <!-- SUBTITLE 3 ITEMS -->
                <div class="sub-header">
                    <div class="sub-content"*ngIf="state === 'INICIADO'"
                        [ngClass]="!exitPermission('contractingdata.requestdelete') ? 'blueAnalityco': 'greendAnalityco'
                        " >{{'CONTACTO' || 'N/A'}}
                    </div>
                    <div class="sub-content"*ngIf="state === 'SOLICITADO'"
                        [ngClass]="!exitPermission('contractingdata.requestdelete') || !exitPermission('contractingdata.approvereject')? 'blueAnalityco': 'greendAnalityco'
                        " >{{'CONTACTO' || 'N/A'}}
                    </div>
                    <div class="sub-content"*ngIf="state === 'APROBADO'"
                        [ngClass]="!exitPermission('contractingcontract.generate')? 'blueAnalityco': 'greendAnalityco'
                        " >{{'CONTACTO' || 'N/A'}}
                    </div>
                    <div class="sub-content"*ngIf="state === 'CONTRATADO'"
                        [ngClass]="!exitPermission('contractingcontract.generate') || !exitPermission('contractingcontract.generate')? 'blueAnalityco': 'greendAnalityco'
                        " >{{'CONTACTO' || 'N/A'}}
                    </div>
                    <div class="sub-content"*ngIf="state === 'RECHAZADO'"
                        [ngClass]="!exitPermission('contractingdata.requestdelete')? 'blueAnalityco': 'greendAnalityco'
                        " >{{'CONTACTO' || 'N/A'}}
                    </div>
                    <div class="sub-content" *ngIf="state === 'ANULADO'"
                        [ngClass]="state === 'ANULADO' ? 'blueAnalityco': 'greendAnalityco'
                        ">{{'CONTACTO' || 'N/A'}}
                    </div>
                </div>
                <br>
                <app-select-search class="select-component-medium"
                        [_dataSource]="daneCity" [textInput]="'CIUDAD'" [dataShow]="'CityName'" [dataValue]="'CityCode'" [siteCtrl]="formPersonal.get('CityCode')" 
                        [inputType]="'text'" (onSelectionChange)="selectedDaneCityTown($event)">
                </app-select-search>
                <div class="fields-group">
                    <mat-form-field class="input-field-50">
                        <label><strong>DIRECCIÓN</strong></label>
                        <input matInput type="text" formControlName="Address">
                        <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('Address').setValue('')">X</div>
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                    <app-select-search class="select-component-medium"
                        [_dataSource]="town" [textInput]="'ZONA O LOCALIDAD'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formPersonal.get('TownCode')" 
                        [inputType]="'text'">
                        <!-- (onSelectionChange)="onSelectionChange($event)" -->
                    </app-select-search>
                </div>
                <div class="fields-group">
                    <mat-form-field class="input-field-50">
                        <label><strong>CORREO</strong></label>
                        <input matInput type="text" formControlName="Email">
                        <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('Email').setValue('')">X</div>
                        <mat-error>formato incorrecto / Requerido</mat-error>
                    </mat-form-field>
                    <mat-form-field class="input-field-50">
                        <label><strong>TELEFONO</strong></label>
                        <input matInput type="text" formControlName="PhoneNumber">
                        <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('PhoneNumber').setValue('')">X</div>
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                </div>
                <!--SUB-SUBTITLE 3 ITEMS -->
                <div class="sub-header">
                    <div class="sub-content"*ngIf="state === 'INICIADO'"
                        [ngClass]="!exitPermission('contractingdata.requestdelete')? 'blueAnalityco': 'greendAnalityco'
                        " >{{'EN CASO DE EMERGENCIA LLAMAR A' || 'N/A'}}
                    </div>
                    <div class="sub-content"*ngIf="state === 'SOLICITADO'"
                        [ngClass]="!exitPermission('contractingdata.requestdelete') || !exitPermission('contractingdata.approvereject')? 'blueAnalityco': 'greendAnalityco'
                        " >{{'EN CASO DE EMERGENCIA LLAMAR A' || 'N/A'}}
                    </div>
                    <div class="sub-content"*ngIf="state === 'APROBADO'"
                        [ngClass]="!exitPermission('contractingcontract.generate')? 'blueAnalityco': 'greendAnalityco'
                        " >{{'EN CASO DE EMERGENCIA LLAMAR A' || 'N/A'}}
                    </div>
                    <div class="sub-content"*ngIf="state === 'CONTRATADO'"
                        [ngClass]="!exitPermission('contractingcontract.generate')? 'blueAnalityco': 'greendAnalityco'
                        " >{{'EN CASO DE EMERGENCIA LLAMAR A' || 'N/A'}}
                    </div>
                    <div class="sub-content"*ngIf="state === 'RECHAZADO'"
                        [ngClass]="!exitPermission('contractingdata.requestdelete')? 'blueAnalityco': 'greendAnalityco'
                        " >{{'EN CASO DE EMERGENCIA LLAMAR A' || 'N/A'}}
                    </div>
                    <div class="sub-content" *ngIf="state === 'ANULADO'"
                        [ngClass]="state === 'ANULADO' ? 'blueAnalityco': 'greendAnalityco'
                        ">{{'EN CASO DE EMERGENCIA LLAMAR A ' || 'N/A'}}
                    </div>
                </div>
                <br>
                <div class="fields-group">
                    <mat-form-field class="input-field-50">
                        <label><strong>NOMBRE</strong></label>
                        <input matInput type="text" formControlName="EmergencyContact">
                        <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('EmergencyContact').setValue('')">X</div>
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                    <mat-form-field class="input-field-50">
                        <label><strong>TELEFONO</strong></label>
                        <input matInput type="text" formControlName="EmergencyPhone">
                        <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('EmergencyPhone').setValue('')">X</div>
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                </div>
            </form>
            <!-- <br> -->
            
        </div>
    </div>
    <!-- Personal si no exite-->
    <app-card-information *ngIf="!exitPermission('contractingemployee.view')"
            [configCard]="{typeCard: 'default', title:'EMPLEADO'}"        
            [notListDescription]="'Aquí se visualizara los datos del empleado'"        
            >
    </app-card-information>

    <!-- empleadores y docuemntos -->
    <div class="content-empleador">
        <div class="box information-fields" *ngIf="exitPermission('contractingemployer.view')">
            <div class="header">
                <div class="content" [ngClass]="'blueAnalityco'">{{'EMPLEADOR, ORDENES Y CONTRATOS IND.' || 'N/A'}}</div>
            </div>
            
            <div class="content-card-empleador list-card" >
                <!-- <div class="content-start">
                    <div class="text">INICIAR ORDEN DE CONTRATO</div>
                    <button mat-mini-fab color="primary" matTooltipPosition="above" matTooltip="INICIAR" >
                        <mat-icon>add</mat-icon>
                    </button>
                </div> -->
                <div class="form-group-list" *ngIf="individualContract">
                    <h3 class="contratista">Empleador:&nbsp;<label>({{'Contratista'}})</label></h3>
                    <div class="card">
                        <div class="card-content">
                            <img src="{{s3Bucket+getImg(individualContract.personalemployerinfo.Employer.Logo)}}" alt="">
                            <div class="content-information">
                                <div class="ellipsis">{{individualContract.personalemployerinfo.Employer.Name || 'Sin nombre'}}</div>
                                <div class="ellipsis">{{individualContract.personalemployerinfo.Employer.Rol || 'Sin datos'}}</div>
                                <div class="ellipsis baseline">
                                    {{individualContract.personalemployerinfo.Employer.DocumentType || 'Sin tipo de documento'}} {{individualContract.personalemployerinfo.Employer.DocumentNumber || 'Sin numero de documento'}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="des-state">
                        <div class="noState shadow">items:</div>
                        <div class="state shadow">Estado</div>
                    </div>
                    <div class="des-state">
                        <div class="noState">
                            <form [formGroup]="formContractNumber">
                                <div class="fields-group">
                                    <mat-form-field class="input-field-medium">
                                        <label><strong>No.</strong></label>
                                        <input matInput type="text" formControlName="ContractNumber" >
                                        <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('DocumentNumber').setValue('')">X</div> -->
                                        <mat-error>Requerido</mat-error>
                                    </mat-form-field>
                                </div>
                            </form>
                        </div>
                        <div class="state">
                            <div class="text-state" [ngClass]="state || ''">
                                {{state || '' | uppercase}}
                            </div>
                            <!-- <mat-icon [ngClass]="indexSelect === 0 ? 'activeBtn':'inactive'">visibility</mat-icon> -->
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
        <app-card-information  *ngIf="!exitPermission('contractingemployer.view')"
            [configCard]="{typeCard: 'default', title:'EMPLEADOR, ORDENES Y CONTRATOS IND.'}"        
            [notListDescription]="'Aquí se visualizara el empleador, ORDENES Y CONTRATOS IND.'"        
            >
        </app-card-information>
        <div class="box information-fields" *ngIf="exitPermission('contractingdocuments.view')">
            <div class="header" >
                <div class="content" *ngIf="state === 'SOLICITADO'"
                    [ngClass]="!exitPermission('contractingdata.requestdelete') || !exitPermission('contractingdata.approvereject')? 'blueAnalityco': 'greendAnalityco'
                    ">{{'DOCUMENTOS Y REQUISITOS DEL EMPLEADO' || 'N/A'}}
                </div>
                <div class="content" *ngIf="state === 'APROBADO'"
                    [ngClass]="!exitPermission('contractingcontract.generate')? 'blueAnalityco': 'greendAnalityco'
                    ">{{'DOCUMENTOS Y REQUISITOS DEL EMPLEADO' || 'N/A'}}
                </div>
                <div class="content" *ngIf="state === 'INICIADO'"
                    [ngClass]="!exitPermission('contractingdata.requestdelete')? 'blueAnalityco': 'greendAnalityco'"
                    >{{'DOCUMENTOS Y REQUISITOS DEL EMPLEADO' || 'N/A'}}
                </div>
                <div class="content" *ngIf="state === 'CONTRATADO'"
                    [ngClass]="!exitPermission('contractingcontract.generate') ? 'blueAnalityco': 'greendAnalityco'
                    ">{{'DOCUMENTOS Y REQUISITOS DEL EMPLEADO' || 'N/A'}}
                </div>
                <div class="content" *ngIf="state === 'RECHAZADO'"
                    [ngClass]="!exitPermission('contractingcontract.requestdelete')? 'blueAnalityco': 'greendAnalityco'
                    ">{{'DOCUMENTOS Y REQUISITOS DEL EMPLEADO' || 'N/A'}}
                </div>
                <div class="content" *ngIf="state === 'ANULADO'"
                        [ngClass]="state === 'ANULADO' ? 'blueAnalityco': 'greendAnalityco'
                        ">{{'DOCUMENTOS Y REQUISITOS DEL EMPLEADO' || 'N/A'}}
                </div>
            </div>
            <div class="content-card-documentos list-card" *ngIf="documentsAndRquisitosParameters">
                <div class="form-group-list">
                    <form [formGroup]="formCetificados">
                        <div class="item-list-docs" *ngFor="let doc of documentsAndRquisitosParameters || []" [ngSwitch]="exitDocumentUpload(doc.Id)">
                            <div class="doc-name">
                                <mat-icon class="icon-svg" *ngSwitchCase="false" (click)="uploadDoc(doc.Id, inputDate.value)">
                                    upload_file
                                </mat-icon>
                                <svg *ngSwitchCase="true" (click)="uploadDoc(doc.Id, inputDate.value)" 
                                    title="EPS" style="fill: #6ACA25;" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                                    <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                                    <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{doc.Abrv}}</text>
                                </svg>
                                <div class="text" *ngIf="doc.Abrv !== 'EPS' && doc.Abrv !== 'AFP'">{{doc.Name || ''}}</div>
                                <app-select-search class="select-component-100" *ngIf="doc.Abrv === 'EPS'"
                                    [_dataSource]="eps" [textInput]="'EPS'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formCetificados.get('EpsId')" 
                                    [inputType]="'text'">
                                </app-select-search>
                                <app-select-search class="select-component-100" *ngIf="doc.Abrv === 'AFP'"
                                    [_dataSource]="afp" [textInput]="'AFP'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formCetificados.get('AfpId')" 
                                    [inputType]="'text'">
                                </app-select-search>
                                <p class="description" *ngIf="!doc.IsByDate">{{doc.Description}}</p>
                                <div [ngClass]="getDateRequired(doc.Id) === ''? 'colorNotData': ''">
                                    <mat-form-field [ngClass]="doc.IsByDate === true ? 'visibility': 'visibilityhidden'" class="input-field-medium" >
                                        <mat-icon (click)="picker.open()">today</mat-icon>
                                        <input matInput [matDatepicker]="picker" #inputDate (click)="picker.open()"
                                                (dateChange)="changeSelectedDate(doc.Id, inputDate.value)"
                                                [value]="getDateRequired(doc.Id)">
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-error>Requerido</mat-error>
                                    </mat-form-field>
                                </div>
                                <a *ngIf="exitDocumentUpload(doc.Id) && state === 'SOLICITADO'" class="button-info">NUEVO</a> 
                                <mat-icon class="mat-icon-show" 
                                    *ngIf="getUrlDocCreateInicial(doc.Id)" (click)="openDialogImageOrVideoOrPdf(doc.Id)"
                                    mattooltip="DOCUMENTO GUARDADO">
                                    visibility
                                </mat-icon>
                            </div>                            
                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <app-card-information  *ngIf="!exitPermission('contractingdocuments.view')"
            [configCard]="{typeCard: 'default', title:'DOCUMENTOS Y REQUISITOS DEL EMPLEADO'}"        
            [notListDescription]="'Aquí se visualizara los documentos del empleado'"        
            >
        </app-card-information>
    </div>
    <!-- empleadores y docuemntos Fin-->
    <!-- Datos de orden de ingreso y contrato-->
    <form [formGroup]="formOtrosDatos" (ngSubmit)="onSubmit()" class="personal-empleyer-documents">
        <!-- Datos de orden de ingreso Y EXAMEN MEDICO -->
        <div class="content-empleador" *ngIf="exitPermission('contractingdata.view')">
            <div class="personal">
                <div class="header" >
                    <div class="content" *ngIf="state === 'SOLICITADO'"
                        [ngClass]="!exitPermission('contractingdata.requestdelete') || !exitPermission('contractingdata.approvereject')? 'blueAnalityco': 'greendAnalityco'
                        ">{{'DATOS DE ORDEN DE INGRESO' || 'N/A'}}
                    </div>
                    <div class="content" *ngIf="state === 'APROBADO'"
                        [ngClass]="!exitPermission('contractingcontract.generate')? 'blueAnalityco': 'greendAnalityco'
                        ">{{'DATOS DE ORDEN DE INGRESO' || 'N/A'}}
                    </div>
                    <div class="content" *ngIf="state === 'INICIADO'"
                        [ngClass]="!exitPermission('contractingdata.requestdelete')? 'blueAnalityco': 'greendAnalityco'"
                        >{{'DATOS DE ORDEN DE INGRESO' || 'N/A'}}
                    </div>
                    <div class="content" *ngIf="state === 'CONTRATADO'"
                        [ngClass]="!exitPermission('contractingcontract.generate')? 'blueAnalityco': 'greendAnalityco'
                        ">{{'DATOS DE ORDEN DE INGRESO' || 'N/A'}}
                    </div>
                    <div class="content" *ngIf="state === 'RECHAZADO'"
                        [ngClass]="!exitPermission('contractingdata.requestdelete')? 'blueAnalityco': 'greendAnalityco'
                        ">{{'DATOS DE ORDEN DE INGRESO' || 'N/A'}}
                    </div>
                    <div class="content" *ngIf="state === 'ANULADO'"
                        [ngClass]="state === 'ANULADO' ? 'blueAnalityco': 'greendAnalityco'
                        ">{{'DATOS DE ORDEN DE INGRESO' || 'N/A'}}
                    </div>
                </div>
                <div class="content-card list-card" >
                        <!-- <div><strong>{{personal.Name + ' ' + personal.LastName || 'Sin Title'}}</strong></div> -->
                    <form [formGroup]="formDatosContrato" (ngSubmit)="onSubmit()">
                        <app-select-search
                            [_dataSource]="individualContractsType" [textInput]="'TIPO DE CONTRATO'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="formDatosContrato.get('IndividualContractTypeId')" 
                            [inputType]="'text'">
                        </app-select-search>
                        <app-select-search
                            [_dataSource]="positions" [textInput]="'OFICIO A DESEMPEÑARr'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formDatosContrato.get('PositionId')" 
                            [inputType]="'text'">
                        </app-select-search>
                        <div class="fields-group">
                            <mat-form-field class="input-field-medium">
                                <label><strong>SALARIO MENSUAL</strong></label>
                                <input matInput #moneySalary formControlName="Salary">
                                <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formPersonal.get('DocumentNumber').setValue('')">X</div> -->
                                <mat-error>Requerido</mat-error>
                            </mat-form-field>
                            <app-select-search class="select-component-medium"
                                [_dataSource]="payPeriodType" [textInput]="'PERIODO DE PAGO'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="formDatosContrato.get('PayPeriodId')" 
                                [inputType]="'text'">
                            </app-select-search>
                        </div>
                        <div class="fields-group">
                            <div class="input-field-medium">
                                <label><strong>{{'Fecha de inicio de labores' | uppercase}}</strong></label>
                            </div>
                            <mat-form-field class="input-field-medium">
                                <!-- <label><strong>Fecha</strong></label> -->
                                <mat-icon (click)="picker2.open()">today</mat-icon>
                                <input matInput [matDatepicker]="picker2" formControlName="StartDate" (click)="picker2.open()">
                                <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formDatosContrato.get('StartDate').setValue('')">X</div>
                                <mat-datepicker #picker2></mat-datepicker>
                                <mat-error>Requerido</mat-error>
                            </mat-form-field>
                        </div>
                        <app-select-search class="select-component-medium"
                                [_dataSource]="countrys" [textInput]="'Pais del contrato'" [dataShow]="'Description'" [dataValue]="'Value'" [siteCtrl]="formDatosContrato.get('contry')" 
                                [inputType]="'text'">
                            </app-select-search>
                        <app-select-search class="select-component-medium"
                                [_dataSource]="daneCity" [textInput]="'Cuidad del contrato'" [dataShow]="'CityName'" [dataValue]="'Id'" [siteCtrl]="formDatosContrato.get('ContractCityId')" 
                                [inputType]="'text'">
                        </app-select-search>
                        <app-select-search class="select-component"
                                         [_dataSource]="proyectSelect" [textInput]="'LUGAR DE TRABAJO'" [inputType]="'text'" 
                                         [dataShow]="'Name'" [dataValue]="'projectid'" [siteCtrl]="formDatosContrato.get('ProjectId')" 
                                         [isCard]="true"
                                         [card]="{img: 'Logo', title: 'Name', subTitle: 'ContractReview', finalLeft: 'ContractNumber', finalRight: 'FinishDate'}"
                                        >
                        </app-select-search>
                        <div *ngIf="exitTypeDocument('SOLICITUD_DE_INGRESO')
                                    && state !== 'INICIADO'" class="input-field">
                                <hr>
                                <div class="document-generate-orden">
                                    <svg (click)="showTypeDocument('SOLICITUD_DE_INGRESO')" 
                                        [ngClass]="state === 'SOLICITADO' ? 'solicitado_svg' : 'default_svg'"
                                        title="EPS" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                                        <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                                        <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{'PDF'}}</text>
                                    </svg>
                                    <div class="generate-document"
                                        [ngClass]="state === 'SOLICITADO' ? 'solicitado_text' : 'default_text'"   
                                        >{{getNameDocumentGenerate('SOLICITUD_DE_INGRESO')}}
                                    </div>
                                </div>
                                <hr>
                        </div>
                        <div *ngIf="exitTypeDocument('SOLICITUD_DE_INGRESO_APROBADA')
                                    && state !== 'INICIADO' && state !== 'SOLICITADO'" class="input-field">
                            <hr>
                            <div class="document-generate-orden">
                                <svg (click)="showTypeDocument('SOLICITUD_DE_INGRESO_APROBADA')"
                                    [ngClass]="state === 'APROBADO' || state === 'CONTRATADO' || state === 'LIQUIDADO' ? 'aprobado_svg' : 'default_svg'"
                                    title="EPS"  class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                                    <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                                    <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{'PDF'}}</text>
                                </svg>
                                <div class="generate-document"
                                    [ngClass]="state === 'APROBADO' || state === 'CONTRATADO' || state === 'LIQUIDADO' ? 'aprobado_text' : 'default_svg'"
                                    >{{getNameDocumentGenerate('SOLICITUD_DE_INGRESO_APROBADA')}}
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div *ngIf="exitTypeDocument('SOLICITUD_DE_INGRESO_RECHAZADA') && state !== 'SOLICITADO' && state !== 'APROBADO' && state !== 'CONTRATADO' && state !== 'ANULADO'" class="input-field">
                            <hr>
                            <div class="document-generate-orden">
                                <svg (click)="showTypeDocument('SOLICITUD_DE_INGRESO_RECHAZADA')"
                                    [ngClass]="state === 'RECHAZADO' ? 'rechazado_svg' : 'default_svg'"
                                    title="EPS" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                                    <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                                    <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{'PDF'}}</text>
                                </svg>
                                <div class="generate-document"
                                    [ngClass]="state === 'RECHAZADO' ? 'rechazado_text' : 'default_svg'"
                                    >{{getNameDocumentGenerate('SOLICITUD_DE_INGRESO_RECHAZADA')}}
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div class="text-anulado" *ngIf="state === 'ANULADO' || state === 'RECHAZADO'">
                            <div class="text-red-anulado">{{state}} POR:</div>
                            <div>{{individualContract.IndividualContractStage.Comment || 'Sin Comentarios'}}</div>
                        </div>
                        <div class="actions-dialog"style="margin: 3px;" *ngIf="(state === 'INICIADO' || state === 'RECHAZADO') && exitPermission('contractingdata.requestdelete')">
                            <button mat-button class="btn-danger" type="button" (click)="deleteOrden()">Borrar Orden</button>&nbsp;&nbsp;
                            <button mat-button class="btn-generar" type="submit">Solicitar Orden</button>
                            <!-- disabled="'disablesForm'" -->
                        </div>
                        <!-- <button mat-button class="btn-generar" type="button" (click)="disabledForm()">Personal</button> -->
                        <div class="actions-dialog"style="margin: 3px;" *ngIf="(state === 'SOLICITADO') && exitPermission('contractingdata.approvereject')">
                            <button mat-button class="btn-orange" type="button" (click)="openDialogIndividualContractRechazar()">Rechazar orden</button>&nbsp;&nbsp;
                            <button mat-button class="btn-generar" type="submit">Aprobar orden</button>
                            <!-- disabled="'disablesForm'" -->
                        </div>
                        <div class="actions-dialog"style="margin: 3px;" *ngIf="(state === 'APROBADO') && exitPermission('contractingdata.rejectannul')">
                            <button mat-button class="btn-orange" type="button" (click)="openDialogIndividualContractRechazar()">Rechazar orden</button>&nbsp;&nbsp;
                            <button mat-button class="btn-danger" type="button" (click)="anularOrdern()">Anular orden</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="personal" *ngIf="exitPermission('contractingmedicalexam.view') && (state === 'APROBADO' || state === 'CONTRATADO')" >
                <div class="header" >
                    <div class="content" *ngIf="state === 'APROBADO'"
                        [ngClass]="(!exitPermission('contractingmedicalexam.view') || exitTypeDocument('SOLICITUD_DE_EXAMENES_MEDICOS'))? 'blueAnalityco': 'greendAnalityco'
                        ">{{'EXAMEN MEDICO OCUPACIONAL' || 'N/A'}}
                    </div>
                    <div class="content" *ngIf="state === 'CONTRATADO'"
                        [ngClass]="!exitPermission('contractingmedicalexam.view') || exitTypeDocument('SOLICITUD_DE_EXAMENES_MEDICOS')? 'blueAnalityco': 'greendAnalityco'
                        ">{{'EXAMEN MEDICO OCUPACIONAL' || 'N/A'}}
                    </div>
                </div>
                <div class="content-card list-card" >
                    <form [formGroup]="formExamType" style="width: 100%;">
                            <app-select-search class="select-component"
                            [_dataSource]="examType" [textInput]="'Tipo de examen medico'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="formExamType.get('examType')" 
                                [inputType]="'text'">
                            </app-select-search>
                            <div *ngIf="exitTypeDocument('SOLICITUD_DE_EXAMENES_MEDICOS') && exitPermission('contractingmedicalexam.view')" class="input-field">
                                <hr>
                                <div class="document-generate-orden">
                                    <svg (click)="showTypeDocument('SOLICITUD_DE_EXAMENES_MEDICOS')"
                                        title="EPS" style="fill: #000000;" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                                        <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                                        <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{'PDF'}}</text>
                                    </svg>
                                    <div class="generate-document-contrato" >{{getNameDocumentGenerate('SOLICITUD_DE_EXAMENES_MEDICOS')}}</div>
                                    <!-- <div class="text-state" [ngClass]="state || ''">
                                        {{state || '' | uppercase}}
                                    </div> -->
                                </div>
                                <hr>
                            </div>
                            <div class="actions-dialog"style="margin: 3px;" *ngIf="(state === 'APROBADO') && exitPermission('contractingmedicalexam.senddeleterequest')">
                                <button mat-button type="button" (click)="deleteTypeDocument('SOLICITUD_DE_EXAMENES_MEDICOS')"
                                        [disabled]="!exitTypeDocument('SOLICITUD_DE_EXAMENES_MEDICOS')"
                                        [ngClass]="!exitTypeDocument('SOLICITUD_DE_EXAMENES_MEDICOS') ? 'btn-disabled': 'btn-danger'">
                                        Eliminar  Solicitud
                                </button>&nbsp;&nbsp;
                                <button mat-button type="button" (click)="getGenerateExamenMedico()"
                                        [disabled]="exitTypeDocument('SOLICITUD_DE_EXAMENES_MEDICOS')"
                                        [ngClass]="exitTypeDocument('SOLICITUD_DE_EXAMENES_MEDICOS') ? 'btn-disabled': 'btn-generar'">
                                    Generar Solicitud
                                </button>
                                <!-- disabled="'disablesForm'" -->
                                
                            </div>
                        <div *ngIf="exitPermission('contractingmedicalcert.view')">
                            <div class="sub-header">
                                    <div class="sub-content"*ngIf="state === 'APROBADO'"
                                        [ngClass]="!exitPermission('contractingdata.requestdelete') ? 'blueAnalityco': 'greendAnalityco'
                                        " >{{'CERTIFICADO' || 'N/A'}}
                                    </div>
                                    <div class="sub-content"*ngIf="state === 'CONTRATADO'"
                                        [ngClass]="!exitPermission('contractingdata.requestdelete') || !exitPermission('contractingdata.approvereject') || 
                                        (documentsExamFormParameters.length === docsUploadIniadoExam.length)? 'blueAnalityco': 'greendAnalityco'
                                        " >{{'CERTIFICADO' || 'N/A'}}
                                    </div>
                            </div>
                            <div class="item-list-docs" *ngFor="let doc of documentsExamFormParameters || []" [ngSwitch]="exitDocumentUploadExamForm(doc.Id)">
                                <div class="doc-name">
                                    <mat-icon class="icon-svg" *ngSwitchCase="false" (click)="uploadDocExam(doc.Id, inputDateExamn.value)">
                                        upload_file
                                    </mat-icon>
                                    <svg *ngSwitchCase="true" (click)="uploadDocExam(doc.Id, inputDateExamn.value)"
                                        title="EPS" style="fill: #6ACA25; margin-right: 3px;" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                                        <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                                        <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{doc.Abrv}}</text>
                                    </svg>
                                    <div class="text">{{doc.Name || ''}}</div>
                                    <p class="description" *ngIf="!doc.IsByDate">{{doc.Description}}</p>
                                    <div [ngClass]="getDateRequiredExam(doc.Id) === ''? 'colorNotData': ''">
                                        <mat-form-field [ngClass]="doc.IsByDate === true ? 'visibility': 'visibilityhidden'" class="input-field-short">
                                            <mat-icon (click)="pickerExam.open()">today</mat-icon>
                                            <input matInput [matDatepicker]="pickerExam" #inputDateExamn (click)="pickerExam.open()"
                                                    (dateChange)="changeSelectedDateExam(doc.Id, inputDateExamn.value)"
                                                    [value]="getDateRequiredExam(doc.Id)">
                                            <mat-datepicker #pickerExam></mat-datepicker>
                                            <mat-error>Requerido</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <mat-icon class="mat-icon-show" 
                                        *ngIf="getUrlDocCreateInicialExam(doc.Id)" (click)="openDialogImageOrVideoOrPdfExam(doc.Id)"
                                        mattooltip="DOCUMENTO GUARDADO">
                                        visibility
                                    </mat-icon>
                                </div>                            
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <app-card-information *ngIf="!exitPermission('contractingmedicalexam.view') || (state !== 'APROBADO' && state !== 'CONTRATADO')"
                [configCard]="{typeCard: 'default', title:'EXAMEN MEDICO OCUPACIONAL'}"
                [notListDescription]="'Aquí se visualizaran el examen medico ocupacional'"        
                >
            </app-card-information>
            <app-card-information *ngIf="!exitPermission('contractingdata.view')"
                [configCard]="{typeCard: 'default', title:'DATOS DE ORDEN DE INGRESO'}"
                [notListDescription]="'Aquí se visualizaran datos del contrato individual'"        
                >
            </app-card-information>
        </div>
        <!-- Datos de orden de ingreso Y EXAMEN MEDICO FIN-->
        
        <!-- CONTRATO Y OTROS DATOS DEL EMPLEADO -->
        <div class="content-empleador" *ngIf="state === 'APROBADO' || state === 'CONTRATADO' || state === 'LIQUIDADO'">            
            <div class="personal" *ngIf="(state === 'APROBADO' || state === 'CONTRATADO' || state === 'LIQUIDADO') && exitPermission('contractinganotheremployeedata.view')">
                <div class="header" >
                    <div class="content" *ngIf="state === 'APROBADO'"
                        [ngClass]="!exitPermission('contractingcontract.generate')? 'blueAnalityco': 'greendAnalityco'
                        ">{{'OTROS DATOS DEL EMPLEADO' || 'N/A'}}
                    </div>
                    <div class="content" *ngIf="state === 'CONTRATADO'"
                        [ngClass]="!exitPermission('contractingcontract.generate')? 'blueAnalityco': 'greendAnalityco'
                        ">{{'OTROS DATOS DEL EMPLEADO' || 'N/A'}}
                    </div>
                </div>
                <div class="content-card list-card" >
                            <app-select-search class="select-component"
                                    [_dataSource]="bancks" [textInput]="'Banco'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formOtrosDatos.get('BankId')" 
                                    [inputType]="'text'">
                                </app-select-search>
                            <div class="fields-group">
                                <mat-form-field class="input-field">
                                    <label><strong>No Cuenta Bancaria</strong></label>
                                    <input matInput type="text" formControlName="BankAccount">
                                    <div class="btn-clear" mat-button matSuffix mat-icon-button>X</div>
                                    <mat-error>Requerido</mat-error>
                                </mat-form-field>
                                <app-select-search class="select-component-medium"
                                    [_dataSource]="bankAccountType" [textInput]="'Tipo'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="formOtrosDatos.get('BankAccountTypeId')" 
                                    [inputType]="'text'">
                                </app-select-search>
                            </div>
                            <app-select-search class="select-component"
                                    [_dataSource]="CajadeCompensacion" [textInput]="'Caja de comp.'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formOtrosDatos.get('CcfId')" 
                                    [inputType]="'text'">
                            </app-select-search>
                            <app-select-search class="select-component"
                                    [_dataSource]="contributorType" [textInput]="'Tipo de cotizante'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formOtrosDatos.get('ContributorTypeId')" 
                                    [inputType]="'text'">
                            </app-select-search>
                            <app-select-search class="select-component"
                                    [_dataSource]="occupationalRisk" [textInput]="'Tipo de riesgo'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="formOtrosDatos.get('TypeARLId')" 
                                    [inputType]="'text'">
                            </app-select-search>
                </div>    
            </div>
            <app-card-information *ngIf="!exitPermission('contractinganotheremployeedata.view')"
                [configCard]="{typeCard: 'default', title:'OTROS DATOS DEL EMPLEADO'}"
                [notListDescription]="'Aquí se visualizaran los datos bancarios'"        
                >
            </app-card-information>
            <div class="personal" *ngIf="exitPermission('contractingcontract.view') && (exitPermission('contractingcontract.generate') || (
                    exitTypeDocument('CONTRATO') || exitTypeDocument('RECOM_MEDICAS') ||
                    exitTypeDocument('AUTORIZACION_DE_DATOS') || exitTypeDocument('AUTORIZACION_DE_EXAMENES_MEDICOS')
                ))">
                    <div class="header" >
                        <div class="content" *ngIf="state === 'APROBADO'"
                            [ngClass]="!exitPermission('contractingcontract.generate')? 'blueAnalityco': 'greendAnalityco'
                            ">{{'CONTRATO INDIVIDUAL' || 'N/A'}}
                        </div>
                        <div class="content" *ngIf="state === 'CONTRATADO'"
                            [ngClass]="!exitPermission('contractingcontract.generate')? 'blueAnalityco': 'greendAnalityco'
                            ">{{'CONTRATO INDIVIDUAL' || 'N/A'}}
                        </div>
                    </div>
                    <div class="content-card list-card" >
                    <div class="content-start" *ngIf="exitPermission('contractingcontract.generate')">
                        <div class="text">GENERAR CONTRATO</div>
                        <button mat-mini-fab color="primary" matTooltipPosition="above" matTooltip="INICIAR" >
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="exitTypeDocument('CONTRATO') && state === 'CONTRATADO'" class="input-field">
                        <hr>
                        <div class="document-generate-orden">
                            <svg (click)="showTypeDocument('CONTRATO')"
                                title="EPS" style="fill: #000000;" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                                <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                                <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{'PDF'}}</text>
                            </svg>
                            <div class="generate-document-contrato">{{getNameDocumentGenerate('CONTRATO')}}</div>
                            <!-- <div class="text-state" [ngClass]="state || ''">
                                {{state || '' | uppercase}}
                            </div> -->
                        </div>
                        <hr>
                    </div>

                    <div *ngIf="exitTypeDocument('RECOM_MEDICAS') && state === 'CONTRATADO'" class="input-field">
                        <div class="document-generate-orden">
                            <svg (click)="showTypeDocument('RECOM_MEDICAS')"
                                title="EPS" style="fill: #000000;" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                                <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                                <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{'PDF'}}</text>
                            </svg>
                            <div class="generate-document-contrato">{{getNameDocumentGenerate('RECOM_MEDICAS')}}</div>
                            <!-- <div class="text-state" [ngClass]="state || ''">
                                {{state || '' | uppercase}}
                            </div> -->
                        </div>
                        <hr>
                    </div>

                    <div *ngIf="exitTypeDocument('AUTORIZACION_DE_DATOS') && state === 'CONTRATADO'" class="input-field">
                        <div class="document-generate-orden">
                            <svg (click)="showTypeDocument('AUTORIZACION_DE_DATOS')"
                                title="EPS" style="fill: #000000;" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                                <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                                <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{'PDF'}}</text>
                            </svg>
                            <div class="generate-document-contrato">{{getNameDocumentGenerate('AUTORIZACION_DE_DATOS')}}</div>
                            <!-- <div class="text-state" [ngClass]="state || ''">
                                {{state || '' | uppercase}}
                            </div> -->
                        </div>
                        <hr>
                    </div>

                    <div *ngIf="exitTypeDocument('AUTORIZACION_DE_EXAMENES_MEDICOS') && state === 'CONTRATADO'" class="input-field">
                        <div class="document-generate-orden">
                            <svg (click)="showTypeDocument('AUTORIZACION_DE_EXAMENES_MEDICOS')"
                                title="EPS" style="fill: #000000;" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                                <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                                <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{'PDF'}}</text>
                            </svg>
                            <div class="generate-document-contrato">{{getNameDocumentGenerate('AUTORIZACION_DE_EXAMENES_MEDICOS')}}</div>
                            <!-- <div class="text-state" [ngClass]="state || ''">
                                {{state || '' | uppercase}}
                            </div> -->
                        </div>
                        <hr>
                    </div>
                        
                    </div>
                    
            </div>
            <app-card-information *ngIf="!exitPermission('contractingcontract.view') && !exitPermission('contractingcontract.generate')
            "
                [configCard]="{typeCard: 'default', title:'CONTRATO INDIVIDUAL'}"        
                [notListDescription]="'Aquí se visualizara el contrato y su liquidación'"        
                >
            </app-card-information>
        </div>
        <!-- CONTRATO Y OTROS DATOS DEL EMPLEADO FIN-->

        <!-- estos solo aparecen en estados colocados en ngIf -->
        <div class="content-empleador" *ngIf="state === 'INICIADO' || state === 'SOLICITADO' || state === 'RECHAZADO' || state === 'ANULADO'">
            <app-card-information 
                [configCard]="{typeCard: 'default', title:'OTROS DATOS DEL EMPLEADO'}"
                [notListDescription]="'Aquí se visualizaran datos bancarios y más'"        
                >
            </app-card-information>
            <app-card-information 
                [configCard]="{typeCard: 'default', title:'CONTRATO INDIVIDUAL'}"        
                [notListDescription]="'Aquí se visualizara el contrato y su liquidación'"        
                >
            </app-card-information>
        </div>
    </form>
</div> 


