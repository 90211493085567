<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'AGREGAR PERSONAL INACTIVO' || 'N/A'}}</div>
    </div>
    <div class="content-card list-card" >

        <form [formGroup]="formPersonal">
            <div class="listPersonalSelected">
                <div class="chip" *ngFor="let item of personals">
                     <div class="text-chip" matTooltip="{{item.name}}"
                        [matTooltipPosition]="'right'">{{item.Name +' '+item.LastName}}</div>
                    <mat-icon style="cursor: pointer;" (click)="removeItemPersonalSelected(item)">cancel</mat-icon>
                </div>
            </div>
            <mat-form-field class="input-field" appearance="outline">
                <mat-label> {{'BUSCAR PERSONAL DIGITANDO ID O CEDULA'}}</mat-label>
                    <input matInput formControlName="searchPersonal">
                    <mat-icon matSuffix style="cursor: pointer;" (click)="searchPersonalFilter()">search</mat-icon>
            </mat-form-field>
        </form>
        <div class="actions-dialog"style="margin: 3px;">
            <button mat-button class="btn-cancelar" type="button" (click)="close()">CERRAR</button>&nbsp;&nbsp;
            <button mat-button class="btn-generar" type="button" (click)="add()">AGREGAR</button>
        </div>
    </div>
