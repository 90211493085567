import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashBoardService {
 
    constructor( 
        private api: ApiService,
        private _http: HttpClient
    ) { }

    getContractValidateExist(token?): Observable<any>{
        if(token !== undefined) {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            });
            return this._http.get(this.api.getUrl(`api/Company/Select`), { headers: headers });
        } else {
            return this._http.get<any>(this.api.getUrl(`api/Company/Select`));
        }
    }

    // Select Filter
    getProjectSelectCompanyInfoId(token?, companyInfoId?): Observable<any> {
        if(token !== undefined) {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            });
            return this._http.get<any>(this.api.getUrl(`api/Project/Select${companyInfoId === '-1'? '': '?companyInfoId='+companyInfoId}`), { headers: headers });
        } else {
            return this._http.get<any>(this.api.getUrl(`api/Project/Select${companyInfoId === '-1'? '': '?companyInfoId='+companyInfoId}`));
        }
    }

    getEmployerSelectCompanyInfoId(token?, companyInfoId?, projectId?): Observable<any> {
        let url = ''   
        if(companyInfoId === '-1' && projectId === '') {
            url = '';
        } else if(companyInfoId === '-1' && projectId !== '') {
            url = '?projectId='+projectId;
        } else if(companyInfoId !== '-1' && projectId !== '') {
            url = '?companyInfoId='+companyInfoId+'&projectId='+projectId;
        }
        //Se agregar token por dashboard desde androi
        if(token !== undefined) {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            });
            return this._http.get<any>(this.api.getUrl(`api/Employer/Select${url}`), { headers: headers });
        } else {
            return this._http.get<any>(this.api.getUrl(`api/Employer/Select${url}`));
        }
        
    }


    getContractSelectCompanyInfoId(token?, companyInfoId?, employerId?, projectId?): Observable<any> {
        let url = ''   
        if(companyInfoId === '-1' && employerId === '' && projectId === '') {
            url = '';
        } else if(companyInfoId === '-1' && employerId !== '' && projectId === '') {
            url = '?employerId='+employerId;
        } else if(companyInfoId !== '-1' && employerId !== '' && projectId === '') {
            url = '?companyInfoId='+companyInfoId+'&employerId='+employerId;
        } else if(companyInfoId !== '-1' && employerId === '' && projectId !== '') {
            url = '?companyInfoId='+companyInfoId+'&projectId='+projectId;
        } else if(companyInfoId !== '-1' && employerId !== '' && projectId !== '') {
            url = '?companyInfoId='+companyInfoId+'&employerId='+employerId+'&projectId='+projectId;
        }
        //Se agregar token por dashboard desde androi
        if(token !== undefined) {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            });
            return this._http.get<any>(this.api.getUrl(`api/Contract/Select${url}`), { headers: headers });
        } else {
            return this._http.get<any>(this.api.getUrl(`api/Contract/Select${url}`));
        }
    }

    
    // Dashboard Config main
    getDashBoardMainCompanyInfoId(token?, companyInfoId?, contractId?, employerId?, projectId?): Observable<any> {
        let url = ''
        if(companyInfoId === '-1' && contractId ==='' && employerId ==='' && projectId === '') {
            url = 'api/DashBoard/Main';
        } if(companyInfoId !== '-1') {
            url = 'api/DashBoard/Main?companyInfoId='+companyInfoId+
               (contractId !== '' ? '&contractId='+contractId : '')+
               (employerId !== '' ? '&employerId='+employerId : '')+
               (projectId !== '' ? '&projectId='+projectId : '');
        } else {
            url = 'api/DashBoard/Main?'+
               (contractId !== '' ? '&contractId='+contractId : '')+
               (employerId !== '' ? '&employerId='+employerId : '')+
               (projectId !== '' ? '&projectId='+projectId : '');
        }
        //Se agregar token por dashboard desde androi
        if(token !== undefined) {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            });
            return this._http.get<any>(this.api.getUrl(url), { headers: headers });
        } else {
            return this._http.get<any>(this.api.getUrl(url));
        }
    }

    getDashBoardMainCompanyInfoIdisCount(token?, companyInfoId?, contractId?, employerId?, projectId?): Observable<any> {
        let url = ''
        if(companyInfoId === '-1' && contractId ==='' && employerId ==='' && projectId === '') {
            url = 'api/DashBoard/Main?isCount=true';
        } if(companyInfoId !== '-1') {
            url = 'api/DashBoard/Main?companyInfoId='+companyInfoId+
               (contractId !== '' ? '&contractId='+contractId : '')+
               (employerId !== '' ? '&employerId='+employerId : '')+
               (projectId !== '' ? '&isCount=true&projectId='+projectId : '&isCount=true');
        } else {
            url = 'api/DashBoard/Main?'+
               (contractId !== '' ? '&contractId='+contractId : '')+
               (employerId !== '' ? '&employerId='+employerId : '')+
               (projectId !== '' ? '&projectId='+projectId : '');
        }
        //Se agregar token por dashboard desde androi
        if(token !== undefined) {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            });
            return this._http.get<any>(this.api.getUrl(url), { headers: headers });
        } else {
            return this._http.get<any>(this.api.getUrl(url));
        }
        
    }

    getDashBoardMainCompanyInfoIdbyPersona(token?, companyInfoId?, dashboardPersonalFilterValue?, contractId?, employerId?, projectId?): Observable<any> {
        let url = ''
        if(companyInfoId === '-1' && contractId ==='' && employerId ==='' && projectId === '') {
            url = `api/DashBoard/Main${dashboardPersonalFilterValue}=true`;
        } if(companyInfoId !== '-1') {
            url = 'api/DashBoard/Main?'+dashboardPersonalFilterValue+'=true&companyInfoId='+companyInfoId+
               (contractId !== '' ? '&contractId='+contractId : '')+
               (employerId !== '' ? '&employerId='+employerId : '')+
               (projectId !== '' ? '&projectId='+projectId : '');
        } else {
            url = 'api/DashBoard/Main?'+dashboardPersonalFilterValue+'=true'+
               (contractId !== '' ? '&contractId='+contractId : '')+
               (employerId !== '' ? '&employerId='+employerId : '')+
               (projectId !== '' ? '&projectId='+projectId : '');
        }
        //Se agregar token por dashboard desde androi
        if(token !== undefined) {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            });
            return this._http.get<any>(this.api.getUrl(url), { headers: headers });
        } else {
            return this._http.get<any>(this.api.getUrl(url));
        }
    }
    
}