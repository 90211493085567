import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { isArray } from "rxjs/internal-compatibility";
import { takeUntil } from "rxjs/operators";
import { CardSelect } from "src/app/core/interfaces/interfaces";
import { CommonMetodService } from "src/app/core/services/utils/commonMetod.service";
import { environment } from "src/environments/environment";

export interface Card {
  img: string;
  title: string;
  subTitle?: string;
  finalLeft?: string;
  finalRight?: string;
  // Por si la data es typo de dato (NIT): y el value (number)
  itemKey?: string;
  itemValue?: string;
}

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent implements OnInit {
  s3Bucket:string;

  dataSource: any[] = [];
  dataSourceSave: any[] = [];
  @Input() set _dataSource(value: Array<any>) {
    value.length === 0 ? this.dataSource = []: this.dataSource = value;
    this.createForm();
  }

  @Input() set _dataSourceSave(value: Array<any>) {
    value.length === 0 ? this.dataSourceSave = []: this.dataSourceSave = value;
  }

  @Input() set _value(value: any) {
    if(value !== null && value !== undefined) {
      this.siteCtrl.setValue(value);
    }
  }

  @Input() card: CardSelect;

  @Input() textInput: string;
  @Input() inputType: string;
  // Data Filter dataShow and otherFilter
  @Input() dataShow: string;
  @Input() otherFilter: string;

  @Input() appearance: string = '';

  // is card
  @Input() isCard: boolean = false;
  @Input() isCardImage: boolean = false;
  @Input() isDescription: boolean = false;
  @Input() isCardAdove: boolean = false;

  @Input() isCleanSelect = false;

  @Input() dataValue: string;
  @Output() onSelectionChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() siteCtrl: FormControl;

  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() fill: boolean = false;

  @Input() onErrorUrl: string = '';

  @Input() placeholder: string = '';z

  color: string = '';

  selectedOption: string | null = null;

  @Input() set _color(value: any) {
    if(value !== undefined && value !== '') {
      this.color = value;
      this.selectedOption = value ? 'SIN VERIFICAR':''
    }
  }

  public inputFilter: FormControl = new FormControl();
  public controlFilter: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  private _changeSubscription: Subscription = null;
  protected _onDestroy = new Subject<void>();

  @Input() selectOpen: boolean = false;
  @ViewChild('select') select: MatSelect;

  constructor(
    public commonMetod: CommonMetodService
  ) { }

  ngOnInit() {
    this.s3Bucket = environment.api.s3Bucket;
    if(this.selectOpen === true ) {
      this.OpenSelect();
    }
  }

  ngOnDestroy() {
    if (this._changeSubscription !== null) {
      this._changeSubscription.unsubscribe();
    }
  }

  OpenSelect() {
    setTimeout(() => {
      this.select.open();
    },1000)

  }

  createForm() {
    this.inputFilter.value === null ? this.inputFilter.setValue(''): this.inputFilter.setValue(this.inputFilter.value);
    if (this.dataSource !== null) {
      this.setInitialValue(); // STRUGGLING HERE
      this.inputFilter.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterData();
        });
    }
  }

  changeSelected(e) {
    this.onSelectionChange.emit(e);
    this.selectedOption = e;
    if (this.siteCtrl.value === e && this.isCleanSelect === true) {
      this.siteCtrl.setValue(null);
    }

  }

  setInitialValue() {
    this.controlFilter.next(this.dataSource.slice());
  }

  protected filterData() {
    if (!this.dataSource) {
      return;
    }
    // get the search keyword
    let search = this.inputFilter.value;
    if (!search) {
      this.controlFilter.next(this.dataSource.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.dataShow === null ? this.dataShow = '' : this.dataShow = this.dataShow;
    this.otherFilter === null ? this.otherFilter = '' : this.otherFilter = this.otherFilter;
    // filter the data
    // 1 parametro de busqueda es this.dataShow
    if(this.otherFilter === undefined) {
      if(this.isCardImage === false) {
        this.controlFilter.next(
          this.dataSource.filter(
            data => data[this.dataShow]?.toLowerCase().indexOf(search) > -1
          )
        );
      } else {
        this.controlFilter.next(
          this.dataSource.filter(arraydata => {
            return arraydata[this.dataShow]?.find(data => {
                return data?.toLowerCase().indexOf(search) > -1
              });
          })
        );
      }
    } else {
      // 2 parametro de busqueda es this.dataShow and this.otherFilter
        this.controlFilter.next(
          this.dataSource.filter(
            data => isArray(data[this.dataShow]) ?
            data[this.dataShow][0]['ProjectName']?.toLowerCase().indexOf(search) > -1 || data[this.otherFilter]?.toLowerCase().indexOf(search) > -1
            :
            data[this.dataShow]?.toLowerCase().indexOf(search) > -1 || data[this.otherFilter]?.toLowerCase().indexOf(search) > -1
          )
        );
    }

  }

  getImg(logo) {
    let img = (logo === null || logo === '' || logo === undefined ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg': logo);
    return img;
  }

  getDateToolTip(value) {
    let data = this.dataSource.find(x => x[this.dataValue] === value);
    if(data !== undefined) {
      return data[this.otherFilter];
    }
  }

  getStringDate(string): string {
    return string;
  }

  optionSelected(): boolean {
    return this.selectedOption === 'SIN VERIFICAR';
  }
}
