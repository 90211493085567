import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { CardList, FilterList } from 'src/app/core/interfaces/interfaces';
import { FilterMultipleService } from 'src/app/core/services/utils/filter-multiple.service';
import { environment } from 'src/environments/environment';
import {FechasService} from '../../../services/utils/fechas.service';

export interface DialogData {
  dataList: any;
  cardOne: CardList;
  cardTwo: CardList;
  path: string;
  title: string;
  dataOne: any;
  dataTwo: any;
}

@Component({
  selector: 'app-dialog-selected-vincular-contracts',
  templateUrl: './dialog-selected-vincular-contracts.component.html',
  styleUrls: ['./dialog-selected-vincular-contracts.component.scss']
})
export class DialogSelectedVincularContractsComponent implements OnInit {
  s3Bucket: string;
  production: boolean = environment.production;

  list: any = [];
  listSlice: any = [];
  arreglo: any[] = [];
  listShow: any[] = [];
  contracts: any[] = [];
  contractsSelected: any[] = [];

  filterValue = '';
  filterListValue = '';

  @ViewChild('paginatorList', {read: MatPaginator}) paginatorList: MatPaginator;

  filterList: FilterList[] = [
    { Value: '', Name: 'Todos', icon: '', count: null},
    { Value: 'IsActive', Name: 'Activos', icon: '', count: null},
    { Value: 'IsInactive', Name: 'Inactivos', icon: '', count: null}
  ];

  constructor(
    public dialogRef: MatDialogRef<DialogSelectedVincularContractsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private filters: FilterMultipleService,
    public fechasService: FechasService,
  ) {
      this.contracts = data.dataOne;
      this.list = data.dataOne;
      this.arreglo = data.dataOne;
      this.listSlice = this.contracts.slice(0, 32);
      const arrayNewContractModifica = [];
      data.dataOne.forEach((x) => {
          arrayNewContractModifica.push({...x, ControlCheck: false});
      });
      this.listShow = arrayNewContractModifica;
  }

  ngOnInit(): void { }

  getImg(logo) {
    const img = (logo === null || logo === undefined ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg': logo);
    return img;
  }

  close() {
    this.dialogRef.close(undefined);
  }

  save(selectedAll) {
    this.dialogRef.close({contracts: this.contractsSelected, selectedAll: selectedAll});
  }

  selectAll() {
    const uniqueProjectsInData = [...this.data.dataTwo, ...this.listSlice].reduce((acc, item) => {
      if (!acc.includes(item.ProjectName)) { acc.push(item.ProjectName); }
      return acc;
    }, []);

    const uniqueProjectsInSelected = this.contractsSelected.map(item => item.ProjectName);
    const allProjectsSelected = uniqueProjectsInData.every(project => uniqueProjectsInSelected.includes(project));

    if (allProjectsSelected) {
      this.contractsSelected = [];
      this.data.dataTwo.forEach(item => item.ControlCheck = false);
      this.listSlice.forEach(item => item.ControlCheck = false);
      return;
    }

    this.data.dataTwo.forEach(item => item.ControlCheck = true);
    this.listSlice.forEach(item => item.ControlCheck = true);

    [...this.data.dataTwo, ...this.listSlice].forEach((item) => {
      const existingIndex = this.contractsSelected.findIndex(x => x.ProjectName === item.ProjectName);

      if (item.ControlCheck) {
        const projectExists = this.contractsSelected.some(x => x.ProjectName === item.ProjectName);
        if (!projectExists && existingIndex === -1) {
          this.contractsSelected.push(item);
        }
      } else {
        if (existingIndex !== -1) {
          this.contractsSelected.splice(existingIndex, 1);
        }
      }
      item.ControlCheck = this.contractsSelected.some(x => x.Id === item.Id);
    });
  }

  searchFilterPagination(changeFilter: string){
    const startIndex = this.paginatorList.pageIndex * this.paginatorList.pageSize;
    let endIndex = startIndex + this.paginatorList.pageSize;
    if (this.filterListValue === '' && changeFilter === '' && this.filterValue === '' ) {
      this.arreglo = this.list;
    }else {
      this.filterListValue = changeFilter;
      this.arreglo = this.getPersonalFilter(this.filterListValue, this.filterValue);
    }
    if (endIndex > this.arreglo.length) {
      endIndex = this.arreglo.length;
    }
    this.listSlice = this.arreglo.slice(startIndex, endIndex);
  }

  getPersonalFilter(value: string, filter?: string): any[] {
    if (value === '' && (filter === '' || filter === undefined)) {
        return this.list;
    } else if (filter === 'IsActive') {
        return this.list.filter(x => {
            return (this.filters.exitFilter(x[this.data.cardOne.titleFirst] + ' ' + x.LastName, value) ||
                    this.filters.exitFilter(x.DocumentNumber, value) ||
                    this.filters.exitFilter(x.DocumentType, value)) &&
                    (x.IsActive === true);
        });
    } else if (filter === 'IsInactive') {
        return this.list.filter(x => {
            return (this.filters.exitFilter(x.Name + ' ' + x.LastName, value) ||
                    this.filters.exitFilter(x.DocumentNumber, value) ||
                    this.filters.exitFilter(x.DocumentType, value)) &&
                    (x.IsActive === false);
        });
    } else  {
      return this.list.filter(x => {
        return (this.filters.exitFilter(x[this.data.cardOne.titleFirst] + ' ' + (x[this.data.cardOne.abodeTextMedium] ===
          undefined ? '' : x[this.data.cardOne.itemFinalText]), value) ||
                this.filters.exitFilter(x[this.data.cardOne.abodeTextMedium], value) ||
                this.filters.exitFilter(x[this.data.cardOne.itemFinalText], value));
        });
    }
  }

  selectedItem(item, i) {
    this.listSlice[i].ControlCheck = !this.listSlice[i].ControlCheck;

    if (this.listSlice[i].ControlCheck === true) {
      const existingIndex = this.contractsSelected.findIndex(x => x.ProjectName === item.ProjectName);
      if (existingIndex !== -1) {
        this.contractsSelected[existingIndex].ControlCheck = false;
        this.contractsSelected.splice(existingIndex, 1);
      }
      const data = this.contractsSelected.find(x => x.Id === item.Id);
      if (data === undefined) {
        this.contractsSelected.push(item);
      }
    } else {
      const index = this.contractsSelected.findIndex(x => x.Id === item.Id);
      if (index !== -1) {
        this.contractsSelected.splice(index, 1);
      }
    }
  }


  selectedItemBound(item: any, i: number) {
    this.data.dataTwo[i].ControlCheck = !this.data.dataTwo[i].ControlCheck;

    if (this.data.dataTwo[i].ControlCheck) {
      const existingIndex = this.contractsSelected.findIndex(x => x.ProjectName === item.ProjectName);
      if (existingIndex !== -1) {
        this.contractsSelected[existingIndex].ControlCheck = false;
        this.contractsSelected.splice(existingIndex, 1);
      }
      const exists = this.contractsSelected.find(x => x.Id === item.Id);
      if (!exists) {
        this.contractsSelected.push(item);
      }
    } else {
      const index = this.contractsSelected.findIndex(x => x.Id === item.Id);
      if (index !== -1) {
        this.contractsSelected.splice(index, 1);
      }
    }
  }



}
