import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../http/api.service';
import { Observable } from 'rxjs';
import { DetailsSurveySymptoms, PersonalInfoEncuenta, SurveySymptomsValidateAds } from '../../interfaces/interfaces';



@Injectable({
  providedIn: 'root'
})
export class SymptomReportService {

    
    personalInfo: PersonalInfoEncuenta;
    constructor(    
        private api: ApiService,
        private _http: HttpClient
    ) { }


    //Guardar Encuenta SurveySymptoms
    saveEncuesta(data, token) {
        const headers = {'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `bearer ${token}`};
        return this._http.post(this.api.getUrl('api/SurveySymptoms'), data, {headers});
    }

    getDetailsSurveySymptoms(id): Observable<DetailsSurveySymptoms> {
        return this._http.get<DetailsSurveySymptoms>(this.api.getUrl('api/SurveySymptoms/'+id));
    }

    //Guardar Encuenta SurveySymptoms
    SurveySymptoms(companyInfoId): Observable<SurveySymptomsValidateAds> {
        return this._http.get<SurveySymptomsValidateAds>(this.api.getUrl('api/SurveySymptoms/ValidateAds/?companyInfoId='+companyInfoId));
    }
}




