import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DaneCity } from 'src/app/core/interfaces/interfaces';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';

export interface DialogData {
  update: boolean, CompanyId: string, model?: any, daneCity?, isCreate?: boolean;
}

@Component({
  selector: 'app-dialog-crud-sucursales',
  templateUrl: './dialog-crud-sucursales.component.html',
  styleUrls: ['./dialog-crud-sucursales.component.scss']
})
export class DialogCrudSucursalesComponent implements OnInit {

  formSucursal: FormGroup;

  daneCity: DaneCity[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogCrudSucursalesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private commonService: CommonService,
    private snackBService: SnackBarService,
  ) {
    console.log('data',data);
    this.daneCity = this.data.daneCity;
  }

  ngOnInit(): void {
    this.formSucursal = this.fb.group({
        Id: '',
        Address: new FormControl('',Validators.required),
        CityCode: new FormControl('',Validators.required),
        Name: new FormControl('',Validators.required),
        Phone: new FormControl('',Validators.required),
        Email: new FormControl('',Validators.required),
        IsMain: new FormControl(false,Validators.required),
        CompanyId: new FormControl('',Validators.required)
    });
    if(this.data.model) {
      if(this.data.isCreate === true){
        this.formSucursal.get('Address').setValue(this.data.model.Address);
        this.formSucursal.get('CityCode').setValue(this.data.model.CityCode);
        this.formSucursal.get('Name').setValue(this.data.model.Name);
        this.formSucursal.get('Phone').setValue(this.data.model.Phone);
        this.formSucursal.get('Email').setValue(this.data.model.Email);
        this.formSucursal.get('CompanyId').setValue(this.data.CompanyId);
      }else {
        this.formSucursal.get('Id').setValue(this.data.model.Id);
        this.formSucursal.get('Address').setValue(this.data.model.BranchofficeAddress);
        this.formSucursal.get('CityCode').setValue(this.data.model.BranchofficeCityCode);
        this.formSucursal.get('Name').setValue(this.data.model.BranchofficeName);
        this.formSucursal.get('Phone').setValue(this.data.model.BranchofficePhone);
        this.formSucursal.get('Email').setValue(this.data.model.BranchofficeEmail);
        this.formSucursal.get('IsMain').setValue(this.data.model.BranchofficeIsMain);
        this.formSucursal.get('CompanyId').setValue(this.data.model.BranchofficeCompanyId);
        console.log('this.formSucursal', this.formSucursal);
      }
    }
    if(this.data.update === false) {
    this.formSucursal.get('CompanyId').setValue(this.data.CompanyId);
    }
  }

  onSubmit() {
    if(this.data.update === false) {
      if(this.formSucursal.status === 'VALID') {
        let sucursal = {
          Address: this.formSucursal.get('Address').value,
          CityCode: this.formSucursal.get('CityCode').value,
          Name: this.formSucursal.get('Name').value.substring(0, 1).toUpperCase() + this.formSucursal.get('Name').value.substring(1).toLowerCase(),
          Phone: this.formSucursal.get('Phone').value,
          Email: this.formSucursal.get('Email').value,
          IsMain: this.formSucursal.get('IsMain').value,
          CompanyId: this.formSucursal.get('CompanyId').value
        }
        if (this.data.isCreate === true){
          this.dialogRef.close(sucursal);
        } else {
          this.commonService.postBranchOffice(sucursal).subscribe(restCreate => {
            this.dialogRef.close(true);
          });
        }
      } else {
        this.snackBService.openSnackBar('Completar el formulario', '', 3000);
      }
    } else {
      if(this.formSucursal.status === 'VALID') {
        let sucursal = {
          Address: this.formSucursal.get('Address').value,
          CityCode: this.formSucursal.get('CityCode').value,
          Name: this.formSucursal.get('Name').value,
          Phone: this.formSucursal.get('Phone').value,
          Email: this.formSucursal.get('Email').value,
          IsMain: this.formSucursal.get('IsMain').value,
          CompanyId: this.formSucursal.get('CompanyId').value
        }
        if (this.data.isCreate === true){
          this.dialogRef.close(sucursal);
        } else {
          this.commonService.putBranchOffice(this.formSucursal.get('Id').value, sucursal).subscribe(restCreate => {
            this.dialogRef.close(true);
          });
        }
      } else {
        this.snackBService.openSnackBar('Completar el formulario', '', 3000);
      }
    }

  }

  close() {
    this.dialogRef.close();
  }

}
