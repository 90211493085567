import {Component, Inject, OnInit} from '@angular/core';
import {CommonService} from '../../../services/modules/common.service';
import {SnackBarService} from '../../../services/utils/snackBar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContractService} from '../../../services/modules/contract.service';

export interface DialogData {
  companyInfoId: any;
}

@Component({
  selector: 'app-dialog-contract-notification-scheduling',
  templateUrl: './dialog-contract-notification-scheduling.component.html',
  styleUrls: ['./dialog-contract-notification-scheduling.component.scss']
})
export class DialogContractNotificationSchedulingComponent implements OnInit {
  companyId = '';
  formNotificationsType: FormGroup;
  editDataNotificationSchedule: any;

  flagNotificationView = true;
  flagEditNotificationSchedule = false;
  flagConfigNotificationSchedule = false;
  flagEditNotificationScheduleChange = false;

  projectsList: any[] = [];
  permissionsCompany: string[] = [];
  notificationSchedule = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private commonService: CommonService,
    private contractService: ContractService,
    private snackBService: SnackBarService,
    private dialogRef: MatDialogRef<DialogContractNotificationSchedulingComponent>
  ) { }

  ngOnInit(): void {
    this.commonService.getUserInfo().subscribe((userinfo) => {
      this.permissionsCompany = userinfo.Companies.find(x => x.Id ===  this.companyId).Permissions;
    });
    this.formNotificationsType = this.fb.group({
      email: new FormControl('', Validators.required),
      Description:  new FormControl('', Validators.required),
      AdministratorId: '',
      IsActive: false,
      Id: ''
    });
    this.formNotificationsType.get('email')?.valueChanges.subscribe((value: string) => {
      const lowercaseValue = value?.toLowerCase() || '';
      if (value !== lowercaseValue) {
        this.formNotificationsType.get('email')?.setValue(lowercaseValue, { emitEvent: false });
      }
    });
    this.companyId = this.data.companyInfoId;

    this.getNotificationSchedule();
    this.getProjects(this.companyId);
  }

  exitPermissionCompany(permiso: string): boolean {
    let exit: boolean;
    const permisionsUserInfo  = this.permissionsCompany.find(x => x === permiso);
    permisionsUserInfo !== undefined ? exit = true : exit = false;
    return exit;
  }

  getNotificationSchedule() {
    this.contractService.getNotificationsSchedule(this.companyId).subscribe(rest => {
      this.notificationSchedule = rest;
    });
  }

  getProjects(adminInfoId: string, isActive: boolean = true) {
    this.contractService.getNotificationsScheduleProject(adminInfoId, isActive).subscribe((rest: any) => {
      this.projectsList = rest.map(p => ({ ...p, IsActive: false }))
        .sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
    });
  }

  getProjectNames(item: any): string {
    return item.Projects?.map(p => p.ProjectName).join(', ') || '';
  }

  addCreateNotificationSchedule(){
    this.formNotificationsType.get('email').setValue('');
    this.formNotificationsType.get('Description').setValue('');
    this.formNotificationsType.get('IsActive').setValue(false);
    this.flagEditNotificationSchedule = true;
    this.flagEditNotificationScheduleChange = false;
    this.editDataNotificationSchedule = {};
    this.flagNotificationView = false;
  }

  editarProgramming(item?) {
    this.flagEditNotificationSchedule = true;
    this.flagEditNotificationScheduleChange = true;
    this.editDataNotificationSchedule = item;
    this.formNotificationsType.get('email').setValue(item.Email);
    this.formNotificationsType.get('Description').setValue(item.Description);
    this.formNotificationsType.get('IsActive').setValue(item.IsActive);
    this.flagNotificationView = false;
  }

  setUpProgramming(item) {
    this.flagConfigNotificationSchedule = true;
    this.flagEditNotificationSchedule = false;
    this.flagNotificationView = false;
    this.editDataNotificationSchedule = item;

    this.projectsList.forEach(p => {
      p.IsActive = item.Projects.some(pr => pr.ProjectId === p.Id);
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmitTypeNotifications() {
    const postModelNotifications = {
      Email: this.formNotificationsType.get('email').value,
      Description: this.formNotificationsType.get('Description').value,
      IsActive: this.formNotificationsType.get('IsActive').value,
      AdminInfoId: this.companyId
    };
    if (this.formNotificationsType.status === 'VALID' && this.flagEditNotificationScheduleChange === false) {
      this.contractService.postNotificationSchedule(postModelNotifications).subscribe(rest => {
        this.flagEditNotificationSchedule = false;
        this.flagEditNotificationScheduleChange = false;
        this.flagNotificationView = true;
        this.getNotificationSchedule();
      });
    } else if (this.formNotificationsType.status === 'VALID' && this.flagEditNotificationScheduleChange === true) {
      const putModelNotifications = {
        Email: this.formNotificationsType.get('email').value,
        Description: this.formNotificationsType.get('Description').value,
        IsActive: this.formNotificationsType.get('IsActive').value,
        AdminInfoId: this.companyId
      };
      this.contractService.putNotificationSchedule(this.editDataNotificationSchedule.Id, putModelNotifications).subscribe(rest => {
        this.flagEditNotificationSchedule = false;
        this.flagEditNotificationScheduleChange = false;
        this.flagNotificationView = true;
        this.getNotificationSchedule();
       });
    }else {
      this.snackBService.openSnackBar('Falta algun campo', 'X', 4000);
    }
  }

  onSubmitSetupProjects() {
    const putModelNotificationsProject = {
      NotificationId: this.editDataNotificationSchedule.Id,
      ProjectIds: this.projectsList.filter(p => p.IsActive).map(p => p.Id)
    };
    this.contractService.putNotificationScheduleProject(putModelNotificationsProject).subscribe(rest => {
      this.flagConfigNotificationSchedule = false;
      this.flagEditNotificationSchedule = false;
      this.getNotificationSchedule();
      this.flagNotificationView = true;
    });
  }

  notEditNotifications() {
    this.flagEditNotificationSchedule = false;
    this.flagEditNotificationScheduleChange = false;
    this.flagNotificationView = true;
  }

  notConfigNotifications() {
    this.flagConfigNotificationSchedule = false;
    this.flagNotificationView = true;
  }

}
