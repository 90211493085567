import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../../../services/modules/common.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FilterMultipleService } from 'src/app/core/services/utils/filter-multiple.service';
import { Requirements } from 'src/app/core/interfaces/interfaces';

export interface DialogData {
  title: string;
  Requirements: any[];
}

@Component({
  selector: 'app-dialog-requirement-employer',
  templateUrl: './dialog-requirement-employer.component.html',
  styleUrls: ['./dialog-requirement-employer.component.scss']
})
export class DialogRequirementEmployerComponent implements OnInit {
  requirementsDocuments: Requirements[] = [];
  filterRequirementsDocuments: Requirements[] = [];

  requirementsUpdate: any[] = [];
  requirements: any = [];
  requirementsSave: any = [];
  title: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogRequirementEmployerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonService: CommonService,
    private filters: FilterMultipleService
  ) { 
    this.requirementsSave = data.Requirements;
    this.title = data?.title;
    this.commonService.getRequirement().subscribe((requirement: any) => {
      this.filterRequirementsDocuments = requirement.filter(x => x.IsActive === true && x.ByEmployer === true ).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
      this.requirementsDocuments = requirement.filter(x => x.IsActive === true && x.ByEmployer === true ).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

      this.requirementsDocuments.forEach(req => {
        if(this.exitRequisito(req.Id) === true) {
          this.requirementsUpdate.push({Id: req.Id, op: 'SAVE'});
        }
      });
    });
    
  }

  ngOnInit(): void { }

  close() {
    this.dialogRef.close(false);
  }

  checked(event: MatSlideToggleChange , requirementId: number) {
    if(event.checked === true) { 
      let data = this.requirementsUpdate.find(x => x.Id === requirementId);
      // si exite
      if(data === undefined) {
        this.requirementsUpdate.push({Id: requirementId, op: 'CREATE'});
      } else {
        this.requirementsUpdate.forEach((value, index) => {
          if(this.exitRequisito(value.Id) === true) {
            value.Id === requirementId ? value.op = 'TRUE': '';
          } else {
            value.Id === requirementId ? value.op = 'CREATE': '';
          }
        });
      }
    } else {
      this.requirementsUpdate.forEach((value, index) => {
        if(this.exitRequisito(value.Id) === true) {
        value.Id === requirementId ? value.op = 'FALSE': '';
        } else {
          value.Id === requirementId ? value.op = 'SAVE': '';
        }
      });
    }
  }

  cheketRequisito(Id): boolean {
    let exit = false;
    let data = this.requirementsSave.find(x => x.RequirementId === Id && x.IsActive === true);
    data !== undefined ? exit = true: exit = false;
    return exit;
  }

  exitRequisito(Id): boolean {
    let exit = false;
    let data = this.requirementsSave.find(x => x.RequirementId === Id);
    data !== undefined ? exit = true: exit = false;
    return exit;
  }


  save() {
    this.dialogRef.close(this.requirementsUpdate);
  }

  getfilterRequirements(value: string) {
    if(value === '') {
      this.filterRequirementsDocuments = this.requirementsDocuments;
    } else {
      this.filterRequirementsDocuments = this.getRequirementsFilterDocuments(value);
    }
  }

  getRequirementsFilterDocuments(value: string): any[] {
      return this.requirementsDocuments.filter(x => {
        return (this.filters.exitFilter(x.Name, value));
      });
  }

}