<div class="container">
    <div class="box">

        <div class="title">
            <span class="block"></span>
            <h1><img src="../../../../favicon.ico" alt="" width="35" height="35"> <p>ANALITYCO</p><span></span></h1>
        </div>

        <div class="role">
            <div class="block"></div>
            <p>No tienes permisos, por favor comunicate con un Administrador.</p>
        </div>

    </div>
</div>