import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

    constructor(
        private api: ApiService,
        private _http: HttpClient
    ) { }

    //GET user Correo
    getVehicle(): Observable<any>{
        return this._http.get<any>(this.api.getUrl(`api/Vehicle`));
    }

    getVehicleId(vehicleCompanyInfoId): Observable<any>{
        return this._http.get<any>(this.api.getUrl(`api/Vehicle?vehicleCompanyInfo=${vehicleCompanyInfoId}`));
    }

    getVehicleIdContract(vehicleCompanyInfoId): Observable<any>{
        return this._http.get<any>(this.api.getUrl(`api/Vehicle/Contract?vehicleCompanyInfoId=${vehicleCompanyInfoId}`));
    }

    getVehicleContractAll(): Observable<any>{
        return this._http.get<any>(this.api.getUrl(`api/Vehicle/Contract`));
    }

    updatePhotoVehicle(id, file) {
        return this._http.put(this.api.getUrl(`api/Vehicle/CompanyInfo/UpdatePhoto/${id}`), file);
    }

    putVehicle(vehicleCompanyInfoId, model) {
        return this._http.put(this.api.getUrl(`api/Vehicle/${vehicleCompanyInfoId}`), model);
    }

    postVehicle(model) {
        return this._http.post(this.api.getUrl(`api/Vehicle`), model);
    }

    postVehicleContract(model) {
        return this._http.post(this.api.getUrl(`api/Vehicle/Contract/true`), model);
    }

    putVehicleContract(vehicleContractId, model) {
        return this._http.put(this.api.getUrl(`api/Vehicle/Contract/${vehicleContractId}`), model);
    }

  postVehicleContractFalse() {
    return this._http.post(this.api.getUrl(`api/Vehicle/Contract/false`),null);
  }
    postVehicleContractMove(move, model) {
      return this._http.post(this.api.getUrl(`api/Vehicle/Contract/${move}`), model);
    }


  getVehicleByLic(license): Observable<any>{
        return this._http.get<any>(this.api.getUrl(`api/Vehicle/ByLic?license=${license}`));
    }

    // Apis enrla vehicle a contrato
    getVehicleDocNumContractId(docNum, contractId) {
        return this._http.get(this.api.getUrl(`api/Vehicle/GetId/${docNum}/${contractId}`));
    }

    getIsContractVehicleCompanyInfoId(ContracId, VehicleInfoId) {
        return this._http.get(this.api.getUrl(`api/Vehicle/${ContracId}/HasContract/${VehicleInfoId}`));
    }

    postMoveVehicleInfoContract(ContracId, modelo) {
        return this._http.post(this.api.getUrl(`api/Vehicle/${ContracId}/MoveVehicleInfo`),modelo);
    }

    putRemoveContract(modelo) {
      return this._http.put(this.api.getUrl(`api/Vehicle/RemoveVehicle`),modelo);
    }
}
