import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment.prod';
import { DomSanitizer } from '@angular/platform-browser';
// import * as XLSX from 'xlsx';
export interface DialogImage {
  image: string;
  video: string;
}

@Component({
  selector: 'app-dialog-image-or-video',
  templateUrl: './dialog-image-video.component.html',
  styleUrls: ['./dialog-image-video.component.scss']
})
export class DialogImageOrVideoComponent implements OnInit {  
  // Nota la url de la imagen o video o pdf tiene que ser recortada no completa
  s3Bucket:string;
  urlPdf: string;
  pdf;

  constructor(
    private dialogRef: MatDialogRef<DialogImageOrVideoComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogImage,
      private sanitizer: DomSanitizer
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.s3Bucket= environment.api.s3Bucket;
    if(this.data.image){
      if(this.data.image.slice(-4).toUpperCase() === '.PDF'){
        this.urlPdf = this.getUrlImg(this.data.image);
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlPdf); 
      }
    }
  }

  getUrlImg(url) {
    const image = this.s3Bucket+url
    return image;
  }

}
