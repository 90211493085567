import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-carnets',
  templateUrl: './print-carnets.component.html',
  styleUrls: ['./print-carnets.component.scss']
})
export class PrintCarnetsComponent implements OnInit {

  position: string = 'horizontal'

  constructor(
    
  ) { }

  ngOnInit(): void {
    window.print();
  }

  

}
