<div class="btns-actions" >
  <div class="btn-close">
      <button mat-fab color="primary" matTooltip="Cerrar" type="button" (click)="close()">
        <mat-icon>highlight_off</mat-icon>
      </button>
  </div>
</div>
<div class="list">
    <div class="carousel" #carouselFlickity>
        <div class="carousel-cell" *ngFor="let img of imgs">
            <img class="carousel-cell-image"
              [src]="getImg(img.url)" alt="tulip" (error)="commonMetod._getUrlErrorImg('noImage', $event)" />
        </div>
        <!-- <div class="carousel-cell">
            <img class="carousel-cell-image"
              src="https://s3.amazonaws.com/analityco-acceso/upload/files/companyInfo/f3dfc3f6-7da3-423b-bb55-5631f6517d31/SupplyReport/Photos/file_26d8ed6f-a5f5-4124-9053-cbea4e47eea1.jpg" alt="tulip" (error)="commonMetod._getUrlErrorImg('noImage', $event)" />
        </div>
        <div class="carousel-cell">
          <img class="carousel-cell-image"
            src="https://s3.amazonaws.com/analityco-acceso/upload/files/companyInfo/f3dfc3f6-7da3-423b-bb55-5631f6517d31/SupplyReport/Photos/file_26d8ed6f-a5f5-4124-9053-cbea4e47eea1.jpg" alt="tulip" (error)="commonMetod._getUrlErrorImg('noImage', $event)" />
        </div> -->
    </div>
</div>