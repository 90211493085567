<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'IMAGENES DE FORMULARIOS' || 'N/A'}}</div>
    </div>
    
    <div class="content-card list-card" autocomplete="off">
        <button class="uploadPhoto" [ngClass]="formDataPhoto === undefined ? 'borderBtn': ''" (click)="updatePhotoPersonal()">
            <mat-icon>file_upload</mat-icon> 
            <div>SUBIR IMAGEN</div>
        </button>
        <img style="margin-bottom: 10px; margin-top: 10px;" *ngIf="imgCropperResult" [src]="imgCropperResult" alt="" width="150" height="150">
        <form [formGroup]="form">
            <!-- <mat-form-field class="input-field" appearance="outline">
                <label><strong>ETIQUETAS</strong></label>
                <input matInput type="text" formControlName="Tags">
                <mat-error>Requerido</mat-error>
            </mat-form-field> -->
            <mat-form-field class="input-field">
                <mat-label>ETIQUETAS</mat-label>
                <mat-chip-list #chipList aria-label="Etiquetas">
                  <mat-chip
                    *ngFor="let tag of tags"
                    (removed)="remove(tag)">
                    {{tag}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    placeholder="Nueva etiqueta"
                    #fruitInput
                    [formControlName]="'Tags'"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <mat-option *ngFor="let tag of filteredtags | async" [value]="tag">
                    {{tag}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
        </form>
    </div>

    <div class="btn-actions"style="margin: 3px;">
        <button mat-button class="btn-cancelar" (click)="close()">{{'CANCELAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" (click)="save()">{{data.Id !== undefined ? 'ACTUALIZAR': 'GUARDAR'}}</button>
    </div>
</div>
