<div class="box">
  <div class="header">
      <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'CONFIGURACION DE HORARIO DE TRABAJO POR PROYECTO' || 'N/A'}}</div>
  </div>

  <div class="content-card list-card" autocomplete="off">
      <form [formGroup]="form" >
        <div class="horizontal-layout">
          <label style="width: 50%;" for="turno">Horario de trabajo:</label>
          <input style="width: 100%;"  type="text" id="turno" formControlName="Name">
          <div class="tWork">
            <label for="estado">Estado (activo/inactivo):</label>
            <mat-slide-toggle id="estado" formControlName="isActive"></mat-slide-toggle>
          </div>
        </div>

          <table >
              <thead>
                  <tr>
                      <td style="text-align: center; border: hidden; font-weight: 600;">Día inicio</td>
                      <td style="text-align: center; border: hidden; font-weight: 600;">Hora inicio</td>
                      <td style="text-align: center; border: hidden; font-weight: 600;">Día final</td>
                      <td style="text-align: center; border: hidden; font-weight: 600;">Hora final</td>
                      <td style="text-align: center; border: hidden; font-weight: 600;">Total horas</td>
                      <td style="text-align: center; border: hidden; font-weight: 600;">Tiempo de descanso</td>
                      <td style="text-align: center; border: hidden; font-weight: 600;">Trd</td>
                      <td style="text-align: center; border: hidden; font-weight: 600;">Horas de trabajo</td>
                  </tr>
              </thead>
              <tbody >
                  <tr  *ngFor="let item of horario">
                      <td style="text-align: center;">
                          <div class="Name" style="width:74px;">{{item.Name}}</div>
                          <!-- <mat-icon >close</mat-icon> -->
                      </td>
                      <td style="text-align: center;" class="day-delete">
                          <mat-form-field class="input-field" appearance="outline">
                              <input matInput type="time" formControlName="{{item.start}}">
                              <mat-icon matSuffix (click)="deleteValue(item.start)">close</mat-icon>
                          </mat-form-field>
                          <!-- {{item.start}} -->
                      </td>
                      <td style="text-align: center;" >
                        <div class="select-day">
                        <select style="width:74px;" formControlName="{{item.NameEnd}}" disabled>
                          <option  *ngFor="let dia of diasSemana" [value]="dia">{{dia}}</option>
                        </select>
                        </div>
                      </td>
                      <td style="text-align: center;" class="day-delete">
                          <mat-form-field class="input-field" appearance="outline">
                              <input matInput type="time" formControlName="{{item.finisht}}" >
                              <mat-icon matSuffix (click)="deleteValue(item.finisht)">close</mat-icon>
                          </mat-form-field>
                          <!-- {{item.finisht}} -->
                      </td>
                      <td style="text-align: center;">
                        <div>{{getTotalTime(item.Name, item.NameEnd,item.start, item.finisht)}}</div>
                    </td>
                    <td style="text-align: center;">
                      <div class="selected-horas">
                        <select formControlName="{{item.extraTimeBreakL}}" style="appearance:none;">
                          <option [value]="t" *ngFor="let t of time">{{t}}</option>
                        </select>:
                        <select formControlName="{{item.extraTimeBreakR}}" style="appearance:none;">
                          <option [value]="t" *ngFor="let t of time">{{t}}</option>
                        </select>
                      </div>
                    </td>
                    <td style="text-align: center;">
                      <div class="selected-horas">
                        <select formControlName="{{item.extraTimeTrdL}}" style="appearance:none;">
                          <option [value]="t" *ngFor="let t of time">{{t}}</option>
                        </select>:
                        <select formControlName="{{item.extraTimeTrdR}}" style="appearance:none;">
                          <option [value]="t" *ngFor="let t of time">{{t}}</option>
                        </select>
                      </div>
                    </td>
                  <td style="text-align: center;">
                    <div>{{getHoursWorkTime(item.Name, item.NameEnd, item.start, item.finisht, item.extraTimeBreakL, item.extraTimeBreakR, item.extraTimeTrdR, item.extraTimeTrdL)}}</div>
                </td>
                  </tr>
              </tbody>
          </table>
      </form>
      <div class="total-content">
        <div class="tWork">Total horas de trabajo por semana &nbsp;&nbsp; {{getTotalHoursWork()}}</div>
        <div class="tTrd"><span class="trd">Trd =</span> Tiempo trabajado a partir del cual se descuenta el tiempo de descanso</div>
    </div>
  </div>
  <div class="btn-actions" style="margin: 3px;">
      <button mat-button class="btn-cancelar" (click)="close()">{{'CANCELAR'}}</button>&nbsp;&nbsp;
      <button mat-button class="btn-generar" (click)="save()">{{'GUARDAR'}}</button>
  </div>
</div>
