import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UpdatePassword, UserGetEmail } from '../../interfaces/interfaces';
import { UserInfo } from '../../models/User';

@Injectable({
  providedIn: 'root'
})
export class UserService {

    constructor(
        private api: ApiService,
        private _http: HttpClient
    ) { }

    //GET user Correo
    getUserByEmail(Email: string): Observable<UserGetEmail>{
        return this._http.get<UserGetEmail>(this.api.getUrl(`api/User/ByEmail/${Email}`));
    }

    getUserByEmailAdmin(Email: string, admin?): Observable<UserGetEmail>{
        return this._http.get<UserGetEmail>(this.api.getUrl(`api/User/ByEmail/${Email}${admin === true ? '?admin=true': ''}`));
    }

    getProjectsUser(userId: string){
      return this._http.get(this.api.getUrl(`api/User/Projects?userId=${userId}`));
    }

    getContractsUser(userId: string){
      return this._http.get(this.api.getUrl(`api/User/Contracts?userId=${userId}`));
    }

    getAdminCompanies(userId: string){
      return this._http.get(this.api.getUrl(`api/User/AdminCompanies/${userId}`));
    }

    postUpdatePasswordUser(model){
      return this._http.post(this.api.getUrl(`api/User/UpdatePassword`),model);
    }

    //PUT USER ID
    putUserId(UserId: string, model) {
      return this._http.put(this.api.getUrl('api/User/'+UserId), model);
    }

    //PUT USER ID
    putUserWithoutPersonal(UserId: string, model) {
      return this._http.put(this.api.getUrl('api/User/WithoutPersonal/'+UserId), model);
    }

     //GET USER ID
    getUserId(UserId: string) {
      return this._http.get(this.api.getUrl('api/User/'+UserId));
    }

    /**
     * Permite obtener los usuarios
     */
    getProfiles() {
      return this._http.get<any>(this.api.getUrl(`api/User`));
    }

    postSendRecoveryEmail(email) {
      return this._http.post(this.api.getUrl(`api/User/SendRecoveryEmail?email=`+email),email);
    }

    postResetPassword(model) {
      return this._http.post(this.api.getUrl(`api/User/ResetPassword`),model);
    }

    putUserUpdatePhoto(id, formData) {
      return this._http.put(this.api.getUrl(`api/User/${id}/UpdatePhoto`),formData);
    }

    putUserUpdatePhotoProfile(formData) {
      return this._http.put(this.api.getUrl(`api/User/UpdatePhotoProfile`),formData);
    }

    //GET USER ID
    getUserContractors(UserId: string) {
      return this._http.get(this.api.getUrl(`api/User/Contractors/${UserId}`));
    }

    // Actualizar contraseña vencida la contraseña
    postUpdatePasswordAnonymous(model) {
      return this._http.post(this.api.getUrl(`api/User/UpdatePasswordAnonymous`),model);
    }

}
