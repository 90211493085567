import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonOptions, ModelDocumentsShow } from '../../../interfaces/interfaces';
import { DialogService } from '../../../services/utils/dialog.service';
import { EmployerService } from '../../../services/modules/employer.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { CropperComponent } from 'angular-cropperjs';
import { AuthService } from 'src/app/core/services/modules/auth.service';
import { User } from 'src/app/core/models/User';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';

export interface DialogData {
  title: string;
  docs: ModelDocumentsShow[];
  typeOperator: string;
  idPlanilla: number;
  typeGestor: string;
  cropper?: boolean;
  desc: string;
  proveedorId: boolean;
}

@Component({
  selector: 'app-dialog-gestor-planillas',
  templateUrl: './dialog-gestor-planillas.component.html',
  styleUrls: ['./dialog-gestor-planillas.component.scss']
})
export class DialogGestorPlanillasComponent implements OnInit {

  @ViewChild('angularCropper') angularCropper: CropperComponent;

  s3Bucket:string;
  DocsJSON: any = {};
  documentos: any[] = [];

  cropper: boolean = false;
  // FIles
  formFile: FormGroup;
  _file: File = null;
  files: File[] = [];
  email: string = '';
  cropperOptions: any = {};

  userAuth: User;

  proveedor :CommonOptions[] = [];
  controProveedor = new FormControl();

  constructor(
    private dialogRef: MatDialogRef<DialogGestorPlanillasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private employerService: EmployerService,
    private fb: FormBuilder,
    private authService: AuthService,
    private commonService: CommonService,
    private snackBService: SnackBarService
  ) {
    this.userAuth = this.authService.getUser();
    this.email = this.userAuth?.Email !== null && this.userAuth?.Email !== undefined ? this.userAuth?.Email: '';
    this.formFile = this.fb.group({
      File: ''
    });
    if(this.data.cropper) {
      this.cropper = this.data.cropper;
    }
    if(this.data.typeOperator === 'uploadProveedor'){ 
      this.commonService.getCommonOptions("PlanillaProvider").subscribe((data: CommonOptions[]) => {
        this.proveedor = data;
      });
    }
    if(this.data.proveedorId) {
      this.controProveedor.setValue(this.data.proveedorId);
    }
   }

  ngOnInit(): void {
    this.s3Bucket= environment.api.s3Bucket;
    this.data.docs.forEach(doc => {
      // if(this.getName(doc).includes('commentary_fileAnalityco.txt')){
      //   let archivo = new XMLHttpRequest();
      //   archivo.open('GET', this.s3Bucket+this.getUrl(doc), false);
      //   archivo.send(null);
      //   // console.log('getSplitDoc',this.getSplitDoc(doc));
      //   let data = this.getSplitDoc(doc);
      //   for (let index = 0; index < data.length; index++) {
      //       if(index === 0) {
      //         doc.urlDoc = data[index];
      //       }
      //       if(index === 1) {
      //         doc.nameFile = archivo.responseText;
      //       }
      //       if(index === 2) {
      //         doc.user = data[index];
      //       } 
      //       if(index === 3) {
      //         doc.date = data[index];
      //       }          
      //   }
      //   doc.isCommentary = true;
      // } else {
      //   let data = this.getSplitDoc(doc);
      //   for (let index = 0; index < data.length; index++) {
      //       if(index === 0) {
      //         doc.urlDoc = data[index];
      //       }
      //       if(index === 1) {
      //         doc.nameFile = data[index];
      //       }
      //       if(index === 2) {
      //         doc.user = data[index];
      //       } 
      //       if(index === 3) {
      //         doc.date = data[index];
      //       }          
      //   }
      //   doc.isCommentary = false;
      // }
      // this.documentos.push(doc);  
    });
    if(this.data.typeOperator === 'uploadProveedor'){
      this.documentos = this.data.docs;  
    }
    this.documentos.reverse();
  }

  openImgVideo(doc: ModelDocumentsShow) {
    window.open(this.s3Bucket + this.getUrl(doc), '_blank');
    // Se comenta el componente que muestra en modal los videos he imagenes para poderlos abrir en otra ventana 15/09/2022
    // this.dialogService.openDialogImageOrVideoOrPdf(this.getUrl(doc)).afterClosed().subscribe(x => {});
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getSplitDoc(doc: ModelDocumentsShow) {
    let name: any = '';
    if(doc) {
      let a = doc.urlDoc.split('||');
      for (let index = 0; index < a.length; index++) {
        if(index === 1)  {
          a[index] = a[index].replace('name=','');
        }
        if(index === 2) {
          a[index] = a[index].replace('user=','');
        }
        if(index === 3) {
          a[index] = a[index].replace('date=','');
        }
        if(index === a.length-1) {
          name = a;
        }
      }  
    }
    return name;
  }

  getName(doc: ModelDocumentsShow) {
    let name = '';
    if(doc) {
      let a = doc.urlDoc.split('||name=');
      name = a[1];
    }
    return name;
  }

  getUrl(doc: ModelDocumentsShow): string {
    let url = ''
    let a = doc?.urlDoc?.split('||name=');
    if(a !== undefined) {
      url = a[0];
    }
    return url;
  }

  getUser(doc: ModelDocumentsShow): string {
    let url = ''
    let a = doc?.urlDoc?.split('||user=');
    if(a !== undefined) {
      url = a[1];
    }
    return url;
  }

  jsonUpdateDeleteDoc(doc: ModelDocumentsShow, index) {
    // console.log(doc, index);
    let DocsJSON = `{"${doc.name}": "${doc.urlDoc}"}`
    let a = JSON.parse(DocsJSON);
    Object.assign(this.DocsJSON, a);
    delete this.documentos[index];
  }

  jsonDocExitValue(): boolean {
    return Object.entries(this.DocsJSON).length === 0;
  }

  upDateDocuments: any;
  upDateDocs(DocsJSON) {
    this.createFile();
    if(this.data.typeOperator === 'showDelete' || this.data.typeOperator === 'showUpdate') {
      this.dialogRef.close({DocsJSON, files: this.files});
    } else if(!this.jsonDocExitValue() && this.data.typeOperator === 'showUpdatePlanilla') {
      this.upDateDocuments = {Id: this.data.idPlanilla,  DocsJSON};
      this.employerService.updateDocsJsonPlanilla(this.data.idPlanilla, this.upDateDocuments).subscribe((rest: any) => {
        if(this.files.length > 0) {
          this.saveFiles();
        }
        this.dialogRef.close(rest.DocsJSON);
      });
    } else if(this.files.length > 0) {
      this.saveFiles();
    }
    
  }

  changeFile(event: any) {
    let selectedFiles: FileList = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.files.push(selectedFiles[i]);
    }
  }

  saveFiles() {
    for (let i = 0; i < this.files.length; i++) {
        let formData = new FormData();
        formData.append('File', this.files[i]);
        this.employerService.updateFilesocialSecurity(this.data.idPlanilla, formData).subscribe((rest: any) => {
          // console.log(`${i} - ${this.files.length}`);
          if(i === this.files.length-1) {
            this.dialogRef.close(rest.DocsJSON);
          }
        });
    }
  }
  // METODOS PARA CORTAR UNA IMAGEN Y GUARDARLA
  imgCropper: string;
  imgCropperResult: string;
  formData = new FormData();
	onSelect(event) {
    if(event.addedFiles[0].type === 'application/pdf') {
      if(this.cropper === false) {
        let selectedFiles: FileList = event.addedFiles;
        for (let i = 0; i < selectedFiles.length; i++) {
          this.files.push(selectedFiles[i]);
        }
      } else if(this.cropper === true && (this.data.typeOperator === 'showDelete' || this.data.typeOperator === 'showUpdate')) {
        
        let selectedFiles: FileList = event.addedFiles;
        for (let i = 0; i < selectedFiles.length; i++) {
          this.files.push(selectedFiles[i]);
        }
      } else {
        let selectedFiles: FileList = event.addedFiles;
        const reader = new FileReader();
        reader.readAsDataURL(selectedFiles[0]);
        reader.onload = () => {
          this.imgCropper = reader.result as string;
          this.imgCropperResult = reader.result as string;
          // console.log('this.imgCropperResult',this.imgCropperResult);
        }
      }
    } else {
      this.snackBService.openSnackBar('SOLO SE PERMITEN ARCHIVOS PDF', 'X', 4000);
    }
	}

  updatePhoto() {
    this.cropper = false;
    if(this.imgCropper !== undefined){
      this.imgCropperResult = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
      this.angularCropper.cropper.getCroppedCanvas().toBlob((blob) => {
        this.cropper = false;
        let formData = new FormData();
        formData.append('File', blob);
        this.dialogRef.close({FormData: formData, imgResult: this.imgCropperResult});
      }, 'iamge/jpeg', 0.95);
    } else {
      if(this.files.length === 1){
        if(this.data.typeOperator === 'uploadProveedor') {
          this.dialogRef.close({files: this.files, ProviderId: this.controProveedor.value});
        } else {
          let formData = new FormData();
          formData.append('File', this.files[0]);
          this.dialogRef.close(formData);
        }
      } else if(!this.jsonDocExitValue()) {
        this.dialogRef.close('delete');
      }
    }
  }

  isEqualsUserRemoveFile(doc): boolean {
    let equal = false;
    this.userAuth.username === doc?.user ? equal = true: equal = false;
    return equal;
  }

  // imageCropped(event: ImageCroppedEvent) {
  //   this.croppedImage = event.base64;
  // }
  // METODOS PARA CORTAR UNA IMAGEN Y GUARDARLA FIN

  removeFile(index) {
    // delete this.files[index];
    this.files.splice(index, 1);
  }

  isXLSX(doc: any): boolean {
    return this.getUrl(doc).slice(-5).toUpperCase() === '.XLSX';
  }

  getUrlFile(url) {
    const urlFile = this.s3Bucket+this.getUrl(url)
    return urlFile;
  }

  commentary: string = '';
  createFile() {
    if(this.commentary !== '') {
      const file = new File([this.commentary], 'commentary_fileAnalityco.txt',{ type: 'text/plain' });
      this.files.push(file);
    }
  }

  changeNameOrCommentary(file: File): any {
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function(e) { 
      return reader.result;
    };
  }

	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
	}

  selectedProveedor(e) {
    let data = this.arrayURLSProveedor(e);
    this.dialogService.openDialogDialogConfirmImg('REQUERIMIENTOS DEL DOCUMENTO A CARGAR',
                                                  [ '1. Solo cargar documentos en PDF',
                                                    '2. Solo cargar documentos generado directamente con la plataforma del operador PILA, No se aceptan fotocopias.',
                                                    '3. Solo cargar documentos en fomato indicado por la imagen.'
                                                  ],
                                                  data.urlComplet,
                                                  data.urlAssets
      ).afterClosed().subscribe(confirm => {
          if(confirm === false) {
            this.controProveedor.setValue('');
          }
    }); 
  }

  arrayURLSProveedor(Id): URLproveedor {
    let URLproveedor: URLproveedor;
    let dataProveedor = this.proveedor.find(x => x.Id === Id);
    let dataProveedorURLS = this.proveedorURLS.find(x => x.Value === dataProveedor.Value);
    dataProveedorURLS !== undefined ? URLproveedor = dataProveedorURLS: URLproveedor = {Value:'',urlAssets:'',urlComplet:''}
    return URLproveedor;
  }

  proveedorURLS = [
    {Value:'ARU',urlAssets:'assets/images/ImagenFormatoArus.png',urlComplet:'https://analityco-acceso.s3.amazonaws.com/assets/images/ImagenFormatoArus.png'},
    {Value:'APT',urlAssets:'assets/images/ImagenFormatoAportesEnLinea.png',urlComplet:'https://analityco-acceso.s3.amazonaws.com/assets/images/ImagenFormatoAportesEnLinea.png'},
    {Value:'MIP',urlAssets:'assets/images/ImagenFormatoMiPlanilla.png',urlComplet:'https://analityco-acceso.s3.amazonaws.com/assets/images/ImagenFormatoMiPlanilla.png'},
    {Value:'ASO',urlAssets:'assets/images/ImagenFormatoAsopagos.png',urlComplet:'https://analityco-acceso.s3.amazonaws.com/assets/images/ImagenFormatoAsopagos.png'},
    {Value:'SIM',urlAssets:'assets/images/pagosimple.png',urlComplet:'https://analityco-acceso.s3.amazonaws.com/assets/images/pagosimple.png'}
  ];

}

export interface URLproveedor {
  Value: string;
  urlComplet: string;
  urlAssets: string;
}
