import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContratingService } from '../../../services/modules/contrating.service';
import { environment } from 'src/environments/environment';
import { CommonOptions } from '../../../interfaces/interfaces';
import { CommonService } from '../../../services/modules/common.service';
import { SnackBarService } from '../../../services/utils/snackBar.service';
import { DialogService } from '../../../services/utils/dialog.service';
import { Router } from '@angular/router';
import { IndividualContract } from '../../../models/IndividualContract/individualContract';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FechasService } from 'src/app/core/services/utils/fechas.service';
import { CommonMetodService } from 'src/app/core/services/utils/commonMetod.service';
import { PersonalService } from 'src/app/core/services/modules/personal.service';
import { ContractService } from 'src/app/core/services/modules/contract.service';
import { RequestService } from 'src/app/core/services/modules/request.service';
import { EmployerService } from 'src/app/core/services/modules/employer.service';

export interface DialogData {
  idEmployer: any;
}


@Component({
  selector: 'app-dialog-create-prenomina',
  templateUrl: './dialog-create-prenomina.component.html',
  styleUrls: ['./dialog-create-prenomina.component.scss']
})
export class DialogCreatePrenominaComponent implements OnInit {
  s3Bucket:string;

  formPersonal: FormGroup;

  projectLaboral: any[] = [];
  periodos: any[] = [];
  periodosAll: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogCreatePrenominaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private contratingService: ContratingService,
    private fb: FormBuilder,
    private employerService: EmployerService,
    public serviceFecha: FechasService,
  ) {
    this.formPersonal = this.fb.group({
      ProjectId: new FormControl('',Validators.required),
      PeriodoId: new FormControl('',Validators.required),
    });

    this.employerService.getPayrollTimePlaceOfWorkEmployerInfoId(this.data.idEmployer).subscribe((rest:any) => {
      this.periodosAll = rest;
    });
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    this.contratingService.getProyectSelect().subscribe((data: any[]) => {
      this.projectLaboral = data.filter(x => x.IsActive === true);
      if(this.projectLaboral.length === 1) {
        this.formPersonal.get('ProjectId').setValue(this.projectLaboral[0]?.Id);
        this.formPersonal.get('PeriodoId').setValue(this.projectLaboral[0]?.Id);
      }
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  generate() {
    this.dialogRef.close(
      { ProjectId: this.formPersonal.get('ProjectId').value,
        PeriodoId: this.formPersonal.get('PeriodoId').value
      }
    )
  }

  getPeriodFilterProject(ProjectId) {
    let newPeriodos = [];
    this.periodosAll.forEach(project => {
        if(project.PlaceOfWorkId === ProjectId) {
          project.PayrollTimes.forEach(period => {
            let data = {
              ...period,
              Name: 'Periodo: '+this.serviceFecha.getDateFormatRWith(period.StartDate+'T00:00:00')+' a ' +this.serviceFecha.getDateFormatRWith(period.EndDate+'T00:00:00')
            }
            newPeriodos.push(data);    
          });
        }
        
      });
      this.periodos = newPeriodos.sort((a, b) => a.StartDate < b.StartDate ? 1 : -1);
  }
  

}
