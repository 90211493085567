import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FechasService} from '../../../services/utils/fechas.service';
import {ProjectService} from '../../../services/modules/project.service';
import {DialogService} from '../../../services/utils/dialog.service';
import {ContractService} from '../../../services/modules/contract.service';
import {EmployerService} from '../../../services/modules/employer.service';
import {CommonService} from '../../../services/modules/common.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
  projectId: string;
}

@Component({
  selector: 'app-dialog-project-add',
  templateUrl: './dialog-project-add.component.html',
  styleUrls: ['./dialog-project-add.component.scss']
})
export class DialogProjectAddComponent implements OnInit {

  GeoFenceJson;
  GeoFenceJsonEdit;
  formEtapa: FormGroup;
  formProject: FormGroup;
  Address = '';
  CityName = '';
  projectId = '';
  CompanyInfoId: '';

  project: any;
  flagEditEtapas: boolean;
  changeAfterfillFormProject: boolean;
  permissions: string[] = [];
  roles: any[] = [];
  contracts: any[] = [];
  employers: any[] = [];
  rolesSelected: any[] = [];
  etapaSelected: any[] = [];
  projectStageArray: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogProjectAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public fechasService: FechasService,
    private projectService: ProjectService,
    private dialogService: DialogService,
    private contractService: ContractService,
    private employersService: EmployerService,
    private commonService: CommonService,
  ) {
  }

  ngOnInit(): void {
    this.formEtapa = this.fb.group({
      Name: new FormControl('', Validators.required),
      Review: new FormControl('', Validators.required),
      StartDate: new FormControl(new Date(), Validators.required),
      FinishDate: new FormControl(new Date(), Validators.required)
    });
    this.projectId = this.data.projectId;
    this.getProjectId(this.projectId);
  }
  getProjectId(Id) {
    this.projectService.getProjectId(Id).subscribe(data => {
      this.GeoFenceJson = JSON.parse(data?.GeoFenceJson);
      this.GeoFenceJsonEdit = JSON.parse(data?.GeoFenceJson);
      this.Address = data?.Address;
      this.CityName = data?.CityName;
      this.project = data;


      this.projectStageArray = data.ProjectStageArray;
      this.CompanyInfoId = data.CompanyInfoId;
      if (this.project?.ContractIds?.length > 0) {
        this.getContracts(this.project?.ContractIds);
      }
      if (this.project?.EmployerIds?.length > 0) {
        this.getEmployers(this.project?.EmployerIds);
      }
      this.getRolesProject();

      setTimeout(() => {
        this.changeAfterfillFormProject = true;
      }, 1000);
    });
  }

  getContracts(ContractIds) {
    let ids = '';
    ContractIds.forEach((item, index) => {
      ids += index !== ContractIds.length - 1 ? item + ',' : item + '';
      if (index === ContractIds.length - 1) {
        this.contractService.getContractIds(ids).subscribe((rest: any[]) => {
          this.contracts = rest.sort((a, b) => a.FinishDate < b.FinishDate ? 1 : -1);
        });
      }
    });
  }

  getEmployers(EmployerIds) {
    let ids = '';
    EmployerIds.forEach((item, index) => {
      ids += index !== EmployerIds.length - 1 ? item + ',' : item + '';
      if (index === EmployerIds.length - 1) {
        this.employersService.getEmployerIds(ids).subscribe((rest: any[]) => {
          this.employers = rest.sort((a, b) => a.FinishDate < b.FinishDate ? 1 : -1);
        });
      }
    });
  }

  getRolesProject() {
    this.projectService.getRolesProject(this.projectId).subscribe((rolesCompany: any) => {
      rolesCompany.forEach(rol => {
        rol.Name = rol.Name.split(']')[1] !== undefined ? rol.Name.split(']')[1] : rol.Name;
      });
      this.roles = rolesCompany;
      this.getRolesSelected(this.CompanyInfoId);
    });
  }

  getRolesSelected(CompanyId) {
    this.commonService.getRolesCompanyProject(CompanyId, 'Project').subscribe((rest: any[]) => {
      this.rolesSelected = rest.filter(x => this.exitRolesSave(x.Id) === false && x.IsEnabled === true);
    });
  }

  getProjectIdAlone() {
    this.projectService.getProjectId(this.projectId).subscribe(data => {
      this.project = data;
      this.projectStageArray = data.ProjectStageArray;
    });
  }

  onSubmitEtapa() {
    if (this.etapaSelected.length === 0) {
      const modelPost = {
        Name: this.formEtapa.get('Name').value,
        Review: this.formEtapa.get('Review').value,
        ProjectId: this.projectId,
        StartDate: this.fechasService.getDateFormatDatePiker(this.formEtapa.get('StartDate').value),
        FinishDate: this.formEtapa.get('FinishDate').value,
        IsActive: true
      };
      this.projectService.postProjectStage(modelPost).subscribe(rest => {
        this.getProjectIdAlone();
        this.flagEditEtapas = false;
        this.dialogRef.close();
      });
    } else {
      const modelPut = {
        ...this.etapaSelected[0],
        Name: this.formEtapa.get('Name').value,
        Review: this.formEtapa.get('Review').value,
        StartDate: this.formEtapa.get('StartDate').value,
        FinishDate: this.formEtapa.get('FinishDate').value,
      };
      this.projectService.putProjectStage(this.etapaSelected[0].Id, modelPut).subscribe(rest => {
        this.getProjectIdAlone();
        this.flagEditEtapas = false;
        this.dialogRef.close();
      });
    }
  }

  exitRolesSave(id): boolean {
    let exit = false;
    const data = this.roles.find(x => x.Id === id);
    data !== undefined ? exit = true : exit = false;
    return exit;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
