import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CommonOptions} from 'src/app/core/interfaces/interfaces';
import {CommonService} from 'src/app/core/services/modules/common.service';
import {DialogService} from 'src/app/core/services/utils/dialog.service';
import {environment} from 'src/environments/environment';
import {VehicleService} from '../../../services/modules/vehicle.service';
import {FechasService} from 'src/app/core/services/utils/fechas.service';
import {HttpErrorResponse} from '@angular/common/http';
import {SnackBarService} from 'src/app/core/services/utils/snackBar.service';
import {Router} from '@angular/router';
import {CommonMetodService} from 'src/app/core/services/utils/commonMetod.service';

export interface DialogData {
  CompanyInfoId: string;
}

@Component({
  selector: 'app-dialog-create-vehicle',
  templateUrl: './dialog-create-vehicle.component.html',
  styleUrls: ['./dialog-create-vehicle.component.scss']
})
export class DialogCreateVehicleComponent implements OnInit {
  s3Bucket: string;
  documentType: string;
  imgCropperResult: string;

  form: FormGroup;
  formDataPhoto: FormData;
  next = false;
  documentNumber = '';
  flagCreateVehicle = false;
  flagExitContractContracts = true;

  proyects: any[] = [];
  vehicleContracts: any[] = [];
  proyectSelected: any [] = [];
  personalSelected: any [] = [];
  contractsSelected: any [] = [];
  vehicleContractsFilter: any[] = [];
  selectedVehicleContracts: any[] = [];
  contries: CommonOptions[] = [];
  vehicleType: CommonOptions[] = [];

  date = new Date();
  controlEmployer = new FormControl();
  controlContractSelected = new FormControl();
  minDateVigenciaContract = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());

  constructor(public dialogRef: MatDialogRef<DialogCreateVehicleComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private commonService: CommonService,
              private dialogService: DialogService,
              private vehicleService: VehicleService,
              private fechasService: FechasService,
              private snackBService: SnackBarService,
              private router: Router,
              private fb: FormBuilder,
              public commonMetodService: CommonMetodService) {
      this.form = this.fb.group({
        Photo: new FormControl(''),
        LicenseLsearch: new FormControl('', Validators.required),
        LicenseNsearch: new FormControl('', Validators.required),
        LicenseL: new FormControl('', Validators.required),
        LicenseN: new FormControl('', Validators.required),
        Review: new FormControl(''),
        CountryId: new FormControl('', Validators.required),
        VehicleTypeId: new FormControl('', Validators.required),
        // DAtOS DEL CONTRACTO
        FinishDate: new FormControl('', Validators.required),
        StartDate: new FormControl(this.fechasService.getDateFormatDatePikerDATEHORANOW(), Validators.required),
        Function: new FormControl('', Validators.required),
        ContractId: new FormControl('', Validators.required),
      });
    // tslint:disable-next-line:no-shadowed-variable
      this.commonService.getCommonOptions('Country').subscribe((data: CommonOptions[]) => {
        this.contries = data.sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
      });
    // tslint:disable-next-line:no-shadowed-variable
      this.commonService.getCommonOptions('VehicleType').subscribe((data: CommonOptions[]) => {
        this.vehicleType = data.filter(x => x.Value !== 'BICICLETA').sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
      });
      this.commonService.getContract().subscribe((contracts: any[]) => {
        this.vehicleContracts = contracts.filter(x => x.IsActive === true);
      });
    }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    setTimeout(() => {
      this.commonService.getProyect().subscribe((proyects: any[]) => {
        this.proyects = proyects.filter(proy => proy.CompanyInfoId === this.data.CompanyInfoId && proy.IsActive === true);
        if (this.proyects.length === 1) {
          this.proyectSelected.push(this.proyects[0]);
          this.selectedProyect(this.proyects[0].Id);
        }
      });
    }, 300);
  }

  setValue() {
    if (this.controlEmployer.value !== undefined) {
      this.controlEmployer.setValue('');
      this.controlContractSelected.setValue('');
      this.proyectSelected = [];
      this.personalSelected = [];
      this.contractsSelected = [];
      this.documentNumber = '';
      this.documentType = 'CC';
      this.flagExitContractContracts = true;
    }
  }

  selectedProyect(Id: any) {
    this.proyectSelected = [];
    const data = this.proyects.find(x => x.Id === Id);
    this.proyectSelected.push(data);
    this.vehicleContractsFilter = this.vehicleContracts.filter(x => x?.ProjectIds[0] === Id);
  }

  getVehicleDocument() {
    if (this.selectedVehicleContracts.length === 0) {
      this.snackBService.openSnackBar('Seleccionar contrato antes de continuar.', 'X', 4000);
    } else {
      if (this.form.get('LicenseLsearch').value.length !== 3 || this.form.get('LicenseNsearch').value.length !== 3) {
        this.snackBService.openSnackBar('Verifique la información ingresada.', 'X', 4000);
      } else {
        this.vehicleService.getVehicleDocNumContractId(this.form.get('LicenseLsearch').value + this.form.get('LicenseNsearch').value,
          this.selectedVehicleContracts[0].Id).subscribe((data: any) => {
          const vehicleCompany = {
            FinishDate: this.form.get('FinishDate').value,
            StartDate: this.form.get('StartDate').value,
            Function: this.form.get('Function').value,
            ContractId: this.form.get('ContractId').value,
            VehicleCompanyInfoId: data?.VehicleInfoId
          };
          if (data !== null) {
            this.vehicleService.getIsContractVehicleCompanyInfoId(this.selectedVehicleContracts[0].Id,
              data.VehicleInfoId).subscribe(isContract => {
              this.vehicleService.postVehicleContract(vehicleCompany).subscribe((restContractPersonal: any) => {
                this.router.navigate(['/admin/vehicle/vehicle/', data?.VehicleInfoId]);
                this.dialogRef.close(true);
              });
            }, (error: HttpErrorResponse) => {
              if (error.status === 400){
                this.dialogService.openDialogMovePersonalInfo(true, error, 'Desea trasladarlo?',
                  false, 'TRANSLADAR VEHICULO').afterClosed().subscribe(restDialog => {
                  if (restDialog === true) {
                    this.vehicleService.postMoveVehicleInfoContract(
                      this.selectedVehicleContracts[0].Id, vehicleCompany).subscribe((restMOvePersonal: any) => {
                      this.router.navigate(['/admin/vehicle/vehicle/', data.VehicleInfoId]);
                      this.dialogRef.close(true);
                    });
                  }
                });
              }
          });
          }
        }, (error: HttpErrorResponse) => {
          error.status === 400 || error.status === 404 || error.status === 409 ?
            this.dialogService.openDialogConfirm(
              `Lo sentimos, no hay un vehiculo registrado con este número. Desea crearlo?`).afterClosed().subscribe(rest => {
              if (rest !== false) {
                this.flagCreateVehicle = true;
                this.form.get('LicenseL').setValue(this.form.get('LicenseLsearch').value.toUpperCase());
                this.form.get('LicenseN').setValue(this.form.get('LicenseNsearch').value.toUpperCase());
                this.form.get('LicenseN').disable();
                this.form.get('LicenseL').disable();
                this.form.get('CountryId').setValue('0');
              }
            })
          : '';
        });
      }
    }
  }

  getImg(logo: string) {
    return (logo === null || logo === '' || logo === undefined ? this.s3Bucket + 'assets/images/gnr-buildings.png' : this.s3Bucket + logo);
  }

  getImgContract(logo: string) {
    return (logo === null || logo === '' || logo === undefined ?
      'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg' : logo);
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    if (this.form.status === 'VALID')  {
      const model = {
        License: this.form.get('LicenseL').value.toUpperCase() + this.form.get('LicenseN').value.toUpperCase(),
        CountryId: this.form.get('CountryId').value,
        Review: this.form.get('Review').value,

        CompanyInfoId: this.data?.CompanyInfoId,
        VehicleTypeId: this.form.get('VehicleTypeId').value,
        Photo: '',
      };
      this.vehicleService.postVehicle(model).subscribe((restCreate: any) => {
        const modelAsociaContractVehicle = {
          FinishDate: this.form.get('FinishDate').value,
          StartDate: this.form.get('StartDate').value,
          Function: this.form.get('Function').value,
          ContractId: this.form.get('ContractId').value,
          VehicleCompanyInfoId: restCreate?.Id
        };
        if (this.formDataPhoto === undefined) {
          this.vehicleService.postVehicleContract(modelAsociaContractVehicle).subscribe(restAsociate => {
            this.dialogRef.close(restCreate.Id);
          });
        } else {
          this.vehicleService.updatePhotoVehicle(restCreate.Id, this.formDataPhoto).subscribe(rest => {
            this.vehicleService.postVehicleContract(modelAsociaContractVehicle).subscribe(restAsociate => {
              this.dialogRef.close(restCreate.Id);
            });
          });
        }

      }, (error: HttpErrorResponse) => {
        error.status === 409 ?
        this.snackBService.openSnackBar('Ya existe un vehiculo con la placa digitada.', 'X', 4000) : '';
      }
      );
    } else {
      this.form.markAllAsTouched();
    }
  }

  updatePhotoVehicle() {
    this.dialogService.openDialogGestorImg('CARGAR IMAGEN', [], 'editPhoto', 0, 'true', true).afterClosed().subscribe(formDataPhoto => {
      if (formDataPhoto !== undefined) {
        this.imgCropperResult = formDataPhoto.imgResult;
        this.formDataPhoto = formDataPhoto.FormData;
      }
    });
  }

  setValueContract() {
    if (this.controlContractSelected.value !== undefined) {
      this.form.get('ContractId').setValue('');
      this.selectedVehicleContracts = [];
    }
  }

  onSelectionChangeContract(e: any) {
    this.selectedVehicleContracts = [];
    this.vehicleContracts.forEach((contract: any) => {
      if (contract.Id === e) {
        this.selectedVehicleContracts.push(contract);
        this.form.get('FinishDate').setValue(contract.FinishDate);
        this.form.get('Function').setValue(

          contract.DescriptionPersonalType !== null
          ?
            contract?.DescriptionPersonalType?.toUpperCase() === 'OTROS' ?
            'FUNCION DESCONOCIDA' : 'VEHICULO ' + contract?.DescriptionPersonalType?.toUpperCase()
          : 'FUNCION DESCONOCIDA');
      }
    });
  }

}
