import {DocsJSONCollection} from '../interfaces/interfaces';

export class DocsJsonUtils {
  /**
   * Convierte un JSON string en un objeto tipado de DocsJSONCollection
   * @param docsJSON Cadena JSON recibida de la API
   * @returns Objeto DocsJSONCollection o null si hay error
   */
  static deserializeDocsJSON(docsJSON: string | null): DocsJSONCollection | null {
    if (!docsJSON) { return null; } // Evita errores si el campo es null o undefined

    try {
      const parsed = JSON.parse(docsJSON);

      return Object.keys(parsed).reduce((acc: DocsJSONCollection, key: string) => {
        const rawValue = parsed[key];

        // Extraer la ruta original antes del primer '||'
        const separatorIndex = rawValue.indexOf('||');
        const originalPath = separatorIndex !== -1 ? rawValue.substring(0, separatorIndex) : rawValue;

        // Obtener solo las propiedades después del primer '||'
        const propertiesString = separatorIndex !== -1 ? rawValue.substring(separatorIndex + 2) : '';
        const properties = propertiesString.split('||').reduce((obj: any, item: string) => {
          const [k, v] = item.split('=');
          obj[k] = v || ''; // Si el valor no existe, lo deja vacío
          return obj;
        }, {} as any);

        acc[key] = {
          path: key,  // Conserva el identificador original
          originalPath, // Guarda la ruta original correctamente
          name: properties.name || '',
          user: properties.user || '',
          date: properties.date || '',
          module: properties.module || '',
          module_id: properties.module_id || '',
          module_state: properties.module_state || '',
          module_comment: properties.module_comment || '',
          module_date: properties.module_date || '',
        };

        return acc;
      }, {} as DocsJSONCollection);
    } catch (error) {
      console.error('Error al deserializar DocsJSON:', error);
      return null;
    }
  }



  /**
   * Convierte un objeto DocsJSONCollection en un JSON string
   * @param docs Objeto DocsJSONCollection
   * @returns JSON string para enviar a la API
   */
  static serializeDocsJSON(docs: DocsJSONCollection): string {
    return JSON.stringify(
      Object.keys(docs).reduce((acc, key) => {
        const doc = docs[key];

        acc[key] = [
          `name=${doc.name}`,
          `user=${doc.user}`,
          `date=${doc.date}`,
          `module=${doc.module}`,
          `module_id=${doc.module_id}`,
          `module_state=${doc.module_state || ''}`,
          `module_comment=${doc.module_comment || ''}`,
          `module_date=${doc.module_date || ''}`,
        ].join('||');

        return acc;
      }, {} as Record<string, string>)
    );
  }
}
