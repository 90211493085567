<div class="search-paginator" [ngClass]="production === false ? 'color-search-paginator-pruebas': 'color-search-paginator-pro'">
    <div class="search">
         <input class="input-search" 
                type="text" 
                placeholder="Buscar..." 
                (keyup)="searchFilterPagination($event.target.value)">
    </div>
    <mat-paginator  *ngIf="arreglo"
                    [length]="arreglo.length"
                    [pageSize]="32"
                    [pageSizeOptions]="[15,32]"
                    (page)="searchFilterPagination(filterListValue)"
                    #paginatorList
    ></mat-paginator>
    <mat-icon class="close" (click)="close()">close</mat-icon>
</div>
<div class="list">
    <div *ngFor="let item of listSlice">
        <app-card 
            (click)="personalId(item[data.card.id])" 
            [photo]="getImg(item[data.card.img])" 
            [title]="item[data.card.titleFirst] + ' ' + (item[data.card.titleSecond] === undefined ? '' : item[data.card.titleSecond])" 
            [item1]="item[data.card.subTitle]" 
            [item2]="(item[data.card.itemKey] === undefined ? '' : item[data.card.itemKey] + ': ')  + (item[data.card.itemValue] === undefined ? '' : item[data.card.itemValue])" 
            [itemF1]="item[data.card.finalLeft]"
            [itemF2]="item[data.card.finalRight]"
            [stateTriangle]="item[data.card.IsActive] === true && item[data.card.Expiry] === true ? 'orange' : 'red'"
            >
        </app-card>
    </div>
</div>