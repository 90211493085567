import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../../../services/modules/common.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FilterMultipleService } from 'src/app/core/services/utils/filter-multiple.service';
import { Requirements } from 'src/app/core/interfaces/interfaces';

export interface DialogData {
  title: string;
  Requirements: any[];
}

@Component({
  selector: 'app-dialog-requirements',
  templateUrl: './dialog-requirements.component.html',
  styleUrls: ['./dialog-requirements.component.scss']
})
export class DialogRequirementsComponent implements OnInit {

  requirementsDocuments: Requirements[] = [];
  filterRequirementsDocuments: Requirements[] = [];

  requirementsDocumentsVigenci: Requirements[] = [];
  filterRequirementsDocumentsVigenci: Requirements[] = [];

  requirementsInspeccion: Requirements[] = [];
  filterRequirementsInspeccion: Requirements[] = [];

  requirementsInspeccionParam: Requirements[] = [];
  filterRequirementsInspeccionParam: Requirements[] = [];

  requirementsEncuesta: Requirements[] = [];
  filterRequirementsEncuesta: Requirements[] = [];

  requirementsId: number[] = [];
  requirements: any = [];
  requirementsPositionType: any = [];
  title: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogRequirementsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonService: CommonService,
    private filters: FilterMultipleService
  ) { 
    // console.log('data',data);
    this.requirementsPositionType = data.Requirements;
    this.title = data?.title;
    this.commonService.getRequirement().subscribe((requirement: any) => {
      this.filterRequirementsDocuments = requirement.filter(x => x.IsActive === true && (x.ByPersonal === true || x.ByEmployerPersonal === true) && x.ByEntry === false && x.BySurvey === false && x.EnableInputEntry === false && x.ByDate === false && x.ByCertification === true).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
      this.requirementsDocuments = requirement.filter(x => x.IsActive === true && (x.ByPersonal === true || x.ByEmployerPersonal === true) && x.ByEntry === false && x.BySurvey === false && x.EnableInputEntry === false && x.ByDate === false && x.ByCertification === true).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

      this.filterRequirementsDocumentsVigenci = requirement.filter(x => x.IsActive === true && x.ByDate === true || x.BySecuritySocial === true).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
      this.requirementsDocumentsVigenci = requirement.filter(x => x.IsActive === true && x.ByDate === true || x.BySecuritySocial === true).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

      this.filterRequirementsInspeccion = requirement.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === false).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
      this.requirementsInspeccion = requirement.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === false).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

      this.filterRequirementsInspeccionParam = requirement.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === true).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
      this.requirementsInspeccionParam = requirement.filter(x => x.IsActive === true && x.ByEntry === true && x.EnableInputEntry === true).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

      this.filterRequirementsEncuesta = requirement.filter(x => x.IsActive === true && x.BySurvey === true).sort((a,b) => (a.OrderNum !== null ? a.OrderNum : Infinity)  > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);
      this.requirementsEncuesta = requirement.filter(x => x.IsActive === true && x.BySurvey === true).sort((a,b) => (a.OrderNum !== null  ? a.OrderNum : Infinity) > (b.OrderNum !== null ? b.OrderNum : Infinity) ? 1 :-1);

      requirement.forEach(req => {
        if(this.cheketRequisito(req.Id) === true) {
          this.requirementsId.push(req.Id);
        }
      });
    });
    
  }

  ngOnInit(): void { }

  close() {
    this.dialogRef.close(false);
  }

  checked(event: MatSlideToggleChange , requirementId: number) {
    if(event.checked === true) {
      this.requirementsId.push(requirementId);
    } else {
      this.requirementsId.forEach((value, index) => {
        value === requirementId ? this.requirementsId.splice(index, 1): '';
      });
    }
  }

  cheketRequisito(Id): boolean {
    let exit = false;
    let data = this.requirementsPositionType.find(x => x.Id === Id);
    data !== undefined ? exit = true: exit = false;
    return exit;
  }


  save() {
    this.dialogRef.close(this.requirementsId);
  }

  getfilterRequirements(value: string) {
    if(value === '') {
      this.filterRequirementsDocuments = this.requirementsDocuments;
      this.filterRequirementsDocumentsVigenci = this.requirementsDocumentsVigenci;
      this.filterRequirementsInspeccion = this.requirementsInspeccion;
      this.filterRequirementsInspeccionParam = this.requirementsInspeccionParam;
      this.filterRequirementsEncuesta = this.requirementsEncuesta;
    } else {
      this.filterRequirementsDocuments = this.getRequirementsFilterDocuments(value);
      this.filterRequirementsDocumentsVigenci = this.getRequirementsFilterDocumentsVigenci(value);
      this.filterRequirementsInspeccion = this.getRequirementsFilterInspeccion(value);
      this.filterRequirementsInspeccionParam = this.getRequirementsFilterInspeccionParam(value);
      this.filterRequirementsEncuesta = this.getRequirementsFilterEncuesta(value);
    }
  }

  getRequirementsFilterDocuments(value: string): any[] {
      return this.requirementsDocuments.filter(x => {
        return (this.filters.exitFilter(x.Name, value));
      });
  }

  getRequirementsFilterDocumentsVigenci(value: string): any[] {
    return this.requirementsDocumentsVigenci.filter(x => {
      return (this.filters.exitFilter(x.Name, value));
    });
  }

  getRequirementsFilterInspeccion(value: string): any[] {
    return this.requirementsInspeccion.filter(x => {
      return (this.filters.exitFilter(x.Name, value));
    });
  }

  getRequirementsFilterInspeccionParam(value: string): any[] {
    return this.requirementsInspeccionParam.filter(x => {
      return (this.filters.exitFilter(x.Name, value));
    });
  }

  getRequirementsFilterEncuesta(value: string): any[] {
    return this.requirementsEncuesta.filter(x => {
      return (this.filters.exitFilter(x.Name, value));
    });
  }

}
