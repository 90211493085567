import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from '../../../services/modules/common.service';
import { CommonOptions, DaneCity, SecurityReference, SecurityReferences } from '../../../interfaces/interfaces';
import { environment } from 'src/environments/environment';
import { SnackBarService } from '../../../services/utils/snackBar.service';
import { DialogService } from '../../../services/utils/dialog.service';
import { UserInfo } from 'src/app/core/models/User';
import { EmployerPost } from 'src/app/core/models/Employer/Employer';
import { EmployerService } from 'src/app/core/services/modules/employer.service';
import { TypeSecurityReference } from 'src/app/core/constants/type-security-references';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FilterMultipleService } from 'src/app/core/services/utils/filter-multiple.service';
import { UtilService } from 'src/app/core/services/utils/utils.service';

export interface DialogData {
  data: any;
  DocumentType: string;
  DocumentNumber: string;
  personal: any;
  digito: any;
  CompanyInfoParentId: any;

}

export interface viewshowChips {
  Id: number;
  Name: string;
}

@Component({
  selector: 'app-dialog-create-employer',
  templateUrl: './dialog-create-employer.component.html',
  styleUrls: ['./dialog-create-employer.component.scss']
})
export class DialogCreateEmployerComponent implements OnInit {

  s3Bucket:string;
  userInfo: UserInfo;
  formEmployer: FormGroup;
  employer: EmployerPost;
  securityReferences: any = [];
  arl: SecurityReference[] = [];

  companyDocumentType: CommonOptions[] = [];
  CajadeCompensacion: SecurityReferences[] = [];

  disablesForm: boolean = false;
  formDataPhoto: FormData;

  daneCity: DaneCity[] = [];

  documentType: string;
  documentNumber: string = "";
  digito: string;
  flagValidNit: boolean = true;
  visible = true;
  selectable = true;
  removable = true;
  // separatorKeysCodes: number[] = [ENTER, COMMA];
  control = new FormControl();

  CajadeCompensacionObs: SecurityReferences[];
  selectedIdCajadeCompensacion: viewshowChips[] = [];

  viewValues: string[] = [];

  maxCaracter: number = 15;
  maxCaracterRol: number = 50;

  imgCropperResult: string;
  @ViewChild('InputControl') InputControl: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    public dialogRef: MatDialogRef<DialogCreateEmployerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private commonService: CommonService,
    private snackBService: SnackBarService,
    private dialogService: DialogService,
    private employerService: EmployerService,
    private filters: FilterMultipleService,
    private utilService: UtilService
  ) {
    // Select Chip inicialicacion de valores
    this.control.valueChanges.subscribe(value => {
    value !== '' ?
    this.CajadeCompensacionObs = this._filter(value) :
    this.CajadeCompensacionObs = this.CajadeCompensacion.slice()});
  }

  ngOnInit(): void {
    setTimeout(() => this.servicesEmployerAll(), 300);
    this.s3Bucket=environment.api.s3Bucket;

    this.formEmployer = this.fb.group({
      Address: '',
      ArlId: 81,
      CityCode: '',
      // CompanyId: '',
      CompanyInfoParentId: new FormControl('', Validators.required),
      DocumentNumber: new FormControl('', Validators.required),//
      DocumentType: new FormControl('', Validators.required),//
      Email: '',
      Name: new FormControl('', Validators.required),//
      Phone: '',
      Rol: new FormControl('', Validators.required),//
      ServicesProvided: '',
      Website: '',
      IsExempt: false,
    });
    // console.log('this.data.DocumentNumber :>> ', this.data);
    if (this.data.data) {
      if (this.data.DocumentType === 'CC') {
        this.formEmployer.get('DocumentType').setValue(this.data.DocumentType);
        this.formEmployer.get('DocumentNumber').setValue(this.data.DocumentNumber);
        this.formEmployer.get('Name').setValue(this.data.DocumentNumber);
        this.formEmployer.get('Name').disable();
      } else if (this.data.DocumentType === 'NIT') {
        this.formEmployer.get('DocumentType').setValue(this.data.DocumentType);
        this.formEmployer.get('DocumentNumber').setValue(this.data.DocumentNumber);
        this.formEmployer.get('Name').setValue(this.data.data.Name);
        this.formEmployer.get('Name').disable();
        this.documentNumber = this.data.DocumentNumber;
        this.documentType = this.data.DocumentType;
        this.digito = this.data.digito;
      }
    }
    if (this.data.DocumentNumber && this.data.DocumentType === 'CC') {
      this.formEmployer.get('DocumentType').setValue(this.data.DocumentType);
      this.formEmployer.get('DocumentNumber').setValue(this.data.DocumentNumber);
      this.documentNumber = this.data.DocumentNumber;
      this.documentType = this.data.DocumentType;
    }
    if (this.data.DocumentNumber && this.data.DocumentType === 'NIT') {
      this.formEmployer.get('DocumentType').setValue(this.data.DocumentType);
      this.formEmployer.get('DocumentNumber').setValue(this.data.DocumentNumber);
      this.documentNumber = this.data.DocumentNumber;
      this.documentType = this.data.DocumentType;
      this.digito = this.data.digito;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  servicesEmployerAll() {
    this.commonService.getUserInfo().subscribe((userInfo: UserInfo) => {
      this.userInfo = userInfo;
    });
    this.commonService.getCommonOptions('CompanyDocumentType').subscribe((data: CommonOptions[]) => {
      this.companyDocumentType = data.filter(documents => documents.Value === 'NIT' || documents.Value === 'CC')
          .sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.employerService.getSecurityReferences().subscribe((data: any) => {
      this.arl = data.filter(x => x.Type === TypeSecurityReference.ARL).sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.commonService.getTypeSecurityReferences(3).subscribe(rest =>{
      this.CajadeCompensacion = rest;
      this.CajadeCompensacionObs = rest;
    });
    this.commonService.getDaneCity().subscribe((daneCity: DaneCity[]) => {
      this.daneCity = daneCity;
    });
  }
  updatePhotoEmployer() {
    this.dialogService.openDialogGestorImg('CARGAR IMAGEN', [], 'editPhoto', 0, 'true', true).afterClosed().subscribe(formDataPhoto => {
      if (formDataPhoto !== undefined) {
        this.imgCropperResult = formDataPhoto.imgResult;
        this.formDataPhoto = formDataPhoto.FormData;
      }
    });
  }
  onSubmit() {
    // console.log('this.selectedIdCajadeCompensacion',this.selectedIdCajadeCompensacion);
    this.formEmployer.get('CompanyInfoParentId').setValue(this.data.CompanyInfoParentId);
    // console.log(this.formPersonal);
    if (this.formEmployer.status === 'VALID') {
      this.employer = {
        Address: this.formEmployer.get('Address').value,
        ArlId: this.formEmployer.get('ArlId').value,
        CityCode: this.formEmployer.get('CityCode').value,
        CompanyInfoParentId: this.formEmployer.get('CompanyInfoParentId').value,
        DocumentNumber: this.documentType === 'NIT' ? this.formEmployer.get('DocumentNumber').value+'-'+this.digito : this.formEmployer.get('DocumentNumber').value,
        DocumentType: this.formEmployer.get('DocumentType').value,
        Email: this.formEmployer.get('Email').value,
        Name: this.formEmployer.get('Name').value,
        Phone: this.formEmployer.get('Phone').value,
        Rol: this.formEmployer.get('Rol').value,
        ServicesProvided: this.formEmployer.get('ServicesProvided').value,
        Website: this.formEmployer.get('Website').value,
        IsExempt: this.formEmployer.get('IsExempt').value
      }
      let CCFId: number[] = [];
      this.selectedIdCajadeCompensacion.forEach(selectedCaja => {
        CCFId.push(selectedCaja.Id);
      });
      this.employerService.postEmployer(this.employer).subscribe((employer: EmployerPost) => {
        if (this.formDataPhoto === undefined) {
          if (CCFId.length === 0) {
            this.dialogRef.close(employer);
          } else {
            this.employerService.postAddCCFemployerId(employer.CompanyInfoParentId, CCFId).subscribe(restAddCCF => {
              this.dialogRef.close(employer);
            });
          }
        } else {
          this.employerService.putEmloyerUpdateLogo(employer.Id, this.formDataPhoto).subscribe((rest: any) => {
            if (CCFId.length === 0) {
              this.dialogRef.close(employer);
            } else {
              this.employerService.postAddCCFemployerId(employer.CompanyInfoParentId, CCFId).subscribe(restAddCCF => {
                this.dialogRef.close(employer);
              });
            }
          });
        }
      });
    } else {
      // console.log(this.formEmployer);
      this.snackBService.openSnackBar('Completar los campos requeridos', 'X', 4000);
    }
  }
  validateNit() {
    if ((this.documentType === 'NIT') && (this.documentNumber !== '' && this.digito !== undefined)) {
      if(parseInt(this.digito) !== this.utilService.calcularDigito(this.documentNumber)) {
        this.flagValidNit = false;
      } else {
        this.flagValidNit = true;
      }
    }
  }

  setValuePersonal() {
    this.documentNumber = '';
    this.documentType = 'NIT';
  }


  // Metodos Selected Chip / en el Constructor se inicia los array con el change Input
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.viewValues.push(value);
    }
    this.control.setValue(null);
  }

  remove(item: string): void {
    // console.log(item);
    const index = this.viewValues.indexOf(item);
    for (let i = 0; i < this.selectedIdCajadeCompensacion.length; i++) {
      if (this.selectedIdCajadeCompensacion[i].Name === item) {
        this.selectedIdCajadeCompensacion.splice(index, 1);
      }
    }
    if (index >= 0) {
      this.viewValues.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let exitCaja = this.selectedIdCajadeCompensacion.find(x => x.Id === event.option.value);
    if (this.selectedIdCajadeCompensacion.length === 0 || exitCaja === undefined) {
      this.selectedIdCajadeCompensacion.push({Id: event.option.value, Name: event.option.viewValue});
      this.viewValues.push(event.option.viewValue);
      this.InputControl.nativeElement.value = '';
      this.InputControl.nativeElement.disabled = true;
      this.control.setValue('');
      this.InputControl.nativeElement.disabled = false;
    } else {
      this.InputControl.nativeElement.value = '';
      this.InputControl.nativeElement.disabled = true;
      this.control.setValue('');
      this.InputControl.nativeElement.disabled = false;
    }
  }

  private _filter(value: string): SecurityReferences[] {
    return this.CajadeCompensacion.filter(x => {
      return  this.filters.exitFilter(x.Name , value);
    });
  }
  // Metodos Selected Chip / en el Constructor se inicia los array con el change Input FIn
}
