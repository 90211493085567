<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{data.title || 'Sin title'}}</div>
        <!-- <mat-icon class="close" (click)="close()">close</mat-icon> -->
    </div>
    <div class="content-card">
        <div class="search">
            <input class="input-search" 
                type="text" 
                placeholder="escribe para agregar" 
                [(ngModel)]="valueInput">
            <mat-icon class="icon-add" (click)="addItemList(valueInput)">add</mat-icon>
        </div>
        <div class="list" *ngIf="data.dataList">
            <div class="items-list" *ngFor="let item of sendList; let i = index;">
                <div class="item">
                    {{item}}
                </div>
                <mat-icon class="icon-delete" (click)="deleteItemList(i)">delete</mat-icon>
            </div>
        </div>
        <div class="actions-dialog"style="margin: 3px;">
            <button mat-button class="btn-cancelar" (click)="close()">CANCELAR</button>
            <button mat-button class="btn-generar" (click)="_send()">ENVIAR</button>
        </div>
    </div>
   
    
</div>