import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DatesPeriods } from 'src/app/core/interfaces/interfaces';
import { FechasService } from 'src/app/core/services/utils/fechas.service';

export interface DialogData {
  copyPrevious: boolean;
  datePeriod: any;
}

@Component({
  selector: 'app-dialog-planilla',
  templateUrl: './dialog-planilla.component.html',
  styleUrls: ['./dialog-planilla.component.scss']
})
export class DialogPlanillaComponent implements OnInit {

  datesPeriod:DatesPeriods[] = [];

  constructor(
        private fechasService: FechasService,
        public dialogRef: MatDialogRef<DialogPlanillaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.datesPeriod = this.fechasService.getListDateYYYYMM(0,5);
    this.data.datePeriod = this.datesPeriod[0].dateAPi;
    this.data.copyPrevious = false;
  }

}
