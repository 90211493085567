<div class="dashboard">
    <div class="above">
        <!-- <div class="search-paginator" [ngClass]="production === false ? 'color-search-paginator-pruebas': 'color-search-paginator-pro'">
        </div> -->
    </div>
    <div class="filters">
        <app-select-search class="select-field"
                        [_dataSource]="companies" [textInput]="'ADMINISTRADORAS'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="controlCompanies" 
                        [inputType]="'text'" (onSelectionChange)="onChangeSelectedCompany($event)">
        </app-select-search>
        <app-select-search class="select-field"
                        [_dataSource]="proyects" [textInput]="'PROYECTOS'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="controlProyects" 
                        [inputType]="'text'" (onSelectionChange)="onChangeSelectedPro($event)">
        </app-select-search>
        <app-select-search class="select-field"
                        [_dataSource]="employers" [textInput]="'EMPRESAS'" [dataShow]="'Name'" [otherFilter]="'DocumentNumber'" [dataValue]="'Id'" [siteCtrl]="controlEmployers" 
                        [inputType]="'text'" (onSelectionChange)="onChangeSelectedEmp($event)">
        </app-select-search>
        <app-select-search class="select-field"
                        [_dataSource]="contracts" [textInput]="'CONTRATOS'" [dataShow]="'ContractReview'" [otherFilter]="'ContractNumber'" [dataValue]="'Id'" [siteCtrl]="controlContracts" 
                        [inputType]="'text'" (onSelectionChange)="onChangeSelectedContract($event)">
        </app-select-search>
        <app-select-search class="select-field"
                        [_dataSource]="person" [textInput]="'PERSONAL'" [dataShow]="'Name'"  [dataValue]="'Id'" [siteCtrl]="controlPerson" 
                        [inputType]="'text'" [disabled]="true">
        </app-select-search>
    </div>
    <form class="filters">
        <app-select-search class="select-field-short"
                        [_dataSource]="jsonDashboardFilters[0]?.states" [textInput]="'ESTADO'" [dataShow]="'Name'" [dataValue]="'Value'" [siteCtrl]="this.formFilters.get('estado')" 
                        [inputType]="'text'" (onSelectionChange)="changeSubFilter($event)">
        </app-select-search>
        <!-- <app-select-search class="select-field-short"
                        [_dataSource]="jsonDashboardFilters[0]?.variaciones" [textInput]="'VARIACIONES'" [dataShow]="'Name'" [dataValue]="'Value'" [siteCtrl]="this.formFilters.get('variaciones')" 
                        [inputType]="'text'" (onSelectionChange)="changeSubFilter($event)">
        </app-select-search> -->
        <app-select-search class="select-field-short"
                        [_dataSource]="jsonDashboardFilters[0]?.asistencia" [textInput]="'ASISTENCIA'" [dataShow]="'Name'" [dataValue]="'Value'" [siteCtrl]="this.formFilters.get('asistencia')" 
                        [inputType]="'text'" (onSelectionChange)="changeSubFilter($event)">
        </app-select-search>
        <app-select-search class="select-field-short"
                        [_dataSource]="jsonDashboardFilters[0]?.cumplimiento" [textInput]="'CUMPLIMIENTO'" [dataShow]="'Name'" [dataValue]="'Value'" [siteCtrl]="this.formFilters.get('cumplimiento')" 
                        [inputType]="'text'" (onSelectionChange)="changeSubFilter($event)">
        </app-select-search>
        <app-select-search class="select-field-short"
                        [_dataSource]="jsonDashboardFilters[0]?.categoria" [textInput]="'CATEGORIA'" [dataShow]="'Name'"  [dataValue]="'Value'" [siteCtrl]="this.formFilters.get('categorias')" 
                        [inputType]="'text'" (onSelectionChange)="changeSubFilter($event)">
        </app-select-search>
        <app-select-search class="select-field-short"
                        [_dataSource]="jsonDashboardFilters[0]?.sexo" [textInput]="'SEXO'" [dataShow]="'Name'" [dataValue]="'Value'" [siteCtrl]="this.formFilters.get('sexo')" 
                        [inputType]="'text'" (onSelectionChange)="changeSubFilter($event)">
        </app-select-search>
        <app-select-search class="select-field-short"
                        [_dataSource]="jsonDashboardFilters[0]?.rangoYear" [textInput]="'RENGO DE EDAD'" [dataShow]="'Name'"  [dataValue]="'Value'" [siteCtrl]="this.formFilters.get('rango')" 
                        [inputType]="'text'" (onSelectionChange)="changeSubFilter($event)">
        </app-select-search>
    </form>
    <div class="list" style="padding-top: 19px;">
        <div class="card-nivel-1">
            <div class="card-img-1">
                <mat-icon style="color: #03a9f4;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/req.svg" role="img" aria-hidden="true">
                person
                </mat-icon>
            </div>
            <div class="card-content-nivel-1">
                <div class="field">{{'Activo / vinculados' || ''}}</div>
                <div class="indicators">
                    <div class="valores"  >
                        {{total?.Active || '0'}}/{{total?.Total || '0'}}
                    </div>
                    <div class="porcentaje">
                        {{getPercentage(total?.Active, total?.Total) || '0'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="card-nivel-1">
            <div class="card-img-1">
                <mat-icon style="color: #03a9f4;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/new.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 210 210" style="enable-background:new 0 0 210 210;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path d="M126,42c0,23.2-18.8,42-42,42S42,65.2,42,42S60.8,0,84,0S126,18.8,126,42z M0,168v-21
                        c0,0,19.9-42,84-42c16.9,0,30.8,2.9,42,7.3V126H84v42H0z M210,147h-42v-42h-21v42h-42v21h42v42h21v-42h42V147z"></path>
                </svg></mat-icon>
            </div>
            <div class="card-content-nivel-1">
                <div class="field">{{'Nuevo personal / vinculados' || ''}}</div>
                <div class="indicators">
                    <div class="valores"  >
                        {{total?.NewPersonal || '0'}}/{{total?.Total || '0'}}
                    </div>
                    <div class="porcentaje"  >
                        {{getPercentage(total?.NewPersonal, total?.Total) || '0'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="card-nivel-1">
            <div class="card-img-1">
                <mat-icon style="color: black" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/assistant.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 210 210" style="enable-background:new 0 0 210 210;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path d="M126,42c0,23.2-18.8,42-42,42S42,65.2,42,42S60.8,0,84,0S126,18.8,126,42z M0,168v-21
                        c0,0,19.9-42,84-42c33.3,0,54.6,11.3,67.5,22.2l-23.1,24.5l-11.2-10.1L103,127.3l-28.4,28.4L63.2,168L0,168z M128.9,210l-39.6-39.6
                        l14.2-14.2l25.4,24.3l66.9-69l14.2,14.2L128.9,210z"></path>
                </svg></mat-icon>
            </div>
            <div class="card-content-nivel-1">
                <div class="field">{{'Asistentes / Activos' || ''}}</div>
                <div class="indicators">
                    <div class="valores"  >
                        {{total?.Presence || '0'}}/{{total?.Active || '0'}}
                    </div>
                    <div class="porcentaje"  >
                        {{getPercentage(total?.Presence, total?.Active) || '0'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="card-nivel-1">
            <div class="card-img-1">
                <mat-icon style="color: black;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/inactive.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 210 210" style="enable-background:new 0 0 210 210;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path d="M126,42c0,23.2-18.8,42-42,42S42,65.2,42,42S60.8,0,84,0S126,18.8,126,42z M0,168v-21
                        c0,0,19.9-42,84-42c12.8,0,23.8,1.7,33.2,4.3l-11.2,12l-14.2,14.2l30.4,30.4L120,168H0z M210,135l-14.2-14.2l-30.4,30.4L135,120.8
                        L120.8,135l30.4,30.4l-30.4,30.4L135,210l30.4-30.4l30.4,30.4l14.2-14.2l-30.4-30.4L210,135z"></path>
                </svg></mat-icon>
            </div>
            <div class="card-content-nivel-1">
                <div class="field">{{'Ausente / Activos' || ''}}</div>
                <div class="indicators">
                    <div class="valores"  >
                        {{total?.Active - total?.Presence || '0'}}/{{total?.Active || '0'}}
                    </div>
                    <div class="porcentaje"  >
                        {{getPercentage(total?.Active - total?.Presence, total?.Active) || '0'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="card-nivel-1">
            <div class="card-img-1">
                <mat-icon style="color: #02c302;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/req.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 16" style="enable-background:new 0 0 20 16;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path d="M0,0v16h4v-2H2V2h2V0H0z M16,0v2h2v12h-2v2h4V0H16z M4,3v2h12V3H4z M4,7v2h8V7H4z M4,11v2h12v-2H4z"></path>
                    </svg></mat-icon>
            </div>
            <div class="card-content-nivel-1">
                <div class="field">{{'Cumplimiento del 100% de los requisitos / Total asistentes' || ''}}</div>
                <div class="indicators">
                    <div class="valores"  >
                        {{total?.Accomplish || '0'}}/{{total?.Presence || '0'}}
                    </div>
                    <div class="porcentaje"  >
                        {{getPercentage(total?.Accomplish, total?.Presence) || '0'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="card-nivel-1">
            <div class="card-img-1">
                <mat-icon style="color: red;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/req.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 16" style="enable-background:new 0 0 20 16;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path d="M0,0v16h4v-2H2V2h2V0H0z M16,0v2h2v12h-2v2h4V0H16z M4,3v2h12V3H4z M4,7v2h8V7H4z M4,11v2h12v-2H4z"></path>
                    </svg></mat-icon>
            </div>
            <div class="card-content-nivel-1">
                <div class="field">{{'Personal sin cumplir el 100% de requisitos / Total asistentes' || ''}}</div>
                <div class="indicators">
                    <div class="valores"  >
                        {{total?.NoCumple || '0'}}/{{total?.Presence || '0'}}
                    </div>
                    <div class="porcentaje"  >
                        {{getPercentage(total?.NoCumple, total?.Presence) || '0'}}
                    </div>
                </div>
            </div>
        </div>
    </div>    
    

    <!-- summary -->
    <div class="summary wrapper row">
            <div class="col" style="margin-top: 19px;">

                <div class="row start-center" style="height: 91px;">
                    <app-select-search class="select-field-medium"
                            [_dataSource]="dashboardPersonalFilter" [textInput]="'FILTRO'" [dataShow]="'Description'"  [dataValue]="'Value'" [siteCtrl]="controlFilter" 
                            [inputType]="'text'" (onSelectionChange)="changeFilter($event)">
                    </app-select-search>
                </div>
        
                <div id="SummaryNames" (scroll)="onScrollSummaryNames($event)" #scrollSummaryNames>
                    <div class="description ellipsis"  *ngFor="let item of (flagChangeFilter === true ? dataFilter : data)" title="{{ item.Name }}">
                        <div class="goPerson" (click)="goPerson(item)">{{ item.Name | uppercase}}</div>
                    </div>
                </div>
        
                <div class="foot" style="background: #E1F5FE">
                    Subtotales
                </div>
                <div class="foot uppercase" style="border-bottom-left-radius: 4px;background: #039BE5; color:#FFFFFF;">
                    TOTAL FILTRADO
                </div>
            </div>
            <div class="data">
                <div class="headers">
                    <div style="min-width: 120px;">Estado</div>
                    <!-- <div style="min-width: 180px;">Variaciones</div> -->
                    <div style="width: 60px !important; word-wrap: break-word; padding: 14px 12px 0px 11px;">Verificado</div>
                    <div style="width: 60px; border-left: none; word-wrap: break-word;">Inspeccionado</div>
                    <div style="min-width: 240px;">Asistencia</div>
                    <div style="min-width: 120px;">Cumplimiento</div>
                    <div style="min-width: 420px;">Categoría de asistentes</div>
                    <div style="min-width: 120px;">Sexo</div>
                    <div style="min-width: 420px;">Edad de asistentes</div>
                </div>
                <div class="icons">
                    <div class="row" style="min-width: 120px;">
                        <div class="icon-wrapper">
                            <div class="icon" title="Personal vinculado">
                                <!-- <mat-icon style="color: grey;" class="material-icons" role="img" aria-label="person">person</mat-icon> -->
                                <mat-icon style="color: #03a9f4;" class="material-icons" role="img" aria-label="person">person</mat-icon>
                            </div>
                        </div>
                        <div class="icon-wrapper border-l">
                            <div class="icon" title="Personal inactivo">
                                <!-- <mat-icon style="color: red;" class="material-icons" role="img" aria-label="person">person</mat-icon> -->
                                <mat-icon style="color: orange;" class="material-icons" role="img" aria-label="person">person</mat-icon>
                            </div>
                        </div>
                        <!-- <div class="icon-wrapper border-r">
                            <div class="icon" title="Personal activo">
                                <mat-icon style="color: #03a9f4;" class="material-icons" role="img" aria-label="person">person</mat-icon>
                            </div>
                        </div>
                        <div class="icon-wrapper">
                            <div class="icon" title="Personal por vencer">
                                <mat-icon style="color: orange;" class="material-icons" role="img" aria-label="person">person</mat-icon>
                            </div>
                        </div> -->
                    </div>
        
        
                    <!-- <div style="min-width: 180px;">
                        <div class="icon" title="Activado">
                            <mat-icon style="color: #03a9f4;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/new.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 210 210" style="enable-background:new 0 0 210 210;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M126,42c0,23.2-18.8,42-42,42S42,65.2,42,42S60.8,0,84,0S126,18.8,126,42z M0,168v-21
            c0,0,19.9-42,84-42c16.9,0,30.8,2.9,42,7.3V126H84v42H0z M210,147h-42v-42h-21v42h-42v21h42v42h21v-42h42V147z"></path>
        </svg></mat-icon>
                        </div>
                        <div class="icon" title="Reactivado">
                            <mat-icon style="color: #02c302;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/assistant.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 210 210" style="enable-background:new 0 0 210 210;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M126,42c0,23.2-18.8,42-42,42S42,65.2,42,42S60.8,0,84,0S126,18.8,126,42z M0,168v-21
            c0,0,19.9-42,84-42c33.3,0,54.6,11.3,67.5,22.2l-23.1,24.5l-11.2-10.1L103,127.3l-28.4,28.4L63.2,168L0,168z M128.9,210l-39.6-39.6
            l14.2-14.2l25.4,24.3l66.9-69l14.2,14.2L128.9,210z"></path>
        </svg></mat-icon>
                        </div>
                        <div class="icon" title="Inactivado">
                            <mat-icon style="color: red;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/inactive.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 210 210" style="enable-background:new 0 0 210 210;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M126,42c0,23.2-18.8,42-42,42S42,65.2,42,42S60.8,0,84,0S126,18.8,126,42z M0,168v-21
            c0,0,19.9-42,84-42c12.8,0,23.8,1.7,33.2,4.3l-11.2,12l-14.2,14.2l30.4,30.4L120,168H0z M210,135l-14.2-14.2l-30.4,30.4L135,120.8
            L120.8,135l30.4,30.4l-30.4,30.4L135,210l30.4-30.4l30.4,30.4l14.2-14.2l-30.4-30.4L210,135z"></path>
        </svg></mat-icon>
                        </div>
                    </div> -->
        
        
                    <div style="min-width: 120px;">
                        <div class="icon" title="Verificado">
                            <mat-icon style="color:black;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/verify.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 37 47" style="enable-background:new 0 0 37 47;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <style type="text/css">
            .st0{stroke:#000000;stroke-miterlimit:10;}
        </style>
        <g>
            <g>
                <path class="st0" d="M0.5,0.5v46h36V13.1l-0.3-0.3l-12-12l-0.3-0.3H0.5z M2.5,2.5h20v12h12v30h-32V2.5z M24.5,3.9l8.6,8.6h-8.6
                    V3.9z"></path>
            </g>
        </g>
        </svg></mat-icon>
                        </div>
                        <div class="icon" title="Inspeccionado">
                            <mat-icon style="color:black;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/inspect.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 51 68" style="enable-background:new 0 0 51 68;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <style type="text/css">
            .st0{stroke:#000000;stroke-miterlimit:10;}
        </style>
        <path class="st0" d="M25.5,0.5c-2.9,0-5.3,2.2-5.8,5H3.5c-1.6,0-3,1.4-3,3v56c0,1.6,1.4,3,3,3h44c1.6,0,3-1.4,3-3v-56
            c0-1.6-1.4-3-3-3H31.3C30.8,2.7,28.4,0.5,25.5,0.5z M25.5,2.5c2.2,0,4,1.8,4,4v1h6v4c0,0.6-0.4,1-1,1h-18c-0.6,0-1-0.4-1-1v-4h6v-1
            C21.5,4.3,23.3,2.5,25.5,2.5z M25.5,5.5c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S26.1,5.5,25.5,5.5z M3.5,7.5h10v4c0,1.6,1.4,3,3,3h18
            c1.6,0,3-1.4,3-3v-4h10c0.6,0,1,0.4,1,1v56c0,0.6-0.4,1-1,1h-44c-0.6,0-1-0.4-1-1v-56C2.5,7.9,2.9,7.5,3.5,7.5z"></path>
        </svg></mat-icon>
                        </div>
                    </div>
        
        
                    <div style="min-width: 240px;">
                        <div class="icon" title="Personal ausente">
                            <mat-icon style="color: black;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/inactive.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 210 210" style="enable-background:new 0 0 210 210;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M126,42c0,23.2-18.8,42-42,42S42,65.2,42,42S60.8,0,84,0S126,18.8,126,42z M0,168v-21
            c0,0,19.9-42,84-42c12.8,0,23.8,1.7,33.2,4.3l-11.2,12l-14.2,14.2l30.4,30.4L120,168H0z M210,135l-14.2-14.2l-30.4,30.4L135,120.8
            L120.8,135l30.4,30.4l-30.4,30.4L135,210l30.4-30.4l30.4,30.4l14.2-14.2l-30.4-30.4L210,135z"></path>
        </svg></mat-icon>
                        </div>
        
                        <div class="icon" title="Personal asistente">
                            <mat-icon style="color: black" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/assistant.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 210 210" style="enable-background:new 0 0 210 210;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M126,42c0,23.2-18.8,42-42,42S42,65.2,42,42S60.8,0,84,0S126,18.8,126,42z M0,168v-21
            c0,0,19.9-42,84-42c33.3,0,54.6,11.3,67.5,22.2l-23.1,24.5l-11.2-10.1L103,127.3l-28.4,28.4L63.2,168L0,168z M128.9,210l-39.6-39.6
            l14.2-14.2l25.4,24.3l66.9-69l14.2,14.2L128.9,210z"></path>
        </svg></mat-icon>
                        </div>
                        
                        <div class="icon" title="Entrada">
                            <mat-icon style="color:black;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/entry.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 19 20" style="enable-background:new 0 0 19 20;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M2,0C0.9,0,0,0.9,0,2v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-5h-2v5H2V2h12v5h2V2c0-1.1-0.9-2-2-2H2z M11,6.3L6,10l5,3.8
            V11h8V9h-8V6.3z"></path>
        </svg></mat-icon>
                        </div>
                        <div class="icon" title="Salida">
                            <mat-icon style="color:black;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/exit.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 17 20" style="enable-background:new 0 0 17 20;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <g id="surface1">
            <path d="M2,0C0.9,0,0,0.9,0,2v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-4.8l-2,1.5V18H2V2h12v3.3l2,1.5V2c0-1.1-0.9-2-2-2H2z
                M12,6.3V9H7v2h5v2.8l5-3.8L12,6.3z"></path>
        </g>
        </svg></mat-icon>
                        </div>
                    </div>
        
        
                    <div style="min-width: 120px;">
                        <div class="icon" title="Cumple">
                            <mat-icon style="color: #02c302;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/req.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 16" style="enable-background:new 0 0 20 16;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M0,0v16h4v-2H2V2h2V0H0z M16,0v2h2v12h-2v2h4V0H16z M4,3v2h12V3H4z M4,7v2h8V7H4z M4,11v2h12v-2H4z"></path>
        </svg></mat-icon>
                        </div>
                        <div class="icon" title="No cumple">
                            <mat-icon style="color: red;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/req.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 16" style="enable-background:new 0 0 20 16;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M0,0v16h4v-2H2V2h2V0H0z M16,0v2h2v12h-2v2h4V0H16z M4,3v2h12V3H4z M4,7v2h8V7H4z M4,11v2h12v-2H4z"></path>
        </svg></mat-icon>
                        </div>
                    </div>
        
        
                    <div style="min-width: 420px;">
                        <div class="icon" title="Personal administrativo">
                            <mat-icon md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/category/AD.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 396.9 496.1" style="enable-background:new 0 0 396.9 496.1;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path style="fill:#FF9800;" d="M198.4,409.3l-62-74.4v-74.4h124v74.4L198.4,409.3z"></path>
        <path style="fill:#FFA726;" d="M334.8,186c0,13.7-11.1,24.8-24.8,24.8s-24.8-11.1-24.8-24.8c0-13.7,11.1-24.8,24.8-24.8
            S334.8,172.3,334.8,186 M111.6,186c0-13.7-11.1-24.8-24.8-24.8C73.1,161.2,62,172.3,62,186c0,13.7,11.1,24.8,24.8,24.8
            C100.5,210.8,111.6,199.7,111.6,186"></path>
        <path style="fill:#FFB74D;" d="M310,111.6c0-94.7-223.2-61.7-223.2,0v86.8c0,61.7,49.9,111.6,111.6,111.6
            c61.7,0,111.6-49.9,111.6-111.6V111.6z"></path>
        <path style="fill:#424242;" d="M198.4,0c-75.3,0-124,61.1-124,136.4v28.3L99.2,186v-62L248,74.4l49.6,49.6v62l24.8-21.6v-28
            c0-49.9-12.9-99.4-74.4-111.6L235.6,0L198.4,0L198.4,0z"></path>
        <path style="fill:#784719;" d="M235.6,186c0-6.8,5.6-12.4,12.4-12.4c6.8,0,12.4,5.6,12.4,12.4c0,6.8-5.6,12.4-12.4,12.4
            C241.2,198.4,235.6,192.9,235.6,186 M136.4,186c0,6.8,5.6,12.4,12.4,12.4c6.8,0,12.4-5.6,12.4-12.4c0-6.8-5.6-12.4-12.4-12.4
            C142,173.6,136.4,179.2,136.4,186"></path>
        <path style="fill:#FFFFFF;" d="M198.4,483.7l-62-148.8l62,12.4l62-12.4L198.4,483.7z"></path>
        <path style="fill:#D32F2F;" d="M186,384.5l-8.3,55.4l20.7,49.6l20.7-49.6l-8.3-55.4l12.4-12.4l-24.8-24.8l-24.8,24.8L186,384.5z"></path>
        <path style="fill:#3F51B5;" d="M260.4,334.8l-62,148.8l-62-148.8c0,0-136.4,24.6-136.4,161.2h396.9
            C396.9,359.9,260.4,334.8,260.4,334.8"></path>
        </svg></mat-icon>
                        </div>
                        <div class="icon" title="Personal asociado">
                            <mat-icon md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/category/AS.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 451.7 541.1" style="enable-background:new 0 0 451.7 541.1;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path style="fill:#364C9B;" d="M297.6,45.4c0-15.5,11.8-28.1,26.4-28.1h34V0h-34c-23.5,0-42.6,20.3-42.6,45.4
            c0,25,19.1,45.4,42.6,45.4h34V73.5h-34C309.4,73.5,297.6,60.9,297.6,45.4z M332.5,54.4h68.1V36.3h-68.1V54.4z M409.1,0H375v17.2
            h34.1c14.6,0,26.4,12.6,26.4,28.1s-11.8,28.1-26.4,28.1H375v17.2h34.1c23.5,0,42.6-20.3,42.6-45.4C451.7,20.3,432.6,0,409.1,0z"></path>
        <path style="fill:#F39C12;" d="M198.4,454.3l-62-74.4v-74.4h124v74.4L198.4,454.3z"></path>
        <path style="fill:#FFA726;" d="M334.8,231.1c0,13.7-11.1,24.8-24.8,24.8s-24.8-11.1-24.8-24.8s11.1-24.8,24.8-24.8
            S334.8,217.4,334.8,231.1z"></path>
        <path style="fill:#FFA726;" d="M111.6,231.1c0,13.7-11.1,24.8-24.8,24.8S62,244.8,62,231.1s11.1-24.8,24.8-24.8
            S111.6,217.4,111.6,231.1z"></path>
        <path style="fill:#FFB74D;" d="M310,156.7c0-94.3-223.2-62-223.2,0c0,13.7,0,73.2,0,86.8c0,62,49.6,111.6,111.6,111.6
            S310,305.5,310,243.5C310,229.8,310,170.3,310,156.7z"></path>
        <path style="fill:#424242;" d="M198.4,45c-75.7,0-124,60.7-124,136.4c0,9.9,0,28.5,0,28.5L99.2,231v-62L248,119.5l49.6,49.6v62
            l24.8-21.1c0,0,0-18.6,0-28.5c0-49.6-12.4-99.2-74.4-111.6L235.6,45H198.4z"></path>
        <path style="fill:#784719;" d="M260.4,231.1c0,6.8-5.6,12.4-12.4,12.4c-6.8,0-12.4-5.6-12.4-12.4c0-6.8,5.6-12.4,12.4-12.4
            C254.9,218.7,260.4,224.2,260.4,231.1z"></path>
        <path style="fill:#784719;" d="M161.2,231.1c0,6.8-5.6,12.4-12.4,12.4c-6.8,0-12.4-5.6-12.4-12.4c0-6.8,5.6-12.4,12.4-12.4
            C155.6,218.7,161.2,224.2,161.2,231.1z"></path>
        <path style="fill:#FFFFFF;" d="M198.4,528.7l-62-148.8l62,12.4l62-12.4L198.4,528.7z"></path>
        <path style="fill:#2C3E50;" d="M186,429.5l-8.7,55.8l21.1,49.6l21.1-49.6l-8.7-55.8l12.4-12.4l-24.8-24.8l-24.8,24.8L186,429.5z"></path>
        <path style="fill:#2980B9;" d="M260.4,379.9l-62,148.8l-62-148.8c0,0-136.4,24.8-136.4,161.2h396.9
            C396.9,404.7,260.4,379.9,260.4,379.9z"></path>
        </svg></mat-icon>
                        </div>
                        <div class="icon" title="Personal contratista">
                            <mat-icon md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/category/CO.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 396.9 520.9" style="enable-background:new 0 0 396.9 520.9;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path style="fill:#00897B;" d="M260.4,359.6l-62,12.4l-62-12.4c0,0-136.4,24.6-136.4,161.2h396.9
            C396.9,384.7,260.4,359.6,260.4,359.6"></path>
        <path style="fill:#FF9800;" d="M198.4,434.1l-62-74.4v-74.4h124v74.4L198.4,434.1z"></path>
        <path style="fill:#FFA726;" d="M334.8,210.8c0,13.7-11.1,24.8-24.8,24.8s-24.8-11.1-24.8-24.8S296.3,186,310,186
            S334.8,197.1,334.8,210.8 M111.6,210.8c0-13.7-11.1-24.8-24.8-24.8S62,197.1,62,210.8s11.1,24.8,24.8,24.8S111.6,224.5,111.6,210.8"></path>
        <path style="fill:#FFB74D;" d="M310,136.4c0-94.7-223.2-61.7-223.2,0v86.8c0,61.7,49.9,111.6,111.6,111.6S310,284.9,310,223.2V136.4
            z"></path>
        <path style="fill:#424242;" d="M198.4,24.8c-75.3,0-124,61.1-124,136.4v28.3l24.8,21.3v-62L248,99.2l49.6,49.6v62l24.8-21.6v-28
            c0-49.9-12.9-99.4-74.4-111.6l-12.4-24.8L198.4,24.8L198.4,24.8z"></path>
        <path style="fill:#784719;" d="M235.6,210.8c0-6.8,5.6-12.4,12.4-12.4c6.8,0,12.4,5.6,12.4,12.4c0,6.8-5.6,12.4-12.4,12.4
            C241.2,223.2,235.6,217.7,235.6,210.8 M136.4,210.8c0,6.8,5.6,12.4,12.4,12.4c6.8,0,12.4-5.6,12.4-12.4c0-6.8-5.6-12.4-12.4-12.4
            C142,198.4,136.4,204,136.4,210.8"></path>
        <path style="fill:#F9A825;" d="M272.8,362.8v108.5H124V362.7c-11.7,3.5-30.3,10.2-49.6,22.2v135.9h248V385.2
            C303.2,373.1,284.6,366.3,272.8,362.8z"></path>
        <path style="fill:#263238;" d="M124,471.3H74.4v-37.2H124V471.3z M322.4,434.1h-49.6v37.2h49.6V434.1L322.4,434.1z"></path>
        <path style="fill:#FF5722;" d="M322.4,136.4V124c0-61.7-49.9-111.6-111.6-111.6H186C124.4,12.4,74.4,62.3,74.4,124v12.4H49.6v37.2
            h297.6v-37.2H322.4z"></path>
        <path style="fill:#D84315;" d="M161.2,0h74.4v136.4h-74.4L161.2,0L161.2,0z"></path>
        </svg></mat-icon>
                        </div>
                        <div class="icon" title="Personal proveedor">
                            <mat-icon md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/category/PR.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 520.9 520.9" style="enable-background:new 0 0 520.9 520.9;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path style="fill:#1565C0;" d="M260.4,359.6l-62,12.4l-62-12.4c0,0-136.4,24.6-136.4,161.2h396.9
            C396.9,384.7,260.4,359.6,260.4,359.6"></path>
        <path style="fill:#FF9800;" d="M198.4,434.1l-62-74.4v-74.4h124v74.4L198.4,434.1z"></path>
        <path style="fill:#F9A825;" d="M334.8,210.8c0,13.7-11.1,24.8-24.8,24.8c-13.7,0-24.8-11.1-24.8-24.8S296.3,186,310,186
            C323.8,186,334.8,197.1,334.8,210.8 M111.6,210.8c0-13.7-11.1-24.8-24.8-24.8S62,197.1,62,210.8s11.1,24.8,24.8,24.8
            S111.6,224.5,111.6,210.8"></path>
        <path style="fill:#FFB74D;" d="M310,136.4c0-94.7-223.2-61.7-223.2,0v86.8c0,61.7,49.9,111.6,111.6,111.6S310,284.9,310,223.2V136.4
            L310,136.4z"></path>
        <path style="fill:#424242;" d="M198.4,24.8c-75.3,0-124,61.1-124,136.4v28.3l24.8,21.3v-62L248,99.2l49.6,49.6v62l24.8-21.6v-28
            c0-49.9-12.9-99.4-74.4-111.6l-12.4-24.8L198.4,24.8L198.4,24.8z"></path>
        <path style="fill:#784719;" d="M235.6,210.8c0-6.8,5.6-12.4,12.4-12.4s12.4,5.6,12.4,12.4c0,6.8-5.6,12.4-12.4,12.4
            C241.2,223.2,235.6,217.7,235.6,210.8 M136.4,210.8c0,6.8,5.6,12.4,12.4,12.4c6.8,0,12.4-5.6,12.4-12.4c0-6.8-5.6-12.4-12.4-12.4
            C142,198.4,136.4,204,136.4,210.8"></path>
        <path style="fill:#283593;" d="M272.8,362.8v108.5H124V362.8c-11.7,3.5-30.3,10.2-49.6,22.2v135.9h248V385.2
            C303.2,373.1,284.6,366.3,272.8,362.8z"></path>
        <path style="fill:#263238;" d="M124,471.3H74.4v-37.2H124V471.3z M322.4,434.1h-49.6v37.2h49.6V434.1z"></path>
        <path style="fill:#1565C0;" d="M322.4,142.9v-28c0-62.1-59.3-102.5-124-102.5s-124,40.4-124,102.5v28.6c0,0,48.3-31.9,124-31.9
            C274.2,111.6,322.4,142.9,322.4,142.9z"></path>
        <path style="fill:#1976D2;" d="M124,124h148.8c0,0-6.2-92.3-74.4-116.8C128.1,37.1,124,124,124,124z"></path>
        <path style="fill:#42A5F5;" d="M214.9,7.9c0-4.4-7.4-7.9-16.5-7.9s-16.5,3.5-16.5,7.9s7.4,7.9,16.5,7.9
            C207.5,15.9,214.9,12.3,214.9,7.9z"></path>
        <path style="fill:#42A5F5;" d="M198.4,111.6c-74.4,0-108.5,20.7-124,31.2c12.4,18.4,23.8,30.8,32.5,30.8c5.9,0,17.1-12.4,91.6-12.4
            s85.1,12.4,90.9,12.4c8.6,0,20.7-12.4,33.1-30.8C310.4,132.5,272.8,111.6,198.4,111.6z"></path>
        <path style="fill:#FF9800;" d="M509.8,520.9H321.1c-6.1,0-11-4.9-11-11V333.5c0-6.1,4.9-11,11-11h188.7c6.1,0,11,4.9,11,11v176.3
            C520.9,515.9,515.9,520.9,509.8,520.9z"></path>
        <path style="fill:#FFB74D;" d="M396.9,322.4h37.2v62h-37.2V322.4z"></path>
        <path style="fill:#3E2723;" d="M372.1,483.7h86.8v12.4h-86.8L372.1,483.7L372.1,483.7z"></path>
        <path style="fill:#3E2723;" d="M434.1,434.1h12.4v37.2h-12.4V434.1z"></path>
        <path style="fill:#3E2723;" d="M440.3,421.7l-18.6,24.8h37.2L440.3,421.7z"></path>
        <path style="fill:#3E2723;" d="M384.5,434.1h12.4v37.2h-12.4V434.1z"></path>
        <path style="fill:#3E2723;" d="M390.7,421.7l-18.6,24.8h37.2L390.7,421.7z"></path>
        </svg></mat-icon>
                        </div>
                        <div class="icon" title="Personal funcionario">
                            <mat-icon md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/category/FU.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 396.9 496.1" style="enable-background:new 0 0 396.9 496.1;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path style="fill:#FF9800;" d="M198.4,409.3l-62-74.4v-74.4h124v74.4L198.4,409.3z"></path>
        <path style="fill:#FFA726;" d="M334.8,186c0,13.7-11.1,24.8-24.8,24.8s-24.8-11.1-24.8-24.8s11.1-24.8,24.8-24.8
            S334.8,172.3,334.8,186 M111.6,186c0-13.7-11.1-24.8-24.8-24.8S62,172.3,62,186s11.1,24.8,24.8,24.8S111.6,199.7,111.6,186"></path>
        <path style="fill:#FFB74D;" d="M310,111.6c0-94.7-223.2-61.7-223.2,0v86.8c0,61.7,49.9,111.6,111.6,111.6S310,260.1,310,198.4V111.6
            z"></path>
        <path style="fill:#424242;" d="M198.4,0c-75.3,0-124,61.1-124,136.4v28.3L99.2,186v-62L248,74.4l49.6,49.6v62l24.8-21.6v-28
            c0-49.9-12.9-99.4-74.4-111.6L235.6,0H198.4L198.4,0z"></path>
        <path style="fill:#784719;" d="M235.6,186c0-6.8,5.6-12.4,12.4-12.4c6.8,0,12.4,5.6,12.4,12.4c0,6.8-5.6,12.4-12.4,12.4
            C241.2,198.4,235.6,192.9,235.6,186 M136.4,186c0,6.8,5.6,12.4,12.4,12.4c6.8,0,12.4-5.6,12.4-12.4c0-6.8-5.6-12.4-12.4-12.4
            S136.4,179.2,136.4,186"></path>
        <path style="fill:#CFD8DC;" d="M260.4,334.8l-62,12.4l-62-12.4C136.4,334.8,0,359.4,0,496h396.9
            C396.9,359.9,260.4,334.8,260.4,334.8"></path>
        <path style="fill:#CDFF00;" d="M186,384.5l-12.4,111.6h49.6l-12.4-111.6l12.4-12.4l-24.8-24.8l-24.8,24.8L186,384.5z"></path>
        <path style="fill:#FFFFFF;" d="M260.4,421.7h86.8v49.6h-86.8L260.4,421.7L260.4,421.7z"></path>
        </svg></mat-icon>
                        </div>
                        <div class="icon" title="Personal visitante">
                            <mat-icon md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/category/VI.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 396.9 496.1" style="enable-background:new 0 0 396.9 496.1;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path style="fill:#4CAF50;" d="M260.4,334.8h-124C136.4,334.8,0,359.4,0,496h396.9C396.9,359.9,260.4,334.8,260.4,334.8"></path>
        <path style="fill:#FF9800;" d="M198.4,409.3l-62-74.4v-74.4h124v74.4L198.4,409.3z"></path>
        <path style="fill:#FFA726;" d="M334.8,186c0,13.7-11.1,24.8-24.8,24.8c-13.7,0-24.8-11.1-24.8-24.8s11.1-24.8,24.8-24.8
            C323.8,161.2,334.8,172.3,334.8,186 M111.6,186c0-13.7-11.1-24.8-24.8-24.8S62,172.3,62,186s11.1,24.8,24.8,24.8
            S111.6,199.7,111.6,186"></path>
        <path style="fill:#FFB74D;" d="M310,111.6c0-94.7-223.2-61.7-223.2,0v86.8c0,61.7,49.9,111.6,111.6,111.6S310,260.1,310,198.4V111.6
            z"></path>
        <path style="fill:#424242;" d="M198.4,0c-75.3,0-124,61.1-124,136.4v28.3L99.2,186v-62L248,74.4l49.6,49.6v62l24.8-21.6v-28
            c0-49.9-12.9-99.4-74.4-111.6L235.6,0H198.4L198.4,0z"></path>
        <path style="fill:#784719;" d="M235.6,186c0-6.8,5.6-12.4,12.4-12.4c6.8,0,12.4,5.6,12.4,12.4c0,6.8-5.6,12.4-12.4,12.4
            C241.2,198.4,235.6,192.9,235.6,186 M136.4,186c0,6.8,5.6,12.4,12.4,12.4c6.8,0,12.4-5.6,12.4-12.4c0-6.8-5.6-12.4-12.4-12.4
            S136.4,179.2,136.4,186"></path>
        <path style="fill:#2E7D32;" d="M260.4,334.8l-62,74.4l-62-74.4c0,0-10.9,2-26.5,7.9l7.5,8l62,74.4l6.7,8V496h24.8v-62.9l6.6-8
            l62-74.4l6.7-8.2C271,336.8,260.4,334.8,260.4,334.8z"></path>
        </svg></mat-icon>
                        </div>
                        <div class="icon" title="Otro personal">
                            <mat-icon md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/category/OT.svg" role="img" aria-hidden="true"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 443.9 526.1" style="enable-background:new 0 0 443.9 526.1;" xml:space="preserve" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path style="fill:#999999;" d="M291.3,355.1c0,0-13.9,52.6-69.4,52.6s-69.4-52.6-69.4-52.6S0,381.2,0,526.1h443.9
            C443.9,381.7,291.3,355.1,291.3,355.1"></path>
        <path style="fill:#FF9800;" d="M221.9,434c-69.4,0-69.4-78.9-69.4-78.9v-78.9h138.7v78.9C291.3,355.1,291.3,434,221.9,434z"></path>
        <path style="fill:#FFA726;" d="M374.5,197.3c0,14.5-12.4,26.3-27.7,26.3c-15.3,0-27.7-11.8-27.7-26.3s12.4-26.3,27.7-26.3
            S374.5,182.7,374.5,197.3 M124.8,197.3c0-14.5-12.4-26.3-27.7-26.3s-27.7,11.8-27.7,26.3s12.4,26.3,27.7,26.3
            S124.8,211.8,124.8,197.3"></path>
        <path style="fill:#FFB74D;" d="M346.8,118.4c0-100.4-249.7-65.4-249.7,0v92.1c0,65.4,55.9,118.4,124.8,118.4
            c69,0,124.8-53,124.8-118.4L346.8,118.4L346.8,118.4z"></path>
        <path style="fill:#424242;" d="M221.9,0C137.7,0,83.2,64.8,83.2,144.7v30.1l27.7,22.6v-65.8L277.3,79l55.5,52.6v65.8l27.7-22.9
            v-29.7c0-52.9-14.4-105.4-83.2-118.4L263.5,0H221.9L221.9,0z"></path>
        <path style="fill:#784719;" d="M263.5,197.3c0-7.2,6.2-13.2,13.9-13.2c7.6,0,13.9,5.9,13.9,13.2c0,7.2-6.2,13.2-13.9,13.2
            C269.8,210.4,263.5,204.5,263.5,197.3 M152.6,197.3c0,7.2,6.2,13.2,13.9,13.2s13.9-5.9,13.9-13.2s-6.2-13.2-13.9-13.2
            S152.6,190,152.6,197.3"></path>
        <path style="fill:#01579B;" d="M221.9,434c69.4,0,93.6-51.7,96.4-71.4c-16-5.5-27-7.5-27-7.5s-13.9,52.6-69.4,52.6
            s-69.4-52.6-69.4-52.6s-11.1,1.9-27.1,7.4C128.4,382.2,152.6,434,221.9,434z"></path>
        </svg></mat-icon>
                        </div>
                    </div>
        
        
                    <div style="min-width: 120px;">
                        <div class="icon" title="Masculino">
                            <mat-icon style="color: #50a5e8;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/male.svg" role="img" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" version="1.1" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        
        <path d="M 39.171875 5.996094 L 42 8.824219 L 26.417969 24.414063 L 23.589844 21.585938 Z "></path>
        <path d="M 18 42 C 14.792969 42 11.78125 40.753906 9.515625 38.484375 C 7.246094 36.21875 6 33.203125 6 30 C 6 26.796875 7.246094 23.78125 9.515625 21.515625 C 11.78125 19.246094 14.792969 18 18 18 C 21.207031 18 24.21875 19.246094 26.484375 21.515625 C 31.164063 26.195313 31.164063 33.808594 26.484375 38.484375 C 24.21875 40.753906 21.203125 42 18 42 Z M 18 22 C 15.863281 22 13.855469 22.832031 12.34375 24.34375 C 9.222656 27.460938 9.222656 32.539063 12.34375 35.65625 C 13.855469 37.167969 15.863281 38 18 38 C 20.136719 38 22.144531 37.167969 23.65625 35.65625 C 26.777344 32.539063 26.777344 27.460938 23.65625 24.34375 C 22.144531 22.832031 20.136719 22 18 22 Z "></path>
        <path d="M 25 6 L 42 6 L 42 10 L 25 10 Z "></path>
        <path d="M 38 6 L 42 6 L 42 23 L 38 23 Z "></path>
        </svg></mat-icon>
                        </div>
                        <div class="icon" title="Femenino">
                            <mat-icon style="color: #ec748b;" md-svg-src="https://s3.amazonaws.com/analityco-acceso/assets/icons/common/female.svg" role="img" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" version="1.1" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M 26 25.898438 C 28.398438 25.5 30.699219 24.398438 32.5 22.601563 C 34.800781 20.300781 36 17.300781 36 14.101563 C 36 10.898438 34.800781 7.898438 32.5 5.601563 C 30.199219 3.300781 27.199219 2.101563 24 2.101563 C 20.800781 2.101563 17.800781 3.300781 15.5 5.601563 C 13.199219 7.898438 12 10.898438 12 14.101563 C 12 20 16.300781 25 22 25.898438 L 22 34 L 14 34 L 14 38 L 22 38 L 22 46 L 26 46 L 26 38 L 34 38 L 34 34 L 26 34 Z M 16 14.101563 C 16 12 16.800781 10 18.300781 8.398438 C 19.800781 6.898438 21.800781 6.101563 24 6.101563 C 28.398438 6.101563 32 9.699219 32 14.101563 C 32 16.199219 31.199219 18.199219 29.699219 19.800781 C 28.199219 21.300781 26.199219 22.101563 24 22.101563 C 19.601563 22.101563 16 18.5 16 14.101563 Z "></path>
        </svg></mat-icon>
                        </div>
                    </div>
        
        
                    <div style="min-width: 420px;">
                        <div class="icon">
                            <div style="font-size: 16px;margin-left: -5px;" title="Menor a 18"> <strong> &lt;18 </strong> </div>
                        </div>
                        <div class="icon">
                            <div style="font-size: 16px;margin-left: -5px;" title="Menor a 30"> <strong> &lt;30 </strong> </div>
                        </div>
                        <div class="icon">
                            <div style="font-size: 16px;margin-left: -5px;" title="Menor a 40"> <strong> &lt;40 </strong> </div>
                        </div>
                        <div class="icon">
                            <div style="font-size: 16px;margin-left: -5px;" title="Menor a 50"> <strong> &lt;50 </strong> </div>
                        </div>
                        <div class="icon">
                            <div style="font-size: 16px;margin-left: -5px;" title="Menor a 60"> <strong> &lt;60 </strong> </div>
                        </div>
                        <div class="icon">
                            <div style="font-size: 16px;margin-left: -5px;" title="Menor a 70"> <strong> &lt;70 </strong> </div>
                        </div>
                        <div class="icon">
                            <div style="font-size: 16px;margin-left: -5px;" title="Igual o mayor a 70"> <strong> ≥70 </strong> </div>
                        </div>
                    </div>
        
                </div>
                <div id="SummaryContent" class="content" (scroll)="onScrollSummaryContent($event)" #scrollSummaryContent>
                    <table>
                        <tbody>
                            <tr class="content" *ngFor="let item of (flagChangeFilter === true ? dataFilter : data)">
                                <!-- se quitan  -->
                                <!-- <td class="">{{item.Inactive + item.Active}}</td>
                                <td class="border-l">{{item.Inactive}}</td> -->
                                <td class="">{{item.Active}}</td>
                                <td class="border-l">{{item.Expiry}}</td>
                                <!-- <td class="border-r">{{item.Active}}</td>
                                <td>{{item.Expiry}}</td> -->
        
                                <!-- <td class="border-l">{{item?.Variaciones?.Activated }}</td>
                                <td>{{item?.Variaciones?.Reactivated }}</td>
                                <td>{{item?.Variaciones?.Inactivated }}</td> -->
        
                                <td class="border-l">{{item.Verify}}</td>
                                <td>{{item.Inspect}}</td>
        
                                <td class="border-l">{{item.NotAssistant}}</td>
                                <td>{{item.Assistant}} </td>
                                <td>{{item.HoraEntrada !== null && item.HoraEntrada !== undefined? item.HoraEntrada.substr(0,5): '' }}</td>
                                <td>{{item.HoraSalida !== null && item.HoraSalida !== undefined? item.HoraSalida.substr(0,5): '' }}</td>
        
                                <td class="border-l">{{item.Cumple}}</td>
                                <td>{{item.NoCumple}}</td>
        
                                <td class="border-l"> {{item.AD}}</td>
                                <td> {{item.AS}}</td>
                                <td> {{item.CO}}</td>
                                <td> {{item.PR}}</td>
                                <td> {{item.FU}}</td>
                                <td> {{item.VI}}</td>
                                <td> {{item.OT}}</td>
        
                                <td class="border-l">{{item.SexMale}}</td>
                                <td>{{item.SexFemale}}</td>

                                <td class="border-l">{{item.Menor18}}</td>
                                <td>{{item.Menor30}}</td>
                                <td>{{item.Menor40}}</td>
                                <td>{{item.Menor50}}</td>
                                <td>{{item.Menor60}}</td>
                                <td>{{item.Menor70}}</td>
                                <td>{{item.Menor80}}</td>
        
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="footer">
                    <div class="foot" style="background: #E1F5FE">
        
                        <div style="min-width: 120px !important;" class="cell">
                            <!-- <div>{{summaryData('Active', 'Inactive') || 0 }}</div>
                            <div class="border-l">{{summaryData('Inactive') || 0 }}</div> -->
                            <div class="border-r"style="min-width: 61px !important;">{{summaryData('Active') || 0 }}</div>
                            <div style="min-width: 59px !important;">{{summaryData('Expiry') || 0 }}</div>
                        </div>
        
                        <!-- <div style="min-width: 180px;" class="cell">
                            <div>{{ summaryData('Activated', 'Variaciones') }}</div>
                            <div>{{ summaryData('Reactivated','Variaciones') }}</div>
                            <div style="min-width: 59px !important;">{{ summaryData('Inactivated', 'Variaciones') }}</div>
                        </div> -->
        
                        <div style="min-width: 120px;" class="cell">
                            <div>{{ summaryData('Verify') || 0 }}</div>
                            <div style="min-width: 59px !important;">{{ summaryData('Inspect') || 0 }}</div>
                        </div>
        
                        <div style="min-width: 240px;" class="cell">
                            <div>{{summaryData('NotAssistant') || 0 }}</div>
                            <div>{{summaryData('Assistant') || 0 }}</div>
                            <div>{{ '' | date: 'HH:mm' }}</div>
                            <div style="min-width: 59px !important;">{{ '' | date: 'HH:mm' }}</div>
                        </div>
        
                        <div style="min-width: 120px;" class="cell">
                            <div>{{summaryData('Cumple') || 0 }}</div>
                            <div style="min-width: 59px !important;">{{summaryData('NoCumple') || 0 }}</div>
                        </div>
        
                        <div style="min-width: 420px;" class="cell">
                            <div>{{summaryData('AD') || 0 }}</div>
                            <div>{{summaryData('AS') || 0 }}</div>
                            <div>{{summaryData('CO') || 0 }}</div>
                            <div>{{summaryData('PR') || 0 }}</div>
                            <div>{{summaryData('FU') || 0 }}</div>
                            <div>{{summaryData('VI') || 0 }}</div>
                            <div style="min-width: 59px !important;">{{summaryData('OT') || 0 }}</div>
                        </div>
        
                        <div style="min-width: 120px;" class="cell">
                            <div>{{summaryData('SexMale') || 0 }}</div>
                            <div style="min-width: 59px !important;">{{summaryData('SexFemale') || 0 }}</div>
                        </div>
        
                        <div style="min-width: 420px;" class="cell">
                            <div>{{summaryData('Menor18') || 0 }}</div>
                            <div>{{summaryData('Menor30') || 0 }}</div>
                            <div>{{summaryData('Menor40') || 0 }}</div>
                            <div>{{summaryData('Menor50') || 0 }}</div>
                            <div>{{summaryData('Menor60') || 0 }}</div>
                            <div>{{summaryData('Menor70') || 0 }}</div>
                            <div style="min-width: 59px !important;">{{summaryData('Menor80') || 0 }}</div>
                        </div>
        
                    </div>
                    <div class="totals" style="background: #039BE5; color:#FFFFFF">
                        <div style="min-width: 120px;">{{ 'Activos: ' + summaryData('Active') || 0 }}</div>
                        <!-- <div style="min-width: 180px;">{{ 'Total: '+ (summaryData('Activated', 'Variaciones')+ summaryData('Reactivated', 'Variaciones')+ summaryData('Inactivated', 'Variaciones')) }}</div> -->
                        <div style="min-width: 120px;"></div>
                        <div style="min-width: 240px;">{{ 'Asistentes: '+ summaryData('Assistant') }}</div>
                        <div style="min-width: 120px;">{{ 'Total: ' +(summaryData('Cumple')+ summaryData('NoCumple')) }}</div>
                        <div style="min-width: 420px;">{{ 'Total: ' +(summaryData('AD')+ summaryData('AS')+summaryData('CO')+summaryData('PR')+summaryData('FU')+summaryData('VI'))}}</div>
                        <div style="min-width: 120px;">{{ 'Total: ' +(summaryData('SexMale')+ summaryData('SexFemale'))}}</div>
                        <div style="min-width: 420px;">{{ 'Total: ' +(summaryData('Menor18')+summaryData('Menor30')+summaryData('Menor40')+summaryData('Menor50')+summaryData('Menor60')+summaryData('Menor70')+summaryData('Menor80'))}}</div>
                    </div>
                </div>
            </div>
    </div> 
</div>
    
    
