import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import {CommonOptions, Projects} from '../../../interfaces/interfaces';
import { CommonService } from '../../../services/modules/common.service';
import { SnackBarService } from '../../../services/utils/snackBar.service';
import { DialogService } from '../../../services/utils/dialog.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilService } from 'src/app/core/services/utils/utils.service';
import { ContractService } from 'src/app/core/services/modules/contract.service';
import { FechasService } from '../../../services/utils/fechas.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatChipInputEvent } from '@angular/material/chips';
import { FilterMultipleService } from 'src/app/core/services/utils/filter-multiple.service';
import { CommonMetodService } from 'src/app/core/services/utils/commonMetod.service';
import {Contract} from '../../../models/Contract/Contract';

export interface DialogData {
  message: string;
  CompanyInfoParentId: any;
}

export interface viewshowChipsActividadEstapa {
  Name: string;
  Id: number;
  StartDate: string;
  FinishDate: string;
}

export interface EmployerManger{
  CompanyInfoParentId: string;
  DocumentNumber: string;
  DocumentType: string;
  Logo: string;
  Name: string;
}

@Component({
  selector: 'app-dialog-create-contract',
  templateUrl: './dialog-create-contract.component.html',
  styleUrls: ['./dialog-create-contract.component.scss']
})
export class DialogCreateContractComponent implements OnInit {
  maxDate: any;
  digito: string;
  s3Bucket: string;
  contract: Contract;
  documentType: string;
  CompanyInfoParentId: any;
  formContract: FormGroup;
  projectSelected: Projects;
  formVincularProyect: FormGroup;

  controlCompany = new FormControl();
  controlCategoria = new FormControl();
  minDateNow = this.fechasService.getDateNow();
  controlSearchAllEmployer = new FormControl();
  controleconomicActivity = new FormControl();

  documentNumber = '';
  maxDateOfProyect = '';
  typeContractState = '';
  nameProyectSelected = '';
  flagValidNit = true;
  disableFormProject = false;

  companies: any[] = [];
  projects: Projects[];
  allEmployers: any[] = [];
  selectedLogo: any[] = [];
  contractTypes: any[] = [];
  economicActivityObs: any[];
  filtesDocuments: any[] = [];
  economicActivity: any[] = [];
  companySelected: any [] = [];
  contractProjects: any[] = [];
  employerSelected: any [] = [];
  formImageContract: any[] = [];
  companiesAsociados: any[] = [];
  permissionsCompany: string[] = [];
  viewValueseconomicActivity: string[] = [];
  companyDocumentType: CommonOptions[] = [];
  personalType: CommonOptions[] = [];
  selectedIdeconomicActivity: viewshowChipsActividadEstapa[] = [];

  maxCaracter = 9;
  joinCompaniesCount = 0;

  @ViewChild('InputControleconomicActivity') InputControleconomicActivity: ElementRef<HTMLInputElement>;
  @ViewChild('autoeconomicActivity') matAutocompleteeconomicActivity: MatAutocomplete;
  @ViewChild('clickHoverMenuTriggereconomicActivity') clickHoverMenuTriggereconomicActivity: MatMenuTrigger;

  visible = true;
  selectable = true;
  removable = true;

  pasoSelectedProyet = true;

  constructor(
    public dialogRef: MatDialogRef<DialogCreateContractComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonService: CommonService,
    private snackBService: SnackBarService,
    private dialogService: DialogService,
    private router: Router,
    private utilService: UtilService,
    private contractService: ContractService,
    private fb: FormBuilder,
    public fechasService: FechasService,
    private filters: FilterMultipleService,
    public commonMetodService: CommonMetodService
  ) {
    this.CompanyInfoParentId = this.data.CompanyInfoParentId;
    this.getProyectsJoinCompany(this.CompanyInfoParentId);
    this.controleconomicActivity.valueChanges.subscribe(value => {
      if (value !== undefined) {
        value !== '' ?
        this.economicActivityObs = this._filterActivityEconom(value) :
        this.economicActivityObs = this.economicActivity.slice();
      }
    });
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    this.formContract = this.fb.group({
      ContractTypeId:  new FormControl('', Validators.required),
      ContractNumber:  new FormControl('', Validators.required),
      ContractReview:  new FormControl('', Validators.required),
      FormImageId:  new FormControl('', Validators.required),
      EmployerId: new FormControl('', Validators.required),
    });
    this.formVincularProyect = this.fb.group({
      ProjectId: new FormControl('', Validators.required),
      StartDate: new FormControl(new Date(), Validators.required),
      FinishDate: new FormControl(new Date(), Validators.required)
    });
    setTimeout(() => {
      this.getServices();
      this.formVincularProyect.get('StartDate').setValue(this.minDateNow);
      this.formVincularProyect.get('FinishDate').setValue(this.minDateNow);
    }, 300);
  }

  /**
   * Permite obtener los proyectos para asociar al contrato
   */
   getProyectsJoinCompany( CompanyInfoId: string ): void {
    this.contractService.getProyectsJoinCompany( CompanyInfoId ).subscribe((data: any) => {
      this.contractProjects = data.filter(x => x.IsActive === true);
      if (this.contractProjects.length === 1) {
        this.formVincularProyect.get('ProjectId').setValue(this.contractProjects[0].Id);
        this.changeSelectedProyect(this.contractProjects[0].Id);
      }
    });
  }


  changeMaxCaracter(e) {
    if (e.value === 'NIT') {
      this.maxCaracter = 9;
      this.documentNumber = '';
    } else {
      this.maxCaracter = 11;
      this.documentNumber = '';
    }
  }

  onChangeTypeContract(e) {
    const typeContract: any = this.getTypeContrat(e);
    this.typeContractState = typeContract.Value;

    // Para calcular la categoria de persona
    const dataCategoria = this.personalType.find(x => x.Value === typeContract.Value);
    dataCategoria !== undefined ? this.controlCategoria.setValue(dataCategoria.Id) : '';

    // Selected Company auto
    this.companySelected = [];
    const dataCompany = this.typeContractState === 'AS' ? this.companiesAsociados.find(x => x.Id === this.CompanyInfoParentId) :
      this.companies.find(x => x.Id === this.CompanyInfoParentId);
    dataCompany !== undefined ? this.companySelected.push(dataCompany) : '';
    dataCompany !== undefined ? this.controlCompany.setValue(dataCompany.Id) : '';
    // Se selecciona el mismo employerId en contrato administrativo y asociado
    if ((this.typeContractState === 'AS' || this.typeContractState === 'AD') && dataCompany !== undefined)  {
      this.formContract.get('EmployerId').setValue(dataCompany.Id);
    } else {
      this.formContract.get('EmployerId').setValue('');
    }


    // FLujo para ver datos dependiendo del tipo de contrato
    this.contractService.getCountContract(this.CompanyInfoParentId).subscribe(restCount => {
      this.formContract.get('ContractNumber').setValue('' + typeContract.Value + '-' +
        this.fechasService.getDateNumerFechaContractNow() + '-' + restCount.Count + '');
      this.formContract.get('ContractReview').setValue('Contrato ' + typeContract.Description.toLocaleLowerCase() + '');
      const filterFormImgData = this.formContract.get('ContractReview').value.replace('Contrato para un ', '');
      const finalfilterFormImgData = filterFormImgData.replace('Contrato para ', '');

      if (finalfilterFormImgData.includes('administrativo')){
        const data = this.formImageContract.filter(arraydata => {
          return arraydata.Id === 49;
        });
        if (data !== undefined) {
          this.selectedLogo = [];
          this.selectedLogo.push(data[0]);
          this.formContract.get('FormImageId').setValue(this.selectedLogo[0].Id);
        }
      }else{
        const data = this.formImageContract.filter(arraydata => {
          return arraydata.Tags?.find(data => {
            return data?.toLowerCase().includes(finalfilterFormImgData.toLowerCase()) && !data?.toLowerCase().includes('registro');
          });
        });
        if (data !== undefined) {
          this.selectedLogo = [];
          this.selectedLogo.push(data[0]);
          this.formContract.get('FormImageId').setValue(this.selectedLogo[0].Id);
        }
      }
    });
    this.deleteEmployerSelected(dataCompany);
  }


  // Devuelve el tipo de cotrato
  getTypeContrat(Id): any {
    let type;
    const data = this.contractTypes.find(x => x.Id === Id);
    data !== undefined ? type = data : type = null;
    return type;
  }


  hasPermissionContractType(): any {
     const validateContractTypes = [];
     this.contractTypes.forEach( contract => {
       if (this.exitPermissionCompany('contracts.addadmin') && contract.Value === 'AD'){
         validateContractTypes.push(contract);
       }else if (this.exitPermissionCompany('contracts.addcontractor') && contract.Value === 'CO'){
          validateContractTypes.push(contract);
       }else if (this.exitPermissionCompany('contracts.addprovider') && contract.Value === 'PR'){
         validateContractTypes.push(contract);
       }else if (this.exitPermissionCompany('contracts.addofficial') && contract.Value === 'FU'){
         validateContractTypes.push(contract);
       }
     });
     return validateContractTypes;
  }

    exitPermissionCompany(permiso: string): boolean {
      let exit = false;
      const permisionsUserInfo  = this.permissionsCompany.find(x => x === permiso);
      permisionsUserInfo !== undefined ? exit = true : exit = false;
      return exit;
    }

  // Devuelve el tipo de cotrato
  onSelectedLogo(Id) {
    const data = this.formImageContract.find(x => x.Id === Id);
    this.selectedLogo = [];
    data !== undefined ? this.selectedLogo.push(data) : '';
  }

  onSelectedAllEmployer(Id) {
    const data = this.allEmployers.find(x => x.Id === Id);
    this.employerSelected = [];
    data !== undefined ? this.employerSelected.push(data) : '';
    data !== undefined ? this.formContract.get('EmployerId').setValue(data.Id) : '';
  }

  onSelectedCompany(Id) {
    const data = this.typeContractState === 'AS' ? this.companiesAsociados.find(x => x.Id === Id) : this.companies.find(x => x.Id === Id);
    this.companySelected = [];
    data !== undefined ? this.companySelected.push(data) : '';
    data !== undefined ? this.controlCompany.setValue(data.Id) : '';
  }


  search() {
    this.validateNit();
    if ((this.documentType === 'NIT') && (this.documentNumber === '' || this.digito === undefined || this.flagValidNit === false)) {
      this.snackBService.openSnackBar('Compruebe los datos ingresados', 'X', 4000);
    } else {
      // Anterior api change 19/08/2022 porque no trae todas las empresas
      // this.commonService.getSearchEmployerDocumentTypeName(this.documentType, this.documentNumber).subscribe(employer => {
      this.commonService.getSearchAllEmployersWitchAdministrador(this.CompanyInfoParentId, this.documentNumber).subscribe(employer => {
        this.employerSelected = [];
        this.employerSelected.push(employer);
        this.formContract.get('EmployerId').setValue(employer.Id);
      }, (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.dialogService.openDialogConfirm(
            `Lo sentimos, no hay una empresa registrada con este número. Desea crearla?`).afterClosed().subscribe(rest => {
            if (rest !== false) {
              this.dialogService.openDialogCreateEmployer('', this.documentNumber, this.documentType, '', (this.digito === '' ? '' :
                this.digito), this.CompanyInfoParentId).afterClosed().subscribe(restDialog => {
                if (restDialog !== undefined) {
                  // Anterior api change 19/08/2022 porque no trae todas las empresas
                  // this.commonService.getSearchEmployerDocumentTypeName(this.documentType, this.documentNumber).subscribe(employer => {
                  this.commonService.getSearchAllEmployersWitchAdministrador(this.CompanyInfoParentId,
                    this.documentNumber).subscribe(employer => {
                    this.employerSelected = [];
                    this.employerSelected.push(employer);
                    this.formContract.get('EmployerId').setValue(employer.Id);
                  });
                }
              });
            }
          });
        }else if (error.status === 424) {
          this.dialogService.openDialogConfirm(
            `Lo sentimos, no hay una empresa registrada con este número. Desea crearla?`).afterClosed().subscribe(rest => {
            if (rest !== false) {
              this.dialogService.openDialogCreateEmployer(error.error, this.documentNumber, this.documentType, '',
                (this.digito === '' ? '' : this.digito), this.CompanyInfoParentId).afterClosed().subscribe(employer => {
                if (employer !== undefined) {
                  this.commonService.getSearchAllEmployersWitchAdministrador(this.CompanyInfoParentId,
                    this.documentNumber).subscribe(employer => {
                    this.employerSelected = [];
                    this.employerSelected.push(employer);
                    this.formContract.get('EmployerId').setValue(employer.Id);
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  changeSelectedProyect(e) {
    this.contractProjects.forEach(x => {
      if (x.Id === e) {
        this.nameProyectSelected = x.Name;
        this.joinCompaniesCount = x.JoinCompaniesCount;
      }
    });
    this.formContract.reset();
    this.selectedLogo = [];
    this.formContract.get('FormImageId').setValue('');
    this.companySelected = [];
    this.controlCompany.setValue('');
    this.employerSelected = [];
    this.formContract.get('EmployerId').setValue('');
    this.formContract.get('ContractTypeId').setValue('');
    this.controlSearchAllEmployer.setValue('');
    this.documentNumber = '';
    this.contractService.getProjectStageEtapas(e).subscribe((rest: any) => {
      this.economicActivity = rest.filter(x => x.IsActive === true);
      this.economicActivityObs = rest.filter(x => x.IsActive === true);

      this.selectedIdeconomicActivity = [];
      this.viewValueseconomicActivity = [];
      // Auto select Etapa cuando solo exite una etapa
      if (this.economicActivity.length === 1) {
        this.selectedIdeconomicActivity.push(
          { Id: this.economicActivity[0].Id,
            Name: this.economicActivity[0].Name,
            FinishDate: this.economicActivity[0].FinishDate,
            StartDate: this.economicActivity[0].StartDate
          });
        this.viewValueseconomicActivity.push(this.economicActivity[0].Name);
        this.formVincularProyect.get('StartDate').setValue(this.economicActivity[0].StartDate);
        this.formVincularProyect.get('FinishDate').setValue(this.economicActivity[0].FinishDate);
      }
    });
    this.contractService.getContractType().subscribe(contractType => {
      if (this.joinCompaniesCount === 0) {
        this.contractTypes = contractType.filter(x => x.Value !== 'AS');
        this.setContractTypeByPermissions();
      } else {
        this.contractTypes = contractType;
        this.setContractTypeByPermissions();
      }
    });
  }

  validateNit() {
    if ((this.documentType === 'NIT') && (this.documentNumber !== '' && this.digito !== undefined)) {
      if (parseInt(this.digito) !== this.utilService.calcularDigito(this.documentNumber)) {
        this.flagValidNit = false;
      } else {
        this.flagValidNit = true;
      }
    }
  }

  getServices() {
    this.commonService.getUserInfo().subscribe(userinfo => {
      this.permissionsCompany = userinfo.Companies.find(x => x.Id === this.CompanyInfoParentId).Permissions;
    });

    this.commonService.getCommonOptions('PersonalType').subscribe((data: CommonOptions[]) => {
      this.personalType = data.sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.commonService.getCommonOptions('CompanyDocumentType').subscribe((data: CommonOptions[]) => {
      this.companyDocumentType = data.filter(documents => documents.Value === 'CC' || documents.Value === 'NIT')
            .sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
      this.documentType = 'NIT';
    });
    this.contractService.getFormImageContract().subscribe(restImagenes => {
      this.formImageContract = restImagenes;
    });
    this.contractService.getContractJoinCompanies(this.CompanyInfoParentId).subscribe(restEmployer => {
      if (restEmployer !== undefined && restEmployer.length > 0){
        this.companies = restEmployer;
        this.companiesAsociados = restEmployer.filter(x => x.Id !== this.CompanyInfoParentId);
        if (this.companiesAsociados.length === 0) {
          this.contractService.getContractType().subscribe(contractType => {
            this.contractTypes = contractType.filter(x => x.Value !== 'AS');
            this.setContractTypeByPermissions();
          });
        } else {
          this.contractService.getContractType().subscribe(contractType => {
            this.contractTypes = contractType;
            this.setContractTypeByPermissions();
          });
        }
      } else {
        this.contractService.getContractType().subscribe(contractType => {
          this.contractTypes = contractType.filter(x => x.Value !== 'AS');
          this.setContractTypeByPermissions();
        });
      }
    });
    this.commonService.getAllEmployersCompanyId(this.CompanyInfoParentId).subscribe((data: any) => {
      this.allEmployers = data.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
    });

  }

  getImg(logo) {
    const img = (logo === null || logo === '' || logo === undefined ? 'assets/images/panteon.png' : logo);
    return img;
  }

  getImgEmployer(logo) {
    const img = (logo === null || logo === '' || logo === undefined ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg' : logo);
    return img;
  }

  setContractTypeByPermissions(){
    this.contractTypes = this.hasPermissionContractType();
  }



  // Metodos para multi chips selected

  private _filterActivityEconom(value: string): any[] {
    return this.economicActivity.filter(x => {
      return (this.filters.exitFilter(x.Name , value));
    });
  }

  addActivity(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.viewValueseconomicActivity.push(value);
    }
    this.controleconomicActivity.setValue(null);
  }

  removeActivity(item: any): void {
    const index = this.viewValueseconomicActivity.indexOf(item);
    for (let i = 0; i < this.selectedIdeconomicActivity.length; i++) {
      if (this.selectedIdeconomicActivity[i].Name === item) {
        this.selectedIdeconomicActivity.splice(index, 1);
        if (i === this.selectedIdeconomicActivity.length - 1) {
          this.formVincularProyect.get('FinishDate').setValue('');
          this.changeDateProjet();
        }
      }
    }
    if (index >= 0) {
      this.viewValueseconomicActivity.splice(index, 1);
      this.formVincularProyect.get('FinishDate').setValue('');
      this.changeDateProjet();
      this.formContract.get('EmployerId').setValue('');
      this.controlSearchAllEmployer.setValue('');
      this.employerSelected = [];
      this.formContract.reset();
      this.selectedLogo = [];
      this.formContract.get('FormImageId').setValue('');
      this.companySelected = [];
      this.controlCompany.setValue('');
      this.documentNumber = '';
    }
  }

  selectedActivity(event: MatAutocompleteSelectedEvent): void {
    const exitCaja = this.selectedIdeconomicActivity.find(x => x.Id === event.option.value);
    if (this.selectedIdeconomicActivity.length === 0 || exitCaja === undefined) {
      this.selectedIdeconomicActivity.push(
              { Id: event.option.value,
                Name: event.option.viewValue,
                FinishDate: this.getDateProjet(event.option.value).FinishDate,
                StartDate: this.getDateProjet(event.option.value).StartDate
              });
      this.viewValueseconomicActivity.push(event.option.viewValue);
      this.changeDateProjet();
      this.InputControleconomicActivity.nativeElement.value = '';
      this.InputControleconomicActivity.nativeElement.disabled = true;
      this.controleconomicActivity.setValue('');
      this.InputControleconomicActivity.nativeElement.disabled = false;
    } else {
      this.InputControleconomicActivity.nativeElement.value = '';
      this.InputControleconomicActivity.nativeElement.disabled = true;
      this.changeDateProjet();
      this.controleconomicActivity.setValue('');
      this.InputControleconomicActivity.nativeElement.disabled = false;
    }
  }

  changeDateProjet() {
    let startDate = this.formVincularProyect.get('StartDate').value;
    let finishDate = this.formVincularProyect.get('FinishDate').value;
    if (this.selectedIdeconomicActivity.length === 0) {
      this.formVincularProyect.get('StartDate').setValue(new Date());
      this.formVincularProyect.get('FinishDate').setValue(new Date());
    }
    this.selectedIdeconomicActivity.forEach((value, index) => {
      if (this.selectedIdeconomicActivity.length === 1) {
        this.maxDateOfProyect = value.FinishDate;
        this.formVincularProyect.get('StartDate').setValue(value.StartDate);
        this.formVincularProyect.get('FinishDate').setValue(value.FinishDate);
      } else {
        if (value.FinishDate > finishDate || value.FinishDate === finishDate) {
          finishDate = value.FinishDate;
        }
        if (value.StartDate < startDate || value.StartDate === startDate) {
          startDate = value.StartDate;
        }
        this.maxDateOfProyect = finishDate;
        if (index === this.selectedIdeconomicActivity.length - 1) {
          this.formVincularProyect.get('StartDate').setValue(startDate);
          this.formVincularProyect.get('FinishDate').setValue(finishDate);
        }
      }
    });
  }

  getDateProjet(id) {
    const data = this.economicActivity.find(x => x.Id === id);
    return data;
  }


  deleteEmployerSelected(dataCompany?) {
    this.formContract.get('EmployerId').setValue('');
    this.controlSearchAllEmployer.setValue('');
    this.employerSelected = [];
    if ((this.typeContractState === 'AS' || this.typeContractState === 'AD') && dataCompany !== undefined)  {
      this.formContract.get('EmployerId').setValue(dataCompany.Id);
    }
  }

  deleteCompanySelected() {
    this.controlCompany.setValue('');
    this.companySelected = [];
  }

  deleteLogoSelected() {
    this.selectedLogo = [];
    this.formContract.get('FormImageId').setValue('');
  }

  save() {
    const model = {
      CompanyInfoId: this.controlCompany.value,
      ContractNumber: this.formContract.get('ContractNumber').value,
      ContractReview: this.formContract.get('ContractReview').value,
      ContractTypeId: this.formContract.get('ContractTypeId').value,
      EmployerId: this.formContract.get('EmployerId').value,
      FormImageId: this.formContract.get('FormImageId').value,
      PersonalTypeId: this.controlCategoria.value,
      AgeMax: 99,
      AgeMin: 18
    };
    // Valida si el contrato exite
    this.contractService.getContractValidateExist(this.CompanyInfoParentId,
      this.formContract.get('ContractNumber').value).subscribe(restContractCreate => {
      if (restContractCreate?.IsContract === false) {
        // Crea el contrato
        this.contractService.getHasContractorProject(this.formVincularProyect.get('ProjectId').value,
          this.formContract.get('EmployerId').value).subscribe( (restHasContractor: any) => {
            if (restHasContractor?.Result === true) {
              this.dialogService.openDialogConfirm(`La empresa ${this.employerSelected.length > 0 ? this.employerSelected[0]?.Name : this.data.message} tiene un contrato en el proyecto ${this.nameProyectSelected}`, true, true, 'NO', 'SI', 'Desea crear otro contrato en este proyecto?').afterClosed().subscribe(x => {
                if (x !== false) {
                  this.contractService.postCreateContract(model).subscribe((restCreateContract: any) => {
                    // Asocia el proyecto al contrato
                    const projectStages: number[] = [];
                    this.selectedIdeconomicActivity.forEach(selectedCaja => {
                      projectStages.push(selectedCaja.Id);
                    });
                    const model = {
                      ContractId: restCreateContract.Id,
                      FinishDate: this.formVincularProyect.get('FinishDate').value,
                      ProjectId: this.formVincularProyect.get('ProjectId').value,
                      ProjectStages: projectStages,
                      StartDate:  this.formVincularProyect.get('StartDate').value
                    };
                    this.contractService.postContractProject(restCreateContract.Id, model, true).subscribe(rest => {
                      // PUT FILE
                      this.saveClose(restCreateContract.Id);
                    }, (error: HttpErrorResponse) => {
                        this.saveClose(restCreateContract.Id);
                    });
                  });
                } else {
                  this.dialogRef.close();
                }
              });
            } else {
              this.contractService.postCreateContract(model).subscribe((restCreateContract: any) => {
                // Asocia el proyecto al contrato
                const projectStages: number[] = [];
                this.selectedIdeconomicActivity.forEach(selectedCaja => {
                  projectStages.push(selectedCaja.Id);
                });
                const model = {
                  ContractId: restCreateContract.Id,
                  FinishDate: this.formVincularProyect.get('FinishDate').value,
                  ProjectId: this.formVincularProyect.get('ProjectId').value,
                  ProjectStages: projectStages,
                  StartDate:  this.formVincularProyect.get('StartDate').value
                };
                this.contractService.postContractProject(restCreateContract.Id, model, true).subscribe(rest => {
                    this.saveClose(restCreateContract.Id);
                }, (error: HttpErrorResponse) => {
                    this.saveClose(restCreateContract.Id);
                });
              });
            }
        });
      } else if (restContractCreate?.IsContract === true){
        this.dialogService.openDialogConfirm(`El número de contrato ya existe`, false , true, 'CERRAR').afterClosed().subscribe(x => {});
      } else {

      }
    });
  }

  saveClose(idCreate) {
    if (this.filtesDocuments.length !== 0) {
      for (let i = 0; i < this.filtesDocuments.length; i++) {
        const formData = new FormData();
        formData.append('File', this.filtesDocuments[i]);
        this.contractService.putContractUpdateFile(idCreate, formData).subscribe((restUpdate: any) => {
          if (i === this.filtesDocuments.length - 1) {
            this.snackBService.openSnackBar('Contrato creado correctamente', 'X', 4000);
            this.router.navigate(['admin/contract/contract', idCreate]);
            this.dialogRef.close();
          }
        });
      }
    } else {
      this.snackBService.openSnackBar('Contrato creado correctamente', 'X', 4000);
      this.router.navigate(['admin/contract/contract', idCreate]);
      this.dialogRef.close();
    }
  }

  validFormContract(): boolean {
    let valid = false;
    if (this.formContract.status === 'VALID' && this.formVincularProyect.status === 'VALID' && this.controlCompany.value !== null &&
      this.controlCompany.value !== '' && this.viewValueseconomicActivity.length > 0) {
      valid = true;
    } else {
      valid = false;
    }
    return valid;
  }

  calculateIfDateIsGreaterThanToday(date): string {
    const selectedDate: Date = new Date(date);
    const today: Date = new Date();

    if (selectedDate && selectedDate > today) {
      return 'DateRed';
    } else {
      return '';
    }
  }

  calculateIfDateIsGreaterThanTodayFlag(date): boolean {
    const selectedDate: Date = new Date(date);
    const today: Date = new Date();
    let flag = false;
    flag = selectedDate && selectedDate > today;
    return flag;
  }

  changeDateFinishDateContractTime(date, isFinishDate) {
    if (date !== undefined && date !== '' && date !== null){
      if (isFinishDate === true) {
        this.formVincularProyect.get('FinishDate').setValue(date);
      } else {
        this.formVincularProyect.get('StartDate').setValue(date);
      }
    }
  }

  isDisabledFieldProject(): boolean{
    let flagDisabled = false;
    if (this.contract?.IsRegister === true){
      flagDisabled = true;
    }else{
      flagDisabled = this.disableFormProject;
    }
    return flagDisabled;
  }

  getCalculateStateDateWithHORA(date, id?): string {
    let response = '';
    response = this.fechasService.calculateStateDateWithHORA(date, id);
    return response;
  }

  getIsOutDate(date, id?): boolean{
    let response = false;
    if (this.fechasService.calculateStateDateWithHORA(date, id) === 'DateRed'){
      response = true;
    }else if (this.fechasService.calculateStateDateWithHORA(date, id) === 'DateOrange'){
      response = true;
    }
    return response;
  }

  getCalculateStateDateAndHourDescription(date, id?): string{
    let response = '';
    if (this.fechasService.calculateStateDateWithHORA(date, id) === 'DateRed'){
      response = 'INACTIVO';
    }else if (this.fechasService.calculateStateDateWithHORA(date, id) === 'DateOrange'){
      response = 'POR VENCER';
    }
    return response;
  }

  getCalculateStateDateString(date, id?): string {
    let response = '';
    response = this.fechasService.calculateStateDate(date, id) + '-string';
    return response;
  }


}

