<div class="box">
    <div class="actions-dialog">
        <button mat-button class="btn-cancelar" (click)="close()" >CANCELAR</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" *ngIf="this.contractsSelected.length !== 0" (click)="save(false)">GUARDAR</button>
    </div>
    <div class="search-paginator" [ngClass]="production === false ? 'color-search-paginator-pruebas': 'color-search-paginator-pro'">
        <div class="search">
            <input class="input-search"
                    type="text"
                    placeholder="Buscar..."
                    (keyup)="searchFilterPagination($event.target.value)">
        </div>
        <mat-paginator  *ngIf="arreglo"
                        [length]="arreglo.length"
                        [pageSize]="32"
                        [pageSizeOptions]="[15,32]"
                        (page)="searchFilterPagination(filterListValue)"
                        #paginatorList
        ></mat-paginator>

      <div class="countExpirySearch">
        <button mat-button class="btn-success"
                matTooltip="SELECCIONA UN CONTRATO POR CADA PROYECTO"
                type="button"
                (click)="selectAll()">SELECCIONE TODOS LOS PROYECTOS
        </button>
      </div>


        <mat-icon class="close" (click)="close()">close</mat-icon>
    </div>

    <div class="list">
        <h1 *ngIf="data.dataOne.length > 0">Contratos para vincular y activar accesso</h1>
        <div class="sub-list">
            <div *ngFor="let item of listSlice; let i = index" style=" margin-right: 10px;" >
                <app-card
                    [dataAbove]="{iconStart:'location_city', textStart:item[data.cardOne.abodeTextMedium]}"
                    [cardInformation]="true"
                    (click)="selectedItem(item, i)"
                    [photo]="getImg(item[data.cardOne.img])"
                    [title]="item[data.cardOne.titleFirst] + ' ' + (item[data.cardOne.titleSecond] === undefined ? '' : item[data.cardOne.titleSecond])"
                    [item1]="item[data.cardOne.subTitle]"
                    [item2]="(item[data.cardOne.itemKey] === undefined ? '' : item[data.cardOne.itemKey] + ': ')  + (item[data.cardOne.itemValue] === undefined ? '' : item[data.cardOne.itemValue])"
                    [itemFinalDate]="item[data.cardOne.itemFinalDate]"
                    [itemFinalText]="item[data.cardOne.itemFinalText]"
                    [cardIsSelected]="true"
                    [_AllDisabled]="true"
                    [_ckeckbox]="item.ControlCheck"
                    >
                </app-card>
            </div>
        </div>
        <h1 *ngIf="data.dataTwo.length > 0">Contratos para modificar el acceso</h1>
        <div class="sub-list">
            <div *ngFor="let item of data.dataTwo; let i = index" style=" margin-right: 10px; margin-left: 10px;">
                <app-card
                    [dataAbove]="{iconStart:'location_city', textStart:item[data.cardOne.abodeTextMedium]}"
                    [cardInformation]="true"
                    [showDates]="true"
                    (click)="selectedItemBound(item, i)"
                    [photo]="getImg(item[data.cardOne.img])"
                    [title]="item[data.cardOne.titleFirst] + ' ' + (item[data.cardOne.titleSecond] === undefined ? '' : item[data.cardOne.titleSecond])"
                    [item1]="item[data.cardOne.subTitle]"
                    [item2]="(item[data.cardOne.itemKey] === undefined ? '' : item[data.cardOne.itemKey] + ': ')  + (item[data.cardOne.itemValue] === undefined ? '' : item[data.cardOne.itemValue])"
                    [itemFinalDate]="item[data.cardOne.itemFinalDate]"
                    [itemFinalText]="item[data.cardOne.itemFinalText]"
                    [startDateContract]="item.StartDateContract"
                    [finishDateContract]="item.FinishDateContract"
                    [cardIsSelected]="true"
                    [_AllDisabled]="true"
                    [_ckeckbox]="item.ControlCheck"
                    >
                </app-card>
            </div>
        </div>
    </div>
</div>
