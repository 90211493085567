<div class="box-form">
  <h3 class="uppercase align-center">Cambiar contraseña</h3>
    <form [formGroup]="formUpdate" class="padding-24 ng-pristine ng-invalid ng-invalid-required ng-valid-pattern ng-valid-match" >
      <div class="form-group" ng-if="!show" *ngIf="userChangeData === undefined" >
        <label>Contraseña anterior</label>
          <div class="input-icon">
            <input [type]="LastPassword === true ? 'password': 'text'" [formControl]="formUpdate.controls['LastPassword']" class="input-password">
            <mat-icon (click)="LastPassword = !LastPassword">visibility</mat-icon>
          </div>
          </div>
          <div class="form-group">
            <label>Nueva contraseña</label>
            <div class="input-icon">
              <input [formControl]="formUpdate.controls['Password']" [type]="password === true ? 'password': 'text'" class="input-password">
              <mat-icon (click)="password = !password">visibility</mat-icon>
            </div>
            <div *ngIf="formUpdate.controls['Password'].invalid && (formUpdate.controls['Password'].dirty || formUpdate.controls['Password'].touched)" class="text-light badge-danger my-2 py-2">
              <div *ngFor="let validation of user_validation_messages.password" >
                  <div *ngIf="formUpdate.get('Password').hasError(validation.type) && (formUpdate.get('Password').dirty || formUpdate.get('Password').touched)" class="error-message">
                      {{validation.message}}
                  </div>
              </div>
          </div>
          </div>
          <div class="form-group">
            <label>Confirmación de contraseña</label>
            <div class="input-icon">
              <input [formControl]="formUpdate.controls['ConfirmPassword']" [type]="ConfirmPassword === true ? 'password': 'text'" class="input-password">
              <mat-icon (click)="ConfirmPassword = !ConfirmPassword">visibility</mat-icon>
            </div>
            <div *ngIf="passwordsEquals()">
              <div ng-message="required" class="error-message">Las contraseñas no coinciden.</div>
            </div>
            <div *ngIf="lastPasswordIncorrect()">
              <div ng-message="required" class="error-message">La contraseña debe tener letras en mayusculas (A-Z), minusculas (a-z), números (0-9) y caracteres especiales (*#,%.) no puede utilizar 3 caracteres consecutivos (123 0 abc) no se puede repetir 3 caracteres iguales (444 o ddd) mínimo 8 caracteres de longitud.</div>
            </div>
            <div *ngIf="passwordsEqualsLast()">
              <div ng-message="required" class="error-message">La contraseña nueva no puede ser igual a la anterior.</div>
            </div>
          </div>
        </form>
        <div class="actions">
        <button type="button" class="bttn" (click)="close()">Cancelar</button>
        <button type="button" *ngIf="this.data.UpdatePasswordAnonymous === undefined" class="bttn green" ng-disabled="PasswordForm.$invalid" (click)="userChangeData === undefined ? update() : updateSuperUser()" [disabled]="formUpdate.invalid || validatePasswordEquals === true">Actualizar</button>
        <button type="button" *ngIf="this.data.UpdatePasswordAnonymous !== undefined" class="bttn green" ng-disabled="PasswordForm.$invalid" (click)="updateError432()" [disabled]="formUpdate.invalid || validatePasswordEquals === true">Actualizar</button>
      </div>
</div>
