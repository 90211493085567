<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'VEHICULO' || 'N/A'}}</div>
    </div>

    <div class="content-card list-card" autocomplete="off">
        <button class="uploadPhoto" [ngClass]="formDataPhoto === undefined ? 'borderBtn': ''" (click)="updatePhotoVehicle()" *ngIf="flagCreateVehicle === true">
            <mat-icon>file_upload</mat-icon>
            <div>Cargar Imagen</div>
        </button>
        <img style="margin-bottom: 10px; margin-top: 10px;" *ngIf="imgCropperResult" [src]="imgCropperResult" alt="" width="150" height="150">
        <form [formGroup]="form">
            <div class="sub-header" *ngIf="flagCreateVehicle === false">
                <div class="sub-content greendAnalityco">{{'PROYECTO O LUGAR DE TRABAJO' || 'N/A'}}</div>
            </div>
            <br *ngIf="flagCreateVehicle === false">
            <div *ngIf="flagCreateVehicle === false">
                <div class="card" *ngFor="let proyect of proyectSelected">
                    <div class="card-option">
                        <img src="{{getImg(proyect.Logo)}}" alt="" (error)="commonMetodService._getUrlErrorImg('project', $event)">
                        <div class="card-content">
                            <div class="card-items">
                                <h3 class="title">{{(proyect.Name | uppercase) || ''}}</h3>
                                <div class="field">{{proyect.CompanyName || ''}}</div>
                                <div class="field marginTop">{{proyect.CompanyDocNum || ''}}</div>
                            </div>
                        </div>
                    </div>
                    <mat-icon class="icon-close" (click)="setValue()">close</mat-icon>
                </div>
            </div>
            <app-select-search class="select-component"
                            [appearance]="'outline'" *ngIf="proyectSelected.length === 0 && flagCreateVehicle === false"
                            [_dataSource]="proyects" [textInput]="'PROYECTO'" [inputType]="'text'"
                            [dataShow]="'Name'" [dataValue]="'Id'" [otherFilter]="'CompanyDocNum'" [siteCtrl]="controlEmployer"
                            [isCard]="true"
                            [card]="{img: 'Logo', title: 'Name', subTitle: 'CompanyName', finalLeft:'CompanyDocNum'}"
                            (onSelectionChange)="selectedProyect($event)"
                            [onErrorUrl]="'project'">
            </app-select-search>
            <div class="sub-header" *ngIf="flagCreateVehicle === false">
                <div class="sub-content greendAnalityco">{{'CONTRATO' || 'N/A'}}</div>
            </div>
            <br>
            <div *ngIf="flagCreateVehicle === false">
                <div class="card" *ngFor="let contract of selectedVehicleContracts">
                    <div class="card-option">
                        <img src="{{s3Bucket+getImgContract(contract.FormImageLogo)}}" alt="">
                        <div class="card-content">
                            <div class="card-items">
                                <h3 class="title">{{(contract.ContractReview | uppercase) || ''}}</h3>
                                <div class="field">{{contract.ContractorName}}</div>
                                <div class="field">{{contract.ContractNumber}}</div>
                            </div>
                        </div>
                    </div>
                    <mat-icon class="icon-close" (click)="setValueContract()">close</mat-icon>
                </div>
            </div>
            <app-select-search class="select-component" *ngIf="selectedVehicleContracts.length === 0 && flagCreateVehicle === false"
                            [appearance]="'outline'"
                            [_dataSource]="vehicleContractsFilter" [textInput]="'CONTRATOS'" [inputType]="'text'"
                            [dataShow]="'ContractorName'" [dataValue]="'Id'" [otherFilter]="'ContractorDocumentNumber'" [siteCtrl]="form.get('ContractId')"
                            [isCard]="true"
                            [card]="{img: 'FormImageLogo', title: 'ContractReview', subTitle: 'ContractorName', finalLeft: 'ContractNumber'}"
                            (onSelectionChange)="onSelectionChangeContract($event)">
            </app-select-search>
            <!-- DAtos del contra funsion fecha inicio y final si se desea mostrar descomentar  -->
            <!-- <div class="fields-group" *ngIf="selectedVehicleContracts.length !== 0">
                <div class="input-field-medium-date" matTooltip="Fecha de unicio para el acceso">
                    <mat-label >Inicio</mat-label>&nbsp;&nbsp;&nbsp;
                    <mat-icon (click)="pickerStartDateContractItem.open()" >today</mat-icon>
                    <mat-form-field class="" appearance="outline">
                        <input matInput
                            #inputDateStartDateContract
                            [matDatepicker]="pickerStartDateContractItem"
                            formControlName="StartDate"
                            [disabled]="true" (click)="pickerStartDateContractItem.open()">
                        <mat-datepicker #pickerStartDateContractItem></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="input-field-medium-date" matTooltip="Fecha de fin para el acceso" [ngClass]="getCalculateStateDate(form.get('FinishDate'))">
                <mat-label>Fin</mat-label>&nbsp;&nbsp;&nbsp;
                <mat-icon (click)="pickerFinishDateContractItem.open()">today</mat-icon>
                <mat-form-field class="" appearance="outline">
                    <input matInput
                        #inputDateFinishDateContract
                        [matDatepicker]="pickerFinishDateContractItem"
                        formControlName="FinishDate"
                        (click)="pickerFinishDateContractItem.open()" [min]="minDateVigenciaContract">
                    <mat-datepicker #pickerFinishDateContractItem></mat-datepicker>
                </mat-form-field>
                </div>
            </div>
            <mat-form-field class="input-field" appearance="outline" appearance="outline" *ngIf="selectedVehicleContracts.length !== 0">
                <label><strong>FUNCIÓN</strong></label>
                <input matInput
                    type="text"
                    formControlName="Function" >
                <mat-error>Requerido</mat-error>
            </mat-form-field> -->
            <div class="sub-header" *ngIf="flagCreateVehicle === false">
                <div class="sub-content greendAnalityco">{{'VEHICULO' || 'N/A'}}</div>
            </div>
            <br *ngIf="flagCreateVehicle === true">
            <div class="fields-group" *ngIf="flagCreateVehicle === false">
                <label style="margin-left: 5px;"><strong>PLACA:</strong></label>
                <mat-form-field class="input-field-70"  appearance="outline">
                    <input matInput type="text" formControlName="LicenseLsearch" maxlength="3" onkeyup="
                        this.value=this.value.replace(0,'');
                        this.value=this.value.replace(1,'');
                        this.value=this.value.replace(2,'');
                        this.value=this.value.replace(3,'');
                        this.value=this.value.replace(4,'');
                        this.value=this.value.replace(5,'');
                        this.value=this.value.replace(6,'');
                        this.value=this.value.replace(7,'');
                        this.value=this.value.replace(8,'');
                        this.value=this.value.replace(9,'');
                        if (this.value.length == this.getAttribute('maxlength')) {
                            this.value=this.value.toUpperCase();
                            input2.focus()
                        } else {
                            this.value=this.value.toUpperCase();
                        }">
                    <mat-error>Requerido</mat-error>
                </mat-form-field>-
                <mat-form-field class="input-field-70"  appearance="outline">
                    <input matInput type="text" id="input2" formControlName="LicenseNsearch" maxlength="3" onkeyup="this.value=this.value.toUpperCase();">
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
                <div class="btn-search" (click)="getVehicleDocument()">
                    <button mat-mini-fab color="primary" matTooltip="buscar" >
                      <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div>
            <div class="fields-group" *ngIf="flagCreateVehicle === true">
                <label style="margin-left: 5px;"><strong>PLACA:</strong></label>
                <mat-form-field class="input-field-70"  appearance="outline">
                    <input matInput type="text" formControlName="LicenseL" maxlength="3" onkeyup="this.value=this.value.toUpperCase();" disabled>
                    <mat-error>Requerido</mat-error>
                </mat-form-field>-
                <mat-form-field class="input-field-70"  appearance="outline">
                    <input matInput type="text" formControlName="LicenseN" maxlength="3" onkeyup="this.value=this.value.toUpperCase();" disabled>
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
            </div>
            <app-select-search class="select-component-medium" *ngIf="flagCreateVehicle === true"
                [appearance]="'outline'"
                [_dataSource]="vehicleType" [textInput]="'TIPO'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="form.get('VehicleTypeId')"
                [inputType]="'text'" >
            </app-select-search>
            <app-select-search class="select-component-medium" *ngIf="flagCreateVehicle === true"
                [appearance]="'outline'"
                [_dataSource]="contries" [textInput]="'PAIS'" [dataShow]="'Description'" [dataValue]="'Value'" [siteCtrl]="form.get('CountryId')"
                [inputType]="'text'" >
            </app-select-search>
            <div class="subTitle" *ngIf="flagCreateVehicle === true">DESCRIPCIÓN</div>
            <mat-form-field class="input-field"  appearance="outline" *ngIf="flagCreateVehicle === true">
                <input matInput type="text" formControlName="Review">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
        </form>
    </div>

    <div class="btn-actions"style="margin: 3px;">
        <button mat-button class="btn-cancelar" (click)="close()">{{'CANCELAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" *ngIf="flagCreateVehicle === true" (click)="save()">{{'GUARDAR'}}</button>
    </div>
</div>
