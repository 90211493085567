<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{data?.title ||'Buscar Empresa' || 'N/A'}}</div>
    </div>
    <div class="content-card list-card" >
        <div *ngIf="data?.messsage !== undefined && data?.messsage.length !== 0">
            <div class="meessage" *ngFor="let item of data?.messsage">
                {{item}}
            </div>
        </div>
        <img [src]="data.urlComplet" alt="" (click)="openImgVideo()">
        <br>
        <div class="actions-dialog"style="margin: 3px;">
            <button mat-button class="btn-cancelar" (click)="onNoClick(false)">CANCELAR</button>&nbsp;&nbsp;
            <button mat-button 
                    class="btn-generar" (click)="onNoClick(true)">CONTINUAR
            </button>
            <!-- <button (click)="_getContractNumber()">Hola</button> -->
        </div>
    </div>
</div>