<!-- menu -->
<div class="hidden" *ngIf="exitPermissionCompany('personalhistoricals.view')" [ngClass]="btn_menuHistory === true ? 'show':''">
    <div>
        <div class="title-hidden">Historial de cambios</div>
        <span (click)="btn_menuHistory = !btn_menuHistory">X</span>
    </div>
    <app-select-search
        [appearance]="'outline'"
        [_dataSource]="listMenuHistory" [textInput]="'SERVICIO'" [dataShow]="'name'" [dataValue]="'id'" [siteCtrl]="controHistory"
        [inputType]="'text'" (onSelectionChange)="getHistory()">
    </app-select-search>
    <div class="history-scroll">
        <div class="history" *ngFor="let his of history">
            <div class="history-date"><mat-icon>play_arrow</mat-icon><div>{{fechasService.getDateFormatRWithHourAMPM(his.UpdateDate) || ''}}</div></div>
            <div class="history-user" *ngIf="his.Action"><mat-icon>circle</mat-icon><div>{{his.Action || ''}}</div></div>
            <div class="history-user" *ngIf="his.ContractNumber"><div>Contratos:&nbsp;{{getContractNumber(his.ContractNumber) || ''}}</div></div>
            <div class="history-user" *ngIf="his.Position"><div>Función:&nbsp;{{his.Position || ''}}</div></div>
            <div class="history-user" *ngIf="his.StartDate"><div>Inicio de acceso:&nbsp;{{fechasService.getDateFormatRWithHourAMPM(his.StartDate) || ''}}</div></div>
            <div class="history-user" *ngIf="his.FinishDate"><div>Fin de acceso:&nbsp;{{fechasService.getDateFormatRWithHourAMPM(his.FinishDate) || ''}}</div></div>
            <div class="history-user"><mat-icon>circle</mat-icon><div>{{his.UserName || 'Sin user'}}</div></div>
        </div>
    </div>
</div>
<div class="btn-open-history" (click)="btn_menuHistory = !btn_menuHistory" *ngIf="btn_menuHistory === false">
    <mat-icon>history</mat-icon>
</div>
<!-- fin menu historyu -->
<div class="btns-actions" *ngIf="flagListF === true">
    <div class="btn-save">
        <button mat-fab color="primary" *ngIf="flagChangeDataBtnSave === true" matTooltip="Guardar" type="button" (click)="eventSave()">
          <mat-icon>save</mat-icon>
        </button>
        <button mat-fab color="secundary" *ngIf="flagChangeDataBtnSave === false" type="button">
            <mat-icon>save</mat-icon>
        </button>
    </div>
</div>

<!-- ESTA ES LA TARJETA DE INFORMACION DEL EMPLEADO -->
<div class="contract-proyect" >
    <div class="box information-fields" *ngIf="configCard.typeCard === 'information'">
        <div class="header">
            <div class="content" [ngClass]="configCard.color || 'primary'">{{configCard.title || 'N/A'}}</div>
        </div>
        <div class="content-card" *ngIf="personal">
            <!-- TITLE AND PHOTO -->
            <div class="button-container" >
                <img class="img-perfil" src="{{s3Bucket+etImg(personal.Photo)}}" (error)="commonMetodService._getUrlErrorImg('personal', $event)" alt="">
                <a *ngIf="flagListF === true && exitPermissionCompany('personals.update')" (click)="openDialogPhotoPersonal()"><mat-icon>camera_alt</mat-icon></a>
            </div>
                <mat-icon   class="icon-black-list-add"
                            *ngIf="flagListF=== true && exitPermissionCompany('contractingblacklist.view')"
                            matTooltip="Agregar a la lista"
                            [matTooltipPosition]="'right'"
                            (click)="postPersonalBlackList()"
                >person</mat-icon>
                <mat-icon   class="icon-black-list-remove"
                            *ngIf="flagListF=== false && exitPermissionCompany('contractingblacklist.view')"
                            [matTooltip]="''+(flagListFInfo!.Reason === null ? '': flagListFInfo!.Reason!.Description) + ', ' + fechasService.getDateFormatRWith(flagListFInfo!.StartDate) + ', ' + (flagListFInfo!.User === null ? '': flagListFInfo!.User!.Email) + ''"
                            [matTooltipPosition]="'right'"
                            matTooltipClass = 'testClassTootipBlackList'
                            (click)="deletePersonalBlackList()"
                >person_off</mat-icon>

            <div class="card-description">
                <form [formGroup]="formPersonal" autocomplete="off">
                    <app-select-search class="select-component"
                            [appearance]="'outline'"
                            [_dataSource]="nationality" [textInput]="'NACIONALIDAD'" [dataShow]="'Description'" [dataValue]="'Value'" [siteCtrl]="formPersonal.get('Nationality')"
                            [inputType]="'text'" (onSelectionChange)="getRequirementsUptadeInfo()">
                    </app-select-search>
                    <div class="fields-group">
                        <mat-form-field class="input-field-medium"  style="margin-left: 0px;" appearance="outline">
                            <label><strong>TIPO</strong></label>
                            <mat-select formControlName="DocumentType" [disabled]="isCreateAppPersonal">
                                <mat-option *ngFor="let data of companyDocumentType" [value]="data.Value">
                                    {{data.Description}}
                                </mat-option>
                            </mat-select>
                            <mat-error>Requerido</mat-error>
                        </mat-form-field>
                        <mat-form-field class="input-field" appearance="outline">
                            <label><strong>NÚMERO</strong></label>
                            <input matInput type="number" formControlName="DocumentNumber" [disabled]="isCreateAppPersonal"
                                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">
                            <mat-error>Requerido</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-form-field class="input-field" appearance="outline">
                        <label><strong>NOMBRE</strong></label>
                        <input matInput type="text" formControlName="Name" (input)="convertirAMayusculas($event)">
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                    <mat-form-field class="input-field" appearance="outline">
                        <label><strong>APELLIDOS</strong></label>
                        <input matInput type="text" formControlName="LastName" (input)="convertirAMayusculas($event)">
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                    <div class="fields-group">
                        <mat-form-field class="input-filed-fecha" style="margin-left: 0px;" appearance="outline">
                            <label><strong>FECH. NACIMIENTO</strong></label>
                            <input matInput [matDatepicker]="picker" formControlName="BirthDate" (click)="picker.open()" minlength="8" [readonly]="true"
                            (dateChange)="changeBirthDateCalculate()">
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error>Requerido</mat-error>
                        </mat-form-field>
                        <app-select-search class="select-field-medium-1"
                                [appearance]="'outline'"
                            [_dataSource]="sex" [textInput]="'SEXO'" [dataShow]="'Value'" [dataValue]="'Value'" [siteCtrl]="formPersonal.get('Sex')"
                            [inputType]="'text'">
                        </app-select-search>
                        <app-select-search class="select-field-medium-1"
                                [appearance]="'outline'"
                            [_dataSource]="rh" [textInput]="'RH'" [dataShow]="'Description'" [dataValue]="'Value'" [siteCtrl]="formPersonal.get('RH')"
                            [inputType]="'text'">
                        </app-select-search>
                    </div>
                    <app-select-search class="select-component-medium"
                            [appearance]="'outline'"
                            [_dataSource]="countrys" [textInput]="'PAIS DE NACIMIENTO'" [dataShow]="'Description'" [dataValue]="'Value'" [siteCtrl]="formPersonal.get('contryNalcionality')"
                            [inputType]="'text'" (onSelectionChange)="getCitiesNacimiento($event, true)">
                    </app-select-search>
                    <app-select-search
                            [appearance]="'outline'"
                        [_dataSource]="citiesNacimiento" [textInput]="'CIUDAD DE NACIMIENTO'" [dataShow]="'Name'" [dataValue]="'Code'" [siteCtrl]="formPersonal.get('CityOfBirthCode')"
                        [inputType]="'text'">
                    </app-select-search>
                    <div class="fields-group" *ngIf="exitPermissionCompany('personals.docexpeditionview')">
                        <mat-form-field class="input-filed-fecha" style="margin-left: 0px;" appearance="outline">
                            <label><strong>FECH. DE EXPEDICIÓN</strong></label>
                            <input matInput [matDatepicker]="pickerExpedicion"
                                #inputExpeditionDate
                                [value]="controlExpeditionDate.value" (click)="pickerExpedicion.open()" minlength="8" [readonly]="true"
                                (dateChange)="changeExpeditionDate(inputExpeditionDate.value)"
                                [disabled]="flagListF === false ? true : false">
                            <mat-datepicker #pickerExpedicion></mat-datepicker>
                            <mat-error>Requerido</mat-error>
                        </mat-form-field>
                        <app-select-search class="select-medium-short"
                                [appearance]="'outline'"
                            [_dataSource]="citiesExpedicion" [textInput]="'LUGAR DE EXPEDICIÓN'" [dataShow]="'Name'" [dataValue]="'Code'" [siteCtrl]="controlExpeditionPlace"
                            [inputType]="'text'" [disabled]="flagListF === false ? true : false" (onSelectionChange)="changeControlExpeditionPlace()">
                        </app-select-search>
                    </div>

                    <!-- SUBTITLE 3 ITEMS -->
                    <div class="sub-header">
                        <div class="sub-content greendAnalityco">
                            {{'CONTACTO' || 'N/A'}}
                        </div>
                    </div>
                    <br>
                    <app-select-search class="select-component-medium"
                            [appearance]="'outline'"
                            [_dataSource]="daneCity" [textInput]="'CIUDAD'" [dataShow]="'CityName'" [dataValue]="'CityCode'" [siteCtrl]="formPersonal.get('CityCode')"
                            [inputType]="'text'" (onSelectionChange)="getTown($event)">
                    </app-select-search>
                    <!-- //direccion spla sin localidad porque base datos no esta el tiwnCode -->

                    <div class="fields-group">
                        <mat-form-field class="input-field-medium-more" style="margin-left: 0px;" appearance="outline">
                            <label><strong>DIRECCIÓN</strong></label>
                            <input matInput type="text" formControlName="Address">
                            <mat-error>Requerido</mat-error>
                    </mat-form-field>
                        <app-select-search class="select-field-medium-short"
                                [appearance]="'outline'"
                            [_dataSource]="town" [textInput]="'ZONA O LOCALIDAD'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formPersonal.get('TownId')"
                            [inputType]="'text'">
                        </app-select-search>
                    </div>
                    <div class="fields-group">
                        <mat-form-field class="input-field-medium-more" style="margin-left: 0px;" appearance="outline">
                            <label><strong>CORREO</strong></label>
                            <input matInput type="text" formControlName="Email" onkeyup="this.value=this.value.toLowerCase();">
                            <mat-error>formato incorrecto / Requerido</mat-error>
                        </mat-form-field>
                        <mat-form-field class="field-short" appearance="outline">
                            <label><strong>TELEFONO</strong></label>
                            <input matInput type="text" formControlName="PhoneNumber">
                            <mat-error>Requerido</mat-error>
                        </mat-form-field>
                    </div>
                    <app-select-search class="select-component-medium"
                            [appearance]="'outline'"
                        [_dataSource]="eps" [textInput]="'EPS'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formPersonal.get('EpsId')"
                        [inputType]="'text'" (onSelectionChange)="changeEPSAFP($event, 'EPS')">
                    </app-select-search>
                    <app-select-search class="select-component-medium"
                            [appearance]="'outline'"
                        [_dataSource]="afp" [textInput]="'AFP'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formPersonal.get('AfpId')"
                        [inputType]="'text'" (onSelectionChange)="changeEPSAFP($event, 'AFP')">
                    </app-select-search>
                    <div class="subTitle">En caso de emergencia llamar a</div>
                    <div class="fields-group">
                        <mat-form-field class="input-field-medium-more" appearance="outline">
                            <label><strong>NOMBRE</strong></label>
                            <input matInput type="text" formControlName="EmergencyContact">
                            <mat-error>Requerido</mat-error>
                        </mat-form-field>
                        <mat-form-field class="input-field-medium-short" appearance="outline">
                            <label><strong>PARENTESCO</strong></label>
                            <input matInput type="text" formControlName="RelationshipWithContact">
                            <mat-error>Requerido</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group">
                        <mat-form-field class="input-field-51" appearance="outline">
                            <label><strong>TELEFONO</strong></label>
                            <input matInput type="text" formControlName="EmergencyContactPhone">
                            <mat-error>Requerido</mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </div>
        </div>
        <div class="content-card list-card pre-content" *ngIf="!personal">
            <div class="form-group-list" *ngFor="let item of production.pre_loading">
                <div class="card">
                    <div class="relational-info">
                        <div class="desc flex" style="width: 18px;">
                            <div class="icon-pre pre-loading"></div>
                            <div class="title-pre pre-loading"></div>
                        </div>
                        <div class="icon-pre pre-loading"></div>
                    </div>
                    <div class="card-content">
                        <div class="pre-img pre-loading"></div>
                        <div class="content-information">
                            <div class="text-pre pre-loading"></div>
                            <div class="text-pre pre-loading"></div>
                            <div c
                            lass="text-pre pre-loading"></div>
                        </div>
                    </div>
                </div>
                <hr>
            </div>
        </div>
    </div>

  <!-- OTROS DATOS HOJA DE VIDA -->
  <div>
    <div class="box information-fields" *ngIf="configCard.typeCard === 'information' && exitPermissionCompany('personalanotherdata.view')">
      <div class="header">
        <div class="content" [ngClass]="'greendAnalityco'">{{'OTROS DATOS' || 'N/A'}}</div>
      </div>
      <div class="content-card">
        <form [formGroup]="formPersonalCV" class="docsCV" autocomplete="off">
          <div class="item-list-docs">
            <div class="subTitle">Hoja de vida</div>
            <div class="doc-icon-input">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fit="" height="10%" width="10%" preserveAspectRatio="xMidYMid meet" focusable="false">
                <path d="M 15 3 A 1 1 0 0 0 14.527344 3.1191406 L 0.53710938 10.113281 L 0.53710938 10.115234 A 1 1 0 0 0 0 11 A 1 1 0 0 0 0.53710938 11.884766 L 0.53710938 11.886719 L 14.521484 18.876953 A 1 1 0 0 0 15 19 A 1 1 0 0 0 15.472656 18.880859 L 29.453125 11.890625 A 1 1 0 0 0 29.462891 11.886719 L 29.462891 11.884766 A 1 1 0 0 0 30 11 A 1 1 0 0 0 29.462891 10.115234 L 29.462891 10.113281 L 15.478516 3.1230469 A 1 1 0 0 0 15 3 z M 29 14.353516 L 27 15.353516 L 27 24.277344 C 26.404366 24.623613 26 25.261054 26 26 C 26 27.105 28 30 28 30 C 28 30 30 27.105 30 26 C 30 25.261054 29.595634 24.623613 29 24.277344 L 29 14.353516 z M 6 16.851562 L 6 21 C 6 23.761 10.029 26 15 26 C 19.971 26 24 23.761 24 21 L 24 16.851562 L 16.365234 20.669922 C 16.281234 20.711922 16.197328 20.746391 16.111328 20.775391 C 15.749328 20.924391 15.377 21 15 21 C 14.624 21 14.252578 20.924391 13.892578 20.775391 C 13.805578 20.746391 13.719766 20.710969 13.634766 20.667969 L 6 16.851562 z"></path>
              </svg>
              <app-select-search class="select-field-full-icon"
                                 [appearance]="'outline'"
                                 [_dataSource]="jobs" [textInput]="'PROFESION U OFICIO'" [dataShow]="'Code'" [otherFilter]="'Name'" [dataValue]="'Code'" [siteCtrl]="formPersonalCV.get('JobCode')"
                                 [inputType]="'text'">
              </app-select-search>
            </div>
            <div class="doc-name" *ngIf="exitPermissionCompany('personalstudiescert.view')">
              <svg *ngIf="(formDataStudyCertificate !== undefined && formDataStudyCertificate !== null) || (urlDataStudyCertificate !== null && urlDataStudyCertificate !== '')"
                   (click)="openDocumentsPersonalCV('StudyCertificate')"
                   title="EPS" style="fill: #4C4C4C" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{'CHL'}}</text>
              </svg>
              <svg *ngIf="(formDataStudyCertificate === undefined || formDataStudyCertificate === null) && (urlDataStudyCertificate === null || urlDataStudyCertificate === '')"
                   (click)="openDocumentsPersonalCV('StudyCertificate')"
                   title="EPS" style="fill: red;" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{'CHL'}}</text>
              </svg>
              <p class="description">{{'Certificado de estudio' || ''}}</p>
              <app-select-search class="select-component-full"
                                 [appearance]="'outline'"
                                 [_dataSource]="educationalLevel" [textInput]="'NIVEL DE ESTUDIOS'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="formPersonalCV.get('EducationalLevelId')"
                                 [inputType]="'text'">
              </app-select-search>
            </div>
            <div class="doc-name" *ngIf="exitPermissionCompany('personalespiriencecert.view')">
              <svg *ngIf="(formDataExperienceCertificate !== undefined && formDataExperienceCertificate !== null) || (urlDataExperienceCertificate !== null && urlDataExperienceCertificate !== '')"
                   (click)="openDocumentsPersonalCV('ExperienceCertificate')"
                   title="EPS" style="fill: #4C4C4C" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{'CHL'}}</text>
              </svg>
              <svg *ngIf="(formDataExperienceCertificate === undefined || formDataExperienceCertificate === null) && (urlDataExperienceCertificate === null || urlDataExperienceCertificate === '')"
                   (click)="openDocumentsPersonalCV('ExperienceCertificate')"
                   title="EPS" style="fill: red;" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{'CHL'}}</text>
              </svg>
              <p class="description">{{'Certificado de experiencia' || ''}}</p>
              <div class="inputExperient">
                <div class="text-inputExperiendt"><strong>EXPERIENCIA EN EL CARGO</strong></div>
                <div class="inputs">
                  <mat-form-field class="input-field-10" appearance="outline">
                    <input matInput type="number" min="0" max="12" step="1" name="num" formControlName="ExperienceYears" style="text-align:right;"
                           onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    >
                  </mat-form-field>
                  <div class="text-años">Año(s)</div>
                  <mat-form-field class="input-field-10" appearance="outline">
                    <input matInput #ExperienceMonths type="number" [min]="0" [max]="12" formControlName="ExperienceMonths"  style="text-align:right;" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                           onkeyup="if(this.value > 12) this.value = 12">
                  </mat-form-field>
                  <div class="text-años">Meses</div>
                </div>
              </div>
            </div>
            <br>
            <div class="subTitle" style="margin-top: 10px;">Otros Datos</div>
            <div class="fields-group">
              <app-select-search class="input-field-50"
                                 [appearance]="'outline'"
                                 [_dataSource]="maritalStatus" [textInput]="'ESTADO CIVIL'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="formPersonalCV.get('MaritalStatusId')"
                                 [inputType]="'text'">
              </app-select-search>
              <mat-form-field class="input-field-50" appearance="outline">
                <label><strong>NUMERO DE HIJOS</strong></label>
                <input matInput type="number" formControlName="NumberOfChilds" [min]="0" [max]="12" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">
                <mat-error>Requerido</mat-error>
              </mat-form-field>
            </div>
            <div class="fields-group">
              <app-select-search class="input-field-50"
                                 [appearance]="'outline'"
                                 [_dataSource]="socialStratum" [textInput]="'ESTRATO SOCIO ECONOMICO'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="formPersonalCV.get('SocialStratumId')"
                                 [inputType]="'text'">
              </app-select-search>
              <mat-form-field class="input-field-50" appearance="outline">
                <label><strong>PERSONAS A CARGO</strong></label>
                <input matInput type="number" formControlName="NumberOfDependants"  [min]="0" [max]="12" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">
                <mat-error>Requerido</mat-error>
              </mat-form-field>
            </div>
            <div class="subTitle">Salud</div>
            <div class="doc-icon-input">
              <mat-form-field class="select-icon" appearance="outline">
                <label><strong>INDICAR MEDICAMENTOS QUE TOMAS</strong></label>
                <textarea matInput formControlName="Medicines" maxlength="{{100}}"></textarea>
                <mat-error>Requerido</mat-error>
              </mat-form-field>
            </div>
            <div class="poll">
              <div class="text-poll">
                <div>Encuesta de ingreso</div>
              </div>
              <mat-icon class="icon-poll" *ngIf="flagListF === true && exitPermissionCompany('personalsurvery.view')" matTooltip="Crear nueva encuesta" (click)="openPoll()">link</mat-icon>
              <mat-icon class="icon-poll-disabled" *ngIf="flagListF === false ||  !exitPermissionCompany('personalsurvery.view')" matTooltip="Crear nueva encuesta">link</mat-icon>
            </div>
            <form [formGroup]="formVaccine" style="margin-top: 10px;" autocomplete="off">
              <div class="subTitle">Covid19</div>
              <app-select-search class="input-field"
                                 [appearance]="'outline'"
                                 [_dataSource]="vaccinationStatus" [textInput]="'ESTADO'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="formVaccine.get('VaccineStatusId')"
                                 [inputType]="'text'">
              </app-select-search>
              <div class="fields-group" *ngIf="getDescriptionStatus(formVaccine.get('VaccineStatusId').value) === 'Vacunado' || getDescriptionStatus(formVaccine.get('VaccineStatusId').value) === 'Parcialmente Vacunado'">
                <app-select-search class="input-field-50"
                                   [appearance]="'outline'"
                                   [_dataSource]="vaccineType" [textInput]="'TIPO DE VACUNA'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="formVaccine.get('VaccineTypeId')"
                                   [inputType]="'text'">
                </app-select-search>
                <app-select-search class="input-field-50"
                                   [appearance]="'outline'"
                                   [_dataSource]="arrayDOSIS" [textInput]="'DOSIS APLICADA'" [dataShow]="'name'" [dataValue]="'value'" [siteCtrl]="formVaccine.get('AppliedDose')"
                                   [inputType]="'text'">
                </app-select-search>
              </div>
              <div class="doc-icon-input">
                <mat-form-field class="select-field-full" appearance="outline">
                  <label><strong>OBSERVACIÓN</strong></label>
                  <textarea matInput formControlName="VaccineObservations" maxlength="{{100}}"></textarea>
                  <mat-error>Requerido</mat-error>
                </mat-form-field>
              </div>
            </form>
          </div>
        </form>
      </div>
    </div>

    <!-- ESTA ES EL ADMIN DE CONTRATOS LABORALES -->
    <div class="box" *ngIf="exitPermissionCompany('personalsemployerscontract.view') ">
      <div class="header">
        <div class="content" [ngClass]="configCard.color || 'primary'">{{'CONTRATOS LABORALES' || 'N/A'}}</div>
      </div>
      <form [formGroup]="formDatosContrato" autocomplete="off" *ngIf="exitPermissionCompany('personalsemployerscontract.view')" >
        <div class="content-card-contracts" *ngIf="exitContractsOffline === true">
          <div class="add-associate-contract-labor">
            <div class="text-add-usuario">Crear contrato laboral</div>
            <button mat-mini-fab [ngClass]="!exitPermissionCompany('personalsemployerscontract.add') || flagListF === false? 'disabledAnalityco': 'blueAnalityco'" type="button" matTooltipPosition="above" matTooltip="crear"
                    (click)="postIndividualContractLabor()" [disabled]="!exitPermissionCompany('personalsemployerscontract.add') || flagListF === false ? true: false">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <div class="form-group-list-scroll-states">
            <div class="form-group-list-contracts" *ngFor="let item of contractsLabor || []; let i = index">
              <form [formGroup]="formDatosContrato" autocomplete="off" style="padding: 7px;" (click)="changeSelectedContractLabor(i)">
                <div class="subTitle-contracts">Datos del contrato laboral</div>
                <div class="fields-group-contract-labor">
                  <strong style="width: 125px;">CONTRATO No:</strong>
                  <mat-form-field class="input-field-medium" appearance="outline">
                    <input matInput type="text"
                           #inputContractNumber
                           [value]="item.ContractNumber"
                           (change)="changeinputContractLaborContractNumber(inputContractNumber.value, i)"
                           [disabled]="!exitPermissionCompany('personalsemployerscontract.update') || indexSelectContracLabor !== i || flagListF === false? true: false">
                    <mat-error>Requerido</mat-error>
                  </mat-form-field>
                  <div class="btns-operator">
                    <mat-icon [ngClass]="indexSelectContracLabor === i ? 'activeBtn':'inactive'" style="margin-left: 10px;">visibility</mat-icon>

                    <div [ngStyle]="!flagListF ? {'cursor': 'not-allowed'} : {}" style="display: inline-block;">
                      <mat-icon
                        [matMenuTriggerFor]="menuPosition"
                        [ngClass]="indexSelectContracLabor === i && flagListF === true ? 'activeBtn':'inactive'"
                        [ngStyle]="!flagListF ? {'pointer-events': 'none', 'opacity': '0.5'} : {}"
                        style="margin-left: 5px;">
                        more_vert
                      </mat-icon>
                    </div>

                    <mat-menu #menuPosition="matMenu">
                      <button mat-menu-item *ngIf="exitPermissionCompany('personalsemployerstermination.start')" (click)="startRetiro(item)">Iniciar retiro</button>
                      <button mat-menu-item *ngIf="exitPermissionCompany('personalsemployerscontract.delete')" (click)="deleteContractLabor(indexSelectContracLabor === i  && flagListF === true ? item.Id : null, item.ContractNumber)">Eliminar</button>
                    </mat-menu>
                  </div>
                </div>
                <app-select-search class="select-component-full-employerSelectedContract"
                                   [appearance]="'outline'"
                                   [_dataSource]="allEmployers" [textInput]="'EMPLEADOR'" [inputType]="'text'"
                                   [dataShow]="'Name'" [dataValue]="'Id'" [otherFilter]="'DocumentNumber'" [siteCtrl]="item.ControlEmployerInfoId"
                                   [isCard]="true" [_value]="item.EmployerInfoId"
                                   [card]="{img: 'Logo', title: 'Name', itemKey: 'DocumentType', itemValue:'DocumentNumber', finalLeft: 'Rol'}"
                                   [disabled]="true"
                                   (onSelectionChange)="onSelectedEmployerInfoId($event, i)"
                                   [onErrorUrl]="'employer'"
                >
                </app-select-search>

                <app-select-search class="select-component-full-employerSelectedContract"
                                   [appearance]="'outline'" [textInput]="'TIPO DE CONTRATO'"
                                   [_dataSource]="individualContractsType" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="item.ControlContractTypeId"
                                   [_value]="item.ContractTypeId"
                                   [disabled]="!exitPermissionCompany('personalsemployerscontract.update') || indexSelectContracLabor !== i || flagListF === false? true: false"
                                   (onSelectionChange)="onSelectedContractTypeId($event, i)"
                                   [inputType]="'text'">
                </app-select-search>
                <div class="fields-group-contract-labor">
                  <mat-form-field class="input-field" appearance="outline">
                    <label><strong>CARGO</strong></label>
                    <input matInput type="text"
                           #inputContractPositionName
                           [value]="item.PositionName"
                           (change)="changeinputContractLaborPositionName(inputContractPositionName.value, i)"
                           [disabled]="!exitPermissionCompany('personalsemployerscontract.update') || indexSelectContracLabor !== i || flagListF === false? true: false">
                    <mat-error>Requerido</mat-error>
                  </mat-form-field>
                </div>
                <div class="fields-group-contract">
                  <mat-form-field class="input-field-contract" appearance="outline">
                    <label><strong>SALARIO MENSUAL</strong></label>
                    <input matInput
                           #inputContractSalary
                           [value]="item.Salary"
                           (change)="changeinputContractLaborSalary(inputContractSalary.value, i)"
                           (keypress)="changeinputContractLaborSalary(inputContractSalary.value, i)"
                           style="text-align: right;"
                           [disabled]="!exitPermissionCompany('personalsemployerscontract.update') || indexSelectContracLabor !== i || flagListF === false? true: false">
                    <mat-error>Requerido</mat-error>
                  </mat-form-field>
                  <div style="margin-left: 5px">
                    <mat-form-field class="input-field-contract-1" appearance="outline">
                      <label><strong>AUXILIO DE TRANS.</strong></label>
                      <input matInput
                             #inputAuxiliaryTransport
                             [value]="item.TransportationAllowance"
                             (change)="changeinputAuxiliaryTransport(inputAuxiliaryTransport.value, i)"
                             (keypress)="changeinputAuxiliaryTransport(inputAuxiliaryTransport.value, i)"
                             style="text-align: right;"
                             [disabled]="indexSelectContracLabor !== i || flagListF === false? true: false">
                      <mat-error>Requerido</mat-error>
                    </mat-form-field>
                  </div>
                  <div style="margin-left: 3px;">
                    <mat-form-field class="input-field-contract-2" appearance="none">
                      <label><strong>TOTAL DEVENGADO</strong></label>
                      <input matInput
                             #inputTotalEarnings
                             [value]="formatCurrency(item.TotalEarnings)"
                             (change)="changeinputTotalEarnings(inputTotalEarnings.value, i)"
                             (keypress)="changeinputTotalEarnings(inputTotalEarnings.value, i)"
                             style="text-align: right;"
                             [disabled]="indexSelectContracLabor !== i || flagListF === false? true: false" readonly>
                      <mat-error>Requerido</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="fields-group-contract-date">
                  <mat-form-field class="input-field-medium-dates" appearance="outline">
                    <label><strong>FECH.INICIO DE CONTRATO</strong></label>
                    <input matInput [matDatepicker]="picker2"
                           #inputDateStartDateContractLabor
                           (dateChange)="changeDateFinishDateContractLabor(inputDateStartDateContractLabor?.value, false, i)"
                           [value]="item.StartDate"
                           (click)="picker2.open()"
                           [disabled]="!exitPermissionCompany('personalsemployerscontract.update') || indexSelectContracLabor !== i || flagListF === false? true: false">
                    <mat-datepicker #picker2></mat-datepicker>
                    <mat-error>Requerido</mat-error>
                  </mat-form-field>

                  <div>
                    <mat-form-field class="input-field-medium-dates1" appearance="outline">
                      <label><strong>FECH.FIN DE CONTRATO</strong></label>
                      <input matInput [matDatepicker]="picker3"
                             #inputDateFinishDateContractLabor
                             (dateChange)="changeDateFinishDateContractLabor(inputDateFinishDateContractLabor?.value, true, i)"
                             [value]="item.EndDate"
                             (click)="picker3.open()"
                             [disabled]="!exitPermissionCompany('personalsemployerscontract.update') || indexSelectContracLabor !== i || flagListF === false? true: false">
                      <mat-datepicker #picker3></mat-datepicker>
                      <mat-error>Requerido</mat-error>
                    </mat-form-field>
                  </div>

                </div>
                <div class="subTitle-contracts">Cuenta bancaria</div>
                <br>
                <div>
                  <app-select-search class="input-field"
                                     [appearance]="'outline'"
                                     [_dataSource]="bancks" [textInput]="'Banco'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="item.ControlBankId"
                                     [_value]="item.BankId"
                                     [disabled]="!exitPermissionCompany('personalsemployerscontract.update') || indexSelectContracLabor !== i || flagListF === false? true: false"
                                     (onSelectionChange)="onSelectedBankId($event, i)"
                                     [inputType]="'text'">
                  </app-select-search>
                  <div class="fields-group-contract-labor">
                    <div class="fields-group-contract-labor">
                      <mat-form-field class="input-field" appearance="outline">
                        <label><strong>NO CUENTA BANCARIA</strong></label>
                        <input matInput type="text"
                               #inputContractBankAccount
                               [value]="item.BankAccount"
                               (change)="changeinputContractLaborBankAccount(inputContractBankAccount.value, i)"
                               [disabled]="!exitPermissionCompany('personalsemployerscontract.update') || indexSelectContracLabor !== i || flagListF === false? true: false">
                        <mat-error>Requerido</mat-error>
                      </mat-form-field>
                    </div>
                    <app-select-search class="select-field-medium-short-contract-labor"
                                       [appearance]="'outline'"
                                       [_dataSource]="bankAccountType" [textInput]="'Tipo'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="item.ControlBankAccountTypeId"
                                       [_value]="item.BankAccountTypeId"
                                       [disabled]="!exitPermissionCompany('personalsemployerscontract.update') || indexSelectContracLabor !== i || flagListF === false? true: false"
                                       (onSelectionChange)="onSelectedBankAccountTypeId($event, i)"
                                       [inputType]="'text'">
                    </app-select-search>
                  </div>
                </div>
                <div class="subTitle-contracts">Lugar y turno de trabajo (Pre-nomina)</div>
                <br>
                <app-select-search class="input-field"
                                   [appearance]="'outline'"
                                   [_dataSource]="projects" [textInput]="'Lugar de trabajo'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="item.ControlProjectId"
                                   [_value]="item.ProjectId"
                                   [disabled]="!exitPermissionCompany('personalsemployerscontract.update') || indexSelectContracLabor !== i || flagListF === false? true: false"
                                   (onSelectionChange)="onSelectedProjectId($event, i)"
                                   [inputType]="'text'">
                </app-select-search>
                <app-select-search class="input-field"
                                   [appearance]="'outline'"
                                   [_dataSource]="workShift" [textInput]="'Horario de trabajo'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="item.ControlWorkShift"
                                   [_value]="item.WorkShiftId"
                                   [disabled]="!exitPermissionCompany('personalsemployerscontract.update') || indexSelectContracLabor !== i || flagListF === false? true: false"
                                   (onSelectionChange)="onSelectedWorkShift($event, i)"
                                   [inputType]="'text'">
                </app-select-search>
                <br>
                <div class="timeExtras">
                  <div class="timeExtrasText">Calcular horas extras diurnas</div>
                  <app-select-search class="input-field" style="width: 80px;"
                                     [appearance]="'outline'"
                                     [_dataSource]="HasExtraTimeList" [textInput]="''" [dataShow]="'name'" [dataValue]="'value'" [siteCtrl]="item.ControlHasExtraTime"
                                     [_value]="item.HasExtraTime"
                                     [disabled]="!exitPermissionCompany('personalsemployerscontract.update') || indexSelectContracLabor !== i || flagListF === false? true: false"
                                     (onSelectionChange)="onSelectedHasExtraTime($event, i)"
                                     [inputType]="'text'">
                  </app-select-search>
                </div>
              </form>
            </div>
          </div>
          <app-title-card-preview
            *ngIf="contractsLabor.length === 0"
            style="width: 100%;"
            [icon]="'help'"
            [text]="'Aqui se visualizan los contratos laborales'"
            [textTooltip]="'Aqui se visualizan los contratos laborales'"
          ></app-title-card-preview>
        </div>
      </form>
      <!-- pre-loading -->
      <div class="content-card list-card pre-content" *ngIf="exitContractsOffline === false">
        <div class="form-group-list" *ngFor="let item of production.pre_loading">
          <div class="card">
            <div class="relational-info">
              <div class="desc flex" style="width: 18px;">
                <div class="icon-pre pre-loading"></div>
                <div class="title-pre pre-loading"></div>
              </div>
              <div class="icon-pre pre-loading"></div>
            </div>
            <div class="card-content">
              <div class="pre-img pre-loading"></div>
              <div class="content-information">
                <div class="text-pre pre-loading"></div>
                <div class="text-pre pre-loading"></div>
                <div class="text-pre pre-loading"></div>
              </div>
            </div>
          </div>
          <hr>
        </div>
      </div>
    </div>
  </div>

  <!-- ESTA ES EL ADMIN DE CONTRATOS Y PROYECTOS VINCULADOS -->
  <div>
    <div class="box" *ngIf="exitPermissionCompany('personalscontracts.view')">
      <div class="header">
        <div class="content" [ngClass]="configCard.color || 'primary'">{{'VINCULADOS A LOS CONTRATOS Y PROYECTOS' || 'N/A'}}</div>
      </div>
      <div class="content-card-contracts" *ngIf="exitContractsOffline === true">
        <div class="add-associate-contract">
          <div class="text-add-usuario">Vincular a contratos</div>
          <button mat-mini-fab [ngClass]="!exitPermissionCompany('contractspersonals.add') || flagListF === false? 'disabledAnalityco': 'blueAnalityco'" type="button" matTooltipPosition="above" matTooltip="crear"
                  (click)="associateContrac()" [disabled]="!exitPermissionCompany('contractspersonals.add') || flagListF === false ? true: false">
            <mat-icon>add</mat-icon>
          </button>
          <div class="text-add-usuario">Inactivar</div>
          <button mat-mini-fab [ngClass]="!exitPermissionCompany('firepersonal.update') || flagListF === false? 'disabledAnalityco': 'redInactivar'" type="button" matTooltipPosition="above" matTooltip="inactivar"
                  (click)="inactiveContrac()" [disabled]="!exitPermissionCompany('firepersonal.update') || flagListF === false ? true: false">
            <mat-icon>remove</mat-icon>
          </button>
        </div>
        <div class="form-group-list-scroll-states">
          <div class="form-group-list-contracts" *ngFor="let item of contracts || []; let i = index">
            <div class="card">
              <div class="relational-info" (click)="selectContract(i)">
                <div class="desc">
                  <mat-icon>location_city</mat-icon>
                  <div class="proj">
                    <h3 *ngFor="let proj of getNamesProyect(item.ProjectContracts) || [];">
                      <div [ngClass]="getCalculateStateDateString(proj.FinishDate)" style="padding-left: 2px; margin-top: -12px;" >{{proj.Name+','}}</div>
                    </h3>
                  </div>
                </div>
                <mat-icon [ngClass]="indexSelect === i ? 'activeBtn':'inactive'">visibility</mat-icon>
              </div>
              <div class="card-content" (click)="goContract(item?.Id)" style="cursor: pointer;">
                <img src="{{s3Bucket+item.FormImageLogo}}" alt="">
                <div class="content-information">
                  <div class="ellipsis">{{item.ContractReview || ''}}</div>
                  <div class="ellipsis" matTooltip="{{item.EmployerName}}">{{item.EmployerName || ''}}</div>
                  <div class="ellipsis baseline" [ngClass]="getCalculateStateDateString(item.FinishDateContract, item.Id)">
                    <mat-icon>today</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;
                    <div>{{fechasService.getDateFormatR(item.FinishDateContract) || ''}}&nbsp;&nbsp;{{item.ContractNumber || ''}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fields-group" (click)="selectContract(i)" >
              <div class="fields-group" (click)="selectContract(i)" [ngClass]="getCalculateStateDateMore(item.StartDate, item.Id)">
                <div class="labeTimePiecker" style="display: flex; flex-direction: column; align-items: flex-start; margin-right: 20px; margin-left: 10px;">
                  <mat-label class="classLabel">INICIO DEL ACCESO <mat-label *ngIf="getCalculateStateDateMore(item.StartDate, item.Id) ? 'DateRed':''" [ngClass]="getCalculateStateDateMore(item.StartDate, item.Id)+'-string'">({{getCalculateStateDateMoreLabel(item.StartDate, item.Id) }})</mat-label></mat-label>
                  <div class="input-field-medium-date-datetime" matTooltip="INICIO DEL ACCESO">
                    <mat-form-field appearance="outline">
                      <input matInput type="datetime-local"
                             style="max-width: 140px !important; height: 20px !important; display: flex; flex-direction: column;"
                             #inputDateStartDateContracttime
                             [value]="this.fechasService.getDateHours(item?.StartDate)"
                             [min]="minDateVigenciaContract"
                             (change)="changeDateFinishDateContractTime(inputDateStartDateContracttime?.value, false)"
                             [disabled]="!exitPermissionCompany('personalscontractsvalidity.config') || indexSelect !== i || getCalculateStateDate(item.FinishDateContract, item.Id) === 'DateRed' || flagListF === false"
                      >
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="fields-group" (click)="selectContract(i)" [ngClass]="getCalculateStateDateWithHORA(item.FinishDate, item.Id)">
                <div class="fields-group" style="margin-left: 1px;" (click)="selectContract(i)" [ngClass]="getCalculateStateDateWithHORA(item.FinishDate, item.Id)">
                  <div class="labeTimePiecker" style="display: flex; flex-direction: column;">
                    <mat-label class="classLabel">FIN DEL ACCESO <mat-label *ngIf="getIsOutDate(item.FinishDate, item.Id)" [ngClass]="getCalculateStateDateString(item.FinishDate, item.Id)">({{getCalculateStateDateAndHourDescription(item.FinishDate, item.Id)}})</mat-label></mat-label>
                    <div class="input-field-medium-date-datetime" matTooltip="FIN DEL ACCESO">
                      <mat-form-field appearance="outline">
                        <input matInput type="datetime-local"
                               style="max-width: 140px !important; height: 20px !important; display: flex; flex-direction: column;"
                               #inputDateFinishDateContracttime
                               [value]="this.fechasService.getDateHours(item?.FinishDate)"
                               (change)="changeDateFinishDateContractTime(inputDateFinishDateContracttime?.value, true)"
                               [min]="minDateVigenciaContract" [max]="maxDate"
                               [disabled]="!exitPermissionCompany('personalscontractsvalidity.config') || indexSelect !== i || getCalculateStateDateWithHORA(item.FinishDateContract, item.Id) === 'DateRed' || flagListF === false"
                        >
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br *ngIf="!exitPermissionCompany('personalscontractsposition.configposition')">
            <div class="fields-group" (click)="selectContract(i)">
              <div class="labeTimePiecker" style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 10px;">
                <mat-label class="classLabel">FUNCION</mat-label>
                <div class="input-field-medium-date-funcion" matTooltip="FUNCION">
                  <mat-form-field *ngIf="exitPermissionCompany('personalscontractsposition.configposition')" appearance="outline" style="width: 87%;">
                    <input matInput
                           type="text"
                           #inputPosition
                           [value]="item.Position"
                           (change)="changeinputPositionContract(inputPosition.value)"
                           [disabled]="indexSelect !== i || flagListF === false? true: false"
                           [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOptionItem #auto="matAutocomplete">
                      <mat-option *ngFor="let option of positionSujester" [value]="option" (click)="changeinputPositionContract(option)">
                        {{option || ''}}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>Requerido</mat-error>
                  </mat-form-field>
                  <div *ngIf="!exitPermissionCompany('personalscontractsposition.configposition')">{{item.Position}}</div>
                </div>
              </div>
            </div>

            <div class="fields-group" (click)="selectContract(i)">
              <div class="labeTimePiecker" style="display: flex; flex-direction: column; align-items: flex-start;  margin-left: 8px;">
                <div class="empleadorContratista" style="margin-bottom: 2px;">
                  <mat-label class="classLabel">EMPLEADOR ({{validateIfEmployerContratista(item.EmployerId, item.EmployerName) || ''}})</mat-label>
                </div>
                <div class="input-field-medium-date-funcion" matTooltip="EMPLEADOR" style="margin-top: -8px; margin-bottom:12px; ">
                  <app-select-search class="select-component-full-employerSelected"
                                     [style]="'width: 87%'"
                                     [appearance]="'outline'"
                                     [_dataSource]="allEmployers" [textInput]="''" [inputType]="'text'"
                                     [dataShow]="'Name'" [dataValue]="'Id'" [otherFilter]="'DocumentNumber'" [siteCtrl]="item.Control"
                                     [isCard]="true" [_value]="item.EmployerId"
                                     [card]="{img: 'Logo', title: 'Name', itemKey: 'DocumentType', itemValue:'DocumentNumber', finalLeft: 'Rol'}"
                                     (onSelectionChange)="onSelectedAllEmployer($event)"
                                     [disabled]="indexSelect !== i || flagListF === false? true: false"
                                     [onErrorUrl]="'employer'"
                  >
                  </app-select-search>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- pre-loading -->
      <div class="content-card list-card pre-content" *ngIf="exitContractsOffline === false">
        <div class="form-group-list" *ngFor="let item of production.pre_loading">
          <div class="card">
            <div class="relational-info">
              <div class="desc flex" style="width: 18px;">
                <div class="icon-pre pre-loading"></div>
                <div class="title-pre pre-loading"></div>
              </div>
              <div class="icon-pre pre-loading"></div>
            </div>
            <div class="card-content">
              <div class="pre-img pre-loading"></div>
              <div class="content-information">
                <div class="text-pre pre-loading"></div>
                <div class="text-pre pre-loading"></div>
                <div class="text-pre pre-loading"></div>
              </div>
            </div>
          </div>
          <hr>
        </div>
      </div>
    </div>
    <app-card-information *ngIf="!exitPermissionCompany('personalscontracts.view')"
                          [configCard]="{typeCard: 'default', title:'VINCULADOS A LOS CONTRATOS Y PROYECTOS'}"
                          [notListDescription]="'Aquí se visualizaran contratos y proyectos  vinculados'"
    >
    </app-card-information>
  </div>

  <!-- DOCUMENOTS Y REQUISITOS DE ACCESO -->
  <div oncopy='return false' oncut='return false'>
    <div class="box" *ngIf="exitPermissionCompany('personalsrequirements.view')">
      <div class="header">
        <div class="content" [ngClass]="'greendAnalityco2'">{{'DOCUMENTOS Y REQUISITOS DE ACCESO' || 'N/A'}}</div>
      </div>
      <div>
        <!-- pre-loading -->
        <div class="content-Document">
          <div class="content-card list-card pre-content" *ngIf="exitContractsOffline === false">
            <div class="form-group-list" *ngFor="let item of production.pre_loading">
              <div class="card">
                <div class="relational-info">
                  <div class="desc flex" style="width: 18px;">
                    <div class="icon-pre pre-loading"></div>
                    <div class="title-pre pre-loading"></div>
                  </div>
                  <div class="icon-pre pre-loading"></div>
                </div>
                <div class="card-content">
                  <div class="pre-img pre-loading"></div>
                  <div class="content-information">
                    <div class="text-pre pre-loading"></div>
                    <div class="text-pre pre-loading"></div>
                    <div class="text-pre pre-loading"></div>
                  </div>
                </div>
              </div>
              <hr>
            </div>
          </div>
          <div class="card-description" *ngIf="exitContractsOffline === true">
            <div class="form-group-list" *ngIf="contracts.length > 0">
              <div class="description-title">
                <div class="desc flex" style="width: 100%;">
                  <h3>{{'Requisitos de acceso por cargo '}}</h3>
                </div>
                <mat-icon *ngIf="flagListF === true && exitPermissionCompany('personalsrequirements.update')" class="icon-setting" (click)="openAcceso()" style="cursor: pointer;"
                >settings</mat-icon>
                <mat-icon *ngIf="flagListF === false || !exitPermissionCompany('personalsrequirements.update')" class="icon-setting-not" style="cursor: pointer;"
                >settings</mat-icon>
              </div>
            </div>
            <div class="form-group-list" *ngIf="contracts.length > 0">
              <form [formGroup]="formVigenciaArlExigida">
                <div class="description-title-req" style="max-width: 260px !important; margin-top: 10px;">
                  <div class="desc flex" style="width: 80%;">
                    <h3>{{'Tipo de riesgo exigido de ARL '}}</h3>
                  </div>
                  <app-select-search class="select-component-mini"
                                     [appearance]="'outline'"
                                     [_dataSource]="occupationalRisk" [textInput]="''" [dataShow]="'Description'" [dataValue]="'Description'" [siteCtrl]="formVigenciaArlExigida.get('TypeARL')"
                                     [inputType]="'text'" (onSelectionChange)="changeTypeRiesgo($event)" [disabled]="flagListF === false? true: false">
                  </app-select-search>
                </div>
              </form>
            </div>
            <div class="not-Information-Select" *ngIf="contracts.length === 0">No hay información</div>
          </div>

          <app-title-card-preview
            style="width: 100%;"
            [icon]="'error'"
            [text]="validatePersonalAlert() ? 'ACCESO AUTORIZADO' : 'ACCESO NO AUTORIZADO'"
            [textTooltip]="validatePersonalAlert() ? 'ACCESO AUTORIZADO' : 'ACCESO NO AUTORIZADO'"
            [subText]="subTitlePersonalAlert()"
            [isInfo]="false"
            [isAuthorized]="validatePersonalAlert()"
          ></app-title-card-preview>
        </div>

        <div class="content-card">
          <div class="sub-header"  *ngIf="contractsOffline.length > 0 && exitContractsOffline === true && contracts.length !== 0" >
            <div class="sub-content" style="background: #53DF79 !important;">{{'EXIGIDOS' || 'N/A'}}</div>
          </div>
          <div class="content-card-documentos list-card" *ngIf="contractsOffline.length > 0 && exitContractsOffline === true && contracts.length !== 0">
            <div class="form-group-list">
              <form>
                <div class="item-list-docs">
                  <h2 style="font-weight: 500; color: black; margin: 0px !important;" class="edadRange">
                    <svg *ngIf="edadRange === true" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 26 26" enable-background="new 0 0 26 26" width="100%" height="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                      <path d="m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z" fill="#03a9f4"></path>
                    </svg>
                    <svg *ngIf="edadRange === false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1_cache3" x="0px" y="0px" viewBox="0 0 174.239 174.239" style="enable-background:new 0 0 174.239 174.239;" xml:space="preserve" width="100%" height="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                        <path style="width: 40px; height: 40px;" d="M146.537,1.047c-1.396-1.396-3.681-1.396-5.077,0L89.658,52.849c-1.396,1.396-3.681,1.396-5.077,0L32.78,1.047  c-1.396-1.396-3.681-1.396-5.077,0L1.047,27.702c-1.396,1.396-1.396,3.681,0,5.077l51.802,51.802c1.396,1.396,1.396,3.681,0,5.077  L1.047,141.46c-1.396,1.396-1.396,3.681,0,5.077l26.655,26.655c1.396,1.396,3.681,1.396,5.077,0l51.802-51.802  c1.396-1.396,3.681-1.396,5.077,0l51.801,51.801c1.396,1.396,3.681,1.396,5.077,0l26.655-26.655c1.396-1.396,1.396-3.681,0-5.077  l-51.801-51.801c-1.396-1.396-1.396-3.681,0-5.077l51.801-51.801c1.396-1.396,1.396-3.681,0-5.077L146.537,1.047z" fill="#D80027"></path>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                                    </svg>
                    {{biometricMaxMin}}
                  </h2>
                </div>
                <div class="item-list-docs">
                  <mat-icon style="font-size: 26px !important;">today</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;<div>{{'Calendario: ' +'Dom,Lun,Mar,Mie,Jue,Vie,Sab'}}</div>
                </div>
                <div class="item-list-docs">
                  <mat-icon style="font-size: 26px !important;">schedule</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;<div>{{'Horario: ' +contractsOffline[contractsOffline.length === 1 ? 0 : indexSelect].MinHour + ' ' +contractsOffline[contractsOffline.length === 1 ? 0 : indexSelect].MaxHour}}</div>
                </div>
                <div class="item-list-docs" *ngFor="let socialSecur of socialSecurity || []" [ngSwitch]="socialSecur.Type">
                  <div class="doc-name">
                    <div class="text" *ngSwitchCase = "'EPS'">
                      <app-img
                        [type]="getshowDocumentos(socialSecur ,socialSecur.DocsJSON !== '{}' && socialSecur.DocsJSON !== '' && socialSecur.DocsJSON !== null ? 'negro': 'rojo')"
                        [text]="socialSecur.Type"
                        [color]="socialSecur.DocsJSON !== '{}' && socialSecur.DocsJSON !== '' && socialSecur.DocsJSON !== null ? 'negro': 'rojo'"
                        [width]="50"
                        [height]="50"
                        (click)="showDocumentos(socialSecur.DocsJSON, socialSecur.Id, socialSecur, 'SocialSecurity')"
                        matTooltipPosition="above"
                        [matTooltip]="socialSecur.Desc"
                      ></app-img>
                      <div class="select-component-medium" *ngIf="socialSecur.Model !== undefined && flagListF !== false">
                        <app-select-search class="select-component-medium" *ngSwitchCase = "'EPS'"
                                           [appearance]="'outline'"
                                           [disabled]="!this.exitPermissionCompany('personalsrequirements.updatebyposition')"
                                           [_dataSource]="eps" [textInput]="'EPS'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="controEps"
                                           [inputType]="'text'" (onSelectionChange)="replaceEPSorAFP({path: '/EpsId', value:$event})" >
                        </app-select-search>
                      </div>
                      <p class="description"
                         *ngIf="socialSecur.Model === undefined
                                            || flagListF === false"
                      >{{socialSecur.EPSName || ''}}
                      </p>
                      <div class="datepikerInput" [ngClass]="getCalculateStateDate(getDateDocsUploadIniadoSocialSecurity(socialSecur.Id))"
                           *ngIf=" ((socialSecur.Model === undefined && getCalculateStateDate(getDateDocsUploadIniadoSocialSecurity(socialSecur.Id)) === 'DateRed') && flagListF !== false) ||
                                                ((socialSecur.Model !== undefined) && flagListF !== false)">
                        <mat-form-field class="input-field-medium" appearance="outline">
                          <label><strong>{{socialSecur!.DateLabel || ''}}</strong></label>
                          <input matInput [matDatepicker]="pickerSocialEPS" #inputDateSocialEPS (click)="pickerSocialEPS.open()"
                                 (dateChange)="changeDateRequirement(socialSecur.Model, socialSecur.Id, inputDateSocialEPS.value)"
                                 [value]="getDateRequirement(socialSecur.Id)"
                                 [disabled]="!this.exitPermissionCompany('personalsrequirements.updatebyposition')"
                                 [min]="minDateSocialSecurity" [max]="maxDateSocialSecurity"
                          >
                          <mat-datepicker #pickerSocialEPS></mat-datepicker>
                          <mat-error>Requerido</mat-error>
                        </mat-form-field>
                      </div>
                      <p class="description"
                         *ngIf="(socialSecur.Model === undefined && getCalculateStateDate(getDateDocsUploadIniadoSocialSecurity(socialSecur.Id)) !== 'DateRed')
                                            || flagListF === false"
                      >{{getDateRequirement(socialSecur.Id) | date:'dd/MM/yyyy' || ''}}

                      </p>
                    </div>
                    <div class="text" *ngSwitchCase = "'ARL'">
                      <app-img
                        [type]="getshowDocumentos(socialSecur ,socialSecur.DocsJSON !== '{}' && socialSecur.DocsJSON !== '' && socialSecur.DocsJSON !== null ? 'negro': 'rojo')"
                        [text]="socialSecur.Type"
                        [color]="socialSecur.DocsJSON !== '{}' && socialSecur.DocsJSON !== '' && socialSecur.DocsJSON !== null ? 'negro': 'rojo'"
                        [width]="50"
                        [height]="50"
                        (click)="showDocumentos(socialSecur.DocsJSON, socialSecur.Id, socialSecur, 'SocialSecurity')"
                        matTooltipPosition="above"
                        [matTooltip]="socialSecur.Desc"
                      ></app-img>
                      <p class="description">{{personalInfo?.DescARL || ''}}</p>
                      <div class="datepikerInput" [ngClass]="getCalculateStateDate(getDateDocsUploadIniadoSocialSecurity(socialSecur.Id))"
                           *ngIf=" ((socialSecur.Model === undefined && getCalculateStateDate(getDateDocsUploadIniadoSocialSecurity(socialSecur.Id)) === 'DateRed') && flagListF !== false) ||
                                                    ((socialSecur.Model !== undefined) && flagListF !== false)">
                        <mat-form-field class="input-field-medium" appearance="outline">
                          <label><strong>{{socialSecur!.DateLabel || ''}}</strong></label>
                          <input matInput [matDatepicker]="pickerSocialARL" #inputDateSocialARL (click)="pickerSocialARL.open()"
                                 (dateChange)="changeDateRequirement(socialSecur.Model, socialSecur.Id, inputDateSocialARL.value)"
                                 [value]="getDateRequirement(socialSecur.Id)"
                                 [disabled]="!this.exitPermissionCompany('personalsrequirements.updatebyposition')"
                                 [min]="minDateSocialSecurity" [max]="maxDateSocialSecurity"
                          >
                          <mat-datepicker #pickerSocialARL></mat-datepicker>
                          <mat-error>Requerido</mat-error>
                        </mat-form-field>
                      </div>
                      <p class="description"
                         *ngIf="(socialSecur.Model === undefined && getCalculateStateDate(getDateDocsUploadIniadoSocialSecurity(socialSecur.Id)) !== 'DateRed')
                                            || flagListF === false"
                      >{{getDateRequirement(socialSecur.Id) | date:'dd/MM/yyyy' || ''}}
                      </p>
                    </div>
                    <div class="text" *ngSwitchCase = "'AFP'">
                      <app-img
                        [type]="getshowDocumentos(socialSecur ,socialSecur.DocsJSON !== '{}' && socialSecur.DocsJSON !== '' && socialSecur.DocsJSON !== null ? 'negro': 'rojo')"
                        [text]="socialSecur.Type"
                        [color]="socialSecur.DocsJSON !== '{}' && socialSecur.DocsJSON !== '' && socialSecur.DocsJSON !== null ? 'negro': 'rojo'"
                        [width]="50"
                        [height]="50"
                        (click)="showDocumentos(socialSecur.DocsJSON, socialSecur.Id, socialSecur, 'SocialSecurity')"
                        matTooltipPosition="above"
                        [matTooltip]="socialSecur.Desc"
                      ></app-img>

                      <div class="select-component-medium" *ngIf="socialSecur.Model !== undefined && flagListF !== false">
                        <app-select-search class="select-component-medium" *ngSwitchCase = "'AFP'"
                                           [appearance]="'outline'"
                                           [_dataSource]="afp" [textInput]="'AFP'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="controAfp"
                                           [inputType]="'text'" (onSelectionChange)="replaceEPSorAFP({path: '/AfpId', value:$event})" >
                        </app-select-search>
                      </div>
                      <p class="description"
                         *ngIf="socialSecur.Model === undefined
                                            || flagListF === false"
                      >{{socialSecur.AFPName || ''}}
                      </p>
                      <div class="datepikerInput" [ngClass]="getCalculateStateDate(getDateDocsUploadIniadoSocialSecurity(socialSecur.Id))"
                           *ngIf=" ((socialSecur.Model === undefined && getCalculateStateDate(getDateDocsUploadIniadoSocialSecurity(socialSecur.Id)) === 'DateRed') && flagListF !== false) ||
                                                    ((socialSecur.Model !== undefined) && flagListF !== false)">
                        <mat-form-field class="input-field-medium" appearance="outline">
                          <label><strong>{{socialSecur!.DateLabel || ''}}</strong></label>
                          <input matInput [matDatepicker]="pickerSocialAFP" #inputDateSocialAFP (click)="pickerSocialAFP.open()"
                                 (dateChange)="changeDateRequirement(socialSecur.Model, socialSecur.Id, inputDateSocialAFP.value)"
                                 [value]="getDateRequirement(socialSecur.Id)"
                                 [disabled]="!this.exitPermissionCompany('personalsrequirements.updatebyposition')"
                                 [min]="minDateSocialSecurity" [max]="maxDateSocialSecurity"
                                 oncopy="return false"
                          >

                          <mat-datepicker #pickerSocialAFP></mat-datepicker>
                          <mat-error>Requerido</mat-error>
                        </mat-form-field>
                      </div>
                      <p class="description"
                         *ngIf="(socialSecur.Model === undefined && getCalculateStateDate(getDateDocsUploadIniadoSocialSecurity(socialSecur.Id)) !== 'DateRed')
                                            || flagListF === false"
                      >{{getDateRequirement(socialSecur.Id) | date:'dd/MM/yyyy' || ''}}

                      </p>
                    </div>
                  </div>

                  <div class="TypeARLisValided" *ngSwitchCase = "'ARL'">
                    <svg *ngIf="IsValidedARL === true" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 26 26" enable-background="new 0 0 26 26" width="100%" height="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                      <path d="m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z" fill="#03a9f4"></path>
                    </svg>
                    <svg *ngIf="IsValidedARL === false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1_cache3" x="0px" y="0px" viewBox="0 0 174.239 174.239" style="enable-background:new 0 0 174.239 174.239;" xml:space="preserve" width="100%" height="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                        <path style="width: 40px; height: 40px;" d="M146.537,1.047c-1.396-1.396-3.681-1.396-5.077,0L89.658,52.849c-1.396,1.396-3.681,1.396-5.077,0L32.78,1.047  c-1.396-1.396-3.681-1.396-5.077,0L1.047,27.702c-1.396,1.396-1.396,3.681,0,5.077l51.802,51.802c1.396,1.396,1.396,3.681,0,5.077  L1.047,141.46c-1.396,1.396-1.396,3.681,0,5.077l26.655,26.655c1.396,1.396,3.681,1.396,5.077,0l51.802-51.802  c1.396-1.396,3.681-1.396,5.077,0l51.801,51.801c1.396,1.396,3.681,1.396,5.077,0l26.655-26.655c1.396-1.396,1.396-3.681,0-5.077  l-51.801-51.801c-1.396-1.396-1.396-3.681,0-5.077l51.801-51.801c1.396-1.396,1.396-3.681,0-5.077L146.537,1.047z" fill="#D80027"></path>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                                    </svg>
                    <svg *ngIf="IsValidedARL === null" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1_cache3" x="0px" y="0px" viewBox="0 0 174.239 174.239" style="enable-background:new 0 0 174.239 174.239;" xml:space="preserve" width="100%" height="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                        <path style="width: 40px; height: 40px;" d="M146.537,1.047c-1.396-1.396-3.681-1.396-5.077,0L89.658,52.849c-1.396,1.396-3.681,1.396-5.077,0L32.78,1.047  c-1.396-1.396-3.681-1.396-5.077,0L1.047,27.702c-1.396,1.396-1.396,3.681,0,5.077l51.802,51.802c1.396,1.396,1.396,3.681,0,5.077  L1.047,141.46c-1.396,1.396-1.396,3.681,0,5.077l26.655,26.655c1.396,1.396,3.681,1.396,5.077,0l51.802-51.802  c1.396-1.396,3.681-1.396,5.077,0l51.801,51.801c1.396,1.396,3.681,1.396,5.077,0l26.655-26.655c1.396-1.396,1.396-3.681,0-5.077  l-51.801-51.801c-1.396-1.396-1.396-3.681,0-5.077l51.801-51.801c1.396-1.396,1.396-3.681,0-5.077L146.537,1.047z" fill="#D80027"></path>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                      <g>
                                        </g>
                                    </svg>
                    <h3 class="text-description-title">Tipo de riesgo pagado</h3>
                    <app-select-search class="select-component-short"
                                       [appearance]="'outline'"
                                       [_dataSource]="occupationalRisk" [textInput]="''" [dataShow]="'Description'" [dataValue]="'Description'" [siteCtrl]="controOccupationalRisk"
                                       [inputType]="'text'" (onSelectionChange)="changeDateRequirement(socialSecur.Model, socialSecur.Id, null, $event)" [disabled]="socialSecur.Model === undefined || flagListF === false ? true : false">
                    </app-select-search>
                  </div>
                </div>

                <div class="item-list-docs" *ngFor="let certif of certification || []" >
                  <div class="doc-name" *ngIf="certifIsValition(certif) === true">
                    <div class="text">
                      <app-img
                        [type]="getshowDocumentos(certif , certif.Model === 'EmployerRequirement' ? 'EmployerRequirement': (certif.DocsJSON !== '{}' && certif.DocsJSON !== '' && certif.DocsJSON !== null ? 'negro': 'rojo'))"
                        [text]="certif.Type"
                        [color]="certif.DocsJSON !== '{}' && certif.DocsJSON !== '' && certif.DocsJSON !== null ? 'negro': 'rojo'"
                        [width]="50"
                        [height]="50"
                        (click)="showDocumentos(certif.DocsJSON, certif.Id, certif, 'Certification')"
                        matTooltipPosition="above"
                        [matTooltip]="certif.Desc"
                      ></app-img>

                      <p class="description">{{certif.Attr || ''}}</p>
                      <div class="datepikerInput"  [ngClass]="getCalculateStateDate(getDateDocsUploadIniadoSocialSecurity(certif.Id))"
                           *ngIf="certif.Model !== 'EmployerRequirement'">
                        <div *ngIf="!this.exitPermissionCompany('personalsrequirements.updatebyposition')">
                          <p><strong style="font-size: 12px; font-weight: bold;">{{certif!.DateLabel || ''}}</strong></p>
                          <p *ngIf="certif.Date != null"  [ngClass]="getCalculateStateDate(getDateDocsUploadIniadoSocialSecurity(certif.Id))+'-string'">{{getDateRequirement(certif.Id) | date:'dd/MM/yyyy' || ''}}</p>
                          <p *ngIf="certif.Date == null" class="DateRed-string">_ _ /_ _ /_ _</p>
                        </div>

                        <mat-form-field class="input-field-medium" [ngClass]="certif.IsDate === true ? 'visibility': 'visibilityhidden'" appearance="outline" *ngIf="this.exitPermissionCompany('personalsrequirements.updatebyposition')">
                          <label><strong>{{certif!.DateLabel || ''}}</strong></label>
                          <input matInput [matDatepicker]="picker" #inputDateCertificado (click)="picker.open()"
                                 (dateChange)="changeDateRequirement(certif.Model, certif.Id, inputDateCertificado.value)"
                                 [value]="getDateRequirement(certif.Id)"
                                 [disabled]="!this.exitPermissionCompany('personalsrequirements.updatebyposition') || certif.Model === 'EmployerRequirement' || flagListF === false? true: false">
                          <mat-datepicker #picker></mat-datepicker>
                          <mat-error>Requerido</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="dateTextNodate" *ngIf="certif.Model === 'EmployerRequirement'">{{getDateFormatR(certif?.Date)}}</div>
                    </div>
                  </div>

                </div>
                <div class="item-list-docs" *ngFor="let survey of surveys || []" >
                  <div class="doc-name">
                    <div class="text">
                      <svg  (click)="openDialogDetailsSurveySymptoms(survey.SurveyId)"
                            matTooltipPosition="above"
                            [matTooltip]="survey.Desc"
                            title="EPS" style="fill: #4C4C4C" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                        <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                        <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{survey.Type}}</text>
                      </svg>
                      <p class="description">{{survey.Attr || ''}}</p>
                      <div class="datepikerInput" [ngClass]="getCalculateStateDate(getDateDocsUploadIniadoSocialSecurity(survey.SurveyId))">
                        <mat-form-field class="input-field-medium" appearance="outline">
                          <label><strong>{{survey!.DateLabel || ''}}</strong></label>
                          <input matInput [matDatepicker]="pickersurvey" #inputDateSurvey (click)="pickersurvey.open()"
                                 (dateChange)="changeDateRequirement(survey.Model, survey.Id, inputDateSurvey.value)"
                                 [value]="getDateRequirement(survey.SurveyId)"
                                 [disabled]="true">
                          <mat-datepicker #pickersurvey></mat-datepicker>
                          <mat-error>Requerido</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="item-list-docs" *ngFor="let ent of entry || []" >
                  <div class="doc-name">
                    <div class="text">
                      <svg
                        matTooltipPosition="above"
                        [matTooltip]="ent.Desc"
                        title="EPS" style="fill: #4C4C4C" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                        <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                        <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{ent.Type}}</text>
                      </svg>
                      <p class="description">{{ent.Attr || ''}}</p>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-card-information *ngIf="!exitPermissionCompany('personalscontracts.view')"
                          [configCard]="{typeCard: 'default', title:'DOCUMENTOS Y REQUISITOS DE ACCESO'}"
                          [notListDescription]="'Aquí se visualizaran los documentos y requisitos de acceso'"
    >
    </app-card-information>

    <!-- CARNETS -->
    <div class="box information-fields" *ngIf="exitPermissionCompany('personalscontracts.view')">
      <div class="header">
        <div class="content" [ngClass]="'greendAnalityco2'">{{'CARNET' || 'N/A'}}</div>
      </div>
      <div class="content-card" *ngIf="exitContractsOffline === true">
        <div class="vigencia-contrat">

          <div class="carmets">
            <div class="subTitle">Empleador: {{employerName}}</div>
            <h3 class="label-carnet"><label>Imprimir carnet</label>
              <button mat-icon-button (click)="printCarnet()" *ngIf="flagListF === true && exitPermissionCompany('personalscarnets.print') && flagChangeContratista === false"
                      [ngClass]="personalContractSelected?.IsActive === false || personalContractSelected?.CarnetCodes === null || personalContractSelected?.CarnetCodes === '' || personalContractSelected?.CarnetCodes === '[]' ? 'disabled': ''"
                      [disabled]="personalContractSelected?.IsActive === false || personalContractSelected?.CarnetCodes === null || personalContractSelected?.CarnetCodes === '' || personalContractSelected?.CarnetCodes === '[]'" color="primary">
                <mat-icon >print</mat-icon>
              </button>
              <button mat-icon-button class="disabled"
                      *ngIf="flagListF === false || !exitPermissionCompany('personalscarnets.print') || (exitPermissionCompany('personalscarnets.print') && flagChangeContratista === true)"
                      [disabled]="true" color="primary">
                <mat-icon >print</mat-icon>
              </button>
            </h3>

            <h3 class="label-carnet"><label>Generar nuevo carnet</label>
              <button mat-icon-button (click)="generateCarnet()" *ngIf="flagListF === true && exitPermissionCompany('personalscarnets.generate') && flagChangeContratista === false"
                      [ngClass]="personalContractSelected?.IsActive === false ? 'disabled': ''"
                      [disabled]="personalContractSelected?.IsActive === false" color="primary">
                <mat-icon>print</mat-icon>
              </button>
              <button mat-icon-button class="disabled" *ngIf="flagListF === false || !exitPermissionCompany('personalscarnets.generate')  || (exitPermissionCompany('personalscarnets.print') && flagChangeContratista === true)"
                      [disabled]="true" color="primary">
                <mat-icon >print</mat-icon>
              </button>
            </h3>
          </div>
        </div>
      </div>

      <!-- pre-loading -->
      <div class="content-card list-card pre-content" *ngIf="exitContractsOffline === false">
        <div class="form-group-list" *ngFor="let item of production.pre_loading">
          <div class="card">
            <div class="relational-info">
              <div class="desc flex" style="width: 18px;">
                <div class="icon-pre pre-loading"></div>
                <div class="title-pre pre-loading"></div>
              </div>
              <div class="icon-pre pre-loading"></div>
            </div>
            <div class="card-content">
              <div class="pre-img pre-loading"></div>
              <div class="content-information">
                <div class="text-pre pre-loading"></div>
                <div class="text-pre pre-loading"></div>
                <div class="text-pre pre-loading"></div>
              </div>
            </div>
          </div>
          <hr>
        </div>
      </div>
    </div>
  </div>

</div>





