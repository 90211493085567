<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{data?.title || 'N/A'}}</div>
    </div>
    <div class="content-card list-card">
        <div class="fields-group">
            <app-select-search *ngIf="data"
                class="selected"
                [_dataSource]="data.data"
                [textInput]="data?.optionsCardSelect?.textInput"
                [dataShow]="data.optionsCardSelect?.dataShow"
                [otherFilter]="data.optionsCardSelect?.otherFilter"
                [dataValue]="data?.optionsCardSelect?.dataValue"
                [isCard]="data?.optionsCardSelect?.isCard"
                [isCardImage]="data?.optionsCardSelect?.isCardImage"
                [isDescription]="data?.optionsCardSelect?.isDescription"

                [card]="{
                    img: data?.optionsCardSelect?.card?.img,
                    title: data?.optionsCardSelect?.card?.title,
                    subTitle: data?.optionsCardSelect?.card?.subTitle,
                    finalLeft: data?.optionsCardSelect?.card?.finalLeft,
                    finalRight: data?.optionsCardSelect?.card?.finalRight,
                    itemKey: data?.optionsCardSelect?.card?.itemKey,
                    itemValue: data?.optionsCardSelect?.card?.itemValue
                }"
                (onSelectionChange)="selected($event)"
                [siteCtrl]="controlSelected"
                [inputType]="'text'"
                [selectOpen]="true"
                [placeholder]="'Seleccione...'">
            </app-select-search>
        </div>
      <div style="width: 100%; margin-top: 10px;" *ngIf="data?.message !== undefined" [ngClass]="data?.colorMessage !== undefined? data?.colorMessage: ''">{{data?.message || ''}}</div>
      <br>
        <div class="actions-dialog"style="margin: 3px;">
            <button mat-button class="btn-cancelar" *ngIf="data?.btnClose === true" (click)="close()" >{{data?.textClose}}</button>&nbsp;&nbsp;
            <button mat-button class="btn-generar" *ngIf="data?.btnConfirm === true"  (click)="save()">{{data?.textConfirm}}</button>
        </div>
    </div>

</div>

