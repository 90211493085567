<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'ADMINISTRADORA' || 'N/A'}}</div>
    </div>
    
    <div class="content-card" autocomplete="off">
        <button class="uploadPhoto" [ngClass]="formDataPhoto === undefined ? 'borderBtn': ''" (click)="updatePhotoPersonal()">
            <mat-icon>file_upload</mat-icon> 
            <div>Cargar logotipo</div>
        </button>
        <img style="margin-bottom: 10px; margin-top: 10px;" *ngIf="imgCropperResult" [src]="imgCropperResult" alt="" width="150" height="150">
        <form [formGroup]="form">
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>TIPO</strong></label>
                <input matInput type="text" formControlName="Type">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>DOCUMENTO</strong></label>
                <input matInput type="text" formControlName="DocNumber">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>NOMBRE</strong></label>
                <input matInput type="text" formControlName="Name">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>DESCRIPCIÓN</strong></label>
                <input matInput type="text" formControlName="Description">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>CÓDIGO</strong></label>
                <input matInput type="text" formControlName="Code">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <div class="form-group">
                <mat-form-field class="input-field-50" appearance="outline">
                    <label><strong>ORDEN</strong></label>
                    <input matInput type="number" formControlName="OrderNum"
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
                <div class="inputTogle">
                    <mat-slide-toggle formControlName="IsActive"></mat-slide-toggle>&nbsp;
                    <label>ESTADO</label>
                </div>
            </div>
            
        </form>
    </div>

    <div class="btn-actions"style="margin: 3px;">
        <button mat-button class="btn-cancelar" (click)="close()">{{'CANCELAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" (click)="save()">{{data.Id !== undefined ? 'ACTUALIZAR': 'GUARDAR'}}</button>
    </div>
</div>
