<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'SUCURSAL' || 'N/A'}}</div>
    </div>
    
    <div class="content-card list-card" >
        <form [formGroup]="formSucursal" (ngSubmit)="onSubmit()">
            <mat-form-field class="input-field">
                <label><strong>NOMBRE</strong></label>
                <input matInput type="text" formControlName="Name" onkeyup="this.value=this.value.substring(0, 1).toUpperCase() + this.value.substring(1).toLowerCase();">
                <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formSucursal.get('Name').setValue('')">X</div> -->
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <app-select-search class="select-component-short"
                    [_dataSource]="daneCity" [textInput]="'CIUDAD'" [dataShow]="'CityName'" [dataValue]="'CityCode'" [siteCtrl]="formSucursal.get('CityCode')" 
                    [inputType]="'text'">
        </app-select-search>
            <mat-form-field class="input-field toUpperCaseInput">
                <label><strong>DIRECCIÓN</strong></label>
                <input matInput type="text" formControlName="Address">
                <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formSucursal.get('Address').setValue('')">X</div> -->
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <div class="fields-group">
                <mat-form-field class="input-field-medium-more">
                    <label><strong>CORREO ELECTRÓNICO</strong></label>
                    <input matInput type="text" formControlName="Email">
                    <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formSucursal.get('Email').setValue('')">X</div> -->
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
                <mat-form-field class="input-field-medium-short">
                    <label><strong>TELÉFONO</strong></label>
                    <input matInput type="text" formControlName="Phone">
                    <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formSucursal.get('Phone').setValue('')">X</div> -->
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
            </div>
            <div class="actions-dialog"style="margin: 3px;">
                <button mat-button class="btn-cancelar" type="button" (click)="close()">CANCELAR</button>
                <button mat-button class="btn-generar" type="submit">GUARDAR</button>
            </div>
        </form>
        
    </div>
   
    
</div>