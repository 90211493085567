import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonMetodService } from '../../services/utils/commonMetod.service';
import { Router } from '@angular/router';
import { SnackBarService } from '../../services/utils/snackBar.service';

export interface UrlRouter {
  url: string;
  id: string;
}

@Component({
  selector: 'app-card-base',
  templateUrl: './card-base.component.html',
  styleUrls: ['./card-base.component.scss']
})
export class CardBaseComponent implements OnInit {

  s3Bucket: string;
  @Input() img: string;
  @Input() altImg: string;
  @Input() modulo: string;

  @Input() cardInfo: boolean = false;

  @Input() withStatus: boolean = false;
  @Input() status: boolean;

  @Input() urlRouter: UrlRouter;

  constructor(
    public commonMetod: CommonMetodService,
    private router: Router,
    private snackBService: SnackBarService,
  ) { }

  ngOnInit(): void {
    this.s3Bucket=environment.api.s3Bucket;
  }

  clickRouterNavigate() {
    if(this.urlRouter !== undefined) {
      this.router.navigate([this.urlRouter.url,this.urlRouter.id]);
    }else{
      this.snackBService.openSnackBar('Sin autorización para ver esta información.', 'X', 3000);
    }
  }

  
}
