
<div class="content-print" *ngIf="data.print === 'IMPRIMIR'">
    <div class="close" style="text-align: right; width: 100%; cursor: pointer;" *ngIf="!print">
        <h4 (click)="onNoClick()"><mat-icon>close</mat-icon></h4>
    </div>
    <div class="actions-dialog" style="margin-bottom: 3px;" *ngIf="print">
      <mat-radio-group  [(ngModel)]="position" style="margin-left: 35px;">
        <mat-radio-button color="warn" class="btn-cancelar" style="font-weight: bold; font-size: 16px;" value="horizontal"  (click)="position = 'horizontal'">HORIZONTAL</mat-radio-button>
        <mat-radio-button color="warn" class="btn-cancelar" style="font-weight: bold; font-size: 16px;" value="vertical" (click)="position = 'vertical'">VERTICAL</mat-radio-button>
      </mat-radio-group>

      <!-- <button mat-button class="btn-cancelar" [ngClass]="position === 'horizontal' ? 'btn-selected': 'btn-cancelar'" (click)="position = 'horizontal'">HORIZONTAL</button>-->
      <!-- <button mat-button class="btn-cancelar" [ngClass]="position === 'vertical' ? 'btn-selected': 'btn-cancelar'" (click)="position = 'vertical'">VERTICAL</button>-->
  </div>
  <div class="horizontal" *ngIf="position === 'horizontal' && personalSelected">
      <div class="first">
          <div class="title">{{personalSelected.EmployerName}}</div>
          <div class="content-horizontal">
              <img src="{{s3Bucket+personalSelected.Photo}}" alt="">
              <div class="information">
                  <div class="field">Nombre:</div>
                  <div class="field" style="text-align: center; font-weight: 700;">{{personalSelected.Name}}</div>
                  <div class="field">Apellido:</div>
                  <div class="field" style="text-align: center; font-weight: 700;">{{personalSelected.LastName}}</div>
                  <div class="field">Cargo:</div>
                  <div class="field" style="text-align: center; font-weight: 700;">{{personalSelected.Position}}</div>
                  <div class="field" style="text-align: center; font-weight: 700;">{{'ID'}}: {{personalSelected.DocumentNumber}}</div>
              </div>
          </div>
      </div>
      <div class="second">
          <div class="terms">
              Esta autorización de ingreso es de uso obligatorio y debe portarse en un lugar visible para poder ingresar al área restringida. En cualquier momento se podrá solicitar y en caso de no portarlo será retirado inmediatamente de las instalaciones
          </div>
          <div class="content-horizontal">
              <div class="information">
                  <div class="information-data">
                      <div class="filed">EPS: <label style="text-align: center; font-weight: 700;">{{personalSelected.NameEPS}}</label></div>
                      <div class="filed">ARL: <label style="text-align: center; font-weight: 700;">{{personalSelected.NameARL}}</label></div>
                      <div class="filed">RH: <label style="text-align: center; font-weight: 700;">{{personalSelected.RH}}</label></div>
                  </div>
              </div>
              <div class="content-qr">
                  <div class="qr" *ngIf="valueQR === ''">Espacio para QR</div>
                  <ngx-qrcode
                      *ngIf="valueQR !== ''"
                      [elementType]="elementType"
                      [errorCorrectionLevel]="correctionLevel"
                      [value]="valueQR"
                      alt="Demo QR Code"
                      cssClass="qr"
                      [width]="135">
                  </ngx-qrcode>
              </div>
          </div>
          <div class="information-autoriza">
            <hr>
            <div class="Aut">Autoriza: </div>
          </div>
      </div>
  </div>

  <div class="vertical" *ngIf="position === 'vertical' && personalSelected">
      <div class="first">
          <div class="title">{{personalSelected.EmployerName}}</div>
          <img src="{{s3Bucket+personalSelected.Photo}}" alt="">
          <div class="information">
              <div class="field" style="text-align: center; font-weight: 700;">{{personalSelected.Name}} {{personalSelected.LastName}}</div>
              <div class="field" style="text-align: center; font-weight: 700;">{{personalSelected.Position}}</div>
              <div class="field" style="text-align: center; font-weight: 700;">{{'ID'}}: {{personalSelected.DocumentNumber}}</div>
              <div class="field" style="text-align: center; font-weight: 700;">{{'RH'}}: {{personalSelected.RH}}</div>
          </div>
          <div class="information">
              <div class="information-data">
                  <div class="field">EPS: <label style="text-align: center; font-weight: 700;">{{personalSelected.NameEPS}}</label></div>
                  <div class="field">ARL: <label style="text-align: center; font-weight: 700;">{{personalSelected.NameARL}}</label></div>
              </div>
          </div>
      </div>
      <div class="second">
          <div class="content-vertical">
              <div class="content-qr">
                  <div class="qr" *ngIf="valueQR === ''">Espacio para QR</div>
                  <ngx-qrcode
                      *ngIf="valueQR !== ''"
                      [elementType]="elementType"
                      [errorCorrectionLevel]="correctionLevel"
                      [value]="valueQR"
                      alt="Demo QR Code"
                      cssClass="qr"
                      style="margin-top: -150px;"
                      [width]="135">
                  </ngx-qrcode>
              </div>
            <div>
              <hr style="margin-left: 5px; margin-right: 6px;">
              <div class="field" style="margin-top: 5px; margin-left: 5px">Autoriza: </div>
            </div>
              <div class="terms" style="margin-top: 15px;">
                  Esta autorización de ingreso es de uso obligatorio y debe portarse en un lugar visible para poder ingresar al área restringida. En cualquier momento se podrá solicitar y en caso de no portarlo será retirado inmediatamente de las instalaciones
              </div>
          </div>
      </div>
  </div>

  <div class="nota-qr" *ngIf="print && data.print !==  'IMPRIMIR'">El código QR se genera automaticamente al imprimir el carnet</div>
  <div class="actions-dialog" *ngIf="print" style="margin-top: 3px;">
      <button mat-button class="btn-cancelar" [mat-dialog-close]="data" >CANCELAR</button>&nbsp;&nbsp;
      <button mat-button class="btn-generar" (click)="rePrintCarnet()">IMPRIMIR</button>
  </div>

</div>

<div class="content-print" *ngIf="data.print === 'GENERAR'">
  <div class="close" style="text-align: right; width: 100%; cursor: pointer;" *ngIf="!print">
    <h4 (click)="onNoClick()"><mat-icon>close</mat-icon></h4>
  </div>
  <div class="actions-dialog" style="margin-bottom: 3px;" *ngIf="print">
    <mat-radio-group  [(ngModel)]="position" style="margin-left: 35px;">
      <mat-radio-button color="warn" class="btn-cancelar" style="font-weight: bold; font-size: 16px;" value="horizontal"  (click)="position = 'horizontal'">HORIZONTAL</mat-radio-button>
      <mat-radio-button color="warn" class="btn-cancelar" style="font-weight: bold; font-size: 16px;" value="vertical" (click)="position = 'vertical'">VERTICAL</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="horizontal" *ngIf="position === 'horizontal' && personalSelected">
    <div class="first">
      <div class="title">{{personalSelected.EmployerName}}</div>
      <div class="content-horizontal">
        <img src="{{s3Bucket+personalSelected.Photo}}" alt="">
        <div class="information">
          <div class="field">Nombre:</div>
          <div class="field" style="text-align: center; font-weight: 700;">{{personalSelected.Name}}</div>
          <div class="field">Apellido:</div>
          <div class="field" style="text-align: center; font-weight: 700;">{{personalSelected.LastName}}</div>
          <div class="field">Cargo:</div>
          <div class="field" style="text-align: center; font-weight: 700;">{{personalSelected.Position}}</div>
          <div class="field" style="text-align: center; font-weight: 700;">{{'ID'}}: {{personalSelected.DocumentNumber}}</div>
        </div>
      </div>
    </div>
    <div class="second">
      <div class="terms">
        Esta autorización de ingreso es de uso obligatorio y debe portarse en un lugar visible para poder ingresar al área restringida. En cualquier momento se podrá solicitar y en caso de no portarlo será retirado inmediatamente de las instalaciones
      </div>
      <div class="content-horizontal">
        <div class="information">
          <div class="information-data">
            <div class="filed">EPS: <label style="text-align: center; font-weight: 700;">{{personalSelected.NameEPS}}</label></div>
            <div class="filed">ARL: <label style="text-align: center; font-weight: 700;">{{personalSelected.NameARL}}</label></div>
            <div class="filed">RH: <label style="text-align: center; font-weight: 700;">{{personalSelected.RH}}</label></div>
          </div>
        </div>
        <div class="content-qr">
          <div class="qr" *ngIf="valueQR === ''">Espacio para QR</div>
          <ngx-qrcode
            *ngIf="valueQR !== ''"
            [elementType]="elementType"
            [errorCorrectionLevel]="correctionLevel"
            [value]="valueQR"
            alt="Demo QR Code"
            cssClass="qr"
            [width]="135">
          </ngx-qrcode>
        </div>
      </div>
      <div class="information-autoriza">
        <hr>
        <div class="Aut">Autoriza: </div>
      </div>
    </div>
  </div>

  <div class="vertical" *ngIf="position === 'vertical' && personalSelected">
    <div class="first">
      <div class="title">{{personalSelected.EmployerName}}</div>
      <img src="{{s3Bucket+personalSelected.Photo}}" alt="">
      <div class="information">
        <div class="field" style="text-align: center; font-weight: 700;">{{personalSelected.Name}} {{personalSelected.LastName}}</div>
        <div class="field" style="text-align: center; font-weight: 700;">{{personalSelected.Position}}</div>
        <div class="field" style="text-align: center; font-weight: 700;">{{'ID'}}: {{personalSelected.DocumentNumber}}</div>
        <div class="field" style="text-align: center; font-weight: 700;">{{'RH'}}: {{personalSelected.RH}}</div>
      </div>
      <div class="information">
        <div class="information-data">
          <div class="field">EPS: <label style="text-align: center; font-weight: 700;">{{personalSelected.NameEPS}}</label></div>
          <div class="field">ARL: <label style="text-align: center; font-weight: 700;">{{personalSelected.NameARL}}</label></div>
        </div>
      </div>
    </div>
    <div class="second">
      <div class="content-qr-generar" style="margin-top: 20px;">
        <div class="qr" *ngIf="valueQR === ''">Espacio para QR</div>
        <ngx-qrcode
          *ngIf="valueQR !== ''"
          [elementType]="elementType"
          [errorCorrectionLevel]="correctionLevel"
          [value]="valueQR"
          alt="Demo QR Code"
          cssClass="qr"
          style="margin-top: -150px;"
          [width]="135">
        </ngx-qrcode>
      </div>
      <div class="content-vertical-generar ">
        <div>
          <hr style="margin-left: 5px; margin-right: 6px; margin-top: -20px">
          <div class="field" style="margin-top: 5px; margin-left: 5px">Autoriza: </div>
        </div>
        <div class="terms" style="margin-top: 13px;">
          Esta autorización de ingreso es de uso obligatorio y debe portarse en un lugar visible para poder ingresar al área restringida. En cualquier momento se podrá solicitar y en caso de no portarlo será retirado inmediatamente de las instalaciones
        </div>
      </div>
    </div>
  </div>

  <div class="nota-qr">El código QR se genera automaticamente al imprimir el carnet</div>
  <div class="actions-dialog" *ngIf="print" style="margin-top: 3px;">
    <button mat-button class="btn-cancelar" [mat-dialog-close]="data" >CANCELAR</button>&nbsp;&nbsp;
    <button mat-button class="btn-generar" (click)="rePrintCarnet()">IMPRIMIR</button>
  </div>

</div>
