import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { Observable } from 'rxjs';
import { SocialSecPersonalList } from '../../models/SocialSecPersonalList';
import { FormArray } from '@angular/forms';
import { map } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import { SocialSecurity } from '../../models/SocialSecurity';
import { UserVinculadoEmployer } from '../../models/User';
import { PersonalReport, PersonalReportArray } from '../../models/PersonalReport';
import { CurrencyPipe } from '@angular/common';
import { PersonalNovelty } from '../../models/novelty';

@Injectable({
  providedIn: 'root'
})
export class EmployerService {

    constructor(
        private api: ApiService,
        private _http: HttpClient,
        private currencyPipe: CurrencyPipe
    ) { }

    // GET Employers
    getEmployers(){
        return this.api.get('api/Employer');
    }

    // GET Employers
    getEmployersAdminId(adminInfoId, page?, pageWindow?, Search?){
      return this._http.get<any>(
        this.api.getUrl(`api/Employer?adminInfoId=${adminInfoId}&${page !== undefined ? `page=${page}` : `page=1`}${pageWindow !== undefined ? `&pageWindow=${pageWindow}` : `&pageWindow=32`}${Search !== undefined && Search !== null ? `&search=${Search}` : ``}`));
    }

    getEmployersByPersonal(adminInfoId?) {
      return this.api.get(`api/Employer/Select?companyInfoId=${adminInfoId}`);
    }


  async getEmployersAsy(){
        return await this._http.get(this.api.getUrl('api/Employer')).toPromise();
      }

    // GET:ID Employer
    getEmployerId(id){
        return this.api.get('api/Employer/'+id);
    }

    getUsersEmployerIdVinculados(id, CompanyInfoParentId): Observable<UserVinculadoEmployer[]>{
        return this._http.get<UserVinculadoEmployer[]>(this.api.getUrl(`api/Employer/${id}/ContractingUsers?administratorInfoId=${CompanyInfoParentId}`));
    }

    /**
     * Permite traer las novedades / CRUD APIS NOVEDADES
     * @param CompanyId since/until filtro
     */
    getNovelty(CompanyId, since, until) {
        return this._http.get(this.api.getUrl(`api/Novelty/Company/${CompanyId}?since=${since}&until=${until}`));
    }

    postNovelty(model) {
        return this._http.post(this.api.getUrl('api/Novelty'), model);
    }

    putNovelty(noveltyId, model) {
        return this._http.put(this.api.getUrl(`api/Novelty/${noveltyId}`), model);
    }

    deleteNovelty(NoveltyId) {
        return this._http.delete(this.api.getUrl(`api/Novelty/${NoveltyId}`));
    }

    // Fin apis novedades

    getContractsIndividualByDocuments(DocumentType, DocumentNumber) {
        return this._http.get(this.api.getUrl(`api/IndividualContract/ByDocument?DocumentType=${DocumentType}&DocumentNumber=${DocumentNumber}`));
    }

    // Post EMpoyer
    postEmployer(model) {
        return this._http.post(this.api.getUrl('api/Employer'), model);
    }

    putEmployer(Id, model) {
        return this._http.put(this.api.getUrl(`api/Employer/${Id}`), model);
    }

    putEmloyerUpdateLogo(Id, file) {
        return this._http.put(this.api.getUrl(`api/Employer/UpdateLogo/${Id}`), file);
    }

    postAddCCFemployerId(employerId, CCFId: number[]) {
        return this._http.post(this.api.getUrl(`api/Employer/${employerId}/AddCCF`), CCFId);
    }

    postContractingUsers(Id, model){
        return this._http.post(this.api.getUrl(`api/Employer/${Id}/ContractingUsers`), model);
    }

    postContractUsers(Id, model){
        return this._http.post(this.api.getUrl(`api/Contract/${Id}/Users`), model);
    }

    deleteAddCCFemployerId(employerId, CCFId) {
        return this._http.delete(this.api.getUrl(`api/Employer/${employerId}/RemoveCCF?CCFId=${CCFId}`));
    }

    deleteContractingUsers(employerId, userId){
        return this._http.delete(this.api.getUrl(`api/Employer/${employerId}/ContractingUsers?userId=${userId}`));
    }

    getAddCCFemployerId(employerId) {
        return this._http.get(this.api.getUrl(`api/Employer/${employerId}/GetCCF`));
    }

    // GET PERSONAL ACTIVE, PLANILLA
    getPersonalSocialSecurity(id, params): Observable<SocialSecPersonalList[]> {
        const url = this.api.getUrl('api/SocialSecurity/Personal/' + id);
        return this._http.get<SocialSecPersonalList[]>(url, { params});
    }

    // GET PERSONAL ACTIVE, PLANILLA AS FORMARRAY
    getSocialSecPersonalListAsFormArray(id, params): Observable<FormArray> {
        return this.getPersonalSocialSecurity(id, params).pipe(map((personalCompanyInfoId: SocialSecPersonalList[]) => {
          // Maps all the PersonalCompanyInfoId into a formGroup defined in tge (PersonalCompanyInfoId.ts MODEL)
          const fgs = personalCompanyInfoId.map(SocialSecPersonalList?.asFormGroup);
          return new FormArray(fgs);
        }));
    }

    // GET REFERENCES ARL EPS RIESGOS
    getSecurityReferences() {
        return this.api.get('api/SecurityReferences');
    }

    // GET Planillas creadas por company employer
    getSocialSecurity(companyInfoId: string) {
        const url = this.api.getUrl('api/SocialSecurity?companyInfoId=' + companyInfoId);
        return this._http.get(url);
    }

    // GET Planilla por id
    getSocialSecurityId(Id: number): Observable<SocialSecurity> {
        const url = this.api.getUrl('api/SocialSecurity/'+Id);
        return this._http.get<SocialSecurity>(url);
    }

    getSocialSecurityAsFormArray(id): Observable<FormArray> {
        return this.getSocialSecurityId(id).pipe(map((socialSecurity: SocialSecurity) => {
          const fgs = socialSecurity.SocialSecPersonalList.map(SocialSecPersonalList.asFormGroup);
          return new FormArray(fgs);
        }));
    }

    // Gestor de documentos
    getExtractionInfo(Id) {
        return this.api.get('api/SocialSecurity/ExtractionInfo/'+Id);
    }

    getExtractedPeople(Id) {
        return this._http.get(this.api.getUrl(`api/SocialSecurity/ExtractedPeople/${Id}`));
    }

    socialSecurityExtractedPeopleFormArray(Id) {
        return this.getExtractedPeople(Id).pipe(map((socialSecurity: SocialSecPersonalList[]) => {
            const fgs = socialSecurity.map(SocialSecPersonalList.asFormGroup);
            fgs.forEach((formGroup, index) => {
                formGroup?.controls?.No.setValue(index + 1);
                formGroup?.controls?.Extracted.setValue(true);
                // parse salary
                if (formGroup?.controls?.IBCAFP.value !== 0 && formGroup?.controls?.IBCAFP.value !== null &&
                  formGroup?.controls?.IBCAFP.value !== undefined){
                    const valuesetSalary = this.currencyPipe.transform(formGroup?.controls?.IBCAFP.value.toString().replace(
                      /\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '1.0-0').replace(/,/gi, '.');
                    formGroup?.controls?.IBCAFP.setValue(valuesetSalary);
                }
                if (formGroup?.controls?.IBCARL.value !== 0 && formGroup?.controls?.IBCARL.value !== null &&
                  formGroup?.controls?.IBCARL.value !== undefined){
                    const valuesetSalary = this.currencyPipe.transform(formGroup?.controls?.IBCARL.value.toString().replace(
                      /\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '1.0-0').replace(/,/gi, '.');
                    formGroup?.controls?.IBCARL.setValue(valuesetSalary);
                }
                if (formGroup?.controls?.IBCEPS.value !== 0 && formGroup?.controls?.IBCEPS.value !== null &&
                  formGroup?.controls?.IBCEPS.value !== undefined){
                    const valuesetSalary = this.currencyPipe.transform(formGroup?.controls?.IBCEPS.value.toString().replace(
                      /\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '1.0-0').replace(/,/gi, '.');
                    formGroup?.controls?.IBCEPS.setValue(valuesetSalary);
                }
            });
            return new FormArray(fgs);
        }));
    }

    postSocialSecurityExtractionInfo(model) {
        return this._http.post(this.api.getUrl('api/SocialSecurity/ExtractionInfo'), model);
    }

    putSocialSecurityExtractionInfo(Id, model) {
        return this._http.put(this.api.getUrl('api/SocialSecurity/ExtractionInfo/'+Id), model);
    }

    postExtractionInfo(id) {
        return this._http.post(this.api.getUrl('api/SocialSecurity/ProcessDocument/'+id), null);
    }

    // PUT FILE SocialSecurity
    updateFilesocialSecurityExtractionInfo(id, file) {
        return this._http.put(this.api.getUrl('api/SocialSecurity/ExtractionInfo/Document/'+id), file);
    }

    // PUT FILE SocialSecurity
    deleteFilesocialSecurityExtractionInfo(id) {
        return this._http.delete(this.api.getUrl('api/SocialSecurity/ExtractionInfo/Document/'+id));
    }

    // PUT UPDATE SocialSecurity (update planillas)
    putSocialSegurity(id, socialSecurity) {
        return this._http.put(this.api.getUrl('api/SocialSecurity/'+id), socialSecurity);
    }

    // PUT FILE SocialSecurity
    updateFilesocialSecurity(id, file) {
        return this._http.put(this.api.getUrl('api/SocialSecurity/UpdateFile/'+id), file);
    }

    // PUT FILE comentario solo
    updateCommentsocialSecurityCommentary(id, commentary) {
        return this._http.put(this.api.getUrl('api/SocialSecurity/UpdateComment/'+id+'?commentary='+commentary), null);
    }

updateNoverdadRetiro(personalCompanyInfoId, idPlanilla) {
        idPlanilla = parseInt(idPlanilla);
        return this._http.put(this.api.getUrl('api/Contract/FirePersonal'),
          {SocialSecurityId: idPlanilla, personalCompanyInfoId: personalCompanyInfoId});
    }

    // UpdateDocsJson PLanilla
    updateDocsJsonPlanilla(idPlanilla: number, docs) {
        return this._http.put(this.api.getUrl('api/SocialSecurity/DeleteFiles/'+idPlanilla), docs);
    }

    updateReplaceAfpOrEps(personalId, replace) {
        return this._http.patch(this.api.getUrl('api/Person/'+personalId), replace);
    }

    // Employers POSITIONS APIS CRUD
    getPositionEmployerId(employerId) {
        return this._http.get(this.api.getUrl(`api/Position/Employer/${employerId}`));
    }
    postPosition(model) {
        return this._http.post(this.api.getUrl('api/Position'), model);
    }
    putPosition(id, model) {
        return this._http.put(this.api.getUrl(`api/Position/${id}`), model);
    }

    deletePosition(id) {
        return this._http.delete(this.api.getUrl(`api/Position/${id}`));
    }

    // novedades
    getSocialsecuritydocument(period: string) {
        return this._http.get(this.api.getUrl(`api/socialsecuritydocument/pila/period/${period}`));
    }

    postSocialsecuritydocument(period: string, CompanyId) {
        return this._http.post(this.api.getUrl(`api/SocialSecurityDocument/pila/Generate/${CompanyId}/${period}`), CompanyId);
    }

    putSocialsecuritydocumentState(period: string, CompanyId, idState) {
        return this._http.put(this.api.getUrl(`api/socialsecurity/socialsecuritydocument/${CompanyId}/ChangeState?period=${period}&stateId=${idState}`), CompanyId);
    }

    // Empleados
    GetPersonal(IdCompany) {
        return this._http.get(this.api.getUrl(`api/Employer/${IdCompany}/GetPersonal`));
    }

    // Contratos
    GetContracts(IdCompany, type?) {
        return this._http.get(this.api.getUrl(`api/Employer/${IdCompany}/GetContracts`+(type !== undefined ? '?type='+type : '' )));
    }

    getSendEmailPlanilla(employerInfoId, emails) {
        return this._http.get(this.api.getUrl(`api/SocialSecurityDocument/EmailPlanilla/${employerInfoId}?emails=${emails}`));
    }

    getEmailPlanilla(employerInfoId) {
        return this._http.get(this.api.getUrl(`api/SocialSecurityDocument/ContactorEmails/${employerInfoId}`));
    }

    getEmployerIds(ids) {
        return this._http.get(this.api.getUrl(`api/Employer/ByIds?Ids=${ids}`));
    }

    getLugarTrabajoNoveltyCreate(employerId, startDate?, endDate?) {
        return this._http.get(this.api.getUrl(`api/PersonalEmployer/SelectByEmployer/${employerId}`));
    }

    getPersonalTrabajoNovelty(employerId, startDate?, endDate?) {
        return this._http.get(this.api.getUrl(`api/PersonalEmployer/SelectByEmployer/${employerId}?startDate=${startDate}&endDate=${endDate}`));
    }

    getPersonalEmployerEmployerInfoId(employerInfoId) {
        return this._http.get(this.api.getUrl(`api/PersonalEmployer?employerInfoId=${employerInfoId}&onlyActive=true`));
    }

    postNoveltyDialog(model) {
        return this._http.post(this.api.getUrl(`api/Novelty`), model);
    }

    // GET SALIDAS Y ENTRADAS
    getPersonalReport(employerId, data?): Observable<PersonalReport[]> {
        const url = this.api.getUrl(`api/PersonalReport/Memorandum/${employerId}?date=${data}`);
        return this._http.get<PersonalReport[]>(url);
    }

    getPayrollTimeEmployerInfoId(companyId, EmployerInfoId) {
        return this._http.get(this.api.getUrl(`api/PayrollTime/${companyId}?EmployerinfoId=${EmployerInfoId}`));
    }

    getPayrollTimePlaceOfWorkEmployerInfoId(employerInfoId) {
        return this._http.get(this.api.getUrl(`api/PayrollTime/PlaceOfWork/${employerInfoId}`));
    }

    // GET PERSONAL ACTIVE, PLANILLA AS FORMARRAY
    getPersonalReportMemorandumPlaceOfWork(employerId, projectId, data?, endDate?, personalCompanyInfoId?): Observable<PersonalReport[]> {
        const url = this.api.getUrl(`api/PersonalReport/MemorandumPlaceOfWork/${projectId}?EmployerInfoId=${employerId}&date=${data}${endDate !== undefined ? '&endDate='+endDate: ''}${personalCompanyInfoId !== undefined ? '&personalCompanyInfoId='+personalCompanyInfoId: ''}`);
        return this._http.get<PersonalReport[]>(url);
    }

    getPersonalReportMemorandumPlaceOfWorkArray(employerId, projectId, data?, endDate?, personalCompanyInfoId?): Observable<FormArray> {
        return this.getPersonalReportMemorandumPlaceOfWork(employerId, projectId, data,
          endDate, personalCompanyInfoId).pipe(map((personal: PersonalReport[]) => {
            const fgs = personal.map(PersonalReportArray.asFormGroup);
            return new FormArray(fgs);
        }));
    }

    getNoveltyComponent(employerInfoId, startDate, endDate): Observable<PersonalNovelty[]> {
        const url = this.api.getUrl(`api/Novelty/${employerInfoId}?startDate=${startDate}&endDate=${endDate}`);
        return this._http.get<PersonalNovelty[]>(url);
    }

    getNoveltyComponentArrayForm(employerId, projectId, data?): Observable<FormArray> {
        return this.getNoveltyComponent(employerId, projectId, data).pipe(map((personal: PersonalNovelty[]) => {
            const fgs = personal.map(PersonalNovelty.asFormGroup);
            return new FormArray(fgs);
        }));
    }

    putNoveltyDialog(noveltyId, model) {
        return this._http.put(this.api.getUrl(`api/Novelty/${noveltyId}`), model);
    }

    putNoveltyDialogFile(noveltyId, formData) {
        return this._http.put(this.api.getUrl(`api/Novelty/UpdateFile/${noveltyId}`), formData);
    }

    deleteNoveltyDialog(noveltyId) {
        return this._http.delete(this.api.getUrl(`api/Novelty/${noveltyId}`));
    }

    // Post Message alone
    postNoveltyMessage(model) {
        return this._http.post(this.api.getUrl(`api/Novelty/Comment`), model);
    }

    // PersonalReport New APIS
    postPersonalReportMemorandumPlaceOfWorkEvent(model) {
        return this._http.post(this.api.getUrl(`api/PersonalReport/MemorandumPlaceOfWorkEvent`), model);
    }

    // postPersonalSocialSecurityNovelty New APIS
    postPersonalSocialSecurityNovelty(model) {
        return this._http.post(this.api.getUrl(`api/Novelty`), model);
    }

    // GET PersonalReportEventReason
    getPersonalReportEventReason(AdministratorId: string): Observable<any[]> {
        const url = this.api.getUrl('api/Company/PersonalReportEventReason?AdministratorId='+AdministratorId);
        return this._http.get<any[]>(url);
    }

    deletePayrollNewsType(Id: string): Observable<any[]> {
        const url = this.api.getUrl(`api/PayrollNewsType/${Id}`);
        return this._http.delete<any[]>(url);
    }

    // EMPLOYER REQUIREMENTS

    /**
     * Permite consular documentos requerido por empresa
     * @param
     * @param Id
     */

    getEmployerRequirement(id) {
        return this._http.get(this.api.getUrl(`api/EmployerRequirement/?employerId=${id}`));
    }

    /**
     * Permite guardar documentos requerido por empresa
     * @param Id
     */

    postEmployerRequirement(model) {
        return this._http.post(this.api.getUrl(`api/EmployerRequirement`), model);
    }

    /**
     * Permite elimnar documento requerido por empresa
     * @param Id
     */

     deleteEmployerRequirement(id) {
        return this._http.delete(this.api.getUrl(`api/EmployerRequirement/${id}`));
    }

    /**
     * Permite actualizar documento requerido por empresa
     * @param Id
     */
     putEmployerRequirement(id, model) {
        return this._http.put(this.api.getUrl(`api/EmployerRequirement/${id}`), model);
    }

    /**
     * Permite actualizar el documento por documento requerido por empresa
     * @param Id
     */
     putEmployerRequirementFile(id, formData) {
        return this._http.put(this.api.getUrl(`api/EmployerRequirement/UpdateFile/${id}`), formData);
    }

    /**
     * Permite actualizar documento requerido por empresa
     * @param Id
     */
     deleteEmployerRequirementFile(id, model) {
        return this._http.put(this.api.getUrl(`api/EmployerRequirement/DeleteFiles/${id}`), model);
    }

    getPayrollTime(companyInfoId, EmployerinfoId) {
        return this._http.get(this.api.getUrl(`api/PayrollTime/${companyInfoId}?EmployerinfoId=${EmployerinfoId}`));
    }

    postPayrollTime(model) {
      return this._http.post(this.api.getUrl(`api/PayrollTime`), model);
    }

    putPayrollTime(id, model) {
      return this._http.put(this.api.getUrl(`api/PayrollTime/${id}`), model);
    }

    deletePayrollTime(id) {
      return this._http.delete(this.api.getUrl(`api/PayrollTime/${id}`));
    }

    getPayrollConfiguration(id: string) {
        return this.api.get('api/PayrollConfiguration/'+id);
    }

    postPayrollConfigurationEmployer(model) {
        return this._http.post(this.api.getUrl('api/PayrollConfiguration/v2'), model);
    }

    putPayrollConfigurationEmployer(Id, model) {
      return this._http.put(this.api.getUrl('api/PayrollConfiguration/v2/'+Id), model);
    }

    // prenomina
    getPrePayroll(employerInfoId: string) {
        return this.api.get('api/PrePayroll?employerInfoId='+employerInfoId);
    }

    postPrePayroll(employerInfoId, payrollTimeId, placeOfWorkId) {
        return this._http.post(this.api.getUrl(`api/PrePayroll/Calculate?employerInfoId=${employerInfoId}&payrollTimeId=${payrollTimeId}&placeOfWorkId=${placeOfWorkId}`), null);
    }

    putPrepayrollBlock(prepayrollId, block) {
        return this._http.put(this.api.getUrl(`api/Prepayroll/Block/${prepayrollId}?block=${block}`), null);
    }

    /**
     * Permite ver el gestor de documentos por empresa
     */
    getDocumentInfoEmployer(adminInfoId, page?, pageWindow?, filter?, Search?) {
        return this._http.get<any>(
            this.api.getUrl(`api/DocumentInfo/Employer/${adminInfoId}?${page !== undefined ? `page=${page}`: `page=1`}${pageWindow !== undefined ? `&pageWindow=${pageWindow}`: `&pageWindow=32`}${filter !== undefined ? `&stage=${filter}`: `&stage=ALL`}${Search !== undefined && Search !== null ? `&search=${Search}`: ``}`));
    }

    /**
     * Permite ver el gestor de documentos por empresa
     */
    getDocumentInfoEmployerNotification(adminInfoId, page?, pageWindow?, filter?, Search?) {
        return this._http.get<any>(
            this.api.getUrl(`api/DocumentInfo/Employer/Notification/${adminInfoId}?${page !== undefined ? `page=${page}`: `page=1`}${pageWindow !== undefined ? `&pageWindow=${pageWindow}`: `&pageWindow=32`}  ${Search !== undefined && Search !== null ? `&search=${Search}`: ``}`));
    }

    /**
     * Permite ver el gestor de documentos por empresa
     */
    getDocumentInfoPersonal(adminInfoId, page?, pageWindow?, filter?, Search?) {
        return this._http.get<any>(
            this.api.getUrl(`api/DocumentInfo/Personal/${adminInfoId}?${page !== undefined ? `page=${page}`: `page=1`}${pageWindow !== undefined ? `&pageWindow=${pageWindow}`: `&pageWindow=32`}${filter !== undefined ? `&stage=${filter}`: `&stage=ALL`}${Search !== undefined && Search !== null ? `&search=${Search}`: ``}`));
    }

    /**
     * Permite ver el gestor de documentos por empresa
     */
    getDocumentInfoPersonalNotification(adminInfoId, page?, pageWindow?, filter?, Search?) {
        return this._http.get<any>(
            this.api.getUrl(`api/DocumentInfo/Personal/Notification/${adminInfoId}?${page !== undefined ? `page=${page}`: `page=1`}${pageWindow !== undefined ? `&pageWindow=${pageWindow}`: `&pageWindow=32`}  ${Search !== undefined && Search !== null ? `&search=${Search}`: ``}`));
    }

    /**
     * Permite ver el gestor de documentos por planillas
     */
    getDocumentInfoSocialSecurity(adminInfoId, page?, pageWindow?, filter?, Search?) {
        return this._http.get<any>(
            this.api.getUrl(`api/DocumentInfo/SocialSecurity/${adminInfoId}?${page !== undefined ? `page=${page}`: `page=1`}${pageWindow !== undefined ? `&pageWindow=${pageWindow}`: `&pageWindow=32`}${filter !== undefined ? `&stage=${filter}`: `&stage=ALL`}${Search !== undefined && Search !== null ? `&search=${Search}`: ``}`));
    }

    /**
     * Permite ver el gestor de documentos por planillas
     */
    getDocumentInfoSocialSecurityNotification(adminInfoId, page?, pageWindow?, filter?, Search?) {
        return this._http.get<any>(
            this.api.getUrl(`api/DocumentInfo/SocialSecurity/Notification/${adminInfoId}?${page !== undefined ? `page=${page}`: `page=1`}${pageWindow !== undefined ? `&pageWindow=${pageWindow}`: `&pageWindow=32`}  ${Search !== undefined && Search !== null ? `&search=${Search}`: ``}`));
    }
    /**
     * Permite ver el historico del documento
     */
    getDocumentInfoEmployerHistorical(id) {
        return this._http.get<any>(this.api.getUrl(`api/DocumentInfo/Historical/${id}`));
    }

    /**
     * Permite ver historico por employer
     */
    postDocumentInfo(model ,module) {
        return this._http.post(this.api.getUrl(`api/DocumentInfo/${module}`), model);
    }

    /**
     * Permite actualizar el documento por documento requerido por empresa
     * @param documentInfoId
     */
    putDocumentInfoFile(documentInfoId, formData) {
        return this._http.put(this.api.getUrl(`api/DocumentInfo/UploadFile/${documentInfoId}`), formData);
    }

    getCountDocumentInfoEmployer(Id) {
        return this._http.get<any>(this.api.getUrl(`api/DocumentInfo/Employer/Count/${Id}`));
    }

    getCountDocumentInfoSocialSecurity(Id) {
        return this._http.get<any>(this.api.getUrl(`api/DocumentInfo/SocialSecurity/Count/${Id}`));
    }

    /**
     * Permite actualizar el comentario sin archivo
     * @param comment
     */
    putEmployerRequirementUpdateComment(id, comment) {
        return this._http.put(this.api.getUrl(`api/EmployerRequirement/UpdateComment/${id}?comment=${comment}`), null);
    }

  putExtractFromDocumentEmployer(compnayInfoId: string, file) {
    return this._http.put(this.api.getUrl(`api/Employer/ExtracFromDoc/${compnayInfoId}`), file);
  }

  getExtractFromDocumentEmployer(companyInfoId: string) {
    return this._http.get(this.api.getUrl(`api/Employer/DatasheetExtraction/${companyInfoId}`));
  }

}




