import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';
import { EmployerService } from 'src/app/core/services/modules/employer.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from 'src/app/core/services/utils/dialog.service';
import { ContractService } from 'src/app/core/services/modules/contract.service';
import { FechasService } from 'src/app/core/services/utils/fechas.service';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { CardList } from 'src/app/core/interfaces/interfaces';
import {from} from 'rxjs';
import {catchError, concatMap} from 'rxjs/operators';

export interface DataShowVincularContracts {
  personalId: number;
  employerId: string;
}

export interface DialogData {
  personalCompanyInfoId: any;
  data: any;
  proyects: any;
  dataModuleRequest: DataShowVincularContracts;
  isFromVehicle: boolean;
  adminInfoId: any;
}

@Component({
  selector: 'app-dialog-person-associate-contracts',
  templateUrl: './dialog-person-associate-contracts.component.html',
  styleUrls: ['./dialog-person-associate-contracts.component.scss']
})
export class DialogPersonAssociateContractsComponent implements OnInit {
  s3Bucket: string;
  progressQuery: any = null;
  personalCompanyInfoId: any;

  now = new Date();
  controlPosition = new FormControl();
  controlStartDate = new FormControl();
  controlFinisthDate = new FormControl();
  controlSearchEmployer = new FormControl();
  controlSearchContract = new FormControl();
  controlSearchEmpleador = new FormControl();
  minDate = this.fechasService.getDateNow();

  isFromVehicleFlag = false;

  companyInfoId: string;

  projects: any[] = [];
  employers: any[] = [];
  contracts: any[] = [];
  movTranslado: any[] = [];
  contractsCopy: any[] = [];
  contractsSAVED: any[] = [];
  employerSelected: any [] = [];
  contractsSelected: any[] = [];
  positionSujester: string[];

  constructor(
    public dialogRef: MatDialogRef<DialogPersonAssociateContractsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private snackBService: SnackBarService,
    private employerService: EmployerService,
    private contractService: ContractService,
    public fechasService: FechasService,
    private commonService: CommonService
  ) {
    this.isFromVehicleFlag = this.data.isFromVehicle;
    this.projects = this.data.proyects;
    this.personalCompanyInfoId = this.data.personalCompanyInfoId;
    this.companyInfoId = this.data.adminInfoId;
    if (this.isFromVehicleFlag === true){
      this.data.data.forEach(data => {
        this.contractsSAVED.push(
          {
            ProjectStageContracts: [{ProjectName: data.ProjectNames[0]}],
            FormImageLogo: data?.FormImageLogo,
            ContractReview: data?.ContractReview,
            EmployerName: data?.EmployerName,
            ContractNumber: data?.ContractNumber,
            FinishDate: data?.Projects[0]?.FinishDate,
            IsActiveContractProject: data?.Projects[0]?.IsProjectActive,
            Id: data?.ContractId,
            StartDateContract: data?.StartDate,
            FinishDateContract: data?.FinishDate
          }
        );
      });
    }else{
      this.data.data.forEach(data => {
        this.contractsSAVED.push(
          {
            ProjectStageContracts: [{ProjectName: this.getNameProyect(data?.PersonalProyect?.ProjectId)}],
            FormImageLogo: data?.PersonalContract.FormImageLogo,
            ContractReview: data?.PersonalContract.ContractReview,
            EmployerName: data?.PersonalContract.EmployerName,
            ContractNumber: data?.PersonalContract.ContractNumber,
            StartDateContract: data?.PersonalContract.StartDate,
            FinishDateContract: data?.PersonalContract.FinishDate,
            IsActiveContractProject: data?.PersonalContract.ContractIsActive,
            Id: data?.PersonalContract.Id,
            FinishDate: data?.PersonalProyect.FinishDate
          }
        );
      });
    }
    this.controlStartDate.setValue(this.fechasService.getDateFormatDatePikerDATEHORANOWNotSecunt());
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    setTimeout(() => {
      this.getServices();
    }, 500);
  }

  getServices() {
    if (this.isFromVehicleFlag){
      this.employerService.getEmployers().subscribe((data: any) => {
        this.employers = data.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0)).filter(x => x.IsActive === true);
        if (this.data?.dataModuleRequest !== undefined) {
          // Si vienen del modulo request
          this.onSelectedAllEmployer(this.data?.dataModuleRequest.employerId);
          this.controlSearchEmployer.setValue(this.data?.dataModuleRequest.employerId);
        }
      });
    }else{
      this.employerService.getEmployersByPersonal(this.companyInfoId).subscribe((data: any) => {
        console.log(data);
        this.employers = data.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0)).filter(x => x.IsActive === true);
        if (this.data?.dataModuleRequest !== undefined) {
          // Si vienen del modulo request
          this.onSelectedAllEmployer(this.data?.dataModuleRequest.employerId);
          this.controlSearchEmployer.setValue(this.data?.dataModuleRequest.employerId);
        }
      });
      console.log(this.employers);
    }
  }

  getContracts(Id) {
    this.employerService.GetContracts(Id, 'Contractor').subscribe((restContract: any[]) => {
      this.contracts = restContract.filter(x => x.IsActive === true );
      this.contracts.sort((a, b) => (a.ProjectStageContracts[0].ProjectName > b.ProjectStageContracts[0].ProjectName) ?
        1 : ((b.ProjectStageContracts[0].ProjectName > a.ProjectStageContracts[0].ProjectName) ? -1 : 0));
      this.contractsCopy = restContract.filter(x => x.IsActive === true && this.exitContractSaveContractNumber(x.ContractNumber) === false);
    });
  }

  getNameProyect(ProyectId: string): string {
    let Name: string;
    this.projects.forEach(info => {
      if (ProyectId === info.ProjectId) {
        Name = info.Name;
      }
    });
    return Name;
  }

  getImg(logo) {
    const img = (logo === null || logo === '' || logo === undefined ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg' : logo);
    return img;
  }

  onSelectedAllEmployer(Id) {
    if (this.employerSelected.length !== 0) {
      this.controlPosition.setValue('');
      this.employerSelected = [];
      this.contractsSelected = [];
    }
    const data = this.employers.find(x => x.Id === Id);
    this.employerSelected = [];
    data !== undefined ? this.employerSelected.push(data) : '';
    data !== undefined ? this.getContracts(Id) : '';
  }

  onSelectedContract(Id) {
    // Si selecciona 0 para seleccionar un contrato por proyecto masivo.
    if (Id === 0) {
      this.contracts.forEach((value, index) => {
        this.selectedContract(value.Id, index === this.contracts.length - 1);
      });
    } else {
      this.selectedContract(Id, true);
    }
  }

  getSearchEmployerIdCompanyInfoId() {
    if (this.controlSearchEmpleador.value === '') {
      this.snackBService.openSnackBar('Por favor digita nombre o NIT de Empleador', 'X', 3000);
    } else {
      this.commonService.getSearchAllEmployersCompanyInfoId(this.employerSelected[0].CompanyInfoParentId,
        this.controlSearchEmpleador.value).subscribe((data: any) => {
        this.employerSelected = [];
        this.employerSelected.push(
          {
            Name: data.Name,
            EmployerId: data.Id,
            DocumentType: data.DocumentType,
            Logo: data.Logo,
            DocumentNumber: data.DocumentNumber,
            Email: data.Email
          }
        );
      },
        error => {
          this.snackBService.openSnackBar('POR FAVOR VALIDA LA INFORMACIÓN INGRESADA', 'X', 3000);
      });
    }
  }

  selectedContract(Id, oneFinal) {
    const data = this.contracts.find(x => x.Id === Id);

    if (data !== undefined) {
      // Verificar si ya existe un contrato con el mismo ProjectName
      const existingIndex = this.contractsSelected.findIndex(
        x => x.ProjectStageContracts[0].ProjectName === data.ProjectStageContracts[0].ProjectName
      );

      if (existingIndex !== -1) {
        // Reemplazar el contrato existente si ya hay uno con el mismo ProjectName
        this.contractsSelected[existingIndex] = data;
      } else {
        // Agregar el contrato si no hay otro con el mismo ProjectName
        this.contractsSelected.push(data);
      }
    }

    if (oneFinal === true) {
      this.setFinisthDate();
    }

    // Ordenar los contratos seleccionados por nombre de proyecto
    this.contractsSelected.sort((a, b) =>
      a.ProjectStageContracts[0].ProjectName > b.ProjectStageContracts[0].ProjectName
        ? 1
        : b.ProjectStageContracts[0].ProjectName > a.ProjectStageContracts[0].ProjectName
          ? -1
          : 0
    );

    // Resetear el control de búsqueda
    this.controlSearchContract.setValue('');
  }

  onRemoveSelectedContract(Id) {
    // Agregar el removido de los seleccionado
    const data = this.contractsSelected.find(x => x.Id === Id);
    // push pero en primera posicion
    this.contracts.unshift(data);
    // Elimina de array seleccionado
    const index = this.contractsSelected.indexOf(data);
    this.contractsSelected.splice(index, 1);
    // actualiza la informacion del select
    const newContracts = this.contracts.slice(0);
    this.contracts = newContracts;
    this.getSelectedContractsPosition();
    this.setFinisthDate();
    this.controlSearchContract.setValue('');
  }

  setFinisthDate() {
    let dateMax = '';
    this.contractsSelected.forEach((value, index) => {
      if (dateMax === '') {
        dateMax = value.FinishDate;
      } else if (value.FinishDate > dateMax) {
        dateMax = value.FinishDate;
      }
      if (index === this.contractsSelected.length -1) {
        this.controlFinisthDate.setValue(dateMax);
      }
    });
  }

  changeDateFinishDateContractTime(date, bool) {
  }

  exitContractSaveContractNumber(ContractNumber): boolean {
    let exit = false;
    const data = this.contractsSAVED.find(x => x.ContractNumber === ContractNumber);
    data !== undefined ? exit = true : exit = false;
    return exit;
  }

  postNewPersonalInfoContract() {
    if (this.isFromVehicleFlag === true){
      this.postSaveVehicleContract();
    }else {
      this.progressQuery = true;
      from(this.contractsSelected).pipe(
        concatMap((contract, index) => {
          const personalCompany = {
            ContractId: contract.Id,
            EmployerId: this.employerSelected[0].EmployerId === this.controlSearchEmployer.value
              ? contract.EmployerId
              : this.employerSelected[0].EmployerId,
            FinishDate: contract.ProjectStageContracts[0]?.FinishDate < this.controlFinisthDate.value
              ? contract.ProjectStageContracts[0]?.FinishDate
              : this.controlFinisthDate.value,
            PersonalCompanyInfoId: this.personalCompanyInfoId,
            Position: this.controlPosition.value,
            StartDate: contract.ProjectStageContracts[0]?.StartDate > this.controlStartDate.value
              ? contract.ProjectStageContracts[0]?.StartDate
              : this.controlStartDate.value,
          };
          return this.contractService.getIsContractPersonalCompanyInfoId(contract.Id, this.personalCompanyInfoId).pipe(
            concatMap(() => this.contractService.postContractPersonalInfo(contract.Id, personalCompany)),
            catchError((error: HttpErrorResponse) => {
              this.movTranslado.push(contract.Id);
              if (error.status === 400) {
                return this.dialogService.openDialogMovePersonalInfo(true, error, 'Desea trasladarlo?', false).afterClosed().pipe(
                  concatMap((restDialog) => {
                    if (restDialog === true) {
                      return this.contractService.postMovePersonalInfoContract(contract.Id, personalCompany).pipe(
                        concatMap((restMovePersonal: any) => {
                          this.snackBService.openSnackBar('Trasladado correctamente!', 'X', 4000);
                          this.movTranslado.pop();
                          return [];
                        })
                      );
                    } else {
                      return [];
                    }
                  })
                );
              }
              return [];
            })
          );
        })
      ).subscribe({
        next: () => {
          this.snackBService.openSnackBar('Vinculado correctamente!', 'X', 4000);
        },
        complete: () => {
          this.progressQuery = false;
          this.dialogRef.close(true);
        },
        error: (err) => {
          this.progressQuery = false;
        }
      });
    }
  }

  postSaveVehicleContract() {
    this.progressQuery = true;
    from(this.contractsSelected).pipe(
      concatMap((contract, index) => {
        const vehicleCompany = {
          ContractId: contract.Id,
          EmployerId: this.employerSelected[0].EmployerId === undefined ?
            contract.EmployerId : this.employerSelected[0].EmployerId,
          FinishDate: contract.ProjectStageContracts[0]?.FinishDate < this.controlFinisthDate.value ?
            contract.ProjectStageContracts[0]?.FinishDate : this.controlFinisthDate.value,
          VehicleCompanyInfoId: this.personalCompanyInfoId,
          Function: this.controlPosition.value,
          StartDate: contract.ProjectStageContracts[0]?.StartDate > this.controlStartDate.value ?
            contract.ProjectStageContracts[0]?.StartDate : this.controlStartDate.value,
          tillEndOfDay: true,
          Requirements: [0]
        };

        return this.contractService.postVehicleContract(false, vehicleCompany).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 412) {
              return this.dialogService.openDialogMovePersonalInfo(true, error, 'Desea trasladarlo?',
                false, 'TRANSLADAR VEHICULO', true).afterClosed().pipe(
                concatMap(restDialog => {
                  if (restDialog === true) {
                    return this.contractService.postVehicleContract(true, vehicleCompany).pipe(
                      concatMap(resPostContrac => {
                        this.snackBService.openSnackBar('Trasladado correctamente!', 'X', 4000);
                        return [];
                      })
                    );
                  } else {
                    return [];
                  }
                })
              );
            }
            return [];
          })
        );
      })
    ).subscribe({
      next: () => {
        this.snackBService.openSnackBar('Vinculado correctamente!', 'X', 4000);
      },
      complete: () => {
        this.progressQuery = false;
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.progressQuery = false;
      }
    });
  }


  getSelectedContractsPosition() {
    this.positionSujester = [];
    this.contractService.getPositions().subscribe((data: any) => {
      data.forEach(x => {
        this.positionSujester.push(x.Name);
      });
    });
  }

  changeinputPositionContract(value) {
    this.controlPosition.setValue(value);
  }

  close() {
    this.dialogRef.close();
  }

  validFormContract(): boolean {
    let valid = false;
    if (this.employerSelected.length > 0 && this.controlPosition.value !== '' &&
      this.controlPosition.value !== null && this.contractsSelected.length > 0) {
      valid = true;
    } else {
      valid = false;
    }
    return valid;
  }

  openDialogSelectedVincularContracts() {
    const list = [];
    this.contracts.forEach(v => {
      list.push({
        ...v,
        ProjectName: v.ProjectStageContracts[0].ProjectName
      });
    });
    const listTwo = [];
    this.contractsSAVED.forEach(v => {
      listTwo.push({
        ...v,
        ProjectName: v.ProjectStageContracts[0].ProjectName
      });
    });
    const card: CardList = {
      id: 'Id',
      img: 'FormImageLogo',
      titleFirst: 'ContractReview',
      subTitle: 'EmployerName',
      itemFinalDate: 'FinishDate',
      itemFinalText: 'ContractNumber',
      abodeTextMedium: 'ProjectName'
    };
    const cardSAVE: CardList = {
      id: '',
      img: 'FormImageLogo',
      titleFirst: 'ContractReview',
      subTitle: 'EmployerName',
      itemFinalDate: 'FinishDate',
      itemFinalText: 'ContractNumber',
      abodeTextMedium: 'ProjectName'
    };
    const dataTwoFilter = listTwo.filter(x => x.EmployerName === this.employerSelected[0].Name && x.IsActiveContractProject === true);
    const listFilter = list.filter(x => !dataTwoFilter.some(y => y.Id === x.Id));
    this.dialogService.openDialogSelectedVincularContracts(card, cardSAVE,
      '/admin/personal/person', listFilter, dataTwoFilter).afterClosed().subscribe(rest => {
      if (rest !== undefined) {
        if (rest.selectedAll === true) {
          this.onSelectedContract(0);
        } else {
          if (rest.contracts.length !== 0) {
            rest.contracts.forEach(element => {
              this.onSelectedContract(element.Id);
            });
          }
        }
      }
    });
  }

}

