import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { DialogService } from 'src/app/core/services/utils/dialog.service';
import { environment } from 'src/environments/environment';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';

export interface DialogData {
  Id: string;
}

@Component({
  selector: 'app-dialog-form-image-crud',
  templateUrl: './dialog-form-image-crud.component.html',
  styleUrls: ['./dialog-form-image-crud.component.scss']
})
export class DialogFormImageCrudComponent implements OnInit {
  s3Bucket:string;

  form: FormGroup;
  imgCropperResult: string;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredtags: Observable<string[]>;
  tags: string[] = [];
  alltags: string[] = [];

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  
  constructor(public dialogRef: MatDialogRef<DialogFormImageCrudComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonService: CommonService,
    private dialogService: DialogService,
    private fb: FormBuilder) { 
      this.form = this.fb.group({
        Tags: new FormControl('',Validators.required),
        Logo: '',
      });
      if(this.data.Id !== undefined) {
        this.commonService.getFormImageId(this.data.Id).subscribe((rest: any) => {
          this.form.get('Tags').setValue(rest.Tags);
          this.tags = rest.Tags;
          this.form.get('Logo').setValue(rest.Logo);
          this.imgCropperResult = this.getImg(rest.Logo);
        });
      }
    }

  ngOnInit(): void {
    this.s3Bucket=environment.api.s3Bucket;
    this.filteredtags = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.alltags.slice())),
    );
  }

  getImg(logo) {
    let img = (logo === null || logo === '' ? 'assets/images/personblue.png': logo); 
    return this.s3Bucket+img;
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    if(this.form.status === 'VALID')  {
      if(this.data.Id !== undefined) {
        let model = {
          Logo: this.form.get('Logo').value,
          Tags: this.tags,
          Id: this.data.Id
        }
        this.commonService.putFormImageId(this.data.Id, model).subscribe(rest => {
          if(this.formDataPhoto === undefined) {
            this.dialogRef.close(true);
          } else {
            this.commonService.putFormImageUpdateFileId(this.data.Id, this.formDataPhoto).subscribe(rest => {
              this.dialogRef.close(true);
            });
          }
          
        })
      } else {
        let model = {
          Logo: this.form.get('Logo').value,
          Tags: this.tags,
        }
        this.commonService.postFormImage(model).subscribe((rest: any) => {
          if(this.formDataPhoto === undefined) {
            this.dialogRef.close(true);
          } else {
            this.commonService.putFormImageUpdateFileId(rest.Id, this.formDataPhoto).subscribe(rest => {
              this.dialogRef.close(true);
            });
          }
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  formDataPhoto: FormData;
  updatePhotoPersonal() {
    this.dialogService.openDialogGestorImg('CARGAR IMAGEN',[],'editPhoto', 0, 'true', true).afterClosed().subscribe(formDataPhoto =>{
      if(formDataPhoto !== undefined) {
        this.imgCropperResult = formDataPhoto.imgResult;
        this.formDataPhoto = formDataPhoto.FormData;
      }
      // rest !== undefined ? this.formDataPhoto = rest : this.formDataPhoto = this.formDataPhoto;
    });
  }


  // Proceso Select CHip


  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.tags.push(value);
    }

    // Clear the input value
    if (event.input) {
      event.input.value = '';
    }

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.tags.indexOf(fruit);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.alltags.filter(tag => tag.toLowerCase().includes(filterValue));
  }

}
