import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private _snackBar: MatSnackBar) {}

  openSnackBar(message: string, action: string, time: number) {
    this._snackBar.open(message, action, {
      duration: time,
      verticalPosition: 'top',
      panelClass: ['success-snack-bar']
    });
  }

  // Método para mostrar notificaciones
  showSuccess(message: string) {
    this._snackBar.open(message, 'Cerrar', {
      duration: 3000,
    });
  }
}
