<div class="search-paginator" [ngClass]="production === false ? 'color-search-paginator-pruebas': 'color-search-paginator-pro'">
    <div class="search">
         <!-- <input class="input-search" 
                type="text" 
                placeholder="Buscar..." 
                (keyup)="searchFilterPagination($event.target.value)"> -->
        <input  class="input-search" (keydown.Tab)="$event.preventDefault()" (keyup)="applyFilterPlanilla($event.target.value)" #search placeholder="Buscar">
    </div>
    <mat-paginator #paginatorPersonal [pageSize]="20" [pageSizeOptions]="[7, 15, 20]" showFirstLastButtons></mat-paginator>
    <mat-icon class="close" (click)="close()">close</mat-icon>
</div>
<div class="list">
    <div [ngClass]="production === false ? 'color-bg-pruebas-tabs': 'color-bg-pro-tabs'"> 
        <div class="plantilla personalRemove" [ngClass]="production === false ? 'planilla-pruebas': 'planilla-prod'">
            <div class="mat-elevation-z8">
                <form [formGroup]="formPersonal" *ngIf="formPersonal" autocomplete="off">
                    <div class="planilla-information">
                        <table>
                            <tr>
                                <th><h3 class="field">Identificacion</h3></th>
                                <th><h3 class="field">Razón social</h3></th>
                                <th><h3 class="field">Empleado</h3></th>
                                <th><h3 class="field">Fecha de asistencia</h3></th>
                                <th><h3 class="field"></h3></th>
                            </tr>
                            <tr>
                                <td><div class="field center">{{employer?.DocumentType || ''}}:&nbsp;&nbsp;{{employer?.DocumentNumber || ''}}</div></td>
                                <td><div class="field center">{{employer?.Name || ''}}</div></td>
                                <td><div class="filed center" style="min-width: 150px;">
                                    <app-select-search class="select-component"
                                    [appearance]="'outline'"
                                    [_dataSource]="projectLaboral" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formPersonal.get('ProjectId')"
                                    [inputType]="'text'">
                                    </app-select-search>                                
                                </div></td>
                                <td><div class="field center">
                                    <div class="calendar-filter-range">
                                        <div class="txtD">DESDE</div>
                                        <div class="datepikerInput getNameDayDate">
                                            <div class="getNameDay">{{this.serviceFecha.getNameDay(this.formPersonal.get('startDate').value) || ''}}</div>
                                            <mat-form-field class="input-field-medium"  appearance="outline">
                                                <input matInput [matDatepicker]="pickerFilterStart" #inputDateFilterStart (click)="pickerFilterStart.open()" formControlName="startDate"
                                                    (dateChange)="changeDateFilter(inputDateFilterStart.value, true)"
                                                    [readonly]="true">
                                                <mat-datepicker #pickerFilterStart></mat-datepicker>
                                                <mat-error>Requerido</mat-error>
                                            </mat-form-field>
                                            <mat-icon style="margin-bottom: 2px;" (click)="pickerFilterStart.open()">today</mat-icon>
                                            <!-- <mat-icon class="btnSearch" [matTooltip]="'Buscar por fecha de asistencia'" [matTooltipPosition]="'after'">search</mat-icon> -->
                                        </div>
                                        &nbsp;&nbsp;
                                        <div class="txtD">HASTA</div>
                                        <div class="datepikerInput getNameDayDate">
                                            <div class="getNameDay">{{this.serviceFecha.getNameDay(this.formPersonal.get('endDate').value) || ''}}</div>
                                            <mat-form-field class="input-field-medium"  appearance="outline">
                                                <input matInput [matDatepicker]="pickerFilterEnd" #inputDateFilterEnd (click)="pickerFilterEnd.open()" formControlName="endDate"
                                                    (dateChange)="changeDateFilter(inputDateFilterEnd.value, false)"
                                                    [readonly]="true">
                                                <mat-datepicker #pickerFilterEnd></mat-datepicker>
                                                <mat-error>Requerido</mat-error>
                                            </mat-form-field>
                                            <mat-icon style="margin-bottom: 2px;" (click)="pickerFilterEnd.open()">today</mat-icon>
                                            <!-- <mat-icon class="btnSearch" [matTooltip]="'Buscar por fecha de asistencia'" [matTooltipPosition]="'after'">search</mat-icon> -->
                                        </div>
                                    </div>
                                </div></td>
                                <td><div class="field center" style="padding: 5px;">
                                    <div class="btn-cancel">
                                        <!-- <div style="color: black; font-weight: 600;">Novedades y retiros</div>&nbsp;&nbsp;&nbsp; -->
                                        <button mat-mini-fab class="add" *ngIf="exitPermission('employerparollnovelties.add')" (click)="openDialogCreateNoveltyDialog()" matTooltip="Crear novedad">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                        <button mat-mini-fab class="add-disabled" *ngIf="!exitPermission('employerparollnovelties.add')" matTooltip="Crear novedad">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                </div></td>
                            </tr>
                        </table>
                    </div>
    
                    <div class="plantilla" [ngClass]="production === false ? 'planilla-pruebas': 'planilla-prod'">
                        <div class="mat-elevation-z8">
                            <ng-container formArrayName="PersonalReportList">
                                <table mat-table [dataSource]="tableDataSourcePersonal" *ngIf="noveltyArraySave.length > 0">
                                    <ng-container matColumnDef="Id">
                                        <th mat-header-cell class="header-table" *matHeaderCellDef> ID </th>
                                        <td mat-cell class="header-table" *matCellDef="let personal" style="min-width: 40px; font-weight: 600;"> 
                                            {{personal.get('Id').value}}
                                        </td>
                                    </ng-container> 
                                    <!-- <ng-container matColumnDef="ContractNumber">
                                        <th mat-header-cell class="header-table" *matHeaderCellDef> Contrato en el Proyecto </th>
                                        <td mat-cell class="header-table" *matCellDef="let personal"> 
                                            {{personal.get('ContractNumber').value}}
                                        </td>
                                    </ng-container>  -->
                                    <ng-container matColumnDef="PersonalName">
                                        <th mat-header-cell class="header-table" *matHeaderCellDef> Empleado </th>
                                        <td mat-cell class="header-table" *matCellDef="let personal">
                                            <div class="personal">
                                                <div><img [src]="getUrlImg(personal.get('PersonalPhoto').value)" class="photo" /></div>
                                                <div class="DescPersonal">
                                                    <div class="lablePersonal">{{personal.get('FullName').value}}</div>
                                                    <div class="lablePersonal"></div>
                                                    <div class="lablePersonal">{{personal.get('PersonalDocumentType').value || 'N/A'}}: {{personal.get('PersonalDocumentNumber').value || 'N/A'}}</div>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container> 
                                    <ng-container matColumnDef="Position">
                                        <th mat-header-cell class="header-table" *matHeaderCellDef> Cargo </th>
                                        <td mat-cell class="header-table" *matCellDef="let personal"> 
                                            {{personal.get('Position').value}}
                                        </td>           
                                    </ng-container> 
                                    <ng-container matColumnDef="Novelty">
                                        <th mat-header-cell class="header-table" *matHeaderCellDef>Motivo de la novedad</th>
                                        <td mat-cell class="header-table" *matCellDef="let personal" [formGroup]="personal" style="min-width: 200px !important;"> 
                                            <app-select-search class="select-component"
                                            [appearance]="'outline'"
                                            [_dataSource]="this.motivos" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="personal.get('NoveltyTypeId')"
                                            [inputType]="'text'" (onSelectionChange)="changeNovelty(personal)" [disabled]="personal.get('IsOpen')?.value === false ? true: false">
                                            </app-select-search>
                                            <div class="fechasNovedad">
                                                <div class="fecha">
                                                    <div class="itemKey">Inicio:&nbsp;&nbsp;</div>
                                                    <mat-form-field class="input-field-medium"  appearance="outline" *ngIf="personal.get('IsOpen')?.value === true">
                                                        <input matInput [matDatepicker]="pickerFilterStart" #inputDateFilterStart (click)="pickerFilterStart.open()" formControlName="NoveltyStartDate"
                                                            (dateChange)="changeNovelty(personal, true)"
                                                            [readonly]="true">
                                                        <mat-datepicker #pickerFilterStart></mat-datepicker>
                                                        <mat-error>Requerido</mat-error>
                                                    </mat-form-field>
                                                    <!-- <mat-icon style="margin-bottom: 2px;" (click)="pickerFilterStart.open()">today</mat-icon> -->
                                                    <div *ngIf="personal.get('IsOpen')?.value === false">{{personal.get('NoveltyStartDate').value | date:'dd/MM/yyyy' || ''}}</div>
                                                </div>
                                                <div class="fecha">
                                                    <div class="itemKey">Fin&nbsp;&nbsp;</div>  
                                                    <mat-form-field class="input-field-medium"  appearance="outline" *ngIf="personal.get('IsOpen')?.value === true">
                                                        <input matInput [matDatepicker]="pickerFilterEnd" #inputDateFilterEnd (click)="pickerFilterEnd.open()" formControlName="NoveltyEndDate"
                                                            (dateChange)="changeNovelty(personal, true)"
                                                            [readonly]="true">
                                                        <mat-datepicker #pickerFilterEnd></mat-datepicker>
                                                        <mat-error>Requerido</mat-error>
                                                    </mat-form-field>
                                                    <!-- <mat-icon style="margin-bottom: 2px;" (click)="pickerFilterEnd.open()">today</mat-icon> -->
                                                    <div *ngIf="personal.get('IsOpen')?.value === false">{{personal.get('NoveltyEndDate').value | date:'dd/MM/yyyy' || ''}}</div>
                                                </div>  
                                            </div>  
                                        </td>
                                    </ng-container> 
                                    <ng-container matColumnDef="Lugar">
                                        <th mat-header-cell class="header-table" *matHeaderCellDef> Lugar de trabajo </th>
                                        <td mat-cell class="header-table" *matCellDef="let personal" style="font-weight: 600;"> 
                                            {{personal.get('PlaceOfWork').value}}
                                        </td>
                                    </ng-container> 
                                    <ng-container matColumnDef="Document">
                                        <th mat-header-cell class="header-table" *matHeaderCellDef> Documentos y comentarios </th>
                                        <td mat-cell class="header-table" *matCellDef="let personal"> 
                                            <div class="operators">
                                                <svg *ngIf="(personal.get('Comments').value !== null && personal.get('Comments').value !== undefined)
                                                        || (personal.get('NoveltyDocsJSON').value !== null && personal.get('NoveltyDocsJSON').value !== undefined)"
                                                    (click)="openDialogDocumentCommentInputAndOutput(personal)"
                                                    title="EPS" style="fill: #4C4C4C" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                                                    <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                                                    <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{''}}</text>
                                                </svg>
                                                <svg *ngIf="(personal.get('Comments').value === null || personal.get('Comments').value === undefined)
                                                            && (personal.get('NoveltyDocsJSON').value === null || personal.get('NoveltyDocsJSON').value === undefined)"
                                                    (click)="openDialogDocumentCommentInputAndOutput(personal)"
                                                    title="EPS" style="fill: red;" class="req-abrv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.13 33.91">
                                                    <path d="M17,0H3.39A3.39,3.39,0,0,0,0,3.39L0,30.52a3.38,3.38,0,0,0,3.37,3.39H23.74a3.4,3.4,0,0,0,3.39-3.39V10.17ZM3.39,30.52V3.39H15.26v8.48h8.48V30.52Z"></path>
                                                    <text transform="translate(3 22.41)" style="font-size: 11px;" class="uppercase">{{''}}</text>
                                                </svg>
                                                
                                            </div>
                                        </td>
                                    </ng-container> 
                                    <ng-container matColumnDef="Accion">
                                        <th mat-header-cell class="header-table" *matHeaderCellDef>Acciones</th>
                                        <td mat-cell class="header-table" *matCellDef="let personal"> 
                                            <button mat-mini-fab matTooltip="Guardar" style="margin-right: 4px;" 
                                                    (click)="updateNovelty(personal)"
                                                    [ngClass]="exitPermission('employerparollnovelties.update') && personal.get('update').value === true ? 'btn-save': 'btn-save-disabled'"
                                                    [disabled]="!exitPermission('employerparollnovelties.update') || personal.get('update').value === false ? true: false">
                                                    <mat-icon>save</mat-icon>
                                            </button>
                                            <button mat-mini-fab matTooltip="Eliminar" 
                                                    (click)="dialogDeleteNovelty(personal)" 
                                                    [ngClass]="exitPermission('employerparollnovelties.delete') && personal.get('IsOpen').value === true ? 'btn-delete': 'btn-save-disabled'" style="margin-right: 4px;"
                                                    [disabled]="!exitPermission('employerparollnovelties.delete')  || personal.get('IsOpen').value === false ? true: false">
                                                    <mat-icon>delete</mat-icon>
                                            </button>
                                            <button mat-mini-fab
                                                    (click)="changeIsOpen(personal)" 
                                                    [disabled]="!exitPermission('employerparollnovelties.close') ? true: false"
                                                    [ngClass]="exitPermission('employerparollnovelties.close') ? 'btn-save': 'btn-save-disabled'" style="margin-right: 4px;"
                                                    [disabled]="!exitPermission('employerparollnovelties.close') ? true: false">
                                                    <mat-icon *ngIf="personal.get('IsOpen')?.value === false" [matTooltipPosition]="'above'" [matTooltip]="'Novedad bloqueada'">lock</mat-icon>
                                                    <mat-icon *ngIf="personal.get('IsOpen')?.value === true" [matTooltipPosition]="'above'" [matTooltip]="'Novedad abierta'">lock_open</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="columnsPersonal"></tr>
                                    <tr mat-row *matRowDef="let row; columns: columnsPersonal;"></tr>
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell" colspan="8">No se encontro datos para tu consulta</td>
                                    </tr>
                                </table>
                            </ng-container>  
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>