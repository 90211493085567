
<div class="search-paginator" [ngClass]="production === false ? 'color-search-paginator-pruebas': 'color-search-paginator-pro'">
    <div class="search">
         <input class="input-search" 
                type="text" 
                placeholder="Buscar..." 
                (keyup)="searchFilterPagination($event.target.value)">
    </div>
    <mat-paginator  *ngIf="arreglo"
                    [length]="arreglo.length"
                    [pageSize]="32"
                    [pageSizeOptions]="[15,32]"
                    (page)="searchFilterPagination(filterListValue)"
                    #paginatorList
    ></mat-paginator>
    <mat-icon class="close" (click)="close()">close</mat-icon>
</div>
<div class="list">
    <div *ngFor="let contract of listSlice">
        <app-card-base
            [img]="contract.FormImageLogo"
            [altImg]="contract.FormImageLogo"
    
            [withStatus]="!contract.isActive || contract.Expiry"
            [status]="contract.Expiry"
        >   
        
            <div body>
                <div class="text">
                    <h3 class="title item-text">{{contract.ContractReview || 'N/A' }}</h3>
                    <p class="subTitle item-text">{{contract.CompanyName || 'N/A' | uppercase}}</p>
                    <p class="subTitle item-text">{{contract.ContractorName || 'N/A'| lowercase}}</p>
        
                </div>
            </div>
        
            <div footer class="space-between">
                <div>
                    <mat-icon
                        class="color-gray"
                        matTooltipPosition="above"
                        [matTooltip]="'Proyectos: ' + contract.CountProjects"
                        >location_city</mat-icon
                    >
                    <mat-icon
                        class="color-gray"
                        matTooltipPosition="above"
                        [matTooltip]="'SubContratistas: ' + contract.CountSubContractor"
                        >business</mat-icon
                    >
                    <mat-icon
                        class="color-gray"
                        matTooltipPosition="above"
                        [matTooltip]="'Personal: ' + contract.CountPersonal"
                        >personal</mat-icon
                    >
                    </div>
                    <div>
                    <span><strong>No: </strong>{{ contract.ContractNumber }}</span>
                </div>
            </div>
        </app-card-base>
    </div>
</div>