
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModelDocumentsShow } from '../../../interfaces/interfaces';
import { DialogService } from '../../../services/utils/dialog.service';
import { EmployerService } from '../../../services/modules/employer.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

export interface DialogData {
  urlType: string;
  url: string;
  typeOperator: string;
  files?: File[]
}

@Component({
  selector: 'app-dialog-change-one-file',
  templateUrl: './dialog-change-one-file.component.html',
  styleUrls: ['./dialog-change-one-file.component.scss']
})
export class DialogChangeOneFileComponent implements OnInit {
  // Si cambia el archivo devulve un FormData del File
  s3Bucket:string;

  // FIles
  formFile: FormGroup;
  _file: File = null;
  files: File[] = [];

  url = '';

  constructor(
    private dialogRef: MatDialogRef<DialogChangeOneFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private fb: FormBuilder,
  ) {
    this.files ? this.files = this.data.files: '';
    this.formFile = this.fb.group({
      File: ''
    });
   }
  

  ngOnInit(): void {
    this.s3Bucket= environment.api.s3Bucket;
    // console.log('data',this.data)
    if(this.data.urlType === 'Complete' && this.data.url !== '' && this.data.url !== null) {
      // console.log('URL COMPLETE:', this.data.url);
      let a = this.data.url.split('https://analityco-acceso.s3.amazonaws.com/');
      // console.log('a',a);
      if(a !== undefined) {
        // console.log('a[1];',a[1]);
        this.url = a[1];
      }
    } else if(this.data.urlType === 'Incomplete' && this.data.url !== '' && this.data.url !== null) {
      this.url = this.data.url;
    }
  }

  openImgVideo(url: string) {
    this.dialogService.openDialogImageOrVideoOrPdf(url).afterClosed().subscribe(x => {});
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.getName();
  }

  getName(): string {
    let name: string;
    if(this.url !== '') {
      let arrayDeCadenas = this.url.split('/');
      // console.log('arrayDeCadenas',arrayDeCadenas);
      name = arrayDeCadenas.pop();
    }
    return name;
  }

  getUrl(doc: ModelDocumentsShow): string {
    let url = ''
    let a = doc?.urlDoc?.split('||name=');
    if(a !== undefined) {
      url = a[0];
    }
    return url;
  }



  changeFile(event: any) {
    this.files = [];
    let selectedFiles: FileList = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.files.push(selectedFiles[i]);
    }
  }

  onSelect(event) {
    this.files = [];
    let selectedFiles: FileList = event.addedFiles;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.files.push(selectedFiles[i]);
    }
	}

  updatePhoto() {
    let formData = new FormData();
    formData.append('File', this.files[0]);
    this.dialogRef.close({FormData: formData, File: this.files});
  }


  removeFile(index) {
    delete this.files[index];
  }

  isXLSX(doc: any): boolean {
    return this.getUrl(doc).slice(-5).toUpperCase() === '.XLSX';
  }

  getUrlFile(url) {
    const urlFile = this.s3Bucket+this.getUrl(url)
    return urlFile;
  }

  

	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
	}

}
