
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CommonService} from 'src/app/core/services/modules/common.service';
import {ProjectService} from 'src/app/core/services/modules/project.service';
import {DialogService} from 'src/app/core/services/utils/dialog.service';
import {FechasService} from 'src/app/core/services/utils/fechas.service';
import {SnackBarService} from 'src/app/core/services/utils/snackBar.service';
import {environment} from 'src/environments/environment';

export interface DialogData {
  Id: string;
  permissions: any[];
}

@Component({
  selector: 'app-dialog-create-project',
  templateUrl: './dialog-create-project.component.html',
  styleUrls: ['./dialog-create-project.component.scss']
})
export class DialogCreateProjectComponent implements OnInit {
  s3Bucket: string;
  imgCropperResult: string;

  form: FormGroup;
  GeoFenceJson: any;
  formEtapas: FormGroup;
  changeGeoFenceJson: any;
  formDataPhoto: FormData;

  daneCity: [] = [];
  permissions: string[] = [];
  projectStageArray: any[] = [];
  flagNext = false;
  flagEditEtapas = true;

  configStart = '{"type":"Polygon","coordinates":[]}';

  date = new Date();
  minDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());

  constructor(public dialogRef: MatDialogRef<DialogCreateProjectComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private commonService: CommonService,
              private dialogService: DialogService,
              private projectService: ProjectService,
              private snackBService: SnackBarService,
              public fechasService: FechasService,
              private fb: FormBuilder) {
      this.getServices();
      this.form = this.fb.group({
        Address: new FormControl('', Validators.required),
        CityCode: new FormControl('', Validators.required),
        CompanyInfoId: new FormControl(this.data.Id, Validators.required),
        GeoFence: new FormControl(''),
        JoinCompaniesList: new FormControl([]),
        Name: new FormControl('', Validators.required),
      });
      this.formEtapas = this.fb.group({
        Name: new FormControl('', Validators.required),
        Review: new FormControl('', Validators.required),
        StartDate: new FormControl(new Date(), Validators.required),
        FinishDate: new FormControl('', Validators.required),
      });
    }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    this.GeoFenceJson = JSON.parse(this.configStart);
  }

  getServices() {
    this.commonService.getDaneCitySelectTrue().subscribe((daneCity: any) => {
      this.daneCity = daneCity;
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  next() {
    if (this.form.status !== 'VALID') {
      this.snackBService.openSnackBar('Diligenciar los campos requeridos', 'X', 4000);
      this.form.markAllAsTouched();
    } else if (this.imgCropperResult === undefined){
      this.snackBService.openSnackBar('Falta cargar el logo', 'X', 4000);
    } else {
      this.flagNext = true;
    }
  }


  guardarEtapa() {
    if (this.formEtapas.status === 'VALID') {
      if (this.projectStageArray.length === 0) {
        this.projectStageArray.push(this.formEtapas.value);
      }
      this.projectStageArray.forEach(x => {
        if (x.Name === this.formEtapas.get('Name').value) {
          x.Name = this.formEtapas.get('Name').value;
          x.Review = this.formEtapas.get('Review').value;
          x.StartDate = this.formEtapas.get('StartDate').value;
          x.FinishDate = this.formEtapas.get('FinishDate').value;
        } else {
          this.projectStageArray.push(this.formEtapas.value);
        }
      });
      this.formEtapas.get('Name').setValue('');
      this.formEtapas.get('Review').setValue('');
      this.formEtapas.get('StartDate').setValue(new Date());
      this.formEtapas.get('FinishDate').setValue('');
      this.flagEditEtapas = true;
    } else {
      this.formEtapas.markAllAsTouched();
    }
  }

  addEtapa() {
    this.flagEditEtapas = false;
    this.formEtapas.get('Name').setValue('');
    this.formEtapas.get('Review').setValue('');
    this.formEtapas.get('StartDate').setValue(new Date());
    this.formEtapas.get('FinishDate').setValue('');
  }

  editarEtapaProject(stage: { Name: any; Review: any; StartDate: any; FinishDate: any; }) {
    this.flagEditEtapas = false;
    this.formEtapas.get('Name').setValue(stage.Name);
    this.formEtapas.get('Review').setValue(stage.Review);
    this.formEtapas.get('StartDate').setValue(stage.StartDate);
    this.formEtapas.get('FinishDate').setValue(stage.FinishDate);
  }

  eliminarEtapa(stage: { Name: any; }){
    this.projectStageArray.forEach((x, index) => {
      if (x.Name === stage.Name) {
        this.projectStageArray.splice(index, 1);
      }
    });
  }

  getImgEtapa(logo: any) {
    return (logo === null ? 'assets/images/bg/project.png' : logo);
  }

  save() {
    if (this.projectStageArray.length === 0) {
      this.snackBService.openSnackBar('Debes crear una etapa', 'X', 4000);
    } else if (this.form.status === 'VALID')  {
      this.projectStageArray.forEach(x => {
        x.StartDate = this.fechasService.getDateFormatDatePiker(x.StartDate);
      });
      const model = {
        Address: this.form.get('Address').value,
        CityCode: this.form.get('CityCode').value,
        CompanyInfoId: this.form.get('CompanyInfoId').value,
        GeoFence: this.changeGeoFenceJson !== undefined ? this.configStart : '',
        JoinCompaniesList: [],
        Name: this.form.get('Name').value,
        ProjectStageList: this.projectStageArray
      };
      this.projectService.postProject(model).subscribe((rest: any) => {
        if (this.formDataPhoto === undefined) {
          this.dialogRef.close(rest.Id);
        } else {
          // tslint:disable-next-line:no-shadowed-variable
          this.projectService.putProjectUpdateLogo(rest.Id, this.formDataPhoto).subscribe(rest => {
            this.dialogRef.close(rest.Id);
          });
        }
      });
    }
  }

  openDialogMap() {
    this.dialogService.openDialogMap('', JSON.parse(this.configStart)).afterClosed().subscribe(rest => {
      if (rest !== false) {
        this.changeGeoFenceJson = rest;
        this.configStart = rest;
        this.GeoFenceJson = undefined;
        this.GeoFenceJson = JSON.parse(rest);
      }
    });
  }

  updatePhotoPersonal() {
    this.dialogService.openDialogGestorImg('CARGAR IMAGEN', [], 'editPhoto', 0, 'true', true).afterClosed().subscribe(formDataPhoto => {
      if (formDataPhoto !== undefined) {
        this.imgCropperResult = formDataPhoto.imgResult;
        this.formDataPhoto = formDataPhoto.FormData;
      }
    });
  }

  exitPermission(permiso: string): boolean {
    let exit: boolean;
    const permisionsUserInfo  = this.data.permissions.find(x => x === permiso);
    permisionsUserInfo !== undefined ? exit = true : exit = false;
    return exit;
  }


}
