import {Injectable} from '@angular/core';
import {DatesPeriods} from '../../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class  FechasService {

    month = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ];

    monthR = [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
  ];

    days = [
      'Dom',
      'Lun',
      'Mar',
      'Mie',
      'Jue',
      'Vie',
      'Sab',
  ];

  daysName = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
    'Domingo',
  ];

  constructor() {}

  getListDateYYYYMM(dateMax: number, dateMaxLeng: number): DatesPeriods[] {
    let today = new Date();

    // Esta linea es para que empiece un mes mas al mes actual
    today.getMonth() + dateMax === 13 ? today.setMonth(1) : today.setMonth(today.getMonth() + 1);

    let fechas: DatesPeriods[] = [];
    let dayNow = 0;
    for(let i=1; i<= dateMaxLeng; i++){
      dayNow !== 0 ? today.setMonth(today.getMonth() - 1) : '';
      let mm = String((today.getMonth() + 1));
      let yyyy = today.getFullYear();
      let dd = today.getDate();
      fechas.push(
        {
          dateList: yyyy+'-'+(parseInt(mm) === 10 || parseInt(mm) === 11 || parseInt(mm) === 12 ? mm: 0+mm),
          dateAPi: yyyy+'-'+(parseInt(mm) === 10 || parseInt(mm) === 11 || parseInt(mm) === 12 ? mm: 0+mm)+'-'+dd ,
          monthNumer: mm,
          monthText: this.month[parseInt(mm)-1],
          year: yyyy
        });
      dayNow++;
    }
    return fechas;
  }





  // Get date Ejemplo: 10 DE DICIEMBRE DE 2020 SIN HORA
  getDatetoday(): string {
    let today = new Date();
    let mm = String((today.getMonth() + 1));
    let yyyy = today.getFullYear();
    let dd = today.getDate();
    let date = "" +dd+" de "+this.month[parseInt(mm)-1]+" de "+yyyy;
    return date;
  }

  // Get date Ejemplo: 10 DE DICIEMBRE DE 2020 CON HORA
  getDatetodayWithHour(): string {
    let today = new Date();
    let mm = String((today.getMonth() + 1));
    let yyyy = today.getFullYear();
    let dd = today.getDate();
    let hour = today.getHours();
    let minutes = today.getMinutes();
    let date = "" +dd+" de "+this.month[parseInt(mm)-1]+" de "+yyyy+" "+hour+":"+minutes;
    return date;
  }

  // Get date Ejemplo: 10 DIC 2020 SIN HORAS
  getDateFormatR(data): string {
    let today = new Date(data);
    let mm = String((today.getMonth() + 1));
    let yyyy = today.getFullYear();
    let dd = today.getDate();
    let date = "" +dd+" "+this.monthR[parseInt(mm)-1]+" "+yyyy;
    return date;
  }

   // Get date Ejemplo: 10 DIC 2020 SIN HORAS
   getDateFormatRWith(data): string {
    let today = new Date(data);
    let mm = String((today.getMonth() + 1));
    let yyyy = today.getFullYear();
    let dd = today.getDate();
    let date = "" +dd+"."+this.monthR[parseInt(mm)-1]+"."+yyyy;
    return date;
  }

  // Get date Ejemplo: 10 DIC 2020 CON HORAS
  getDateFormatRWithHour(data): string {
    let newDate = new Date(data);
    let mm = String((newDate.getMonth() + 1));
    let yyyy = newDate.getFullYear();
    let dd = newDate.getDate();
    let hour = newDate.getHours();
    let minutes = newDate.getMinutes();
    let date = "" +dd+" "+this.monthR[parseInt(mm)-1]+" "+yyyy+" "+hour+":"+minutes;
    return date;
  }

  // Get date Ejemplo: 10 DIC 2020 CON HORAS
  getDateFormatRWithHourAMPM(data): string {
    let newDate = new Date(data);
    let mm = String((newDate.getMonth() + 1));
    let yyyy = newDate.getFullYear();
    let dd = newDate.getDate();
    let hour = newDate.getHours()% 12 || 12;
    let minutes = newDate.getMinutes();
    let date = "" +dd+" "+this.monthR[parseInt(mm)-1]+" "+yyyy+"   "+hour+":"+(minutes >= 10? minutes :+"0"+minutes.toString() ) + (newDate.getHours() > 12 ?' p.m.': ' a.m.');
    return date;
  }

  // Get date Ejemplo: SOLO HORAS
  getDateFormatRWithHours(data): string {
    let newDate = new Date(data);
    let hour = newDate.getHours()% 12 || 12;
    let minutes = newDate.getMinutes();
    let date = "" +hour+":"+(minutes >= 10? minutes :+"0"+minutes.toString() ) + (newDate.getHours() > 12 ?' p.m.': ' a.m.');
    return date;
  }

  getDateFormatRMinutes(data): string {
    let minutes: string;
    let today = new Date(data);
    minutes = "" + today.getHours()+ ":"+today.getMinutes();
    return minutes;
  }

  getNameDay(fechaComoCadena): string {
    // const fechaComoCadena = "2023-06-11 23:37:22"; // día Domingo
    const numeroDia = new Date(fechaComoCadena).getDay();
    const nombreDia = this.daysName[numeroDia];
    return nombreDia;
  }


  // devulve date 010221 para numero de contrato
  getDateNumerFechaContractNow(): string {
    let today = new Date();
    let mm = String((today.getMonth() + 1));
    let yyyy = today.getFullYear();
    let dd = today.getDate();
    let date = ""+yyyy.toString().slice(2,4)+(parseInt(mm) >= 10? mm :+"0"+mm )+(dd >= 10? dd :+"0"+dd.toString())+"";
    return date;
  }

  // devulve date 20210724
  getDateFormatDayMontYear(d: any): string {
    let today = new Date(d);
    let mm = String((today.getMonth() + 1));
    let yyyy = today.getFullYear();
    let dd = today.getDate();
    let date = ""+yyyy+(parseInt(mm) >= 10? mm :+"0"+mm )+(dd >= 10? dd :+"0"+dd.toString())+"";
    return date;
  }


  // devulve date 2021-07-24-T00:00:00
  getDateFormatDatePiker(d: any): string {
    let today = new Date(d);
    let mm = String((today.getMonth() + 1));
    let yyyy = today.getFullYear();
    let dd = today.getDate();
    let date = ""+yyyy+'-'+(parseInt(mm) >= 10? mm :+"0"+mm )+'-'+(dd >= 10? dd :+"0"+dd.toString())+"T00:00:00";
    return date;
  }

  // devulve date 2021-07-24-T23:59:59
  getDateFormatDatePiker23(d: any): string {
    let today = new Date(d);
    let mm = String((today.getMonth() + 1));
    let yyyy = today.getFullYear();
    let dd = today.getDate();
    let date = ""+yyyy+'-'+(parseInt(mm) >= 10? mm :+"0"+mm )+'-'+(dd >= 10? dd :+"0"+dd.toString())+"T23:59:59";
    return date;
  }

  // Get date Ejemplo: NOW devulve date 2021-07-24-T12:52:15 AHORA
  getDateFormatDatePikerDATEHORANOW(): string {
    let newDate = new Date();
    let mm = String((newDate.getMonth() + 1));
    let yyyy = newDate.getFullYear();
    let dd = newDate.getDate();
    let hour = newDate.getHours();
    let minutes = newDate.getMinutes();
    let seconds = newDate.getSeconds();
    let date = ""+yyyy+'-'+(parseInt(mm) >= 10? mm :+"0"+mm )+'-'+(dd >= 10? dd :+"0"+dd.toString())+"T"+
    (hour >= 10? hour :+"0"+hour.toString())+':'+
    (minutes >= 10? minutes :+"0"+minutes.toString())+':'+
    (seconds >= 10? seconds :+"0"+seconds.toString())+"";
    return date;
  }

  // Get date Ejemplo: NOW devulve date 2021-07-24-T12:52:15 AHORA
  getDateFormatDatePikerDATEHORANOWNotSecunt(): string {
    let newDate = new Date();
    let mm = String((newDate.getMonth() + 1));
    let yyyy = newDate.getFullYear();
    let dd = newDate.getDate();
    let hour = newDate.getHours();
    let minutes = newDate.getMinutes();
    let seconds = newDate.getSeconds();
    let date = ""+yyyy+'-'+(parseInt(mm) >= 10? mm :+"0"+mm )+'-'+(dd >= 10? dd :+"0"+dd.toString())+"T"+
    (hour >= 10? hour :+"0"+hour.toString())+':'+
    (minutes >= 10? minutes :+"0"+minutes.toString());
    return date;
  }

  // Get date Ejemplo: devulve date 07-12-2012 12:52:15
  getDateFormatDateNormal(ddate): string {
    let newDate = new Date(ddate);
    let mm = String((newDate.getMonth() + 1));
    let yyyy = newDate.getFullYear();
    let dd = newDate.getDate();
    let hour = newDate.getHours();
    let minutes = newDate.getMinutes();
    let seconds = newDate.getSeconds();
    let date = ""+(dd >= 10? dd :+"0"+dd.toString())+'/'+(parseInt(mm) >= 10? mm :+"0"+mm )+'/'+yyyy+" "+
    (hour >= 10? hour :+"0"+hour.toString())+':'+
    (minutes >= 10? minutes :+"0"+minutes.toString());
    return date;
  }

  // Get date Ejemplo: NOW devulve date 2021-07-24-T12:52:15 AHORA NOTA: Por ahora se coloca la hora final del dia por cambios de api
  getDateFormatDatePikerDATEHORANOWNotSecunt59(): string {
    let newDate = new Date();
    let mm = String((newDate.getMonth() + 1));
    let yyyy = newDate.getFullYear();
    let dd = newDate.getDate();
    let hour = newDate.getHours();
    let minutes = newDate.getMinutes();
    let seconds = newDate.getSeconds();
    let date = ""+yyyy+'-'+(parseInt(mm) >= 10? mm :+"0"+mm )+'-'+(dd >= 10? dd :+"0"+dd.toString())+"T23:59:00"
    return date;
  }

  // devulve date 2021-07-24
  getDateFormatDateSinHora(d: any): string {
    let today = new Date(d);
    let mm = String((today.getMonth() + 1));
    let yyyy = today.getFullYear();
    let dd = today.getDate();
    let date = ""+yyyy+'-'+(parseInt(mm) >= 10? mm :+"0"+mm )+'-'+(dd >= 10? dd :+"0"+dd.toString());
    return date;
  }

  // Date NOow
  getDateNow(): string {
    let today = new Date();
    // console.log('today',today);
    let mm = String((today.getMonth() + 1));
    let yyyy = today.getFullYear();
    let dd = today.getDate();
    let date = ""+yyyy+'-'+(parseInt(mm) >= 10? mm :+"0"+mm )+'-'+(dd >= 10? dd :+"0"+dd.toString())+"T00:00:00";
    // console.log('date',date);
    return date;
  }

  getDateTimeNow(endOfDay: boolean = false): string {
    let today = new Date();
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    let dd = String(today.getDate()).padStart(2, '0');

    let hh = endOfDay ? '23' : String(today.getHours()).padStart(2, '0');
    let min = endOfDay ? '59' : String(today.getMinutes()).padStart(2, '0');
    let ss = '00';

    return `${yyyy}-${mm}-${dd}T${hh}:${min}:${ss}`;
  }

  // Date noew un dia anterior
  getPrevieDayDate(): string {
    let today = new Date();
    today.setDate(today.getDate() - 1);
    let mm = String((today.getMonth() + 1));
    let yyyy = today.getFullYear();
    let dd = today.getDate();
    let date = ""+yyyy+'-'+(parseInt(mm) >= 10? mm :+"0"+mm )+'-'+(dd >= 10? dd :+"0"+dd.toString())+"T23:59:59";
    return date;
  }

  // Date fecha con horas
  getDateHours(date): string {
    if(date) {
      let length = date.length
      if(length >= 19) {
        return date.substring(0,16);
      } else {
        return date;
      }
    }
  }


  //Devuelve la fecha menos un mes
  calculateLessMonth(date: Date): Date {
    let newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() - 1);
    return newDate;
  }

  // Calcula si la planilla esta vencida a dos meses
  calculatePlanillaCreate(PeriodEPS: string): boolean {
    // console.log('Per :>> ', PeriodEPS);
    let flag = false;
    let nowDate = new Date();
    let D1 = PeriodEPS.slice(-2);
    let D2 = nowDate.getMonth() +1;
    let D3 = D2 - 1;
    // console.log('nowDate.getMonth() :>> ', nowDate);
    // if(D3 === 0)
    D3 === 0 ? D3 = 12 : null;
    // console.log('D1 :>> ', D1);
    // console.log('D2 :>> ', D2);
    // console.log('D3 :>> ', D3);
    if(parseInt(D1) === D2
    || parseInt(D1) === D3) {
      flag = true;
    }
    return flag;
  }

  //CALCULA SI LA FECHA ES MAYOR A LA DE HOY
  calculateDateMoreNow(date: Date): boolean {
    let flag = false;
    let nowDate = new Date();
    let D1 = new Date(date);
    if(D1 >= nowDate) {
      flag = true;
    }
    return flag
  }

  //CALCULA SI LA FECHA 1 es menos que la 2
  calculateDate1IslessDate2(date1, date2): boolean {
    let flag = false;
    let D1 = new Date(date1);
    let D2 = new Date(date2);
    if(D1 < D2) {
      flag = true;
    }
    return flag
  }

  //CALCULA SI LA FECHA ES MAYOR A LA DE HOY sin tener en cuenta la hora
  calculateStateDate(date: Date, id?): string {
    let flag = '';
    let nowDate = new Date();
    // nowDate.setHours(0, 0, 0);
    let nowDateVencer = new Date();
    let D1 = new Date(date);
    // Este se vulve habilitar para que no revice la hora si no hasta el final del día 12/08/2022 CAP
    // D1.setHours(23, 50, 50);
    let porVencer = nowDateVencer.setDate(nowDateVencer.getDate() + 8);
    let D2 = new Date(porVencer);
    // Debug
    //id depende el contrato
    // if(id === 'b88a9d1a-4ad3-4a3a-bb7c-933ef93ccba7') {
    //   console.log('date',date);
    //   console.log('D1',D1);
    //   console.log('D2',D2);
    //   console.log('nowDate',nowDate);
    // }
    if(nowDate > D1) {
      flag = 'DateRed';
    } else if(D1.getTime() >= nowDate.getTime()
          && D1.getTime() <= D2.getTime()) {
      flag = 'DateOrange';
    }
    return flag;
  }

  //CALCULA SI LA FECHA ES MAYOR A LA DE HOY teniendo en cuenta la hora
  calculateStateDateWithHORA(date: Date, id?): string {
    let flag = '';
    let nowDate = new Date();
    // nowDate.setHours(0, 0, 0);
    let nowDateVencer = new Date();
    let D1 = new Date(date);
    // Este se vulve habilitar para que no revice la hora si no hasta el final del día 12/08/2022 CAP
    // D1.setHours(23, 50, 50);
    let porVencer = nowDateVencer.setDate(nowDateVencer.getDate() + 8);
    let D2 = new Date(porVencer);
    if(nowDate > D1) {
      flag = 'DateRed';
    } else if(D1.getTime() >= nowDate.getTime()
          && D1.getTime() <= D2.getTime()) {
      flag = 'DateOrange';
    }
    return flag;
  }

  //CALCULA Si la fecha incial es mayor a hoy debe estar en rojo
  calculateStateDateMore(date: Date, id?): string {
    let flag = '';
    let nowDate = new Date();
    // nowDate.setHours(0, 0, 0);
    let D1 = new Date(date);
    // Si la fecha incial es mayor a hoy debe estar en rojo
    D1.setHours(0, 0, 0);
    if(nowDate < D1) {
      flag = 'DateRed';
    }
    return flag;
  }

  calculateStateDateFromIcon(date: Date): string {
    let flag = '';
    let nowDate = new Date();
    nowDate.setHours(0, 0, 0);
    let nowDateVencer = new Date();
    let D1 = new Date(date);
    D1.setHours(23, 50, 50);
    let porVencer = nowDateVencer.setDate(nowDateVencer.getDate() + 8);
    let D2 = new Date(porVencer);
    if(nowDate > D1) {
      flag = 'person-red';
    } else if(D1.getTime() >= nowDate.getTime()
          && D1.getTime() <= D2.getTime()) {
      flag = 'person-orange';
    }else{
      flag = 'person-blue';
    }
    return flag;
  }

  calculateStateDateForLabel(date: Date): string {
    let flag = '';
    let nowDate = new Date();
    nowDate.setHours(0, 0, 0);
    let nowDateVencer = new Date();
    let D1 = new Date(date);
    D1.setHours(23, 50, 50);
    let porVencer = nowDateVencer.setDate(nowDateVencer.getDate() + 8);
    let D2 = new Date(porVencer);
    if(nowDate > D1) {
      flag = 'Persona Inactivo';
    } else if(D1.getTime() >= nowDate.getTime()
          && D1.getTime() <= D2.getTime()) {
      flag = 'Persona por vencer';
    }else{
      flag = 'Persona activo';
    }
    return flag;
  }

  calcularEdad(fecha) {
    let hoy = new Date();
    let cumpleanos = new Date(fecha);
    let edad = hoy.getFullYear() - cumpleanos.getFullYear();
    let m = hoy.getMonth() - cumpleanos.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
    }
    return edad;
  }

  //Devuelve true si la fecha esta dentro  del rango - UN MES
  calculateExistDateRango(stardDate, validityDate): boolean {
    let exit = false;
    let validityDateLeessMonth = new Date(validityDate);
    validityDateLeessMonth.setMonth(validityDateLeessMonth.getMonth() - 1);
    // let test = '"11/11/2020"';
    let D1 = new Date(validityDate);
    let D2 = new Date(validityDateLeessMonth);
    //D3 fehca para saber si existe entre D1 y D2

    let D3 = new Date(stardDate);
    // Fecha de test
    // let D3 = new Date(test);

    if(D3.getTime() <= D1.getTime()
    && D3.getTime() >= D2.getTime()) {
      exit = true;
    }
    return exit;
  }

  //Devuelve true si la fecha esta dentro  del rango - RegularUpdateDays
  calculateRegulareUpdateDays(DateCompanyInfo, RegularUpdateDays): boolean {
    let exit = false;
    let validityDateMoreDays = new Date(DateCompanyInfo);
    validityDateMoreDays.setDate(validityDateMoreDays.getDate() + RegularUpdateDays);

    let D1 = new Date(DateCompanyInfo);
    let D2 = new Date(validityDateMoreDays);
    let D3 = new Date();

    // Debug
    // console.log('DateCompanyInfo',D1);
    // console.log('DateCompanyInfo + days',D2);
    // console.log('Ahora',D3);

    if(D3.getTime() <= D2.getTime()
    && D3.getTime() >= D1.getTime()) {
      exit = true;
    }
    return exit;
  }

  //Devuelve true si la fecha esta dentro  del rango - UN MES
  calculateExistDateRang3Date(stardDate, finisDate, validityDate): boolean {
    let exit = false;
    // let test = '"11/11/2020"';
    let D1 = new Date(validityDate);

    let D2 = new Date(finisDate);
    let D3 = new Date(stardDate);

    if(D3.getTime() <= D1.getTime()
    && D3.getTime() >= D2.getTime()) {
      exit = true;
    }
    return exit;
  }


  //PARSE DE STRING A DATETIME // Fecha Cumpleaños cambiar formato
  parseDateTimeBirthDate(date: string): Date {
    const dateSlice: string = date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8) + 'T00:00:00';
    const newDate = new Date(dateSlice);
    return newDate;
  }

  //Fecha Cumpleaños cambiar formato dd-mm-yyy a Date yyyy-mm-dd
  formatDateYYYYMMDD(date: string): string{
    // date = date.replace('/','');
    var info = date.split('/').reverse().join('-');
    const newDate: string =  date.slice(6, 8)+ '-' + date.slice(4, 6) + '-' + date.slice(0, 4) + 'T00:00:00';
    return info;
  }

  //Fecha cambiar espacio por t gestor de docuemntos
  formatDateReplaceSpaceWithT(date: string): string{
    // date = date.replace('/','');
    let newDate = date.replace(' ','/');
    return newDate;
  }

  //Fecha  cambiar formato dd-mm-yyy a Date yyyy-mm-dd and DAtePiker CON HORA T00:00:00
  formatDateYYYYMMDDdatePiker(date: string): string{
    let arrayDate = date.split('/').reverse();
    let year, mm, dd;
    year = arrayDate[0];
    mm = parseInt(arrayDate[1]) >= 10 ? arrayDate[1]: '0'+arrayDate[1];
    dd = parseInt(arrayDate[2]) >= 10 ? arrayDate[2]: '0'+arrayDate[2];
    const newDate: string = year + '-' + mm + '-' + dd + 'T00:00:00';
    return newDate;
  }

  //Fecha  cambiar formato dd-mm-yyy a Date yyyy-mm-dd and DAtePiker CON HORA T23:59:59
  formatDateYYYYMMDDdatePiker23(date: string): string{
    let arrayDate = date.split('/').reverse();
    let year, mm, dd;
    year = arrayDate[0];
    mm = parseInt(arrayDate[1]) >= 10 ? arrayDate[1]: '0'+arrayDate[1];
    dd = parseInt(arrayDate[2]) >= 10 ? arrayDate[2]: '0'+arrayDate[2];
    const newDate: string = year + '-' + mm + '-' + dd + 'T23:59:59';
    return newDate;
  }


  //Fecha  cambiar formato dd-mm-yyy a Date yyyy-mm-dd and DAtePiker CON HORA T23:59:59
  formatDateYYYYMMDDdatePikerWhitMinutes(date: string): string{
    let deleteSpace = date?.replace(' ','/');
    let arrayDate = deleteSpace?.split('/').reverse();
    let year, mm, dd;
    year = arrayDate[1];
    mm = parseInt(arrayDate[2]) >= 10 ? arrayDate[2]: ''+arrayDate[2];
    dd = parseInt(arrayDate[3]) >= 10 ? arrayDate[3]: (arrayDate[3]?.length === 2 ? arrayDate[3] :'0'+arrayDate[3]);
    const newDate: string = dd + ' ' + this.monthR[parseInt(mm)-1] + ' ' + year + ' ' +arrayDate[0].substring(0,5);
    return newDate;
  }

  formatDateYYYYMMDD24Hours(dateString: string): string {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      console.error('Fecha inválida:', dateString);
      return '';
    }

    const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    const day = date.getDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day} ${month} ${year} ${hours}:${minutes}`;
  }


  //Fecha  cambiar formato dd-mm-yyy a Date yyyy-mm-dd and DAtePiker SIN HORA
   formatDateYYYYMMDDdatePikerSinhora(date: string): string{
    let arrayDate = date.split('/').reverse();
    let year, mm, dd;
    year = arrayDate[0];
    mm = parseInt(arrayDate[1]) >= 10 ? arrayDate[1]: '0'+arrayDate[1];
    dd = parseInt(arrayDate[2]) >= 10 ? arrayDate[2]: '0'+arrayDate[2];
    const newDate: string = year + '-' + mm + '-' + dd;
    return newDate;
  }

  //Fecha Cumpleaños cambiar formato String a Date dd-mm-yyy
  formatBirthDate(date: string): string{
    const newDate: string = date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8) + 'T00:00:00';
    return newDate;
  }

  //Contrato "Liquidado" (INDIVIDUAL CONTRACT), CALCULA SI LA FECHA ES MAYOR A8 DIAS
  calculateDateMoreDays(date: string): boolean{
    let flag = false;
    let nowDate = new Date();
    let validityDateMoreDays = new Date(date);
    // console.log(validityDateMoreDays);
    validityDateMoreDays.setDate(validityDateMoreDays.getDay() + 8);
    // console.log(validityDateMoreDays);
    if(nowDate.getTime() < validityDateMoreDays.getTime()) {
      flag = true;
    }
    return flag;
  }

  //CALCULA SI LA FECHA
  calculateDatePlanillaMore(date: string): boolean{
    let flag = true;
    let nowDate = new Date();
    nowDate.setDate(28);
    nowDate.setHours(23);
    nowDate.setMinutes(59);
    nowDate.setSeconds(59);

    let dateApi = new Date(date);
    dateApi.setDate(28);
    dateApi.setHours(23);
    dateApi.setMinutes(59);
    dateApi.setSeconds(59);

    if(dateApi.getTime() > nowDate.getTime()) {
      flag = false;
    }
    return flag;
  }

  getShortTime(timeData): string {
    let time = '';
    if(timeData !== null && timeData !== undefined && timeData !== '') {
      let arrayTime = timeData.split(':');
      let AmOrPm =  arrayTime[0] >= 12 ? 'PM' : 'AM';
      let hours = (arrayTime[0] % 12) || 12;
      let min = arrayTime[1];
      time = hours+':'+min+' '+AmOrPm;
    }
    return time;
  }

}
