import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonOptions, ModelDocumentsShow } from '../../../interfaces/interfaces';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { CropperComponent } from 'angular-cropperjs';
import { AuthService } from 'src/app/core/services/modules/auth.service';
import { User } from 'src/app/core/models/User';
import { EmployerService } from 'src/app/core/services/modules/employer.service';
import { FechasService } from 'src/app/core/services/utils/fechas.service';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';

export interface DialogData {
  title: string;
  docs: any;
  typeOperator: string;
  typeGestor: string;
  module: string;
  adminInfoId: string;
  isFromPersonal: boolean;
}

@Component({
  selector: 'app-dialog-gestor-documents',
  templateUrl: './dialog-gestor-documents.component.html',
  styleUrls: ['./dialog-gestor-documents.component.scss']
})
export class DialogGestorDocumentsComponent implements OnInit {
  @ViewChild('angularCropper') angularCropper: CropperComponent;

  s3Bucket: string;
  DocsJSON: any = {};
  documentos: any[] = [];
  stories: any[] = [];
  upDateDocuments: any;
  cropper: boolean = false;
  imgCropper: string;
  imgCropperResult: string;
  formData = new FormData();
  // FIles
  formFile: FormGroup;
  _file: File = null;
  files: File[] = [];
  email: string = '';
  commentary: string = '';
  cropperOptions: any = {};
  permissions: string[] = [];

  userAuth: User;

  proveedor: CommonOptions[] = [];
  lastStages: CommonOptions[] = [];
  controProveedor = new FormControl();

  state;
  stateString = '';
  stateCurrent;
  module = '';
  permissionsCompany: string[] = [];

  date = new Date();
  minDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());

  permissionsGestor: any[] = [
    { module: 'Personal',
      permissions: { uploadFile: 'documentinfopersonal.updatedate', upload: 'documentinfopersonal.upload', requestapproval: 'documentinfopersonal.requestapproval', approval: 'documentinfopersonal.approval'}
    },
    { module: 'Employer',
      permissions: { uploadFile: 'documentinfoemployer.updatedate', upload: 'documentinfoemployer.upload', requestapproval: 'documentinfoemployer.requestapproval', approval: 'documentinfoemployer.approval'}
    },
    { module: 'SocialSecurity',
      permissions: { uploadFile: 'documentinfosocialsecurity.uploadFile', upload: 'documentinfosocialsecurity.upload', requestapproval: 'documentinfosocialsecurity.requestapproval', approval: 'documentinfosocialsecurity.approval'}
    }];
  permissionsModule;

  constructor(
    private dialogRef: MatDialogRef<DialogGestorDocumentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private authService: AuthService,
    private employerService: EmployerService,
    private fechasService: FechasService,
    private commonService: CommonService,
    private snackBService: SnackBarService,
  ) {
    this.userAuth = this.authService.getUser();
    this.permissions = this.userAuth.claims;
    this.email = this.userAuth?.Email !== null && this.userAuth?.Email !== undefined ? this.userAuth?.Email : '';
    this.formFile = this.fb.group({
      File: '',
      Date: ''
    });
    this.module = this.data.module;
    // selected permisions
    this.permissionsModule = this.permissionsGestor.find(x => x.module === this.module);
    this.stateCurrent = this.data.docs;
    this.state = this.stateCurrent.State;
    if (this.data?.docs?.Id !== undefined) {
      this.employerService.getDocumentInfoEmployerHistorical(this.data?.docs?.Id).subscribe(rest => {
        if (rest !== null && rest.length !== 0) {
          this.stateCurrent = rest[rest.length - 1];
        }
        this.stories = rest.sort((a, b) => a.UpdateDate < b.UpdateDate ? 1 : -1);
      });
    }

    this.commonService.getCommonOptions('PersonalContractStage').subscribe((data: CommonOptions[]) => {
      this.lastStages = data;
      if (this.state !== undefined) {
        const data = this.lastStages.find(x => x.Value === this.state);
        data !== undefined ? this.stateString = data.Description?.toLocaleUpperCase() : '';
      }
    });
    // Fecha state current
    if (this.data.docs.StateId !== null && this.data.docs.StateId !== '' && this.data.docs.StateId !== undefined) {
      this.formFile.get('Date').setValue(this.stateCurrent?.Date);
    }
   }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    this.getInfoAdmin();
  }

  getInfoAdmin() {
    this.commonService.getUserInfo().subscribe(userinfo => {
      this.permissionsCompany = userinfo.Companies.find(x => x.Id === this.data.adminInfoId).Permissions;
    });
  }

  exitPermissionCompany(permiso: string): boolean {
    let exit = false;
    const permisionsUserInfo  = this.permissionsCompany.find(x => x === permiso);
    permisionsUserInfo !== undefined ? exit = true : exit = false;
    return exit;
  }

  // Estos permisos es para ver los campos a editar archivos fecha comentarios
  exitPermissionView(): boolean {
    let exit = false;
    // Permiso sin estado para iniciar una solictud
    if ((this.data?.docs?.State === undefined || this.data?.docs?.State === 'REC')
        && this.exitPermission(this.permissionsModule.permissions.requestapproval)) {
      exit = true;
    }
    // permiso para aprovar una solicitud
    if (this.data?.docs?.State === 'SOL' && this.exitPermission(this.permissionsModule.permissions.approval)) {
      exit = true;
    }
    return exit;
  }

  getCalculateStateDateWithHORA(date, id?): string {
    let response = '';
    response = this.fechasService.calculateStateDateWithHORA(date, id);
    return response;
  }

  getDateFormatR(date): string {
    let response = '';
    if (date !== null && date !== null) {
      response = this.fechasService.getDateFormatR(date);
    }
    return response;
  }

  getDateFormatRMinutes(date): string {
    let response: string;
    response = this.fechasService.getDateFormatRMinutes(date);
    return response;
  }

  openImgVideoUrl(url: any) {
    window.open(this.s3Bucket + url, '_blank');
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  jsonUpdateDeleteDoc(doc: ModelDocumentsShow, index) {
    const DocsJSON = `{"${doc.name}": "${doc.urlDoc}"}`;
    const a = JSON.parse(DocsJSON);
    Object.assign(this.DocsJSON, a);
    delete this.documentos[index];
  }

  jsonDocExitValue(): boolean {
    return Object.entries(this.DocsJSON).length === 0;
  }
  upDateDocs(DocsJSON) {
    this.createFile();
  }

  changeFile(event: any) {
    let selectedFiles: FileList = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.files.push(selectedFiles[i]);
    }
  }

  saveFiles() {
    for (let i = 0; i < this.files.length; i++) {
        const formData = new FormData();
        formData.append('File', this.files[i]);
    }
  }
  // METODOS PARA CORTAR UNA IMAGEN Y GUARDARLA
onSelect(event) {
    this.files = [];
    // if(event.addedFiles[0].type === 'application/pdf') {
    if (this.cropper === false) {
        const selectedFiles: FileList = event.addedFiles;
        for (let i = 0; i < selectedFiles.length; i++) {
          this.files.push(selectedFiles[i]);
        }
      } else if (this.cropper === true && (this.data.typeOperator === 'showDelete' || this.data.typeOperator === 'showUpdate')) {

        const selectedFiles: FileList = event.addedFiles;
        for (let i = 0; i < selectedFiles.length; i++) {
          this.files.push(selectedFiles[i]);
        }
      } else {
        const selectedFiles: FileList = event.addedFiles;
        const reader = new FileReader();
        reader.readAsDataURL(selectedFiles[0]);
        reader.onload = () => {
          this.imgCropper = reader.result as string;
          this.imgCropperResult = reader.result as string;
        };
      }
    // } else {
    //   this.snackBarService.openSnackBar('SOLO SE PERMITEN ARCHIVOS PDF', 'X', 4000);
    // }
}

  updatePhoto() {
    this.cropper = false;
    if (this.imgCropper !== undefined){
      this.imgCropperResult = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
      this.angularCropper.cropper.getCroppedCanvas().toBlob((blob) => {
        this.cropper = false;
        const formData = new FormData();
        formData.append('File', blob);
        this.dialogRef.close({FormData: formData, imgResult: this.imgCropperResult});
      }, 'iamge/jpeg', 0.95);
    } else {
    }
  }

  isEqualsUserRemoveFile(doc): boolean {
    let equal = false;
    this.userAuth.username === doc?.user ? equal = true : equal = false;
    return equal;
  }

  // METODOS PARA CORTAR UNA IMAGEN Y GUARDARLA FIN

  removeFile(index) {
    // delete this.files[index];
    this.files.splice(index, 1);
  }
  createFile() {
    if (this.commentary !== '') {
      const file = new File([this.commentary], 'commentary_fileAnalityco.txt', { type: 'text/plain' });
      this.files.push(file);
    }
  }

  changeNameOrCommentary(file: File): any {
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function(e) {
      return reader.result;
    };
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
}

  getState(state): number {
    let idStage = 0;
    this.lastStages.forEach((stage: CommonOptions) => {
      if (stage.Value === state) {
        idStage = stage.Id;
      }
    });
    return idStage;
  }

  solicitar() {
    const id = this.getState('SOL');
    let model;
    if (this.module === 'Personal') {
      model = {
        PersonalCompanyInfoId: this.data?.docs?.PersonalCompanyInfoId,
        EmployerInfoId: this.data?.docs?.EmployerInfoId,
        Date: this.formFile.get('Date').value,
        RequirementId: this.stateCurrent.RequirementId,
        StateId: id,
        Comments: this.commentary === null || this.commentary === '' ? this.stateCurrent.Comments : this.commentary
      };
    } else if (this.module === 'Employer') {
      model = {
        EmployerInfoId: this.stateCurrent.EmployerInfoId,
        Date: this.formFile.get('Date').value,
        RequirementId: this.stateCurrent.RequirementId,
        StateId: id,
        Comments: this.commentary === null || this.commentary === '' ? this.stateCurrent.Comments : this.commentary
      };
    } else if (this.module === 'SocialSecurity') {
      model = {
        SocialSecurityId: this.data?.docs?.State === undefined ? this.stateCurrent.SocialSecurityId :
          this.stateCurrent.ExternalRequirementId,
        Date: this.formFile.get('Date').value,
        StateId: id,
        Comments: this.commentary === null || this.commentary === '' ? this.stateCurrent.Comments : this.commentary
      };
    }
    this.employerService.postDocumentInfo(model, this.module).subscribe((rest: any) => {
      if (this.files.length !== 0) {
        const formData = new FormData();
        formData.append('File', this.files[0]);
        this.employerService.putDocumentInfoFile(rest.Id, formData).subscribe(rest => {
          this.dialogRef.close(true);
        });
      } else {
        this.dialogRef.close(true);
      }
    });
  }

  aprobar() {
    if ((this.formFile.get('Date').value === '' ||
      this.formFile.get('Date').value === null ||
      this.formFile.get('Date').value === undefined) &&
      this.module !== 'SocialSecurity' &&
      this.exitPermission(this.permissionsModule.permissions.uploadFile) && this.stateCurrent.ByDate === true) {
      this.snackBService.openSnackBar('POR FAVOR DIGITAR FECHA DE VENCIMIENTO', 'X', 3000);
    } else {
      const id = this.getState('APR');
      let model;
      if (this.module === 'Personal') {
        model = {
          PersonalCompanyInfoId: this.data?.docs?.PersonalCompanyInfoId,
          EmployerInfoId: this.data?.docs?.EmployerInfoId,
          Date: this.formFile.get('Date').value,
          RequirementId: this.stateCurrent.RequirementId,
          StateId: id,
          Comments: this.commentary === null || this.commentary === '' ? this.stateCurrent.Comments : this.commentary
        };
      } else if (this.module === 'Employer') {
        model = {
          EmployerInfoId: this.stateCurrent.EmployerInfoId,
          Date: this.formFile.get('Date').value,
          RequirementId: this.stateCurrent.RequirementId,
          StateId: id,
          Comments: this.commentary === null || this.commentary === '' ? this.stateCurrent.Comments : this.commentary
        };
      } else if (this.module === 'SocialSecurity') {
        model = {
          SocialSecurityId: this.stateCurrent.ExternalRequirementId,
          Date: this.formFile.get('Date').value,
          StateId: id,
          Comments: this.commentary === null || this.commentary === '' ? this.stateCurrent.Comments : this.commentary
        };
      }


      if (this.files.length !== 0) {
        const formData = new FormData();
        formData.append('File', this.files[0]);
        this.employerService.putDocumentInfoFile(this.stateCurrent.DocumentInfoId, formData).subscribe(rest => {
          this.employerService.postDocumentInfo(model, this.module).subscribe(rest => {
            this.dialogRef.close(true);
          });
        });
      } else {
        this.employerService.postDocumentInfo(model, this.module).subscribe(rest => {
          this.dialogRef.close(true);
        });
      }
    }
  }

  rechazar() {
    const id = this.getState('REC');
    let model;
    if (this.module === 'Personal') {
      model = {
        PersonalCompanyInfoId: this.data?.docs?.PersonalCompanyInfoId,
        EmployerInfoId: this.data?.docs?.EmployerInfoId,
        Date: this.formFile.get('Date').value,
        RequirementId: this.stateCurrent.RequirementId,
        StateId: id,
        Comments: this.commentary === null || this.commentary === '' ? this.stateCurrent.Comments : this.commentary
      };
    } else if (this.module === 'Employer') {
      model = {
        EmployerInfoId: this.stateCurrent.EmployerInfoId,
        Date: this.formFile.get('Date').value,
        RequirementId: this.stateCurrent.RequirementId,
        StateId: id,
        Comments: this.commentary === null || this.commentary === '' ? this.stateCurrent.Comments : this.commentary
      };
    } else if (this.module === 'SocialSecurity') {
      model = {
        SocialSecurityId: this.stateCurrent.ExternalRequirementId,
        Date: this.formFile.get('Date').value,
        StateId: id,
        Comments: this.commentary === null || this.commentary === '' ? this.stateCurrent.Comments : this.commentary
      };
    }

    if (this.files.length !== 0) {
      const formData = new FormData();
      formData.append('File', this.files[0]);
      this.employerService.putDocumentInfoFile(this.stateCurrent.DocumentInfoId, formData).subscribe(rest => {
        this.employerService.postDocumentInfo(model, this.module).subscribe(rest => {
          this.dialogRef.close(true);
        });
      });
    } else {
      this.employerService.postDocumentInfo(model, this.module).subscribe(rest => {
        this.dialogRef.close(true);
      });
    }


  }
  exitPermission(permiso: string): boolean {
    let exit: boolean = false;
    const permisionsUserInfo  = this.permissions.find(x => x === permiso);
    permisionsUserInfo !== undefined ? exit = true : exit = false;
    return exit;
  }


}

