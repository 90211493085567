import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CompanyModel, CommonModuleSave, BranchOfficesModelSave, BranchOfficesModel } from '../../../../models/CompanyModel';
import { CompaniesService } from '../../../../services/modules/companies.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SnackBarService } from '../../../../services/utils/snackBar.service';
import { ActivatedRoute } from '@angular/router';



export interface DialogData {
  typeDialog: string;
  companyModel?: CompanyModel;
  // modelLinkText?: boolean;
}

export class Archivo {
  constructor(
  public id: number,
  public nombre: string,
  public imagen: string
  ) {}
  // modelLinkText?: boolean;
}


@Component({
  selector: 'app-dialog-condiciones',
  templateUrl: './dialog-condiciones.component.html',
  styleUrls: ['./dialog-condiciones.component.scss']
})
export class DialogCondicionesComponent implements OnInit {

EnableAdFinish: boolean = true
TextAdFinish: string = '';
LinkAdFinish: string = '';

EnableAdStart: boolean = true
company: CompanyModel;
formCompany: FormGroup;

@ViewChild('fileInput')
InputFile: ElementRef;

//FILE
formData = new FormData();
formDataPoliticas = new FormData();
formFile: FormGroup;

CompanyInfoId: string;
constructor(
    public dialogRef: MatDialogRef<DialogCondicionesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private companiesService: CompaniesService,
    private fb: FormBuilder,
    private snackBService: SnackBarService,
) {
  this.CompanyInfoId = data.companyModel.CompanyInfoId;
  if(data.typeDialog === 'showVideoImage'){
    this.EnableAdStart = data.companyModel.EnableAdStart;
  }

  if(data.typeDialog === 'showLinkAndText'){
    this.TextAdFinish = data.companyModel.TextAdFinish;
    this.LinkAdFinish = data.companyModel.LinkAdFinish;
    this.EnableAdFinish = data.companyModel.EnableAdFinish;
  }
}

ngOnInit() {
  this.getCompanyId();
      this.formCompany = this.fb.group({
      Geocerca: '',
      StartDate: '',
      FinishDate: '',
      EnableSurveySymptoms: false,
    });
  this.formFile = this.fb.group({
    File: ''
  });
 }

onNoClick(): void {
this.dialogRef.close();
}

getCompanyId() {
  this.companiesService.getCompanyId(this.CompanyInfoId).subscribe((data: any) => {
    this.company = data;
    this.formCompany.get('Geocerca').setValue(this.company.GeofenceLimit);
    this.formCompany.get('StartDate').setValue(this.company.StartDate);
    this.formCompany.get('FinishDate').setValue(this.company.FinishDate);
    this.formCompany.get('EnableSurveySymptoms').setValue(this.company.EnableSurveySymptoms);
  });
}

saveLink() {
  this.data.companyModel.TextAdFinish = this.TextAdFinish;
  this.data.companyModel.LinkAdFinish = this.LinkAdFinish;
  this.data.companyModel.EnableAdFinish = this.EnableAdFinish;
  const newBranchOfficesModelSave: BranchOfficesModelSave = this.modelBranchOfficesModelSave(this.data.companyModel.BranchOffices);
  this.data.companyModel.BranchOfficeJson = JSON.stringify([newBranchOfficesModelSave]);
  const newCompanyModel: CommonModuleSave = this.modelCompanySave(this.data.companyModel);
  this.companiesService.putCompanyId(newCompanyModel, this.data.companyModel.Id).subscribe(rest =>{
    this.snackBService.openSnackBar('SE GUARDO CORRECTAMENTE', 'X', 3000);
    this.onNoClick();
  });
}

saveShowVideo() {
  this.data.companyModel.EnableAdStart = this.EnableAdStart;
  const newBranchOfficesModelSave: BranchOfficesModelSave = this.modelBranchOfficesModelSave(this.data.companyModel.BranchOffices);
  this.data.companyModel.BranchOfficeJson = JSON.stringify([newBranchOfficesModelSave]);
  const newCompanyModel: CommonModuleSave = this.modelCompanySave(this.data.companyModel);
  this.companiesService.putCompanyId(newCompanyModel, this.data.companyModel.Id).subscribe(rest =>{
    if(this._file) {
      this.companiesService.updateFileCondicionesDeServicio(this.data.companyModel.Id ,this.formData).subscribe(rest =>{
        this.snackBService.openSnackBar('SE GUARDO CORRECTAMENTE', 'X', 3000);
        window.location.reload();
      },error => this.snackBService.openSnackBar('OCURRIO UN ERROR AL GUARDAR EL VIDEO', 'X', 3000));
    }
    this.onNoClick();
  });
}

modelCompanySave(modelCompany: CompanyModel): CommonModuleSave {
  let newCompanyModel: CommonModuleSave = {
    StartDate: modelCompany.StartDate,
    FinishDate: modelCompany.FinishDate,
    DocumentType: modelCompany.DocumentType,
    DocumentNumber: modelCompany.DocumentNumber,
    Name: modelCompany.Name,
    IsStaff: modelCompany.IsStaff,
    Website: modelCompany.Website,
    CrossDomain: true,
    EconomicActivities: [],
    GeofenceLimit: modelCompany.GeofenceLimit,
    ServicesProvided: modelCompany.ServicesProvided,
    BranchOfficeJson: modelCompany.BranchOfficeJson,
    Domains: modelCompany.Domains,
    EnableAdStart: modelCompany.EnableAdStart,
    EnableAdFinish: modelCompany.EnableAdFinish,
    LinkAdStart: modelCompany.LinkAdStart,
    LinkAdFinish: modelCompany.LinkAdFinish,
    TextAdFinish: modelCompany.TextAdFinish
  }
  return newCompanyModel;
}

modelBranchOfficesModelSave(branchOfficesModel: BranchOfficesModel[]): BranchOfficesModelSave{
  let newBranchOfficesModelSave: BranchOfficesModelSave = {
    Address: branchOfficesModel[0].Address, //
    CityCode: branchOfficesModel[0].CityCode, //
    Email: branchOfficesModel[0].Email, //
    IsMain: branchOfficesModel[0].IsMain,//
    Name: branchOfficesModel[0].Name, //
    Phone: branchOfficesModel[0].Phone, //
  }
  return newBranchOfficesModelSave;
}


  _file: File = null;
  typesFile = ['IMAGE/PNG', 'IMAGE/JPG', 'IMAGE/JPEG', 'IMAGE/TIP', 'IMAGE/GIF', 'VIDEO/MP4'];
  changeFile(files: FileList, ev: Archivo) {

    const exitType = this.typesFile.find(type => type === files.item(0).type.toUpperCase());
    if(exitType){
      const sizeFile = files.item(0).size/1024/1024;
      if(files.item(0).type.toUpperCase() === 'VIDEO/MP4') {
        if(sizeFile > 20) {
          this.snackBService.openSnackBar(`VIDEO DEBE SER MÁXIMO DE 20MG, peso del video cargado ${sizeFile.toFixed(2)}MG`, 'X', 4000);
          this.InputFile.nativeElement.value = "";
        } else {
          this._file = files.item(0);
          this.formData.append('File', this._file);
        }
      } else {
        if(sizeFile > 10) {
          this.snackBService.openSnackBar(`IMAGEN DEBE SER MÁXIMO DE 10MG, peso de la imagen cargada ${sizeFile.toFixed(2)}MG`, 'X', 4000);
          this.InputFile.nativeElement.value = "";
        } else {
          this._file = files.item(0);
          this.formData.append('File', this._file);
        }
      }
    } else {
      this.snackBService.openSnackBar('DEBE SER DE FORMATO PNG, JPG, JPEG y TIP, GIF O VIDEO DE MP4', 'X', 6000);
      this.InputFile.nativeElement.value = "";
    }
  }

  _filePoliticas: File = null;
  changeFilePiliticas(files: FileList) {
    this._filePoliticas = files.item(0);
    this.formDataPoliticas.append('File', this._filePoliticas);
  }

  updateFilePoliticas() {
    if(this._filePoliticas) {
      this.companiesService.updateFilePoliticasDePrivacidad(this.data.companyModel.Id ,this.formDataPoliticas).subscribe(rest =>{
        this.snackBService.openSnackBar('SE GUARDO CORRECTAMENTE', 'X', 3000);
        this.onNoClick();
        window.location.reload();
      });
    }
  }

  updateFileVideoImage() {
    this.saveShowVideo();
    if(this._file) {
      this.companiesService.updateFileCondicionesDeServicio(this.data.companyModel.Id ,this.formData).subscribe(rest =>{
        this.snackBService.openSnackBar('SE GUARDO CORRECTAMENTE', 'X', 3000);
        this.onNoClick();
        window.location.reload();
      });
    }
  }

  replaceSave(){
    if(this.formCompany.get('Geocerca').value !== null
        && this.formCompany.get('Geocerca').value !== undefined
        && this.formCompany.get('Geocerca').value !== ''
        && this.formCompany.get('FinishDate').value !== null
        && this.formCompany.get('FinishDate').value !== undefined
        && this.formCompany.get('EnableSurveySymptoms').value !== null
        && this.formCompany.get('EnableSurveySymptoms').value !== undefined){
          let model = [ {op: "replace", path: "/GeofenceLimit", value: this.formCompany.get('Geocerca').value},
                    {op: "replace", path: "/FinishDate", value: this.formCompany.get('FinishDate').value},
                    {op: "replace", path: "/EnableSurveySymptoms", value: this.formCompany.get('EnableSurveySymptoms').value}];
          this.companiesService.patchCompanyId(this.company.Id,model).subscribe(rest=>{
            this.snackBService.openSnackBar('Cambios guardados!','X',3000);
            this.dialogRef.close(true);
            window.location.reload();
          });
        }else{
          this.snackBService.openSnackBar('No pueden haber campos vacios!','X',3000);
        }

  }


}

