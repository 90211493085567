<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'PERMISO' || 'N/A'}}</div>
    </div>
    
    <div class="content-card list-card" >
        <form [formGroup]="form" autocomplete="off">
            <mat-form-field class="input-field">
                <label><strong>NOMBRE</strong></label>
                <input matInput type="text" formControlName="Name" pattern="([a-z0-9]+)\.([a-z0-9]+)">
                <mat-error>Requerido / Un permiso debe tener el siguiente formato: modulo.accion</mat-error>
            </mat-form-field>
            <div class="actions-dialog"style="margin: 3px;">
                <button mat-button class="btn-cancelar" [mat-dialog-close]="false" >CANCELAR</button>&nbsp;&nbsp;
                <button mat-button 
                        (click)="save()"
                        [ngClass]="form.status === 'VALID' ? 'btn-generar':'btn-disabled'"
                        [disabled]="form.status === 'VALID' ? false: true">GUARDAR
                </button>
                <!-- <button (click)="_getContractNumber()">Hola</button> -->
            </div>
        </form>
    </div>
   
    
</div>