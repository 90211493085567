<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'CONFIGURACIÓN DE PERFIL' || 'N/A'}}</div>
    </div>
    
    <div class="content-card list-card" >
        <form [formGroup]="form">
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>NOMBRE</strong></label>
                <input matInput type="text" formControlName="Name">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>DESCRIPCIÓN</strong></label>
                <textarea matInput formControlName="Description" maxlength="{{100}}"></textarea>
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <div class="inputTogle">
                <label>Estado</label>
                <div>
                    <mat-slide-toggle formControlName="IsEnabled"></mat-slide-toggle>&nbsp;
                    <label *ngIf="form.get('IsEnabled').value === true">Habilitado</label>
                    <label *ngIf="form.get('IsEnabled').value === false">Inhabilitado</label>
                </div>
            </div>
            <div class="inputTogle">
                <label>Exigir doble autenticación</label>
                <div>
                    <mat-slide-toggle formControlName="MFA"></mat-slide-toggle>&nbsp;
                    <label *ngIf="form.get('MFA').value === true">Habilitado</label>
                    <label *ngIf="form.get('MFA').value === false">Inhabilitado</label>
                </div>
            </div>
            <div class="inputTogle">
                <label>Días para obligar el cambio de clave</label>
                <mat-form-field class="input-field-100" appearance="outline">
                    <input matInput type="text" formControlName="PasswordExpirationDays">
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
            </div>
            <div class="inputTogle">
                <label>Visible solo en el modulo de:</label>
                <app-select-search class="input-field-selec"
                                *ngIf="form.get('Name').value !== 'Administrador'"
                                [appearance]="'outline'"
                                [_dataSource]="listModulos" [textInput]="''" [dataShow]="'module'" [dataValue]="'value'" [siteCtrl]="controlModule"
                                [inputType]="'text'">
                </app-select-search>
            </div>
            <!-- <div class="inputTogle" *ngIf="form.get('Name').value !== 'Administrador'">
                <mat-slide-toggle formControlName="IsProject" (change)="changeToggle($event, 'IsProject')"></mat-slide-toggle>&nbsp;
                <label>Visible en proyectos</label>
            </div>
            <div class="inputTogle" *ngIf="form.get('Name').value !== 'Administrador'">
                <mat-slide-toggle formControlName="IsContract" (change)="changeToggle($event, 'IsContract')"></mat-slide-toggle>&nbsp;
                <label>Visible en contratos</label>
            </div>
            <div class="inputTogle" *ngIf="form.get('Name').value !== 'Administrador'">
                <mat-slide-toggle formControlName="IsCompany" (change)="changeToggle($event, 'IsCompany')"></mat-slide-toggle>&nbsp;
                <label>Visible en empresas</label>
            </div>
            <div class="inputTogle" *ngIf="form.get('Name').value !== 'Administrador'">
                <mat-slide-toggle formControlName="IsAdmin" (change)="changeToggle($event, 'IsAdmin')"></mat-slide-toggle>&nbsp;
                <label>Visible en Administradoras</label>
            </div> -->
        </form>
        <div class="subTitle">Permisos por modulo</div>
        <div class="menu itemTree" *ngFor="let rol of rolesView">
            <ul class="treeStart">    
                <li >
                    <span class="branch" (click)="rol.IsExpanded = !rol.IsExpanded">
                        <div class="nameBranch">
                            <mat-icon *ngIf="rol.IsExpanded === false">chevron_right</mat-icon><mat-icon *ngIf="rol.IsExpanded === true">expand_more</mat-icon>
                            <label style="font-weight: 500;">{{rol.Label}}</label>
                        </div>
                        <div class="iconsBranch">
                            <img *ngIf="rol?.IsAnalityco === true"  [matTooltip]="tooltipIsAnalityco" [matTooltipPosition]="'above'" src="https://s3.amazonaws.com/analityco-acceso/assets/images/logo.svg" style="width: 24px; height: 24px;" class="tooltipstered">
                            <mat-icon *ngIf="rol?.IsAdmin === true" [matTooltip]="tooltipIsAdmin" [matTooltipPosition]="'above'">supervised_user_circle</mat-icon>
                            <mat-icon *ngIf="rol?.IsWeb === true" [matTooltip]="tooltipIsWeb">web</mat-icon>
                            <mat-icon *ngIf="rol?.IsApp === true" [matTooltip]="tooltipIsApp">smartphone</mat-icon>
                        </div>
                    </span>
                    
                    <ul class="tree" [ngClass]="rol.IsExpanded === true? 'open': ''">
                        <span class="branch" *ngFor="let per of rol.Permissions" style="border-bottom: 1px solid rgb(139, 139, 139);">
                            <div class="nameBranch">
                                <mat-slide-toggle [checked]="havePermisiion(per.Value)" (change)="changePermisiion($event, per.Value)"></mat-slide-toggle>&nbsp;
                                <label>{{per.Label}}</label>
                            </div>
                            <div class="iconsBranch">
                                <img *ngIf="per?.IsAnalityco === true" [matTooltip]="tooltipIsAnalityco" [matTooltipPosition]="'above'"  src="https://s3.amazonaws.com/analityco-acceso/assets/images/logo.svg" style="width: 24px; height: 24px;" class="tooltipstered">
                                <mat-icon *ngIf="per?.IsAdmin === true" [matTooltip]="tooltipIsAdmin" [matTooltipPosition]="'above'">supervised_user_circle</mat-icon>
                                <mat-icon *ngIf="per?.IsWeb === true" [matTooltip]="tooltipIsWeb" [matTooltipPosition]="'above'">web</mat-icon>
                                <mat-icon *ngIf="per?.IsApp === true" [matTooltip]="tooltipIsApp" [matTooltipPosition]="'above'">smartphone</mat-icon>
                            </div>
                        </span>
                        <li *ngFor="let gro of rol.Groups">
                            <span class="branch" (click)="gro.IsExpanded = !gro.IsExpanded">
                                <div class="nameBranch">
                                    <mat-icon *ngIf="gro.IsExpanded === false">chevron_right</mat-icon><mat-icon *ngIf="gro.IsExpanded === true">expand_more</mat-icon>
                                    <label>{{gro.Label}}</label>
                                </div>
                            </span>
                            <ul class="tree" [ngClass]="gro.IsExpanded === true? 'open': ''">
                                <li *ngFor="let per of gro.Permissions">
                                    <span class="branch" style="border-bottom: 1px solid rgb(139, 139, 139);">
                                        <div class="nameBranch">
                                            <mat-slide-toggle [checked]="havePermisiion(per.Value)" (change)="changePermisiion($event, per.Value)"></mat-slide-toggle>&nbsp;
                                            <label>{{per.Label}}</label>
                                        </div>
                                        <div class="iconsBranch">
                                            <img *ngIf="per?.IsAnalityco === true" [matTooltip]="tooltipIsAnalityco" [matTooltipPosition]="'above'" src="https://s3.amazonaws.com/analityco-acceso/assets/images/logo.svg" style="width: 24px; height: 24px;" class="tooltipstered">
                                            <mat-icon *ngIf="per?.IsAdmin === true" [matTooltip]="tooltipIsAdmin" [matTooltipPosition]="'above'">supervised_user_circle</mat-icon>
                                            <mat-icon *ngIf="per?.IsWeb === true" [matTooltip]="tooltipIsWeb" [matTooltipPosition]="'above'">web</mat-icon>
                                            <mat-icon *ngIf="per?.IsApp === true" [matTooltip]="tooltipIsApp" [matTooltipPosition]="'above'">smartphone</mat-icon>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>  
    </div>
    <div class="btn-actions"style="margin: 3px;">
        <button mat-button class="btn-cancelar" (click)="close()">{{'CANCELAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" (click)="save()">{{data.IdRol !== undefined ?'ACTUALIZAR': 'CREAR'}}</button>
    </div>
</div>
