import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { EmployerService } from 'src/app/core/services/modules/employer.service';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { FilterMultipleService } from '../../../../core/services/utils/filter-multiple.service';
import { DialogService } from 'src/app/core/services/utils/dialog.service';
import { CommonOptions, DatesPeriods, FilterList } from 'src/app/core/interfaces/interfaces';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { FormControl } from '@angular/forms';
import { FechasService } from 'src/app/core/services/utils/fechas.service';

export interface DialogData {
  period:string;
}

@Component({
  selector: 'app-dialog-liquidaciones',
  templateUrl: './dialog-liquidaciones.component.html',
  styleUrls: ['./dialog-liquidaciones.component.scss']
})
export class DialogLiquidacionesComponent implements OnInit {
  production:boolean = environment.production;
  s3Bucket:string;

  dataRange: DatesPeriods[] = [];
  employers: any= [];
  employersSlice: any= [];
  filterListValue: string = "";

  controlFormControl = new FormControl();

  columnsLiquidaciones: string[] = ['DocumentType','DocumentNumber', 'Name', 'state', 'user', 'actions'];
  tableDataSourceLiquidaciones: MatTableDataSource<any>;
  @ViewChild('paginatorLiquidaciones', {read: MatPaginator}) paginatorLiquidaciones: MatPaginator;
  @ViewChild(MatSort) sortLiquidaciones: MatSort;


  @ViewChild('paginatorEmployers', {read: MatPaginator}) paginatorEmployers: MatPaginator;

  selectedTab = 1;
  liquidaciones = false;

  period: string;
  socialSecurityDocumentState: CommonOptions[] = [];

  filterList: FilterList[] = [
    { Id: 1, Value: '', Name: 'Todos', icon: 'business', count: null},
    { Id: 2, Value: 'ADMINISTRADAS', Name: 'ADMINISTRADAS', icon: 'business', count: null}
  ];

  constructor(
    public dialogRef: MatDialogRef<DialogLiquidacionesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private employerService: EmployerService,
    private dialogService: DialogService,
    private filters: FilterMultipleService,
    private commonService: CommonService,
    private fechasService: FechasService,
  ) { }

  ngOnInit(): void {
    this.s3Bucket= environment.api.url;
    this.dataRange = this.fechasService.getListDateYYYYMM(1,4);
    this.commonService.getCommonOptions('SocialSecurityDocumentState').subscribe(common => {
      this.socialSecurityDocumentState = common;
    });
    this.period = this.data.period
    this.controlFormControl.setValue(this.data.period);
    this.getSocialSecurity(this.period);
    
  }

  getSocialSecurity(period) {
    this.employerService.getSocialsecuritydocument(period).subscribe((data:any )=> {
      // liquidaciones
      this.tableDataSourceLiquidaciones = new MatTableDataSource(data);
        // filtro filterPredicate objetos dentro de un array
        this.tableDataSourceLiquidaciones.filterPredicate = (data: any, filter) => {
          const dataStr =JSON.stringify(data).toUpperCase();
          return dataStr.indexOf(filter) != -1; 
        }
        this.tableDataSourceLiquidaciones.paginator = this.paginatorLiquidaciones;
        this.tableDataSourceLiquidaciones.sort = this.sortLiquidaciones;
    });
  }

  getUrlFile(url) {
    const urlFile = this.s3Bucket+url;
    return urlFile;
  }

  openFileUrlFile(url, State) {
    if(State === 'Liquidated' || State === 'Pagado') {
      const urlFile = this.s3Bucket+url;
      window.open(urlFile);
    }
  }


  liquidar(CompanyId) {
    this.employerService.postSocialsecuritydocument(this.period, CompanyId).subscribe((document: any) => {
      // console.log(rest.url);
      // this.employerService.putSocialsecuritydocumentState(this.period, CompanyId, this.getStateLiquidacion('Liquidated')).subscribe(putSecurity => {
        // console.log(this.period);
        this.getSocialSecurity(this.period);
        window.open(this.getUrlFile(document.url));
      // });
    });
  }

  pagarPlanilla(EmployerId) {
      this.employerService.putSocialsecuritydocumentState(this.period, EmployerId, this.getStateLiquidacion('Pagado')).subscribe(putSecurity => {
        this.getSocialSecurity(this.period);
      });
  }


  selectedPeriod(e) {
    this.period = e;
    this.getSocialSecurity(e);
  }

  close() {
    this.dialogRef.close();
  }


  getStateLiquidacion(state): number {
    let security: CommonOptions =  this.socialSecurityDocumentState.find(x=> x.Value === state);
    if(security !== undefined) {
      return security.Id;
    } else {
      return 0;
    }
  }

  getImg(logo) {
    let img = (logo === null || logo === '' ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg': logo); 
    return img;
  }

  searchLiquidacion(value: string)  {
    this.tableDataSourceLiquidaciones.filter = value.trim().toUpperCase();
  }

  arregloFinal: any[] = [];
  arreglo: any[] = [];
  searchFilterPagination(changeFilter: string){ 
    const startIndex = this.paginatorEmployers.pageIndex * this.paginatorEmployers.pageSize;
    let endIndex = startIndex + this.paginatorEmployers.pageSize;
    // console.log('filterValue1 :>> ', this.filterValue);
    if(this.filterListValue === '' && changeFilter === '' && this.filterValue === '' ) {
      // console.log('this.filterValue2 :>> ', this.filterValue);
      this.arreglo = this.employers;
    }else { 
      // console.log('this.filterValue3 :>> ', this.filterValue);
      this.filterListValue = changeFilter;
      this.arreglo = this.getEmployerSearch(this.filterListValue, this.filterValue);
    }

    if(endIndex > this.arreglo.length) {
      endIndex = this.arreglo.length;
    }
    this.employersSlice = this.arreglo.slice(startIndex, endIndex);
  }

  filterValue: string = '';
  searchFilter(filterValue) {
    this.selectedTab = filterValue.Id;
    this.filterValue = filterValue.Value;
    this.searchFilterPagination(this.filterListValue);
    if(this.selectedTab !== 2) {
      this.deactivateLiquidaciones();
    }
  }


  activateLiquidaciones() {
    this.dialogService.openDialogSelectPeriod('PERIODO A TRABAJAR', 'Seleccione periodo de liquidación pensiones y riesgos laborales', 1, 4).afterClosed().subscribe(restDialogSelectedPeriod => {
      if(restDialogSelectedPeriod !== undefined) {
        this.liquidaciones = true;
      }
    });
    
  }

  deactivateLiquidaciones() {
    this.liquidaciones = false;
  }

  getEmployerSearch(value: string, filter?: string): any[] {
    // console.log('object4 :>> ', filter);
    if(value === '' && (filter === '' || filter === undefined)) {
        return this.employers;
    } else if(filter === 'ADMINISTRADAS'){
      // console.log('ADMINISTRADAS');
        return this.employers.filter(x => {
            return  ((this.filters.exitFilter(x.Name , value) ||
                    this.filters.exitFilter(x.StateName, value) ||
                    this.filters.exitFilter(x.DocumentNumber, value)||
                    this.filters.exitFilter(x.DocumentType, value)) && 
                    (x.IsManaged === true)
                    );
        });
    }else {
      return this.employers.filter(x => {
        return  (this.filters.exitFilter(x.Name , value) ||
                this.filters.exitFilter(x.StateName, value) ||
                this.filters.exitFilter(x.DocumentNumber, value)||
                this.filters.exitFilter(x.DocumentType, value));
      });
    }
  }


  // addEmployer() {
  //   this.dialogService.openDialogSearchEmployer('Hola').afterClosed().subscribe(rest => {
      
  //   });
  // }


}
