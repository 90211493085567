import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './core/services/guards/AuthGuardService';
import { TokenreceiverComponent } from './tokenreceiver/tokenreceiver.component';
import { SymptomReportComponent } from './core/custom/symptom-report/symptom-report.component';
import { PrintCarnetsComponent } from './core/custom/print-carnets/print-carnets.component';

const defaultRedirect = '/auth/login';

const routes: Routes = [
  { path: '', redirectTo: defaultRedirect, pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)} ,
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
                   canActivate: [AuthGuardService]
    } ,
  { path: 'receiver', component: TokenreceiverComponent},
  { path: 'SymptomReport', component: SymptomReportComponent},
  { path: 'PrintCarnet/:id/:position', component: PrintCarnetsComponent},
  { path: '**', redirectTo: defaultRedirect, pathMatch: 'full' },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
