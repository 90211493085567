import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonOptions, ModelDocumentsShow } from '../../../interfaces/interfaces';
import { DialogService } from '../../../services/utils/dialog.service';
import { EmployerService } from '../../../services/modules/employer.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { CropperComponent } from 'angular-cropperjs';
import { AuthService } from 'src/app/core/services/modules/auth.service';
import { User } from 'src/app/core/models/User';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';

export interface DialogData {
  title: string;
  docs: ModelDocumentsShow[];
  desc: string;
  CompanyInfoParentId?: string;
  typeOperator: string;
}

@Component({
  selector: 'app-dialog-document-comment-input-and-output',
  templateUrl: './dialog-document-comment-input-and-output.component.html',
  styleUrls: ['./dialog-document-comment-input-and-output.component.scss']
})
export class DialogDocumentCommentInputAndOutputComponent implements OnInit {
  @ViewChild('angularCropper') angularCropper: CropperComponent;

  s3Bucket:string;
  DocsJSON: any = {};
  documentos: any[] = [];

  cropper: boolean = false;
  // FIles
  formFile: FormGroup;
  _file: File = null;
  files: File[] = [];
  email: string = '';
  cropperOptions: any = {};

  userAuth: User;

  motivos :any[] = [];
  controlMotivo = new FormControl();

  commentaryInput: string = '';

  constructor(
    private dialogRef: MatDialogRef<DialogDocumentCommentInputAndOutputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private authService: AuthService
  ) {
    this.userAuth = this.authService.getUser();
    this.email = this.userAuth?.Email !== null && this.userAuth?.Email !== undefined ? this.userAuth?.Email: '';
    this.formFile = this.fb.group({
      File: ''
    });
    // this.employerService.getPersonalReportEventReason(this.data?.CompanyInfoParentId).subscribe((data: CommonOptions[]) => {
    //   this.motivos = data;
    // });
   }

  ngOnInit(): void {
    this.s3Bucket= environment.api.s3Bucket;
    this.data.docs.forEach(doc => {
      if(this.getName(doc).includes('commentOnly')){
        let data = this.getSplitDoc(doc);
        for (let index = 0; index < data.length; index++) {
            console.log(data[index]);
            if(index === 0) {
              doc.user = data[index];
            }
            if(index === 1) {
              doc.name = data[index];
            }
            if(index === 2) {
              doc.user = data[index];
            } 
            if(index === 3) {
              doc.date = data[index];
            }
            if(index === 4) {
              doc.nameFile = data[index];
            }           
        }
        doc.isCommentary = true;
      } else if(this.getName(doc).includes('commentary_fileAnalityco.txt')){
        let archivo = new XMLHttpRequest();
        archivo.open('GET', this.s3Bucket+this.getUrl(doc), false);
        archivo.send(null);
        // console.log('getSplitDoc',this.getSplitDoc(doc));
        let data = this.getSplitDoc(doc);
        for (let index = 0; index < data.length; index++) {
            if(index === 0) {
              doc.urlDoc = data[index];
            }
            if(index === 1) {
              doc.nameFile = archivo.responseText;
            }
            if(index === 2) {
              doc.user = data[index];
            } 
            if(index === 3) {
              doc.date = data[index];
            }
            if(index === 4) {
              doc.comment = data[index];
            }           
        }
        doc.isCommentary = true;
      } else {
        let data = this.getSplitDoc(doc);
        for (let index = 0; index < data.length; index++) {
            if(index === 0) {
              doc.urlDoc = data[index];
            }
            if(index === 1) {
              doc.nameFile = data[index];
            }
            if(index === 2) {
              doc.user = data[index];
            } 
            if(index === 3) {
              doc.date = data[index];
            }
            if(index === 4) {
              doc.comment = data[index];
            }            
        }
        doc.isCommentary = false;
      }
      this.documentos.push(doc);  
    });
    this.documentos.reverse();
  }

  openImgVideo(doc: ModelDocumentsShow) {
    this.dialogService.openDialogImageOrVideoOrPdf(this.getUrl(doc)).afterClosed().subscribe(x => {});
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  exitComentarioInMotivo(comment): boolean {
    let exit = false;
    let data = this.motivos.find(x => x.Value === comment);
    data !== undefined ? exit = true: exit = false;
    return exit;
  }

  getSplitDoc(doc: ModelDocumentsShow) {
    let name: any = '';
    if(doc) {
      let a = doc.urlDoc.split('||');
      for (let index = 0; index < a.length; index++) {
        if(index === 1)  {
          a[index] = a[index].replace('name=','');
        }
        if(index === 2) {
          a[index] = a[index].replace('user=','');
        }
        if(index === 3) {
          a[index] = a[index].replace('date=','');
        }
        if(index === 4) {
          a[index] = a[index].replace('comment=','');
        }
        if(index === a.length-1) {
          name = a;
        }
      }  
    }
    return name;
  }

  getName(doc: ModelDocumentsShow) {
    let name = '';
    if(doc) {
      let a = doc.urlDoc.split('||name=');
      name = a[1];
    }
    return name;
  }

  getUrl(doc: ModelDocumentsShow): string {
    let url = ''
    let a = doc?.urlDoc?.split('||name=');
    if(a !== undefined) {
      url = a[0];
    }
    return url;
  }

  getUser(doc: ModelDocumentsShow): string {
    let url = ''
    let a = doc?.urlDoc?.split('||user=');
    if(a !== undefined) {
      url = a[1];
    }
    return url;
  }

  jsonUpdateDeleteDoc(doc: ModelDocumentsShow, index) {
    // console.log(doc, index);
    let DocsJSON = `{"${doc.name}": "${doc.urlDoc}"}`
    let a = JSON.parse(DocsJSON);
    Object.assign(this.DocsJSON, a);
    delete this.documentos[index];
  }

  jsonDocExitValue(): boolean {
    return Object.entries(this.DocsJSON).length === 0;
  }

  upDateDocuments: any;
  upDateDocs(DocsJSON) {
    this.dialogRef.close({DocsJSON, files: this.files});    
  }

  changeFile(event: any) {
    let selectedFiles: FileList = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.files.push(selectedFiles[i]);
    }
  }

  // METODOS PARA CORTAR UNA IMAGEN Y GUARDARLA
  imgCropper: string;
  imgCropperResult: string;
  formData = new FormData();
	onSelect(event) {
    if(this.cropper === false) {
      let selectedFiles: FileList = event.addedFiles;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.files.push(selectedFiles[i]);
      }
    }
	}

  save() {
    let arrayFormData = [];
    for (let i = 0; i < this.files.length; i++) {
      // se valida si exite el file o se va undefined
      if(this.files[i].name) {
        let formData = new FormData();
        formData.append('File', this.files[i]);
        formData.append('comment',(this.commentaryInput !== '' ? this.commentaryInput: this.commentarySelect));
        arrayFormData.push({FormData: formData});
      }
      if(i === this.files.length-1) {
        this.dialogRef.close({arrayFormData: arrayFormData, comment: this.commentaryInput , exitDocument: true});
      }
    } 
    if((this.commentaryInput !== '' || this.commentarySelect !== '') && this.files.length === 0) {
        this.dialogRef.close({comment: (this.commentaryInput !== '' ? this.commentaryInput: this.commentarySelect), exitDocument: false});
    }
  }

  isEqualsUserRemoveFile(doc): boolean {
    let equal = false;
    this.userAuth.username === doc?.user ? equal = true: equal = false;
    return equal;
  }


  removeFile(index) {
    delete this.files[index];
  }

  isXLSX(doc: any): boolean {
    return this.getUrl(doc).slice(-5).toUpperCase() === '.XLSX';
  }

  getUrlFile(url) {
    const urlFile = this.s3Bucket+this.getUrl(url)
    return urlFile;
  }

  changeNameOrCommentary(file: File): any {
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function(e) { 
      return reader.result;
    };
  }

	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
	}

  flagShowCommentary = false;
  commentarySelect: string = '';
  selectedMotivo(motivo) {
    if(motivo.toUpperCase() === 'OTROS') {
      this.flagShowCommentary = true;
    } else {
      this.commentarySelect = motivo;
      this.flagShowCommentary = false;
      this.commentaryInput = '';
    }
  }

}