<div class="content-dialog">
  <h3>Selecciona el periodo de salud liquidado en la planilla</h3>
  <mat-form-field class="input-field">
    <mat-label>MES</mat-label>
    <mat-select name="datePeriod" [(ngModel)]="data.datePeriod">
      <mat-option *ngFor="let date of datesPeriod" [value]="date.dateAPi">{{date.monthText}} {{date.year}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="actions-dialog">
    <button mat-button class="btn-cancelar" (click)="onNoClick()">CANCELAR</button>&nbsp;&nbsp;
    <button mat-button class="btn-generar" [mat-dialog-close]="data">GENERAR</button>
  </div>

  <div class="check-dialog">
      <mat-checkbox class="example-margin" [(ngModel)]="data.copyPrevious">Copiar ultima planilla generada</mat-checkbox>
  </div>

</div>