import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/modules/common.service';

export interface DialogData {
  Id: string;
}

@Component({
  selector: 'app-dialog-common-options',
  templateUrl: './dialog-common-options.component.html',
  styleUrls: ['./dialog-common-options.component.scss']
})
export class DialogCommonOptionsComponent implements OnInit {

  form: FormGroup;
  constructor(public dialogRef: MatDialogRef<DialogCommonOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonService: CommonService,
    private fb: FormBuilder) { 
      this.form = this.fb.group({
        Description: new FormControl('',Validators.required),
        IsActive: new FormControl('',Validators.required),
        OrderNum: new FormControl('',Validators.required),
        Type: new FormControl('',Validators.required),
        Value: new FormControl('',Validators.required),
        File: ''
      });
      if(this.data.Id !== undefined) {
        this.commonService.getCommonOptionsId(this.data.Id).subscribe((rest: any) => {
          this.form.get('Description').setValue(rest.Description);
          this.form.get('IsActive').setValue(rest.IsActive);
          this.form.get('OrderNum').setValue(rest.OrderNum);
          this.form.get('Type').setValue(rest.Type);
          this.form.get('Value').setValue(rest.Value);
          this.form.get('File').setValue(rest.File);
        });
      }
    }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    if(this.form.status === 'VALID') {
      if(this.data.Id !== undefined) {
        let model = {
          ...this.form.value,
          Id: this.data.Id
        }
        this.commonService.putCommonOptionsId(this.data.Id, model).subscribe(rest => {
          this.dialogRef.close(true);
        })
      } else {
        this.commonService.postCommonOptions(this.form.value).subscribe(rest => {
          this.dialogRef.close(true);
        });
      }
    }
  }

}
