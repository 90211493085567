<div class="box">
    <title-dialog [title]="data.title ||'REQUISITO DE ACCESO'" [color]="'greendAnalityco'" [_module]="'Companies'"></title-dialog>

    <!-- Acceso de Proyecto -->
    <div class="content-acceso">
        <div class="search">
            <input class="input-search" 
                type="text" 
                placeholder="Buscar..." 
                (keyup)="getfilterRequirements($event.target.value)">
        </div>
        <div class="acceso-proyect">
            <table>
                <tbody>
                    <tr>
                        <th style="padding: 8px 16px;">Documentos</th>
                        <th style="padding: 8px 16px;"></th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsDocuments" class="array">
                        <td>
                            <div class="icons-requirements">
                                <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                            </div>
                        </td>
                        <td style="text-align: center;">
                            <mat-slide-toggle style="padding: 8px 16px;"
                                [checked]="cheketRequisito(requirement.Id)"
                                (change)="checked($event, requirement.Id)">
                            </mat-slide-toggle>
                        </td>
                    </tr>
                    <tr>
                        <th style="padding: 8px 16px;">Documentos con vigencia</th>
                        <th style="padding: 8px 16px;"></th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsDocumentsVigenci" class="array">
                        <td>
                            <div class="icons-requirements">
                                <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                            </div>
                        </td>
                        <td style="text-align: center;">
                            <mat-slide-toggle style="padding: 8px 16px;"
                                [checked]="cheketRequisito(requirement.Id)"
                                (change)="checked($event, requirement.Id)">
                            </mat-slide-toggle>
                        </td>
                    </tr>
                    <tr>
                        <th style="padding: 8px 16px;">Inspecciones</th>
                        <th style="padding: 8px 16px;"></th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsInspeccion" class="array">
                        <td>
                            <div class="icons-requirements">
                                <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                            </div>
                        </td>
                        <td style="text-align: center;">
                            <mat-slide-toggle style="padding: 8px 16px;"
                                [checked]="cheketRequisito(requirement.Id)"
                                (change)="checked($event, requirement.Id)"
                                [disabled]='true'>
                            </mat-slide-toggle>
                        </td>
                    </tr>
                    <tr>
                        <th style="padding: 8px 16px;">Inspecciones con comparación</th>
                        <th style="padding: 8px 16px;"></th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsInspeccionParam" class="array">
                        <td>
                            <div class="icons-requirements">
                                <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                            </div>
                        </td>
                        <td style="text-align: center;">
                            <mat-slide-toggle style="padding: 8px 16px;"
                                [checked]="cheketRequisito(requirement.Id)"
                                (change)="checked($event, requirement.Id)"
                                [disabled]='true'>
                            </mat-slide-toggle>
                        </td>
                    </tr>
                    <tr>
                        <th style="padding: 8px 16px;">Encuestas</th>
                        <th style="padding: 8px 16px;"></th>
                    </tr>
                    <tr *ngFor="let requirement of filterRequirementsEncuesta" class="array">
                        <td>
                            <div class="icons-requirements">
                                <mat-icon *ngIf="requirement.ByEmployer" matTooltip="EL EMPLEADOR">business</mat-icon>
                                <mat-icon *ngIf="requirement.ByPersonal" matTooltip="EL EMPLEADO">person</mat-icon>
                                <img *ngIf="requirement.ByEmployerPersonal" src="https://analityco-acceso.s3.amazonaws.com/assets/images/unnamed.png" alt="" width="26" height="26" matTooltip="EL EMPLEADO Y SU EMPLEADOR">
                                &nbsp;&nbsp;
                                <div>{{requirement.Name}}</div>
                            </div>
                        </td>
                        <td style="text-align: center;">
                            <mat-slide-toggle style="padding: 8px 16px;"
                                [checked]="cheketRequisito(requirement.Id)"
                                (change)="checked($event, requirement.Id)"
                                [disabled]='true'>
                            </mat-slide-toggle>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="actions-dialog">
        <button mat-button class="btn-cancelar" (click)="close()" >CANCELAR</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" (click)="save()">GUARDAR</button>
    </div>
</div>
