import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { DialogService } from '../../../services/utils/dialog.service';
import { SnackBarService } from '../../../services/utils/snackBar.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonMetodService } from 'src/app/core/services/utils/commonMetod.service';
import { CurrencyPipe } from '@angular/common';

export interface DialogData {
  title: string
  name: string;
  url?: string;
  comment: string;
  aceptText: string;
  isRechazo: boolean;
  isRetiro: boolean;
}

@Component({
  selector: 'app-dialog-individual-contract-aproved',
  templateUrl: './dialog-individual-contract-aproved.component.html',
  styleUrls: ['./dialog-individual-contract-aproved.component.scss']
})
export class DialogIndividualContractAprovedComponent implements OnInit {
  s3Bucket:string;
  
  comment: string = ''
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogIndividualContractAprovedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private currencyPipe: CurrencyPipe,
    private commonMetodService: CommonMetodService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      Severance : 0,
    });
    this.form.valueChanges.subscribe(form => {
      if(form.Severance && this.commonMetodService._validarDateMoney(form.Severance)
        ) {
        this.form.patchValue({
          Severance: this.currencyPipe.transform(form.Severance.toString().replace(/\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '1.0-0').replace(/,/gi, '.')
        }, {emitEvent: false});
      }
    });
   }

  

  onNoClick(): void {
    this.dialogRef.close();
  }

  show() {
    this.dialogService.openDialogImageOrVideoOrPdf(this.data.url).afterClosed().subscribe(rest =>{
    });
  }

  acept(): void {
    if(this.data.isRechazo !== undefined && this.data.isRetiro === undefined) {
      // if(this.comment === '') {
      //   this.snackBService.openSnackBar('Colocar comentario para rechazar la orden', 'X', 4000);
      // } else {
        this.dialogRef.close(this.comment);
      // }
    } else if(this.data.isRechazo !== undefined && this.data.isRetiro !== undefined) {
      this.dialogRef.close({Comment: this.comment, Severance: this.form.get('Severance').value});
    }
     else {
      this.dialogRef.close(true);
    }
  }

  ngOnInit(): void {
    this.s3Bucket= environment.api.s3Bucket;
  }


}
