import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CardList } from 'src/app/core/interfaces/interfaces';
import { FilterMultipleService } from 'src/app/core/services/utils/filter-multiple.service';
import { environment } from 'src/environments/environment';

export interface DialogData {
  dataList: any;
  card: CardList;
  title: string;
}

@Component({
  selector: 'app-dialog-selected-item',
  templateUrl: './dialog-selected-item.component.html',
  styleUrls: ['./dialog-selected-item.component.scss']
})
export class DialogSelectedItemComponent implements OnInit {
  s3Bucket:string;
  
  list: any= [];
  filterlist: any= [];

  constructor(
    public dialogRef: MatDialogRef<DialogSelectedItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private filters: FilterMultipleService,
  ) {
    this.list = data.dataList;
    this.filterlist = data.dataList;
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
  }

  getImg(logo) {
    let img = (logo === null || logo === '' || logo === undefined ? 'assets/images/panteon.png': logo); 
    return img;
  }

  close() {
    this.dialogRef.close();
  }

  selectedItem(item: any) {
    this.dialogRef.close(item);
  }


  getfilterlist(value: string) {
    if(value === '') {
      this.filterlist = this.list;
    } else {
      this.filterlist = this.getListFilter(value);
    }
  }

  getListFilter(value: string): any[] {
      return this.list.filter(x => {
        return (this.filters.exitFilter(x[this.data.card.titleFirst]+ ' ' + (x[this.data.card.titleSecond] === undefined ? '' : x[this.data.card.titleSecond]), value) ||
                this.filters.exitFilter(x[this.data.card.subTitle], value) ||
                this.filters.exitFilter(x[this.data.card.itemValue], value));
      });
  }



}
