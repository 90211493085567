import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FechasService } from '../../services/utils/fechas.service';
import { CommonMetodService } from '../../services/utils/commonMetod.service';
import { Router } from '@angular/router';

export interface State {
  name: string;
  stateDate: string;
}

export interface UrlRouter {
  url: string;
  adminInfoId: string;
  id: string;
}

export interface DataAbove {
  iconStart: string;
  iconStartProyect?: boolean;
  iconMedium: string;
  iconOperation: string;
  textStart: string;
  textMedium: string;
  iconJob: boolean;
}


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  s3Bucket: string;

  opcionMenu1Disabled: boolean = false;
  opcionMenu2Disabled: boolean = false;
  opcionMenu3Disabled: boolean = false;
  opcionMenu4Disabled: boolean = false;
  AllDisabled: boolean = false;

  ckeckbox: boolean = false;

  @Input() cardInformation: boolean = false;
  @Input() cardNotImg: boolean = false;

  @Input() cardIsSelected: boolean = false;

  @Input() dataAbove: DataAbove;

  @Input() niveles: number;

  @Input() title: string;
  @Input() photo: string;
  @Input() item1: string;
  @Input() item2: string;
  @Input() item3: string;
  @Input() itemF1: string;
  @Input() itemDateIcon: string;
  // @Input() itemFstate: string;
  @Input() itemF2: string;
  @Input() itemFinal: string;
  @Input() itemFinalDate: string;
  @Input() itemFinalText: string;
  @Input() state: State;

  @Input() contractCategoria: string;

  @Input() IsManaged: boolean;

  @Input() stateTriangle: string = '';

  @Input() onErrorUrl: string = '';

  @Input() urlRouter: UrlRouter;

  @Input() IconCompanyAsociada: string;
  @Input() opcionMenu1: string;
  @Input() opcionMenu2: string;
  @Input() opcionMenu3: string;
  @Input() opcionMenu4: string;

  @Input() iconOpcionMenu1: string;
  @Input() iconOpcionMenu2: string;
  @Input() iconOpcionMenu3: string;
  @Input() iconOpcionMenu4: string;

  // Acciones Menu
  @Output() clickOpcion1 = new EventEmitter();
  @Output() clickOpcion2 = new EventEmitter();
  @Output() clickOpcion3 = new EventEmitter();
  @Output() clickOpcion4 = new EventEmitter();
  @Output() clickCkeckBox = new EventEmitter();

  @Input() set _ckeckbox(value: any) {
    if (value !== undefined && value !== '') {
      this.ckeckbox = value;
    }
  }

  // Acciones Menu Disabled
  @Input() set _opcionMenu2Disabled(value: any) {
    if (value !== undefined && value !== '') {
      this.opcionMenu2Disabled = value;
    }
  }
  @Input() set _opcionMenu3Disabled(value: any) {
    if (value !== undefined && value !== '') {
      this.opcionMenu3Disabled = value;
    }
  }
  @Input() set _opcionMenu4Disabled(value: any) {
    if (value !== undefined && value !== '') {
      this.opcionMenu4Disabled = value;
    }
  }

  @Input() set _AllDisabled(value: any) {
    if (value !== '') {
      this.AllDisabled = value;
    }
  }

  @Input() set _opcionMenu1Disabled(value: any) {
    if (value !== undefined && value !== '') {
      this.opcionMenu1Disabled = value;
    }
  }

  constructor(public fechasService: FechasService,
              public commonMetod: CommonMetodService,
              private router: Router
  ) { }

  ngOnInit(): void {
    this.router.url === '/admin/info/report-list' ? this.s3Bucket = environment.api.s3Bucket : this.s3Bucket = environment.api.s3Bucket;
  }

  calculateDate(date): string {
    let data = '';
    if (date !== null && date !== '' && date !== undefined) {
      data = this.fechasService.calculateStateDateWithHORA(date);
    }
    return data;
  }

  clickOpc1(){
    this.clickOpcion1.emit();
  }

  clickOpc2(){
    this.clickOpcion2.emit();
  }

  clickOpc3(){
    this.clickOpcion3.emit();
  }

  clickOpc4(){
    this.clickOpcion4.emit();
  }

  clickCkBox(){
    this.clickCkeckBox.emit();
  }


  clickRouterNavigate() {
    if(this.commonMetod._getExitPermissionModel(this.onErrorUrl) === true) {
      if(this.urlRouter !== undefined) {
          this.router.navigate([this.urlRouter.url, this.urlRouter.id]);
      }
    }
  }

}
