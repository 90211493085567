import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CardList, FilterList, ModelDocumentsShow } from 'src/app/core/interfaces/interfaces';
import { EmployerService } from 'src/app/core/services/modules/employer.service';
import { FechasService } from 'src/app/core/services/utils/fechas.service';
import { FilterMultipleService } from 'src/app/core/services/utils/filter-multiple.service';
import { environment } from 'src/environments/environment';
import { DialogService } from '../../../services/utils/dialog.service';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';
import { CommonService } from 'src/app/core/services/modules/common.service';

export interface DialogData {
  dataList: any;
  card: CardList;
  path: string;
  employer: any;
  motivos: any;
}

@Component({
  selector: 'app-dialog-novelty',
  templateUrl: './dialog-novelty.component.html',
  styleUrls: ['./dialog-novelty.component.scss']
})
export class DialogNoveltyComponent implements OnInit {
  production:boolean = environment.production;
  
  formPersonal: FormGroup;
  
  list: any= [];
  listSlice: any= [];
  filterListValue: string = "";

  @ViewChild('paginatorList', {read: MatPaginator}) paginatorList: MatPaginator;

  dateNow = new Date();
  dateNowMontOne = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth()-1, this.dateNow.getDate());
  // minDateFilter = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());

  filterList: FilterList[] = [
    { Value: '', Name: 'Todos', icon: '', count: null},
    { Value: 'IsActive', Name: 'Activos', icon: '', count: null},
    { Value: 'IsInactive', Name: 'Inactivos', icon: '', count: null}
  ];
  employer;
  projectLaboral: any[] = [ {
    Name: 'Todos los empleados', Id: 1
  }];

  columnsPersonal: string[] = ['Id','Lugar', 'PersonalName','Position', 'Novelty', 'Document' , 'Accion' ];
  tableDataSourcePersonal: MatTableDataSource<any>;
  tableDataSourcePersonalMultipleFilter: MatTableDataSource<any>;
  @ViewChild('paginatorPersonal', {read: MatPaginator}) paginatorPersonal: MatPaginator;
  @ViewChild(MatSort) sortPersonal: MatSort;

  dateStart = '';
  dateEnd = '';
  noveltyArraySave: any[]=[];

  motivos: any[] = [];

  permissions: string[] = []; 

  constructor(
    public dialogRef: MatDialogRef<DialogNoveltyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    private filters: FilterMultipleService,
    private fb: FormBuilder,
    public serviceFecha: FechasService,
    private employerService: EmployerService,
    private dialogSevice: DialogService,
    private snackBService: SnackBarService,
    private commonService: CommonService
  ) { 
      this.list = data.dataList;
      this.employer = data.employer;
      this.listSlice = this.list.slice(0,32);
      this.motivos = data.motivos.sort((a:any,b:any) => parseInt(a.OrderNum) > parseInt(b.OrderNum) ? 1 :-1);

      let date = new Date();
      // this.getPersonalReport(date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+'');
      this.dateStart = date.getFullYear()+'-'+(date.getMonth()-1)+'-'+date.getDate()+''
      this.dateEnd = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+''

      this.formPersonal = this.fb.group({
        FilterDate: this.dateNow,
        startDate: this.dateNowMontOne,
        endDate: this.dateNow,  
        ProjectId: 1,
        PersonalReportList: this.fb.array([]),
        PersonalReportListFilter: this.fb.array([]),
      });
      this.commonService.getUserInfo().subscribe(userinfo => {
        this.permissions = userinfo.claims.filter( claim => claim.trim().includes('employer') || claim.trim().includes('contracting'));
      });
     
      this.employerService.getNoveltyComponentArrayForm(this.employer.Id, this.dateStart, this.dateEnd).subscribe(personal => {
        personal.controls = personal.controls.sort((a, b) => a.value.NoveltyCreateDate < b.value.NoveltyCreateDate ? 1 : -1);
        this.formPersonal.setControl('PersonalReportList', personal);
        this.tableDataSourcePersonal = new MatTableDataSource((this.formPersonal.get('PersonalReportList') as FormArray).controls);
        this.tableDataSourcePersonalMultipleFilter = new MatTableDataSource((this.formPersonal.get('PersonalReportList') as FormArray).controls);
        this.configTablePersonal();
        this.formPersonal.markAllAsTouched();
        this.noveltyArraySave = personal.value;
      });
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
  }

  changeIsOpen(personal) {
    let message = ''
    personal.get('IsOpen').value === true ? message = 'Confirmar si desea bloquear la novedad': message = 'Confirmar si desea desbloquear la novedad';
    this.dialogSevice.openDialogConfirm(message,true, true, 'CANCELAR', 'CONFIRMAR').afterClosed().subscribe(rest => {
      if(rest !== false) {
        let model = {
          // StartDate: personal.get('NoveltyStartDate').value,
          // EndDate: personal.get('NoveltyEndDate').value,
          NoveltyTypeId: personal.get('NoveltyTypeId').value,
          IsOpen: personal.get('IsOpen').value === true ? false: true
        };
        this.employerService.putNoveltyDialog(personal.get('Id').value, model).subscribe(rest => {
          personal.get('update').setValue(false);
          this.getPersonalReportProjectLabor();
        });
      } 
    });
  }

  changeDateFilter(e, start?) {
    let data = this.serviceFecha.formatDateYYYYMMDDdatePikerSinhora(e);
    if(start === true) {
      this.dateStart = data; 
    } else {
      this.dateEnd = data; 
    }
    this.getPersonalReportProjectLabor();
  }

  changeNovelty(personal, start?) {
    personal.get('update').setValue(true);
  }

  updateNovelty(personal) {
    let model = {
      StartDate: personal.get('NoveltyStartDate').value,
      EndDate: personal.get('NoveltyEndDate').value,
      NoveltyTypeId: personal.get('NoveltyTypeId').value
    };
    this.employerService.putNoveltyDialog(personal.get('Id').value, model).subscribe((rest: any) => {
      let modelMessage = {
        NoveltyId: rest.Id,
        Comment: this.getNameNoveltySelected(personal)
      }
      this.employerService.postNoveltyMessage(modelMessage).subscribe((restPut:any) => {
        personal.get('update').setValue(false);
        this.getPersonalReportProjectLabor();
      });
      
    }, error => {
        this.snackBService.openSnackBar(error.error.Error,'X',3000);
    });
  }

  getNameNoveltySelected(personal): string {
    let name = '';
    let data = this.motivos.find(x => personal.get('NoveltyTypeId').value === x.Id);
    data !== undefined ? name = data.Name : '';
    return name;
  }

  getPersonalReportProjectLabor() {
    this.employerService.getNoveltyComponentArrayForm(this.employer.Id, this.dateStart, this.dateEnd).subscribe(personal => {
      personal.controls = personal.controls.sort((a, b) => a.value.NoveltyCreateDate < b.value.NoveltyCreateDate ? 1 : -1);
      this.formPersonal.setControl('PersonalReportList', personal);
      this.tableDataSourcePersonal = new MatTableDataSource((this.formPersonal.get('PersonalReportList') as FormArray).controls);
      this.tableDataSourcePersonalMultipleFilter = new MatTableDataSource((this.formPersonal.get('PersonalReportList') as FormArray).controls);
      this.configTablePersonal();
      this.formPersonal.markAllAsTouched();
      this.noveltyArraySave = personal.value;
    });
  }


  dialogDeleteNovelty(personal) {
    this.dialogSevice.openDialogConfirm('Esta seguro de eliminar la novedad '+personal.get('Id').value+'?', true, true, 'NO', 'SI').afterClosed().subscribe(restConfirm => {
      if(restConfirm !== false) {
        this.employerService.deleteNoveltyDialog(personal.get('Id').value).subscribe(rest => {
          this.getPersonalReportProjectLabor();
        });
      }
    });
  }

  openDialogCreateNoveltyDialog() {
    this.dialogSevice.openDialogCreateNoveltyDialog('hola',this.employer, '', false, this.motivos).afterClosed().subscribe(rest => {
      if(rest === true) {
        this.getPersonalReportProjectLabor();
      }
    });
  }

  openDialogDocumentCommentInputAndOutput(personal) {
    let docs = personal.get('NoveltyDocsJSON').value
      const docsShow: ModelDocumentsShow[] = [];
      if (docs !== undefined && docs !== null && docs !== '' && docsShow.length === 0) {
        const array = Object.entries(JSON.parse(docs));
        array.forEach((doc: any) => {
              docsShow.push({ name: doc[0], urlDoc: doc[1]});
        });
      }
      // Si es solo un comentario con la nueva api de comentarios
      personal.get('Comments').value?.forEach(element => {
        let model = {
          name: 'DOC-commentOnly',
          urlDoc: 'undefined||name='+'commentOnly'+'||user='+element.CreateByUserName+'||date='+this.serviceFecha.getDateFormatDateNormal(element.CreateDate)+'||comment='+element.Comment+''
        }
        docsShow.push(model);
      });
      this.dialogSevice.openDialogDocumentCommentInputAndOutput('Documentos y Comentarios', personal.get('IsOpen').value === true? undefined: 'show',  docsShow, 'Documento de motivo de novedad', this.employer?.CompanyInfoParentId, null).afterClosed().subscribe((restDialog: any) => {
        if(restDialog !== false) {
          if(restDialog.exitDocument === true) {
            restDialog.arrayFormData.forEach((item, index) => {
              this.employerService.putNoveltyDialogFile(personal.get('Id').value, item.FormData).subscribe(restPut => {
                if(index === restDialog.arrayFormData.length-1) {
                  this.getPersonalReportProjectLabor();
                }
              });
            });
          } else {
            // si el motivo es sin docuemnto
            let files: File[] = [];
            const file = new File([restDialog.comment],
                                    'commentary_fileAnalityco.txt',{ type: 'text/plain' });
            files.push(file);
            let formData = new FormData();
            formData.append('File', files[0]);
            this.employerService.putNoveltyDialogFile(personal.get('Id').value, formData).subscribe(restPut => {
              this.getPersonalReportProjectLabor();
            });
          }
        }
      });
  }


  // Inicializar paginador, config Filter table, sort
  configTablePersonal() {
    this.tableDataSourcePersonal.filterPredicate = (data: FormGroup, filter: string) => {
      return Object.values(data.controls).some(x => RegExp(filter).test(JSON.stringify(x.value).trim().toUpperCase()));
    };
    this.tableDataSourcePersonal.paginator = this.paginatorPersonal;
    this.tableDataSourcePersonal.sort = this.sortPersonal;
  }

  s3Bucket: string;
  getUrlImg(url) {
    const img = (url === null ? 'assets/images/personblue.png' : url);
    const image = this.s3Bucket + img;
    return image;
  }

  getImg(logo) {
    let img = (logo === null ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg': logo); 
    return img;
  }

  personalId(Id) {
    this.router.navigate([this.data.path, Id]);
    this.close();
  }

  

  close() {
    this.dialogRef.close();
  }


  get getFormPersonal() {
    return this.formPersonal.get('PersonalReportList') as FormArray;
  }

  applyFilterPlanilla(value) {
    const filterArray = this.getFormPersonal.value;
    if(!value.includes(',')) {
      this.tableDataSourcePersonal.filter = value.trim().toUpperCase();
      if(value === '') {
        this.tableDataSourcePersonal.data = this.filterOptionsPlanilla([]);
      }
    } else {
      let trimValue = value.replace(/\s/g, "");
      let array = trimValue.split(",");
      this.tableDataSourcePersonal.filter = '';
      this.tableDataSourcePersonal.data = this.filterOptionsPlanilla(array);
    }

  }

  filterOptionsPlanilla(arrayString: string[]): FormGroup[] {
    let tableDataSourcePersonalData = this.tableDataSourcePersonalMultipleFilter.data;
    if ((!arrayString || arrayString.length === 0)) {
      return tableDataSourcePersonalData;
    }
    const filtered = this.tableDataSourcePersonalMultipleFilter.data.filter((periodicElement) => {
          return arrayString.find(x => x === periodicElement.value.DocumentNumber+'');
    });
    return filtered;
  }

  exitPermission(permiso: string): boolean {
    let exit: boolean = false;
    let permisionsUserInfo  = this.permissions.find(x => x === permiso);
    permisionsUserInfo !== undefined ? exit = true: exit = false;
    return exit;
  }

}

