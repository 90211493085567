import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonOptions } from 'src/app/core/interfaces/interfaces';
import { CommonService } from 'src/app/core/services/modules/common.service';

export interface DialogData {
  Id: string;
  companyId?: string;
  title: string;
}

@Component({
  selector: 'app-dialog-requirement-refence',
  templateUrl: './dialog-requirement-refence.component.html',
  styleUrls: ['./dialog-requirement-refence.component.scss']
})
export class DialogRequirementRefenceComponent implements OnInit {

  dateLabel: CommonOptions[] = [];
  form: FormGroup;
  constructor(public dialogRef: MatDialogRef<DialogRequirementRefenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonService: CommonService,
    private fb: FormBuilder) { 
      this.form = this.fb.group({
        Abrv: new FormControl('',Validators.required),
        ArithmeticOperator: new FormControl(0),
        ByCertification: new FormControl(false),
        ByDate: new FormControl(false),
        ByEmployer: new FormControl(false),
        ByEmployerPersonal: new FormControl(false),
        ByEntry: new FormControl(false),
        ByPersonal: new FormControl(false),
        BySecuritySocial: new FormControl(false),
        BySurvey: new FormControl(false),
        CompanyInfoId: new FormControl(null,),
        Description: new FormControl('',Validators.required),
        EnableInputEntry: new FormControl(false),
        EntryValue: new FormControl(0),
        Id: new FormControl(''),
        DateLabelId: new FormControl(''),
        IsActive: new FormControl(false),
        Name: new FormControl('',Validators.required),
        OrderNum: new FormControl(''),
        RepeatEveryDay: new FormControl(false),
      });
      if(this.data.Id !== undefined) {
        this.commonService.getRequirementId(this.data.Id).subscribe((rest: any) => {
          this.form.get('Abrv').setValue(rest.Abrv);
          this.form.get('ArithmeticOperator').setValue(rest.ArithmeticOperator);
          this.form.get('ByCertification').setValue(rest.ByCertification);
          this.form.get('ByDate').setValue(rest.ByDate);
          this.form.get('ByEmployer').setValue(rest.ByEmployer);
          this.form.get('ByEmployerPersonal').setValue(rest.ByEmployerPersonal);
          this.form.get('ByEntry').setValue(rest.ByEntry);
          this.form.get('ByPersonal').setValue(rest.ByPersonal);
          this.form.get('BySecuritySocial').setValue(rest.BySecuritySocial);
          this.form.get('BySurvey').setValue(rest.BySurvey);
          this.form.get('CompanyInfoId').setValue(rest.CompanyInfoId);
          this.form.get('Description').setValue(rest.Description);
          this.form.get('EnableInputEntry').setValue(rest.EnableInputEntry);
          this.form.get('EntryValue').setValue(rest.EntryValue);
          this.form.get('Id').setValue(rest.Id);
          this.form.get('IsActive').setValue(rest.IsActive);
          this.form.get('Name').setValue(rest.Name);
          this.form.get('OrderNum').setValue(rest.OrderNum);
          this.form.get('RepeatEveryDay').setValue(rest.RepeatEveryDay);
          this.form.get('DateLabelId').setValue(rest.DateLabelId);
        });
      }
      if(this.data.companyId !== undefined) {
        this.form.get('CompanyInfoId').setValue(this.data.companyId);
      }

      this.commonService.getCommonOptions("DateLabel").subscribe((data: CommonOptions[]) => {
        this.dateLabel = data;
      });
    }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    if(this.form.status === 'VALID') {
      if(this.data.Id !== undefined) {
        let model = {
          ...this.form.value,
          Id: this.data.Id
        }
        this.commonService.putRequirementId(this.data.Id, model).subscribe(rest => {
          this.dialogRef.close(true);
        })
      } else {
        let newModel  = this.form.value;
        delete newModel.Id;
        this.commonService.postRequirement(newModel).subscribe(rest => {
          this.dialogRef.close(true);
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  changeByPersonalOrEmployer(e) {
    if(e.value === 'ByPersonal') {
      this.form.get('ByPersonal').setValue(true);
      this.form.get('ByEmployer').setValue(false);
      this.form.get('ByEmployerPersonal').setValue(false);
    } else if(e.value === 'ByEmployer') {
      this.form.get('ByPersonal').setValue(false);
      this.form.get('ByEmployer').setValue(true);
      this.form.get('ByEmployerPersonal').setValue(false);
    } else if(e.value === 'ByEmployerPersonal') {
      this.form.get('ByPersonal').setValue(false);
      this.form.get('ByEmployer').setValue(false);
      this.form.get('ByEmployerPersonal').setValue(true);
    }
  }

  changeToggle(value, e) {
    if(value === 'ByCertification' && e === true) {
      // close los demas
      this.form.get('ByEntry').setValue(false);
      this.form.get('BySecuritySocial').setValue(false);
      this.form.get('BySurvey').setValue(false);

      // no insepcion
      this.form.get('ArithmeticOperator').setValue(0);
      this.form.get('EntryValue').setValue(0);
      this.form.get('EnableInputEntry').setValue(false);

      // no Por encuesta
      this.form.get('RepeatEveryDay').setValue(false);

    } else if(value === 'ByEntry' && e === true) {
      // close los demas
      this.form.get('ByCertification').setValue(false);
      this.form.get('BySecuritySocial').setValue(false);
      this.form.get('BySurvey').setValue(false);

      // no certification
      this.form.get('ByDate').setValue(false);

      // no Por encuesta
      this.form.get('RepeatEveryDay').setValue(false);

    } else if(value === 'BySecuritySocial' && e === true) {
      // close los demas
      this.form.get('ByCertification').setValue(false);
      this.form.get('ByEntry').setValue(false);
      this.form.get('BySurvey').setValue(false);

      // no insepcion
      this.form.get('ArithmeticOperator').setValue(0);
      this.form.get('EntryValue').setValue(0);
      this.form.get('EnableInputEntry').setValue(false);

      // no Por encuesta
      this.form.get('RepeatEveryDay').setValue(false);

      // no certification
      this.form.get('ByDate').setValue(false);
    } else if(value === 'BySurvey' && e === true) {
      // close los demas
      this.form.get('ByEntry').setValue(false);
      this.form.get('BySecuritySocial').setValue(false);
      this.form.get('ByCertification').setValue(false);

      // no insepcion
      this.form.get('ArithmeticOperator').setValue(0);
      this.form.get('EntryValue').setValue(0);
      this.form.get('EnableInputEntry').setValue(false);

      // no certification
      this.form.get('ByDate').setValue(false);
      
    }
  }

}
