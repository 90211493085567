<div class="content-dialog">
    <h3>{{'A SU CORREO SE ENVIO UN CODIGO DE SEGURIDAD'}}</h3>

    <div class="input-characters-content">
        <input type="text" class="input-characters" [(ngModel)]="code" maxlength="5" [autofocus]="true">
        <div class="list-border">
          <div class="linea"></div>
          <div class="linea"></div>
          <div class="linea"></div>
          <div class="linea"></div>
          <div class="linea"></div>
        </div>
        <div class="borderInput"></div>
    </div>
    <h4>{{'Digitar codigo'}}</h4>
    <button   
      mat-button
      [ngClass]="code.length === 5 ? 'btn-send': 'btn-cancelar'"
      [disabled]="code.length !== 5"
      (click)="send()">{{'INGRESAR'}}
    </button>

    <div class="time-expira" *ngIf="flagTimeVencido === false">
      <div class="spinner-width-seconds">
        <mat-spinner 
          class="spinner-div"
          [mode]="'determinate'"
          [value]="tiempo"
          [diameter]="50">
        </mat-spinner>
        <div class="seconds-div">
          <!-- {{seconds}} -->
          {{horas}}
        </div>
      </div>
      <h4 style="margin-top: 50px; margin-left: 50px;">{{'Tiempo de expiración'}}</h4>
    </div>
    <h4 *ngIf="this.flagTimeVencido === true" style="cursor: pointer; margin-top: 20px;" (click)="generateNewCode()"><strong>{{'Generar núevo codigo'}}</strong></h4>

  </div>