import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/services/utils/dialog.service';

export interface DialogData {
  title: string;
  urlComplet: string;
  urlAssets: string;
  messsage: string[];
}

@Component({
  selector: 'app-dialog-confirm-img',
  templateUrl: './dialog-confirm-img.component.html',
  styleUrls: ['./dialog-confirm-img.component.scss']
})
export class DialogConfirmImgComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DialogConfirmImgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,) {}

  ngOnInit(): void {
  }

  onNoClick(save): void {
    this.dialogRef.close(save);
  }

  openImgVideo() {
    this.dialogService.openDialogImageOrVideoOrPdf(this.data.urlAssets).afterClosed().subscribe(x => {});
  }

}
