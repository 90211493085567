<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{data.title || 'Sin title'}}</div>
    </div>
        <div class="content-card list-card" >
            
            <form [formGroup]="form" class="list-card">
                <h3>{{data.description || ''}}</h3>
                <br>
                <app-select-search class="select-component" style="width: 100%;" *ngIf="dataRange"
                    [_dataSource]="dataRange" [textInput]="'Fecha'" [inputType]="'text'" 
                    [dataShow]="'dateList'" [dataValue]="'dateList'" [siteCtrl]="form.get('date')">
                </app-select-search>
                <div class="actions-dialog"style="margin: 3px;">
                    <button mat-button class="btn-cancelar" (click)="dialogRef.close()" >CANCELAR</button>&nbsp;&nbsp;
                    <button mat-button class="btn-generar"
                            (click)="selectedDate()">GUARDAR
                    </button>
                    <!-- <button (click)="_getContractNumber()">Hola</button> -->
                </div>
            </form>
        </div>
   
    
</div>