import { Component, OnInit } from '@angular/core';
import { SymptomReportService } from '../../services/modules/symptomReport.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SnackBarService } from '../../services/utils/snackBar.service';
import { DialogService } from '../../services/utils/dialog.service';
import { PersonalInfoEncuenta, SurveySymptomsValidateAds, PersonalInfo, TownCity } from '../../interfaces/interfaces';
import { ContratingService } from '../../services/modules/contrating.service';
import { CommonService } from '../../services/modules/common.service';

@Component({
  selector: 'app-symptom-report',
  templateUrl: './symptom-report.component.html',
  styleUrls: ['./symptom-report.component.scss']
})
export class SymptomReportComponent implements OnInit {

  formPersonal: FormGroup;
  controlLocalidad = new FormControl();
  personalInfo: PersonalInfoEncuenta;
  form = [];
  localidades : TownCity[] = [];

  surveySymptoms: SurveySymptomsValidateAds;

  temperatura: string;

  constructor(
    private symptomReportService: SymptomReportService,
    private fb: FormBuilder,
    private router: Router,
    private snackBService: SnackBarService,
    private commonService: CommonService,
    private dialogService: DialogService
  ) {
    this.serviceCreateForm();
    if(!this.symptomReportService.personalInfo){
      this.router.navigate(['/auth/AddSessionSymptomReport']);
    } else {
      this.personalInfo = this.symptomReportService.personalInfo;
      this.form[0].name = this.personalInfo.Name + ' ' + this.personalInfo.LastName;
      this.form[0].document = this.personalInfo.DocumentNumber;
      this.formPersonal = this.fb.group({
        AcceptedPrivacy: true,
        OrangeResponse: "[]",
        Localidad:"",
        OtherPersonHasCOVID19: false,
        OtherPersonHasSymptoms: false,
        PersonalCompanyInfoId: this.personalInfo.PersonalCompanyInfoId,
        RedResponse: "[]",
        ResponseState: 0,
        Symptoms: [],
        Transport: [],
        TransportAux: []
      });
      if(this.symptomReportService.personalInfo.ProjectsInfo.length > 1){
        this.dialogService.openDialogSelectPersonalCompanyInfoId(this.symptomReportService.personalInfo.ProjectsInfo).afterClosed().subscribe(personal => {
          this.formPersonal.get('PersonalCompanyInfoId').setValue(personal.PersonalCompanyInfoId);
          this.symptomReportService.personalInfo.PrivacyPolicies = personal.PrivacyPolicies;
          this.openVideoorImageSurveySymptoms(personal.CompanyInfoId);
        });
      } else if(this.symptomReportService.personalInfo.ProjectsInfo.length) {
        this.openVideoorImageSurveySymptoms(this.personalInfo.CompanyInfoId);
      }

    }
   }

  ngOnInit(): void {
    this.getService();
   }

  getService(){
    this.commonService.getTownCity('11001').subscribe((localidades: TownCity[]) => {
      this.localidades = localidades;
    });
  }

  serviceCreateForm() {
    this.form = [
      {
          question: "Datos personales",
          name: "",
          document: "",
          question1: "En el siguiente listado marque las casillas al frente, únicamente si hoy presenta el síntoma correspondiente:",
          options1: [
              {
                id: 1,
                Question: "Fiebre de más de 38 grados",
                Has: false,
                Response: ''
              },
              {
                id: 2,
                Question: "Tos",
                Has: false,
                Response: ''
              },
              {
                id: 3,
                Question: "Congestión nasal",
                Has: false,
                Response: ''
              },
              {
                id: 4,
                Question: "Dolor de garganta",
                Has: false,
                Response: ''

              },
              {
                id: 5,
                Question: "Dificultad para respirar",
                Has: false,
                Response: ''

              },
              {
                id: 6,
                Question: "Fatiga",
                Has: false,
                Response: ''

              },
              {
                id: 7,
                Question: "Escalofrios",
                Has: false,
                Response: ''

              },
              {
                id: 8,
                Question: "Dolor de músculos",
                Has: false,
                Response: ''
              },
              {
                id: 9,
                Question: "Diarrea o problemas digestivos",
                Has: false,
                Response: '',
              },
              {
                id: 10,
                Question: "Ninguna",
                Has: true,
                Response: '',
              },
          ],
          question2: "Frente a cada texto, marque SI o NO, según corresponda:",
          options2: [
              {
                  id: 10,
                  Question: "Ha tenido contacto con alguna persona con los síntomas anteriores.",
                  Has: null,
                  Response: ''
              },
              {
                  id: 11,
                  Question: "Ha tenido contacto con alguna persona sospechosa o diagnosticada de COVID-19.",
                  Has: null,
                  Response: ''
              }
          ],
          question3: "Medio de transporte para desplazarse al trabajo el dia de hoy",
          options3: [
              {
                  label: "Caminando",
                  value: false,
                  id: 0,
              },
              {
                  label: "Bicicleta",
                  value: false,
                  id: 1
              },
              {
                  label: "Patineta",
                  value: false,
                  id: 2
              },
              {
                  label: "Moto",
                  value: false,
                  id: 3
              },
              {
                  label: "Automóvil",
                  value: false,
                  id: 4
              },
              {
                  label: "Transporte masivo (Metro, Transmilenio, Otros)",
                  value: false,
                  id: 5
              },
              {
                  label: "SITP, bus, colectivo",
                  value: false,
                  id: 6
              },
              {
                  label: "Bus intermunicipal",
                  value: false,
                  id: 7
              },

              {
                  label: "Transporte suministrado por la empresa",
                  value: false,
                  id: 8
              },
              {
                  label: "Taxi",
                  value: false,
                  id: 9
              },
              {
                  label: "Estoy trabajando desde la casa",
                  value: false,
                  id: 10
              }
          ]
      }
    ];
  }

  openVideoorImageSurveySymptoms(CompanyInfoId: string){
    this.symptomReportService.SurveySymptoms(CompanyInfoId).subscribe((surveySymptomsData: SurveySymptomsValidateAds) => {
      this.surveySymptoms = surveySymptomsData;
      if(this.surveySymptoms.EnableAdStart === true) {
        //Saber si es una imagen o es un video
        if(this.surveySymptoms.LinkAdStart.slice(-4).toUpperCase() === '.MP4' && this.surveySymptoms.LinkAdStart) {
          this.dialogService.openDialogImageOrVideoOrPdf(undefined, this.surveySymptoms.LinkAdStart).afterClosed().subscribe(rep => {});
        } else {
          this.dialogService.openDialogImageOrVideoOrPdf(this.surveySymptoms.LinkAdStart, undefined).afterClosed().subscribe(rep => {});
        }
      }
    });
  }

  isFalseAll(): boolean {
    let sintomas = this.form[0].options1.find(x => x.Has === true);
    if(sintomas === undefined) {
      return false;
    } else {
      return true;
    }
  }

  submit() {
    if(this.isFalseAll() === false) {
      this.snackBService.openSnackBar('Seleccione algun sintoma, o marque la casilla ninguno', 'X', 30000);
    }else if(this.validateFormCompleted() === false){
      this.snackBService.openSnackBar('Los campos con * son requeridos', 'X', 30000);
    } else {
      this.formPersonal.get('OtherPersonHasCOVID19').setValue(this.form[0].options2[1].Has);
      this.formPersonal.get('OtherPersonHasSymptoms').setValue(this.form[0].options2[0].Has);
      const arrayPersonalNew = [];
      this.form[0].options1.forEach(x => {
        if(x.Has === true && x.id !== 10) {
            arrayPersonalNew.push(x);
        }
      });
      this.form[0].options2.forEach(x => {
        if(x.Has === true) {
            arrayPersonalNew.push(x);
        }
      });
      if(arrayPersonalNew.length > 0) {
        let color = this.colorNotification(arrayPersonalNew);
        if (color === "RED") {
          this.saveFormEnd('RedResponse', arrayPersonalNew);
          this.symptomReportService.saveEncuesta(this.formPersonal.value, this.personalInfo.Token).subscribe(rest => {
            this.dialogService.openDialogOk('QUEDATE EN CASA, INFORMA A TU JEFE INMEDIATO Y LLAMA A TU EPS', 'CERRAR', this.surveySymptoms, this.formPersonal.get('ResponseState').value);
          },error =>  this.router.navigate(['/auth/AddSessionSymptomReport']));
        } else {
          this.saveFormEnd('OrangeResponse', arrayPersonalNew);
          this.symptomReportService.saveEncuesta(this.formPersonal.value, this.personalInfo.Token).subscribe(rest => {
            this.dialogService.openDialogOk('ESPERA EN EL INGRESO DE TU SITIO DE TRABAJO, VAMOS A INVESTIGAR MAS TU CASO', 'CERRAR', this.surveySymptoms, this.formPersonal.get('ResponseState').value);
          },error =>  this.router.navigate(['/auth/AddSessionSymptomReport']));
        }
      } else {
        this.saveFormEnd('', arrayPersonalNew);
        this.symptomReportService.saveEncuesta(this.formPersonal.value, this.personalInfo.Token).subscribe(rest => {
          this.dialogService.openDialogOk('Encuesta completada con éxito', 'CERRAR', this.surveySymptoms, this.formPersonal.get('ResponseState').value);
        },error =>  this.router.navigate(['/auth/AddSessionSymptomReport']));
      }
    }
  }


  changeValueSintomas(id) {
    this.form[0].options1.forEach((value, index) => {
      if(id === 10) {
        if(value.Has === false) {
          this.form[0].options1[index].Has = !this.form[0].options1[index].Has;
          this.form[0].options1.forEach((value, index) => {
            if(value.id !== 10) {
              this.form[0].options1[index].Has = false;
            }
          });
        } else {
          this.form[0].options1[index].Has = !this.form[0].options1[index].Has;
        }
      } else if(id === value.id) {
        this.form[0].options1.forEach((value, index) => {
          if(value.id === 10) {
            this.form[0].options1[index].Has = false;
          }
        });
        this.form[0].options1[index].Has = !this.form[0].options1[index].Has;
      }
    });
    // console.log(this.form[0].options1);
  }


  changeValueQuestion2(id, change) {
    this.form[0].options2.forEach((value, index) => {
      if(id === value.id) {
        if(value.Has === null) {
          this.form[0].options2[index].Has = change;
        } else {
          this.form[0].options2[index].Has = change;
        }
      }
    });
    // console.log(this.form[0].options2);
  }


  changeValuetransporte(id) {
    this.form[0].options3.forEach((value, index) => {
      if(id === 10) {
        if(value.value === false) {
          this.form[0].options3[index].value = !this.form[0].options3[index].value;
          this.form[0].options3.forEach((value, index) => {
            if(value.id !== 10) {
              this.form[0].options3[index].value = false;
            }
          });
        }
      } else if(id === value.id ) {
        this.form[0].options3.forEach((value, index) => {
          if(value.id === 10) {
            this.form[0].options3[index].value = false;
          }
        });
        this.form[0].options3[index].value = !this.form[0].options3[index].value;
      }
    });
    // console.log(this.form[0].options3);
  }

  saveFormEnd(colorResponse: string, arrayPersonalNew) {
    if(colorResponse) {
      this.formPersonal.get(colorResponse).setValue(JSON.stringify(arrayPersonalNew));
    }
    const symptoms = [];
    arrayPersonalNew.forEach(x => {
        symptoms.push(x.Question);
    });
    // Se agrega registro de temperatura en reporte de sintomas
    // if(this.temperatura !== undefined && this.temperatura !== ''){
    //   symptoms.push('Temperatura='+this.temperatura+'')
    // }

    this.formPersonal.get('Symptoms').setValue(symptoms);

    const transport = [];
    this.form[0].options3.forEach(x => {
      if(x.value ===  true) {
        transport.push(x.label);
      }
    });
    // Se agrega registro de Localidad en reporte de transporte
    if(this.controlLocalidad.value !== undefined && this.controlLocalidad.value !== '' && this.personalInfo?.CompanyInfoId === 'aaffc56f-9a1d-4638-aa3b-64c4d7f22a50'){
      this.formPersonal.get('Transport').setValue(transport);
      this.formPersonal.get('Localidad').setValue(this.controlLocalidad.value);
    } else {
      this.formPersonal.get('Transport').setValue(transport);
    }


    this.formPersonal.get('TransportAux').setValue(this.form[0].options3.filter(x => x.value === true));
    if(colorResponse === "RedResponse") {
      this.formPersonal.get('ResponseState').setValue(2);
    } else if(colorResponse === "OrangeResponse") {
      this.formPersonal.get('ResponseState').setValue(1);
    } else {
      this.formPersonal.get('ResponseState').setValue(0);
    }
  }

  // Reglas de color para mostar el modal
  colorNotification(arrayPersonalNew): string {
    let color = "RED"
    var valores = [2 , 3 , 4, 8 , 10];
    arrayPersonalNew.forEach(x => {
      if((valores.indexOf(x.id) > -1) && arrayPersonalNew.length === 1) {
        color = "ORANGE";
      }});
    return color;
  }

  validateFormCompleted(): boolean {
    let completed = true;
    this.form.forEach(options => {
      options.options1.forEach(options1 => {
        if(options1.Has == null) completed = false;
      });
      options.options2.forEach(options2 => {
        if(options2.Has == null) completed = false;
      });
      const x = options.options3.find(options3 => options3.value === true);
      if(!x) completed = false;
    })

    return completed;
  }


  openImagePoliticasPrivacidad(img: string) {
    if(img && img !== null) {
      this.dialogService.openDialogImageOrVideoOrPdf(img);
    } else {
      this.snackBService.openSnackBar('SIN POLITICAS DE PRIVACIDAD', 'X', 3000);
    }
  }

  cancelar() {
    this.router.navigate(['/auth/AddSessionSymptomReport']);
  }

}
