import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import Flickity from 'flickity';
import { CommonMetodService } from 'src/app/core/services/utils/commonMetod.service';

export interface DialogData {
  imgs: any;
}

@Component({
  selector: 'app-dialog-carousel',
  templateUrl: './dialog-carousel.component.html',
  styleUrls: ['./dialog-carousel.component.scss']
})
export class DialogCarouselComponent implements OnInit {
  production:boolean = environment.production;
  s3Bucket:string;
  
  imgs: any= [];

  carousel = null;
  @ViewChild('carouselFlickity', {static: true}) private Carousel;
  carouselElement = null;


  constructor(
    public dialogRef: MatDialogRef<DialogCarouselComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public commonMetod: CommonMetodService,
  ) { 
    if(this.data !== undefined && this.data !== null) {
      this.imgs = this.data.imgs;
      setTimeout(() => {
        this.carouselElement = this.Carousel.nativeElement;
        this.carousel = new Flickity(this.carouselElement, {
          cellAlign: 'center',
          contain: true,
          wrapAround: true,
          adaptiveHeight: true,
          prevNextButtons: true,
        });
      },100);
    }
      
  }

  ngOnInit(): void {
    this.s3Bucket=environment.api.s3Bucket;
    
  }

  getImg(logo) {
    let img = (logo === null ? this.s3Bucket+'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg': this.s3Bucket+logo); 
    return img;
  }

  
  close() {
    this.dialogRef.close();
  }

  create() {
    this.carousel = new Flickity(this.carouselElement, {
      cellAlign: 'center',
      contain: true,
      wrapAround: true,
      adaptiveHeight: true,
      prevNextButtons: true,
    });
  }


 
}
