<mat-form-field  class="input-field-medium" [appearance]="appearance">
  <label><strong>{{textInput || '' | uppercase}}</strong></label>
  <mat-select #select [formControl]="siteCtrl" (selectionChange)="changeSelected($event.value)" [panelClass]="isCard === true || isCardAdove === true ? 'isCardPanel': ''" [disableControl]="disabled" [matTooltip]="getDateToolTip(siteCtrl.value)" [placeholder]="placeholder" [required]="required" class="{{optionSelected() ? 'red' : ''}}">
    <mat-option>
      <ngx-mat-select-search [formControl]="inputFilter" [placeholderLabel]="'Buscar...'"
                              [noEntriesFoundLabel]="'No existe...'">
        <i class="mdi mdi-close menu-icon" ngxMatSelectSearchClear></i>
      </ngx-mat-select-search>
    </mat-option>
    <!-- <mat-option value='-1' selected>{{'--'}}</mat-option> -->
    <div *ngIf="isCardAdove === false && isCard === false && isCardImage === false && isDescription === false">
      <mat-option *ngFor="let data of controlFilter | async" [value]="data" [ngClass]="data[dataShow] === 'SIN VERIFICAR' ? 'red': ''">
        {{ data[dataShow].split(']')[1] !== undefined ? data[dataShow].split(']')[1] : data[dataShow] || '' }} {{data[otherFilter]}}
      </mat-option>
    </div>
    <div *ngIf="isDescription === true">
      <mat-option *ngFor="let data of controlFilter | async" [value]="data[dataValue]" style="height: 46px;">
        <div class="item-description">x
          <div class="title-description">
            <strong>{{data[dataShow] || '' }}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div class="description">{{data[otherFilter] || ''}}</div>
        </div>
      </mat-option>
    </div>
    <div *ngIf="isCard === true">
      <mat-option *ngFor="let data of controlFilter | async" [value]="data[dataValue]" to style="height: 80px;">
        <div class="card-option">
          <img src="{{s3Bucket+getImg(data[card.img])}}" alt="" (error)="commonMetod._getUrlErrorImg(onErrorUrl, $event)">
          <div class="card-content">
            <div class="card-items">
              <h3 class="title">{{data[card.title]}}{{', '}}</h3>
              <div class="field">{{data[card.subTitle]}}</div>
              <div class="field">{{data[card.itemKey]}} {{data[card.itemValue]}}</div>
              <div class="final-items">
                <div class="field">{{data[card.finalLeft]}}</div>
                <div class="final-ringht">{{data[card.finalRight] | date: 'dd-MM-yyyy'}}</div>
              </div>
            </div>
          </div>
        </div>
      </mat-option>
    </div>
    <div *ngIf="isCardImage === true">
      <mat-option *ngFor="let data of controlFilter | async" [value]="data[dataValue]" style="height: 80px;">
        <div class="card-option">
          <img src="{{s3Bucket+getImg(data[card.img])}}" alt="">
        </div>
      </mat-option>
    </div>
    <!-- Nuevo style -->
    <div *ngIf="isCardAdove === true">
      <h4>Contratos para vincular</h4>
      <mat-option [value]="0" style="height: 45px; padding: 0px 5px !important;">
        <div class="cardInformation">
          <div class="relational-info" >
            <div class="desc flex" style="width: 180px; margin-top: 10px;">
              <mat-icon style="margin-right: 2px !important;">location_city</mat-icon>
              <h3>{{'SELECCIONE TODOS LOS PROYECTOS'}}</h3>
            </div><br>
          </div>
          <div class="text-selectedAll">{{'(SELECCIONA UN CONTRATO POR CADA PROYECTO)'}}</div>
        </div>
      </mat-option>
      <mat-option *ngFor="let data of controlFilter | async" [value]="data[dataValue]" style="height: 110px; padding: 0px 5px !important;">
        <div class="cardInformation">
          <div class="relational-info" *ngIf="data[card.textAdove]">
            <div class="desc flex" style="width: 18px;">
              <mat-icon style="margin-right: 2px !important;">location_city</mat-icon>
              <h3>{{data.ProjectStageContracts[0].ProjectName}}</h3>
            </div>
            <div></div>
          </div>
          <div class="cardInformation-content">
            <img src="{{s3Bucket+getImg(data[card.img])}}" alt="" (error)="commonMetod._getUrlErrorImg(onErrorUrl, $event)">
            <div class="content-information">
              <div class="ellipsis"><strong>{{data[card.title]}}</strong></div>
              <div class="ellipsis">{{data[card.subTitle]}}</div>
              <div class="ellipsis baseline">{{data[card.finalLeft]}} <mat-icon>today</mat-icon>{{data[card.finalRight] | date: 'dd-MM-yyyy'}}</div>
            </div>
          </div>
        </div>
      </mat-option>
      <h4>Vinculado a los contratos</h4>
      <mat-option *ngFor="let data of dataSourceSave" [value]="data[dataValue]" style="height: 110px; padding: 0px 5px !important;">
        <div class="cardInformation">
          <div class="relational-info">
            <div class="desc flex" style="width: 18px;">
              <mat-icon style="margin-right: 2px !important;">location_city</mat-icon>
              <h3>{{data.ProjectStageContracts[0].ProjectName}}</h3>
            </div>
            <div></div>
          </div>
          <div class="cardInformation-content">
            <img src="{{s3Bucket+getImg(data[card.img])}}" alt="" (error)="commonMetod._getUrlErrorImg(onErrorUrl, $event)">
            <div class="content-information">
              <div class="ellipsis"><strong>{{data[card.title]}}</strong></div>
              <div class="ellipsis">{{data[card.subTitle] || 'Desconocido'}}</div>
              <div class="ellipsis baseline">{{data[card.finalLeft]}} <mat-icon>today</mat-icon>{{data[card.finalRight] | date: 'dd-MM-yyyy'}}</div>
            </div>
          </div>
        </div>
      </mat-option>
    </div>
  </mat-select>
  <!-- <mat-error>Requerido</mat-error> -->
</mat-form-field>
