<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'HORARIO PERMITIDO' || 'N/A'}}</div>
    </div>
    <div class="content-card list-card" [ngClass]="ngxSlider === true ? 'ngx-sliderChange': 'ngx-NOTsliderChange'">
        <div style="max-width: 500px;">
            <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
        </div>
        <br>
        
        <div class="wrapper">
            <div class="day" 
                *ngFor="let day of WeekDaysView" 
                [ngClass]="exitDayinData(day.value) === true ? 'active': ''" 
                (click)="change(exitDayinData(day.value) === true? day.value : null, day.value)"
                >
                {{day.name}}
            </div>
        </div>
        <br>
        <div style="width: 455px; margin-left: 10px;">
            *El personal vinculado a este contrato podra ingresar y permanecer en el horario y los dias configurados
         </div>
         <div class="actions-dialog">
            <button mat-button class="btn-cancelar" type="button" (click)="close()">CANCELAR</button>
            <button mat-button class="btn-generar" type="button" (click)="save()">GUARDAR</button>
        </div>
        <!-- <br> -->
        
    </div>
    <!-- SUB-SUBTITLE 3 ITEMS -->
                    
    <!-- pre-loading -->
    <!-- <div class="content-card list-card pre-content" *ngIf="!personalContractProyect">
        <div class="form-group-list" *ngFor="let item of production.pre_loading">
            <div class="card">
                <div class="relational-info">
                    <div class="desc flex" style="width: 18px;">
                        <div class="icon-pre pre-loading"></div>
                        <div class="title-pre pre-loading"></div>
                    </div>
                    <div class="icon-pre pre-loading"></div>
                </div>
                <div class="card-content">
                    <div class="pre-img pre-loading"></div>
                    <div class="content-information">
                        <div class="text-pre pre-loading"></div>
                        <div class="text-pre pre-loading"></div>
                        <div class="text-pre pre-loading"></div>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div> -->
    
</div>