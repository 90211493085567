import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Menu } from '../../interfaces/interfaces';
import { User } from '../../models/User';
import { MenuService } from '../../services/menu/menu.service';
import { AuthService } from '../../services/modules/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  menuTogle: boolean = false;
  userAuth: User;
  s3Bucket:string;
  menu: Menu[] = [];

  constructor(
    private authService: AuthService,
    private menuService: MenuService
    ) { 
      this.menu = menuService.menu;
    }


  ngOnInit(): void {
    this.s3Bucket=environment.api.s3Bucket;
    this.getUserAuth();
  }


  

  getUserAuth() {
    this.userAuth = this.authService.getUser();
  }

  logOut() {
    this.authService.cleanSession();
  }


}
