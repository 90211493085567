<div [ngSwitch]="Type">
    <div id="map" *ngSwitchCase = "'show'">
        <div class="data corner-top-left" style="padding: 4px 4px 4px 0;">
            <a class="md-icon-button md-primary" style="margin: 0;" href="http://maps.google.com/?q={{ Address }}" target="_blank">
                <mat-icon>place</mat-icon>
            </a>
            <div class="value">{{ Address || '' }}, {{ City || '' }}</div>
        </div>
    </div>
    <div id="mapEdit" *ngSwitchCase = "'edit'">
        <button mat-button *ngIf="permissionEdit" class="btn-generar corner-top-right" (click)="clickOpc1()">CONFIGURAR</button>
    </div>
    <div id="mapCreate" *ngSwitchCase = "'create'">
        <button mat-button *ngIf="permissionEdit" class="btn-generar corner-top-right" (click)="clickOpc1()">CONFIGURAR</button>
    </div>
</div>

