<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'GENERAR PRENOMINA' || 'N/A'}}</div>
    </div>
    <div class="content-card list-card" >

        <div class="sub-header">
            <div class="sub-content greendAnalityco">{{'LUGAR DE TRABAJO' || 'N/A'}}</div>
        </div>
        <!-- <div class="card" *ngFor="let proyect of proyectSelected">
            <div class="card-option">
                <img src="{{s3Bucket+getImg(proyect.Logo)}}" alt="" (error)="commonMetodService._getUrlErrorImg('project', $event)">
                <div class="card-content">
                    <div class="card-items">
                        <h3 class="title">{{(proyect.Name | uppercase) || ''}}</h3>
                        <div class="field">{{proyect.CompanyName || ''}}</div>
                        <div class="field marginTop">{{proyect.CompanyDocNum || ''}}</div>
                    </div>
                </div>
            </div>
            <mat-icon class="icon-close" (click)="setValue()">close</mat-icon>
        </div> -->
        <app-select-search class="select-component"
                                [appearance]="'outline'"
                                [_dataSource]="projectLaboral" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formPersonal.get('ProjectId')"
                                [inputType]="'text'" (onSelectionChange)="getPeriodFilterProject($event)">
        </app-select-search>
        <div class="sub-header">
            <div class="sub-content greendAnalityco">{{'Periodo abierto sin prenomina' || 'N/A'}}</div>
        </div>
        <!-- <div class="card" *ngFor="let contract of contractsSelected">
            <div class="card-option">
                <img src="{{s3Bucket+getImg(contract.FormImageLogo)}}" alt="">
                <div class="card-content">
                    <div class="card-items">
                        <h3 class="title">{{(contract.ContractReview | uppercase) || ''}}</h3>
                        <div class="field">{{contract.ContractorName}}</div>
                        <div class="field">{{contract.ContractNumber}}</div>
                    </div>
                </div>
            </div>
            <mat-icon class="icon-close" (click)="setValueContract()">close</mat-icon>
        </div> -->
        <app-select-search class="select-component"
                                [appearance]="'outline'"
                                [_dataSource]="periodos" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formPersonal.get('PeriodoId')"
                                [inputType]="'text'">
        </app-select-search>
        <!-- <div class="text-flagExitContractContracts" *ngIf="flagExitContractContracts === false">
            No se encontro un contrato activo en este proyecto. Comuniquese con el operador del proyecto.
        </div> -->
        <div class="actions-dialog"style="margin: 3px;">
            <button mat-button class="btn-cancelar" (click)="close()" >CANCELAR</button>&nbsp;&nbsp;
            <button mat-button 
                    (click)="generate()"
                    [ngClass]="formPersonal.status === 'VALID' ? 'btn-generar':'btn-disabled'"
                    [disabled]="formPersonal.status === 'VALID' ? false: true">GENERAR
            </button>
            <!-- <button (click)="_getContractNumber()">Hola</button> -->
        </div>
    </div>

</div>
