<div class="symptom-report">
    <form  class="form" autocomplete="off">
        <div class="title">
            Reporte Diario de Síntomas
        </div>
        <div class="sub-title">
           de
        </div>
        <div class="step" *ngFor="let step of form">
            <div class="step-1" >
                <!-- <strong>{{1}}. </strong>{{step.question}}  -->
                <div class="row row-subtitle">
                    <div>
                        <strong style="color: #5faec2;">{{step.name | titlecase}}</strong>
                    </div>
                    <!-- <div class="document">
                        ID: <strong >{{step.document | thousandsPipe}}</strong>
                    </div> -->
                </div>
            </div>
            <div class="step-2">
                <div class="sub-title">Seleccione si esta experimentando uno o más de estos sintomas</div>
                <div class="divTable" style="margin-top: 10px;">
                    <div class="divTableBody" *ngFor="let option of step.options1" (click)="changeValueSintomas(option.id)">
                        <!-- <div class="divTableCell s-70">
                            {{option.Question}} <strong style="color: red;">*</strong>
                        </div> -->
                        <div class="row s-30">
                            <div class="divTableCell" [ngClass]="option.Has === true ? 'divTableCellSelected': ''">
                                    <!-- <input matInput type="checkbox" name="option.value" [(ngModel)]="option.value" /> -->
                                    <!-- <mat-checkbox name="option.Has" [(ngModel)]="option.Has" color="primary" required></mat-checkbox>&nbsp;&nbsp; -->
                                    <div class="text-item">
                                        {{option.Question}}
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step-3" >
                <!-- <strong>3. </strong><div class="sub-title "> Frente a cada texto, marque SI o NO, según corresponda:</div> -->
                <div class="divTableOther" style="margin-top: 10px;">
                    <div class="divTableBodyOther" *ngFor="let option of step.options2" >
                        <div class="sub-title">{{option.Question}}?<strong style="color: red;">*</strong></div>
                        <div class="row s-30">
                            <div class="divTableCell" [ngClass]="(option.Has === true && option.Has !== null) ? 'divTableCellSelected': ''" (click)="changeValueQuestion2(option.id, true)">
                                <!-- <input type="radio" [checked]="option.Has === true" name="{{option.id}}_radio" [(ngModel)]="option.Has" [value]="true" required> -->
                                <div class="text-item">
                                    {{'Si'}}
                                </div>
                            </div>
                            <div class="divTableCell" [ngClass]="option.Has === true || option.Has === null ? '': 'divTableCellSelected'" (click)="changeValueQuestion2(option.id, false)">
                                <!-- <input type="radio" [checked]="option.Has === false" name="{{option.id}}_radio" [(ngModel)]="option.Has" [value]="false" required > -->
                                <div class="text-item">
                                    {{'No'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step-4" >
                <div class="sub-title">Medio de transporte para desplazarse al trabajo el dia de hoy <strong style="color: red;">*</strong></div>
                <div class="divTable" style="margin-top: 10px;">
                    <div class="divTableBody" *ngFor="let option of step.options3" (click)="changeValuetransporte(option.id)">
                        <div class="row s-30">
                            <div class="divTableCell" [ngClass]="option.value === true ? (option.id === 10 ? 'divTableCellNingunoTranporte' :'divTableCellSelected'): ''">
                                    <!-- <input matInput type="checkbox" name="option.value" [(ngModel)]="option.value" /> -->
                                    <!-- <mat-checkbox name="option.Has" [(ngModel)]="option.Has" color="primary" required></mat-checkbox>&nbsp;&nbsp; -->
                                    <div class="text-item">
                                        {{option.label}}
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step-3" *ngIf="this.personalInfo?.CompanyInfoId === 'aaffc56f-9a1d-4638-aa3b-64c4d7f22a50'">
                <div class="sub-title">Registro de localidad</div>
                <div class="wrapper">
                    <!-- <input type="number" [(ngModel)]="localidad" name="localidad"
                            [min]="0"/> -->
                    <div class="input-select">
                        <app-select-search class="select-component"
                            [_dataSource]="localidades" [textInput]="'Localidad'" [dataShow]="'Name'" [dataValue]="'Name'" [siteCtrl]="controlLocalidad"
                            [inputType]="'text'">
                        </app-select-search>
                    </div>
                  </div>
            </div>
        </div>
        <!-- <div class="foot">
            Recuerde que es NECESARIO diligencial este cuestionario TODOS LOS DÍAS, como requisito para permitir el ingreso a su lugar de trabajo
        </div> -->
    </form>
    <div class="terms">
        <div class="sub-title">
            Aceptacion de <a (click)="openImagePoliticasPrivacidad(personalInfo.PrivacyPolicies)" >politicas de privacidad</a> de los datos
        </div>
        <div class="row-wrap margin-t-8 center-center">
            <button class="button cancel-button" (click)="cancelar()">CANCELAR</button>
            <button  class="button accept-button" (click)="submit()" >ACEPTAR Y ENVIAR</button>
        </div>
    </div>
</div>
