import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MinutasService} from '../../../services/modules/minutas.service';
import {SnackBarService} from '../../../services/utils/snackBar.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {viewshowChipsActividadEconomica} from '../../../../modules/companies/components/companies-id/companies-id.component';
import {FilterMultipleService} from '../../../services/utils/filter-multiple.service';
import {viewshowChipsSupply} from '../../../../modules/informes/components/report-id/report-id.component';

export interface DialogData {
  supply: any;
}

@Component({
  selector: 'app-dialog-edit-minutes',
  templateUrl: './dialog-edit-minutes.component.html',
  styleUrls: ['./dialog-edit-minutes.component.scss']
})

export class DialogEditMinutesComponent implements OnInit {
  infoSupply: any;
  formVaccine: FormGroup;
  formDatosLoadRecord: FormGroup;

  SupplyObs: any[];
  DataSupply: any[] = [];
  allSupplies: any[] = [];
  selectedSupplies: any[] = [];
  AllCompanyOrSupplier: any[] = [];

  selectedIdSupply: viewshowChipsSupply[] = [];
  viewValuesSupply: viewshowChipsSupply[] = [];

  controlSupply = new FormControl();
  companyOrSupplier = new FormControl();

  @ViewChild('InputControlInsumos') InputControlInsumos: ElementRef<HTMLInputElement>;
  @ViewChild('InputControlSupply') InputControlSupply: ElementRef<HTMLInputElement>;

  constructor(public dialogRef: MatDialogRef<DialogEditMinutesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private fb: FormBuilder,
              private minutasService: MinutasService,
              private filters: FilterMultipleService,
              private snackBService: SnackBarService,
              ) {
    this.infoSupply = this.data.supply;
    this.formDatosLoadRecord = this.fb.group({
      RemittanceOrInvoice: new FormControl(''),
      VaccineObservations: [''],
    });
    this.controlSupply.valueChanges.subscribe(value => {
      value !== '' ?
        this.SupplyObs = this._filterActivityEconom(value) :
        this.SupplyObs = this.DataSupply.slice();
    });

    this.loadData();
  }

  private _filterActivityEconom(value: string): any[] {
    return this.DataSupply.filter(x => {
      return  (this.filters.exitFilter(x.Name , value) || this.filters.exitFilter(x.Code , value));
    });
  }

  loadData() {
    this.selectedIdSupply = [];
    this.viewValuesSupply = [];
    this.formDatosLoadRecord.get('RemittanceOrInvoice').setValue(this.infoSupply.InvoiceNumber);
    this.companyOrSupplier.setValue(this.infoSupply.ProviderId);
    this.selectedSupplies = this.infoSupply.SupplyReportItems;
    if (this.infoSupply !== undefined) {
      this.infoSupply.SupplyReportItems.forEach((supply) => {
        this.selectedIdSupply.push({Code: supply.SupplyId, Name: supply.SupplyName});
        this.viewValuesSupply.push({Code: supply.SupplyId, Name: supply.SupplyName});
      });
    }
    if (this.selectedSupplies === undefined) {
      this.selectedSupplies = [];
    }
    this.formDatosLoadRecord.get('VaccineObservations').setValue(this.infoSupply.Comment);
  }

  ngOnInit(): void {
    this.consumoApiDialogEditMinutes();
  }

  consumoApiDialogEditMinutes() {
    this.minutasService.getCompanyOrSupplier().subscribe((data: any) => {
      this.AllCompanyOrSupplier = data;
    });
    this.minutasService.getSupplies().subscribe((data: any) => {
      this.allSupplies = data;
      this.DataSupply = data;
      this.SupplyObs = data;
    });
  }

  addActivity(event: MatChipInputEvent): void {
    if (event.value) {
      //this.viewValueseconomicActivity.push({Code: event.value.SupplyId, Name: event.value.SupplyName});
    }
    this.controlSupply.setValue(null);
  }

  removeSupply(item: any): void {
    const index = this.viewValuesSupply.indexOf(item);
    for (let i = 0; i < this.selectedIdSupply.length; i++) {
      if (this.selectedIdSupply[i].Code === item.Code) {
        this.selectedIdSupply.splice(index, 1);
      }
    }
    if (index >= 0) {
      this.viewValuesSupply.splice(index, 1);
    }
  }

  selectedSupply(event: MatAutocompleteSelectedEvent): void {
    const exitCaja = this.selectedIdSupply.find(x => x.Code === event.option.value);
    if (this.selectedIdSupply.length === 0 || exitCaja === undefined) {
      this.selectedIdSupply.push({Code: event.option.value, Name: event.option.viewValue});
      this.viewValuesSupply.push({Code: event.option.value, Name: event.option.viewValue});
      this.InputControlSupply.nativeElement.value = '';
      this.InputControlSupply.nativeElement.disabled = true;
      this.controlSupply.setValue('');
      this.InputControlSupply.nativeElement.disabled = false;
    } else {
      this.InputControlSupply.nativeElement.value = '';
      this.InputControlSupply.nativeElement.disabled = true;
      this.controlSupply.setValue('');
      this.InputControlSupply.nativeElement.disabled = false;
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  saveEditMinutes() {
    if (this.selectedSupplies.length > 0) {
      const selectedSuppliesIds = this.selectedIdSupply.map(supply => supply.Code);
      const data = {
        SupplyReportId: this.infoSupply.Id,
        InvoiceNumber: this.formDatosLoadRecord.get('RemittanceOrInvoice').value,
        ProviderId: this.companyOrSupplier.value,
        Comment: this.formDatosLoadRecord.get('VaccineObservations').value,
        SupplyReportItemIds: selectedSuppliesIds
      };

      this.minutasService.putSaveMinutesChanges(data).subscribe((response) => {
        this.snackBService.openSnackBar('Se ha actualizado la minuta correctamente', 'X', 4000);
        this.dialogRef.close(true);
      });
    }else{
      this.snackBService.openSnackBar('Debe seleccionar al menos un insumo', 'X', 4000);
    }
  }

}
