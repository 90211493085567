<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{ data.title || 'TRASLADAR PERSONAL'}}</div>
    </div>

    <div class="content-card list-card" *ngIf="description && data.isRequest === false">
        <b *ngIf="data.title === 'TRANSLADAR VEHICULO'">-El vehiculo esta activo</b>
        <b *ngIf="data.title !== 'TRANSLADAR VEHICULO' || data.title === null">-La persona esta activa</b>
        <b>en el (o los) Proyecto(s)</b>
        <div *ngFor="let item of description.ProjectJson" class="list">
            <div class="text">{{item.Name}}</div>
            <mat-icon class="icon">check_circle</mat-icon>
        </div>
        <br>
        <b>-Vinculado</b>
        <b>en el (o los) Contrato(s)</b>
        <div  class="list">
            <div class="text">{{description.ContractNumber}}</div>
            <mat-icon class="icon">check_circle</mat-icon>
        </div>
        <br>
        <b *ngIf="data.question">{{data.question}}</b>
        <br>
        <div class="actions-dialog"style="margin: 3px;">
            <button mat-button class="btn-cancelar" (click)="close()" >NO</button>
            <button mat-button class="btn-generar" (click)="confirmar()">SI</button>
        </div>
    </div>
    <div class="content-card list-card" *ngIf="data.isRequest === true">
        <b>La persona esta vinculada y activa en otro contrato de este proyecto</b>
        <br>
        <b>Solicite al administrador del proyecto el translado</b>
        <br>
        <div class="actions-dialog"style="margin: 3px;">
            <button mat-button class="btn-cancelar" (click)="close()" >Cancelar</button>
        </div>
    </div>


</div>
