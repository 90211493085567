import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigPaginator } from '../../interfaces/interfaces';



@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input() config: ConfigPaginator;

  @Output() changePaginator = new EventEmitter();

  selectPageWindow = 32;

  constructor() { }

  ngOnInit(): void { }

  clickChange(operator?){
    if(operator === '-' && this.config.AfterPage !== null) {
      this.config.Page = this.config.AfterPage;
      this.configEmit();
    } else if(operator === '--' && this.config.AfterPage !== null) {
      this.config.Page = 1;
      this.configEmit();
    } else if(operator === '+' && this.config.NextPage !== null) {
      this.config.Page = this.config.NextPage;
      this.configEmit();
    } else if(operator === '++' && this.config.NextPage !== null) {
      this.config.Page = this.config.TotalPages;
      this.configEmit();
    } 
  }

  configEmit() {
    this.changePaginator.emit(this.config);
  }

  changePageWindow(value) {
    this.config.PageWindow = parseInt(value);
    this.configEmit();
  }

}
