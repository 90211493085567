import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { DialogService } from 'src/app/core/services/utils/dialog.service';
import { environment } from 'src/environments/environment';

export interface DialogData {
  Id: string;
}

@Component({
  selector: 'app-dialog-supply-group',
  templateUrl: './dialog-supply-group.component.html',
  styleUrls: ['./dialog-supply-group.component.scss']
})
export class DialogSupplyGroupComponent implements OnInit {
  s3Bucket:string;

  form: FormGroup;
  imgCropperResult: string;
  
  constructor(public dialogRef: MatDialogRef<DialogSupplyGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonService: CommonService,
    private dialogService: DialogService,
    private fb: FormBuilder) { 
      this.form = this.fb.group({
        Abbreviation: new FormControl('',Validators.required),
        CompanyInfo: new FormControl(''),
        CompanyInfoId: new FormControl(''),
        IsActive: new FormControl(false,Validators.required),
        Photo: '',
        Name: new FormControl('',Validators.required),
        Order: new FormControl(''),
      });
      if(this.data.Id !== undefined) {
        this.commonService.getSupplyGroupId(this.data.Id).subscribe((rest: any) => {
          this.form.get('Abbreviation').setValue(rest.Abbreviation);
          this.form.get('CompanyInfo').setValue(rest.CompanyInfo);
          this.form.get('CompanyInfoId').setValue(rest.CompanyInfoId);
          this.form.get('IsActive').setValue(rest.IsActive);
          this.form.get('Photo').setValue(rest.Photo);
          this.form.get('Name').setValue(rest.Name);
          this.form.get('Order').setValue(rest.Order);
          this.imgCropperResult = this.getImg(rest.Photo);
        });
      }
    }

  ngOnInit(): void {
    this.s3Bucket=environment.api.s3Bucket;
  }

  getImg(logo) {
    let img = (logo === null || logo === '' ? 'assets/images/sin_imagen.png': logo); 
    return this.s3Bucket+img;
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    if(this.form.status === 'VALID')  {
      if(this.data.Id !== undefined) {
        let model = {
          ...this.form.value,
          Id: this.data.Id
        }
        this.commonService.putSupplyGroupId(this.data.Id, model).subscribe(rest => {
          if(this.formDataPhoto === undefined) {
            this.dialogRef.close(true);
          } else {
            this.commonService.putSupplyGroupUpdateFileId(this.data.Id, this.formDataPhoto).subscribe(rest => {
              this.dialogRef.close(true);
            });
          }
          
        })
      } else {
        this.commonService.postSupplyGroup(this.form.value).subscribe((rest: any) => {
          if(this.formDataPhoto === undefined) {
            this.dialogRef.close(true);
          } else {
            this.commonService.putSupplyGroupUpdateFileId(rest.Id, this.formDataPhoto).subscribe(rest => {
              this.dialogRef.close(true);
            });
          }
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  formDataPhoto: FormData;
  updatePhotoPersonal() {
    this.dialogService.openDialogGestorImg('CARGAR IMAGEN',[],'editPhoto', 0, 'true', true).afterClosed().subscribe(formDataPhoto =>{
      if(formDataPhoto !== undefined) {
        this.imgCropperResult = formDataPhoto.imgResult;
        this.formDataPhoto = formDataPhoto.FormData;
      }
      // rest !== undefined ? this.formDataPhoto = rest : this.formDataPhoto = this.formDataPhoto;
    });
  }

}

