import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FilterList } from '../../interfaces/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent implements OnInit {
  production:boolean = environment.production;

  @Input() filterList: FilterList[];

  @Input() set _selectedItem(value: any) {
    if(value !== undefined && value !== '') {
      this.selectedItem = value;
    }
  }

  selectedItem = 0;

  @Output()
  valueFilter = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onPropagar(Value: string, index, Id) {
    this.selectedItem = index;
    this.valueFilter.emit({Value, Id});
  }

}
