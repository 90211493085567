import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpClient } from '@angular/common/http';

export interface DataShowVincularContracts {
    personalId: number;
    employerId: string;
}

@Injectable({
  providedIn: 'root'
})
export class TerminationService {

    constructor(
        private api: ApiService,
        private _http: HttpClient
    ) { }

    /**
     * Permite traer las stages filtradas
     */
    geTerminationRequestFilter(adminInfoId, page?, pageWindow?, filter?, Search?) {
        return this._http.get<any>(
            this.api.getUrl(`api/PersonalTerminationRequest?adminInfoId=${adminInfoId}&${page !== undefined ? `page=${page}` : `page=1`}${pageWindow !== undefined ? `&pageWindow=${pageWindow}` : `&pageWindow=32`}${filter !== undefined ? `&stage=${filter}` : `&stage=ALL`}${Search !== undefined && Search !== null ? `&search=${Search}` : ``}`));
    }


    /**
     * Permite traer las stages por @Id
     */
    geTerminationRequestId(Id) {
        return this._http.get<any>(
            this.api.getUrl(`api/PersonalTerminationRequest/${Id}`));
    }

    /**
     * Crear una solicitud de retiro *PersonalTerminationRequest
     */
    postPersonalTerminationRequest(model, removeAccess) {
        return this._http.post(this.api.getUrl('api/PersonalTerminationRequest' +
          (removeAccess === true ? '?removeAccess=true' : '')), model);
    }

    /**
     * Crear una solicitud de retiro @PersonalTerminationRequest
     */
    putPersonalTerminationRequestId(personalTerminationRequestId, model) {
        return this._http.put(this.api.getUrl(`api/PersonalTerminationRequest/${personalTerminationRequestId}`), model);
    }

    /**
     * elimina una solicitud de retiro @personalTerminationRequestId
     */
    deletePersonalTerminationRequestId(personalTerminationRequestId) {
        return this._http.delete(this.api.getUrl(`api/PersonalTerminationRequest/${personalTerminationRequestId}`));
    }


    /**
     * Estado de seguridad social por @personalEmployerId
     */
    geSocialSecurityPaidDays(personalEmployerSnapshotId) {
        return this._http.get<any>(this.api.getUrl(`api/SocialSecurity/PaidDays/Snapshot/${personalEmployerSnapshotId} `));
    }

  getPersonalEmployerSnapshot(snapshotId) {
    return this._http.get(this.api.getUrl(`api/PersonalEmployer/Snapshot/${snapshotId}`));
  }

  putPersonalEmployerSnapshot(snapshotId, model?) {
    return this._http.put(this.api.getUrl(`api/PersonalEmployer/Snapshot/${snapshotId}`), model);
  }

  getMassUpload(){
    return this._http.get(this.api.getUrl(`api/MassUpload/Type?termination=true`));
  }

  getTerminationStages(){
    return this._http.get(this.api.getUrl(`api/MassUpload/TerminationStages`));
  }

}

