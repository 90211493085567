<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'OCUPACIONES' || 'N/A'}}</div>
    </div>
    
    <div class="content-card list-card" autocomplete="off">
        <form [formGroup]="form">
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>CÓDIGO</strong></label>
                <input matInput type="text" formControlName="Code">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>NOMBRE</strong></label>
                <input matInput type="text" formControlName="Name">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
        </form>
    </div>

    <div class="btn-actions"style="margin: 3px;">
        <button mat-button class="btn-cancelar" (click)="close()">{{'CANCELAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" (click)="save()">{{data.Id !== undefined ? 'ACTUALIZAR': 'GUARDAR'}}</button>
    </div>
</div>
