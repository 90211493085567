<div class="content-card-Programming list-Programming">
  <div class="sub-header-programming">
    <div class="sub-content-programming greendAnalityco">{{'INFORME' || 'N/A'}}</div>
  </div>
  <div class="fields-group-programming">
    <app-select-search-custom
      class="selected-programming"
      [_dataSource]="ReportToSelect" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="controlSearchReportSelect"
      [inputType]="'text'" (onSelectionChange)="changeReportSelect($event)">
    </app-select-search-custom>
  </div>

  <div class="sub-header-programming">
    <div class="sub-content-programming-1 greendAnalityco">{{'PERFIL DE USUARIO' || 'N/A'}}</div>
  </div>

  <div class="fields-ProfileSelect" *ngIf="showUserProfileSelect">
    <div class="chip-list-wrapper">
      <mat-form-field class="chip-list" style="margin-top: 12px;" appearance="outline">
        <mat-label class="mat-label-perfil">PERFIL DE USUARIO</mat-label>
        <mat-chip-list #chipListProfiles aria-label="Selección de perfiles">
          <mat-chip *ngFor="let profile of selectedProfiles" [selectable]="true" [removable]="true" (removed)="removeProfile(profile)">
            {{ profile.Name }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            #profileInput
            placeholder="+Seleccionar perfil de usuario"
            [formControl]="controlSearchUserProfile"
            [matAutocomplete]="autoProfiles"
            [matChipInputFor]="chipListProfiles">
        </mat-chip-list>

        <mat-autocomplete #autoProfiles="matAutocomplete" (optionSelected)="selectedProfile($event)">
          <mat-option *ngFor="let profile of filteredProfiles" [value]="profile">
            {{ profile.Name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <div class="sub-header-programming">
    <div class="sub-content-programming-1 greendAnalityco">PROGRAMACION</div>
  </div>

  <div class="contenedor-programming" *ngIf="showProgrammingDates">
    <div class="subTitle-programming">
      {{ selectedSchedule === 'WEEKLY' ? 'Enviar todos los Lunes de cada semana' : 'Enviar el reporte en días seleccionados del mes' }}
    </div>

    <ng-container>
      <p class="repetir-texto">
        REPETIR CADA:
        <b>
          {{ getScheduleTranslation('WEEKLY') }}
        </b>
      </p>
      <div class="dias-container">
        <button *ngFor="let day of daysWeek; let i = index"
                class="dia"
                [class.seleccionado]="day.seleccionado"
                (click)="selectDay(i)">
          {{ day.nombre }}
        </button>
      </div>
      <p class="nota">* Seleccionar un día</p>
    </ng-container>

    <ng-container >
      <p class="repetir-texto">
        REPETIR CADA:
        <b>
          {{ getScheduleTranslation('MONTHLY') }}
        </b>
      </p>
      <div class="calendario">
        <button *ngFor="let day of daysOfTheMonth; let i = index"
                class="dia-mes"
                [class.seleccionado]="day.seleccionado"
                (click)="selectDayOfTheMonth(i)">
          {{ day.numero }}
        </button>
      </div>
      <p class="nota">* Se puede seleccionar maximo 4 días</p>
    </ng-container>
  </div>

  <div class="actions-dialog-programming" style="margin: 3px;">
    <button mat-button class="btn-cancelar-programming" (click)="cancelProgramming()" type="button">CANCELAR</button>&nbsp;&nbsp;
    <button mat-button type="submit" class="btn-generar-programming"
            [disabled]="!isSaveEnabled"
            (click)="saveProgramming()"
            [ngClass]="{'disabled-btn': !isSaveEnabled, 'enabled-btn': isSaveEnabled}">
      GUARDAR
    </button>
  </div>
</div>
