import { Component, OnInit, Input } from '@angular/core';
import { ConfigCard, GetPersonalBlackList, PersonalBlackList, SecurityReference } from 'src/app/core/interfaces/interfaces';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PersonalModel } from '../../../models/Personal/personalModel';
import { PersonalEmployerInfo } from '../../../models/Personal/personalEmployerInfo';
import { CommonOptions, City, DaneCity, SecurityReferences, Back } from '../../../interfaces/interfaces';
import { NumberCountry } from '../../../constants/number-city.constant';
import { CommonService } from '../../../services/modules/common.service';
import { DialogService } from '../../../services/utils/dialog.service';
import { ContratingService } from '../../../services/modules/contrating.service';
import { IndividualContractId, IndividualContractPut } from '../../../models/IndividualContract/individualContractId';
import { FechasService } from '../../../services/utils/fechas.service';
import { SnackBarService } from '../../../services/utils/snackBar.service';
import { DocumentI, DocumentIndividualPost, getDocumentIndividualContract } from '../../../models/IndividualContract/document';
import { Positions } from '../../../models/IndividualContract/interfacesIndividualContract';
import { TypeSecurityReference } from 'src/app/core/constants/type-security-references';
import { CurrencyPipe } from '@angular/common';
import { PersonalService } from 'src/app/core/services/modules/personal.service';
import { CommonMetodService } from 'src/app/core/services/utils/commonMetod.service';
import { EmployerService } from 'src/app/core/services/modules/employer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'cards-contracting',
  templateUrl: './cards-contracting.component.html',
  styleUrls: ['./cards-contracting.component.scss']
})
export class CardsContractingComponent implements OnInit {
  production = environment;
  s3Bucket:string;

  individualContract: IndividualContractId;
  individualContractPut: IndividualContractPut;

  configCard: ConfigCard;

  formPersonal: FormGroup;
  formDatosContrato: FormGroup;
  formOtrosDatos: FormGroup;
  formCetificados: FormGroup;
  formContractNumber: FormGroup;
  formExamType: FormGroup;
  personal: PersonalModel;
  personalEmployerInfo: PersonalEmployerInfo;

  nationality: CommonOptions[] = [];
  countrys: CommonOptions[] = [];
  rh: CommonOptions[] = [];
  companyDocumentType :CommonOptions[] = [];
  individualContractsType :CommonOptions[] = [];
  payPeriodType: CommonOptions[] = [];
  states: CommonOptions[] = [];
  sex :CommonOptions[] = [];
  examType: CommonOptions[] = [];
  contributorType: any[] = [];
  occupationalRisk: CommonOptions[] = [];
  positions: Positions[] = [];
  proyectSelect: any[] = [];
  state: string = '';

  cities: City[] = [];
  citiesNacimiento: City[] = [];
  citiesRecidence: City[] = [];
  citiesDefault: City[] = [];
  daneCity: DaneCity[] = [];

  ips: SecurityReferences[] = [];
  CajadeCompensacion: any[] = [];
  bancks: Back[]= [];
  bankAccountType:CommonOptions[] = [];
  medicalExamType: CommonOptions[] = [];
  town: any[] = [];

  employer: any;

  formDataPhoto: FormData;

  indexSelect: number = 0;
  IDindividualContract: string;

  documentsAndRquisitosParameters: DocumentI[] = [];
  documentsExamFormParameters: DocumentI[] = [];
  documentsGenerateParameters: DocumentI[] = [];

  securityReferences: any = [];
  afp: SecurityReference[] = [];
  eps: SecurityReference[] = [];

  permissions: string[] = [];

  @Input() set _configCard(value: ConfigCard) {
    this.configCard = value;
    if(value.typeCard === 'contract_proyects') {
      // this.getContractPersonal();
    }
  }


  @Input() set _individualContractId(value: string) {
    if(value) {
      this.IDindividualContract = value;
      this.getContracting(value);
    }
  }

  @Input() set _permissions(value: string[]) {
    if(value.length > 0) {
      this.permissions = value;
      // console.log('permissionsVAlue',value);
    }
  }


  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private commonMetodService: CommonMetodService,
    private dialogService: DialogService,
    private contratingService: ContratingService,
    private fechasService: FechasService,
    private snackBService: SnackBarService,
    private currencyPipe: CurrencyPipe,
    private personaService: PersonalService,
    private employerService: EmployerService,
    private router: Router
  ) {
    this.s3Bucket=environment.api.s3Bucket;
    
    this.formPersonal = this.fb.group({
      Nationality: new FormControl('',Validators.required),
      DocumentNumber: new FormControl(''),
      DocumentType: new FormControl(''),
      Name: new FormControl('',Validators.required),
      LastName: new FormControl('',Validators.required),
      BirthDate: new FormControl('',Validators.required),
      Sex: new FormControl('',Validators.required),
      RH: new FormControl('',Validators.required),
      contryNalcionality: new FormControl('',Validators.required),
      CityCode: new FormControl('',Validators.required),
      CityOfBirthCode: new FormControl('',Validators.required),
      EmergencyContact: new FormControl(''),
      EmergencyPhone: new FormControl(''),
      Address: new FormControl('',Validators.required),
      PhoneNumber: new FormControl('',Validators.required),
      TownCode: new FormControl('',Validators.required),
      Email: new FormControl('',[Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      Photo: '',
      EpsId: '',
      AfpId: ''
    });
    this.formCetificados = this.fb.group({
      EpsId: new FormControl('',Validators.required),
      AfpId: new FormControl('',Validators.required),
    });
    this.formContractNumber = this.fb.group({
      ContractNumber: ''
    })
    this.formDatosContrato = this.fb.group({
      IndividualContractTypeId: new FormControl('',Validators.required),
      PersonalEmployerInfoId: new FormControl('',Validators.required),
      PositionId: new FormControl('',Validators.required),
      Salary: ['', Validators.min(1)],
      PayPeriodId: new FormControl('',Validators.required),
      StartDate: new FormControl('',Validators.required),
      // EndDate: new FormControl('',Validators.required),
      contry: new FormControl('0',Validators.required),
      ProjectId: new FormControl('',Validators.required),
      ContractCityId: new FormControl('',Validators.required),
      ContractId: '',
      examType: ''
    });

    this.formExamType = this.fb.group({
      examType: new FormControl('',Validators.required)
    });

    this.formDatosContrato.valueChanges.subscribe(form => {
      if(form.Salary && this.commonMetodService._validarDateMoney(form.Salary)
        ) {
        this.formDatosContrato.patchValue({
          Salary: this.currencyPipe.transform(form.Salary.toString().replace(/\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '1.0-0').replace(/,/gi, '.')
        }, {emitEvent: false});
      }
    });

    this.formOtrosDatos = this.fb.group({
      BankAccount: new FormControl('',Validators.required,),
      CcfId: new FormControl('',Validators.required),
      BankId: new FormControl('',Validators.required),
      BankAccountTypeId: new FormControl('',Validators.required),
      ContributorTypeId: new FormControl('',Validators.required),
      TypeARLId: new FormControl('',Validators.required)
    });
    // d8d1468e-b63b-4acf-adbf-145c50aba5ba
    // 900651904

  }

  ngOnInit(): void {
    this.s3Bucket=environment.api.s3Bucket;
  }

  getContracting(individualContractId) {
    this.contratingService.getIndividualContractId(individualContractId).subscribe((IndividualContract:IndividualContractId) => {
      // console.log('IndividualContract',IndividualContract);
      this.individualContract = IndividualContract;
      this.contratingService.getPersonalId(this.individualContract.personalemployerinfo.PersonalId).subscribe((person:PersonalModel) => {
        this.personal = person;
        this.dataFormPerson(this.individualContract, this.personal);
        this.dataFormIndividualContract(this.individualContract);
        this.state = this.individualContract.IndividualContractStage.Description;
        // console.log('this.state',this.state);
        this.docsSave = [];
        this.docsUploadIniado = [];
        this.docsUploadIniadoExam = [];
        this.getRolStateContrating(this.state);
      });
    });
  }

  getRolStateContrating(state: string){
    switch (state) {
      case 'INICIADO':
        this.iniciado();
        break;
      case 'SOLICITADO':
        this.solicitado();
        break;
      case 'APROBADO':
        this.aprobado();
        break;
      case 'RECHAZADO':
        this.rechazado();
        break;
      case 'CONTRATADO':
        this.contratado();
        break;
      default:
        break;
    }
  }

  docsSave: getDocumentIndividualContract[] = [];

  docsUploadIniado: DocumentIndividualPost[] = [];
  docsUploadIniadoExam: DocumentIndividualPost[] = [];
  iniciado() {
    this.contratingService.getDocuemntsIndividualContract(this.IDindividualContract).subscribe((documentsIndividualContract: getDocumentIndividualContract[]) => {
    this.documentsAndRquisitosParameters.forEach((documentParameter: DocumentI) => {
      // console.log(documentParameter, 'documentParameter');
      this.docsSave = documentsIndividualContract;
        let doc = documentsIndividualContract.find(documentoId => documentoId.IdDocument === documentParameter.Id);
        if(doc !== undefined) {
          this.docsUploadIniado.push({IdDocument: documentParameter.Id, IndividualContractId: this.IDindividualContract, urlDoc: doc.File, Id: doc.Id, IsByDate: doc.Document.IsByDate,
                                      RequiredDate: doc.RequiredDate === '0001-01-01T00:00:00' ? '': doc.RequiredDate}); 
        }
      });
    });
  }

  rechazado() {
    this.contratingService.getDocuemntsIndividualContract(this.IDindividualContract).subscribe((documentsIndividualContract: getDocumentIndividualContract[]) => {
      this.documentsAndRquisitosParameters.forEach((documentParameter: DocumentI) => {
        this.docsSave = documentsIndividualContract;
        let doc = documentsIndividualContract.find(documentoId => documentoId.IdDocument === documentParameter.Id);
        if(doc !== undefined) {
          this.docsUploadIniado.push({IdDocument: documentParameter.Id, IndividualContractId: this.IDindividualContract, urlDoc: doc.File, Id: doc.Id, IsByDate: doc.Document.IsByDate, 
            RequiredDate: doc.RequiredDate === '0001-01-01T00:00:00' ? '': doc.RequiredDate}); 
        }
      });
    });
  }

  getDocumentStatesAll(){
    this.contratingService.getDocuemntsIndividualContract(this.IDindividualContract).subscribe((documentsIndividualContract: getDocumentIndividualContract[]) => {
      this.documentsAndRquisitosParameters.forEach((documentParameter: DocumentI) => {
        this.docsSave = documentsIndividualContract;
        let doc = documentsIndividualContract.find(documentoId => documentoId.IdDocument === documentParameter.Id);
        if(doc !== undefined) {
          this.docsUploadIniado.push({IdDocument: documentParameter.Id, IndividualContractId: this.IDindividualContract, urlDoc: doc.File, Id: doc.Id,IsByDate: doc.Document.IsByDate,
            RequiredDate: doc.RequiredDate === '0001-01-01T00:00:00' ? '': doc.RequiredDate}); 
        }
      })
    });
  }

  solicitado(){
    this.contratingService.getDocuemntsIndividualContract(this.IDindividualContract).subscribe((documentsIndividualContract: getDocumentIndividualContract[]) => {
      this.documentsAndRquisitosParameters.forEach((documentParameter: DocumentI) => {
        this.docsSave = documentsIndividualContract;
        let doc = documentsIndividualContract.find(documentoId => documentoId.IdDocument === documentParameter.Id);
        if(doc !== undefined) {
          this.docsUploadIniado.push({IdDocument: documentParameter.Id, IndividualContractId: this.IDindividualContract, urlDoc: doc.File, Id: doc.Id, IsByDate: doc.Document.IsByDate, 
            RequiredDate: doc.RequiredDate === '0001-01-01T00:00:00' ? '': doc.RequiredDate}); 
        }
      })
    });
  }

  aprobado(){
    this.contratingService.getDocuemntsIndividualContract(this.IDindividualContract).subscribe((documentsIndividualContract: getDocumentIndividualContract[]) => {
      this.documentsAndRquisitosParameters.forEach((documentParameter: DocumentI) => {
        this.docsSave = documentsIndividualContract;
        let doc = documentsIndividualContract.find(documentoId => documentoId.IdDocument === documentParameter.Id);
        if(doc !== undefined) {
          this.docsUploadIniado.push({IdDocument: documentParameter.Id, IndividualContractId: this.IDindividualContract, urlDoc: doc.File, Id: doc.Id, IsByDate: doc.Document.IsByDate, RequiredDate: 
            doc.RequiredDate === '0001-01-01T00:00:00' ? '': doc.RequiredDate}); 
        }
      })
    });
    this.contratingService.getDocuemntsIndividualContract(this.IDindividualContract).subscribe((documentsIndividualContract: getDocumentIndividualContract[]) => {
      this.documentsExamFormParameters.forEach((documentParameter: DocumentI) => {
        let doc = documentsIndividualContract.find(documentoId => documentoId.IdDocument === documentParameter.Id);
        if(doc !== undefined) {
          this.docsUploadIniadoExam.push({IdDocument: documentParameter.Id, IndividualContractId: this.IDindividualContract, urlDoc: doc.File, Id: doc.Id, IsByDate: doc.Document.IsByDate, DocumentContents: doc.Document.DocumentContents,
            RequiredDate: doc.RequiredDate === '0001-01-01T00:00:00' ? '': doc.RequiredDate}); 
        }
        let docExamenMedico = documentsIndividualContract.find(docIndi => docIndi.Document.DocumentContents === 'SOLICITUD_DE_EXAMENES_MEDICOS');
        if(docExamenMedico !== undefined) {
          this.formExamType.get('examType').setValue(docExamenMedico.MedicalExamTypeId !== null && docExamenMedico.MedicalExamTypeId !== 0 ? docExamenMedico.MedicalExamTypeId = docExamenMedico.MedicalExamTypeId : '');
        }
      });
    });
    // this.commonService.getTypeSecurityReferences(3).subscribe(rest =>{
    //   this.CajadeCompensacion = rest;
    // });
    this.employerService.getAddCCFemployerId(this.individualContract.personalemployerinfo.Employer.Id).subscribe((CCFemployer: any[]) => {
      this.CajadeCompensacion = CCFemployer;
    });
    this.commonService.getTypeSecurityReferences(4).subscribe(rest =>{
      this.ips = rest;
    });
    this.contratingService.getBanks().subscribe((rest: any) => {
      this.bancks = rest;
    });
    this.commonService.getCommonOptions('MedicalExamType').subscribe(rest =>{
      this.medicalExamType = rest;
    })
    this.commonService.getCommonOptions('BankAccountType').subscribe(rest =>{
      this.bankAccountType = rest;
    })
    this.commonService.getCommonOptions('OccupationalRisk').subscribe(rest =>{
      this.occupationalRisk = rest;
    });
    this.contratingService.getContributorType().subscribe((rest:any) =>{
      this.contributorType = rest;
    });
  }

  contratado(){
    this.contratingService.getDocuemntsIndividualContract(this.IDindividualContract).subscribe((documentsIndividualContract: getDocumentIndividualContract[]) => {
      this.documentsAndRquisitosParameters.forEach((documentParameter: DocumentI) => {
        this.docsSave = documentsIndividualContract;
        let doc = documentsIndividualContract.find(documentoId => documentoId.IdDocument === documentParameter.Id);
        if(doc !== undefined) {
          this.docsUploadIniado.push({IdDocument: documentParameter.Id, IndividualContractId: this.IDindividualContract, urlDoc: doc.File, Id: doc.Id,IsByDate: doc.Document.IsByDate, 
                                      RequiredDate: doc.RequiredDate === '0001-01-01T00:00:00' ? '': doc.RequiredDate}); 
        }
      })
    });
    this.contratingService.getDocuemntsIndividualContract(this.IDindividualContract).subscribe((documentsIndividualContract: getDocumentIndividualContract[]) => {
      this.documentsExamFormParameters.forEach((documentParameter: DocumentI) => {
        let doc = documentsIndividualContract.find(documentoId => documentoId.IdDocument === documentParameter.Id);
        if(doc !== undefined) {
          // console.log('docExamen', doc);
          // console.log('documentParameter', documentParameter);
          // console.log('documentsIndividualContract',documentsIndividualContract);
          this.docsUploadIniadoExam.push({IdDocument: documentParameter.Id, IndividualContractId: this.IDindividualContract, urlDoc: doc.File, Id: doc.Id,IsByDate: doc.Document.IsByDate, DocumentContents: doc.Document.DocumentContents,
            RequiredDate: doc.RequiredDate === '0001-01-01T00:00:00' ? '': doc.RequiredDate}); 
        }
        let docExamenMedico = documentsIndividualContract.find(docIndi => docIndi.Document.DocumentContents === 'SOLICITUD_DE_EXAMENES_MEDICOS');
        if(docExamenMedico !== undefined) {
          this.formExamType.get('examType').setValue(docExamenMedico.MedicalExamTypeId !== null && docExamenMedico.MedicalExamTypeId !== 0 ? docExamenMedico.MedicalExamTypeId = docExamenMedico.MedicalExamTypeId : '');
        }
      })
    });
    // this.commonService.getTypeSecurityReferences(3).subscribe(rest =>{
    //   this.CajadeCompensacion = rest;
    // });
    this.employerService.getAddCCFemployerId(this.individualContract.personalemployerinfo.Employer.Id).subscribe((CCFemployer: any[]) => {
      this.CajadeCompensacion = CCFemployer;
    });
    this.commonService.getTypeSecurityReferences(4).subscribe(rest =>{
      this.ips = rest;
    });
    this.contratingService.getBanks().subscribe((rest: any) => {
      this.bancks = rest;
    });
    this.commonService.getCommonOptions('MedicalExamType').subscribe(rest =>{
      this.medicalExamType = rest;
    })
    this.commonService.getCommonOptions('BankAccountType').subscribe(rest =>{
      this.bankAccountType = rest;
    })
    this.commonService.getCommonOptions('OccupationalRisk').subscribe(rest =>{
      this.occupationalRisk = rest;
    });
    this.contratingService.getContributorType().subscribe((rest:any) =>{
      this.contributorType = rest;
    });

  }


  dataFormIndividualContract(individualContract: IndividualContractId) {
    this.formDatosContrato.get('IndividualContractTypeId').setValue(
      individualContract.IndividualContractTypeId === 0? 77: individualContract.IndividualContractTypeId);
    this.formDatosContrato.get('PersonalEmployerInfoId').setValue(individualContract.PersonalEmployerInfoId);
    this.formDatosContrato.get('PositionId').setValue(individualContract.PositionId === 0? '': individualContract.PositionId);
    this.formDatosContrato.get('Salary').setValue(individualContract.Salary === 0? 0: individualContract.Salary);
    this.formDatosContrato.get('StartDate').setValue(
      individualContract.StartDate === '0001-01-01T00:00:00' ? '' : individualContract.StartDate);
    // this.formDatosContrato.get('EndDate').setValue(individualContract.EndDate === '0001-01-01T00:00:00' ? '' : individualContract.EndDate);
    this.formDatosContrato.get('ProjectId').setValue(
      individualContract.ProjectId === '00000000-0000-0000-0000-000000000000' ? '' : individualContract.ProjectId);
    this.formDatosContrato.get('PayPeriodId').setValue(individualContract.PayPeriodId === 0 ? 69 : individualContract.PayPeriodId);
    this.formDatosContrato.get('ContractId').setValue(individualContract.ContractId);
    this.formDatosContrato.get('ContractCityId').setValue(individualContract.ContractCityId === 0 ? '' : individualContract.ContractCityId);
    this.formContractNumber.get('ContractNumber').setValue(individualContract.ContractNumber);
  }

  dataFormPerson(individualContract: IndividualContractId, person: PersonalModel) {
    // Person
    this.formPersonal.get('Name').setValue(person.Name);
    this.formPersonal.get('LastName').setValue(person.LastName);
    this.formPersonal.get('Nationality').setValue( person.Nationality.toString());
    this.formPersonal.get('DocumentType').setValue(person.DocumentType);
    this.formPersonal.get('DocumentNumber').setValue(person.DocumentNumber);
    person.DocumentRaw === null || person.DocumentRaw === 'null' ? this.isCreateAppPersonal = false: this.isCreateAppPersonal = true;
    // this.getCitiesNacimiento(1);
    this.validateListF(person.DocumentType, person.DocumentNumber, person.CompanyInfoId === undefined ? '': person.CompanyInfoId);
    this.formPersonal.get('CityOfBirthCode').setValue(person.CityOfBirthCode !== null ? person.CityOfBirthCode : '');
    person.CityOfBirthCode !== null ? this.getCitiesNacimiento(person.CityOfBirthCode.substring(0, 1) ) : '';
    this.formPersonal.get('contryNalcionality').setValue(person.CityOfBirthCode !== null ? person.CityOfBirthCode.substring(0, 1) : '');
    this.formPersonal.get('RH').setValue(person.RH);
    this.formPersonal.get('Sex').setValue(person.Sex);
    this.formPersonal.get('BirthDate').setValue(this.fechasService.formatBirthDate(person.BirthDate));
    // console.log('this.fe :>> ', this.fechasService.formatBirthDate(person.BirthDate));
    // PersonalEmployerInfo
    this.formPersonal.get('CityCode').setValue(individualContract.personalemployerinfo.CityCode);
    this.getTown(individualContract.personalemployerinfo.CityCode);
    this.formPersonal.get('Address').setValue(individualContract.personalemployerinfo.Address);
    this.formPersonal.get('PhoneNumber').setValue(individualContract.personalemployerinfo.PhoneNumber);
    this.formPersonal.get('EmergencyContact').setValue(individualContract.personalemployerinfo.EmergencyContact);
    this.formPersonal.get('EmergencyPhone').setValue(individualContract.personalemployerinfo.EmergencyPhone);
    this.formPersonal.get('Photo').setValue(individualContract.personalemployerinfo.Photo);
    this.formPersonal.get('Email').setValue(individualContract.personalemployerinfo.Email);
    this.formPersonal.get('TownCode').setValue(parseInt(individualContract.personalemployerinfo.TownCode));
    this.formCetificados.get('EpsId').setValue(individualContract.personalemployerinfo.EpsId);
    this.formCetificados.get('AfpId').setValue(individualContract.personalemployerinfo.AfpId);
    //Fomr OTROS DATOS PERSONAL EM^PLOYER INFO
    this.formOtrosDatos.get('BankId').setValue(individualContract.personalemployerinfo.BankId === undefined || individualContract.personalemployerinfo.BankId === 0?
                                                '': individualContract.personalemployerinfo.BankId);
    this.formOtrosDatos.get('BankAccount').setValue(individualContract.personalemployerinfo.BankAccount === null ? '': individualContract.personalemployerinfo.BankAccount);
    this.formOtrosDatos.get('BankAccountTypeId').setValue(individualContract.personalemployerinfo.BankAccountTypeId === undefined || individualContract.personalemployerinfo.BankAccountTypeId === 0?
                                                '': individualContract.personalemployerinfo.BankAccountTypeId);
    this.formOtrosDatos.get('CcfId').setValue(individualContract.personalemployerinfo.CcfId === undefined || individualContract.personalemployerinfo.CcfId === 0?
                                                '': individualContract.personalemployerinfo.CcfId);
    this.formOtrosDatos.get('ContributorTypeId').setValue(individualContract.personalemployerinfo.ContributorTypeId === undefined || individualContract.personalemployerinfo.ContributorTypeId === 0?
                                                  '': individualContract.personalemployerinfo.ContributorTypeId);
    this.formOtrosDatos.get('TypeARLId').setValue(individualContract.personalemployerinfo.TypeARLId === undefined || individualContract.personalemployerinfo.TypeARLId === 0?
                                                    '': individualContract.personalemployerinfo.TypeARLId);
    this.getSelectedContracting(individualContract.personalemployerinfo.Employer.Id)
    this.servicesPersonal();
  }

  flagListF: boolean = true;
  validateListF(documentType, documentNumber,CompanyInfoId) {
    this.contratingService.getPersonalBlackList(documentType, documentNumber, CompanyInfoId).subscribe(rest => {
      rest === true? this.flagListF = false: this.flagListF = true;
    });
  }

  postPersonalBlackList() {
    this.dialogService.openDialogConfirm('Esta seguro de agregarlo a la lista').afterClosed().subscribe(rest =>{
      if(rest !== false) {
        let personalBlack: PersonalBlackList = 
        { DocumentType: this.formPersonal.get('DocumentType').value,
          DocumentNumber: this.formPersonal.get('DocumentNumber').value,
          StartDate: new Date(),
        }
        this.contratingService.postPersonalBlackList(personalBlack).subscribe(rest => {
          // console.log(rest);
          this.flagListF = false;
        });
      }
    })
  }

  deletePersonalBlackList() {
    this.contratingService.getAllPersonalBlackList().subscribe((personalBlack: GetPersonalBlackList[]) => {
      let person = personalBlack.find(person => person.DocumentNumber === this.personal.DocumentNumber);
      if(person !== undefined) {
        this.dialogService.openDialogConfirm('Esta seguro de quitarlo de la lista?').afterClosed().subscribe(rest => {
          if(rest !== false) {
            this.contratingService.deletePersonalBlackList(person.Id).subscribe(rest => {
              this.snackBService.openSnackBar('Se a quitado de la lista.', 'X', 4000);
              this.flagListF = true;
            });
          }
        });
      }
    });
  }

  servicesPersonal() {
    this.commonService.getCommonOptions("Nationality").subscribe((data: CommonOptions[]) => {
      this.nationality = data;
    });
    this.commonService.getCommonOptions("Country").subscribe((data: CommonOptions[]) => {
      this.countrys = data;
      // console.log('this.countrys',this.countrys);
    });
    this.commonService.getCommonOptions("RH").subscribe((data: CommonOptions[]) => {
      this.rh = data.sort((a,b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.commonService.getCommonOptions("CompanyDocumentType").subscribe((data: CommonOptions[]) => {
      this.companyDocumentType = data.filter(documents => documents.Value === 'CC' || documents.Value === 'CE' || documents.Value === 'PE')
          .sort((a,b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.commonService.getCommonOptions("Sex").subscribe((data: CommonOptions[]) => {
      this.sex = data.sort((a,b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.commonService.getCommonOptions("MedicalExamType").subscribe((data: CommonOptions[]) => {
      this.examType = data.sort((a,b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.commonService.getCommonOptions('IndividualContractsStageType').subscribe(rest => {
      this.states = rest;
    });
    this.commonService.getCommonOptions('IndividualContractsType').subscribe(rest => {
      this.individualContractsType = rest;
    });
    this.commonService.getCommonOptions('PayPeriodType').subscribe(rest => {
      this.payPeriodType = rest;
    });
    this.employerService.getPositionEmployerId(this.individualContract.personalemployerinfo.Employer.Id).subscribe((positions: Positions[]) => {
      this.positions = positions;
    });
    
    // this.contratingService.getProyectSelect().subscribe((proyectSelect: ProyectSelect[]) => {
    //   this.proyectSelect = proyectSelect;
    // });
    this.getdocumentsForNationalyty(this.formPersonal.get('Nationality').value);
    this.commonService.getSecurityReferences().subscribe(data => {
      this.securityReferences = data;
      this.eps = this.securityReferences.filter(x => x.Type === TypeSecurityReference.EPS).sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
      this.afp = this.securityReferences.filter(x => x.Type === TypeSecurityReference.AFP).sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
  });
    this.getDaneCity();
    
  }

  getdocumentsForNationalyty(nationalidad) {
    this.contratingService.getDocument().subscribe((documents: DocumentI[]) => {
      // console.log(documents);
      if(nationalidad === '0') {
        this.documentsAndRquisitosParameters = documents.filter(doc => doc.IsUpload === true && doc.DocumentContents === 'DocumentsForm' 
                                                        && doc.Abrv !== 'PPE' && doc.Abrv !== 'PEP' )
                                                        .sort((a,b) => parseInt(a.OrderNum) > parseInt(b.OrderNum) ? 1 :-1);
      } else {
        this.documentsAndRquisitosParameters = documents.filter(doc => doc.IsUpload === true && doc.DocumentContents === 'DocumentsForm')
                                                        .sort((a,b) => parseInt(a.OrderNum) > parseInt(b.OrderNum) ? 1 :-1);
      }
      
      this.documentsExamFormParameters = documents.filter(doc => doc.IsUpload === true && doc.DocumentContents === 'ExamForm');
      this.documentsGenerateParameters = documents.filter(doc => doc.IsUpload === false);
    });
  }

  selectionChangeGetdocumentsForNationalyty(event) {
    this.getdocumentsForNationalyty(event);
  }

  selectedDaneCityTown(e: any) {
    this.getTown(e);
  }

  getStateId(textState: string): number {
    let stateIniciat: number = 0;
    this.states.forEach(state => {
      state.Description === textState ? stateIniciat = state.Id: stateIniciat =  stateIniciat;
    });
    return stateIniciat;
  }

  getSelectedContracting(CompanyId) {
    this.contratingService.getListSelectContracting(CompanyId).subscribe((rest: any) => {
      // console.log('rest',rest);
      this.proyectSelect = [];
      this.proyectSelect = rest;
      // console.log('contracts',this.proyectSelect);
    });
    // contractsSelected
  }

  getDaneCity() {
    this.commonService.getDaneCity().subscribe((daneCity: DaneCity[]) => {
      this.daneCity = daneCity;
    });
  }

  getCitiesNacimiento(numberContry: NumberCountry){
    this.commonService.getCities(numberContry).subscribe((data: City[]) => {
      this.citiesNacimiento = data;
    });
  }


  getCitiesDefault(numberContry: NumberCountry) {
    this.commonService.getCities(numberContry).subscribe((data: City[]) => {
      this.citiesDefault = data;
    });
  }

  getTown(CodeCity) {
    this.contratingService.getTownIdCity(CodeCity).subscribe((data: any) => {
      this.town = data;
    });
  } 

  openDialogPhotoPersonal() {
    this.dialogService.openDialogGestorImg('CARGAR IMAGEN',[],'editPhoto', 0, 'true', true).afterClosed().subscribe(formDataPhoto =>{
      // rest !== undefined ? this.updatePhotoPersonal(rest) : this.formDataPhoto = this.formDataPhoto;
      if(formDataPhoto !== undefined) {
        this.contratingService.updatePhotoPersonal(this.individualContract.personalemployerinfo.Id, formDataPhoto.FormData).subscribe(rest =>{
          this.getContracting(this.IDindividualContract);
          this.snackBService.openSnackBar('Foto Actualizada', 'X', 4000);
        });    
      }
    });
  }

  updatePhotoPersonal(formDataPhoto: FormData) {
    this.contratingService.updatePhotoPersonal(this.individualContract.personalemployerinfo.Id, formDataPhoto).subscribe(rest =>{
      this.getContracting(this.IDindividualContract);
      this.snackBService.openSnackBar('Foto Actualizada', 'X', 4000);
    });
  }

  uploadDoc(docId, inputDate?) {
    if(this.exitPermission('contractingdocuments.upload')) {
      this.dialogService.openDialogGestorImg('GESTOR DE DOCUMENTOS',[],'editPhoto', 0, 'true').afterClosed().subscribe((rest: any) =>{
        rest !== undefined ? this.setArrayDocumentSave(rest, docId, inputDate) : this.formDataPhoto = this.formDataPhoto;
      });
    } else {
      this.snackBService.openSnackBar('No tienes permiso de subir archivos', 'X', 4000);
    }
    
  }

  uploadDocExam(docId, inputDate?) {
    if(this.exitPermission('contractingmedicalcert.upload')) {
      this.dialogService.openDialogGestorImg('GESTOR DE DOCUMENTOS',[],'editPhoto', 0, 'true').afterClosed().subscribe((rest: any) =>{
        rest !== undefined ? this.setArrayDocumentExamSave(rest, docId, inputDate) : this.formDataPhoto = this.formDataPhoto;
      });
    } else {
      this.snackBService.openSnackBar('No tienes permiso de subir archivos', 'X', 4000);
    }
  }

  changeSelectedDate(IdDocument, Fecha) {    
    let date = this.fechasService.getDateFormatDatePiker(this.fechasService.formatDateYYYYMMDD(Fecha));
    // console.log(IdDocument + ' ' + date);
    // console.log('this.docsUploadIniado', this.docsUploadIniado);
    for (var i = 0; i < this.docsUploadIniado.length; i++) {
      if(IdDocument === this.docsUploadIniado[i].IdDocument) {
        this.docsUploadIniado[i].RequiredDate = date;
        this.setDateRequiredDate(this.docsUploadIniado[i]);
        // console.log('this.docsUploadIniado[i]',this.docsUploadIniado[i]);
      }
     }
    //  console.log('this.docsUploadIniado', this.docsUploadIniado);
    
  }

  changeSelectedDateExam(IdDocument, Fecha) {    
    let date = this.fechasService.getDateFormatDatePiker(this.fechasService.formatDateYYYYMMDD(Fecha));
    // console.log(IdDocument + ' ' + date);
    // console.log('this.docsUploadIniado', this.docsUploadIniado);
    for (var i = 0; i < this.docsUploadIniadoExam.length; i++) {
      if(IdDocument === this.docsUploadIniadoExam[i].IdDocument) {
        this.docsUploadIniadoExam[i].RequiredDate = date;
        this.setDateRequiredDateExam(this.docsUploadIniadoExam[i]);
        // console.log('this.docsUploadIniado[i]',this.docsUploadIniado[i]);
      }
     }
    //  console.log('this.docsUploadIniado', this.docsUploadIniado);
    
  }


  noExitRequiredDate(): boolean {
    let exit = false;
    for (var i = 0; i < this.docsUploadIniado.length; i++) {
      if(this.docsUploadIniado[i].IsByDate === true && (this.docsUploadIniado[i].RequiredDate === '' || this.docsUploadIniado[i].RequiredDate === '0001-01-01T00:00:00' || this.docsUploadIniado[i].RequiredDate === null)) {
        exit = true;
      }
    }
    return exit;
  }

  noExitRequiredDateExam(): boolean {
    let exit = false;
    for (var i = 0; i < this.docsUploadIniadoExam.length; i++) {
      if(this.docsUploadIniadoExam[i].DocumentContents === 'ExamForm' && this.docsUploadIniadoExam[i].IsByDate === true && (this.docsUploadIniadoExam[i].RequiredDate === '' || this.docsUploadIniadoExam[i].RequiredDate === '0001-01-01T00:00:00' || this.docsUploadIniadoExam[i].RequiredDate === null)) {
        exit = true;
      }
    }
    return exit;
  }

  dateRequiredDate: DocumentIndividualPost[] = [];
  setDateRequiredDate(dateRequiredDate: DocumentIndividualPost) {
    if(this.dateRequiredDate.length === 0) {
      this.dateRequiredDate.push(dateRequiredDate);
    } else {
      for (var i = 0; i < this.dateRequiredDate.length; i++) {
        if(this.dateRequiredDate[i].IdDocument === dateRequiredDate.IdDocument){
          this.dateRequiredDate[i].RequiredDate = dateRequiredDate.RequiredDate;
        } else {
          this.dateRequiredDate.push(dateRequiredDate);
        }
      }
    }
  }

  dateRequiredDateExam: DocumentIndividualPost[] = [];
  setDateRequiredDateExam(dateRequiredDateExam: DocumentIndividualPost) {
    if(this.dateRequiredDateExam.length === 0) {
      this.dateRequiredDateExam.push(dateRequiredDateExam);
    } else {
      for (var i = 0; i < this.dateRequiredDateExam.length; i++) {
        if(this.dateRequiredDateExam[i].IdDocument === dateRequiredDateExam.IdDocument){
          this.dateRequiredDateExam[i].RequiredDate = dateRequiredDateExam.RequiredDate;
        } else {
          this.dateRequiredDateExam.push(dateRequiredDateExam);
        }
      }
    }
  }

  getDateRequired(Id): string {
    for (var i = 0; i < this.docsUploadIniado.length; i++) {
      if(Id === this.docsUploadIniado[i].IdDocument) {
        return this.docsUploadIniado[i].RequiredDate;
      }
    }
  }

  getDateRequiredExam(Id): string {
    for (var i = 0; i < this.docsUploadIniadoExam.length; i++) {
      if(Id === this.docsUploadIniadoExam[i].IdDocument) {
        return this.docsUploadIniadoExam[i].RequiredDate;
      }
    }
    // console.log(this.docsUploadIniado);
    // let date = '';
    // let doc = this.docsUploadIniadoExam.find(doc => doc.IdDocument === Id);
    // doc !== undefined ? date = doc.RequiredDate: date = '';
    // return date;
  }

  putDocumentRequiredDateIndividual() {
    if(this.dateRequiredDate.length > 0){
      this.dateRequiredDate.forEach(data => {
        let docData = {
          IndividualContractId: this.IDindividualContract,
          IdDocument: data.IdDocument,
          RequiredDate: data.RequiredDate
        }
        this.contratingService.postDocumentDataIndividual(docData).subscribe(rest => {});
      });
    }
  }

  putDocumentRequiredDateIndividualExam() {
    if(this.dateRequiredDateExam.length > 0){
      this.dateRequiredDateExam.forEach(data => {
        let docData = {
          IndividualContractId: this.IDindividualContract,
          IdDocument: data.IdDocument,
          RequiredDate: data.RequiredDate
        }
        this.contratingService.postDocumentDataIndividual(docData).subscribe(rest => {});
      });
    }
  }

  docsUploadExam: DocumentIndividualPost[] = [];
  setArrayDocumentExamSave(formData: FormData, docId, inputDate){
    let date = this.fechasService.formatDateYYYYMMDD(inputDate);
    date === null || date === undefined ? date = '': date = date;
    if(this.docsUploadExam.length  === 0) {
      date === '' ?
      this.docsUploadExam.push({
        IndividualContractId: this.individualContract.Id,
        IdDocument: docId,
        FileData: formData,
      }) :
      this.docsUploadExam.push({
        IndividualContractId: this.individualContract.Id,
        IdDocument: docId,
        FileData: formData,
        RequiredDate: date
      });
    } else {
      this.docsUploadExam.forEach(docFile => {
        if(docFile.IdDocument === docId) {
          docFile.FileData = formData;
        } else {
          let exit = this.docsUploadExam.find(doc => doc.IdDocument === docId);
          exit === undefined ?
          (
          date === '' ?
          this.docsUploadExam.push({
            IndividualContractId: this.individualContract.Id,
            IdDocument: docId,
            FileData: formData,
          }) :
          this.docsUploadExam.push({
            IndividualContractId: this.individualContract.Id,
            IdDocument: docId,
            FileData: formData,
            RequiredDate: date
          })
          )
          :
          this.docsUploadExam = this.docsUploadExam;
        }
      });
    }
    this.docsUploadExam.forEach(doc => {
      if(docId === doc.IdDocument) {
        this.contratingService.postDocumentDataIndividual(doc).subscribe((rest: any) => {
          this.contratingService.putDocumentFileIndividual(rest.IdDocument, doc.FileData).subscribe(rest => {
            this.documentsExamFormParameters.forEach((documentParameter: DocumentI) => {
                if(docId === documentParameter.Id) {
                  this.contratingService.getDocuemntsIndividualContract(this.IDindividualContract).subscribe((documentsIndividualContract: getDocumentIndividualContract[]) => {
                    let doc = documentsIndividualContract.find(documentoId => documentoId.IdDocument === documentParameter.Id);
                    if(doc !== undefined) {
                      // this.docsUploadIniadoExam = [];
                      let documento = this.docsUploadIniadoExam.find(docUpload => docId === docUpload.IdDocument);
                      if(documento === undefined){
                        this.docsUploadIniadoExam.push({IdDocument: documentParameter.Id, IndividualContractId: this.IDindividualContract, urlDoc: doc.File,
                                                        Id: doc.Id,RequiredDate: doc.RequiredDate === '0001-01-01T00:00:00' ? '': doc.RequiredDate,
                                                        DocumentContents: doc.Document.DocumentContents,
                                                        IsByDate: doc.Document.IsByDate}); 
                      } else {
                        this.docsUploadIniadoExam.forEach((value, index)=> {
                          if(value.IdDocument === docId){
                            this.docsUploadIniadoExam[index].urlDoc = doc.File;
                          }
                        });
                      } 
                    }
                  });
                }
              });
            });
        });
      }
    });
  }

  docsUpload: DocumentIndividualPost[] = [];
  setArrayDocumentSave(formData: FormData, docId, inputDate){
    let date = this.fechasService.formatDateYYYYMMDD(inputDate);
    date === null || date === undefined ? date = '': date = date;
    if(this.docsUpload.length  === 0) {
      date === '' ?
      this.docsUpload.push({
        IndividualContractId: this.individualContract.Id,
        IdDocument: docId,
        FileData: formData,
      }) :
      this.docsUpload.push({
        IndividualContractId: this.individualContract.Id,
        IdDocument: docId,
        FileData: formData,
        RequiredDate: date
      });
    } else {
      this.docsUpload.forEach(docFile => {
        if(docFile.IdDocument === docId) {
          docFile.FileData = formData;
        } else {
          let exit = this.docsUpload.find(doc => doc.IdDocument === docId);
          exit === undefined ?
          (
          date === '' ?
          this.docsUpload.push({
            IndividualContractId: this.individualContract.Id,
            IdDocument: docId,
            FileData: formData,
          }) :
          this.docsUpload.push({
            IndividualContractId: this.individualContract.Id,
            IdDocument: docId,
            FileData: formData,
            RequiredDate: date
          })
          )
          :
          this.docsUpload = this.docsUpload;
        }
      });
    }
    this.docsUpload.forEach(doc => {
      if(docId === doc.IdDocument) {
        this.contratingService.postDocumentDataIndividual(doc).subscribe((rest: any) => {
          this.contratingService.putDocumentFileIndividual(rest.IdDocument, doc.FileData).subscribe(rest => {
            this.documentsAndRquisitosParameters.forEach((documentParameter: DocumentI) => {
                if(docId === documentParameter.Id) {
                  this.contratingService.getDocuemntsIndividualContract(this.IDindividualContract).subscribe((documentsIndividualContract: getDocumentIndividualContract[]) => {
                    let doc = documentsIndividualContract.find(documentoId => documentoId.IdDocument === documentParameter.Id);
                    if(doc !== undefined) {
                      // this.docsUploadIniado = [];
                      let documento = this.docsUploadIniado.find(docUpload => docId === docUpload.IdDocument);
                      if(documento === undefined){
                        this.docsUploadIniado.push({IdDocument: documentParameter.Id, IndividualContractId: this.IDindividualContract, urlDoc: doc.File, Id: doc.Id,
                                                    RequiredDate: doc.RequiredDate === '0001-01-01T00:00:00' ? '': doc.RequiredDate,
                                                    IsByDate: doc.Document.IsByDate}); 
                      } else {
                        this.docsUploadIniado.forEach((value, index)=> {
                          if(value.IdDocument === docId){
                            this.docsUploadIniado[index].urlDoc = doc.File
                          }
                        });
                      } 
                      
                    }
                  });
                }
              })
            });
        });
      }
    });
  }

  exitDocumentUpload(IdDocument): boolean {
    let exit = false;
    if(this.docsUpload.length !== 0 || this.docsUploadIniado.length !== 0) {
      this.docsUpload.find(doc => doc.IdDocument === IdDocument) !== undefined ? 
      exit = true:
      this.docsUploadIniado.find(doc => doc.IdDocument === IdDocument) !== undefined ? exit = true: exit = false;
    }
    return exit;
  }

  exitDocumentUploadExamForm(IdDocument): boolean {
    let exit = false;
    if(this.docsUploadExam.length !== 0 || this.docsUploadIniadoExam.length !== 0) {
      this.docsUploadExam.find(doc => doc.IdDocument === IdDocument) !== undefined ? 
      exit = true:
      this.docsUploadIniadoExam.find(doc => doc.IdDocument === IdDocument) !== undefined ? exit = true: exit = false;
    }
    return exit;
  }


  getImg(logo):string {
    let img = (logo === null || logo === '' ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg': logo);
    return img;
  }

  // Metodo no utilizado pero dado caso para borrar toda la informacion del contrato Docuemntos
  deleteDataDocumentIndividualContract() {
    this.dialogService.openDialogConfirm('ESTA SEGURO DE BORRAR LA INFORMACION DEL CONTRATO').afterClosed().subscribe(rest =>{
      if(rest !== false) {
        this.docsUploadIniado.forEach((doc, index) => {
          this.contratingService.deleteDocumentId(doc.Id).subscribe(rest => {});
          if(index === this.docsUploadIniado.length - 1){
            this.contratingService.deleteOrdenData(this.IDindividualContract).subscribe(putRest =>{
              this.docsUploadIniado = [];
              this.docsUpload = [];
              this.getContracting(this.IDindividualContract);
            });
          }  
        });
        if(this.docsUploadIniado.length === 0){
          this.contratingService.deleteOrdenData(this.IDindividualContract).subscribe(putRest =>{
            this.getContracting(this.IDindividualContract);
          });
        }
      }
    });
  }

  deleteOrden() {
    this.dialogService.openDialogConfirm('Esta seguro de eliminar el contrato individual?').afterClosed().subscribe(restClose => {
        if(restClose !== false) {
          this.contratingService.deleteIndividualContract(this.IDindividualContract).subscribe(deleteRest => {
            this.router.navigate(['/admin/contracting']);
          });
        }
    });
  }


  onSubmit() {
    if(this.state !== 'APROBADO') {
      this.formDatosContrato.get('PersonalEmployerInfoId').setValue(this.individualContract.PersonalEmployerInfoId);
      this.formDatosContrato.get('ContractId').setValue(this.individualContract.ContractId);
      // this.postStateInicial();
      if(this.state === "INICIADO" || this.state === 'RECHAZADO') {
        if(this.docsUpload.length !== this.documentsAndRquisitosParameters.length && this.docsUploadIniado.length === 0) {
          this.snackBService.openSnackBar('FALTA DOCUMENTOS O REQUISITOS DEL EMPLEADO1', 'X', 4000);
        } else if(this.documentsAndRquisitosParameters.length > this.docsUploadIniado.length){
          this.snackBService.openSnackBar('FALTA DOCUMENTOS O REQUISITOS DEL EMPLEADO2', 'X', 4000); 
        } else if(this.formCetificados.status !== "VALID" || this.formCetificados.get('EpsId').value === 0 || this.formCetificados.get('AfpId').value === 0){
          this.snackBService.openSnackBar('FALTA SELECCIONAR LA EPS O AFP DE LOS DOCUMENTOS', 'X', 4000);
        } else if(this.noExitRequiredDate()) {
          this.snackBService.openSnackBar('FALTAN FECHAS DE DOCUMENTO', 'X', 4000);
        } else if(this.formDatosContrato.status !== "VALID") {
          this.snackBService.openSnackBar('FALTA DATOS DEL CONTRATO', 'X', 4000);
        } else {
          this.postStateInicial(this.state);
        }
      } else if(this.state === "SOLICITADO") {
        if(this.docsUpload.length !== this.documentsAndRquisitosParameters.length && this.docsUploadIniado.length === 0) {
          this.snackBService.openSnackBar('FALTA DOCUMENTOS O REQUISITOS DEL EMPLEADO', 'X', 4000);
        } else if(this.documentsAndRquisitosParameters.length > this.docsUploadIniado.length){
          this.snackBService.openSnackBar('FALTA DOCUMENTOS O REQUISITOS DEL EMPLEADO', 'X', 4000);  
        } else if(this.noExitRequiredDate()) {
          this.snackBService.openSnackBar('FALTAN FECHAS DE DOCUMENTO', 'X', 4000);
        } else if(this.formDatosContrato.status !== "VALID") {
          // console.log('formDatosContrato :>> ', this.formDatosContrato);
          this.snackBService.openSnackBar('FALTA DATOS DEL CONTRATO', 'X', 4000);
        } else {
          this.postStateSolicitado('SOLICITADO');
        }
        // if(this.docsUpload.length !== this.documentsAndRquisitosParameters.length) {
        //   this.snackBService.openSnackBar('FALTA DOCUMENTOS O REQUISITOS DEL EMPLEADO', 'X', 4000);
        // } else if(this.formDatosContrato.status !== "VALID") {
        //   this.snackBService.openSnackBar('FALTA DATOS DEL CONTRATO', 'X', 4000);
        // } else {
        //   this.postStateInicial();
        // }
      }
    } else {
      if(this.formOtrosDatos.status === 'INVALID') {
        this.snackBService.openSnackBar('Faltan datos bancarios', 'X', 4000);
      } else if(!this.exitTypeDocument('SOLICITUD_DE_EXAMENES_MEDICOS')) {
        this.snackBService.openSnackBar('Falta examen de ingreso', 'X', 4000);
      } else if(this.docsUploadExam.length !== this.documentsExamFormParameters.length && this.docsUploadIniadoExam.length === 0) {
        this.snackBService.openSnackBar('FALTA CERTIFICADOS DE EXAMENES MEDICOS', 'X', 4000);
      } else if(this.documentsExamFormParameters.length > this.docsUploadIniadoExam.length){
        this.snackBService.openSnackBar('FALTA CERTIFICADOS DE EXAMENES MEDICOS', 'X', 4000);  
      } else if(this.noExitRequiredDateExam()) {
        this.snackBService.openSnackBar('FALTAN FECHAS DE CERTIFICADOS', 'X', 4000);
      } else {
        // console.log('this.formOtrosDatos.value',this.formOtrosDatos.value);
          this.contratingService.postGenerateProjectContract(this.IDindividualContract).subscribe(rest => {
            this.contratingService.postGenerateDocumentMedicalAdviceAcknowledgement(this.IDindividualContract).subscribe(rest => {
              this.contratingService.postGenerateDocumentDataAuthorization(this.IDindividualContract).subscribe(rest => {
                this.contratingService.postGenerateDocumentAuthMedicalExam(this.IDindividualContract).subscribe(rest => {
                  // this.savePersonalEmployerInfo();
                  this.snackBService.openSnackBar('ARCHIVOS GENERADOS', 'X', 4000);
                  this.postStateAprovado('CONTRATADO');
                });
              });
            });
          });
      }
    }
    if(this.state === 'CONTRATADO') {
      this.putDocumentRequiredDateIndividualExam();
      this.savePersonalEmployerInfo();
      this.upDatePersonal();
    }
    
  }
  

  postStateInicial(state: string) {
    console.log('INICIADO O RECHAZADO postStateInicial');
    // console.log('state :>> ', state);
    // console.log('files :>> ', this.docsUpload);
    
    // this.docsUpload.forEach(doc => {
    //   let documneto = this.docsUploadIniado.find(docUpload => docUpload.IdDocument === doc.IdDocument);
    //     this.contratingService.postDocumentDataIndividual(doc).subscribe((rest: any) => {
    //       this.contratingService.putDocumentFileIndividual(doc.IdDocument, doc.FileData).subscribe(rest => {
    //       });
    //     });
    // });
    this.individualContractPut = {
      IndividualContractTypeId: this.formDatosContrato.get('IndividualContractTypeId').value,
      PersonalEmployerInfoId: this.formDatosContrato.get('PersonalEmployerInfoId').value,
      ContractNumber: this.formContractNumber.get('ContractNumber').value,
      PositionId: this.formDatosContrato.get('PositionId').value,
      Salary: this.formDatosContrato.get('Salary').value.toString().replace(/\$/g, '').replace(/\./g, ''),
      PayPeriodId: this.formDatosContrato.get('PayPeriodId').value,
      StartDate: this.formDatosContrato.get('StartDate').value,
      // EndDate: this.formDatosContrato.get('EndDate').value,
      ProjectId: this.formDatosContrato.get('ProjectId').value,
      ContractId: this.formDatosContrato.get('ContractId').value,
      ContractCityId: this.formDatosContrato.get('ContractCityId').value,
      IndividualContractStage:
        { Comment:'', StageTypeId: this.getStateId(state), IndividualContractId: this.IDindividualContract },
    }
    this.savePersonalEmployerInfo();
    this.upDatePersonal();
    this.putDocumentRequiredDateIndividual();
    this.contratingService.putIndividualContract(this.IDindividualContract, this.individualContractPut).subscribe((rest: any) => {
        this.contratingService.postGenerateDocumentDatosContrato(this.IDindividualContract).subscribe((rest: any) => {
          if(state === 'INICIADO' || state === 'RECHAZADO'){
            this.dialogService.openDialogIndividualContractAproved('CONFIRMAR SOLICITUD', undefined, 'Orden de contratación', this.getUrlDocument(rest.url), 'CONFIRMAR SOLICITUD').afterClosed().subscribe(rest =>{
              if(rest !== undefined) {
                  this.docsUpload = [];
                  this.postStateInicial('SOLICITADO');
              }
            });
          } else if(state === 'SOLICITADO') {
            this.getContracting(this.IDindividualContract);
          }
        });
    });
  }

  // postGenerateDocumentExamenMedico() {
  //   this.contratingService.postGenerateDocumentExamenMedico(this.IDindividualContract, ).subscribe((rest: any) => {
  //     this.dialogService.openDialogIndividualContractAproved(undefined, 'Examen Medico ocupacional', this.getUrlDocument(rest.url), 'CONFIRMAR SOLICITUD').afterClosed().subscribe(rest =>{
  //       this.getContracting(this.IDindividualContract);
  //     });
  //   });
  // }

  postStateSolicitado(state: string) {
    console.log('SOLICITADO postStateSolicitado');
    console.log('state :>> ', state);
    // this.docsUpload.forEach(doc => {
    //   let documneto = this.docsUploadIniado.find(docUpload => docUpload.IdDocument === doc.IdDocument);
    //     this.contratingService.postDocumentDataIndividual(doc).subscribe((rest: any) => {
    //       this.contratingService.putDocumentFileIndividual(doc.IdDocument, doc.FileData).subscribe(rest => {
    //       });
    //     });
    // });
    this.individualContractPut = {
      IndividualContractTypeId: this.formDatosContrato.get('IndividualContractTypeId').value,
      PersonalEmployerInfoId: this.formDatosContrato.get('PersonalEmployerInfoId').value,
      ContractNumber: this.formContractNumber.get('ContractNumber').value,
      PositionId: this.formDatosContrato.get('PositionId').value,
      Salary: this.formDatosContrato.get('Salary').value.toString().replace(/\$/g, '').replace(/\./g, ''),
      PayPeriodId: this.formDatosContrato.get('PayPeriodId').value,
      StartDate: this.formDatosContrato.get('StartDate').value,
      // EndDate: this.formDatosContrato.get('EndDate').value,
      ProjectId: this.formDatosContrato.get('ProjectId').value,
      ContractId: this.formDatosContrato.get('ContractId').value,
      ContractCityId: this.formDatosContrato.get('ContractCityId').value,
      IndividualContractStage:
        { Comment: '', StageTypeId: this.getStateId(state), IndividualContractId: this.IDindividualContract },
    }
    this.savePersonalEmployerInfo();
    this.upDatePersonal();
    this.putDocumentRequiredDateIndividual();
    this.contratingService.putIndividualContract(this.IDindividualContract, this.individualContractPut).subscribe((putIndividualContract: any) => {
        this.contratingService.postGenerateDocumentDatosContratoAproved(this.IDindividualContract).subscribe((rest: any) => {
          if(state === 'SOLICITADO'){
            this.dialogService.openDialogIndividualContractAproved('APROBAR SOLICITUD',undefined, 'Orden de contratación', this.getUrlDocument(rest.url), 'APROBAR SOLICITUD').afterClosed().subscribe(rest =>{
              if(rest !== undefined) {
                this.contratingService.postGenerateDocumentDatosContratoAproved(this.IDindividualContract).subscribe((rest: any) => {
                  this.docsUpload = [];
                  rest !== undefined ? this.postStateSolicitado('APROBADO'): '';
                });
              }
            });
          } else if(state === 'APROBADO') {
            this.getContracting(this.IDindividualContract);
          }
        });
    });
  }
  

  postStateAprovado(state: string) {
    this.individualContractPut = {
      IndividualContractTypeId: this.formDatosContrato.get('IndividualContractTypeId').value,
      PersonalEmployerInfoId: this.formDatosContrato.get('PersonalEmployerInfoId').value,
      ContractNumber: this.formContractNumber.get('ContractNumber').value,
      PositionId: this.formDatosContrato.get('PositionId').value,
      Salary: this.formDatosContrato.get('Salary').value.toString().replace(/\$/g, '').replace(/\./g, ''),
      PayPeriodId: this.formDatosContrato.get('PayPeriodId').value,
      StartDate: this.formDatosContrato.get('StartDate').value,
      // EndDate: this.formDatosContrato.get('EndDate').value,
      ProjectId: this.formDatosContrato.get('ProjectId').value,
      ContractId: this.formDatosContrato.get('ContractId').value,
      ContractCityId: this.formDatosContrato.get('ContractCityId').value,
      IndividualContractStage:
        { Comment: '', StageTypeId: this.getStateId(state), IndividualContractId: this.IDindividualContract },
    }
    this.savePersonalEmployerInfo();
    this.upDatePersonal();
    this.putDocumentRequiredDateIndividual();
    this.putDocumentRequiredDateIndividualExam();
    this.contratingService.putIndividualContract(this.IDindividualContract, this.individualContractPut).subscribe((putIndividualContract: any) => {
        if(state === 'APROBADO'){
            this.contratingService.postGenerateDocumentDatosContratoAproved(this.IDindividualContract).subscribe((rest: any) => {
              this.postStateAprovado('CONTRATADO');
            });
        } else if(state === 'CONTRATADO') {
          this.getContracting(this.IDindividualContract);
        }
      });
  }

  openDialogIndividualContractRechazar() {
      this.contratingService.postGenerateDocumentIndividualContractRechazada(this.IDindividualContract).subscribe((contratoRechazado: any) => {
        this.dialogService.openDialogIndividualContractAproved('RECHAZAR ORDEN', undefined, 'Orden de contratación', this.getUrlDocument(contratoRechazado.url), 'RECHAZAR SOLICITUD', true).afterClosed().subscribe(rest =>{
          // console.log('rest',rest);
          rest !== undefined || rest === '' ? this.rechazarOrden(rest): '';
        });
      });
    
  }

  anularOrdern() {
    this.dialogService.openDialogIndividualContractAproved('ANULAR APROBACIÓN', undefined, 'Orden de contratación', '', 'ANULAR APROBACIÓN', true).afterClosed().subscribe(comment =>{
      // console.log('rest',rest);
      // rest !== undefined || rest === '' ? this.rechazarOrden(rest): '';
      if(comment !== undefined || comment === '') {
        this.contratingService.postGenerateDocumentIndividualContractRechazada(this.IDindividualContract).subscribe(rest => {
          let model = { Comment: comment, StageTypeId: this.getStateId('ANULADO'), IndividualContractId: this.IDindividualContract }
          this.contratingService.changeStateIndividualContract(model).subscribe((putIndividualContract: any) => {
            // console.log('anularOrden ',putIndividualContract);
            this.docsUpload = [];
            this.getContracting(this.IDindividualContract);
          });
        });
      }
    });
    
  }

  rechazarOrden(Comment) {
    this.contratingService.postGenerateDocumentIndividualContractRechazada(this.IDindividualContract).subscribe(rest => {
      let model = { Comment:Comment, StageTypeId: this.getStateId('RECHAZADO'), IndividualContractId: this.IDindividualContract }
      this.contratingService.changeStateIndividualContract(model).subscribe((putIndividualContract: any) => {
        // console.log('rechazarOrden ',putIndividualContract);
        this.docsUpload = [];
        this.getContracting(this.IDindividualContract);
      });
    });
  }

  savePersonalEmployerInfo() {
    let personalEmployerInfo: PersonalEmployerInfo = {
      CityCode: this.formPersonal.get('CityCode').value,
      TownCode: this.formPersonal.get('TownCode').value,
      Address: this.formPersonal.get('Address').value,
      PhoneNumber: this.formPersonal.get('PhoneNumber').value,
      EmergencyContact: this.formPersonal.get('EmergencyContact').value,
      EmergencyPhone: this.formPersonal.get('EmergencyPhone').value,
      EpsId: this.formCetificados.get('EpsId').value,
      AfpId: this.formCetificados.get('AfpId').value,
      Email: this.formPersonal.get('Email').value,
      BankAccount: this.formOtrosDatos.get('BankAccount').value === '' ? '': this.formOtrosDatos.get('BankAccount').value,
      CcfId: this.formOtrosDatos.get('CcfId').value === '' ? 0: this.formOtrosDatos.get('CcfId').value,
      BankId: this.formOtrosDatos.get('BankId').value === '' ? 0: this.formOtrosDatos.get('BankId').value,
      BankAccountTypeId: this.formOtrosDatos.get('BankAccountTypeId').value === '' ? 0: this.formOtrosDatos.get('BankAccountTypeId').value,
      ContributorTypeId: this.formOtrosDatos.get('ContributorTypeId').value === '' ? 0: this.formOtrosDatos.get('ContributorTypeId').value,
      TypeARLId: this.formOtrosDatos.get('TypeARLId').value === '' ? 0: this.formOtrosDatos.get('TypeARLId').value
    }
    this.contratingService.putPersonalEmployerInfo(this.individualContract.PersonalEmployerInfoId, personalEmployerInfo).subscribe(rest => {
      // console.log(rest);
    });
  }

  isCreateAppPersonal: boolean = false;
  upDatePersonal() {
    if(this.isCreateAppPersonal === false) {
      let birthDate = ''
      if(typeof this.formPersonal.get('BirthDate').value === 'object') {
        birthDate = this.fechasService.getDateFormatDayMontYear(this.formPersonal.get('BirthDate').value);
      } else {
        birthDate = this.formPersonal.get('BirthDate').value.toString().replace(/\-/g, '').substr(0,8)
      }
      let personalModel = {
        DocumentType: this.formPersonal.get('DocumentType').value,
        DocumentNumber: this.formPersonal.get('DocumentNumber').value,
        Name: this.formPersonal.get('Name').value,
        BirthDate: birthDate,
        LastName: this.formPersonal.get('LastName').value,
        // Height: 0,
        RH: this.formPersonal.get('RH').value,
        Sex: this.formPersonal.get('Sex').value,
        Nationality: this.formPersonal.get('Nationality').value,
        CityOfBirthCode: this.formPersonal.get('CityOfBirthCode').value,
        // DocumentRaw: this.formPersonal.get('CityCode').value,
      }
      
      this.personaService.putUpdatePersonal(personalModel).subscribe(rest => {
        
      });
    }
  }


  getUrlDocCreateInicial(IdDocument: string): boolean {
    let exit: boolean = false;
    this.docsUploadIniado.find(doc => doc.IdDocument === IdDocument) !== undefined ?
    exit = true : exit = false;
    return exit;
  }

  getUrlDocCreateInicialExam(IdDocument: string): boolean {
    let exit: boolean = false;
    this.docsUploadIniadoExam.find(doc => doc.IdDocument === IdDocument) !== undefined ?
    exit = true : exit = false;
    return exit;
  }

  openDialogImageOrVideoOrPdf(IdDocument: string) {
    // console.log(this.docsUploadIniado);
    let doc = this.docsUploadIniado.find(doc => doc.IdDocument === IdDocument);
    if(doc !== undefined ) {
      if(doc.urlDoc !== '' && doc.urlDoc !== undefined && doc.urlDoc !== null){
        this.dialogService.openDialogImageOrVideoOrPdf(this.getUrlDocument(doc.urlDoc)).afterClosed().subscribe(rest =>{});
      } else {
        this.snackBService.openSnackBar('NO HAY DOCUMENTO GUARDADO', 'X', 4000);
      }
      
    }
    // this.dialogService.openDialogImageOrVideoOrPdf()
  }

  openDialogImageOrVideoOrPdfExam(IdDocument: string) {
    // console.log(this.docsUploadIniadoExam);
    let doc = this.docsUploadIniadoExam.find(doc => doc.IdDocument === IdDocument);
    if(doc !== undefined ) {
      if(doc.urlDoc !== '' && doc.urlDoc !== undefined && doc.urlDoc !== null){
        this.dialogService.openDialogImageOrVideoOrPdf(this.getUrlDocument(doc.urlDoc)).afterClosed().subscribe(rest =>{});
      } else {
        this.snackBService.openSnackBar('NO HAY DOCUMENTO GUARDADO', 'X', 4000);
      }
    }
    // this.dialogService.openDialogImageOrVideoOrPdf()
  }

  getUrlDocument(urlDoc: string): string {
    let url: string = urlDoc.split('.com/')[1];
    return url;
  }


  getNameDocumentGenerate(DocumentContents): string {
    let name: string = '';
    if(this.docsSave.length !== 0) {
      let doc =this.documentsGenerateParameters.find(doc => doc.DocumentContents === DocumentContents);
      doc !== undefined ? name = doc.Name: name= name;
    }
    return name;
  }


  exitTypeDocument(tipoDocument): boolean {
    let exit = false;
    if(this.docsSave.length !== 0){
      let doc = this.docsSave.find(doc => doc.Document.DocumentContents === tipoDocument);
      doc !== undefined ? exit = true: exit= false;
    }
    return exit;
  }

  deleteTypeDocument(typeDocument: string) {
    this.dialogService.openDialogConfirm('Esta seguro de eliminar el documento').afterClosed().subscribe(restDialog => {
      if(restDialog !== false) {
        if(this.docsSave.length !== 0){
          let doc = this.docsSave.find(doc => doc.Document.DocumentContents === typeDocument);
          // console.log('doc',doc);
          doc !== undefined ? this.contratingService.deleteDocumentId(doc.Id).subscribe(rest =>{
            this.getDocumentStatesAll();
          })
          : '';
        }
      }
    })
    
  }

  showTypeDocument(tipoDocument) {
    let docUrl = '';
    if(this.docsSave.length !== 0){
      let doc = this.docsSave.find(doc => doc.Document.DocumentContents === tipoDocument);
      doc !== undefined ? this.showGenerateSolicitud(doc.File): docUrl= '';
    }
  }

  // getDocumentoGenerado(Abrv){
  //   this.contratingService.getDocumentoGenerado(this.IDindividualContract, Abrv).subscribe((rest: any) =>{
  //     this.docSolicitudContrato = [];
  //     this.docSolicitudContrato.push(rest[0].url);
  //     console.log('object :>> ', this.docSolicitudContrato);
  //   });
  // }

  getGenerateExamenMedico(){
    if(this.formExamType.status === 'VALID') {
      this.contratingService.postGenerateDocumentExamenMedico(this.IDindividualContract, this.formExamType.get('examType').value).subscribe(rest => {
        this.contratingService.getDocuemntsIndividualContract(this.IDindividualContract).subscribe((documentsIndividualContract: getDocumentIndividualContract[]) => {
          this.docsSave = documentsIndividualContract;
          this.snackBService.openSnackBar('ARCHIVO GENERADO', 'X', 4000);
        });
      });
    } else {
      this.snackBService.openSnackBar('FALTA SELECCIONAR TIPO DE EXAMEN MEDICO', 'X', 4000);
    }
    
  }

  showGenerateSolicitud(docUrl) {
    // console.log(docUrl);
    let url = this.getUrlDocument(docUrl);
    this.dialogService.openDialogImageOrVideoOrPdf(url).afterClosed().subscribe(rest =>{
        
    })
  }

  exitPermission(permiso: string): boolean {
    let exit: boolean = false;
    let permisionsUserInfo  = this.permissions.find(x => x === permiso);
    permisionsUserInfo !== undefined ? exit = true: exit = false;
    return exit;
  }

}
