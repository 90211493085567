<div class="box" (keyup.enter)="search()">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'CREAR CONTRATO' || 'N/A'}}</div>
    </div>
    <div class="scroll">
        <div class="content-card list-card" >
            <!-- proyecto -->
            <div class="sub-header" *ngIf="pasoSelectedProyet === true">
                <div class="sub-content greendAnalityco">{{'PROYECTO' || 'N/A'}}</div>
            </div>
            <br *ngIf="pasoSelectedProyet === true">
            <form [formGroup]="formVincularProyect" *ngIf="pasoSelectedProyet === true">
                <div class="form-group-list">
                    <app-select-search
                                [_dataSource]="contractProjects" [textInput]="'PROYECTO'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formVincularProyect.get('ProjectId')"
                                [inputType]="'text'" (onSelectionChange)="changeSelectedProyect($event)">
                    </app-select-search>

                    <mat-label class="multiChips-title">ESTAPAS DEL PROYECTO</mat-label>
                    <mat-form-field class="chip-list"  appearance="fill" style="margin-top: 0px !important;">
                            <mat-chip-list #chipListeconomicActivity aria-label="Fruit selection">
                            <mat-chip
                                *ngFor="let values of viewValueseconomicActivity"
                                [selectable]="selectable"
                                [removable]="removable"
                                (removed)="removeActivity(values)">
                                {{values}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input
                                placeholder="+Seleccionar"
                                #InputControleconomicActivity
                                [formControl]="controleconomicActivity"
                                [matAutocomplete]="autoeconomicActivity"
                                [matChipInputFor]="chipListeconomicActivity"
                                (matChipInputTokenEnd)="addActivity($event)"
                                >
                            </mat-chip-list>
                            <mat-autocomplete #autoeconomicActivity="matAutocomplete" (optionSelected)="selectedActivity($event)">
                            <mat-option *ngFor="let item of economicActivityObs" [value]="item.Id">
                                {{item.Name || ''}}
                            </mat-option>
                            </mat-autocomplete>
                    </mat-form-field>

                  <div class="fields-group-2">
                    <div class="input-container">
                      <div class="fields-group-2" [ngClass]="calculateIfDateIsGreaterThanToday(formVincularProyect.get('StartDate').value)">
                        <div class="labeTimePiecker-2" style="flex-direction: column; align-items: flex-start; margin-right: 20px;" >
                          <mat-label class="classLabel" style="padding-left: 10px; font-weight: bold" >INICIO<mat-label *ngIf="calculateIfDateIsGreaterThanTodayFlag(formVincularProyect.get('StartDate').value)" [ngClass]="calculateIfDateIsGreaterThanToday(formVincularProyect.get('StartDate').value) +'-string'">(INVACTIVO)</mat-label></mat-label>
                          <div class="input-field-medium-date-datetime-2" matTooltip="INICIO">
                            <mat-form-field appearance="outline">
                              <input matInput type="datetime-local"
                                     formControlName="StartDate"
                                     style="max-width: 138px !important; height: 16px !important; display: flex; flex-direction: column-reverse;"
                                     #inputDateStartDateContracttime
                                     [min]="minDateNow"
                                     [value]="this.fechasService.getDateHours(formVincularProyect.get('StartDate').value)"
                                     (change)="changeDateFinishDateContractTime(inputDateStartDateContracttime?.value, false)"
                                     [disabled]="isDisabledFieldProject()"
                              >
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="fields-group-2">
                      <div class="fields-group-2" style="margin-left: -9px;" [ngClass]="getCalculateStateDateWithHORA(formVincularProyect.get('FinishDate').value)">
                        <div class="labeTimePiecker-2" style="flex-direction: column; margin-right: 20px;">
                          <mat-label class="classLabel" style="padding-left: 10px; font-weight: bold">FIN <mat-label *ngIf="getIsOutDate(formVincularProyect.get('FinishDate').value)" [ngClass]="getCalculateStateDateString(formVincularProyect.get('FinishDate').value)">({{getCalculateStateDateAndHourDescription(formVincularProyect.get('FinishDate').value)}})</mat-label></mat-label>
                          <div class="input-field-medium-date-datetime-2" matTooltip="FIN">
                            <mat-form-field appearance="outline">
                              <input matInput type="datetime-local"
                                     style="max-width: 138px !important; height: 16px !important; display: flex; flex-direction: column-reverse;"
                                     #inputDateFinishDateContracttime
                                     formControlName="FinishDate"
                                     [min]="minDateNow"
                                     [max]="maxDateOfProyect"
                                     (change)="changeDateFinishDateContractTime(inputDateFinishDateContracttime?.value, true)"
                                     [value]="this.fechasService.getDateHours(formVincularProyect.get('FinishDate').value)"
                                     [disabled]="isDisabledFieldProject()"
                              >
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </form>
            <br>
            <div class="sub-header" *ngIf="formVincularProyect.get('ProjectId').value !== '' && viewValueseconomicActivity.length > 0 && pasoSelectedProyet === false">
                <div class="sub-content greendAnalityco">{{'CONTRATO' || 'N/A'}}</div>
            </div>
            <br>
            <form [formGroup]="formContract" *ngIf="formVincularProyect.get('ProjectId').value !== '' && viewValueseconomicActivity.length > 0 && pasoSelectedProyet === false">
                <app-select-search class="select-component"
                    [_dataSource]="contractTypes" [textInput]="'TIPO DE CONTRATO'" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="formContract.get('ContractTypeId')"
                    [inputType]="'text'" (onSelectionChange)="onChangeTypeContract($event)">
                </app-select-search>
                <mat-form-field class="input-field">
                    <label><strong>NÚMERO DE CONTRATO</strong></label>
                    <input matInput type="text" formControlName="ContractNumber">
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
                <div class="select-logo">
                    <div style="margin-right: 10px;">
                        <label><strong>LOGO</strong></label>
                    </div>
                    <div *ngIf="selectedLogo.length > 0">
                        <div class="card" *ngFor="let logo of selectedLogo">
                            <div class="card-content">
                                <img src="{{s3Bucket+getImg(logo.Logo)}}" alt="">
                            </div>
                            <mat-icon class="icon-close" (click)="deleteLogoSelected()">close</mat-icon>
                        </div>
                    </div>
                    <app-select-search class="select-component-short"
                        *ngIf="selectedLogo.length === 0"
                        [_dataSource]="formImageContract"
                        [textInput]="''"
                        [dataShow]="'Tags'" [dataValue]="'Id'" [siteCtrl]="formContract.get('FormImageId')"
                        [inputType]="'text'" [isCardImage]="true"
                        [card]="{img: 'Logo'}"
                        (onSelectionChange)="onSelectedLogo($event)"
                        >
                    </app-select-search>
                </div>
                <mat-form-field class="input-field">
                    <label><strong>RESEÑA</strong></label>
                    <input matInput type="text" formControlName="ContractReview">
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
                <div class="sub-header" *ngIf="formContract.get('ContractTypeId').value !== ''">
                    <div class="sub-content greendAnalityco">{{'CONTRATANTE' || 'N/A'}}</div>
                </div>
                <div class="card" *ngFor="let company of companySelected">
                    <div class="card-option">
                        <img src="{{s3Bucket+getImg(company.Logo)}}" (error)="commonMetodService._getUrlErrorImg('administradora', $event)" alt="" alt="">
                        <div class="card-content">
                            <div class="card-items">
                                <h3 class="title">{{company.Name || ''}} {{company.LastName || ''}}</h3>
                                <div class="field">{{company.DocumentType}}: {{company.DocumentNumber || ''}}</div>
                                <div class="field">{{company.Address || ''}}</div>
                            </div>
                        </div>
                    </div>
                    <mat-icon class="icon-close" *ngIf="companySelected.length !== 1" (click)="deleteCompanySelected()">close</mat-icon>
                </div>
                <br>
                <app-select-search class="select-component" *ngIf="companySelected.length !== 1 && formContract.get('ContractTypeId').value !== ''"
                            style="margin-top: 10px"
                            [_dataSource]="typeContractState === 'AS' ? companiesAsociados: companies" [textInput]="'Buscar empresa'" [inputType]="'text'"
                            [dataShow]="'Name'" [dataValue]="'Id'" [otherFilter]="'DocumentNumber'" [siteCtrl]="controlCompany"
                            [isCard]="true"
                            [card]="{img: 'Logo', title: 'Name', itemKey: 'DocumentType', itemValue:'DocumentNumber', finalLeft: 'Address'}"
                            (onSelectionChange)="onSelectedCompany($event)"
                            [onErrorUrl]="'administradora'"
                            >
                </app-select-search>

            </form>
            <div class="sub-header" *ngIf="typeContractState !== 'AS' && typeContractState !== 'AD' && formContract.get('ContractTypeId').value !== '' && companySelected.length !== 0 && viewValueseconomicActivity.length > 0 && pasoSelectedProyet === false">
                    <div class="sub-content greendAnalityco">{{'CONTRATISTA' || 'N/A'}}</div>
            </div>
            <div class="card" *ngFor="let employer of employerSelected">
                <div class="card-option">
                    <img src="{{s3Bucket+getImgEmployer(employer.Logo)}}" (error)="commonMetodService._getUrlErrorImg('employer', $event)" alt="">
                    <div class="card-content">
                        <div class="card-items">
                            <h3 class="title">{{employer.Name || ''}} {{employer.LastName || ''}}</h3>
                            <div class="field">{{employer.DocumentType}}: {{employer.DocumentNumber || ''}}</div>
                            <div class="field">{{employer.Address || ''}}</div>
                        </div>
                    </div>
                </div>
                <mat-icon class="icon-close" (click)="deleteEmployerSelected()">close</mat-icon>
            </div>
            <label class="text-description-title" *ngIf="typeContractState !== 'AS' && typeContractState !== 'AD' && employerSelected.length !== 1 && companySelected.length !== 0 && formContract.get('ContractTypeId').value !== '' && viewValueseconomicActivity.length > 0 && pasoSelectedProyet === false"><strong>BUSCAR POR NOMBRE</strong></label>
            <div class="fields-group" *ngIf="typeContractState !== 'AS' && typeContractState !== 'AD' && employerSelected.length !== 1 && companySelected.length !== 0 && formContract.get('ContractTypeId').value !== '' && viewValueseconomicActivity.length > 0 && pasoSelectedProyet === false">
                <app-select-search class="select-component"
                            [_dataSource]="allEmployers" [textInput]="''" [inputType]="'text'"
                            [dataShow]="'Name'" [dataValue]="'Id'" [otherFilter]="'DocumentNumber'" [siteCtrl]="controlSearchAllEmployer"
                            [isCard]="true"
                            [card]="{img: 'Logo', title: 'Name', itemKey: 'DocumentType', itemValue:'DocumentNumber', finalLeft: 'Rol'}"
                            (onSelectionChange)="onSelectedAllEmployer($event)"
                            [onErrorUrl]="'employer'"
                            >
                </app-select-search>
            </div>
            <label class="text-description-title" *ngIf="typeContractState !== 'AS' && typeContractState !== 'AD' && employerSelected.length !== 1 && companySelected.length !== 0 && formContract.get('ContractTypeId').value !== '' && viewValueseconomicActivity.length > 0 && pasoSelectedProyet === false"><strong>BUSCAR POR DOCUMENTO</strong></label>
            <div class="fields-group" *ngIf="typeContractState !== 'AS' && typeContractState !== 'AD' && employerSelected.length !== 1 && companySelected.length !== 0 && formContract.get('ContractTypeId').value !== '' && viewValueseconomicActivity.length > 0 && pasoSelectedProyet === false">
                    <mat-form-field class="input-field-medium" appearance="fill" >
                        <mat-label>TIPO</mat-label>
                        <mat-select [(ngModel)]="documentType" (selectionChange)="changeMaxCaracter($event)">
                            <mat-option *ngFor="let data of companyDocumentType" [value]="data.Value">
                                {{data.Value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="input-field-100" appearance="fill">
                        <mat-label>NÚMERO</mat-label>
                        <input matInput type="text" [(ngModel)]="documentNumber"  maxlength="{{maxCaracter}}" (ngModelChange)="validateNit()">
                    </mat-form-field>
                    <div *ngIf="documentType === 'NIT'" class="nitSeparator">-</div>
                    <mat-form-field class="input-field-short" *ngIf="documentType === 'NIT'" appearance="fill">
                        <input matInput type="text" [(ngModel)]="digito" maxlength="1" (ngModelChange)="validateNit()">
                    </mat-form-field>
                    <div class="btn-save" (click)="search()">
                        <button mat-mini-fab color="primary" matTooltip="buscar" >
                        <mat-icon>search</mat-icon>
                        </button>
                    </div>
            </div>
            <div class="NITinvalido" *ngIf="documentType === 'NIT' && flagValidNit === false && typeContractState !== 'AS' && typeContractState !== 'AD' && viewValueseconomicActivity.length > 0 && pasoSelectedProyet === false">
                    NIT invalido
            </div>
            <br>
            <div class="actions-dialog"style="margin: 3px;" *ngIf="pasoSelectedProyet === true">
                <button mat-button class="btn-cancelar" [mat-dialog-close]="data" >CANCELAR</button>&nbsp;&nbsp;
                <button mat-button
                        *ngIf="this.formVincularProyect.status === 'VALID' && this.viewValueseconomicActivity.length > 0"
                        (click)="pasoSelectedProyet = false"
                        class="btn-generar">SIGUENTE
                </button>
            </div>
            <div class="actions-dialog"style="margin: 3px;" *ngIf="pasoSelectedProyet === false">
                <button mat-button class="btn-cancelar" (click)="pasoSelectedProyet = true" >ATRAS</button>&nbsp;&nbsp;
                <button mat-button
                        *ngIf="validFormContract() === true"
                        (click)="save()"
                        class="btn-generar">GUARDAR
                </button>
            </div>
        </div>

    </div>
</div>
