<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'NOVEDADES' || 'N/A'}}</div>
    </div>
    
        <div class="content-card list-card" >
            
            <div class="sub-header">
                <div class="sub-content greendAnalityco">{{'EMPLEADOR' || 'N/A'}}</div>
            </div>
            <div class="card" *ngFor="let employer of employerSelected">
                <div class="card-content">
                    <img src="{{s3Bucket+getImg(employer.Logo)}}" alt="">
                    <div class="content-information">
                        <div class="ellipsis" style="max-width: 200px;">{{employer.Name || ''}}</div>
                        <div class="ellipsis baseline">{{employer.Rol}}</div>
                        <div class="ellipsis baseline">{{employer.DocumentType}}: {{employer.DocumentNumber || ''}}</div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="sub-header">
                <div class="sub-content greendAnalityco">{{'EMPLEADO' || 'N/A'}}</div>
            </div>
            <div class="card" *ngFor="let personal of personalSelected">
                <div class="card-content">
                    <img src="{{s3Bucket+getImg(personal.Logo)}}" alt="">
                    <div class="content-information">
                        <div class="ellipsis" style="max-width: 200px;">{{personal.Name || ''}} {{personal.LastName || ''}}</div>
                        <div class="ellipsis baseline">{{personal.DocumentType}}: {{personal.DocumentNumber || ''}}</div>
                        <!-- <div class="ellipsis selected">{{'PERSONA SELECCIONADO' || ''}}</div> -->
                    </div>
                </div>
                <mat-icon class="icon-close" (click)="setValuePersonal()" *ngIf="data.edit === false">close</mat-icon>
            </div>
            <div class="fields-group" *ngIf="employerSelected.length === 1 && personalSelected.length !==1">
                <mat-form-field class="input-field-short">
                    <label><strong>TIPO</strong></label>
                    <mat-select [(ngModel)]="documentType" (selectionChange)="changeType($event.value)">
                        <mat-option *ngFor="let data of companyDocumentType" [value]="data.Value">
                            {{data.Value === 'CC' ? 'Cédula': (data.Value === 'PE' ? 'Permiso especial de permanecía': 'Cedula de extranjeria')}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> 
                <mat-form-field class="input-field-50">
                    <label><strong>Numero</strong></label>
                    <input matInput type="text" [(ngModel)]="documentNumber" pattern="/^-?\d+\.?\d*$/" maxlength="{{maxCaracter}}">
                    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="setValuePersonal()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <div class="btn-save" (click)="getPersonalDocument()">
                    <button mat-mini-fab color="primary" matTooltip="buscar" >
                    <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div>
            <div class="sub-header">
                <div class="sub-content greendAnalityco">{{'NOVEDAD' || 'N/A'}}</div>
            </div>
            <h4 style="width: 100%;"><b>{{'Periodo de trabajo: '}}</b>{{data.period}}</h4>
            <form [formGroup]="formNovelty" class="list-card">
                <app-select-search class="select-component" *ngIf="personalSelected.length === 1 && noveltyTypes"
                                [_dataSource]="noveltyTypes" [textInput]="'TIPO DE NOVEDAD'" [inputType]="'text'" 
                                [dataShow]="'Description'" [dataValue]="'Id'" [otherFilter]="'Value'" [siteCtrl]="  " 
                                (onSelectionChange)="onSelectionChangeNovelty($event)">
                </app-select-search> 
                <br>
                <div class="fields-group"  *ngIf="typeNovelty !== '' && data.period ">
                    <label><strong>FECHA INICIAL</strong></label>
                    <div class="text-datepiker">
                        <mat-icon (click)="picker.open()" style="cursor: pointer;">today</mat-icon>
                        <mat-form-field class="input-field-medium">
                            <input matInput [matDatepicker]="picker" formControlName="StartDate" (click)="picker.open()" [min]="minDate" [max]="maxDate">
                            <div class="btn-clear" mat-button matSuffix mat-icon-button>X</div>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error>Requerido</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="fields-group" *ngIf="typeNovelty === 'SLN' || typeNovelty === 'VAC-LR'">
                    <label><strong>DURACIÓN (DÍAS)</strong></label>
                    <mat-form-field class="input-field-medium">
                        <!-- <label><strong>DURACIÓN</strong></label> -->
                        <input matInput type="number" formControlName="Duration">
                        <div class="btn-clear" mat-button matSuffix mat-icon-button>X</div>
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                </div>
                <div class="actions-dialog"style="margin: 3px;">
                    <button mat-button class="btn-cancelar" [mat-dialog-close]="data">CANCELAR</button>&nbsp;&nbsp;
                    <button mat-button 
                            (click)="saveNovelty()"
                            [ngClass]="getDisabled() === true ? 'btn-generar':'btn-disabled'"
                            [disabled]="!getDisabled()">GUARDAR
                    </button>
                    <!-- <button (click)="_getContractNumber()">Hola</button> -->
                </div>
            </form>
        </div>
   
    
</div>