import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppColor } from '../../services/utils/utils.types';

@Component({
  selector: 'app-card-title',
  templateUrl: './card-title.component.html',
  styleUrls: ['./card-title.component.scss']
})
export class CardTitleComponent implements OnInit {

  @Input() title: string;
  @Input() color: AppColor;

  @Input() icon: string;


  @Output() clickIcon = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClickBtnTitle() {
    this.clickIcon.emit();
  }

}
