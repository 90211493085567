export const environment = {
  production: false,
  api: {
    url: 'https://api-acceso-stg.analityco.com.co/',
    urlActualFront: 'http://v2.acceso-stg.analityco.com.co/',
    s3Bucket: "https://s3.amazonaws.com/analityco-acceso/",
    openIdParams:{
      client_id: "megadatosapi",
      client_secret: "9BBBEDA7-426E-4350-A3CC-6B8A2680BD57",
      scope: "openid roles profile email",
      grant_type:"password",
      remember:"false"
    },
    MapboxAccessToken: "pk.eyJ1IjoiaW5ub3ZhZGF0YSIsImEiOiJjajUwZjZwczQwZjZpMzJvN2ltMm8yaG4wIn0.llx-FNIzYV6YHepbW_uwsA"
  },
  pre_loading: [
    {item: 0},
    {item: 1},
  ]
};

