import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FechasService } from 'src/app/core/services/utils/fechas.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

export interface DialogData {
  message: string;
  confirm: boolean;
  btnConfirm?: boolean;
  btnCancel?: boolean;
  textBtnCancel: string;
  textBtnConfirm: string;
  question?: string;
  date?: string;
}

@Component({
  selector: 'app-dialog-select-date-disabled',
  templateUrl: './dialog-select-date-disabled.component.html',
  styleUrls: ['./dialog-select-date-disabled.component.scss']
})

export class DialogSelectDateDisabledComponent implements OnInit {
  btnConfirm: boolean = true;
  btnCancel: boolean = true;

  form: FormGroup;

  date = new Date();
  minDate = this.fechasService.getDateFormatDatePikerDATEHORANOWNotSecunt();
  
  constructor(
        public dialogRef: MatDialogRef<DialogSelectDateDisabledComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public fechasService: FechasService,
        private fb: FormBuilder,
    ) {
      if(data?.btnConfirm === false) {
        this.btnConfirm = data.btnConfirm;
      }
      if(data?.btnCancel === false) {
        this.btnCancel = data.btnCancel;
      }
     }

  onNoClick(): void {
    this.dialogRef.close(this.data.confirm = false);
  }

  changeDateFinishDateContractTime(value, boo) {

  }

  click() {
    this.data.confirm = true;
    this.data.date = this.form.get('date').value;
    this.dialogRef.close(this.data);
  }

  ngOnInit() { 
    this.form = this.fb.group({
      date: new FormControl(this.fechasService.getDateFormatDatePikerDATEHORANOWNotSecunt(),Validators.required),
    })
  }

}
