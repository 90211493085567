import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { CardList, FilterList } from 'src/app/core/interfaces/interfaces';
import { FilterMultipleService } from 'src/app/core/services/utils/filter-multiple.service';
import { environment } from 'src/environments/environment';

export interface DialogData {
  dataList: any;
  cardOne: CardList;
  cardTwo: CardList;
  path: string;
  title: string;
  dataOne: any;
  dataTwo: any;
}

@Component({
  selector: 'app-dialog-selected-vincular-contracts',
  templateUrl: './dialog-selected-vincular-contracts.component.html',
  styleUrls: ['./dialog-selected-vincular-contracts.component.scss']
})
export class DialogSelectedVincularContractsComponent implements OnInit {
  s3Bucket:string;
  production:boolean = environment.production;

  contracts: any[] = [];
  contractsSelected: any[] = [];
  
  list: any= [];
  listSlice: any= [];
  filterListValue: string = "";

  checkSelected = false;

  listShow: any[] = [];

  @ViewChild('paginatorList', {read: MatPaginator}) paginatorList: MatPaginator;

  filterList: FilterList[] = [
    { Value: '', Name: 'Todos', icon: '', count: null},
    { Value: 'IsActive', Name: 'Activos', icon: '', count: null},
    { Value: 'IsInactive', Name: 'Inactivos', icon: '', count: null}
  ];

  constructor(
    public dialogRef: MatDialogRef<DialogSelectedVincularContractsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    private filters: FilterMultipleService,
  ) { 
      data.dataOne 
      this.contracts = data.dataOne;
      this.arreglo = data.dataOne;
      this.listSlice = this.contracts.slice(0,32);

      let arrayNewContractModifica = []; 
      data.dataOne.forEach((x) => {
          arrayNewContractModifica.push({...x, ControlCheck: false});
      });
      this.listShow = arrayNewContractModifica;
  }

  ngOnInit(): void { }

  getImg(logo) {
    let img = (logo === null || logo === undefined ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg': logo); 
    return img;
  }

  personalId(Id) {
    this.router.navigate([this.data.path, Id]);
    this.close();
  }

  close() {
    this.dialogRef.close(undefined);
  }

  save(selectedAll) {
    this.dialogRef.close({contracts: this.contractsSelected, selectedAll: selectedAll});
  }

  filterValue: string = '';
  searchFilter(filterValue) {
    this.filterValue = filterValue.Value;
    this.searchFilterPagination(this.filterListValue);
  }

  arreglo: any[] = [];
  searchFilterPagination(changeFilter: string){ 
    const startIndex = this.paginatorList.pageIndex * this.paginatorList.pageSize;
    let endIndex = startIndex + this.paginatorList.pageSize;
    // console.log('filterValue1 :>> ', this.filterValue);
    if(this.filterListValue === '' && changeFilter === '' && this.filterValue === '' ) {
      // console.log('this.filterValue2 :>> ', this.filterValue);
      this.arreglo = this.list;
    }else { 
      // console.log('this.filterValue3 :>> ', this.filterValue);
      this.filterListValue = changeFilter;
      this.arreglo = this.getPersonalFilter(this.filterListValue, this.filterValue);
    }

    if(endIndex > this.arreglo.length) {
      endIndex = this.arreglo.length;
    }
    this.listSlice = this.arreglo.slice(startIndex, endIndex);
  }

  getPersonalFilter(value: string, filter?: string): any[] {
    if(value === '' && (filter === '' || filter === undefined)) {
        return this.list;
    } else if(filter === 'IsActive') {
        return this.list.filter(x => {
            return (this.filters.exitFilter(x[this.data.cardOne.titleFirst]+ ' ' + x.LastName, value) ||
                    this.filters.exitFilter(x.DocumentNumber, value)||
                    this.filters.exitFilter(x.DocumentType, value)) && 
                    (x.IsActive === true);
        });
    } else if(filter === 'IsInactive') {
        return this.list.filter(x => {
            return (this.filters.exitFilter(x.Name+ ' ' + x.LastName, value) ||
                    this.filters.exitFilter(x.DocumentNumber, value)||
                    this.filters.exitFilter(x.DocumentType, value)) && 
                    (x.IsActive === false);
        });
    } else  {
      return this.list.filter(x => {
        return (this.filters.exitFilter(x[this.data.cardOne.titleFirst]+ ' ' + (x[this.data.cardOne.titleSecond] === undefined ? '' : x[this.data.cardOne.titleSecond]), value) ||
                this.filters.exitFilter(x[this.data.cardOne.subTitle], value) ||
                this.filters.exitFilter(x[this.data.cardOne.itemValue], value));
        });
    } 
  }

  isSelected(item): boolean {
    let selected = false;
    let data = this.contractsSelected.find(x => x.Id === item.Id);
    data !== undefined ? selected = true: selected = false;
    return selected;
  }

  selectedItem(item, i) {
    this.listShow[i].ControlCheck = !this.listShow[i].ControlCheck;
    if(this.listShow[i].ControlCheck === true) {
      if(this.contractsSelected.length === 0) {
        this.contractsSelected.push(item);  
      } else {
        let data = this.contractsSelected.find(x => x.Id === item.Id);
        if(data === undefined) {
          this.contractsSelected.push(item);  
        }
      }
    } else {
      let index = this.contractsSelected.indexOf(x => x.Id === item.Id);
      this.contractsSelected.splice(index, 1);
    }
  }

}