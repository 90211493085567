<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'CREAR PROYECTO' || 'N/A'}}</div>
    </div>

    <div class="content-card" *ngIf="flagNext === false">
        <button class="uploadPhoto" [ngClass]="formDataPhoto === undefined ? 'borderBtn': ''" (click)="updatePhotoPersonal()" *ngIf="!imgCropperResult">
            <mat-icon>file_upload</mat-icon>
            <div>Cargar logotipo</div>
        </button>
        <div class="changeImg">
            <img style="margin-bottom: 10px;" *ngIf="imgCropperResult" [src]="imgCropperResult" alt="" width="100" height="100">&nbsp;&nbsp;&nbsp;
            <button class="uploadPhoto" [ngClass]="formDataPhoto === undefined ? 'borderBtn': ''" (click)="updatePhotoPersonal()" *ngIf="imgCropperResult">
                <div>Cambiar imagen</div>
            </button>
        </div>
        <form [formGroup]="form" autocomplete="off">
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>NOMBRE</strong></label>
                <input matInput type="text" formControlName="Name">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <div class="sub-header">
                <div class="sub-content">{{'UBICACIÓN'}}</div>
            </div>
            <br>
            <app-select-search class="input-field" [appearance]="'outline'"
                    [_dataSource]="daneCity" [textInput]="'CIUDAD'" [dataShow]="'CityName'" [dataValue]="'CityCode'" [siteCtrl]="form.get('CityCode')"
                    [inputType]="'text'">
            </app-select-search>
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>DIRECCIÓN</strong></label>
                <input matInput type="text" formControlName="Address">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <div class="sub-header">
                <div class="sub-content">{{'GEOCERCA'}}</div>
            </div>
            <br>
            <div class="map-container" >
                <app-mapbox-view  [ngClass]="imgCropperResult !== undefined ? 'map-mapbox-img': 'map-mapbox'" (clickOpcion1)="openDialogMap()" [_Type]="'create'" [_geoFenceJson]="GeoFenceJson" [permissionEdit]="exitPermission('projects.add')"></app-mapbox-view>
            </div>
        </form>
    </div>
    <div class="content-card" *ngIf="flagNext === true">
            <div class="sub-header">
                <div class="sub-content">{{'ETAPAS DEL PROYECTO'}}</div>
            </div>
            <form [formGroup]="formEtapas" *ngIf="flagEditEtapas === false" autocomplete="off">
                    <mat-form-field class="input-field" appearance="outline">
                        <label><strong>NOMBRE</strong></label>
                        <input matInput type="text" formControlName="Name">
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                    <mat-form-field class="input-field" appearance="outline">
                        <label><strong>RESEÑA</strong></label>
                        <textarea matInput formControlName="Review" maxlength="{{100}}"></textarea>
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                    <div class="text-inputExperiendt"><strong>Vigencia de la etapa en el proyecto</strong></div>
                    <div class="fields-group">
                        <div class="input-field-medium-date">
                            <mat-label>Inicio</mat-label>
                            <mat-icon (click)="pickerStart.open()">today</mat-icon>
                            <mat-form-field class="" appearance="outline">
                                <input matInput [matDatepicker]="pickerStart" formControlName="StartDate" (click)="pickerStart.open()">
                                <mat-datepicker #pickerStart></mat-datepicker>
                                <mat-error>Requerido</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="input-field-medium-date">
                            <mat-label>Fin</mat-label>
                            <mat-icon (click)="pickerFinist.open()">today</mat-icon>
                            <mat-form-field class="" appearance="outline">
                                <input matInput [matDatepicker]="pickerFinist" formControlName="FinishDate" (click)="pickerFinist.open()" [min]="minDate">
                                <mat-datepicker #pickerFinist></mat-datepicker>
                                <mat-error>Requerido</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="actions-dialog"style="margin: 3px;">
                        <button mat-button class="btn-cancelar" (click)="flagEditEtapas = true" type="button">CANCELAR</button>&nbsp;&nbsp;
                        <button mat-button type="button" class="btn-generar" (click)="guardarEtapa()">GUARDAR</button>
                    </div>
            </form>
            <div class="form-group-list" *ngIf="flagEditEtapas === true">
                <div class="add-usuario-vinculados" >
                    <div class="text-add-usuario">Crear etapa</div>
                    <button mat-mini-fab color="primary" type="button" matTooltipPosition="above" matTooltip="CREAR" (click)="addEtapa()" >
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <div class="card-user-viculado">
                    <app-card *ngFor="let stage of projectStageArray" style="margin-bottom: 4px;"
                        [dataAbove]="{iconStart:'',iconStartProyect: true, iconMedium:'today',iconOperation:'more_vert',textStart:'Etapa',textMedium:stage.FinishDate}"
                        [cardInformation]="true"
                        [title]="stage.Name"
                        [photo]="getImgEtapa(null)"
                        [item1]="stage.Review"
                        [itemDateIcon]="fechasService.getDateFormatR(stage.FinishDate)"
                        [opcionMenu3]="'Editar'"
                        [iconOpcionMenu3]="'edit'"
                        (clickOpcion3)="editarEtapaProject(stage)"
                        [opcionMenu4]="'Eliminar'"
                        [iconOpcionMenu4]="'delete'"
                        (clickOpcion4)="eliminarEtapa(stage)"
                        [_opcionMenu3Disabled]="true"
                        [_opcionMenu4Disabled]="true"
                        [_AllDisabled]="false"
                        >
                    </app-card>

                </div>
                <div *ngIf="projectStageArray.length === 0" style="margin: 10px;">No hay etapas creadas</div>
                <br>
            </div>
    </div>

    <div class="btn-actions"style="margin: 3px;"  *ngIf="flagNext === false">
        <button mat-button class="btn-cancelar" (click)="close()">{{'CANCELAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" type="button" (click)="next()">{{'SIGUIENTE'}}</button>
    </div>
    <div class="btn-actions"style="margin: 3px;"  *ngIf="flagNext === true && flagEditEtapas === true">
        <button mat-button class="btn-cancelar" (click)="flagNext = false">{{'ATRAS'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" type="button" (click)="save()">{{'CREAR PROYECTO'}}</button>
    </div>
</div>
