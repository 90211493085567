import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/modules/auth.service';
import { Company } from '../../models/Company';
import { environment } from 'src/environments/environment';
import { CommonMetodService } from '../../services/utils/commonMetod.service';

@Component({
  selector: 'app-select-admin',
  templateUrl: './select-admin.component.html',
  styleUrls: ['./select-admin.component.scss']
})
export class SelectAdminComponent implements OnInit {


  s3Bucket:string;
  selectedAmin: Company;
  companies: Company[];

  @Output() _click = new EventEmitter();

  constructor(private authService: AuthService, public commonMetod: CommonMetodService) { }

  ngOnInit(): void {
    this.s3Bucket=environment.api.s3Bucket;
    this.getUserAuth();
  }

  getUserAuth() {
    this.companies = this.authService.getUser()?.Companies;
    if(this.companies.length !== 0) {
      if(this.authService.getCompany() === null) {
        this.selectedAmin = this.companies[0];
      } else {
        this.selectedAmin = this.authService.getCompany();
      }
      this.click();
    }
  }

  selected(company) {
    if(this.selectedAmin !== company) {
      this.selectedAmin = company;
      localStorage.setItem('selectedAmin', JSON.stringify(this.selectedAmin));
      this._click.emit(this.selectedAmin);
    }
  }

  click(){
    this._click.emit(this.selectedAmin);
  }
}
