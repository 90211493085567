
import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpClient } from '@angular/common/http';
import { Contract } from '../../models/Contract/Contract';
import { Observable } from 'rxjs';
import { PersonalContract } from '../../models/Personal/PersonalContractProyect';
import { InfoPersonalCompanyPersonalId } from '../../models/Personal/InfoPersonalCompanyPersonalId';
import { ModelSaveCarnetPrint, ModelSaveCarnetPrintResponse } from '../../interfaces/interfaces';
import { PersonalInfoContract } from '../../models/Personal/personalInfoContract';
import { ContractsOffline } from '../../models/Personal/ContractsOffline';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

    constructor(
        private api: ApiService,
        private _http: HttpClient
    ) { }

    postCreateContract(modelo) {
        return this._http.post(this.api.getUrl(`api/Contract`),modelo);
    }

    postCreateContractPATCH(id, modelo) {
        return this._http.patch(this.api.getUrl(`api/Contract/${id}`),modelo);
    }

    getIsContractPersonalCompanyInfoId(ContracId, personalCompanyInfoId) {
        return this._http.get(this.api.getUrl(`api/Contract/${ContracId}/IsContract/${personalCompanyInfoId}`));
    }

    postMovePersonalInfoContract(ContracId, modelo) {
        return this._http.post(this.api.getUrl(`api/Contract/${ContracId}/MovePersonalInfo`),modelo);
    }

    //GET Contract
    getContract(): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl('api/Contract'));
    }

    //GET Contract filter
    getContractFilter(adminInfoId?, isActive?, ids?, self?): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Contract?${adminInfoId !== undefined ? 'adminInfoId='+adminInfoId:''}`));
    }

    patchContract(id, model: any[]) {
        return this._http.patch(this.api.getUrl(`api/Contract${id}`), model);
    }

    //GET:ID Contract
    getContractId(id): Observable<Contract>{
        return this._http.get<Contract>(this.api.getUrl('api/Contract/'+id));
    }

    getProjects( id ): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Contract/${id}/Project`));
    }

    getSubContractor( id ): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Contract/${id}/SubContractor`));
    }

    getContractUsers( id ): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Contract/${id}/Users`));
    }

    getContractPersonalInfo( id, parameter ): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Contract/${id}/PersonalInfo${parameter !== undefined ? '?blackList=true': ''}`));
    }

    getProyectsJoinCompany( id ): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Project?joinCompanyId=${id}`));
    }

    putPersonalFull(id, model) {
        return this._http.put(this.api.getUrl(`api/Personal/UpdatePersonalFull/${id}`), model);
    }

    getPersonalDocNumContractId(docNum, contractId) {
        return this._http.get(this.api.getUrl(`api/Personal/GetId/${docNum}/${contractId}`));
    }

    postPersonalInfoCompany(model) {
        return this._http.post(this.api.getUrl(`api/Personal/InfoCompany`), model);
    }

    postContractPersonalInfo(id, model) {
        return this._http.post(this.api.getUrl(`api/Contract/${id}/PersonalInfo`), model);
    }

  postVehicleContract(move, model) {
    return this._http.post(this.api.getUrl(`api/Vehicle/Contract/${move}`), model);
  }


    getContractsPersonal(personalCompanyInfoId): Observable<PersonalContract[]> {
        return this._http.get<PersonalContract[]>(this.api.getUrl('api/Personal/ContractsOffline?full=true&personalCompanyInfoId='+personalCompanyInfoId+'&v2=true'));
    }

    getInfoPersonalCompanyPersonalId(personalCompanyInfoId): Observable<InfoPersonalCompanyPersonalId> {
        return this._http.get<InfoPersonalCompanyPersonalId>(this.api.getUrl('api/Personal/GetInfoPersonalCompany?PersonalId='+personalCompanyInfoId+'&v2=true'));
    }

    // Save Print Carnet POST
    printSaveCarnet(printCarnet: ModelSaveCarnetPrint): Observable<ModelSaveCarnetPrintResponse> {
        return this._http.post<ModelSaveCarnetPrintResponse>(this.api.getUrl('api/Carnet'), printCarnet);
    }

    // Save Print Carnet PATCH
    printSaveCarnetPATCH(idCarnet: number) {
        return this._http.patch(this.api.getUrl(`api/Carnet/${idCarnet}`),  [{op: "replace", path: "/IsActive", value: "false"}]);
    }

    changeEpsAFpPATCH(id, path, value) {
        return this._http.patch(this.api.getUrl(`api/Person/${id}`),  [{op: "replace", path, value}]);
    }

    // get PersonalsInfo Contract
    getPersonalsInfoContract(contractId): Observable<PersonalInfoContract[]> {
        return this._http.get<PersonalInfoContract[]>(this.api.getUrl(`api/Contract/${contractId}/PersonalInfo`));
    }

    // get PersonalsInfo Contract
    updatePersonalsInfoContract(contractId, PersonalInfo, personalInfo: PersonalInfoContract) {
        return this._http.post(this.api.getUrl(`api/Contract/${contractId}/PersonalInfo?Id=${PersonalInfo}`), personalInfo);
    }

    // putPersonal con el document
    putUpdatePersonal(personalModel) {
        return this._http.put(this.api.getUrl(`api/Personal/UpdatePersonal`), personalModel);
    }


    updatePhotoCompanyInfo(id, file) {
        return this._http.put(this.api.getUrl(`api/Personal/CompanyInfo/UpdatePhoto/${id}`), file);
    }


    getContractsOfflinePersonalCompanyInfoId(personalCompanyInfoId) :Observable<ContractsOffline> {
        return this._http.get<ContractsOffline>(this.api.getUrl(`api/Personal/ContractsOffline?full=true&personalCompanyInfoId=${personalCompanyInfoId}`+'&v2=true'));
    }

    putEmployerPersonalRequirement(Id, model) {
        return this._http.put(this.api.getUrl(`api/EmployerPersonalRequirement/${Id}`), model);
    }

    putDELETEEmployerPersonalRequirement(Id, model) {
        return this._http.put(this.api.getUrl(`api/EmployerPersonalRequirement/DeleteFiles/${Id}`), model);
    }

    putUPDATEFILEEmployerPersonalRequirement(Id,file) {
        return this._http.put(this.api.getUrl(`api/EmployerPersonalRequirement/UpdateFile/${Id}`), file);
    }

    getContractType(): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Contract/GetContractType`));
    }

    getRolesCompany(id): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/Roles/Company/${id}?contract=true`));
    }

    getProjectStageEtapas(id): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/ProjectStage/Project/${id}`));
    }

    postContractProject(contractId, model, multipleEmployers?) {
        return this._http.post(this.api.getUrl(`api/Contract/${contractId}/Project${multipleEmployers !== undefined ? '?multipleEmployers=true' : ''}`), model);
    }

    postContractProjectId(contractId, Id, model, multipleEmployers, blockPersonalUpdate) {
        return this._http.post(this.api.getUrl(`api/Contract/${contractId}/Project?Id=${Id}${multipleEmployers !== undefined ? '&multipleEmployers=true' : ''}${blockPersonalUpdate !== undefined ? '&blockPersonalUpdate=false' : '&blockPersonalUpdate=true'}`), model);
    }

    postContractRequirement(contractId, model) {
        return this._http.post(this.api.getUrl(`api/Contract/${contractId}/Requirements`), model);
    }

    deleteContractRequirement(contractId) {
        return this._http.delete(this.api.getUrl(`api/Contract/${contractId}/Requirements`));
    }

    getCountContract(companyInfoId): Observable<any>{
        return this._http.get<any>(this.api.getUrl(`api/Contract/CountContract/${companyInfoId}`));
    }

    getFormImageContract(): Observable<any>{
        return this._http.get<any>(this.api.getUrl(`api/FormImage?Select=true`));
    }

    getContractJoinCompanies(companyInfoId): Observable<any>{
        return this._http.get<any>(this.api.getUrl(`api/Company/${companyInfoId}/JoinCompanies`));
    }

    getContractValidateExist(companyInfoId, ContractNumber): Observable<any>{
        return this._http.get<any>(this.api.getUrl(`api/Contract/Exist/${companyInfoId}/${ContractNumber}`));
    }

    patchChangeContract(id, model) {
        return this._http.patch<any>(this.api.getUrl(`api/Contract/${id}`), model);
    }

    deletePersonalInfoOfContract(contractId, PersonalInfo) {
        return this._http.delete(this.api.getUrl(`api/Contract/${contractId}/PersonalInfo/${PersonalInfo}`));
    }

    deleteProyectInContract(contractId, ProjectId) {
        return this._http.delete(this.api.getUrl(`api/Contract/${contractId}/Project/${ProjectId}`));
    }

    patchDisabledCarnetUser(carnetId) {
        return this._http.patch<any>(this.api.getUrl(`api/Carnet/${carnetId}`), [{op: "replace", path: "/IsActive", value: false}]);
    }

    putContractUpdateFile(ContractId,file) {
        return this._http.put(this.api.getUrl(`api/Contract/UpdateFile/${ContractId}`), file);
    }

    putContractDeleteFiles(ContractId,file) {
        return this._http.put(this.api.getUrl(`api/Contract/DeleteFiles/${ContractId}`), file);
    }

    getHasContractorProject(projectId,employerId) {
        return this._http.get(this.api.getUrl(`api/Project/HasContractor/${projectId}?employerId=${employerId}`));
    }

    getContractIds(ids) {
        return this._http.get(this.api.getUrl(`api/Contract/ByIds?Ids=${ids}`));
    }

    getPersonalBlackList(DocumentType, DocumentNumber, CompanyInfoId): Observable<boolean>{
    return this._http.get<boolean>(this.api.getUrl(`api/PersonalBlackList/ByDoc?DocumentType=${DocumentType}&DocumentNumber=${DocumentNumber}${CompanyInfoId === ''? '': '&adminInfoId='+CompanyInfoId}`));
    }

    getPositions() {
        return this._http.get(this.api.getUrl(`api/Position`));
    }


}
