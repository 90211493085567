import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { TitleComponent } from './components/title/title.component';
import { JobsViewComponent } from './components/jobs-view/jobs-view.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardComponent } from './components/card/card.component';
import { CardBaseComponent } from './components/card-base/card-base.component';
import { CardInformationComponent } from './components/card-information/card-information.component';
import { DialogPlanillaComponent } from './components/dialogs/dialog-planilla/dialog-planilla.component';
import { DialogConfirmComponent } from './components/dialogs/dialog-confirmation/dialog-confirm.component';
import { DialogImageOrVideoComponent } from './components/dialogs/dialog-image-video/dialog-image-video.component';
import { SymptomReportComponent } from './custom/symptom-report/symptom-report.component';
import { DialogOkComponent } from './components/dialogs/dialogs-encuesta/dialog-ok/dialog-ok.component';
import { DialogSelectPersonalCompanyInfoId } from './components/dialogs/dialogs-encuesta/dialog-personal-company-infoid/dialog-personal-company-infoid.component';
import { DialogCondicionesComponent } from './components/dialogs/dialogs-condicionesServicios/dialog-condiciones/dialog-condiciones.component';
import { ThousandsPipePipe } from './pipes/thousands-pipe.pipe';
import { CardsPersonalComponent } from './components/cards-modules/cards-personal/cards-personal.component';
import { DialogPrintPreCarnetComponent } from './components/dialogs/dialog-print-pre-carnet/dialog-print-pre-carnet.component';
import { PrintCarnetsComponent } from './custom/print-carnets/print-carnets.component';
import { DialogAdminAccesoComponent } from './components/dialogs/dialog-admin-acceso/dialog-admin-acceso.component';
import { DialogGestorImgComponent } from './components/dialogs/dialog-gestor-img/dialog-gestor-img.component';
import { TitleDialogComponent } from './components/titles/title-dialog/title-dialog.component';
import { FilterListComponent } from './components/filter-list/filter-list.component';
import { CardsContractingComponent } from './components/cards-modules/cards-contracting/cards-contracting.component';
import { DialogContratoIndividualComponent } from './components/dialogs/dialog-contrato-individual/dialog-contrato-individual.component';
import { DialogCreatePersonalComponent } from './components/dialogs/dialog-create-personal/dialog-create-personal.component';
import { NotPermissionsComponent } from './components/not-permissions/not-permissions.component';
import { DialogIndividualContractAprovedComponent } from './components/dialogs/dialog-individual-contract-aproved/dialog-individual-contract-aproved.component';
import { SelectSearchComponent } from './components/Controls/select-search/select-search.component';
import { CurrencyPipe } from '@angular/common';
import { DialogSearchEmployerComponent } from './components/dialogs/dialog-search-employer/dialog-search-employer.component';
import { DialogCreateEmployerComponent } from './components/dialogs/dialog-create-employer/dialog-create-employer.component';
import { DialogCreateNoveltyComponent } from './components/dialogs/dialog-create-novelty/dialog-create-novelty.component';
import { DialogSelectPeriodComponent } from './components/dialogs/dialog-select-period/dialog-select-period.component';
import { DialogLiquidacionesComponent } from './components/dialogs/dialog-liquidaciones/dialog-liquidaciones.component';
import { DetailsSurveySymptomsComponent } from './components/dialogs/details-survey-symptoms/details-survey-symptoms.component';
import { DialogSelectedItemComponent } from './components/dialogs/dialog-selected-item/dialog-selected-item.component';
import { DialogCreatePersonInfoCompanyComponent } from './components/dialogs/dialog-create-person-info-company/dialog-create-person-info-company.component';
import { DialogEnrolarPersonalWithContractComponent } from './components/dialogs/dialog-enrolar-personal-with-contract/dialog-enrolar-personal-with-contract.component';
import { DialogMovePersonalInfoComponent } from './components/dialogs/dialog-move-personal-info/dialog-move-personal-info.component';
import { DialogListComponent } from './components/dialogs/dialog-list/dialog-list.component';
import { DialogContractListComponent } from './components/dialogs/dialog-contract-list/dialog-contract-list.component';
import { CardTitleComponent } from './components/card-title/card-title.component';
import { DialogCrudSucursalesComponent } from './components/dialogs/dialog-crud-sucursales/dialog-crud-sucursales.component';
import { DialogChangeOneFileComponent } from './components/dialogs/dialog-change-one-file/dialog-change-one-file.component';
import { DisabledControlsDirective } from './services/directive/disabled-controls.directive';
import { DialogPollComponent } from './components/dialogs/dialog-poll/dialog-poll.component';
import { DialogEmailSendComponent } from './components/dialogs/dialog-email-send/dialog-email-send.component';
import { DialogCreateContractComponent } from './components/dialogs/dialog-create-contract/dialog-create-contract.component';
import { DialogRequirementsComponent } from './components/dialogs/dialog-requirements/dialog-requirements.component';
import { DialogHoursAllowedComponent } from './components/dialogs/dialog-hours-allowed/dialog-hours-allowed.component';
import { DialogSelectedControlSearchListComponent } from './components/dialogs/dialog-selected-control-search-list/dialog-selected-control-search-list.component';
import { DialogPersonAssociateContractsComponent } from './components/dialogs/dialog-person-associate-contracts/dialog-person-associate-contracts.component';
import { DialogMapComponent } from './components/dialogs/dialog-map/dialog-map.component';
import { MapboxViewComponent } from './components/mapbox-view/mapbox-view.component';
import { DialogSelectedCausalComponent } from './components/dialogs/dialog-selected-causal/dialog-selected-causal.component';
import { DialogUpdatePasswordComponent } from './components/dialogs/dialog-update-password/dialog-update-password.component';
import { DialogAdminAccesoPersonalComponent } from './components/dialogs/dialog-admin-acceso-personal/dialog-admin-acceso-personal.component';
import { DialogConfirmImgComponent } from './components/dialogs/dialog-confirm-img/dialog-confirm-img.component';
import { DialogCRUDPermissionComponent } from './components/dialogs/dialog-crudpermission/dialog-crudpermission.component';
import { DialogPerfilesComponent } from './components/dialogs/dialog-perfiles/dialog-perfiles.component';
import { DialogCommonOptionsComponent } from './components/dialogs/dialog-common-options/dialog-common-options.component';
import { DialogRequirementRefenceComponent } from './components/dialogs/dialog-requirement-refence/dialog-requirement-refence.component';
import { DialogEconomicActivityComponent } from './components/dialogs/dialog-economic-activity/dialog-economic-activity.component';
import { DialogSecurityReferenceComponent } from './components/dialogs/dialog-security-reference/dialog-security-reference.component';
import { DialogFormImageCrudComponent } from './components/dialogs/dialog-form-image-crud/dialog-form-image-crud.component';
import { DialogCreateProjectComponent } from './components/dialogs/dialog-create-project/dialog-create-project.component';
import { DialogOccupationComponent } from './components/dialogs/dialog-occupation/dialog-occupation.component';
import { DialogSocialSecRangeComponent } from './components/dialogs/dialog-social-sec-range/dialog-social-sec-range.component';
import { DialogCreateVehicleComponent } from './components/dialogs/dialog-create-vehicle/dialog-create-vehicle.component';
import { DialogTimeWorkProjectComponent } from './components/dialogs/dialog-time-work-project/dialog-time-work-project.component';
import { DialogDocumentCommentInputAndOutputComponent } from './components/dialogs/dialog-document-comment-input-and-output/dialog-document-comment-input-and-output.component';
import { DialogSupplyGroupComponent } from './components/dialogs/dialog-supply-group/dialog-supply-group.component';
import { DialogSupplyComponent } from './components/dialogs/dialog-supply/dialog-supply.component';
import { CustomPageComponent } from './custom/custom-page/custom-page.component';
import { DialogCarouselComponent } from './components/dialogs/dialog-carousel/dialog-carousel.component';
import { DialogSelectDateDisabledComponent } from './components/dialogs/dialog-select-date-disabled/dialog-select-date-disabled.component';
import { DialogRequirementEmployerComponent } from './components/dialogs/dialog-requirement-employer/dialog-requirement-employer.component';
import { DialogCreateContractLaborComponent } from './components/dialogs/dialog-create-contract-labor/dialog-create-contract-labor.component';
import { DialogSelectedVincularContractsComponent } from './components/dialogs/dialog-selected-vincular-contracts/dialog-selected-vincular-contracts.component';
import { JobsViewManualComponent } from './components/jobs-view-manual/jobs-view-manual.component';
import { ImgComponent } from './components/img-component/img.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { DialogGestorPlanillasComponent } from './components/dialogs/dialog-gestor-planillas/dialog-gestor-planillas.component';
import { DialogPayrollRulesComponent } from './components/dialogs/dialog-payroll-rules/dialog-payroll-rules.component';
import { DialogWorkShiftsComponent } from './components/dialogs/dialog-work-shifts/dialog-work-shifts.component';
import { DialogNoveltyComponent } from './components/dialogs/dialog-novelty/dialog-novelty.component';
import { DialogCreateNoveltyDialogComponent } from './components/dialogs/dialog-create-novelty-dialog/dialog-create-novelty-dialog.component';
import { DialogCreatePrenominaComponent } from './components/dialogs/dialog-create-prenomina/dialog-create-prenomina.component';
import { TitleCardPreviewComponent } from './components/titles/title-card-preview/title-card-preview.component';
import { DialogCreateTerminationComponent } from './components/dialogs/dialog-create-termination/dialog-create-termination.component';
import { DialogConfirmCodeLoginComponent } from './components/dialogs/dialog-confirm-code-login/dialog-confirm-code-login.component';
import { DialogGestorDocumentsComponent } from './components/dialogs/dialog-gestor-documents/dialog-gestor-documents.component';
import { SelectAdminComponent } from './components/select-admin/select-admin.component';
import { DialogAddPersonalSocialSegurityComponent } from './components/dialogs/dialog-add-personal-social-segurity/dialog-add-personal-social-segurity.component';
import { DialogSearchAdminComponent } from './components/dialogs/dialog-search-admin/dialog-search-admin.component';
import { DialogContractAddProjectComponent } from './components/dialogs/dialog-contract-add-project/dialog-contract-add-project.component';
import { DialogProjectAddComponent } from './components/dialogs/dialog-project-add/dialog-project-add.component';
import { DialogContractAddTransferPersonnelComponent } from './components/dialogs/dialog-contract-add-transfer-personnel/dialog-contract-add-transfer-personnel.component';
import { DialogCargeMasivoComponent } from './components/dialogs/dialog-carge-masivo/dialog-carge-masivo.component';
import {MatRadioModule} from '@angular/material/radio';
import { SelectSearchCustomComponent } from './components/Controls/select-search-custom/select-search-custom.component';
import { DialogEditMinutesComponent } from './components/dialogs/dialog-edit-minutes/dialog-edit-minutes.component';
import { DialogGestorImgPersonalComponent } from './components/dialogs/dialog-gestor-img-personal/dialog-gestor-img-personal.component';
import { DialogSelectedControlWithdrawalsComponent } from './components/dialogs/dialog-selected-control-withdrawals/dialog-selected-control-withdrawals.component';

@NgModule({
  declarations: [
    HeaderComponent,
    TitleComponent,
    JobsViewComponent,
    CardComponent,
    CardBaseComponent,
    CardInformationComponent,
    CardsPersonalComponent,
    DialogPlanillaComponent,
    DialogConfirmComponent,
    DialogImageOrVideoComponent,
    SymptomReportComponent,
    DialogOkComponent,
    DialogSelectPersonalCompanyInfoId,
    DialogCondicionesComponent,
    ThousandsPipePipe,
    DialogPrintPreCarnetComponent,
    PrintCarnetsComponent,
    DialogAdminAccesoComponent,
    DialogGestorImgComponent,
    TitleDialogComponent,
    FilterListComponent,
    CardsContractingComponent,
    DialogContratoIndividualComponent,
    DialogCreatePersonalComponent,
    NotPermissionsComponent,
    DialogIndividualContractAprovedComponent,
    SelectSearchComponent,
    DialogSearchEmployerComponent,
    DialogCreateEmployerComponent,
    DialogCreateNoveltyComponent,
    DialogSelectPeriodComponent,
    DialogLiquidacionesComponent,
    DetailsSurveySymptomsComponent,
    DialogSelectedItemComponent,
    DialogCreatePersonInfoCompanyComponent,
    DialogEnrolarPersonalWithContractComponent,
    DialogMovePersonalInfoComponent,
    DialogListComponent,
    DialogContractListComponent,
    CardTitleComponent,
    DialogCrudSucursalesComponent,
    DialogChangeOneFileComponent,
    DisabledControlsDirective,
    DialogPollComponent,
    DialogEmailSendComponent,
    DialogCreateContractComponent,
    DialogRequirementsComponent,
    DialogHoursAllowedComponent,
    DialogSelectedControlSearchListComponent,
    DialogPersonAssociateContractsComponent,
    DialogMapComponent,
    MapboxViewComponent,
    DialogSelectedCausalComponent,
    DialogUpdatePasswordComponent,
    DialogAdminAccesoPersonalComponent,
    DialogConfirmImgComponent,
    DialogPerfilesComponent,
    DialogCRUDPermissionComponent,
    DialogCommonOptionsComponent,
    DialogRequirementRefenceComponent,
    DialogEconomicActivityComponent,
    DialogSecurityReferenceComponent,
    DialogFormImageCrudComponent,
    DialogCreateProjectComponent,
    DialogOccupationComponent,
    DialogSocialSecRangeComponent,
    DialogCreateVehicleComponent,
    DialogTimeWorkProjectComponent,
    DialogDocumentCommentInputAndOutputComponent,
    DialogSupplyGroupComponent,
    DialogSupplyComponent,
    CustomPageComponent,
    DialogCarouselComponent,
    DialogSelectDateDisabledComponent,
    DialogRequirementEmployerComponent,
    DialogCreateContractLaborComponent,
    DialogSelectedVincularContractsComponent,
    JobsViewManualComponent,
    ImgComponent,
    PaginatorComponent,
    DialogGestorPlanillasComponent,
    DialogPayrollRulesComponent,
    DialogGestorPlanillasComponent,
    PaginatorComponent,
    DialogWorkShiftsComponent,
    DialogNoveltyComponent,
    DialogCreateNoveltyDialogComponent,
    DialogCreatePrenominaComponent,
    TitleCardPreviewComponent,
    DialogCreateTerminationComponent,
    DialogConfirmCodeLoginComponent,
    DialogGestorDocumentsComponent,
    SelectAdminComponent,
    DialogAddPersonalSocialSegurityComponent,
    DialogSearchAdminComponent,
    DialogContractAddProjectComponent,
    DialogProjectAddComponent,
    DialogContractAddTransferPersonnelComponent,
    DialogCargeMasivoComponent,
    SelectSearchCustomComponent,
    DialogEditMinutesComponent,
    DialogGestorImgPersonalComponent,
    DialogSelectedControlWithdrawalsComponent,
  ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        MatRadioModule,
        // PdfViewerModule
    ],
  exports: [
    HeaderComponent,
    TitleComponent,
    JobsViewComponent,
    JobsViewManualComponent,
    CardComponent,
    CardBaseComponent,
    CardInformationComponent,
    CardsPersonalComponent,
    CardsContractingComponent,
    DialogPlanillaComponent,
    DialogConfirmComponent,
    DialogImageOrVideoComponent,
    DialogOkComponent,
    DialogSelectPersonalCompanyInfoId,
    DialogContratoIndividualComponent,
    FilterListComponent,
    SelectSearchComponent,
    DialogContractListComponent,
    CardTitleComponent,
    MapboxViewComponent,
    PaginatorComponent,
    ImgComponent,
    TitleCardPreviewComponent,
    SelectAdminComponent,
    DisabledControlsDirective,
    SelectSearchCustomComponent,
    // PdfViewerModule
  ],
  providers: [CurrencyPipe]
})
export class CoreModule { }
