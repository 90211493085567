import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  dataList: any;
  title: string;
}


@Component({
  selector: 'app-dialog-email-send',
  templateUrl: './dialog-email-send.component.html',
  styleUrls: ['./dialog-email-send.component.scss']
})
export class DialogEmailSendComponent implements OnInit {

  valueInput: string = ''
  sendList: string[] = [];
  constructor(public dialogRef: MatDialogRef<DialogEmailSendComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.sendList = data.dataList;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  _send() {
    this.dialogRef.close(this.sendList);
  }

  addItemList(item) {
    this.sendList.push(item);
    this.valueInput = '';
  }

  deleteItemList(index) {
    this.sendList.splice(index, 1);
  }

}
