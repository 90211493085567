import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PayrollConfiguration } from 'src/app/core/interfaces/interfaces';
import { EmployerService } from 'src/app/core/services/modules/employer.service';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';

export interface DialogData {
  title: string;
  payrollConfiguration: PayrollConfiguration;
  CompanyInfoId: string;
}

@Component({
  selector: 'app-dialog-payroll-rules',
  templateUrl: './dialog-payroll-rules.component.html',
  styleUrls: ['./dialog-payroll-rules.component.scss']
})
export class DialogPayrollRulesComponent implements OnInit {

  formRulesPayroll: FormGroup;

  time = [];

  listYesOrNot = [
    {Name: 'SI', Id: true},
    {Name: 'NO', Id: false}
  ];

  listHora = [
    {Name: 'Sin redondeo', Id: 0},
    {Name: '1/4 Hora', Id: 0.25},
    {Name: '1/2 Hora', Id: 0.5},
    {Name: '1 Hora', Id: 1},
  ];

  constructor(private dialogRef: MatDialogRef<DialogPayrollRulesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private employerService: EmployerService,
              private snackBarService: SnackBarService,
              private fb: FormBuilder) {
    this.formRulesPayroll = this.fb.group({
      CompanyInfoId: new FormControl(this.data.CompanyInfoId, Validators.required),

      SlackTimeL: new FormControl('00', Validators.required),
      SlackTimeR: new FormControl('00', Validators.required),
      ExtraTimeL: new FormControl('00', Validators.required),
      ExtraTimeR: new FormControl('00', Validators.required),
      IsRoundDownStartHour: new FormControl(true, Validators.required),
      RoundUpEarlyEntries: new FormControl(false, Validators.required),
      SetEarlyEntriesToStartTime: new FormControl(false, Validators.required),
      SetLateLeavesToLeaveTime: new FormControl(false, Validators.required),
      IsRoundDownStartHourSlackTime: new FormControl(false, Validators.required),
      RoundUpStartHourSlackTime: new FormControl(0, Validators.required),
      IsRoundDownEndHour: new FormControl(false, Validators.required),
      IsRoundDownEndHourSlackTime: new FormControl(false, Validators.required),
      RoundUpStartEndSlackTime: new FormControl(0, Validators.required),
      ExtraTimeDiscountL: new FormControl('00', Validators.required),
      ExtraTimeDiscountR: new FormControl('00', Validators.required),
      DiscountL: new FormControl('00', Validators.required),
      DiscountR: new FormControl('00', Validators.required),
      RoundUpTo: new FormControl(0, Validators.required),
      RoundDownTo: new FormControl(0, Validators.required),
      RoundUpExtraTime: new FormControl(0, Validators.required)

    });
    if (this.data.payrollConfiguration !== undefined) {
      this.formRulesPayroll.get('SlackTimeL').setValue(this.data.payrollConfiguration.SlackTime.split(':')[0]);
      this.formRulesPayroll.get('SlackTimeR').setValue(this.data.payrollConfiguration.SlackTime.split(':')[1]);
      this.formRulesPayroll.get('ExtraTimeL').setValue(this.data.payrollConfiguration.ExtraTime.split(':')[0]);
      this.formRulesPayroll.get('ExtraTimeR').setValue(this.data.payrollConfiguration.ExtraTime.split(':')[1]);
      this.formRulesPayroll.get('SetEarlyEntriesToStartTime').setValue
      (this.data.payrollConfiguration.RoundStartHour.SetEarlyEntriesToStartTime);
      this.formRulesPayroll.get('RoundUpEarlyEntries').setValue(this.data.payrollConfiguration.RoundStartHour.RoundUpEarlyEntries);
      this.formRulesPayroll.get('IsRoundDownStartHourSlackTime').setValue(
        this.data.payrollConfiguration.RoundStartHour.IsRoundDownStartHourSlackTime);
      this.formRulesPayroll.get('RoundUpStartHourSlackTime').setValue(
        this.data.payrollConfiguration.RoundStartHour.RoundUpStartHourSlackTime);
      this.formRulesPayroll.get('IsRoundDownEndHourSlackTime').setValue(
        this.data.payrollConfiguration.RoundEndHour.IsRoundDownEndHourSlackTime);
      this.formRulesPayroll.get('SetLateLeavesToLeaveTime').setValue(this.data.payrollConfiguration.RoundEndHour.SetLateLeavesToLeaveTime);
      this.formRulesPayroll.get('RoundUpStartEndSlackTime').setValue(this.data.payrollConfiguration.RoundEndHour.RoundUpStartEndSlackTime);
      this.formRulesPayroll.get('RoundUpExtraTime').setValue(this.data.payrollConfiguration.RoundUpExtraTime);
      this.formRulesPayroll.get('ExtraTimeDiscountL').setValue(this.data.payrollConfiguration.ExtraTimeDiscount.split(':')[0]);
      this.formRulesPayroll.get('ExtraTimeDiscountR').setValue(this.data.payrollConfiguration.ExtraTimeDiscount.split(':')[1]);
      this.formRulesPayroll.get('IsRoundDownStartHour').setValue(this.data.payrollConfiguration.RoundStartHour.IsRoundDownStartHour);
      this.formRulesPayroll.get('IsRoundDownEndHour').setValue(this.data.payrollConfiguration.RoundEndHour.IsRoundDownEndHour);
      this.formRulesPayroll.get('RoundUpTo').setValue(this.data.payrollConfiguration.RoundEntriesWithoutSchedule.RoundUpTo);
      this.formRulesPayroll.get('RoundDownTo').setValue(this.data.payrollConfiguration.RoundLeavesWithoutSchedule.RoundDownTo);
      this.formRulesPayroll.get('DiscountL').setValue(this.data.payrollConfiguration.RoundLeavesWithoutSchedule.Discount.split(':')[0]);
      this.formRulesPayroll.get('DiscountR').setValue(this.data.payrollConfiguration.RoundLeavesWithoutSchedule.Discount.split(':')[1]);
    }
    for (let i = 0; i < 60; i++) {
      this.time.push(i < 10 ? '0' + i : i);
    }
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeIsRoundDownStartHour(value) {
    if (value === true) {
      this.formRulesPayroll.get('RoundUpEarlyEntries').setValue(0);
    }
  }

  save() {
    const model = {
      CompanyInfoId: this.formRulesPayroll.get('CompanyInfoId').value,
      SlackTime: this.formRulesPayroll.get('SlackTimeR').value,
      ExtraTime: this.formRulesPayroll.get('ExtraTimeR').value,
      RoundStartHour: {
          IsRoundDownStartHour: this.formRulesPayroll.get('IsRoundDownStartHour').value,
          IsRoundDownStartHourSlackTime: this.formRulesPayroll.get('IsRoundDownStartHourSlackTime').value,
          RoundUpStartHourSlackTime: this.formRulesPayroll.get('RoundUpStartHourSlackTime').value,
          RoundUpEarlyEntries: this.formRulesPayroll.get('RoundUpEarlyEntries').value ?? 0,
          SetEarlyEntriesToStartTime: this.formRulesPayroll.get('SetEarlyEntriesToStartTime').value,
      },
      RoundEndHour: {
          IsRoundDownEndHour: this.formRulesPayroll.get('IsRoundDownEndHour').value,
          IsRoundDownEndHourSlackTime: this.formRulesPayroll.get('IsRoundDownEndHourSlackTime').value,
          RoundUpStartEndSlackTime: this.formRulesPayroll.get('RoundUpStartEndSlackTime').value,
          SetLateLeavesToLeaveTime: this.formRulesPayroll.get('SetLateLeavesToLeaveTime').value,
      },
      RoundEntriesWithoutSchedule: {
        RoundUpTo: this.formRulesPayroll.get('RoundUpTo').value,
      },
      RoundLeavesWithoutSchedule: {
        RoundDownTo: this.formRulesPayroll.get('RoundDownTo').value,
        Discount: this.formRulesPayroll.get('DiscountR').value,
      },
      ExtraTimeDiscount: this.formRulesPayroll.get('ExtraTimeDiscountR').value,
      RoundUpExtraTime: this.formRulesPayroll.get('RoundUpExtraTime').value,
    };

    if (this.data.payrollConfiguration === undefined) {
      if (this.formRulesPayroll.status === 'VALID') {
        this.employerService.postPayrollConfigurationEmployer(model).subscribe(rest => {
          this.snackBarService.openSnackBar('Configuración guardada', 'X', 4000);
          this.dialogRef.close();
        });
      } else {
        this.snackBarService.openSnackBar('Completa la imformación requerida', 'X', 4000);
        this.formRulesPayroll.markAllAsTouched();
      }
    } else {
      if (this.formRulesPayroll.status === 'VALID') {
        this.employerService.putPayrollConfigurationEmployer(this.data.payrollConfiguration.Id, model).subscribe(rest => {
          this.snackBarService.openSnackBar('Configuración actualizada', 'X', 4000);
          this.dialogRef.close();
        });
      } else {
        this.snackBarService.openSnackBar('Completa la imformación requerida', 'X', 4000);
        this.formRulesPayroll.markAllAsTouched();
      }
    }
  }
}
