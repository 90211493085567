import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { timer } from 'rxjs';
import { AuthService } from 'src/app/core/services/modules/auth.service';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';
export interface DialogData {
  email, password, token
}

@Component({
  selector: 'app-dialog-confirm-code-login',
  templateUrl: './dialog-confirm-code-login.component.html',
  styleUrls: ['./dialog-confirm-code-login.component.scss']
})
export class DialogConfirmCodeLoginComponent implements OnInit {

  tiempo: number = 100;
  source = timer(0, 1000);
  // 300 son 5 Minutos
  seconds = 300;

  flagTimeVencido = false;

  code = '';

  tokenNew;

  constructor(
        public dialogRef: MatDialogRef<DialogConfirmCodeLoginComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private authService: AuthService,
        private snackBService: SnackBarService
    ) {  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  send() {
    this.dialogRef.close({code: this.code, token: this.tokenNew});
  }

  generateNewCode() {
    if(this.flagTimeVencido === true) {
      this.tiempo = 100;
      this.source = timer(0, 1000);
      this.seconds = 300;
      this.flagTimeVencido = false;
      this.resetCode();
    }
  }

  ngOnInit() {
    this.source.subscribe(t => {
        this.seconds += -1;
        this.segundosASegundosMinutosYHoras(this.seconds);
      if(this.tiempo !== 0) {
        this.tiempo += -0.33;
      } 
      if(this.seconds === 0) {
        this.flagTimeVencido = true;
      }
    });
   }

   horas;
   segundosASegundosMinutosYHoras(tiempoSegundos:any) {
    let restoHora = tiempoSegundos % 3600;
    let Hora = tiempoSegundos / 3600;
    let Minuto: number = restoHora / 60;
    let Segundo: number = restoHora % 60;
    this.horas = Math.trunc(Minuto)+':'+(Segundo<10? '0'+Segundo: Segundo );

  }

  
   resetCode() {
    this.authService.getToken(this.data.email, this.data.password)
    .subscribe((resp) => { },
      error => {
        if(error.status === 455) {
          this.snackBService.openSnackBar('Correo enviado correctamente!', 'X', 3000);
          this.tokenNew = error.error.token;
        }
      }
    );
   }

}
