<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'RANGO FECHAS DE SEGURIDAD SOCIAL' || 'N/A'}}</div>
    </div>
    
    <div class="content-card list-card" autocomplete="off">
        <form [formGroup]="form" autocomplete="off">
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>AÑO</strong></label>
                <input matInput type="number" formControlName="Year" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>MINIMO NÚMERO NIT</strong></label>
                <input matInput type="number" formControlName="Min" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>MÁXIMO NÚMERO NIT</strong></label>
                <input matInput type="number" formControlName="Max" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>DIA</strong></label>
                <input matInput type="number" formControlName="Day" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
        </form>
    </div>

    <div class="btn-actions"style="margin: 3px;">
        <button mat-button class="btn-cancelar" (click)="close()">{{'CANCELAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" (click)="save()">{{data.Id !== undefined ? 'ACTUALIZAR': 'GUARDAR'}}</button>
    </div>
</div>
