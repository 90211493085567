

import { FormControl, FormGroup, Validators } from '@angular/forms';

export class    PersonalReport {
    EventId: number;
    Comments: any[]
    ContractId: string;
    ContractNumber: string;
    DocsJSON: string;
    EditedEntry: boolean;
    EditedLeave: boolean;
    EntryEventId: number;
    EntryReportId: number;
    EntrySchedule: string;
    EntryTime: string;
    File: string;
    Function: string;
    LeaveEventId: number;
    LeaveReportId: number;
    LeaveSchedule: string;
    LeaveTime: string;
    PersonalCompanyInfoId: number;
    PersonalName: string;
    Photo: string;
    ProjectId: string;
    ProjectName: string;
    DocumentType: string;
    DocumentNumber: string;
    update: boolean;
    Position: string;
    ScheduledEntry: string;
    ScheduledLeave: string;
    Users: string;
    Comment: '';
    Projects: any;
    NoveltyIsOpen: boolean;
    DayOff: boolean;
    IsHolyDay: boolean;
    LeaveTimeManuallyEdited: boolean;
    EntryTimeManuallyEdited: boolean;
    CalculatedEntryTime: string;
    CalculatedLeaveTime: string;
    NoveltyComments: any[];
}

export class PersonalReportArray {
    NoveltyComments: any[];
    EventId: number;
    Comments: any[];
    Comment: '';
    ContractId: string;
    ContractNumber: string;
    DocsJSON: string;
    EditedEntry: boolean;
    EditedLeave: boolean;
    EntryEventId: number;
    EntryReportId: number;
    EntrySchedule: string;
    EntryTime: string;
    File: string;
    Function: string;
    LeaveEventId: number;
    LeaveReportId: number;
    LeaveSchedule: string;
    LeaveTime: string;
    PersonalCompanyInfoId: number;
    PersonalName: string;
    Name: string;
    LastName: string;
    Photo: string;
    ProjectId: string;
    ProjectName: string;
    DocumentType: string;
    DocumentNumber: string;
    update: boolean;
    updateNovelty: boolean;
    Position: string;
    ScheduledEntry: string;
    ScheduledLeave: string;
    Users: string;
    Projects: any;
    ScheduleType: string;
    Id: number;
    WorkShiftName: string;
    OpenPeriod: boolean;
    NoveltyEndDate: string;
    NoveltyId: string;
    NoveltyStartDate: string;
    NoveltyTypeAbrv: string;
    NoveltyTypeId: string;
    NoveltyTypeName: string;
    NoveltyDocsJSON: string;
    ScheduledEntryDayDetailed: string;
    ScheduledLeaveDayDetailed: string;
    EmployerInfoId: string;
    PlaceOfWorkId: string;
    EntryUsers: string;
    EntryProjects: string;
    LeaveUsers: string;
    LeaveProjects: string;
    NoveltyIsOpen: boolean;
    DayOff: boolean;
    IsHolyDay: boolean;
    Date: string;
    LeaveTimeManuallyEdited: boolean;
    EntryTimeManuallyEdited: boolean;
    CalculatedEntryTime: string;
    CalculatedLeaveTime: string;

    static asFormGroup(personal): FormGroup {
        const fg = new FormGroup({
            NoveltyComments: new FormControl(personal.NoveltyComments, Validators.required),
            CalculatedEntryTime: new FormControl(personal.CalculatedEntryTime, Validators.required),
            CalculatedLeaveTime: new FormControl(personal.CalculatedLeaveTime, Validators.required),
            LeaveTimeManuallyEdited: new FormControl(personal.LeaveTimeManuallyEdited, Validators.required),
            EntryTimeManuallyEdited: new FormControl(personal.EntryTimeManuallyEdited, Validators.required),
            Date: new FormControl(personal.Date, Validators.required),
            DayOff: new FormControl(personal.DayOff, Validators.required),
            IsHolyDay: new FormControl(personal.IsHolyDay, Validators.required),
            EntryUsers: new FormControl(personal.EntryUsers, Validators.required),
            NoveltyIsOpen: new FormControl(personal.NoveltyIsOpen, Validators.required),
            EntryProjects: new FormControl(personal.EntryProjects, Validators.required),
            LeaveUsers: new FormControl(personal.LeaveUsers, Validators.required),
            LeaveProjects: new FormControl(personal.LeaveProjects, Validators.required),
            Projects: new FormControl(personal.Projects, Validators.required),
            EmployerInfoId: new FormControl(personal.EmployerInfoId, Validators.required),
            PlaceOfWorkId: new FormControl(personal.PlaceOfWorkId, Validators.required),
            ScheduledEntryDayDetailed: new FormControl(personal.ScheduledEntryDayDetailed, Validators.required),
            ScheduledLeaveDayDetailed: new FormControl(personal.ScheduledLeaveDayDetailed, Validators.required),
            NoveltyEndDate: new FormControl(personal.NoveltyEndDate, Validators.required),
            NoveltyId: new FormControl(personal.NoveltyId, Validators.required),
            NoveltyStartDate: new FormControl(personal.NoveltyStartDate, Validators.required),
            NoveltyTypeAbrv: new FormControl(personal.NoveltyTypeAbrv, Validators.required),
            NoveltyTypeId: new FormControl(personal.NoveltyTypeId, Validators.required),
            NoveltyTypeName: new FormControl(personal.NoveltyTypeName, Validators.required),
            Users: new FormControl(personal.Users, Validators.required),
            WorkShiftName: new FormControl(personal.WorkShiftName, Validators.required),
            OpenPeriod: new FormControl(personal.OpenPeriod, Validators.required),
            Id: new FormControl(personal.Id, Validators.required),
            ScheduleType: new FormControl(personal.ScheduleType, Validators.required),
            ScheduledEntry: new FormControl(personal.ScheduledEntry, Validators.required),
            ScheduledLeave: new FormControl(personal.ScheduledLeave, Validators.required),
            EventId: new FormControl(personal.EventId, Validators.required),
            Position: new FormControl(personal.Position, Validators.required),
            Comments: new FormControl(personal.Comments, Validators.required),
            Comment: new FormControl(personal.Comment, Validators.required),
            ContractId: new FormControl(personal.ContractId, Validators.required),
            ContractNumber: new FormControl(personal.ContractNumber, Validators.required),
            DocsJSON: new FormControl(personal.DocsJSON, Validators.required),
            NoveltyDocsJSON: new FormControl(personal.NoveltyDocsJSON, Validators.required),
            EditedEntry: new FormControl(personal.EditedEntry, Validators.required),
            EditedLeave: new FormControl(personal.EditedLeave, Validators.required),
            EntryEventId: new FormControl(personal.EntryEventId, Validators.required),
            EntryReportId: new FormControl(personal.EntryReportId, Validators.required),
            EntrySchedule: new FormControl(personal.EntrySchedule, Validators.required),
            EntryTime: new FormControl(personal.EntryTime, Validators.required),
            File: new FormControl(personal.File, Validators.required),
            Function: new FormControl(personal.Function, Validators.required),
            LeaveEventId: new FormControl(personal.LeaveEventId, Validators.required),
            LeaveReportId: new FormControl(personal.LeaveReportId, Validators.required),
            LeaveSchedule: new FormControl(personal.LeaveSchedule, Validators.required),
            LeaveTime: new FormControl(personal.LeaveTime, Validators.required),
            PersonalCompanyInfoId: new FormControl(personal.PersonalCompanyInfoId, Validators.required),
            PersonalName: new FormControl(personal.PersonalName, Validators.required),
            Name: new FormControl(personal.Name, Validators.required),
            LastName: new FormControl(personal.LastName, Validators.required),
            Photo: new FormControl(personal.Photo, Validators.required),
            ProjectId: new FormControl(personal.ProjectId, Validators.required),
            ProjectName: new FormControl(personal.ProjectName, Validators.required),
            DocumentType: new FormControl(personal.DocumentType, Validators.required),
            DocumentNumber: new FormControl(personal.DocumentNumber, Validators.required),
            update: new FormControl(false),
            updateNovelty: new FormControl(false),
        });
        return fg;
    }
}


