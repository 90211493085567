<div class="content-dialog">
    <h3>{{data?.message || 'Sin data'}}</h3>
    <h3 *ngIf="data?.question">{{data?.question || 'Sin data'}}</h3>
    <form [formGroup]="form">
        <div class="fields-group">
            <div class="labeTimePiecker">
                <mat-label class="classLabel">Fin de acceso</mat-label>
                <div class="input-field-medium-date-datetime" matTooltip="FIN DEL ACCESO">
                    <mat-form-field appearance="outline">
                        <input matInput type="datetime-local" 
                            style="max-width: 175px !important;"
                            formControlName="date"
                            [min]="minDate"
                            #inputDateFinishDateContracttime
                            (change)="changeDateFinishDateContractTime(inputDateFinishDateContracttime?.value, true)"
                            >
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
    <div class="actions-dialog">
      <button   *ngIf="btnCancel === true"
                mat-button class="btn-cancelar" 
                [mat-dialog-close]="data" 
                (click)="onNoClick()">{{data?.textBtnCancel || 'CANCELAR'}}</button>&nbsp;&nbsp;
      <button   *ngIf="btnConfirm === true"
                mat-button class="btn-generar" 
                [mat-dialog-close]="data" 
                (click)="click()">{{data?.textBtnConfirm || 'CONFIRMAR'}}</button>
    </div>
  </div>