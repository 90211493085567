
<div class="box">
  <div class="header">
    <div class="content greendAnalityco">{{'ETAPAS DEL PROYECTO' || 'N/A'}}</div>
  </div>
  <div class="content-card-employer">
    <form [formGroup]="formEtapa" (ngSubmit)="onSubmitEtapa()">
      <div class="form-group-list">
        <br>
        <mat-form-field class="input-field">
          <label><strong>NOMBRE</strong></label>
          <input matInput type="text" formControlName="Name">
          <mat-error>Requerido</mat-error>
        </mat-form-field>
        <mat-form-field class="input-field">
          <label><strong>RESEÑA</strong></label>
          <textarea matInput formControlName="Review" maxlength="{{100}}"></textarea>
          <mat-error>Requerido</mat-error>
        </mat-form-field>
        <div class="text-inputExperiendt"><strong>Vigencia de la etapa en el proyecto</strong></div>
        <div class="fields-group">
          <div class="input-field-medium-date">
            <mat-label>Inicio</mat-label>
            <mat-icon (click)="pickerStart.open()">today</mat-icon>
            <mat-form-field class="">
              <input matInput [matDatepicker]="pickerStart" formControlName="StartDate" (click)="pickerStart.open()">
              <mat-datepicker #pickerStart></mat-datepicker>
              <mat-error>Requerido</mat-error>
            </mat-form-field>
          </div>
          <div class="input-field-medium-date">
            <mat-label>Fin</mat-label>
            <mat-icon (click)="pickerFinist.open()">today</mat-icon>
            <mat-form-field class="">
              <input matInput [matDatepicker]="pickerFinist" formControlName="FinishDate" (click)="pickerFinist.open()">
              <mat-datepicker #pickerFinist></mat-datepicker>
              <mat-error>Requerido</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="actions-dialog"style="margin: 3px;">
          <button mat-button class="btn-cancelar" (click)="closeDialog()" type="button">CANCELAR</button>&nbsp;&nbsp;
          <button mat-button type="submit" class="btn-generar">GUARDAR</button>
        </div>
      </div>
    </form>
  </div>
</div>

