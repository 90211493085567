import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { PersonalInfo } from '../../../../interfaces/interfaces';
import { environment } from '../../../../../../environments/environment.prod';

export interface DialogData {
  projectsInfo: PersonalInfo[];
}
@Component({
  selector: 'app-dialog-personal-company-infoid',
  templateUrl: './dialog-personal-company-infoid.component.html',
  styleUrls: ['./dialog-personal-company-infoid.component.scss']
})
export class DialogSelectPersonalCompanyInfoId implements OnInit {

  s3Bucket:string;
  constructor(
    public dialogRef: MatDialogRef<DialogSelectPersonalCompanyInfoId>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
) {
  this.s3Bucket=environment.api.s3Bucket;
  // console.log(data);
 }

onNoClick(PersonalCompanyInfoId, PrivacyPolicies, CompanyInfoId): void {
this.dialogRef.close(
   { PersonalCompanyInfoId, PrivacyPolicies, CompanyInfoId }
  );
}


ngOnInit() {}

}