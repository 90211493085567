import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpClient } from '@angular/common/http';

export interface DataShowVincularContracts {
    personalId: number;
    employerId: string;
}

@Injectable({
  providedIn: 'root'
})
export class RequestService {

    constructor(
        private api: ApiService,
        private _http: HttpClient
    ) { }


    public dataContract: DataShowVincularContracts;
    setEmployerIdPersonalId(data): DataShowVincularContracts {
        let dataContract: DataShowVincularContracts
        data !== undefined ? dataContract = data : dataContract = undefined;
        this.dataContract = dataContract;
        return dataContract;
    }

    /**
     * Permite obtener las solicitudes de de ingreso
     */
    getRequestStage() {
        return this._http.get<any>(this.api.getUrl(`api/Contract/Stage`));
    }

    /**
     * Permite traer las stages filtradas
     */
    getRequestStageFilter(adminInfoId, page?, pageWindow?, filter?, Search?) {
        return this._http.get<any>(
            this.api.getUrl(`api/Contract/Stage?adminInfoId=${adminInfoId}&${page !== undefined ? `page=${page}`: `page=1`}${pageWindow !== undefined ? `&pageWindow=${pageWindow}`: `&pageWindow=32`}${filter !== undefined ? `&stage=${filter}`: `&stage=ALL`}${Search !== undefined && Search !== null ? `&search=${Search}`: ``}`));
    }

    /**
     * Permite obtener el request por id
     * @param stageId Id del servicio
     */
     getRequestStageId(stageId) {
        return this._http.get(this.api.getUrl(`api/Contract/Stage/${stageId}`));
    }

    /**
     * Permite crear la solicitud
     * @param personalcontractId id asociacion Contract y Personal
     * @param LastComment y LastStageId del estado comoon options
     */
    postRequestStage(Id, modelo) {
        return this._http.post<any>(this.api.getUrl(`api/Contract/Stage/${Id}`), modelo);
    }

    getRequestStageByContract(personalCompanyInfoId, contractId) {
        return this._http.get(this.api.getUrl(`api/Contract/stage/ByContract?personalCompanyInfoId=${personalCompanyInfoId}&contractId=${contractId}`));
    }

    /**
     * Permite crear la solicitud
     * @param personalcontractId id asociacion Contract y Personal
     */
    deleteRequestStage(personalcontractId) {
        return this._http.delete<any>(this.api.getUrl(`api/Contract/Stage/${personalcontractId}`));
    }

    /**
     * Permite consular documentos requerido no requeridpos
     * @param contractId
     */
    getRequirement(contractId,personalCompanyInfoId) {
        return this._http.get(this.api.getUrl('api/Requirement/DocumentRequirement/'+contractId+'/'+personalCompanyInfoId));
    }

    /**
     * Actualizar documentos requisitos de acceso
     * @param file
     */
    putRequirementFile(requirementId ,personalCompanyInfoId?, employerInfoId?, file?) {
        return this._http.put(this.api.getUrl(`api/Requirement/UpdateDocument/${requirementId}?personalCompanyInfoId=${personalCompanyInfoId}&employerInfoId=${employerInfoId}`), file);
    }

    /**
     * Actualizar comentario solo request
     * @param file
     */
    putRequirementFileComment(requirementId, personalCompanyInfoId?, employerInfoId?, comment?) {
        return this._http.put(this.api.getUrl(`api/Requirement/UpdateDocument/${requirementId}?personalCompanyInfoId=${personalCompanyInfoId}&employerInfoId=${employerInfoId}&comment=${comment}`), null);
    }

    /**
     * Actualizar datos requisitos de acceso 'typeARL', 'date'
     * @param Data
     */
    putRequirementData(requirementId , employerInfoId, personalCompanyInfoId, date, typeARL) {
        return this._http.put(this.api.getUrl(`api/Requirement/UpdateDocument/${requirementId}?employerInfoId=${employerInfoId}&personalCompanyInfoId=${personalCompanyInfoId}${date === null ? '' :'&date='+date }${typeARL === null ? '' :'&typeARL='+typeARL }`),null);
    }

    /**
    * Permite consular documentos requerido no requeridpos
    * @param contractId
    */
    putRequirementDelete(requirementId ,docs: string[] ,personalCompanyInfoId?, employerInfoId?) {
        return this._http.put(this.api.getUrl(`api/Requirement/DeleteFiles/${requirementId}?personalCompanyInfoId=${personalCompanyInfoId}&employerInfoId=${employerInfoId}`), docs);
    }

    getEvents(employerInfoId) {
        return this._http.get(this.api.getUrl(`api/Employer/GetEvents/${employerInfoId}`));
    }

    getEventsIsRegularUpdate() {
        return this._http.get(this.api.getUrl(`api/Employer/IsRegularUpdate`));
    }



}

