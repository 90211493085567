import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../../components/dialogs/dialog-confirmation/dialog-confirm.component';
import { DialogImageOrVideoComponent } from '../../components/dialogs/dialog-image-video/dialog-image-video.component';
import {
  SurveySymptomsValidateAds,
  PersonalInfo,
  ModelDocumentsShow,
  CardList,
  Poll,
  OptionsCardSelect,
  PayrollConfiguration,
  WorkShiftConfiguration
} from '../../interfaces/interfaces';
import { DialogSelectPersonalCompanyInfoId } from '../../components/dialogs/dialogs-encuesta/dialog-personal-company-infoid/dialog-personal-company-infoid.component';
import { DialogOkComponent } from '../../components/dialogs/dialogs-encuesta/dialog-ok/dialog-ok.component';
import { DialogCondicionesComponent } from '../../components/dialogs/dialogs-condicionesServicios/dialog-condiciones/dialog-condiciones.component';
import { CompanyModel } from '../../models/CompanyModel';
import { DialogPrintPreCarnetComponent } from '../../components/dialogs/dialog-print-pre-carnet/dialog-print-pre-carnet.component';
import { DialogAdminAccesoComponent } from '../../components/dialogs/dialog-admin-acceso/dialog-admin-acceso.component';
import { DialogGestorImgComponent } from '../../components/dialogs/dialog-gestor-img/dialog-gestor-img.component';
import { TypeAdminAcceso } from './utils.types';
import { DialogContratoIndividualComponent } from '../../components/dialogs/dialog-contrato-individual/dialog-contrato-individual.component';
import { DialogCreatePersonalComponent } from '../../components/dialogs/dialog-create-personal/dialog-create-personal.component';
import { DialogIndividualContractAprovedComponent } from '../../components/dialogs/dialog-individual-contract-aproved/dialog-individual-contract-aproved.component';
import { DialogSearchEmployerComponent } from '../../components/dialogs/dialog-search-employer/dialog-search-employer.component';
import { DialogCreateEmployerComponent } from '../../components/dialogs/dialog-create-employer/dialog-create-employer.component';
import { DialogCreateNoveltyComponent } from '../../components/dialogs/dialog-create-novelty/dialog-create-novelty.component';
import { DialogSelectPeriodComponent } from '../../components/dialogs/dialog-select-period/dialog-select-period.component';
import { DialogLiquidacionesComponent } from '../../components/dialogs/dialog-liquidaciones/dialog-liquidaciones.component';
import { DetailsSurveySymptomsComponent } from '../../components/dialogs/details-survey-symptoms/details-survey-symptoms.component';
import { DialogSelectedItemComponent } from '../../components/dialogs/dialog-selected-item/dialog-selected-item.component';
import { DialogCreatePersonInfoCompanyComponent } from '../../components/dialogs/dialog-create-person-info-company/dialog-create-person-info-company.component';
import { DialogEnrolarPersonalWithContractComponent } from '../../components/dialogs/dialog-enrolar-personal-with-contract/dialog-enrolar-personal-with-contract.component';
import { DialogMovePersonalInfoComponent } from '../../components/dialogs/dialog-move-personal-info/dialog-move-personal-info.component';
import { DialogListComponent } from '../../components/dialogs/dialog-list/dialog-list.component';
import { DialogContractListComponent } from '../../components/dialogs/dialog-contract-list/dialog-contract-list.component';
import { DialogCrudSucursalesComponent } from '../../components/dialogs/dialog-crud-sucursales/dialog-crud-sucursales.component';
import { DialogChangeOneFileComponent } from '../../components/dialogs/dialog-change-one-file/dialog-change-one-file.component';
import { PersonalInfoContract } from '../../models/Personal/personalInfoContract';
import { DialogPollComponent } from '../../components/dialogs/dialog-poll/dialog-poll.component';
import { DialogEmailSendComponent } from '../../components/dialogs/dialog-email-send/dialog-email-send.component';
import { DialogCreateContractComponent } from '../../components/dialogs/dialog-create-contract/dialog-create-contract.component';
import { DialogRequirementsComponent } from '../../components/dialogs/dialog-requirements/dialog-requirements.component';
import { DialogHoursAllowedComponent } from '../../components/dialogs/dialog-hours-allowed/dialog-hours-allowed.component';
import { DialogSelectedControlSearchListComponent } from '../../components/dialogs/dialog-selected-control-search-list/dialog-selected-control-search-list.component';
import { DialogPersonAssociateContractsComponent } from '../../components/dialogs/dialog-person-associate-contracts/dialog-person-associate-contracts.component';
import { DialogMapComponent } from '../../components/dialogs/dialog-map/dialog-map.component';
import { DialogSelectedCausalComponent } from '../../components/dialogs/dialog-selected-causal/dialog-selected-causal.component';
import { DialogUpdatePasswordComponent } from '../../components/dialogs/dialog-update-password/dialog-update-password.component';
import { DialogAdminAccesoPersonalComponent } from '../../components/dialogs/dialog-admin-acceso-personal/dialog-admin-acceso-personal.component';
import { DialogConfirmImgComponent } from '../../components/dialogs/dialog-confirm-img/dialog-confirm-img.component';
import { DialogCRUDPermissionComponent } from '../../components/dialogs/dialog-crudpermission/dialog-crudpermission.component';
import { DialogPerfilesComponent } from '../../components/dialogs/dialog-perfiles/dialog-perfiles.component';
import { DialogCommonOptionsComponent } from '../../components/dialogs/dialog-common-options/dialog-common-options.component';
import { DialogRequirementRefenceComponent } from '../../components/dialogs/dialog-requirement-refence/dialog-requirement-refence.component';
import { DialogEconomicActivityComponent } from '../../components/dialogs/dialog-economic-activity/dialog-economic-activity.component';
import { DialogSecurityReferenceComponent } from '../../components/dialogs/dialog-security-reference/dialog-security-reference.component';
import { DialogFormImageCrudComponent } from '../../components/dialogs/dialog-form-image-crud/dialog-form-image-crud.component';
import { DialogCreateProjectComponent } from '../../components/dialogs/dialog-create-project/dialog-create-project.component';
import { DialogOccupationComponent } from '../../components/dialogs/dialog-occupation/dialog-occupation.component';
import { DialogSocialSecRangeComponent } from '../../components/dialogs/dialog-social-sec-range/dialog-social-sec-range.component';
import { DialogCreateVehicleComponent } from '../../components/dialogs/dialog-create-vehicle/dialog-create-vehicle.component';
import { DialogTimeWorkProjectComponent } from '../../components/dialogs/dialog-time-work-project/dialog-time-work-project.component';
import { DialogDocumentCommentInputAndOutputComponent } from '../../components/dialogs/dialog-document-comment-input-and-output/dialog-document-comment-input-and-output.component';
import { DialogSupplyGroupComponent } from '../../components/dialogs/dialog-supply-group/dialog-supply-group.component';
import { DialogSupplyComponent } from '../../components/dialogs/dialog-supply/dialog-supply.component';
import { DialogCarouselComponent } from '../../components/dialogs/dialog-carousel/dialog-carousel.component';
import { DialogSelectDateDisabledComponent } from '../../components/dialogs/dialog-select-date-disabled/dialog-select-date-disabled.component';
import { DialogRequirementEmployerComponent } from '../../components/dialogs/dialog-requirement-employer/dialog-requirement-employer.component';
import { DialogCreateContractLaborComponent } from '../../components/dialogs/dialog-create-contract-labor/dialog-create-contract-labor.component';
import { DialogSelectedVincularContractsComponent } from '../../components/dialogs/dialog-selected-vincular-contracts/dialog-selected-vincular-contracts.component';
import { DialogGestorPlanillasComponent } from '../../components/dialogs/dialog-gestor-planillas/dialog-gestor-planillas.component';
import { DialogPayrollRulesComponent } from '../../components/dialogs/dialog-payroll-rules/dialog-payroll-rules.component';
import { DialogWorkShiftsComponent } from '../../components/dialogs/dialog-work-shifts/dialog-work-shifts.component';
import { DialogNoveltyComponent } from '../../components/dialogs/dialog-novelty/dialog-novelty.component';
import { DialogCreateNoveltyDialogComponent } from '../../components/dialogs/dialog-create-novelty-dialog/dialog-create-novelty-dialog.component';
import { DialogCreatePrenominaComponent } from '../../components/dialogs/dialog-create-prenomina/dialog-create-prenomina.component';
import { DialogCreateTerminationComponent } from '../../components/dialogs/dialog-create-termination/dialog-create-termination.component';
import { DialogConfirmCodeLoginComponent } from '../../components/dialogs/dialog-confirm-code-login/dialog-confirm-code-login.component';
import { DialogGestorDocumentsComponent } from '../../components/dialogs/dialog-gestor-documents/dialog-gestor-documents.component';
import { DialogAddPersonalSocialSegurityComponent } from '../../components/dialogs/dialog-add-personal-social-segurity/dialog-add-personal-social-segurity.component';
import {DialogSearchAdminComponent} from '../../components/dialogs/dialog-search-admin/dialog-search-admin.component';
import {
  DialogContractAddProjectComponent
} from '../../components/dialogs/dialog-contract-add-project/dialog-contract-add-project.component';
import {DialogProjectAddComponent} from '../../components/dialogs/dialog-project-add/dialog-project-add.component';
import {
  DialogContractAddTransferPersonnelComponent
} from '../../components/dialogs/dialog-contract-add-transfer-personnel/dialog-contract-add-transfer-personnel.component';
import {DialogCargeMasivoComponent} from '../../components/dialogs/dialog-carge-masivo/dialog-carge-masivo.component';
import {DialogEditMinutesComponent} from '../../components/dialogs/dialog-edit-minutes/dialog-edit-minutes.component';
import {
  DialogGestorImgPersonalComponent
} from '../../components/dialogs/dialog-gestor-img-personal/dialog-gestor-img-personal.component';
import {
  DialogSelectedControlWithdrawalsComponent
} from '../../components/dialogs/dialog-selected-control-withdrawals/dialog-selected-control-withdrawals.component';
import {
  DialogContractNotificationSchedulingComponent
} from '../../components/dialogs/dialog-contract-notification-scheduling/dialog-contract-notification-scheduling.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private dialog: MatDialog) {}

  // Devuelve true si confirma de lo contrario es false
openDialogConfirm(message: string, btnConfirm?: boolean, btnCancel?: boolean, textBtnCancel?: string,
                  textBtnConfirm?: string, question?: string) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: '452px',
      data: { message, confirm: false, btnConfirm, btnCancel, textBtnCancel, textBtnConfirm, question},
      disableClose: true
    });
    return dialogRef;
  }

  //
  openDialogConfirmCodeLogin(email, password) {
    const dialogRef = this.dialog.open(DialogConfirmCodeLoginComponent, {
      width: '452px',
      data: {email, password},
      disableClose: true
    });
    return dialogRef;
  }

  // Devuelve true si confirma de lo contrario es false
openDialogSelectDateDisabledComponent(message: string, btnConfirm?: boolean, btnCancel?: boolean,
                                      textBtnCancel?: string, textBtnConfirm?: string, question?: string) {
    const dialogRef = this.dialog.open(DialogSelectDateDisabledComponent, {
      width: '452px',
      data: {message, confirm: false, btnConfirm, btnCancel, textBtnCancel, textBtnConfirm, question},
      disableClose: true
    });
    return dialogRef;
  }

  openDialogOk(message: string, buttonText: string, surveySymptoms: SurveySymptomsValidateAds, color: number) {
    const dialogRef = this.dialog.open(DialogOkComponent, {
      width: '452px',
      data: {message, confirm: true, buttonText, surveySymptoms, color },
      disableClose: true
    });
    return dialogRef;
  }

  openDialogImageOrVideoOrPdf(image?: string, video?: string) {
    const dialogRef = this.dialog.open(DialogImageOrVideoComponent, {
      panelClass: 'my-full-screen-dialog',
      data: {image, video}
    });
    return dialogRef;
  }


  openDialogSelectPersonalCompanyInfoId(personalInfo: PersonalInfo[]) {
    const dialogRef = this.dialog.open(DialogSelectPersonalCompanyInfoId, {
      width: '300px',
      data: personalInfo,
      disableClose: true
    });
    return dialogRef;
  }


  openDialogCondiciones(typeDialog: string, companyModel: CompanyModel) {
    const dialogRef = this.dialog.open(DialogCondicionesComponent, {
      width: '352px',
      data: {typeDialog, companyModel},
      disableClose: true
    });
    return dialogRef;
  }

  openDialogPrintPreCarnet(print: string, personalInfo: PersonalInfoContract) {
    const dialogRef = this.dialog.open(DialogPrintPreCarnetComponent, {
      width: '500px',
      data: {print, personalInfo},
      disableClose: true,
      // panelClass: 'custom-dialog'
    });
    return dialogRef;
  }

  openDialogAdminAcceso(personalContractProyectSelected: any, typeAdmin: TypeAdminAcceso, isCrud?: boolean, Requirements?: number[]) {
    const dialogRef = this.dialog.open(DialogAdminAccesoComponent, {
      width: 'auto',
      data: {personalContractProyectSelected, typeAdmin, isCrud, Requirements},
      disableClose: false,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogAdminAccesoPersonal(personalContractSelected: any, typeAdmin: TypeAdminAcceso, isCrud?: boolean, Requirements?: number[]) {
    const dialogRef = this.dialog.open(DialogAdminAccesoPersonalComponent, {
      width: 'auto',
      data: {personalContractSelected, typeAdmin, isCrud, Requirements},
      disableClose: false,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogRequirements(Requirements: any[], title?) {
    const dialogRef = this.dialog.open(DialogRequirementsComponent, {
      // width: '600px',
      data: {Requirements, title},
      disableClose: false,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogRequirementEmployer(Requirements: any[], title?) {
    const dialogRef = this.dialog.open(DialogRequirementEmployerComponent, {
      width: '450px',
      data: {Requirements, title},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

openDialogGestorImg(title, docs?: ModelDocumentsShow[], typeOperator?: string, idPlanilla?: any,
                    typeGestor?: string, cropper?: boolean, desc?: string, permissionCompany?, isFromPersonal?) {
    const dialogRef = this.dialog.open(DialogGestorImgComponent, {
      width: '360px',
      data: {title, docs, typeOperator, idPlanilla, typeGestor, cropper, desc, permissionCompany, isFromPersonal},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogGestorImgPersonal(title, docs?: ModelDocumentsShow[], typeOperator?: string, idPlanilla?: any,
                              typeGestor?: string, cropper?: boolean, desc?: string, permissionCompany?) {
      const dialogRef = this.dialog.open(DialogGestorImgPersonalComponent, {
        width: 'auto',
        data: {title, docs, typeOperator, idPlanilla, typeGestor, cropper, desc, permissionCompany},
        disableClose: true,
        panelClass: 'custom-dialog-transparent'
      });
      return dialogRef;
    }

  openDialogGestorDocuments(title, docs?: any, typeOperator?, module?: string, adminInfoId?, isFromPersonal?) {
    const dialogRef = this.dialog.open(DialogGestorDocumentsComponent, {
      width: '360px',
      data: {title, docs, typeOperator, module, adminInfoId, isFromPersonal},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

openDialogGestorPlanillas(title, docs?: ModelDocumentsShow[], typeOperator?: string, idPlanilla?: any,
                          typeGestor?: string, cropper?: boolean, desc?: string, proveedorId?: string) {
    const dialogRef = this.dialog.open(DialogGestorPlanillasComponent, {
      width: '360px',
      data: {title, docs, typeOperator, idPlanilla, typeGestor, cropper, desc, proveedorId},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

openDialogDocumentCommentInputAndOutput(title, typeOperator, docs?: ModelDocumentsShow[], desc?: string,
                                        CompanyInfoParentId?: string, EventId?: string) {
    const dialogRef = this.dialog.open(DialogDocumentCommentInputAndOutputComponent, {
      width: '360px',
      data: {title, typeOperator, docs, desc, CompanyInfoParentId, EventId},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogChangeOneFile(urlType: string, url?: string, typeOperator?: string, files?: File[]) {
    const dialogRef = this.dialog.open(DialogChangeOneFileComponent, {
      width: '352px',
      data: {urlType, url, typeOperator, files},
      disableClose: true,
      panelClass: 'custom-dialog'
    });
    return dialogRef;
  }


  openDialogDialogConfirmImg(title: string, messsage: string[], urlComplet: string, urlAssets: string) {
    const dialogRef = this.dialog.open(DialogConfirmImgComponent, {
      width: '100%',
      data: {title, messsage, urlComplet, urlAssets},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogDialogPayrollRules(title: string, payrollConfiguration: PayrollConfiguration, CompanyInfoId: string) {
    const dialogRef = this.dialog.open(DialogPayrollRulesComponent, {
      width: '100%',
      data: {title, payrollConfiguration, CompanyInfoId},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogContratoIndividual(message: string) {
    const dialogRef = this.dialog.open(DialogContratoIndividualComponent, {
      width: '350px',
      data: {message},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogEnrolarPersonalWithContract(CompanyInfoId, IsRequest, isFromPersonalList?) {
    const dialogRef = this.dialog.open(DialogEnrolarPersonalWithContractComponent, {
      width: '350px',
      data: {CompanyInfoId, IsRequest, isFromPersonalList},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogAddPersonalSocialSegurity(EmployerInfoId) {
    const dialogRef = this.dialog.open(DialogAddPersonalSocialSegurityComponent, {
      width: '350px',
      data: {EmployerInfoId},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogCreateTermination(CompanyInfoId, IsRequest) {
    const dialogRef = this.dialog.open(DialogCreateTerminationComponent, {
      width: '350px',
      data: {CompanyInfoId, IsRequest},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogCreatePrenomina(idEmployer) {
    const dialogRef = this.dialog.open(DialogCreatePrenominaComponent, {
      width: '350px',
      data: {idEmployer},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogDetailsSurveySymptoms(Id: number) {
    const dialogRef = this.dialog.open(DetailsSurveySymptomsComponent, {
      width: '350px',
      data: {Id},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogPoll(answer: boolean, poll: Poll, answers: any) {
    const dialogRef = this.dialog.open(DialogPollComponent, {
      width: '350px',
      data: {answer, poll, answers},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogList(dataList: any, card: CardList, path: string) {
    const dialogRef = this.dialog.open(DialogListComponent, {
      width: '1130px',
      data: {dataList, card, path},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogSelectedVincularContracts(cardOne: CardList, cardTwo: CardList, path: string, dataOne, dataTwo) {
    const dialogRef = this.dialog.open(DialogSelectedVincularContractsComponent, {
      width: '1130px',
      data: {cardOne, cardTwo, path, dataOne, dataTwo  },
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogCarousel(imgs: any) {
    const dialogRef = this.dialog.open(DialogCarouselComponent, {
      // width: '2000px',
      data: {imgs},
      disableClose: true,
      panelClass: 'custom-dialog-transparent',
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });
    return dialogRef;
  }

  openDialogMovePersonalInfo(confirmar: boolean, data?: any, question?: string, isRequest?: boolean,
                             title?: string, isFromVehicle?: boolean) {
    const dialogRef = this.dialog.open(DialogMovePersonalInfoComponent, {
      width: '350px',
      data: {confirmar, data, question, isRequest, title, isFromVehicle},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogCRUDPermission(IsPost: boolean, Id: number, Name: string) {
    const dialogRef = this.dialog.open(DialogCRUDPermissionComponent, {
      width: '350px',
      data: {IsPost, Id, Name},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogPerfiles(IdRol: string) {
    const dialogRef = this.dialog.open(DialogPerfilesComponent, {
      width: '450px',
      data: {IdRol},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogTimeWorkProject(Id: string) {
    const dialogRef = this.dialog.open(DialogTimeWorkProjectComponent, {
      width: '450px',
      data: {Id},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogWorkShiftsProject(CompanyInfoId: string, ProjectId: string, wShift: WorkShiftConfiguration) {
    const dialogRef = this.dialog.open(DialogWorkShiftsComponent, {
      width: '900px',
      data: {CompanyInfoId, ProjectId, wShift},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogCommonOptions(Id: string) {
    const dialogRef = this.dialog.open(DialogCommonOptionsComponent, {
      width: '350px',
      data: {Id},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogEconomicActivity(Id: string) {
    const dialogRef = this.dialog.open(DialogEconomicActivityComponent, {
      width: '350px',
      data: {Id},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogSecurityReferences(Id: string) {
    const dialogRef = this.dialog.open(DialogSecurityReferenceComponent, {
      width: '350px',
      data: {Id},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogSupplyGroupC(Id: string) {
    const dialogRef = this.dialog.open(DialogSupplyGroupComponent, {
      width: '350px',
      data: {Id},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogSupply(Id: string, CompanyInfoId?: string) {
    const dialogRef = this.dialog.open(DialogSupplyComponent, {
      width: '350px',
      data: {Id, CompanyInfoId},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }


  openDialogCreateVehicle(CompanyInfoId: string) {
    const dialogRef = this.dialog.open(DialogCreateVehicleComponent, {
      width: '360px',
      data: {CompanyInfoId},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogCreateContractLabor(PersonalCompanyInfoId: string, EmployerId: string, CompanyInfoId: string, companyIdUserLogin?: string) {
    const dialogRef = this.dialog.open(DialogCreateContractLaborComponent, {
      width: '360px',
      data: {PersonalCompanyInfoId, EmployerId, CompanyInfoId, companyIdUserLogin},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogSocialSecRange(Id: string) {
    const dialogRef = this.dialog.open(DialogSocialSecRangeComponent, {
      width: '350px',
      data: {Id},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogJob(Id: string) {
    const dialogRef = this.dialog.open(DialogOccupationComponent, {
      width: '350px',
      data: {Id},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogCreateProject(Id: string, permissions: any[]) {
    const dialogRef = this.dialog.open(DialogCreateProjectComponent, {
      width: '350px',
      data: {Id, permissions},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogFormImageCrud(Id: string) {
    const dialogRef = this.dialog.open(DialogFormImageCrudComponent, {
      width: '350px',
      data: {Id},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogRequirementRefence(Id: string, companyId?, title?) {
    const dialogRef = this.dialog.open(DialogRequirementRefenceComponent, {
      width: '450px',
      data: {Id, companyId, title},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogSelectedItemComponent(dataList: any[], card: CardList, title: string) {
    const dialogRef = this.dialog.open(DialogSelectedItemComponent, {
      width: '350px',
      data: {dataList, card, title},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogPersonAssociateContracts(personalCompanyInfoId: any, data: any, proyects, dataModuleRequest?,
                                     isFromVehicle?: boolean, adminInfoId?) {
    const dialogRef = this.dialog.open(DialogPersonAssociateContractsComponent, {
      width: '350px',
      data: {personalCompanyInfoId, data, proyects, dataModuleRequest, isFromVehicle, adminInfoId},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogNovelty(dataList: any, card: CardList, employer: string, motivos) {
    const dialogRef = this.dialog.open(DialogNoveltyComponent, {
      width: '1500px',
      data: {dataList, card, employer, motivos},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogEmailSend(dataList: any[], title: string) {
    const dialogRef = this.dialog.open(DialogEmailSendComponent, {
      width: '350px',
      data: {dataList, title},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogSelectPeriod(title, description, maxDate, itemsNumber) {
    const dialogRef = this.dialog.open(DialogSelectPeriodComponent, {
      width: '350px',
      data: {title, description, maxDate, itemsNumber},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogMap(address, GeoFenceJson) {
    const dialogRef = this.dialog.open(DialogMapComponent, {
      width: '80%',
      data: {address, GeoFenceJson},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }


  openDialogCreateNovelty(message: string, employer: any, period, edit: boolean, novelty?) {
    const dialogRef = this.dialog.open(DialogCreateNoveltyComponent, {
      width: '350px',
      data: {message, employer, period, edit, novelty},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogCreateNoveltyDialog(message: string, employer: any, period, edit: boolean, motivos) {
    const dialogRef = this.dialog.open(DialogCreateNoveltyDialogComponent, {
      width: '350px',
      data: {message, employer, period, edit, motivos},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogLiquidaciones(period) {
    const dialogRef = this.dialog.open(DialogLiquidacionesComponent, {
      width: '100%',
      data: {period},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogSearchEmployer(message: string, CompanyInfoParentId: any) {
    const dialogRef = this.dialog.open(DialogSearchEmployerComponent, {
      width: '350px',
      data: {message, CompanyInfoParentId},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogSearchAdmin(message: string) {
    const dialogRef = this.dialog.open( DialogSearchAdminComponent, {
      width: '350px',
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

openDialogSelectedControlSearchList(optionsCardSelect: OptionsCardSelect, title: string, data: any[],
                                    disableClose: boolean, btnClose: boolean, btnConfirm: boolean, textClose: string, textConfirm: string,
                                    autoClose?: boolean, message?: string, colorMessage?: string) {
    const dialogRef = this.dialog.open(DialogSelectedControlSearchListComponent, {
      width: '350px',
      data: {optionsCardSelect, title, data, btnClose, btnConfirm, textClose, textConfirm, autoClose, message, colorMessage},
      disableClose,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

openDialogSelectedCausal(textClose: string, textConfirm: string, documentType?: string, documentNumber?: string, companyInfoId?: string, employers?) {
    const dialogRef = this.dialog.open(DialogSelectedCausalComponent, {
      width: '360px',
      data: {textClose, textConfirm, documentType, documentNumber, companyInfoId, employers},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogCreateContract(message: string, CompanyInfoParentId: any) {
    const dialogRef = this.dialog.open(DialogCreateContractComponent, {
      width: '350px',
      data: {message, CompanyInfoParentId},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogUpdatePassword(userChangeData?, UpdatePasswordAnonymous?) {
    const dialogRef = this.dialog.open(DialogUpdatePasswordComponent, {
      disableClose: true,
      data: {userChangeData, UpdatePasswordAnonymous}
    });
    return dialogRef;
  }

openDialogIndividualContractAproved(title: string, comment: string, name: string, url?: string, aceptText?: string,
                                    isRechazo?: boolean, isRetiro?: boolean) {
    const dialogRef = this.dialog.open(DialogIndividualContractAprovedComponent, {
      width: '350px',
      data: {title, comment, name, url, aceptText, isRechazo, isRetiro},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

openDialogCreatePersonal(data: any, DocumentNumber?: string, DocumentType?: string, personal?: any) {
    const dialogRef = this.dialog.open(DialogCreatePersonalComponent, {
      width: '350px',
      data: {data, DocumentNumber, DocumentType, personal},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

openDialogCreatePersonInfoCompany(FinishDate: string, StartDate: string, id: any, CompanyInfoId: any,
                                  DocumentNumber?: string, DocumentType?: string, personal?: any, IsRequest?: boolean,
                                  typeContract?: string) {
    const dialogRef = this.dialog.open(DialogCreatePersonInfoCompanyComponent, {
      width: '350px',
      data: {FinishDate, StartDate, id, CompanyInfoId, DocumentNumber, DocumentType, personal, IsRequest, typeContract},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogDialogCrudSucursales(update: boolean, CompanyId: string, model?: any, daneCity?, isCreate?: boolean) {
    const dialogRef = this.dialog.open(DialogCrudSucursalesComponent, {
      width: '350px',
      data: {update, CompanyId, model, daneCity, isCreate},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

openDialogCreateEmployer(data: any, DocumentNumber?: string, DocumentType?: string, personal?: any,
                         digito?: any, CompanyInfoParentId?: any) {
    const dialogRef = this.dialog.open(DialogCreateEmployerComponent, {
      width: '350px',
      data: {data, DocumentNumber, DocumentType, personal, digito, CompanyInfoParentId},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }


  openDialogListC(dataList: any, path: string) {
    const dialogRef = this.dialog.open(DialogContractListComponent, {
      width: '1130px',
      data: {dataList,  path},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogContractNotificationSchedulingComponent(companyInfoId?: string) {
    const dialogRef = this.dialog.open(DialogContractNotificationSchedulingComponent, {
      width: '350px',
      data: {companyInfoId},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogHoursAllowed(minValue, maxValue, arrayWeekDays) {
    const dialogRef = this.dialog.open(DialogHoursAllowedComponent, {
      width: '600px',
      data: {minValue, maxValue, arrayWeekDays},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogContractAddProject(contractId) {
    const dialogRef = this.dialog.open(DialogContractAddProjectComponent, {
      width: '450px',
      data: {contractId},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogProjectAdd(projectId) {
    const dialogRef = this.dialog.open(DialogProjectAddComponent, {
      width: '450px',
      data: {projectId},
      disableClose: true,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogGeneric(title: string, message: string, btnCancel: string) {
    const dialogRef = this.dialog.open(DialogContractAddTransferPersonnelComponent, {
      width: '450px',
      data: {title, message, btnCancel},
      disableClose: false,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogCargeMasivo(title, docs?: any, typeOperator?, module?: string, adminInfoId?,  typeToShow?){
    const dialogRef = this.dialog.open(DialogCargeMasivoComponent, {
      width: '450px',
      data: {title, docs, typeOperator, module, adminInfoId, typeToShow},
      disableClose: false,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogEditMinutes(supply?){
    const dialogRef = this.dialog.open(DialogEditMinutesComponent, {
      width: '450px',
      data: {supply},
      disableClose: false,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

  openDialogSelectedControlWithdrawal(optionsCardSelect: OptionsCardSelect, title: string, data: any[],
                                      disableClose: boolean, btnClose: boolean, btnConfirm: boolean, textClose: string, textConfirm: string,
                                      autoClose?: boolean, message?: string, colorMessage?: string, endDate?: string) {
    const dialogRef = this.dialog.open(DialogSelectedControlWithdrawalsComponent, {
      width: '350px',
      data: {optionsCardSelect, title, data, btnClose, btnConfirm, textClose, textConfirm, autoClose, message, colorMessage, endDate},
      disableClose,
      panelClass: 'custom-dialog-transparent'
    });
    return dialogRef;
  }

}
