<div class="content-dialog">
  <div *ngIf="data.message !== ''" class="colorCard" [ngClass]="data.color === 2 ? 'red': (data.color === 0 ? 'green': 'orange')">
    <h3>{{data.message || 'Sin data' | uppercase}}</h3>
  </div>  
  <div class="link-content" *ngIf="data.surveySymptoms.EnableAdFinish">
    <h3>{{data.surveySymptoms.TextAdFinish || 'Sin MENSAJE'}}</h3>
    <h3 class="link">
      <a [href]="data.surveySymptoms.LinkAdFinish" target="_blank">{{data.surveySymptoms.LinkAdFinish || 'SIN LINK' | uppercase}}</a>
    </h3>
  </div>
  <div class="actions-dialog">
    <button mat-button class="btn-generar" [mat-dialog-close]="data" (click)="routerSesion()">{{data.buttonText | uppercase}}</button>
  </div>
</div>