

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { DialogService } from 'src/app/core/services/utils/dialog.service';
import { environment } from 'src/environments/environment';

export interface DialogData {
  Id: string;
  CompanyInfoId: string;
}

@Component({
  selector: 'app-dialog-supply',
  templateUrl: './dialog-supply.component.html',
  styleUrls: ['./dialog-supply.component.scss']
})
export class DialogSupplyComponent implements OnInit {
  s3Bucket:string;

  form: FormGroup;
  imgCropperResult: string;

  supplyGroup: any[] = []
  
  constructor(public dialogRef: MatDialogRef<DialogSupplyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonService: CommonService,
    private dialogService: DialogService,
    private fb: FormBuilder) { 
      this.form = this.fb.group({
        SupplyGroupId: new FormControl('',Validators.required),
        CompanyInfo: new FormControl(''),
        CompanyInfoId: new FormControl(''),
        IsActive: new FormControl(false,Validators.required),
        Name: new FormControl('',Validators.required),
        Order: new FormControl(''),
      });
      if(this.data.Id !== undefined) {
        this.commonService.getSupplyId(this.data.Id).subscribe((rest: any) => {
          this.form.get('SupplyGroupId').setValue(rest.SupplyGroupId);
          this.form.get('CompanyInfo').setValue(rest.CompanyInfo);
          this.form.get('CompanyInfoId').setValue(rest.CompanyInfoId);
          this.form.get('IsActive').setValue(rest.IsActive);
          this.form.get('Name').setValue(rest.Name);
          this.form.get('Order').setValue(rest.Order);
        });
      }
      if(this.data.CompanyInfoId !== undefined) {
        this.form.get('CompanyInfoId').setValue(this.data.CompanyInfoId);
      }

    }

  ngOnInit(): void {
    this.s3Bucket=environment.api.s3Bucket;
    this.commonService.getSupplyGroup().subscribe((rest) => {
      this.supplyGroup = rest.sort(function (a, b) {   
        return a.Order - b.Order;
      });
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    if(this.form.status === 'VALID')  {
      if(this.data.Id !== undefined) {
        let model = {
          ...this.form.value,
          Id: this.data.Id
        }
        this.commonService.putSupplyId(this.data.Id, model).subscribe(rest => {
          this.dialogRef.close(true);
        })
      } else {
        this.commonService.postSupply(this.form.value).subscribe((rest: any) => {
          this.dialogRef.close(true);
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }


}

