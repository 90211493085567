import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
//import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'company-dialog',
  templateUrl: 'company-dialog.html',
  styleUrls: ['./company-dialog.scss']
})
export class CompanyDialog {
    s3Bucket:string
    constructor(@Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CompanyDialog>
    ) { }
    ngOnInit(){
        this.s3Bucket=environment.api.s3Bucket
    }


    choose($event,company){
        this.dialogRef.close(company.Id)
    }
}
