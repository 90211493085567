import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContractService} from '../../../services/modules/contract.service';
import {SnackBarService} from '../../../services/utils/snackBar.service';
import {Projects} from '../../../interfaces/interfaces';
import {viewshowChipsActividadEstapa} from '../../../../modules/contract/Components/contract-id/contract-id.component';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatMenuTrigger} from '@angular/material/menu';
import {FechasService} from '../../../services/utils/fechas.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
  contractId: string;
}


@Component({
  selector: 'app-dialog-contract-add-project',
  templateUrl: './dialog-contract-add-project.component.html',
  styleUrls: ['./dialog-contract-add-project.component.scss']
})
export class DialogContractAddProjectComponent implements OnInit {

  formVincularProyect: FormGroup;

  contractProjects: any[] = [];
  projects: Projects[];
  selectedIdeconomicActivity: viewshowChipsActividadEstapa[] = [];
  viewValueseconomicActivity: string[] = [];
  controleconomicActivity = new FormControl();
  economicActivityObs: any[];
  minDateNow = this.fechasService.getDateNow();
  maxDateOfProyect = '';
  contractId;
  economicActivity: any[] = [];
  @ViewChild('InputControleconomicActivity') InputControleconomicActivity: ElementRef<HTMLInputElement>;
  @ViewChild('autoeconomicActivity') matAutocompleteeconomicActivity: MatAutocomplete;
  @ViewChild('clickHoverMenuTriggereconomicActivity') clickHoverMenuTriggereconomicActivity: MatMenuTrigger;


  constructor(
    public dialogRef: MatDialogRef<DialogContractAddProjectComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private contractService: ContractService,
    private snackBService: SnackBarService,
    public fechasService: FechasService,
  ) { }

  ngOnInit(): void {
    this.contractId = this.data.contractId;
    this.formVincularProyect = this.fb.group({
      ProjectId: new FormControl('', Validators.required),
      StartDate: new FormControl(new Date(), Validators.required),
      FinishDate: new FormControl(new Date(), Validators.required)
    });

    this.getContracts(this.contractId);
    this.getProjects(this.contractId);
  }

  getProjects( idContract: string ): void {
    this.contractService.getProjects( idContract ).subscribe((data: any) => {
      this.projects = data;
    });
  }

  getContracts( idContract: string ): void {
    this.contractService.getContractId(idContract).subscribe((data: any) => {
      this.getProyectsJoinCompany(data.CompanyInfoId);
    });
  }

  getProyectsJoinCompany( CompanyInfoId: string ): void {
    this.contractService.getProyectsJoinCompany( CompanyInfoId ).subscribe((data: any) => {
      this.contractProjects = data.filter(x => this.existVinculado(x.Id) === false);
    });
  }

  existVinculado(id): boolean {
    let exit = false;
    const data = this.projects.find((x: any) => x.ProjectId === id);
    data !== undefined ? exit = true : exit = false;
    return exit;
  }

  saveProyectVinc() {
    const startDateString = this.formVincularProyect.get('StartDate').value;
    const finishDateString = this.formVincularProyect.get('FinishDate').value;
    const startDate = this.formatDate(startDateString);
    const finishDate = this.formatDate(finishDateString);

    // POST GUARDAR POR PRIMERA VES
    if (this.formVincularProyect.status === 'VALID' && this.selectedIdeconomicActivity.length > 0) {
      const projectStages: number[] = [];
      this.selectedIdeconomicActivity.forEach(selectedCaja => {
        projectStages.push(selectedCaja.Id);
      });
      const model = {
        ContractId: this.contractId,
        FinishDate: this.formVincularProyect.get('FinishDate').value,
        ProjectId: this.formVincularProyect.get('ProjectId').value,
        ProjectStages: projectStages,
        StartDate:  this.formVincularProyect.get('StartDate').value
      };
      if (startDate < finishDate && finishDate > startDate){
        if (finishDate <= this.maxDateOfProyect){
          this.contractService.postContractProject(this.contractId, model).subscribe(rest => {
            this.getProjects(this.contractId);
            this.dialogRef.close(true);
          });
        }else{
          this.snackBService.openSnackBar('LA FECHA DE FIN, NO PUEDE SER SUPERIOR A LA FECHA DEL PROYECTO', 'X', 4000);
        }
      }else{
        this.alertDateNoValid();
      }
    } else {
      this.snackBService.openSnackBar('Valida la información ingresada', 'X', 4000);
    }

  }

  addActivity(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.viewValueseconomicActivity.push(value);
    }
    this.controleconomicActivity.setValue(null);
  }

  removeActivity(item: any): void {
    const index = this.viewValueseconomicActivity.indexOf(item);
    for (let i = 0; i < this.selectedIdeconomicActivity.length; i++) {
      if (this.selectedIdeconomicActivity[i].Name === item) {
        this.selectedIdeconomicActivity.splice(index, 1);
        if (i === this.selectedIdeconomicActivity.length -1) {
          this.formVincularProyect.get('FinishDate').setValue('');
          this.changeDateProjet();
        }
      }
    }
    if (index >= 0) {
      this.viewValueseconomicActivity.splice(index, 1);
      this.formVincularProyect.get('FinishDate').setValue('');
      this.changeDateProjet();
    }
  }

  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = this.padZeroes(date.getMonth() + 1);
    const day = this.padZeroes(date.getDate());
    return `${year}-${month}-${day}`;
  }

  private padZeroes(number: number): string {
    return number < 10 ? '0' + number : number.toString();
  }

  alertDateNoValid(){
    this.snackBService.openSnackBar('LAS FECHAS SELECCIONADAS NO SON VALIDAS', 'X', 4000);
  }

  selectedActivity(event: MatAutocompleteSelectedEvent): void {
    const exitCaja = this.selectedIdeconomicActivity.find(x => x.Id === event.option.value);
    if (this.selectedIdeconomicActivity.length === 0 || exitCaja === undefined) {
      this.selectedIdeconomicActivity.push(
        { Id: event.option.value,
          Name: event.option.viewValue,
          FinishDate: this.getDateProjet(event.option.value).FinishDate,
          StartDate: this.getDateProjet(event.option.value).StartDate
        });
      this.viewValueseconomicActivity.push(event.option.viewValue);
      this.changeDateProjet();
      this.InputControleconomicActivity.nativeElement.value = '';
      this.InputControleconomicActivity.nativeElement.disabled = true;
      this.controleconomicActivity.setValue('');
      this.InputControleconomicActivity.nativeElement.disabled = false;
    } else {
      this.InputControleconomicActivity.nativeElement.value = '';
      this.InputControleconomicActivity.nativeElement.disabled = true;
      this.changeDateProjet();
      this.controleconomicActivity.setValue('');
      this.InputControleconomicActivity.nativeElement.disabled = false;
    }
  }

  getDateProjet(id) {
    const data = this.economicActivity.find(x => x.Id === id);
    return data;
  }

  changeDateProjet() {
    let startDate = this.formVincularProyect.get('StartDate').value;
    let finishDate = this.formVincularProyect.get('FinishDate').value;
    if (this.selectedIdeconomicActivity.length === 0) {
      this.formVincularProyect.get('StartDate').setValue(new Date());
      this.formVincularProyect.get('FinishDate').setValue(new Date());
    }
    this.selectedIdeconomicActivity.forEach((value, index) => {
      if (this.selectedIdeconomicActivity.length === 1) {
        this.formVincularProyect.get('StartDate').setValue(value.StartDate);
        this.formVincularProyect.get('FinishDate').setValue(value.FinishDate);
      } else {
        if (value.FinishDate > finishDate || value.FinishDate === finishDate) {
          finishDate = value.FinishDate;
        }
        if (value.StartDate < startDate || value.StartDate === startDate) {
          startDate = value.StartDate;
        }
        if (index === this.selectedIdeconomicActivity.length -1) {
          this.formVincularProyect.get('StartDate').setValue(startDate);
          this.formVincularProyect.get('FinishDate').setValue(finishDate);
        }
      }
    });
  }

  calculateIfDateIsGreaterThanToday(): string {
    const selectedDate: Date = this.formVincularProyect.get('StartDate').value;
    const today: Date = new Date();

    if (selectedDate && selectedDate > today) {
      return 'DateRed';
    } else {
      return '';
    }
  }

  cancel() {
    this.formVincularProyect.get('ProjectId').setValue('');
    this.InputControleconomicActivity.nativeElement.value = '';
    this.InputControleconomicActivity.nativeElement.disabled = false;
    this.formVincularProyect.get('StartDate').setValue(new Date());
    this.formVincularProyect.get('FinishDate').setValue(new Date());
    this.selectedIdeconomicActivity = [];
    this.viewValueseconomicActivity = [];
    this.dialogRef.close();
  }

  changeSelectedProyect(e) {
    this.contractService.getProjectStageEtapas(e).subscribe(rest => {
      const maxDateObject = rest.reduce((max, current) => {
        return (new Date(current.FinishDate) > new Date(max.FinishDate)) ? current : max;
      }, rest[0]);
      this.maxDateOfProyect = maxDateObject.FinishDate;
      this.economicActivity = rest;
      this.economicActivityObs = rest;

      this.selectedIdeconomicActivity = [];
      this.viewValueseconomicActivity = [];
    });
  }
}
