<div class="box">
  <div class="header">
    <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{data?.title || 'ARCHIVOS Y COMENTARIOS'}}</div>
  </div>

  <div *ngIf="!isProcessing" class="content-card">

    <div class="sub-header" *ngIf="module ==='Personal' || module === 'Termination'">
      <div class="sub-content greendAnalityco">{{'TIPO DE ACTUALIZACION' || 'N/A'}}</div>
    </div>
    <div class="fields-group">
      <app-select-search *ngIf="module === 'Personal'"
        class="selected"
        [_dataSource]="typesUpdate" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="controlSearchTypUpdate"
        [inputType]="'text'" (onSelectionChange)="changeTypeUpdate($event)"></app-select-search>

      <app-select-search *ngIf="module === 'Termination'"
        class="selected"
        [_dataSource]="typesUpdateTermination" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="controlSearchTypUpdateTermination"
        [inputType]="'text'" (onSelectionChange)="changeTypeUpdateTermination($event)">
      </app-select-search>
    </div>

    <!--Personal-->
    <div *ngIf="(module === 'Personal' || module === 'Employer') && typeShowDialog === 1 && exitPermissionCompany('massuploadpersonal.personalcreation')">
      <div class="info-box">
        <p><strong>Pasos:</strong></p>
        <p>1. Descargue el archivo con el formato</p>
        <p>2. Diligencie los datos en el archivo descargado</p>
        <p>3. Cargue el archivo</p>
        <p><strong>Nota:</strong> En cada carga solo se permiten 100 registros</p>
      </div>

      <div class="content-icono">
        <p class="download-text"><strong>Formato para descargar</strong></p>
        <div class="format-download">
          <a [href]="urlFormat" download>
            <mat-icon class="download-icon">
              <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="36px" fill="#5f6368">
                <path d="m720-120 160-160-56-56-64 64v-167h-80v167l-64-64-56 56 160 160ZM560 0v-80h320V0H560ZM240-160q-33 0-56.5-23.5T160-240v-560q0-33 23.5-56.5T240-880h280l240 240v121h-80v-81H480v-200H240v560h240v80H240Zm0-80v-560 560Z"/>
              </svg>
            </mat-icon>
          </a>
        </div>
      </div>

      <div class="sub-header">
        <div class="sub-content-2 greendAnalityco">{{'CARGAR FORMATO DILIGENCIADO' || 'N/A'}}</div>
      </div>
      <div class="content-show" style="padding-top: 12px;">
        <div *ngIf="module === 'Personal'">
          <ngx-dropzone  *ngIf="files.length === 0" (change)="onSelect($event)" class="dropzone" [multiple]="false">
            <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
          </ngx-dropzone>
        </div>

        <div *ngIf="module === 'Employer'">
          <ngx-dropzone  *ngIf="files.length === 0" (change)="onSelect($event)" class="dropzone" [multiple]="false">
            <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
          </ngx-dropzone>
        </div>

        <!-- Sección para mostrar el archivo subido -->
        <div *ngIf="files.length > 0" class="uploaded-file">
          <span class="file-name">{{ files[0]?.name }}</span>
          <button mat-icon-button class="remove-button" (click)="removeFile()">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>


        <div *ngIf="module === 'Personal'">
          <mat-form-field class="input-field">
            <label><div>COMENTARIO</div></label>
            <textarea matInput [(ngModel)]="commentary" maxlength="280" rows="5"></textarea>
            <mat-error>Requerido</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="module === 'Employer'">
          <mat-form-field class="input-field">
            <label><div>COMENTARIO</div></label>
            <textarea matInput [(ngModel)]="commentary" maxlength="280" rows="5"></textarea>
            <mat-error>Requerido</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="actions-dialog border-bottom-actions" style="padding: 0px 12px 8px 12px;" *ngIf="!data.typeGestor">
        <button mat-button class="btn-cancelar" (click)="onNoClick()" >{{(data.typeOperator === 'show')? 'CERRAR': 'CANCELAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-aceptar" *ngIf="files.length !== 0" (click)="submitInitDocument()">INICIAR</button>
      </div>

      <div *ngIf="storiesFiles.length === 0">
        <div class="document-content">
          <label>0 DOCUMENTOS GUARDADOS</label>
        </div>
      </div>

      <div>
        <div *ngIf="storiesFiles.length > 0">
          <div class="subtitleAc">ACTUALIZACIONES</div>
          <div class="history-scroll" style="width: 100%;">
            <div class="history" *ngFor="let story of storiesFiles; let i = index">
              <div class="state" style="color: #a0a0a0 !important;">
                <div class="state-content">
                  <div class="itemState">{{story.StateDescription}} Actualizado:</div>
                  <div class="itemState">{{getDateFormatR(story?.Date) || 'Fecha no reportada' }} {{getDateFormatRMinutes(story?.Date) || '' }} </div>
                  <div class="itemStateIcon"> <div>Por:</div> <mat-icon matTooltipPosition="above" [matTooltip]="'Usuario: ' + (story?.UserName !== undefined ? story?.UserName : 'No reportado')">account_circle</mat-icon></div>
                </div>
                <div class="state-document" *ngIf="story?.Key !== '' && story?.Key !== null">
                  <div class="itemState"><div>Documento:</div></div>
                  <div class="itemState-nameFile" matTooltipPosition="above" [matTooltip]="stateCurrent.Name">{{story.Name}}</div>
                  <div class="itemStateIcon"><mat-icon class="iconView" (click)="openImgVideoUrl(story.Key)">visibility</mat-icon></div>
                </div>
              </div>
            </div>

          </div>
          <app-title-card-preview
            *ngIf="storiesFiles.length === 0"
            style="width: 100%;"
            [icon]="'help'"
            [text]="'Aqui se visualiza el historial'"
            [textTooltip]="'Aqui se visualiza el historial'"
          ></app-title-card-preview>
        </div>
      </div>
    </div>

    <!--DOCREQ-->
    <div  style="width: 100%" *ngIf="typeShowDialog === 2 && exitPermissionCompany('massuploadpersonal.updaterequirement')">
      <div class="info-box">
        <p><strong>Pasos:</strong></p>
        <p>1. Descargue el archivo con el formato</p>
        <p>2. Diligencie los datos en el archivo descargado</p>
        <p>3. Cargue el archivo</p>
        <p><strong>Nota:</strong> En cada carga solo se permiten 100 registros</p>
      </div>

      <div class="content-icono">
        <p class="download-text"><strong>Formato para descargar</strong></p>
        <div class="format-download">
          <a [href]="urlFormat" download>
            <mat-icon class="download-icon">
              <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="36px" fill="#5f6368">
                <path d="m720-120 160-160-56-56-64 64v-167h-80v167l-64-64-56 56 160 160ZM560 0v-80h320V0H560ZM240-160q-33 0-56.5-23.5T160-240v-560q0-33 23.5-56.5T240-880h280l240 240v121h-80v-81H480v-200H240v560h240v80H240Zm0-80v-560 560Z"/>
              </svg>
            </mat-icon>
          </a>
        </div>
      </div>

      <div class="sub-header">
        <div class="sub-content-1 greendAnalityco">{{'SELECCIONAR REQUISITO' || 'N/A'}}</div>
      </div>

      <div class="fields-group">
        <app-select-search-custom
          class="selected"
          [_dataSource]="requirementToSelect" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="controlSearchRequirementSelect"
          [inputType]="'text'" (onSelectionChange)="changeRequirementSelect($event)"></app-select-search-custom>
      </div>

      <div class="sub-header" *ngIf="showEmployerSelect">
        <div class="sub-content-1 greendAnalityco">{{'SELECCIONAR EMPLEADOR' || 'N/A'}}</div>
      </div>

      <div class="fields-group" *ngIf="showEmployerSelect">
        <app-select-search
          [_dataSource]="employerToSelect" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="controlSearchEmployer"
          [inputType]="'text'" (onSelectionChange)="changeEmployerSelect($event)"></app-select-search>
      </div>

      <div class="sub-header">
        <div class="sub-content-2 greendAnalityco">{{'CARGAR FORMATO DILIGENCIADO' || 'N/A'}}</div>
      </div>
      <div class="content-show" style="padding-top: 12px;" *ngIf="showFormatLoad">
        <div>
          <ngx-dropzone  *ngIf="filesFormatLoad.length === 0" (change)="onSelectFormatLoad($event)" class="dropzone" [multiple]="false">
            <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
          </ngx-dropzone>
        </div>
        <!-- Sección para mostrar el archivo subido -->
        <div *ngIf="filesFormatLoad.length > 0" class="uploaded-file">
          <span class="file-name">{{ filesFormatLoad[0]?.name }}</span>
          <button mat-icon-button class="remove-button" (click)="removeFileFormatLoad()">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </div>

      <div class="sub-header">
        <div class="sub-content-2 greendAnalityco">{{'CARGAR DOCUMENTO' || 'N/A'}}</div>
      </div>
      <div class="content-show" style="padding-top: 12px;" *ngIf="showDocumentLoad">
        <div >
          <ngx-dropzone  *ngIf="filesLoadDocument.length === 0" (change)="onSelectLoadDocument($event)" class="dropzone" [multiple]="false">
            <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
          </ngx-dropzone>
        </div>


        <!-- Sección para mostrar el archivo subido -->
        <div *ngIf="filesLoadDocument.length > 0" class="uploaded-file">
          <span class="file-name">{{ filesLoadDocument[0]?.name }}</span>
          <button mat-icon-button class="remove-button" (click)="removeFileLoadDocument()">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>

        <div *ngIf="module === 'Personal'">
          <mat-form-field class="input-field">
            <label><div>COMENTARIO</div></label>
            <textarea matInput [(ngModel)]="commentaryDocReq" maxlength="280" rows="5"></textarea>
            <mat-error>Requerido</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="actions-dialog border-bottom-actions" style="padding: 0px 12px 8px 12px;" *ngIf="!data.typeGestor">
        <button mat-button class="btn-cancelar" (click)="onNoClick()" >{{(data.typeOperator === 'show')? 'CERRAR': 'CANCELAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-aceptar" *ngIf="filesLoadDocument.length !== 0" (click)="submitInitDocreq()">GUARDAR</button>
      </div>

      <div *ngIf="storiesFilesDoc?.length === 0 || storiesFilesDoc === undefined">
        <div class="document-content">
          <label>0 DOCUMENTOS GUARDADOS</label>
        </div>
      </div>
      <div>
        <div *ngIf="storiesFilesDoc?.length > 0">
          <div class="subtitleAc">ACTUALIZACIONES</div>
          <div class="history-scroll-docreq" style="width: 300px;">
            <div class="history-docreq" *ngFor="let story of storiesFilesDoc; let i = index">
              <div class="state-docreq" style="color: #a0a0a0 !important;">
                <div class="state-content-docreq">
                  <div class="itemState-doc">{{story.StateDescription}} Actualizado:</div>
                  <div class="itemState-doc" style="margin-left: 10px">{{getDateFormatR(story?.Date) || 'Fecha no reportada' }} {{getDateFormatRMinutes(story?.Date) || '' }} </div>
                  <div class="itemStateIcon-doc"> <div>Por:</div> <mat-icon matTooltipPosition="above" [matTooltip]="'Usuario: ' + (story?.UserName !== undefined ? story?.UserName : 'No reportado')">account_circle</mat-icon></div>
                </div>
                <div class="state-document-req" *ngIf="story?.Key !== '' && story?.Key !== null">
                  <div class="itemState-doc"><div>Documento:</div></div>
                  <div class="itemState-nameFile-doc" matTooltipPosition="above" [matTooltip]="stateCurrentDocReq.docName">{{story.docName }}</div>
                  <div class="itemStateIcon-doc"><mat-icon class="iconView-doc" (click)="openImgVideoUrl(story.docPath)">visibility</mat-icon></div>
                </div>
                <div class="state-document-req" *ngIf="story?.Comments !== '' && story?.Comments !== null">
                  <div class="itemState-doc"><div>Comentario:</div></div>
                  <div class="itemState-nameFile-doc"  matTooltipPosition="above" >{{story.Comments}}</div>
                </div>
              </div>
            </div>

          </div>
          <app-title-card-preview
            *ngIf="storiesFilesDoc?.length === 0"
            style="width: 100%;"
            [icon]="'help'"
            [text]="'Aqui se visualiza el historial'"
            [textTooltip]="'Aqui se visualiza el historial'"
          ></app-title-card-preview>
        </div>
      </div>
    </div>

    <!-- FECHA DE UN REQUISITO -->
    <div style="width: 100%" *ngIf="typeShowDialog === 3 && exitPermissionCompany('massuploadpersonal.updaterequirementdate')">

      <div class="info-box">
        <p><strong>Pasos:</strong></p>
        <p>1. Descargue el archivo con el formato</p>
        <p>2. Diligencie los datos en el archivo descargado</p>
        <p>3. Cargue el archivo</p>
        <p><strong>Nota:</strong> En cada carga solo se permiten 100 registros</p>
      </div>

      <div class="content-icono">
        <p class="download-text"><strong>Formato para descargar</strong></p>
        <div class="format-download">
          <a [href]="urlFormat" download>
            <mat-icon class="download-icon">
              <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="36px" fill="#5f6368">
                <path d="m720-120 160-160-56-56-64 64v-167h-80v167l-64-64-56 56 160 160ZM560 0v-80h320V0H560ZM240-160q-33 0-56.5-23.5T160-240v-560q0-33 23.5-56.5T240-880h280l240 240v121h-80v-81H480v-200H240v560h240v80H240Zm0-80v-560 560Z"/>
              </svg>
            </mat-icon>
          </a>
        </div>
      </div>

      <div class="sub-header">
        <div class="sub-content-1 greendAnalityco">{{'SELECCIONAR REQUISITO' || 'N/A'}}</div>
      </div>

      <div class="fields-group">
        <app-select-search-custom
          [_dataSource]="DateReqToSelect" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="controlSearchDateReq"
          [inputType]="'text'" (onSelectionChange)="changeDateReqSelect($event)">
        </app-select-search-custom>
      </div>

      <div class="sub-header" *ngIf="showDateReqSelect">
        <div class="sub-content-1 greendAnalityco">{{'SELECCIONAR EMPLEADOR' || 'N/A'}}</div>
      </div>

      <div class="fields-group" *ngIf="showDateReqSelect">
        <app-select-search
          [_dataSource]="DateReqEmployerToSelect" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="controlSearchDateReqEmployer"
          [inputType]="'text'" (onSelectionChange)="changeDateReqEmployer($event)"></app-select-search>
      </div>

      <div class="sub-header">
        <div class="sub-content-2 greendAnalityco">{{'CARGAR FORMATO DILIGENCIADO' || 'N/A'}}</div>
      </div>
      <div class="content-show" *ngIf="showDateReqFormatLoad">

        <div>
          <ngx-dropzone  *ngIf="filesDateReq.length === 0" (change)="onSelectDateReq($event)" class="dropzone" [multiple]="false">
            <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
          </ngx-dropzone>
        </div>

        <!-- Sección para mostrar el archivo subido -->
        <div *ngIf="filesDateReq.length > 0" class="uploaded-file">
          <span class="file-name">{{ filesDateReq[0]?.name }}</span>
          <button mat-icon-button class="remove-button" (click)="removeFileDateReq()">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>

        <div *ngIf="module === 'Personal'">
          <mat-form-field class="input-field">
            <label><div>COMENTARIO</div></label>
            <textarea matInput [(ngModel)]="commentaryDateReq" maxlength="280" rows="5"></textarea>
            <mat-error>Requerido</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="actions-dialog border-bottom-actions" style="padding: 0px 12px 8px 12px;" *ngIf="!data.typeGestor">
        <button mat-button class="btn-cancelar" (click)="onNoClick()" >{{(data.typeOperator === 'show')? 'CERRAR': 'CANCELAR'}}</button>
        <button mat-button class="btn-aceptar"  style="margin-left: 12px;" *ngIf="filesDateReq.length !== 0" (click)="submitInitFechreq()">GUARDAR</button>
      </div>

      <div *ngIf="storiesFilesDateReq?.length === 0 || storiesFilesDateReq === undefined">
        <div class="document-content">
          <label>0 DOCUMENTOS GUARDADOS</label>
        </div>
      </div>

      <div>
        <div *ngIf="storiesFilesDateReq?.length > 0">
          <div class="subtitleAc">ACTUALIZACIONES</div>
          <div class="history-scroll-docreq" style="width: 300px;">
            <div class="history-docreq" *ngFor="let story of storiesFilesDateReq; let i = index">
              <div class="state-docreq" style="color: #a0a0a0 !important;">
                <div class="state-content-docreq">
                  <div class="itemState-doc">{{story.StateDescription}} Actualizado:</div>
                  <div class="itemState-doc" style="margin-left: 10px">{{getDateFormatR(story?.Date) || 'Fecha no reportada' }} {{getDateFormatRMinutes(story?.Date) || '' }} </div>
                  <div class="itemStateIcon-doc"> <div>Por:</div> <mat-icon matTooltipPosition="above" [matTooltip]="'Usuario: ' + (story?.UserName !== undefined ? story?.UserName : 'No reportado')">account_circle</mat-icon></div>
                </div>
                <div class="state-document-req" *ngIf="story?.Key !== '' && story?.Key !== null">
                  <div class="itemState-doc"><div>Documento:</div></div>
                  <div class="itemState-nameFile-doc" matTooltipPosition="above" [matTooltip]="stateCurrentDateReq.docName">{{story.docName}}</div>
                  <div class="itemStateIcon-doc"><mat-icon class="iconView-doc" (click)="openImgVideoUrl(story.docPath)">visibility</mat-icon></div>
                </div>
                <div class="state-document-req"  *ngIf="story?.Comments !== '' && story?.Comments !== null">
                  <div class="itemState-doc"><div>Comentario:</div></div>
                  <div class="itemState-nameFile-doc" matTooltipPosition="above">{{story.Comments}}</div>
                </div>
              </div>
            </div>

          </div>
          <app-title-card-preview
            *ngIf="storiesFilesDateReq?.length === 0"
            style="width: 100%;"
            [icon]="'help'"
            [text]="'Aqui se visualiza el historial'"
            [textTooltip]="'Aqui se visualiza el historial'"
          ></app-title-card-preview>
        </div>
      </div>
    </div>

    <!-- TERMINATION -->
    <div style="width: 100%" *ngIf="module === 'Termination'">
      <div class="info-box">
        <p><strong>Pasos:</strong></p>
        <p>1. Descargue el archivo con el formato</p>
        <p>2. Diligencie los datos en el archivo descargado</p>
        <p>3. Cargue el archivo</p>
        <p><strong>Nota:</strong> En cada carga solo se permiten 100 registros</p>
      </div>

      <div class="content-icono">
        <p class="download-text"><strong>Formato para descargar</strong></p>
        <div class="format-download">
          <a [href]="urlFormat" download>
            <mat-icon class="download-icon">
              <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="36px" fill="#5f6368">
                <path d="m720-120 160-160-56-56-64 64v-167h-80v167l-64-64-56 56 160 160ZM560 0v-80h320V0H560ZM240-160q-33 0-56.5-23.5T160-240v-560q0-33 23.5-56.5T240-880h280l240 240v121h-80v-81H480v-200H240v560h240v80H240Zm0-80v-560 560Z"/>
              </svg>
            </mat-icon>
          </a>
        </div>
      </div>

      <div class="sub-header">
        <div class="sub-content-1 greendAnalityco">{{'SELECCIONAR EMPLEADOR' || 'N/A'}}</div>
      </div>

      <div class="fields-group">
        <app-select-search
          [_dataSource]="TerminationEmployerToSelect" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="controlSearchTerminationEmployer"
          [inputType]="'text'" (onSelectionChange)="changeTerminationEmployer($event)">
        </app-select-search>
      </div>

      <div class="sub-header">
        <div class="sub-content-2 greendAnalityco">{{'CARGAR FORMATO DILIGENCIADO' || 'N/A'}}</div>
      </div>
      <div class="content-show" *ngIf="showTerminationFormatLoad">
        <div *ngIf="module === 'Termination'">
          <ngx-dropzone  *ngIf="filesTermination.length === 0" (change)="onSelectTermination($event)" class="dropzone" [multiple]="false">
            <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
          </ngx-dropzone>
        </div>

        <!-- Sección para mostrar el archivo subido -->
        <div *ngIf="filesTermination.length > 0" class="uploaded-file">
          <span class="file-name">{{ filesTermination[0]?.name }}</span>
          <button mat-icon-button class="remove-button" (click)="removeFileTermination()">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>

      <div class="sub-header">
        <div class="sub-content-Termination greendAnalityco">{{'SELECCIONAR ESTADO FINAL' || 'N/A'}}</div>
      </div>

      <div class="fields-group" *ngIf="showTerminationSelectFinalState">
        <app-select-search-custom
          [_dataSource]="TerminationToSelect" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="controlSearchTermination"
          [inputType]="'text'" (onSelectionChange)="changeTerminationSelect($event)">
        </app-select-search-custom>
      </div>

        <div *ngIf="module === 'Termination'">
          <mat-form-field class="input-field">
            <label><div>COMENTARIO</div></label>
            <textarea matInput [(ngModel)]="commentaryTermination" maxlength="280" rows="5"></textarea>
            <mat-error>Requerido</mat-error>
          </mat-form-field>
        </div>
      </div>


      <div class="actions-dialog border-bottom-actions" style="padding: 0px 12px 8px 12px;" *ngIf="!data.typeGestor">
        <button mat-button class="btn-cancelar" (click)="onNoClick()" >{{(data.typeOperator === 'show')? 'CERRAR': 'CANCELAR'}}</button>
        <button mat-button class="btn-aceptar"  style="margin-left: 12px;" *ngIf="filesTermination.length !== 0 && showBtnSaveTermination === true"
                [disabled]="!exitPermissionCompany('massuploadtermination.changestate')"
                (click)="submitInitTermination()">GUARDAR</button>
      </div>

      <div *ngIf="storiesFilesTermination?.length === 0 || storiesFilesTermination === undefined">
        <div class="document-content">
          <label>0 DOCUMENTOS GUARDADOS</label>
        </div>
      </div>

      <div>
        <div *ngIf="storiesFilesTermination?.length > 0">
          <div class="subtitleAc">ACTUALIZACIONES</div>
          <div class="history-scroll" style="width: 100%; padding: 0px 0px 0px 12px;">
            <div class="history" *ngFor="let story of storiesFilesTermination; let i = index">
              <div class="state" style="color: #a0a0a0 !important;">
                <div class="state-content">
                  <div class="itemState">{{getDateFormatR(story?.Date) || 'Fecha no reportada' }} {{getDateFormatRMinutes(story?.Date) || '' }} </div>
                </div>
                <div class="state-document" *ngIf="story?.Key !== '' && story?.Key !== null">
                  <div class="itemState"><div>Documento:</div></div>
                  <div class="itemState-nameFile" matTooltipPosition="above" [matTooltip]="stateCurrentTermination.Name">{{story.Name}}</div>
                  <div class="itemStateIcon"><mat-icon class="iconView" (click)="openImgVideoUrl(story.Key)">visibility</mat-icon></div>
                </div>
                <div class="state-document-req"  *ngIf="story?.Comments !== '' && story?.Comments !== null">
                  <div class="itemState-doc"><div>Comentario:</div></div>
                  <div class="itemState-nameFile-doc" matTooltipPosition="above">{{story.Comments}}</div>
                </div>
              </div>
            </div>

          </div>
          <app-title-card-preview
            *ngIf="storiesFilesTermination?.length === 0"
            style="width: 100%;"
            [icon]="'help'"
            [text]="'Aqui se visualiza el historial'"
            [textTooltip]="'Aqui se visualiza el historial'"
          ></app-title-card-preview>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isProcessing" class="content-processing">
    <label class="processing-int">PROCESANDO</label>
    <mat-progress-bar mode="query" [value]="20" *ngIf="true"></mat-progress-bar>
  </div>

</div>
