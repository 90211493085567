<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{data.title || 'DOCUMENTO Y REQUISITO DE ACCESO' || 'N/A'}}</div>
    </div>
    
    <div class="content-card list-card" >
        <form [formGroup]="form" autocomplete="off">
            
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>NOMBRE</strong></label>
                <input matInput type="text" formControlName="Name">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>ABREVIATURA</strong></label>
                <input matInput type="text" formControlName="Abrv" maxlength="3">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>DESCRIPCIÓN</strong></label>
                <textarea matInput formControlName="Description" maxlength="{{100}}"></textarea>
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <div class="form-group">
                <mat-form-field class="input-field-50" appearance="outline">
                    <label><strong>ORDEN</strong></label>
                    <input matInput type="number" formControlName="OrderNum"
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
                <div>
                    <mat-slide-toggle formControlName="IsActive"></mat-slide-toggle>&nbsp;
                    <label>ESTADO</label>
                </div>
            </div>
            <br>
            <div class="subtitle">Documento relacionado a:</div>
            <mat-button-toggle-group name="fontStyle" (change)="changeByPersonalOrEmployer($event)" aria-label="Font Style">
                <mat-button-toggle [checked]="form.get('ByPersonal').value" value="ByPersonal">EL EMPLEADO</mat-button-toggle>
                <mat-button-toggle [checked]="form.get('ByEmployer').value" value="ByEmployer">EL EMPLEADOR</mat-button-toggle>
                <mat-button-toggle [checked]="form.get('ByEmployerPersonal').value" value="ByEmployerPersonal">EL EMPLEADO Y SU EMPLEADOR</mat-button-toggle>
            </mat-button-toggle-group>
            <br>
            <br>
            <div class="subtitle">Metodo de validación:</div>
            <div class="inputTogle">
                <mat-slide-toggle formControlName="ByCertification" (change)="changeToggle('ByCertification',$event.checked)"></mat-slide-toggle>&nbsp;
                <label>Por certificación</label>
            </div>
            <div class="card-check" *ngIf="form.get('ByCertification').value === true">
                <div class="inputTogle">
                    <mat-slide-toggle formControlName="ByDate"></mat-slide-toggle>&nbsp;
                    <label>Establecer una fecha de vigencia</label>
                </div>
                
            </div>
            <div class="dateLabel" *ngIf="form.get('ByDate').value === true">
                <div>Titulo del campo de fecha</div>
                <app-select-search class="select-component-medium"
                    [appearance]="'outline'"
                    [_dataSource]="dateLabel" [textInput]="''" [dataShow]="'Description'" [dataValue]="'Id'" [siteCtrl]="form.get('DateLabelId')"
                    [inputType]="'text'">
                </app-select-search>
            </div>
            <div class="inputTogle">
                <mat-slide-toggle formControlName="ByEntry" (change)="changeToggle('ByEntry', $event.checked)"></mat-slide-toggle>&nbsp;
                <label>Por inspección</label>
            </div>
            <div class="card-check" *ngIf="form.get('ByEntry').value === true">
                <div class="inputTogle">
                    <mat-slide-toggle formControlName="EnableInputEntry"></mat-slide-toggle>&nbsp;
                    <label>Digitar campo de registro</label>
                    <mat-form-field appearance="outline" class="input-field-30">
                        <mat-select formControlName="ArithmeticOperator">
                          <mat-option [value]="0"><</mat-option>
                          <mat-option [value]="1"><=</mat-option>
                          <mat-option [value]="2">></mat-option>
                          <mat-option [value]="3">>=</mat-option>
                          <mat-option [value]="4">=</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="input-field-30" appearance="outline">
                        <!-- <label><strong>ORDEN</strong></label> -->
                        <input matInput type="number" formControlName="EntryValue" [min]="0"
                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="inputTogle">
                <mat-slide-toggle formControlName="BySecuritySocial" (change)="changeToggle('BySecuritySocial', $event.checked)"></mat-slide-toggle>&nbsp;
                <label>Por seguridad social</label>
            </div>
            <div class="inputTogle">
                <mat-slide-toggle formControlName="BySurvey" (change)="changeToggle('BySurvey', $event.checked)"></mat-slide-toggle>&nbsp;
                <label>Por encuesta</label>
            </div>
            <div class="card-check" *ngIf="form.get('BySurvey').value === true">
                <div class="inputTogle">
                    <mat-slide-toggle formControlName="RepeatEveryDay"></mat-slide-toggle>&nbsp;
                    <label>Establecer una fecha de vigencia de 1 día</label>
                </div>
            </div>
        </form>
    </div>

    <div class="btn-actions"style="margin: 3px;">
        <button mat-button class="btn-cancelar" (click)="close()">{{'CANCELAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" (click)="save()">{{data.Id !== undefined ? 'ACTUALIZAR': 'GUARDAR'}}</button>
    </div>
</div>
