<div class="content">
    <div class="caja">
        <img [src]="getUrl(type)" alt="" [width]="width" [height]="height">
        <div [ngClass]="color" *ngIf="text" style="font-size: 11.2px;">{{text}}
            <div>{{IsBlocked}}</div>
            <mat-icon  *ngIf="IsBlocked !== undefined" matTooltipPosition="above" matTooltip="Cerrado" class="mat-icon-lock">lock</mat-icon>
        </div>
        <div [ngClass]="color" *ngIf="IsBlocked">
            <mat-icon matTooltipPosition="above" matTooltip="Cerrado" class="mat-icon-lock" >lock</mat-icon>
        </div>
    </div>
</div>
