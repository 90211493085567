<div class="box information-fields">
  <div class="header" [ngClass]="color || 'primary'">
    <div class="content">
      {{ title || "N/A" }}
    </div>
    <mat-icon class="btnHeader" *ngIf="icon" (click)="onClickBtnTitle()">
      {{ icon }}
    </mat-icon>
  </div>

  <div class="content-card">
    <ng-content select="[header]"></ng-content>
    <ng-content select="[body]"></ng-content>
  </div>
</div>
