<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'CREAR EMPRESA' || 'N/A'}}</div>
    </div>
    <div class="content-card list-card" >


            <!-- TITLE AND PHOTO -->
        <button class="uploadPhoto" [ngClass]="formDataPhoto === undefined ? 'borderBtn': ''" (click)="updatePhotoEmployer()">CARGAR LOGO EMPRESARIAL</button>
        <img style="margin-bottom: 10px;" *ngIf="imgCropperResult" [src]="imgCropperResult" alt="">
            <!-- <div><strong>{{personal.Name + ' ' + personal.LastName || 'Sin Title'}}</strong></div> -->
            <div class="fields-group">
                <mat-form-field class="input-field-short">
                    <label><strong>TIPO</strong></label>
                    <mat-select [(ngModel)]="documentType" disabled="{{true}}">
                        <mat-option *ngFor="let data of companyDocumentType" [value]="data.Value">
                            {{data.Value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="input-field">
                    <label><strong>NÚMERO</strong></label>
                    <!-- pattern="/^-?\d+\.?\d*$/" -->
                    <input matInput type="number" [(ngModel)]="documentNumber"  maxlength="{{maxCaracter}}" (ngModelChange)="validateNit()" disabled="{{true}}">
                    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="setValuePersonal()" disabled="{{true}}">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <div *ngIf="documentType === 'NIT'" class="nitSeparator">-</div>
                <mat-form-field class="input-field-short" *ngIf="documentType === 'NIT'">
                    <!-- pattern="/^-?\d+\.?\d*$/" -->
                    <input matInput type="number" [(ngModel)]="digito" maxlength="1" (ngModelChange)="validateNit()" disabled="{{true}}">
                </mat-form-field>
            </div>
            <div class="NITinvalido"  *ngIf="documentType === 'NIT' && flagValidNit === false">
                NIT invalido
            </div>
        <form [formGroup]="formEmployer" (ngSubmit)="onSubmit()">
            <mat-form-field class="input-field">
                <label><strong>RAZÓN SOCIAL</strong></label>
                <input matInput type="text" formControlName="Name" #inputName (input)="inputName.value=$event.target.value.toUpperCase()">
                <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formEmployer.get('Name').setValue('')">X</div> -->
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="input-field">
                <label><strong>ROL</strong></label>
                <input matInput type="text" formControlName="Rol" maxlength="{{maxCaracterRol}}">
                <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formEmployer.get('Rol').setValue('')">X</div> -->
                <mat-error>Requerido</mat-error>
            </mat-form-field>

            <!-- <div class="sub-header">
                <div class="sub-content">{{'OFICINA PRINCIPAL'}}</div>
            </div>
            <br>
            <app-select-search class="select-component-short"
                        [_dataSource]="daneCity" [textInput]="'CIUDAD'" [dataShow]="'CityName'" [dataValue]="'CityCode'" [siteCtrl]="formEmployer.get('CityCode')"
                        [inputType]="'text'">
            </app-select-search>
            <mat-form-field class="input-field">
                <label><strong>DIRECCIÓN</strong></label>
                <input matInput type="text" formControlName="Address">
                <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formEmployer.get('Address').setValue('')">X</div>
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <div class="fields-group">
                <mat-form-field class="input-field-medium">
                    <label><strong>CORREO ELECTRÓNICO</strong></label>
                    <input matInput type="text" formControlName="Email">
                    <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formEmployer.get('Email').setValue('')">X</div>
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
                <mat-form-field class="input-field-medium">
                    <label><strong>TELÉFONO</strong></label>
                    <input matInput type="text" formControlName="Phone">
                    <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formEmployer.get('Phone').setValue('')">X</div>
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
            </div>
            <div class="sub-header">
                <div class="sub-content">{{'MÁS INFORMACIÓN'}}</div>
            </div>
            <br>
            <div class="input-field-literal">
                <label><strong>EXONERADAS DE PAGO PARAFISCALES: </strong></label>
                <mat-slide-toggle formControlName="IsExempt"></mat-slide-toggle>
            </div>
            <mat-form-field class="input-field">
                <label><strong>SERVICIOS PRESTADOS</strong></label>
                <input matInput type="text" formControlName="ServicesProvided">
                <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formEmployer.get('ServicesProvided').setValue('')">X</div>
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="input-field">
                <label><strong>SITIO WEB</strong></label>
                <input matInput type="text" formControlName="Website">
                <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formEmployer.get('Website').setValue('')">X</div>
                <mat-error>Requerido</mat-error>
            </mat-form-field>-->
            <app-select-search class="select-component-short"
                        [_dataSource]="arl" [textInput]="'ARL'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formEmployer.get('ArlId')"
                        [inputType]="'text'">
            </app-select-search>
            <!-- <mat-form-field class="chip-list">
                <mat-label>CAJA DE COMPENSACIÓN</mat-label>
                <mat-chip-list #chipList aria-label="Fruit selection">
                  <mat-chip
                    *ngFor="let values of viewValues"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(values)">
                    {{values}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                  <input
                    placeholder="+Seleccionar"
                    #InputControl
                    [formControl]="control"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    (matChipInputTokenEnd)="add($event)"
                    >
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <mat-option *ngFor="let item of CajadeCompensacionObs" [value]="item.Id">
                    {{item.Name || ''}}
                  </mat-option>
                </mat-autocomplete>
            </mat-form-field> -->




            <div class="actions-dialog"style="margin: 3px;">
                <button mat-button class="btn-cancelar" (click)="onNoClick()" type="button">CANCELAR</button>&nbsp;&nbsp;
                <button mat-button type="submit" class="btn-generar">GUARDAR</button>
                <!-- [disabled]="formDataPhoto === undefined || formPersonal.status === 'INVALID'" -->
                <!-- [ngClass]="formDataPhoto === undefined  || formPersonal.status === 'INVALID' ? 'btn-disabled': 'btn-generar'" -->

            </div>
        </form>
        <!-- <br> -->

    </div>
</div>
