import { Injectable, EventEmitter } from '@angular/core';
import { FilterArray } from '../../interfaces/interfaces';


@Injectable({
  providedIn: 'root'
})
export class FilterMultipleService {

  filter$ = new EventEmitter<FilterArray[]>();

  constructor() {}

  exitFilter(data: any, value: string): boolean {
    let exit = false
    if(data !== null && data !== undefined){
      data.trim().toUpperCase().includes(value.toString().trim().toUpperCase()) ? exit = true: exit = false;
    }
    return exit;
  }

}