import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { DialogService } from 'src/app/core/services/utils/dialog.service';
import { environment } from 'src/environments/environment';

export interface DialogData {
  Id: string;
}

@Component({
  selector: 'app-dialog-security-reference',
  templateUrl: './dialog-security-reference.component.html',
  styleUrls: ['./dialog-security-reference.component.scss']
})
export class DialogSecurityReferenceComponent implements OnInit {
  s3Bucket:string;

  form: FormGroup;
  imgCropperResult: string;
  
  constructor(public dialogRef: MatDialogRef<DialogSecurityReferenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonService: CommonService,
    private dialogService: DialogService,
    private fb: FormBuilder) { 
      this.form = this.fb.group({
        Code: new FormControl('',Validators.required),
        CreateDate: new FormControl(new Date()),
        Description: new FormControl('',Validators.required),
        DocNumber: new FormControl('',Validators.required),
        IsActive: new FormControl(false,Validators.required),
        Logo: '',
        Name: new FormControl('',Validators.required),
        OrderNum: new FormControl(''),
        Type: new FormControl('',Validators.required)
      });
      if(this.data.Id !== undefined) {
        this.commonService.getSecurityReferencesId(this.data.Id).subscribe((rest: any) => {
          this.form.get('Code').setValue(rest.Code);
          this.form.get('CreateDate').setValue(rest.CreateDate);
          this.form.get('Description').setValue(rest.Description);
          this.form.get('DocNumber').setValue(rest.DocNumber);
          this.form.get('IsActive').setValue(rest.IsActive);
          this.form.get('Logo').setValue(rest.Logo);
          this.form.get('Name').setValue(rest.Name);
          this.form.get('OrderNum').setValue(rest.OrderNum);
          this.form.get('Type').setValue(rest.Type);
          this.imgCropperResult = this.getImg(rest.Logo);
        });
      }
    }

  ngOnInit(): void {
    this.s3Bucket=environment.api.s3Bucket;
  }

  getImg(logo) {
    let img = (logo === null || logo === '' ? 'assets/images/personblue.png': logo); 
    return this.s3Bucket+img;
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    if(this.form.status === 'VALID')  {
      if(this.data.Id !== undefined) {
        let model = {
          ...this.form.value,
          Id: this.data.Id
        }
        this.commonService.putSecurityReferencesId(this.data.Id, model).subscribe(rest => {
          if(this.formDataPhoto === undefined) {
            this.dialogRef.close(true);
          } else {
            this.commonService.putSecurityReferencesUpdateFileId(this.data.Id, this.formDataPhoto).subscribe(rest => {
              this.dialogRef.close(true);
            });
          }
          
        })
      } else {
        this.commonService.postSecurityReferences(this.form.value).subscribe((rest: any) => {
          if(this.formDataPhoto === undefined) {
            this.dialogRef.close(true);
          } else {
            this.commonService.putSecurityReferencesUpdateFileId(rest.Id, this.formDataPhoto).subscribe(rest => {
              this.dialogRef.close(true);
            });
          }
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  formDataPhoto: FormData;
  updatePhotoPersonal() {
    this.dialogService.openDialogGestorImg('CARGAR IMAGEN',[],'editPhoto', 0, 'true', true).afterClosed().subscribe(formDataPhoto =>{
      if(formDataPhoto !== undefined) {
        this.imgCropperResult = formDataPhoto.imgResult;
        this.formDataPhoto = formDataPhoto.FormData;
      }
      // rest !== undefined ? this.formDataPhoto = rest : this.formDataPhoto = this.formDataPhoto;
    });
  }

}
