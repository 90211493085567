<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'NOVEDADES' || 'N/A'}}</div>
    </div>
    
        <div class="content-card list-card" >
            
            <div class="sub-header">
                <div class="sub-content greendAnalityco">{{'LUGAR DE TRABAJO' || 'N/A'}}</div>
            </div>
            <app-select-search class="select-component" *ngIf="employerSelected.length === 0"
                                    [appearance]="'outline'"
                                    [_dataSource]="projectLaboral" [textInput]="''" [dataShow]="'ProjectName'" [dataValue]="'ProjectId'" [siteCtrl]="formNovelty.get('ProjectId')"
                                    [inputType]="'text'" (onSelectionChange)="getPersonalReportProjectLabor()">
                                    </app-select-search>
            <div class="card" *ngFor="let employer of employerSelected">
                <div class="card-content">
                    <img src="{{s3Bucket+getImg(employer.ProjectLogo)}}" alt="">
                    <div class="content-information">
                        <div class="ellipsis" style="max-width: 200px;">{{employer.ProjectName || ''}}</div>
                        <div class="ellipsis baseline">{{employer.AdministratorName}}</div>
                        <div class="ellipsis baseline">{{employer.AdministratorDocumentType}}: {{employer.AdministratorDocumentNumber || ''}}</div>
                    </div>
                </div>
                <mat-icon class="icon-close" (click)="setValueProject()" *ngIf="data.edit === false">close</mat-icon>
            </div>
            <hr>
            <div class="sub-header">
                <div class="sub-content greendAnalityco">{{'EMPLEADO' || 'N/A'}}</div>
            </div>
            <app-select-search class="select-component" *ngIf="personalSelected.length === 0"
                                    [appearance]="'outline'"
                                    [_dataSource]="personalLaboral" [textInput]="''" [dataShow]="'PersonalName'" [otherFilter]="'PersonalDocumentNumber'" [dataValue]="'PersonalEmployerId'" [siteCtrl]="formNovelty.get('PersonalEmployerId')"
                                    [inputType]="'text'" (onSelectionChange)="getPersonalReportPersonalLabor()">
            </app-select-search>
            <div class="card" *ngFor="let personal of personalSelected">
                <div class="card-content">
                    <img src="{{s3Bucket+getImg(personal.PersonalPhoto)}}" alt="">
                    <div class="content-information">
                        <div class="ellipsis" style="max-width: 200px;">{{personal.PersonalName || ''}} {{personal.PersonalLastName || ''}}</div>
                        <div class="ellipsis baseline">{{personal.DocumentType}}: {{personal.PersonalDocumentNumber || ''}}</div>
                    </div>
                </div>
                <mat-icon class="icon-close" (click)="setValuePersonal()" *ngIf="data.edit === false">close</mat-icon>
            </div>
            <!--<div class="fields-group" *ngIf="employerSelected.length === 1 && personalSelected.length !==1">
                <mat-form-field class="input-field-short" appearance="outline">
                    <label><strong>TIPO</strong></label>
                    <mat-select [(ngModel)]="documentType" (selectionChange)="changeType($event.value)">
                        <mat-option *ngFor="let data of companyDocumentType" [value]="data.Value">
                            {{data.Value === 'CC' ? 'Cédula': (data.Value === 'PE' ? 'Permiso especial de permanecía': 'Cedula de extranjeria')}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> 
                <mat-form-field class="input-field-50" appearance="outline">
                    <label><strong>Numero</strong></label>
                    <input matInput type="text" [(ngModel)]="documentNumber" pattern="/^-?\d+\.?\d*$/" maxlength="{{maxCaracter}}">
                    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="setValuePersonal()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <div class="btn-save" (click)="getPersonalDocument()">
                    <button mat-mini-fab color="primary" matTooltip="buscar" >
                    <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div> -->
            <div class="sub-header">
                <div class="sub-content greendAnalityco">{{'MOTIVO DE LA NOVEDAD' || 'N/A'}}</div>
            </div>
            <form [formGroup]="formNoveltyMotivo">
                <app-select-search class="select-component"
                [appearance]="'outline'"
                [_dataSource]="this.motivos" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formNoveltyMotivo.get('NoveltyTypeId')"
                [inputType]="'text'">
                </app-select-search>
                <div class="fechasNovedad">
                    <div class="fecha">
                        <div class="itemKey">Inicio:&nbsp;&nbsp;</div>
                        <mat-form-field class="input-field-medium"  appearance="outline" >
                            <input matInput [matDatepicker]="pickerFilterStart" #inputDateFilterStart (click)="pickerFilterStart.open()" formControlName="StartDate">
                            <mat-datepicker #pickerFilterStart></mat-datepicker>
                            <mat-error>Requerido</mat-error>
                        </mat-form-field>
                        <!-- <mat-icon style="margin-bottom: 2px;" (click)="pickerFilterStart.open()">today</mat-icon> -->
                    </div>
                    <div class="fecha">
                        <div class="itemKey">Fin&nbsp;&nbsp;</div>  
                        <mat-form-field class="input-field-medium"  appearance="outline" >
                            <input matInput [matDatepicker]="pickerFilterEnd" #inputDateFilterEnd (click)="pickerFilterEnd.open()" formControlName="EndDate">
                            <mat-datepicker #pickerFilterEnd></mat-datepicker>
                            <mat-error>Requerido</mat-error>
                        </mat-form-field>
                        <!-- <mat-icon style="margin-bottom: 2px;" (click)="pickerFilterEnd.open()">today</mat-icon> -->
                    </div>  
                </div>  
            </form>
            <div class="actions-dialog"style="margin: 3px;">
                <button mat-button class="btn-cancelar" [mat-dialog-close]="data">CANCELAR</button>&nbsp;&nbsp;
                <button mat-button 
                        (click)="saveNovelty()"
                        [ngClass]="getDisabled() === true ? 'btn-generar':'btn-disabled'"
                        [disabled]="!getDisabled()">GUARDAR
                </button>
            </div>
        </div>
   
    
</div>