import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonOptions, City, Requirements, RequirementsContract, RequirementsProyect, ContractIdProyect, SecurityReferences } from '../../interfaces/interfaces';
import { NumberCountry } from '../../constants/number-city.constant';
import { SearchEmployerIdCompanyInfoId } from '../../models/Common/searchEmployerIdCompanyInfoId';
import { UserInfo } from '../../models/User';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

    constructor(
        private api: ApiService,
        private _http: HttpClient
    ) { }

    getUserInfo(): Observable<UserInfo> {
        return this._http.get<UserInfo>(this.api.getUrl(`api/userinfo`));
    }

    getRolesCampany(CompanyId) {
        return this._http.get(this.api.getUrl(`api/Roles/Company/${CompanyId}?company=true`));
    }

    getRolesCampanyAll(CompanyId) {
        return this._http.get(this.api.getUrl(`api/Roles/Company/${CompanyId}`));
    }

    getRoles() {
        return this._http.get(this.api.getUrl(`api/Roles`));
    }

    getPermissions() {
        return this._http.get(this.api.getUrl(`api/Permissions`));
    }


    //GET CommonOptions
    getCommonOptions(typeCommonOptions: string, token?): Observable<CommonOptions[]> {
        const url = this.api.getUrl('api/CommonOptions?Type=' + typeCommonOptions);
        if(token !== undefined) {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            });
            return this._http.get<CommonOptions[]>(url, { headers: headers });
        } else {
            return this._http.get<CommonOptions[]>(url);
        }

    }

    //GET Cities * country
    getCities(numbercContry: NumberCountry) : Observable<City[]> {
        const url = this.api.getUrl('api/City?country=' + numbercContry);
        return this._http.get<City[]>(url);
    }

    getDaneCity() {
        return this._http.get(this.api.getUrl(`api/DaneCity`));
    }

    getDaneCitySelectTrue() {
        return this._http.get(this.api.getUrl(`api/DaneCity?Select=true`));
    }

    getTownCity(cityCode) {
      return this._http.get(this.api.getUrl(`api/Town/City/${cityCode}`));
  }

    // Search Employer
    getSearchEmployerIdCompanyInfoId(companyInfoId: string, name: any): Observable<SearchEmployerIdCompanyInfoId> {
        return this._http.get<SearchEmployerIdCompanyInfoId>(this.api.getUrl(`api/Employer?companyInfoId=${companyInfoId}&name=${name}`));
    }

    // Search Employer new AllEmployers
    getSearchAllEmployersCompanyInfoId(companyInfoId: string, name: any): Observable<SearchEmployerIdCompanyInfoId> {
        return this._http.get<SearchEmployerIdCompanyInfoId>(this.api.getUrl(`api/Employer/AllEmployers?documentName=${name}`));
    }

    // Search Employer new AllEmployers Witch companyInfoId
    getSearchAllEmployersWitchAdministrador(companyInfoId: string, name: any): Observable<SearchEmployerIdCompanyInfoId> {
        return this._http.get<SearchEmployerIdCompanyInfoId>(this.api.getUrl(`api/Employer/AllEmployers?companyInfoId=${companyInfoId}&documentName=${name}`));
    }

    getAllEmployers(): Observable<SearchEmployerIdCompanyInfoId> {
        return this._http.get<SearchEmployerIdCompanyInfoId>(this.api.getUrl(`api/Employer/AllEmployers`));
    }

    getAllEmployersId(companyInfoId): Observable<SearchEmployerIdCompanyInfoId> {
        return this._http.get<SearchEmployerIdCompanyInfoId>(this.api.getUrl(`api/Employer/AllEmployers?companyInfoId=${companyInfoId}`));
    }

    getAllEmployersCompanyId(companyInfoId): Observable<SearchEmployerIdCompanyInfoId> {
        return this._http.get<SearchEmployerIdCompanyInfoId>(this.api.getUrl(`api/Employer/AllEmployers?companyId=${companyInfoId}`));
    }

    getEmployers(): Observable<any> {
        return this._http.get<any>(this.api.getUrl(`api/Employer`));
    }

    getSearchEmployerDocumentTypeName(documentType: string, name: any): Observable<SearchEmployerIdCompanyInfoId> {
        return this._http.get<SearchEmployerIdCompanyInfoId>(this.api.getUrl(`api/Employer?documentType=${documentType}&name=${name}`));
    }

    // Requirements for Company
    getRequirements(companyInfoId: string): Observable<Requirements[]>  {
        return this._http.get<Requirements[]>(this.api.getUrl(`api/Requirement?companyId=${companyInfoId}`));
    }

    // Requirements for all
    getRequirementsAll(): Observable<Requirements[]>  {
        return this._http.get<Requirements[]>(this.api.getUrl(`api/Requirement`));
    }

    // Requirements CONTRACT
    getRequirementsContract(contractId: string): Observable<RequirementsContract[]>  {
        return this._http.get<RequirementsContract[]>(this.api.getUrl(`api/Contract/${contractId}/Requirements`));
    }

    // Requirements PROYECT
    getRequirementsProyect(proyectId: string): Observable<RequirementsProyect[]>  {
        return this._http.get<RequirementsProyect[]>(this.api.getUrl(`api/Project/${proyectId}/Requirements`));
    }

    // Contract Id Proyect
    getContractIdProyect(contractId: string): Observable<ContractIdProyect[]>  {
        return this._http.get<ContractIdProyect[]>(this.api.getUrl(`api/Contract/${contractId}/Project`));
    }

    getTypeSecurityReferences(typeValue): Observable<SecurityReferences[]>  {
        return this._http.get<SecurityReferences[]>(this.api.getUrl(`api/SecurityReferences?Type=${typeValue}`));
    }

    getSecurityReferences() {
        return this.api.get('api/SecurityReferences');
    }

    getJobs() {
        return this.api.get('api/Job');
    }

    getProyect() {
        return this._http.get(this.api.getUrl(`api/Project`));
    }

    getContract() {
        return this._http.get(this.api.getUrl(`api/Contract`));
    }

    /**
     * Permite obtener las sucursales de company/employer
     */
    getBranchOffice() {
        return this._http.get(this.api.getUrl(`api/BranchOffice`));
    }

    /**
     * Permite obtener las sucursales de company/employer
     */
    postBranchOffice(model) {
        return this._http.post(this.api.getUrl(`api/BranchOffice`), model);
    }


    /**
     * Permite actualizar las sucursales de company/employer
     */
    putBranchOffice(Id, model) {
        return this._http.put(this.api.getUrl(`api/BranchOffice/${Id}`), model);
    }


    /**
     * Permite eliminar las sucursales de company/employer
     */
    deleteBranchOffice(Id) {
        return this._http.delete(this.api.getUrl(`api/BranchOffice/${Id}`));
    }

    /**
     * Permite obtener las EconomicActivity de company/employer
     */
     getEconomicActivity() {
        return this._http.get(this.api.getUrl(`api/EconomicActivity?Select=true`));
    }

    /**
     * Permite obtener los PositionType
     */
    getPositionType() {
        return this._http.get(this.api.getUrl(`api/Requirement/PositionType`));
    }

    /**
     * Permite guardar los PositionType por administradora
     */
    postPositionType(model) {
        return this._http.post(this.api.getUrl(`api/Requirement/PositionType`), model);
    }

    /**
     * Permite actualizar los PositionType
     */
    putPositionType(positionTypeId, model) {
        return this._http.put(this.api.getUrl(`api/Requirement/PositionType/${positionTypeId}`), model);
    }

    /**
     * Permite eliminar los PositionType
     */
    deletePositionType(positionTypeId) {
        return this._http.delete(this.api.getUrl(`api/Requirement/PositionType/${positionTypeId}`));
    }

    /**
     * Permite obtener los PositionType
     */
    getReqsPositionType(positionTypeId) {
        return this._http.get(this.api.getUrl(`api/Requirement/PositionType/Reqs/${positionTypeId}`));
    }

    /**
        * Permite ver lista completa requirement
    */
    getRequirement() {
        return this._http.get(this.api.getUrl(`api/Requirement`));
    }

    /**
     * Permite actualizar los requerimientos por adminsitrador y tipo de cargo
     */
    putRequirementPositionType(positionTypeId, model) {
        return this._http.put(this.api.getUrl(`api/Requirement/PositionType/Reqs/${positionTypeId}`), model);
    }

    /**
     * Permite filtrar los requerimientos por tipo de cargo
     */
    getDocumentRequirementFilterTypePosition(contractId, personalCompanyInfoId, positionTypeId) {
        return this._http.get(this.api.getUrl(`api/Requirement/DocumentRequirement/${contractId}/${personalCompanyInfoId}?positionTypeId=${positionTypeId}`));
    }

    /**
     * Permite filtrar los requerimientos por tipo de retiro
     */
    getDocumentRequirementFilterTerminationType(personalEmployerSnapshotId, terminationType) {
        return this._http.get(this.api.getUrl(`api/Requirement/DocumentRequirement/TerminationType/Snapshot/${personalEmployerSnapshotId}?terminationType=${terminationType}`));
    }

    putDocumentRequirementFilterTypeofWithdrawal(terminationId, model) {
        return this._http.put(this.api.getUrl(`api/PersonalTerminationRequest/Simple/${terminationId}`), model);
    }

    getRolesCompanyProject(companyId, type) {
        return this._http.get(this.api.getUrl(`api/Roles/Company/${companyId}?${type !== '' ? type + '=true' :  ''}`));
    }


    /**
     * Permite traer los permisos por type /NOTA: esta pegando al servidor de ANGULAR JS no a las apis tradicionar que se utiliza
     */
     getPermissionGUIType(PermissionType) {
        // return this._http.get(this.api.getUrl(`PermissionGUI/${PermissionType}`));
        // return this._http.get(`http://acceso-stg.analityco.com/PermissionGUI/${PermissionType}`);
        return this._http.get(this.api.getUrl(`api/Permissions/PermissionGui`));


    }

    /**
     * Permite traer roles por id
     */
    getRolesId(id) {
        return this._http.get(this.api.getUrl(`api/Roles/${id}`));
    }


    // CRUDPermissions
    /**
     * Permite actualizar los permisos
     */
     putPermissions(id, model) {
        return this._http.put(this.api.getUrl(`api/Permissions/${id}`), model);
    }
    /**
     * Permite guardar lospermisos
     */
     postPermissions(model) {
        return this._http.post(this.api.getUrl(`api/Permissions`), model);
    }

    /**
     * Permite eliminar los permisos
     */
    deletePermissions(id) {
        return this._http.delete(this.api.getUrl(`api/Permissions/${id}`));
    }


    /**
     * Services modulo administracion roles
     */
    postRoles(model)  {
        return this._http.post(this.api.getUrl(`api/Roles`), model);
    }

    putRoles(Id, model)  {
        return this._http.put(this.api.getUrl(`api/Roles/${Id}`), model);
    }

    postRolesCompany(CompanyId, model)  {
        return this._http.post(this.api.getUrl(`api/Roles/Company/${CompanyId}`), model);
    }

    putRolesCompany(IdRol, CompanyId, model)  {
        return this._http.put(this.api.getUrl(`api/Roles/${IdRol}/Company/${CompanyId}`), model);
    }

    deleteRoleCompany(IdRol, CompanyId)  {
        return this._http.delete(this.api.getUrl(`api/Roles/${IdRol}?CompanyId=${CompanyId}`));
    }

    deleteRole(Id)  {
        return this._http.delete(this.api.getUrl(`api/Roles/${Id}`));
    }

    postClaimsAddToRole(model)  {
        return this._http.post(this.api.getUrl(`api/Claims/AddToRole`), model);
    }

    /**
     * Services modulo references // COMMONOPTIONS
     */

    getCommonOptionsListAll()  {
        return this._http.get(this.api.getUrl(`api/CommonOptions`));
    }

    getCommonOptionsId(id)  {
        return this._http.get(this.api.getUrl(`api/CommonOptions/${id}`));
    }

    putCommonOptionsId(id, model)  {
        return this._http.put(this.api.getUrl(`api/CommonOptions/${id}`), model);
    }

    deleteCommonOptionsId(id)  {
        return this._http.delete(this.api.getUrl(`api/CommonOptions/${id}`));
    }

    postCommonOptions(model)  {
        return this._http.post(this.api.getUrl(`api/CommonOptions`), model);
    }

    /**
     * Services Requirement ALL
     */

    getRequirementAll()  {
        return this._http.get(this.api.getUrl(`api/Requirement/GetAll`));
    }

    getRequirementId(Id)  {
        return this._http.get(this.api.getUrl(`api/Requirement/${Id}`));
    }

    deleteRequirementId(id) {
        return this._http.delete(this.api.getUrl(`api/Requirement/${id}`));
    }

    putRequirementId(id, model)  {
        return this._http.put(this.api.getUrl(`api/Requirement/${id}`), model);
    }

    postRequirement(model)  {
        return this._http.post(this.api.getUrl(`api/Requirement`), model);
    }

    /**
     * Services Actividades economicas
     */

     getEconomicActivityAll()  {
        return this._http.get(this.api.getUrl(`api/EconomicActivity`));
    }

    getEconomicActivityId(Id)  {
        return this._http.get(this.api.getUrl(`api/EconomicActivity/${Id}`));
    }

    deleteEconomicActivityId(id) {
        return this._http.delete(this.api.getUrl(`api/EconomicActivity/${id}`));
    }

    putEconomicActivityId(id, model)  {
        return this._http.put(this.api.getUrl(`api/EconomicActivity/${id}`), model);
    }

    postEconomicActivity(model)  {
        return this._http.post(this.api.getUrl(`api/EconomicActivity`), model);
    }

    /**
     * Services Security References
     */

    getSecurityReferencesAll()  {
        return this._http.get(this.api.getUrl(`api/SecurityReferences`));
    }

    getSecurityReferencesId(Id)  {
        return this._http.get(this.api.getUrl(`api/SecurityReferences/${Id}`));
    }

    deleteSecurityReferencesId(id) {
        return this._http.delete(this.api.getUrl(`api/SecurityReferences/${id}`));
    }

    putSecurityReferencesId(id, model)  {
        return this._http.put(this.api.getUrl(`api/SecurityReferences/${id}`), model);
    }

    postSecurityReferences(model)  {
        return this._http.post(this.api.getUrl(`api/SecurityReferences`), model);
    }

    putSecurityReferencesUpdateFileId(id, filedata)  {
        return this._http.put(this.api.getUrl(`api/SecurityReferences/UpdateFile/${id}`), filedata);
    }


    /**
     * Services Form Image
     */

    getFormImage(){
        return this._http.get<any>(this.api.getUrl(`api/FormImage`));
    }

    getFormImageId(id){
        return this._http.get<any>(this.api.getUrl(`api/FormImage/${id}`));
    }

    putFormImageId(id, model){
        return this._http.put<any>(this.api.getUrl(`api/FormImage/${id}`), model);
    }

    deleteFormImageId(id) {
        return this._http.delete(this.api.getUrl(`api/FormImage/${id}`));
    }

    postFormImage(model)  {
        return this._http.post(this.api.getUrl(`api/FormImage`), model);
    }

    putFormImageUpdateFileId(id, filedata)  {
        return this._http.put(this.api.getUrl(`api/FormImage/UpdateFile/${id}`), filedata);
    }

    /**
     * Services modulo reference occupation
     */

    getJobAll(){
        return this._http.get<any>(this.api.getUrl(`api/Job`));
    }

    getJobId(id){
        return this._http.get<any>(this.api.getUrl(`api/Job/${id}`));
    }

    putJobId(id, model){
        return this._http.put<any>(this.api.getUrl(`api/Job/${id}`), model);
    }

    deleteJobId(id) {
        return this._http.delete(this.api.getUrl(`api/Job/${id}`));
    }

    postJob(model)  {
        return this._http.post(this.api.getUrl(`api/Job`), model);
    }

    /**
     * Services modulo reference SocialSecRange
     */

    getSocialSecRangeAll(){
        return this._http.get<any>(this.api.getUrl(`api/SocialSecRange`));
    }

    getSocialSecRangeId(id){
        return this._http.get<any>(this.api.getUrl(`api/SocialSecRange/${id}`));
    }

    putSocialSecRangeId(id, model){
        return this._http.put<any>(this.api.getUrl(`api/SocialSecRange/${id}`), model);
    }

    deleteSocialSecRangeId(id) {
        return this._http.delete(this.api.getUrl(`api/SocialSecRange/${id}`));
    }

    postSocialSecRange(model)  {
        return this._http.post(this.api.getUrl(`api/SocialSecRange`), model);
    }

    /**
     * Services modulo reference grupos de insumos
     */
    getSupplyGroup(){
        return this._http.get<any>(this.api.getUrl(`api/Company/SupplyGroup`));
    }

    getSupplyGroupId(id){
        return this._http.get<any>(this.api.getUrl(`api/Company/SupplyGroup/${id}`));
    }

    putSupplyGroupId(id, model){
        return this._http.put<any>(this.api.getUrl(`api/Company/SupplyGroup/${id}`), model);
    }

    deleteSupplyGroupId(id) {
        return this._http.delete(this.api.getUrl(`api/Company/SupplyGroup/${id}`));
    }

    postSupplyGroup(model)  {
        return this._http.post(this.api.getUrl(`api/Company/SupplyGroup`), model);
    }

    putSupplyGroupUpdateFileId(id, filedata)  {
        return this._http.put(this.api.getUrl(`api/Company/SupplyGroup/Photo/${id}`), filedata);
    }

    /**
     * Services modulo reference lista de insumos
     */
    getSupply(){
        return this._http.get<any>(this.api.getUrl(`api/Company/Supply?IsActive=true`));
    }

    getSupplyId(id){
        return this._http.get<any>(this.api.getUrl(`api/Company/Supply/${id}`));
    }

    putSupplyId(id, model){
        return this._http.put<any>(this.api.getUrl(`api/Company/Supply/${id}`), model);
    }

    deleteSupplyId(id) {
        return this._http.delete(this.api.getUrl(`api/Company/Supply/${id}`));
    }

    postSupply(model)  {
        return this._http.post(this.api.getUrl(`api/Company/Supply`), model);
    }

    putSupplyUpdateFileId(id, filedata)  {
        return this._http.put(this.api.getUrl(`api/Company/Supply/Photo/${id}`), filedata);
    }

    // history
    getHistory(id?) {
        return this._http.get<any>(this.api.getUrl(`api/Personal/Historical/Contract/${id}`));
    }


    // Modulo perfiles days expire password change
    getRoleConfigurationByAdmin(rolId) {
        return this._http.get<any>(this.api.getUrl(`api/Company/RoleConfigurationByAdmin/rol/${rolId}`));
    }

    postRoleConfigurationByAdmin(model)  {
        return this._http.post(this.api.getUrl(`api/Company/RoleConfigurationByAdmin`), model);
    }


    getCompanyByNitId(nit){
      return this._http.get<any>(this.api.getUrl(`api/Company/ByNitId/${nit}`));
    }


}
