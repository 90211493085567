import { FormControl, FormGroup, Validators } from '@angular/forms';

export class PersonalNovelty {
    FullName: string;
    Id: any;
    IsOpen: boolean;
    NoveltyDocsJSON: string;
    NoveltyEndDate: string;
    NoveltyName: string;
    NoveltyStartDate: string;
    NoveltyTypeAbr: string;
    NoveltyTypeId: string;
    Position: string;
    PersonalPhoto: string;
    PlaceOfWork: string;
    PersonalDocumentNumber: string;
    PersonalDocumentType: string;
    NoveltyCreateDate: string;
    Comments: any[]

    static asFormGroup(personal): FormGroup {
        const fg = new FormGroup({
            Comments: new FormControl(personal.Comments, Validators.required),
            PersonalPhoto: new FormControl(personal.PersonalPhoto, Validators.required),
            NoveltyCreateDate: new FormControl(personal.NoveltyCreateDate, Validators.required),
            PlaceOfWork: new FormControl(personal.PlaceOfWork, Validators.required),
            PersonalDocumentNumber: new FormControl(personal.PersonalDocumentNumber, Validators.required),
            PersonalDocumentType: new FormControl(personal.PersonalDocumentType, Validators.required),
            FullName: new FormControl(personal.FullName, Validators.required),
            Id: new FormControl(personal.Id, Validators.required),
            IsOpen: new FormControl(personal.IsOpen, Validators.required),
            NoveltyDocsJSON: new FormControl(personal.NoveltyDocsJSON, Validators.required),
            NoveltyEndDate: new FormControl(personal.NoveltyEndDate, Validators.required),
            NoveltyName: new FormControl(personal.NoveltyName, Validators.required),
            NoveltyStartDate: new FormControl(personal.NoveltyStartDate, Validators.required),
            NoveltyTypeAbr: new FormControl(personal.NoveltyTypeAbr, Validators.required),
            NoveltyTypeId: new FormControl(personal.NoveltyTypeId, Validators.required),
            Position: new FormControl(personal.Position, Validators.required),
            update: new FormControl(false),
            updateNovelty: new FormControl(false),
        });
        return fg;
    }
}


