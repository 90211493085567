<div class="box" *ngIf="flagNotificationView">
  <div class="header">
    <div class="content" [ngClass]="'primary'">{{'PROGRAMACION DE NOTIFICACIONES' || 'N/A'}}</div>
  </div>
  <div class="content-card-notification list-notification">
    <div class="Create-notification">
      <div class="text-create-notification">Agregar Correo</div>
      <button mat-mini-fab type="button" matTooltipPosition="above" matTooltip="crear"
              (click)="addCreateNotificationSchedule()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="form-group-notification" >
      <div class="cargos-notification">
        <div class="text-notification" *ngFor="let item of notificationSchedule">
          <div class="title-notification">
            <div class="email-project-container">
              <mat-label class="force-lowercase">{{ item.Email?.toLowerCase() }}</mat-label>
              <div class="project-name-not">{{ getProjectNames(item)}}</div>
            </div>
            <span [ngClass]="item.IsActive ? 'estado-activo' : 'estado-inactivo'">
                  {{ item.IsActive ? 'Activo' : 'Inactivo' }}
            </span>
          </div>
          <div class="icons-isActive-notification">
            <ng-container *ngIf="exitPermissionCompany('notificationschedule.edit') || exitPermissionCompany('notificationschedule.update'); else disabledIcon">
              <mat-icon
                [matMenuTriggerFor]="menuPosition"
                matTooltip="Opciones"
                class="menu-icon"
              >
                more_vert
              </mat-icon>
            </ng-container>

            <ng-template #disabledIcon>
              <mat-icon
                class="mat-icon-disabled"
                matTooltip="Sin permiso"
              >
                more_vert
              </mat-icon>
            </ng-template>
            <mat-menu #menuPosition="matMenu">
              <button mat-menu-item (click)="editarProgramming(item)" *ngIf="exitPermissionCompany('notificationschedule.edit')">EDITAR</button>
              <button mat-menu-item (click)="setUpProgramming(item)" *ngIf="exitPermissionCompany('notificationschedule.update')">CONFIGURAR</button>
            </mat-menu>
          </div>
        </div>
        <div class="desc-roles"></div>
          <div class="">
            <label class="comment-notification" >Esta notificación se genera 30 días antes del vencimiento</label>
            <button mat-button class="btn-cerrar-notification" (click)="closeDialog()">CERRAR</button>
          </div>
      </div>
    </div>

    <app-title-card-preview
      *ngIf="notificationSchedule.length === 0"
      style="width: 100%;"
      [icon]="'help'"
      [text]="'Aqui se visualizan los tipos de programacion de notificaciones'"
      [textTooltip]="'Aqui se visualizan los tipos de programacion de notificaciones'"
    ></app-title-card-preview>
  </div>
</div>

<div class="box" *ngIf="flagEditNotificationSchedule">
  <div class="header">
    <div class="content greendAnalityco">{{'PROGRAMACION DE NOTIFICACIONES' || 'N/A'}}</div>
  </div>
  <div class="content-card-edit-notification list-card-notification">
    <form [formGroup]="formNotificationsType" (ngSubmit)="onSubmitTypeNotifications()" style="width: 100%;">
      <mat-form-field class="input-field-notification" appearance="outline">
        <label><strong>Correo</strong></label>
        <input matInput type="text" formControlName="email" autocapitalize="off" autocomplete="off" >
        <mat-error>Requerido</mat-error>
      </mat-form-field>
      <mat-form-field class="input-field-notification" appearance="outline">
        <label><strong>Descripciòn</strong></label>
        <input matInput type="text" formControlName="Description" maxlength="{{350}}" >
        <mat-error>Requerido</mat-error>
      </mat-form-field>
      <div class="active-togle-notification"><div>{{'Estado (Inactivo/Activo)'}}</div><mat-slide-toggle formControlName="IsActive" name="EnableAdStart"></mat-slide-toggle></div>
      <div class="actions-dialog-notification" style="margin: 3px;">
        <button mat-button class="btn-cancelar" (click)="notEditNotifications()" type="button">CANCELAR</button>&nbsp;&nbsp;
        <button mat-button type="submit" class="btn-generar">GUARDAR</button>
      </div>
    </form>
  </div>
</div>

<div class="box" *ngIf="flagConfigNotificationSchedule">
  <div class="header">
    <div class="content greendAnalityco">{{'PROYECTOS' || 'N/A'}}</div>
  </div>
  <div class="content-card-edit-notification list-card-notification">
    <div class="projects-wrapper">
      <ng-container *ngFor="let project of projectsList">
        <div class="project-block">
          <mat-icon class="project-icon">location_city</mat-icon>
          <div class="project-name">
            {{ project.Name }}
          </div>
          <mat-slide-toggle [(ngModel)]="project.IsActive"></mat-slide-toggle>
        </div>
      </ng-container>
    </div>

    <div class="actions-dialog-notification" style="margin-top: 13px;">
      <button mat-button class="btn-cancelar" (click)="notConfigNotifications()" type="button">CANCELAR</button>&nbsp;&nbsp;
      <button mat-button type="submit" class="btn-generar" (click)="onSubmitSetupProjects()">GUARDAR</button>
    </div>
  </div>
</div>
