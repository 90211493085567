import { Component, Input, OnInit } from '@angular/core';
import { AppColor } from '../../../services/utils/utils.types';

@Component({
  selector: 'title-dialog',
  templateUrl: './title-dialog.component.html',
  styleUrls: ['./title-dialog.component.scss']
})
export class TitleDialogComponent implements OnInit {

  @Input() title: string;
  @Input() width: string;
  @Input() color: AppColor;

  style = 'width: 360px;';
  @Input() set _module(module: any) {
    if(module !== undefined && module !== '') {
      if(module === 'Project') {
        this.style = 'width: 1217px;'
      } else if(module === 'Contract'){
        this.style = 'width: 586px;'
      } else if(module === 'Companies'){
        this.style = 'width: 380px;'
      } else {
        this.style = 'width: 200px;'
      }
    }
  }

  colorDefault: string = 'blueAnalityco'; 

  constructor() { }

  ngOnInit(): void {
  }
  
  

}
