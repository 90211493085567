<div class="box">
    <div class="actions-dialog">
        <button mat-button class="btn-cancelar" (click)="close()" >CANCELAR</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" *ngIf="this.contractsSelected.length !== 0" (click)="save(false)">GUARDAR</button>
    </div>
    <div class="search-paginator" [ngClass]="production === false ? 'color-search-paginator-pruebas': 'color-search-paginator-pro'">
        <div class="search">
            <input class="input-search" 
                    type="text" 
                    placeholder="Buscar..." 
                    (keyup)="searchFilterPagination($event.target.value)">
        </div>
        <mat-paginator  *ngIf="arreglo"
                        [length]="arreglo.length"
                        [pageSize]="32"
                        [pageSizeOptions]="[15,32]"
                        (page)="searchFilterPagination(filterListValue)"
                        #paginatorList
        ></mat-paginator>
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </div>

    <div class="list">
        <h1 *ngIf="data.dataOne.length > 0">Contratos para vincular : Cant = {{listShow.length}}</h1>
        <div style="height: 45px; padding: 0px 5px !important;" (click)="save(true)">
            <div class="cardInformation">
                <div class="relational-info" >
                    <div class="desc flex" style="width: 180px; margin-top: 10px;">
                        <mat-icon style="margin-right: 2px !important;">location_city</mat-icon>
                        <h3>{{'SELECCIONE TODOS LOS PROYECTOS'}}</h3>
                    </div><br>
                </div>
                <div class="text-selectedAll">{{'(SELECCIONA UN CONTRATO POR CADA PROYECTO)'}}</div>
            </div>
        </div>
        <div class="sub-list">
            <div *ngFor="let item of listShow; let i = index" style=" margin-right: 10px;" >
                <app-card 
                    [dataAbove]="{iconStart:'location_city', textStart:item[data.cardOne.abodeTextMedium]}"
                    [cardInformation]="true"
                    (click)="selectedItem(item, i)" 
                    [photo]="getImg(item[data.cardOne.img])" 
                    [title]="item[data.cardOne.titleFirst] + ' ' + (item[data.cardOne.titleSecond] === undefined ? '' : item[data.cardOne.titleSecond])" 
                    [item1]="item[data.cardOne.subTitle]" 
                    [item2]="(item[data.cardOne.itemKey] === undefined ? '' : item[data.cardOne.itemKey] + ': ')  + (item[data.cardOne.itemValue] === undefined ? '' : item[data.cardOne.itemValue])" 
                    [itemFinalDate]="item[data.cardOne.itemFinalDate]"
                    [itemFinalText]="item[data.cardOne.itemFinalText]"
                    [cardIsSelected]="true"
                    [_ckeckbox]="item.ControlCheck"
                    >
                </app-card>
            </div>
        </div>
        <h1 *ngIf="data.dataTwo.length > 0">Vinculado a los contratos</h1>
        <div class="sub-list">
            <div *ngFor="let item of data.dataTwo" style=" margin-right: 10px; margin-left: 10px;">
                <app-card 
                    [dataAbove]="{iconStart:'location_city', textStart:item[data.cardOne.abodeTextMedium]}"
                    [cardInformation]="true"
                    [photo]="getImg(item[data.cardOne.img])" 
                    [title]="item[data.cardOne.titleFirst] + ' ' + (item[data.cardOne.titleSecond] === undefined ? '' : item[data.cardOne.titleSecond])" 
                    [item1]="item[data.cardOne.subTitle]" 
                    [item2]="(item[data.cardOne.itemKey] === undefined ? '' : item[data.cardOne.itemKey] + ': ')  + (item[data.cardOne.itemValue] === undefined ? '' : item[data.cardOne.itemValue])" 
                    [itemFinalDate]="item[data.cardOne.itemFinalDate]"
                    [itemFinalText]="item[data.cardOne.itemFinalText]"
                    >
                </app-card>
            </div>
        </div>
        
    </div>
</div>
