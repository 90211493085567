<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{data.title || 'Sin title'}}</div>
        <!-- <mat-icon class="close" (click)="close()">close</mat-icon> -->
    </div>
    <div class="content-card">
        <div class="search">
            <input class="input-search" 
                type="text" 
                placeholder="Buscar..." 
                (keyup)="getfilterlist($event.target.value)">
        </div>
        <div class="list"  *ngIf="data.dataList">
            <div *ngFor="let item of filterlist">
                <app-card 
                    (click)="selectedItem(item)" 
                    [photo]="getImg(item[data.card.img])" 
                    [title]="item[data.card.titleFirst] + ' ' + (item[data.card.titleSecond] === undefined ? '' : item[data.card.titleSecond])" 
                    [item1]="item[data.card.subTitle]" 
                    [itemF1]="item[data.card.finalLeft]"
                    [itemF2]="item[data.card.finalRight]"
                    [niveles]="3"
                    [onErrorUrl]="'administradora'"
                    >
                </app-card>
                <!-- [item2]="(item[data.card.itemKey] === undefined ? '' : item[data.card.itemKey]) + ' ' + (item[data.card.itemValue] === undefined ? '' : item[data.card.itemValue])"  -->
            </div>
        </div>
        <div class="actions-dialog"style="margin: 3px;">
            <button mat-button class="btn-cancelar" (click)="close()">CANCELAR</button>
            <!-- <button (click)="_getContractNumber()">Hola</button> -->
        </div>
    </div>
   
    
</div>