import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { CardList, FilterList } from 'src/app/core/interfaces/interfaces';
import { FilterMultipleService } from 'src/app/core/services/utils/filter-multiple.service';
import { environment } from 'src/environments/environment';

export interface DialogData {
  dataList: any;
  card: CardList;
  path: string;
}

@Component({
  selector: 'app-dialog-list',
  templateUrl: './dialog-list.component.html',
  styleUrls: ['./dialog-list.component.scss']
})
export class DialogListComponent implements OnInit {
  filterValue = '';
  filterListValue = '';
  production: boolean = environment.production;

  list: any = [];
  arreglo: any[] = [];
  listSlice: any = [];

  @ViewChild('paginatorList', {read: MatPaginator}) paginatorList: MatPaginator;

  filterList: FilterList[] = [
    { Value: '', Name: 'Todos', icon: '', count: null},
    { Value: 'IsActive', Name: 'Activos', icon: '', count: null},
    { Value: 'IsInactive', Name: 'Inactivos', icon: '', count: null}
  ];

  constructor(
    public dialogRef: MatDialogRef<DialogListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    private filters: FilterMultipleService,
  ) {
      this.list = data.dataList;
      this.arreglo = data.dataList;
      this.listSlice = this.list.slice(0, 32);
  }

  ngOnInit(): void {
  }

  getImg(logo) {
    const img = (logo === null ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg' : logo);
    return img;
  }

  personalId(Id) {
    this.router.navigate([this.data.path, Id]);
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  searchFilterPagination(changeFilter: string){
    const startIndex = this.paginatorList.pageIndex * this.paginatorList.pageSize;
    let endIndex = startIndex + this.paginatorList.pageSize;
    if (this.filterListValue === '' && changeFilter === '' && this.filterValue === '' ) {
      this.arreglo = this.list;
    }else {
      this.filterListValue = changeFilter;
      this.arreglo = this.getPersonalFilter(this.filterListValue, this.filterValue);
    }

    if (endIndex > this.arreglo.length) {
      endIndex = this.arreglo.length;
    }
    this.listSlice = this.arreglo.slice(startIndex, endIndex);
  }

  getPersonalFilter(value: string, filter?: string): any[] {
    if (value === '' && (filter === '' || filter === undefined)) {
        return this.list;
    } else if (filter === 'IsActive') {
        return this.list.filter(x => {
            return (this.filters.exitFilter(x[this.data.card.titleFirst] + ' ' + x.LastName, value) ||
                    this.filters.exitFilter(x.DocumentNumber, value) ||
                    this.filters.exitFilter(x.DocumentType, value)) &&
                    (x.IsActive === true);
        });
    } else if (filter === 'IsInactive') {
        return this.list.filter(x => {
            return (this.filters.exitFilter(x.Name + ' ' + x.LastName, value) ||
                    this.filters.exitFilter(x.DocumentNumber, value) ||
                    this.filters.exitFilter(x.DocumentType, value)) &&
                    (x.IsActive === false);
        });
    } else  {
      return this.list.filter(x => {
        return (this.filters.exitFilter(x[this.data.card.titleFirst] + ' ' +
            (x[this.data.card.titleSecond] === undefined ? '' : x[this.data.card.titleSecond]), value) ||
                this.filters.exitFilter(x[this.data.card.subTitle], value) ||
                this.filters.exitFilter(x[this.data.card.itemValue], value));
        });
    }
  }
}
