<div class="box">
    <title-dialog [title]="data.title || 'ARCHIVOS Y COMENTARIOS'" [color]="'greendAnalityco'" [width]="'360'"></title-dialog>
    <div class="content-card">
        <div class="content-show">
            <label class="desc">{{data.desc || ''}}</label>
            <ngx-dropzone *ngIf="data.typeOperator === undefined" (change)="onSelect($event)" class="dropzone" [multiple]="true">
                <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
            </ngx-dropzone>
            &nbsp;
            <!-- <app-select-search class="select-component-full"
                                *ngIf="data.typeOperator === undefined"
                                [appearance]="'outline'"
                                [_dataSource]="motivos" [textInput]="'MOTIVO DE LA NOVEDAD'" [dataShow]="'Value'" [dataValue]="'Value'" [siteCtrl]="controlMotivo"
                                [inputType]="'text'" (onSelectionChange)="selectedMotivo($event)">
            </app-select-search> -->
            <!-- <mat-form-field class="input-field" appearance="outline" *ngIf="flagShowCommentary === true"> -->
            <mat-form-field class="input-field" appearance="outline"  *ngIf="data.typeOperator === undefined">
                <label><strong>COMENTARIO</strong></label>
                <textarea matInput [(ngModel)]="commentaryInput" maxlength="280" rows="5"></textarea>
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <div class="list">
                <div class="item" *ngFor="let file of files; let i = index">
                    <div *ngIf="file">{{file.name || 'SIN NOMBRE'}}</div>
                    <div class="red" *ngIf="!file">{{'Archivo Removido'}}</div>
                    <mat-icon *ngIf="file" (click)="removeFile(i)">highlight_off</mat-icon>
                </div>
                <!-- <div class="item" *ngFor="let doc of documentos; let i = index">
                    <div [ngClass]="!doc ? 'red': ''">{{doc?.nameFile || 'Doc. Removido'}}</div>
                    <div>
                        <mat-icon class="mat-icon-show" *ngIf="doc !== undefined && (data.typeOperator === 'show' || data.typeOperator === 'showDelete' || data.typeOperator === 'showUpdate' || data.typeOperator === 'showUpdatePlanilla') && !isXLSX(doc) && doc.isCommentary === false" (click)="openImgVideo(doc)">visibility</mat-icon>
                        <a *ngIf="doc !== undefined && (data.typeOperator === 'show' || data.typeOperator === 'showDelete' || data.typeOperator === 'showUpdate' || data.typeOperator === 'showUpdatePlanilla') && isXLSX(doc)" [href]="getUrlFile(doc)" target="_blank"><mat-icon class="mat-icon-show">visibility</mat-icon></a>
                        <mat-icon class="mat-icon-delete" *ngIf="(data.typeOperator === 'delete' || data.typeOperator === 'showDelete') && doc" (click)="jsonUpdateDeleteDoc(doc, i)">delete</mat-icon>
                    </div>
                </div> -->
                <div class="documents" *ngFor="let doc of documentos; let i = index" [ngSwitch]="doc?.isCommentary">
                    <div *ngSwitchCase="false">
                        <div class="document-doc">
                            <strong>Documento:&nbsp;</strong>
                            <div class="isDocument" [matTooltip]="doc?.nameFile || ''">{{doc?.nameFile}}</div>
                        </div>
                        <div class="document-doc">
                            <strong>Comentario:&nbsp;</strong>
                            <div class="isDocument" [matTooltip]="doc?.comment || ''">{{doc?.comment || 'Sin motivo' }}</div>
                        </div>
                        <div class="state" style="color: #a0a0a0 !important;">
                            <div class="state-description" style="min-width: 80px; width: 80px;">
                                <div>Cargado el</div>
                            </div>
                            <div class="state-description">
                                <div>{{doc?.date || 'Fecha no reportada' }}</div>
                            </div>
                            <div class="state-description">
                                <div>Por:</div>
                                <mat-icon
                                    matTooltipPosition="above"
                                    [matTooltip]="'Usuario: ' + (doc?.user !== undefined ? doc?.user : 'No reportado')"
                                    >account_circle</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div *ngSwitchCase="true">
                        <div class="document-doc">
                            <strong>{{exitComentarioInMotivo(doc?.nameFile) === true ? 'Motivo:' :'Comentario:'}}&nbsp;</strong>
                            <div class="isComentary">{{doc?.nameFile || 'Doc. Removido'}}</div>
                        </div>
                        <div class="state" style="color: #a0a0a0 !important;">
                            <div class="state-description" style="min-width: 80px; width: 80px;">
                                <div>Escrito el</div>
                            </div>
                            <div class="state-description">
                                <div>{{doc?.date || 'Fecha no reportada' }}</div>
                            </div>
                            <div class="state-description">
                                <div>Por:</div>
                                <mat-icon
                                    matTooltipPosition="above"
                                    [matTooltip]="'Usuario: ' + (doc?.user !== undefined ? doc?.user : 'No reportado')"
                                    >account_circle</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!doc" [ngClass]="!doc ? 'red': ''">{{'Doc. Removido'}}</div>
                    <div class="operators" *ngIf="data.typeOperator === undefined || data.typeOperator === 'show'">
                        <mat-icon class="mat-icon-show" *ngIf="doc !== undefined && !isXLSX(doc) && doc.isCommentary === false" (click)="openImgVideo(doc)">visibility</mat-icon>
                        <a *ngIf="doc !== undefined && isXLSX(doc)" [href]="getUrlFile(doc)" target="_blank"><mat-icon class="mat-icon-show">visibility</mat-icon></a>
                        <!-- <mat-icon class="mat-icon-delete" *ngIf="(data.typeOperator === 'delete' || data.typeOperator === 'showDelete' || (data.typeOperator === 'showUpdatePlanilla' && isEqualsUserRemoveFile(doc))) && doc" (click)="jsonUpdateDeleteDoc(doc, i)">delete</mat-icon> -->
                    </div>
                </div>
                <div class="item" *ngIf="data.docs.length === 0">{{'0 DOCUMETOS GUARDADOS'}}</div>
            </div>
        </div>

        <div class="actions-dialog">
        <!-- <button mat-button class="btn-cancelar" (click)="onNoClick()" >{{(data.typeOperator === 'show')? 'CERRAR': 'CANCELAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" *ngIf="!jsonDocExitValue() || files.length > 0 || commentary !== ''" (click)="upDateDocs(DocsJSON)">GUARDAR</button> -->
        </div>
        <div class="actions-dialog" >
            <button mat-button class="btn-cancelar" (click)="onNoClick()" >{{'CERRAR'}}</button>&nbsp;&nbsp;
            <button mat-button 
                class="btn-generar"  
                type="button" 
                [disabled]="files.length === 0 && this.commentaryInput === '' && this.commentarySelect === ''? true: false"
                [ngClass]="files.length === 0 && this.commentaryInput === '' && this.commentarySelect === '' ? 'btn-disabled' : 'btn-generar'" 
                (click)="save()">{{'GUARDAR'}}</button>
            <!-- <button mat-button 
                [disabled]="files.length === 0 ? true: false"
                [ngClass]="files.length === 0 ? 'btn-disabled' : 'btn-generar'"  
                type="button" 
                (click)="updatePhoto()">{{'EXTRAER'}}</button> -->
        </div>
    </div>
</div>
