import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CompaniesService} from '../../../services/modules/companies.service';
import {FormControl} from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-card-programming-dates',
  templateUrl: './card-programming-dates.component.html',
  styleUrls: ['./card-programming-dates.component.scss']
})
export class CardProgrammingDatesComponent implements OnInit {
  scheduleTypes: any[] = [];
  ReportToSelect = [];
  selectedProfiles: any[] = [];
  filteredProfiles: any[] = [];
  UserProfileToSelect = [];
  reportScheduleOptions: any[] = [];

  isSaveEnabled = false;
  isEditing = false;
  showUsersSelect = false;
  showProgrammingDates = false;
  showUserProfileSelect = false;

  selectedSchedule = '';
  selectedScheduleType = '';

  controlSearchUserProfile = new FormControl();
  controlSearchReportSelect = new FormControl();
  dataEditProgramming: any = {};

  diasSeleccionadosMes: number[] = [];
  diaSeleccionadoSemana: number | null = null;
  daySelectedWeek = 0;
  daysOfTheMonth = Array.from({ length: 31 }, (_, i) => ({ numero: i + 1, seleccionado: false }));

  @Input() editData: any;
  @Input() companyInfoId: string;
  @Output() flagEditEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() flagSaveEvent: EventEmitter<boolean> = new EventEmitter<boolean>();


  @ViewChild('profileInput') profileInput!: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger!: MatAutocompleteTrigger;

  daysWeek = [
    { id: 7, nombre: 'DOM', seleccionado: false },
    { id: 1, nombre: 'LUN', seleccionado: false },
    { id: 2, nombre: 'MAR', seleccionado: false },
    { id: 3, nombre: 'MIE', seleccionado: false },
    { id: 4, nombre: 'JUE', seleccionado: false },
    { id: 5, nombre: 'VIE', seleccionado: false },
    { id: 6, nombre: 'SAB', seleccionado: false }
  ];

  constructor(
    private companiesServices: CompaniesService,
  ) { }

  ngOnInit(): void {
    this.consumeApis();
    this.dataEditProgramming = this.editData;
  }

  consumeApis() {
    forkJoin({
      scheduleTypes: this.companiesServices.getReportScheduleprogramming(),
      reportScheduleOptions: this.companiesServices.getReportScheduleprogramming(),
      reports: this.companiesServices.getReportSchedule()
    }).subscribe(({ scheduleTypes, reportScheduleOptions, reports }) => {

      this.scheduleTypes = scheduleTypes;
      this.determineScheduleType();
      this.reportScheduleOptions = reportScheduleOptions;
      if (reportScheduleOptions && reportScheduleOptions.length > 0) {
        this.selectedSchedule = reportScheduleOptions[0].Name;
      }
      this.ReportToSelect = reports;
      if(Object.keys(this.dataEditProgramming).length !== 0){
        this.loadDataEdit();
      }
    });
  }

  loadDataEdit(){
    const report = this.ReportToSelect.find(r => r.Value === this.dataEditProgramming.Type);
    this.companiesServices.getReportScheduleUserProfile(this.companyInfoId, report.Value, this.dataEditProgramming.Id).subscribe((rest) => {
      this.UserProfileToSelect = rest;
      this.filteredProfiles = [...this.UserProfileToSelect];
      this.controlSearchUserProfile.valueChanges.subscribe(value => {
        this.filterProfiles(value);
      });

      this.controlSearchReportSelect.setValue(report);

      if (this.dataEditProgramming) {
        this.controlSearchReportSelect.disable();
      }

      this.showUserProfileSelect = true;
      const profilesToAdd = this.dataEditProgramming.RoleIds;

      profilesToAdd.forEach(profile => {
        if (!this.selectedProfiles.find(p => p.Id === profile)) {
          const profileObj = this.filteredProfiles.find(p => p.Id === profile);
          this.selectedProfiles.push(profileObj);
        }
      });

      this.showProgrammingDates = this.selectedProfiles.length > 0;
      this.filterProfiles('');

      if (this.dataEditProgramming.ScheduleType === 'WEEKLY'){
        this.selectDay(this.dataEditProgramming.Days[0]);
      } else {
        this.diasSeleccionadosMes = this.dataEditProgramming.Days;
        this.diasSeleccionadosMes.forEach(day => {
          this.daysOfTheMonth[day - 1].seleccionado = true;
        });
      }
      this.validateForm();
    });
  }

  validateForm() {
    if (this.isEditing) {
      this.isSaveEnabled = Array.isArray(this.selectedProfiles) && this.selectedProfiles.length > 0 &&
        (this.daySelectedWeek !== 0 || (Array.isArray(this.diasSeleccionadosMes) && this.diasSeleccionadosMes.length > 0));
    } else {
      this.isSaveEnabled = this.controlSearchReportSelect.value &&
        Array.isArray(this.selectedProfiles) && this.selectedProfiles.length > 0 &&
        (this.daySelectedWeek !== 0 || (Array.isArray(this.diasSeleccionadosMes) && this.diasSeleccionadosMes.length > 0));
    }
  }

  changeReportSelect(requirement?, selectedReport?: any) {
    if (!requirement) { return; }

    this.showUsersSelect = requirement.ByEmployerPersonal === true;
    this.showUserProfileSelect = true;
    this.validateForm();
    this.companiesServices.getReportScheduleUserProfile(this.companyInfoId, requirement.Value, 0).subscribe((rest) => {
      this.UserProfileToSelect = rest;
      this.filteredProfiles = [...this.UserProfileToSelect];
      this.controlSearchUserProfile.valueChanges.subscribe(value => {
        this.filterProfiles(value);
      });
    });
  }

  filterProfiles(value: string | any): void {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
    this.filteredProfiles = this.UserProfileToSelect.filter(profile =>
      profile.Name.toLowerCase().includes(filterValue) &&
      !this.selectedProfiles.some(selected => selected.Id === profile.Id)
    );
    this.validateForm();
  }

  selectedProfile(event: MatAutocompleteSelectedEvent): void {
    const profile = event.option.value;
    if (!this.selectedProfiles.find(p => p.Id === profile.Id)) {
      this.selectedProfiles.push(profile);
    }
    this.showProgrammingDates = this.selectedProfiles.length > 0;
    this.controlSearchUserProfile.setValue(null);
    this.filterProfiles('');
    this.validateForm();

    setTimeout(() => {
      this.profileInput.nativeElement.focus();
      this.autocompleteTrigger.openPanel();
    }, 0);
  }

  removeProfile(profile: any): void {
    this.selectedProfiles = this.selectedProfiles.filter(p => p.Id !== profile.Id);
    this.showProgrammingDates = this.selectedProfiles.length > 0;
    this.filterProfiles(this.controlSearchUserProfile.value);
    this.validateForm();
  }

  determineScheduleType() {
    if (!this.showUserProfileSelect) {
      return;
    }
    const weekly = this.scheduleTypes.find(type => type.Name === 'WEEKLY');
    const monthly = this.scheduleTypes.find(type => type.Name === 'MONTHLY');

    if (weekly && monthly) {
      this.selectedScheduleType = 'ALL';
    } else if (weekly) {
      this.selectedScheduleType = 'WEEKLY';
    } else if (monthly) {
      this.selectedScheduleType = 'MONTHLY';
    } else {
      this.selectedScheduleType = '';
    }
    this.showProgrammingDates = this.selectedScheduleType !== '' && this.showUserProfileSelect;
  }

  selectDay(index: number) {
    if (this.diasSeleccionadosMes.length > 0) {
      this.daysOfTheMonth.forEach(day => day.seleccionado = false);
      this.diasSeleccionadosMes = [];
    }
    if (this.diaSeleccionadoSemana !== null) {
      this.daysWeek[this.diaSeleccionadoSemana].seleccionado = false;
    }
    this.daysWeek[index].seleccionado = true;
    this.diaSeleccionadoSemana = index;
    this.daySelectedWeek = this.daysWeek[index].id;
    this.validateForm();
  }

  selectDayOfTheMonth(index: number) {
    this.daySelectedWeek = 0;
    const day = this.daysOfTheMonth[index];
    if (this.diaSeleccionadoSemana !== null) {
      this.daysWeek[this.diaSeleccionadoSemana].seleccionado = false;
      this.diaSeleccionadoSemana = null;
    }
    if (day.seleccionado) {
      day.seleccionado = false;
      this.diasSeleccionadosMes = this.diasSeleccionadosMes.filter(d => d !== day.numero);
    } else {
      if (this.diasSeleccionadosMes.length >= 4) {
        return;
      }
      day.seleccionado = true;
      this.diasSeleccionadosMes.push(day.numero);
    }
    this.validateForm();
  }

  getScheduleTranslation(schedule: string): string {
    const option = this.reportScheduleOptions.find(option => option.Name === schedule);
    return option ? option.Translation : (schedule === 'WEEKLY' ? 'SEMANA' : 'MES');
  }

  cancelProgramming() {
    this.flagEditEvent.emit(false);
  }

  saveProgramming(){
    const model = {
      AdminInfoId: this.companyInfoId,
      ScheduleType: 'WEEKLY',
      Type: this.controlSearchReportSelect.value.Value,
      ReportScheduleDays: [this.daySelectedWeek],
      ReportScheduleRolIds: this.selectedProfiles.map(p => p.Id)
    };
    if (this.daySelectedWeek === 0){
      model.ScheduleType = 'MONTHLY';
      model.ReportScheduleDays = this.diasSeleccionadosMes;
    }
    if (Object.keys(this.dataEditProgramming).length !== 0) {
      this.companiesServices.putReportSchedule(this.dataEditProgramming.Id, model).subscribe((rest) => {
        this.flagEditEvent.emit(false);
        this.flagSaveEvent.emit(true);
      });
    }else{
      this.companiesServices.postReportSchedule(model).subscribe((rest) => {
        this.flagEditEvent.emit(false);
        this.flagSaveEvent.emit(true);
      });
    }
  }

}
