<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'ENROLAMIENTO' || 'N/A'}}</div>
    </div>
    <div class="content-card list-card" >

        <div class="sub-header">
            <div class="sub-content greendAnalityco">{{'PROYECTO O LUGAR DE TRABAJO' || 'N/A'}}</div>
        </div>
        <div class="card" *ngFor="let proyect of proyectSelected">
            <div class="card-option">
                <img src="{{s3Bucket+getImg(proyect.Logo)}}" alt="" (error)="commonMetodService._getUrlErrorImg('project', $event)">
                <div class="card-content">
                    <div class="card-items">
                        <h3 class="title">{{(proyect.Name | uppercase) || ''}}</h3>
                        <div class="field">{{proyect.CompanyName || ''}}</div>
                        <div class="field marginTop">{{proyect.CompanyDocNum || ''}}</div>
                    </div>
                </div>
            </div>
            <mat-icon class="icon-close" (click)="setValue()">close</mat-icon>
        </div>
        <app-select-search class="select-component" style="top: -6px !important;"  *ngIf="proyectSelected.length !== 1 && proyects.length !== 0"
                        [_dataSource]="proyects" [textInput]="'PROYECTO'" [inputType]="'text'"
                        [dataShow]="'Name'" [dataValue]="'Id'" [otherFilter]="'CompanyDocNum'" [siteCtrl]="controlEmployer"
                        [isCard]="true"
                        [card]="{img: 'Logo', title: 'Name', subTitle: 'CompanyName', finalLeft:'CompanyDocNum'}"
                        (onSelectionChange)="valueSelectedProyect($event)"
                        [onErrorUrl]="'project'">
        </app-select-search>
        <div class="text-flagExitContractContracts" *ngIf="flagExitproyects === true">
            Su usuario no esta vinculado a ningún proyecto activo, comuníquese con el usuario operador.
        </div>
        <div class="posgress" *ngIf="flagExitproyects === undefined">
            <h4 >CARGANDO PROYECTOS</h4>
            <mat-progress-bar mode="query" [value]="20"></mat-progress-bar>
        </div>
        <div class="sub-header">
            <div class="sub-content greendAnalityco">{{'CONTRATO' || 'N/A'}}</div>
        </div>
        <div class="card" *ngFor="let contract of contractsSelected">
            <div class="card-option">
                <img src="{{s3Bucket+getImg(contract.FormImageLogo)}}" alt="">
                <div class="card-content">
                    <div class="card-items">
                        <h3 class="title">{{(contract.ContractReview | uppercase) || ''}}</h3>
                        <div class="field">{{contract.ContractorName}}</div>
                        <div class="field">{{contract.ContractNumber}}</div>
                    </div>
                </div>
            </div>
            <mat-icon class="icon-close" (click)="setValueContract()">close</mat-icon>
        </div>
        <app-select-search class="select-component" *ngIf="proyectSelected.length === 1 && contractsSelected.length === 0"
                        [_dataSource]="contracts" [textInput]="'CONTRATO'" [inputType]="'text'"
                        [dataShow]="'ContractorName'" [dataValue]="'Id'" [otherFilter]="'ContractorDocumentNumber'" [siteCtrl]="controlContractSelected"
                        [isCard]="true"
                        [card]="{img: 'FormImageLogo', title: 'ContractReview', subTitle: 'ContractorName', finalLeft: 'ContractNumber'}"
                        (onSelectionChange)="onSelectionChangeContract($event)">
        </app-select-search>
        <div class="text-flagExitContractContracts" *ngIf="flagExitContractContracts === false">
            No se encontro un contrato activo en este proyecto. Comuniquese con el operador del proyecto.
        </div>
        <div class="sub-header">
            <div class="sub-content greendAnalityco">{{'EMPLEADO O CONTRATADO' || 'N/A'}}</div>
        </div>
        <div class="card" *ngFor="let personal of personalSelected">
            <div class="card-option">
                <img src="{{s3Bucket+getImgEmp(personal.Photo)}}" (error)="commonMetodService._getUrlErrorImg('personal', $event)" alt="">
                <div class="card-content">
                    <div class="card-items">
                        <h3 class="title">{{(personal.Name | uppercase) || ''}} {{(personal.LastName | uppercase) || ''}}</h3>
                        <div class="field">{{personal.DocumentType}}: {{personal.DocumentNumber || ''}}</div>
                    </div>
                </div>
            </div>
            <mat-icon class="icon-close" (click)="setValuePersonal()">close</mat-icon>
        </div>
        <div class="fields-group" *ngIf="proyectSelected.length === 1 && contractsSelected.length === 1 && personalSelected.length !==1">
            <mat-form-field class="input-field-short">
                <label><strong>TIPO</strong></label>
                <mat-select [(ngModel)]="documentType" (selectionChange)="changeType($event.value)">
                    <mat-option *ngFor="let data of companyDocumentType" [value]="data.Value">
                        {{(data.Description === 'C.C.' ? 'Cedula': data.Description)}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="input-field-50">
                <label><strong>NÚMERO</strong></label>
                <input matInput type="text" [(ngModel)]="documentNumber" pattern="/^-?\d+\.?\d*$/" maxlength="{{maxCaracter}}" oninput="this.value = this.value.replace(/[^Z0-9]/,'')">

                <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="setValuePersonal()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <div class="btn-save" (click)="getPersonalDocument()">
                <button mat-mini-fab color="primary" matTooltip="buscar" >
                  <mat-icon>search</mat-icon>
                </button>
            </div>
        </div>
        <div class="actions-dialog"style="margin: 3px;">
            <button mat-button class="btn-cancelar" [mat-dialog-close]="data" >CANCELAR</button>&nbsp;&nbsp;
            <button mat-button *ngIf="data.IsRequest !== true"
                    (click)="iniciar()"
                    [ngClass]="getDisabled() === true ? 'btn-generar':'btn-disabled'"
                    [disabled]="!getDisabled()">INICIAR
            </button>
            <!-- <button (click)="_getContractNumber()">Hola</button> -->
        </div>
    </div>

</div>
