import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CommonService } from 'src/app/core/services/modules/common.service';

export interface DialogData {
  IdRol: string;
}

@Component({
  selector: 'app-dialog-perfiles',
  templateUrl: './dialog-perfiles.component.html',
  styleUrls: ['./dialog-perfiles.component.scss']
})
export class DialogPerfilesComponent implements OnInit {

  rolesView: any[] = [];
  rolesSave: Permissions;
  claimsValue: string[] = [];

  form: FormGroup;
  controlModule = new FormControl();

  tooltipIsAnalityco = 'Este permiso solo debe asignarse al personal de Analityco';
  tooltipIsAdmin = 'Este permiso solo debe asignarse a los administradores de empresas';
  tooltipIsWeb = 'Este permiso aplica para módulos web';
  tooltipIsApp = 'Este permiso aplica para módulos de la aplicación movil';

  listModulos: any[] = [
    {value: 'IsProject', module: 'proyectos'},
    {value: 'IsContract', module: 'contratos'},
    {value: 'IsCompany', module: 'empresas'},
    {value: 'IsAdmin', module: 'administradoras'},
  ];

  constructor(
    public dialogRef: MatDialogRef<DialogPerfilesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private commonService: CommonService) {
      this.form = this.fb.group({
        Description: new FormControl('', Validators.required),
        Id: new FormControl(''),
        IsAdmin: new FormControl(false),
        IsCompany: new FormControl(false),
        IsContract: new FormControl(false),
        IsEnabled: new FormControl(false),
        IsProject: new FormControl(false),
        Name: new FormControl('', Validators.required),
        NormalizedName: new FormControl(''),
        Permissions: new FormControl(''),
        // Otros datos
        PasswordExpirationDays: new FormControl(0),
        MFA: new FormControl(false),
        KeyToAccessSettings: new FormControl(0, [
          Validators.required,
          Validators.maxLength(6),
          Validators.min(0),
          Validators.max(999999),
        ]),
      });
      this.getServiceType(data.IdRol);
  }

  ngOnInit(): void {
  }

  getServiceType(IdRol) {
    if (IdRol !== undefined) {
      this.commonService.getRolesId(IdRol).subscribe((rolesSave: any) => {
        this.rolesSave = rolesSave;
        if (rolesSave!.Permissions !== null) {
          rolesSave!.Permissions!.forEach(claim => {
            this.claimsValue.push(claim!.ClaimValue);
          });
        }
        this.form.get('Description').setValue(rolesSave?.Description);
        this.form.get('Id').setValue(rolesSave?.Id);
        this.form.get('IsAdmin').setValue(rolesSave?.IsAdmin);
        this.form.get('IsCompany').setValue(rolesSave?.IsCompany);
        this.form.get('IsContract').setValue(rolesSave?.IsContract);
        this.form.get('IsEnabled').setValue(rolesSave?.IsEnabled);
        this.form.get('IsProject').setValue(rolesSave?.IsProject);
        this.form.get('Name').setValue(rolesSave?.Name);
        this.form.get('NormalizedName').setValue(rolesSave?.NormalizedName);
        this.form.get('Permissions').setValue(rolesSave?.Permissions);

        this.getchangeToggle();
      });
      this.commonService.getRoleConfigurationByAdmin(IdRol).subscribe(rest => {
        this.form.get('PasswordExpirationDays').setValue(rest.PasswordExpirationDays === null ? 0 : rest.PasswordExpirationDays);
        this.form.get('MFA').setValue(rest.MFA);
        this.form.get('KeyToAccessSettings').setValue(rest.ConfigAppPassword);
      });
    }
    this.commonService.getPermissionGUIType('').subscribe((permisiion: any) => {
      this.rolesView = permisiion.sort((a, b) => (a.Label > b.Label) ? 1 : ((b.Label > a.Label) ? -1 : 0));
    });
  }

  save() {
    this.changeSave(this.controlModule.value);
    if (this.data.IdRol === undefined) {
    // POST
      if (this.form.status === 'VALID') {
        const modelRoles = {
          Description: this.form.get('Description').value,
          IsAdmin: this.form.get('IsAdmin').value,
          IsCompany: this.form.get('IsCompany').value,
          IsContract: this.form.get('IsContract').value,
          IsEnabled: this.form.get('IsEnabled').value,
          IsProject: this.form.get('IsProject').value,
          Name: this.form.get('Name').value
        };
        const modelDaysRoleConfigurationByAdmin = {
          AdminInfoId: null,
          RolIds: [
            this.data.IdRol
          ],
          PasswordExpirationDays: this.form.get('PasswordExpirationDays').value,
          MFA: this.form.get('MFA').value,
          ConfigAppPassword: this.form.get('KeyToAccessSettings').value
        };
        this.commonService.postRoleConfigurationByAdmin(modelDaysRoleConfigurationByAdmin).subscribe(() => {
        });
        this.commonService.postRoles(modelRoles).subscribe((rest: any) => {
          const modelAddToRole = {
            PermissionNames: this.claimsValue,
            RoleId: rest.Id
          };
          this.commonService.postClaimsAddToRole(modelAddToRole).subscribe(() => {
            this.dialogRef.close(true);
          });
        });
      } else {
        this.form.markAllAsTouched();
      }
    } else {
    // PUT
      const modelRoles = {
        Description: this.form.get('Description').value,
        Id: this.form.get('Id').value,
        IsAdmin: this.form.get('IsAdmin').value,
        IsCompany: this.form.get('IsCompany').value,
        IsContract: this.form.get('IsContract').value,
        IsEnabled: this.form.get('IsEnabled').value,
        IsProject: this.form.get('IsProject').value,
        Name: this.form.get('Name').value,
        NormalizedName: this.form.get('NormalizedName').value,
        Permissions: this.form.get('Permissions').value,
      };
      const modelDaysRoleConfigurationByAdmin = {
        AdminInfoId: null,
        RolIds: [
          this.data.IdRol
        ],
        PasswordExpirationDays: this.form.get('PasswordExpirationDays').value,
        MFA: this.form.get('MFA').value,
        ConfigAppPassword: this.form.get('KeyToAccessSettings').value
      };
      this.commonService.postRoleConfigurationByAdmin(modelDaysRoleConfigurationByAdmin).subscribe(() => {
      });
      this.commonService.putRoles(this.data.IdRol, modelRoles).subscribe(() => {
        const modelAddToRole = {
          PermissionNames: this.claimsValue,
          RoleId: this.data.IdRol
        };
        this.commonService.postClaimsAddToRole(modelAddToRole).subscribe(() => {
          this.dialogRef.close(true);
        });
      });
    }
  }

  changePermisiion(event: MatSlideToggleChange, value) {
    if (event.checked === true) {
      const data = this.claimsValue.find(claim => claim === value);
      data !== undefined ? '' : this.claimsValue.push(value);
    } else {
      this.claimsValue.forEach((valueClaim, index) => {
        valueClaim === value ? this.claimsValue.splice(index, 1) : '';
      });
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  havePermisiion(permisiion): boolean {
    let exit: boolean;
    const data = this.claimsValue.find(Claim => Claim === permisiion);
    data !== undefined ? exit = true : exit = false;
    return exit;
  }

  getchangeToggle() {
    this.form.get('IsAdmin').value === true ? this.controlModule.setValue('IsAdmin') : '' ;
    this.form.get('IsCompany').value === true ? this.controlModule.setValue('IsCompany') : '' ;
    this.form.get('IsContract').value === true ? this.controlModule.setValue('IsContract') : '' ;
    this.form.get('IsProject').value === true ? this.controlModule.setValue('IsProject') : '' ;
  }

  changeSave(type) {
    type === 'IsAdmin' ? this.form.get('IsAdmin').setValue(true) : this.form.get('IsAdmin').setValue(false);
    type === 'IsCompany' ? this.form.get('IsCompany').setValue(true) : this.form.get('IsCompany').setValue(false);
    type === 'IsContract' ? this.form.get('IsContract').setValue(true) : this.form.get('IsContract').setValue(false);
    type === 'IsProject' ? this.form.get('IsProject').setValue(true) : this.form.get('IsProject').setValue(false);
  }

  restrictToSixDigits(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    if (value.length > 6) {
      input.value = value.slice(0, 6);
    }
  }


}

export interface Permissions
{
  Description: string;
  Id: string;
  IsAdmin: boolean;
  IsCompany: boolean;
  IsContract: boolean;
  IsEnabled: boolean;
  IsProject: boolean;
  Name: string;
  NormalizedName: string;
  Permissions: PermissionsType[];
}

export interface PermissionsType
{
  ClaimType: string;
  ClaimValue: string;
  Id: number;
}
