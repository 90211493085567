<div class="paginator">
    <div class="show-entries">
        <span>Mostrar</span>
        <select id="select-entries" class="form-control" [(ngModel)]="config.PageWindow" (ngModelChange)="changePageWindow($event)">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="32">32</option>
        </select>
        <span>por página</span>
    </div>
    <div class="icons">
        <mat-icon (click)="clickChange('--')" [ngClass]="config.AfterPage === null? 'black':'white'">keyboard_double_arrow_left</mat-icon>
        <mat-icon (click)="clickChange('-')" [ngClass]="config.AfterPage === null? 'black':'white'">arrow_back_ios_new</mat-icon>
        <mat-icon (click)="clickChange('+')" [ngClass]="config.NextPage === null? 'black':'white'">arrow_forward_ios</mat-icon>
        <mat-icon (click)="clickChange('++')" [ngClass]="config.NextPage === null? 'black':'white'">keyboard_double_arrow_right</mat-icon>
    </div>
    <div class="count">
        <div>{{'Total:'+config.TotalRecords}}</div>
        <div>{{config.Page+ ' de '+config.TotalPages}}</div>
    </div>
</div>
