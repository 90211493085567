import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectService } from 'src/app/core/services/modules/project.service';
import { environment } from 'src/environments/environment';

export interface DialogData {
  Id: string;
}


@Component({
  selector: 'app-dialog-time-work-project',
  templateUrl: './dialog-time-work-project.component.html',
  styleUrls: ['./dialog-time-work-project.component.scss']
})
export class DialogTimeWorkProjectComponent implements OnInit {
  s3Bucket:string;

  horario: any[] = [
    {Name: 'Domingo', start: 'SundayEntry', finisht: 'SundayLeave', active: 'SundayActive'},
    {Name: 'Lunes', start: 'MondayEntry', finisht: 'MondayLeave', active: 'MondayActive'},
    {Name: 'Martes', start: 'TuesdayEntry', finisht: 'TuesdayLeave', active: 'TuesdayActive'},
    {Name: 'Miercoles', start: 'WednesdayEntry', finisht: 'WednesdayLeave', active: 'WednesdayActive'},
    {Name: 'Jueves', start: 'ThursdayEntry', finisht: 'ThursdayLeave', active: 'ThursdayActive'},
    {Name: 'Viernes', start: 'FridayEntry', finisht: 'FridayLeave', active: 'FridayActive'},
    {Name: 'Sabado', start: 'SaturdayEntry', finisht: 'SaturdayLeave', active: 'SaturdayActive'},
  ];

  form: FormGroup;
  imgCropperResult: string;
  Schedule;
  
  constructor(public dialogRef: MatDialogRef<DialogTimeWorkProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private projectService: ProjectService,
    private fb: FormBuilder) { 
      this.form = this.fb.group({
        FridayActive: new FormControl(false),
        FridayEntry: new FormControl(null),
        FridayLeave: new FormControl(null),
        MondayActive: new FormControl(false),
        MondayEntry: new FormControl(null),
        MondayLeave: new FormControl(null),
        ProjectId: this.data.Id,
        SaturdayActive: new FormControl(false),
        SaturdayEntry: new FormControl(null),
        SaturdayLeave: new FormControl(null),
        SundayActive: new FormControl(false),
        SundayEntry: new FormControl(null),
        SundayLeave: new FormControl(null),
        ThursdayActive: new FormControl(false),
        ThursdayEntry: new FormControl(null),
        ThursdayLeave: new FormControl(null),
        TuesdayActive: new FormControl(false),
        TuesdayEntry: new FormControl(null),
        TuesdayLeave: new FormControl(null),
        WednesdayActive: new FormControl(false),
        WednesdayEntry: new FormControl(null),
        WednesdayLeave: new FormControl(null),
      });
      this.projectService.getProjectSchedule(this.data.Id).subscribe(rest => {
        if(rest !== undefined) {
          this.Schedule = rest;
          this.form.get('FridayActive').setValue(rest.FridayActive);
          this.form.get('FridayEntry').setValue(rest.FridayEntry);
          this.form.get('FridayLeave').setValue(rest.FridayLeave);
          this.form.get('MondayActive').setValue(rest.MondayActive);
          this.form.get('MondayEntry').setValue(rest.MondayEntry);
          this.form.get('MondayLeave').setValue(rest.MondayLeave);
          this.form.get('SaturdayActive').setValue(rest.SaturdayActive);
          this.form.get('SaturdayEntry').setValue(rest.SaturdayEntry);
          this.form.get('SaturdayLeave').setValue(rest.SaturdayLeave);
          this.form.get('SundayActive').setValue(rest.SundayActive);
          this.form.get('SundayEntry').setValue(rest.SundayEntry);
          this.form.get('SundayLeave').setValue(rest.SundayLeave);
          this.form.get('ThursdayActive').setValue(rest.ThursdayActive);
          this.form.get('ThursdayEntry').setValue(rest.ThursdayEntry);
          this.form.get('ThursdayLeave').setValue(rest.ThursdayLeave);
          this.form.get('TuesdayActive').setValue(rest.TuesdayActive);
          this.form.get('TuesdayEntry').setValue(rest.TuesdayEntry);
          this.form.get('TuesdayLeave').setValue(rest.TuesdayLeave);
          this.form.get('WednesdayActive').setValue(rest.WednesdayActive);
          this.form.get('WednesdayEntry').setValue(rest.WednesdayEntry);
          this.form.get('WednesdayLeave').setValue(rest.WednesdayLeave);
        }
      });
    }

  ngOnInit(): void {
    this.s3Bucket=environment.api.s3Bucket;
  }

  save() {
    if(this.form.status === 'VALID') {
      this.projectService.postProjectSchedule(this.form.value).subscribe(resPost => {
        this.dialogRef.close(true);
      });
    } else {
      this.form.markAllAsTouched();
    }
  }

  getImg(logo) {
    let img = (logo === null || logo === '' ? 'assets/images/personblue.png': logo);
    return this.s3Bucket+img;
  }

  close() {
    this.dialogRef.close(false);
  }

  clear(start, finist) {
    this.form.get(start).setValue(null);
    this.form.get(finist).setValue(null);
  }
 
}
