<div
  [ngClass]="{ card: !cardInfo, 'card-information': cardInfo }"
  (click)="clickRouterNavigate()"
  
>
<div *ngIf="withStatus"  class="triangulo">
    <div  [ngClass]="{ 'red': !status, 'orange': status }">

    </div>
</div>
  <div class="card-img ">
    <img
      [ngClass]="{ img: !cardInfo, 'img-information': cardInfo }"
      src="{{ s3Bucket + img }}"
      alt="altImg"
      (error)="commonMetod._getUrlErrorImg(modulo, $event)"
    />
  </div>
  <div
    [ngClass]="{
      'card-content': !cardInfo,
      'card-content-information': cardInfo
    }"
  > 
  <div class="card-body">
    <ng-content select="[body]"></ng-content>
  </div>

  <div class="card-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
  </div>
</div>