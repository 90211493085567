import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpClient } from '@angular/common/http';
import { Personal } from '../../models/Personal/Personal';
import { Observable } from 'rxjs';
import { InfoPersonalCompanyPersonalId } from '../../models/Personal/InfoPersonalCompanyPersonalId';
import { ModelSaveCarnetPrint, ModelSaveCarnetPrintResponse, Poll } from '../../interfaces/interfaces';
import { PersonalInfoContract } from '../../models/Personal/personalInfoContract';
import { ContractsOffline } from '../../models/Personal/ContractsOffline';

@Injectable({
  providedIn: 'root'
})
export class PersonalService {

    constructor(
        private api: ApiService,
        // tslint:disable-next-line:variable-name
        private _http: HttpClient
    ) { }

    /**
     * Permite traer el personal witch adminInfoId
     */
    getPersonalFilterAdminInfoId(adminInfoId, page?, pageWindow?, filter?, Search? ) {
        return this._http.get<any>(
            this.api.getUrl(`api/Personal?adminInfoId=${adminInfoId}&${page !== undefined ? `page=${page}` : `page=1`}${pageWindow !== undefined ? `&pageWindow=${pageWindow}` : `&pageWindow=32`}${filter !== undefined && filter !== null ? `&filter=${filter}` : `&filter=`}${Search !== undefined && Search !== null ? `&search=${Search}` : ``}`));
    }

    // GET:ID Personal
    getPersonalId(id): Observable<Personal>{
        return this._http.get<Personal>(this.api.getUrl('api/Personal/' + id + '?v2=true'));
    }

    putPersonalFull(id, model) {
        return this._http.put(this.api.getUrl(`api/Personal/UpdatePersonalFull/${id}`), model);
    }

    getPersonalDocNumContractId(docNum, contractId) {
        return this._http.get(this.api.getUrl(`api/Personal/GetId/${docNum}/${contractId}`));
    }

    postPersonalInfoCompany(model) {
        return this._http.post(this.api.getUrl(`api/Personal/InfoCompany`), model);
    }

    postContractPersonalInfo(id, model, tillEndOfDay?) {
        return this._http.post(this.api.getUrl(`api/Contract/${id}/PersonalInfo?${tillEndOfDay
        !== undefined ? 'tillEndOfDay=false' : ''}`) + '&v2=true',  model);
    }

    getContractsOfflinePersonalCompanyInfoId(personalCompanyInfoId): Observable<ContractsOffline[]> {
        return this._http.get<ContractsOffline[]>(this.api.getUrl(`api/Personal/ContractsOffline?full=true&personalCompanyInfoId=${personalCompanyInfoId}` + '&v4=true'));
    }

    getInfoPersonalCompanyPersonalId(personalCompanyInfoId): Observable<InfoPersonalCompanyPersonalId> {
        return this._http.get<InfoPersonalCompanyPersonalId>(this.api.getUrl('api/Personal/GetInfoPersonalCompany?PersonalId='
          + personalCompanyInfoId + '&v2=true'));
    }

    // Save Print Carnet POST
    printSaveCarnet(printCarnet: ModelSaveCarnetPrint): Observable<ModelSaveCarnetPrintResponse> {
        return this._http.post<ModelSaveCarnetPrintResponse>(this.api.getUrl('api/Carnet'), printCarnet);
    }

    // Save Print Carnet PATCH
    printSaveCarnetPATCH(idCarnet: number) {
        return this._http.patch(this.api.getUrl(`api/Carnet/${idCarnet}`),  [{op: 'replace', path: '/IsActive', value: 'false'}]);
    }

    changeEpsAFpPATCH(id, path, value) {
        return this._http.patch(this.api.getUrl(`api/Person/${id}`),  [{op: 'replace', path, value}]);
    }

    // get PersonalsInfo Contract
    getPersonalsInfoContract(contractId): Observable<PersonalInfoContract[]> {
        return this._http.get<PersonalInfoContract[]>(this.api.getUrl(`api/Contract/${contractId}/PersonalInfo`));
    }

    // get PersonalsInfo Contract
    updatePersonalsInfoContract(contractId, PersonalInfo, personalInfo: PersonalInfoContract) {
        return this._http.post(this.api.getUrl(`api/Contract/${contractId}/PersonalInfo?Id=${PersonalInfo}`), personalInfo);
    }

    // putPersonal con el document
    putUpdatePersonal(personalModel) {
        return this._http.put(this.api.getUrl(`api/Personal/UpdatePersonal`), personalModel);
    }

    updatePhotoCompanyInfo(id, file) {
        return this._http.put(this.api.getUrl(`api/Personal/CompanyInfo/UpdatePhoto/${id}`), file);
    }

    putEmployerPersonalRequirement(Id, model) {
        return this._http.put(this.api.getUrl(`api/EmployerPersonalRequirement/${Id}`), model);
    }

    putPersonalRequirement(Id, model) {
        return this._http.put(this.api.getUrl(`api/PersonalRequirement/${Id}`), model);
    }

    putDELETEPersonalRequirement(Id, model) {
        return this._http.put(this.api.getUrl(`api/PersonalRequirement/DeleteFiles/${Id}`), model);
    }

    putUPDATEFILEPersonalRequirement(Id, file) {
        return this._http.put(this.api.getUrl(`api/PersonalRequirement/UpdateFile/${Id}`), file);
    }

    putDELETEEmployerPersonalRequirement(Id, model) {
        return this._http.put(this.api.getUrl(`api/EmployerPersonalRequirement/DeleteFiles/${Id}`), model);
    }

    putUPDATEFILEEmployerPersonalRequirement(Id, file) {
        return this._http.put(this.api.getUrl(`api/EmployerPersonalRequirement/UpdateFile/${Id}`), file);
    }

    putUPDATEFILEEmployerPersonalRequirementPlanilla(requirementId, personalCompanyInfoId, employerInfoId, file) {
        return this._http.put(this.api.getUrl(`api/Requirement/UpdateDocument/${requirementId}?personalCompanyInfoId=${personalCompanyInfoId}&employerInfoId=${employerInfoId}`), file);
    }

    putPersonalRequirementPlanilla(requirementId, personalCompanyInfoId, employerInfoId, date) {
        return this._http.put(this.api.getUrl(`api/Requirement/UpdateDocument/${requirementId}?personalCompanyInfoId=${personalCompanyInfoId}&employerInfoId=${employerInfoId}&date=${date}`), null);
    }

    getPersonalCount() {
        return this._http.get(this.api.getUrl(`api/Personal/Count`));
    }

    // PersonalCV
    postPersonalCV(model) {
        return this._http.post(this.api.getUrl(`api/PersonalCV`), model);
    }

    getPersonalCV(PersonalInfoId) {
        return this._http.get(this.api.getUrl(`api/PersonalCV/${PersonalInfoId}`));
    }

    updatePhotoAddStudyCertificate(personalCVId, file) {
        return this._http.put(this.api.getUrl(`api/PersonalCV/AddStudyCertificate/${personalCVId}`), file);
    }

    updatePhotoAddExperienceCertificate(personalCVId, file) {
        return this._http.put(this.api.getUrl(`api/PersonalCV/AddExperienceCertificate/${personalCVId}`), file);
    }
    // PersonalCVk FIN

    /**
     * Permite obtener el personal vinculados al contrato por id del contrato o personalInfoId
     */
    getContractPersonalInfoId(id, personalInfoId) {
        return this._http.get(this.api.getUrl(`api/Contract/${id}/PersonalInfo?personalInfoId=${personalInfoId}`));
    }
    /**
     * Permite actualizar el personal vinculados al contrato por id del contrato o personalInfoId
     */
    postContractPersonalInfoId(ContractId, Id, model) {
        return this._http.post(this.api.getUrl(`api/Contract/${ContractId}/PersonalInfo?Id=${Id}`), model);
    }

    getPoll(): Observable<Poll[]> {
        return this._http.get<Poll[]>(this.api.getUrl(`api/quiz`));
    }

    /**
    *@personalCompanyInfoId @Id id encuesta de cada Personal
   */
    postPollPersonalCompanyInfoId(Id, personalCompanyInfoId, model) {
        return this._http.post(this.api.getUrl(`api/Quiz/Answer?quizId=${Id}&personalCompanyInfoId=${personalCompanyInfoId}`), model);
    }

    /**
     *@personalCompanyInfoId permite consular los resultados de la encuesta
     */
    getPollPersonalCompanyInfoId(personalCompanyInfoId) {
        return this._http.get(this.api.getUrl(`api/Quiz/AnwersByPersonal/${personalCompanyInfoId}`));
    }

    getVaccine(personalCompanyInfoId) {
        return this._http.get(this.api.getUrl(`api/Personal/Vaccine/${personalCompanyInfoId}`));
    }

    postVaccine(model) {
      // tslint:disable-next-line:radix
        model.PersonalCompanyInfoId = parseInt(model.PersonalCompanyInfoId);
        return this._http.post(this.api.getUrl(`api/Personal/Vaccine`), model);
    }

    getDocumentRequirementForContractAndPersonal(contractId, personalCompanyInfoId) {
        return this._http.get(this.api.getUrl(`api/Requirement/DocumentRequirement/${contractId}/${personalCompanyInfoId}`));
    }

    postTransferDocumentRequirement(model) {
      // tslint:disable-next-line:radix
        model.personalCompanyInfo = parseInt(model.personalCompanyInfo);
        return this._http.post(this.api.getUrl(`api/Requirement/DocumentRequirement/Transfer`), model);
    }

    getPersonalContracts(personalCompanyInfoId) {
        return this._http.get(this.api.getUrl(`api/Personal/${personalCompanyInfoId}/Contracts`));
    }

    // Nuevas apis conteratos
    getPersonalEmployerByPersonal(personalCompanyInfoId) {
        return this._http.get(this.api.getUrl(`api/PersonalEmployer/ByPersonal/${personalCompanyInfoId}`));
    }

    // Post
    postPersonalEmployer(model) {
        return this._http.post(this.api.getUrl(`api/PersonalEmployer`), model);
    }

    // pu
    putPersonalEmployer(personalEmployerId, model) {
        return this._http.put(this.api.getUrl(`api/PersonalEmployer/${personalEmployerId}`), model);
    }
    putPersonalEmployerSnapshot(personalEmployerId, model) {
      return this._http.put(this.api.getUrl(`api/PersonalEmployer/Snapshot/${personalEmployerId}`), model);
    }

    // pu
    deletePersonalEmployer(personalEmployerId) {
        return this._http.delete(this.api.getUrl(`api/PersonalEmployer/${personalEmployerId}`));
    }

    // exist contract?
    getPersonalEmployerByPersonalAndEmployer(personalCompanyInfoId, employerInfoId) {
        return this._http.get(this.api.getUrl(`api/PersonalEmployer/ByPersonalAndEmployer/${personalCompanyInfoId}/${employerInfoId}`));
    }

    // exist contract?
    getPersonalEmployerGenerateContractNumber() {
        return this._http.get(this.api.getUrl(`api/PersonalEmployer/GenerateContractNumber`));
    }

    getPersonalWorkShiftrprojectId(projectId) {
        return this._http.get(this.api.getUrl(`api/WorkShift/Project/${projectId}`));
    }

    getCountDocumentInfoPersonal(id) {
        return this._http.get<any>(this.api.getUrl(`api/DocumentInfo/Personal/Count/${id}`));
    }

    /**
     * Permite actualizar el comentario sin archivo
     * @param comment
     */
    putPersonalRequirementUpdateComment(id, comment) {
        return this._http.put(this.api.getUrl(`api/PersonalRequirement/UpdateComment/${id}?comment=${comment}`), null);
    }

    /**
     * Permite actualizar el comentario sin archivo
     * @param comment
     */
    putEmployerPersonalRequirementUpdateComment(id, comment) {
        return this._http.put(this.api.getUrl(`api/EmployerPersonalRequirement/UpdateComment/${id}?comment=${comment}`), null);
    }
    getSocialSecurityPersonalUnlimited(EmployerInfoId, documentNumber) {
        return this._http.get(this.api.getUrl(`api/SocialSecurity/PersonalUnlimited/${EmployerInfoId}?documentNumber=${documentNumber}`));
    }

    putExtractFromDocument(compnayInfoId: string, file) {
        return this._http.put(this.api.getUrl(`api/Personal/ExtracFromDoc/${compnayInfoId}`), file);
    }

    getExtractFromDocument(compnayInfoId: string) {
        return this._http.get(this.api.getUrl(`api/Personal/DatasheetExtraction/${compnayInfoId}`));
    }

    getMassUploadProcessId(massUploadId?) {
       return this._http.get(this.api.getUrl(`api/MassUpload/History/${massUploadId}`));
    }

    getMassUploadType() {
        return this._http.get(this.api.getUrl(`api/MassUpload/Type`));
    }

    postProcess(massUploadId?) {
        return this._http.post(this.api.getUrl(`api/MassUpload/Process/${massUploadId}`), {});
    }

    getMassUploadInfo(typeId) {
        return this._http.get(this.api.getUrl(`api/MassUpload/Info/${typeId}`));
    }

    putMassUploadInfoFile(massUploadId: string, file) {
        return this._http.put(this.api.getUrl(`api/MassUpload/UploadInfoFile/${massUploadId}`), file);
    }

    putMassUploadPayloadFile(massUploadId: string, file) {
        return this._http.put(this.api.getUrl(`api/MassUpload/UploadPayloadFile/${massUploadId}`), file);
    }

    postMassUploadInit(body) {
      return this._http.post(this.api.getUrl(`api/MassUpload/Init`), body);
    }

    getEmployerSelect() {
      return this._http.get(this.api.getUrl(`api/Employer/Select`));
    }
}
