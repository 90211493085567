import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContratingService } from '../../../services/modules/contrating.service';
import { environment } from 'src/environments/environment';
import { CommonOptions } from '../../../interfaces/interfaces';
import { CommonService } from '../../../services/modules/common.service';
import { SnackBarService } from '../../../services/utils/snackBar.service';
import { DialogService } from '../../../services/utils/dialog.service';
import { Router } from '@angular/router';
import { IndividualContract } from '../../../models/IndividualContract/individualContract';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { FechasService } from 'src/app/core/services/utils/fechas.service';
import { CommonMetodService } from 'src/app/core/services/utils/commonMetod.service';

export interface DialogData {
  message: string;
}

export interface EmployerManger{
  CompanyId: string;
  DocumentNumber: string;
  DocumentType: string;
  Logo: string;
  Name: string;
}

@Component({
  selector: 'app-dialog-contrato-individual',
  templateUrl: './dialog-contrato-individual.component.html',
  styleUrls: ['./dialog-contrato-individual.component.scss']
})
export class DialogContratoIndividualComponent implements OnInit {
  s3Bucket:string;

  controlEmployer = new FormControl();
  controlContractSelected = new FormControl();

  documentType: string;
  documentNumber: string = "";

  employers: EmployerManger[] = [];
  contracts: any[] = [];
  employerSelected: any [] = [];
  personalSelected: any [] = [];
  contractsSelected: any [] = [];

  personalEmployerInfoId: string = '';

  states: CommonOptions[] = [];

  companyDocumentType: CommonOptions[] = [];

  maxCaracter: number = 11;

  constructor(
    public dialogRef: MatDialogRef<DialogContratoIndividualComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private contratingService: ContratingService,
    private commonService: CommonService,
    private commonMetodService: CommonMetodService,
    private snackBService: SnackBarService,
    private dialogService: DialogService,
    private fechasService: FechasService,
    private router: Router
  ) { 
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    setTimeout(() => {
      this.getServices();
      this.getManagedEmployers();
    }, 300);
  }


  setValue() {
    if (this.controlEmployer.value !== undefined) {
      this.controlEmployer.setValue('');
      this.employerSelected = [];
      this.personalSelected = [];
      this.contractsSelected = [];
      this.documentNumber = '';
      this.documentType = 'CC';
    }
  }

  setValueContract() {
    if (this.controlContractSelected.value !== undefined) {
      this.controlContractSelected.setValue('');
      this.contractsSelected = [];
    }
  }

  setValuePersonal() {
    this.personalSelected = [];
    this.documentNumber = '';
    this.documentType = 'CC';
  }

  changeType(e) {
    if(e === 'PE') {
      this.maxCaracter = 16;
    } else if(e === 'CC') {
      this.maxCaracter = 11;
    } else {
      this.maxCaracter = 20;
    }
  }


  valueSelected(e) {
    this.employerSelected = [];
    this.contracts = [];
    this.employers.forEach((employer: any) => {
      employer.CompanyId === e ? this.employerSelected.push(employer): ''
    });
    
    if(this.employerSelected.length === 1) {
      this.getSelectedContracting(this.employerSelected[0].CompanyId);
      // console.log('this.employerSelected',this.employerSelected[0].CompanyId);
    }
  }

  getServices() {
    this.commonService.getCommonOptions("CompanyDocumentType").subscribe((data: CommonOptions[]) => {
      this.companyDocumentType = data.filter(documents => documents.Value === 'CC' || documents.Value === 'CE' || documents.Value === 'PE')
            .sort((a,b) => a.OrderNum > b.OrderNum ? 1 : -1);
      this.documentType = 'CC';
    });
    this.commonService.getCommonOptions('IndividualContractsStageType').subscribe(rest => {
      this.states = rest;
    });
  }


  getSelectedContracting(CompanyId) {
    this.contratingService.getListSelectContracting(CompanyId).subscribe((rest: any) => {
      // console.log('rest',rest);
      this.contracts = [];
      this.contracts = rest;
    });
    // contractsSelected
  }

  getManagedEmployers() {
    this.contratingService.getManagedEmployers().subscribe((rest: any) => {
      this.employers = rest.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
      if(this.employers.length === 1) {
        this.employerSelected.push(this.employers[0]);
        this.getSelectedContracting(this.employerSelected[0].CompanyId);
      }
    });
  }

  getPersonalDocument() {
    if(this.employerSelected.length > 0) {
      this.contratingService.getPersonalDocument(this.documentType, this.documentNumber).subscribe((data: any) => {
        if(data !== null) {
          this.personalSelected = [];
          this.personalSelected.push(data);
          this.contratingService.getPersonalEmployerInfoExit(this.employerSelected[0].CompanyId,this.personalSelected[0].PersonalId).subscribe((personalEmployerInfo: any)=> {
            this.personalEmployerInfoId = personalEmployerInfo.Id;
            this.personalSelected[0].Logo = personalEmployerInfo.Photo;
            this.snackBService.openSnackBar('Por favor seleccione lugar de trabajo para crear el contrato', 'X', 4000);
          }, (error: HttpErrorResponse) => {
            error.status === 412 ? 
            this.dialogService.openDialogConfirm(`Persona no registrada con este empleador. Desea registrarla`).afterClosed().subscribe(rest =>{
              if(rest !== false) {
            this.dialogService.openDialogCreatePersonal(this.employerSelected[0].CompanyId, this.documentNumber, this.documentType, this.personalSelected).afterClosed().subscribe(restCreatePersonalEmployerInfo =>{
              // console.log('Create personalEmployerInfo con Personal ya creado',restCreatePersonalEmployerInfo);
              if(restCreatePersonalEmployerInfo !== undefined) {
                this.personalSelected = [];
                this.personalEmployerInfoId = restCreatePersonalEmployerInfo.Id;
                let personal = {  PersonalId: restCreatePersonalEmployerInfo.persEmpleoyerInfo.PersonalId,
                                  Logo: restCreatePersonalEmployerInfo.Photo,
                                  Name: restCreatePersonalEmployerInfo.persEmpleoyerInfo.personal.Name, 
                                  LastName: restCreatePersonalEmployerInfo.persEmpleoyerInfo.personal.LastName,
                                  DocumentType: restCreatePersonalEmployerInfo.persEmpleoyerInfo.personal.DocumentType, 
                                  DocumentNumber: restCreatePersonalEmployerInfo.persEmpleoyerInfo.personal.DocumentNumber
                                }
                this.personalSelected.push(personal);
                this.snackBService.openSnackBar('Por favor seleccione lugar de trabajo para crear el contrato', 'X', 4000);
                // this.createIndividualContract(rest);
              }  else {
                  this.personalSelected = [];
              }
            })}}):'';
          });
        }
      }, (error: HttpErrorResponse) => {
        error.status === 412 ? 
          this.dialogService.openDialogConfirm(`Lo sentimos, no hay una persona registrada con este número. Desea crearla?`).afterClosed().subscribe(rest =>{
                if(rest !== false) {
                  this.dialogService.openDialogCreatePersonal(this.employerSelected[0].CompanyId, this.documentNumber, this.documentType).afterClosed().subscribe((restCreatePersonalEmployerInfo: any) =>{
                    // console.log('Create personalEmployerInfo desde 0',restCreatePersonalEmployerInfo);
                      if(restCreatePersonalEmployerInfo !== undefined) {
                        this.personalSelected = [];
                        this.personalEmployerInfoId = restCreatePersonalEmployerInfo.Id;
                       
                          let personal = {  PersonalId: restCreatePersonalEmployerInfo.persEmpleoyerInfo.PersonalId,
                                            Logo: restCreatePersonalEmployerInfo.Photo ,
                                            Name: restCreatePersonalEmployerInfo.persEmpleoyerInfo.personal.Name, 
                                            LastName: restCreatePersonalEmployerInfo.persEmpleoyerInfo.personal.LastName,
                                            DocumentType: restCreatePersonalEmployerInfo.persEmpleoyerInfo.personal.DocumentType, 
                                            DocumentNumber: restCreatePersonalEmployerInfo.persEmpleoyerInfo.personal.DocumentNumber
                                          }
                          this.personalSelected.push(personal);
                          this.snackBService.openSnackBar('Por favor seleccione lugar de trabajo para crear el contrato', 'X', 4000);
                          // this.createIndividualContract(rest);
                      } 
              });
            }
          })
        :'';
      });
      
    } else {
      this.snackBService.openSnackBar('PRIMERO SELECCIONE EMPLEADOR Y LUGAR DE TRABAJO', 'X', 4000);
    }
    
  }

  onSelectionChangeContract(e) {
    this.contracts.forEach((contract: any) => {
      contract.ContractId === e ? this.contractsSelected.push(contract): ''
    });
    // console.log('contractsSelected',this.contractsSelected);
  }

  getDisabled(): boolean {
    let enabled: boolean = false;
    this.personalSelected.length === 1 && this.employerSelected.length === 1 && this.contractsSelected.length === 1 ? enabled = true: enabled = false;
    return enabled;
  }


  getStateId(textState: string): number {
    let stateIniciat: number = 0;
    this.states.forEach(state => {
      state.Description === textState ? stateIniciat = state.Id: stateIniciat =  stateIniciat;
    });
    return stateIniciat;
  }

  startSolicitud() {
    this.contratingService.getPersonalBlackList(this.documentType, this.documentNumber, '').subscribe(personBlackList => {
      if(personBlackList === true) {
        this.dialogService.openDialogConfirm(`Lo sentimos, no podemos tramitar su solicitud`, false).afterClosed().subscribe(rest =>{});
      } else {
          this.contratingService.getIndividualContractPersonal(this.documentType, this.documentNumber).subscribe((individualContractPersonal: any[]) => {
            if(individualContractPersonal.length !== 0) {
              if(individualContractPersonal[0].IndividualContractStage.Description === 'LIQUIDADO') {
                if(this.fechasService.calculateDateMoreDays(individualContractPersonal[0].IndividualContractStage.CreateDate) === false) {
                  this.createIndividualContract(this.personalEmployerInfoId);
                } else {
                  this.snackBService.openSnackBar('Lo sentimos, la persona tiene registrado otro contrato activo en el sistema', 'X', 5000);
                }
              } else {
                this.dialogService.openDialogConfirm(`Ya exite un contrato, y esta en estado ${individualContractPersonal[0].IndividualContractStage.Description}`, false).afterClosed().subscribe(confirm =>{
                  // console.log(confirm);
                  // if(confirm !== false) {
                  //   this.router.navigate(['/admin/contracting/contract/', individualContractPersonal[0].Id]) ;
                  //   this.dialogRef.close();
                  // }             
                });
              }
            } else {
              // this.contratingService.getPersonalEmployerInfoExit(this.employerSelected[0].CompanyId,this.personalSelected[0].PersonalId).subscribe((exitPersonalEmployerInfo: any)=> {
              //   console.log('exitPersonalEmployerInfo',exitPersonalEmployerInfo);
                this.contratingService.getIndividualContractPersonalInforId(this.personalEmployerInfoId).subscribe((contrato: any) => {
                  // console.log('contrato',contrato);
                }, (error: HttpErrorResponse) => {
                  error.status === 412 ? 
                  this.createIndividualContract(this.personalEmployerInfoId)
                  : '';
                });
              // });
            }
            
          });
      }
    }); 
  }

  createIndividualContract(personalEmployerInfoId) {
    let individualContract: IndividualContract = {
      PersonalEmployerInfoId: personalEmployerInfoId,
      ContractId: this.contractsSelected[0].ContractId,
      ProjectId: this.contractsSelected[0].projectid,
      ContractNumber: this.commonMetodService._getContractNumber(),
      IndividualContractStage: 
        {
          StageTypeId: this.getStateId('INICIADO'),
          Comment: ''
        }
    };
    this.contratingService.postIndividualContract(individualContract).subscribe((individualContract: IndividualContract) => {
      this.router.navigate(['/admin/contracting/contract/', individualContract.Id]);
      this.dialogRef.close();
    });
  }

  getImg(logo) {
    let img = (logo === null || logo === '' || logo === undefined ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg': logo); 
    return img;
  }
  

}
