import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { BearerToken } from '../../models/BearerToken';
import { User } from '../../models/User';
import { ApiService } from '../../http/api.service';
import { Company } from '../../models/Company';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url:string = environment.api.url + "connect/token"
  private _currentUser:User

  // permissions: string[] = [];
  
  
  constructor(  private http:HttpClient,
                private api: ApiService,
                private router:Router,
                private apiService: ApiService,
              ) {}

  getToken(username:string ,password:string):Observable<BearerToken>{
    const params = new HttpParams({encoder: new CustomURLEncoder() })
        .set('username', username)
        .set('password',password)
        .set('client_id', "megadatosapi")
        .set('client_secret', "9BBBEDA7-426E-4350-A3CC-6B8A2680BD57")
        .set('scope', "openid roles profile email")
        .set('grant_type',"password")
        .set('remember',"false");

    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    };
    return this.http.post<BearerToken>(this.url, params.toString(), httpOptions)
    .pipe(tap(bearer=>{localStorage.setItem('access_token', bearer.access_token);}),
    shareReplay())
    // .subscribe(
    //     (resp) => {
        
    //     }
    // );

    
  }

  getMfaToken(username:string ,password:string, token: string, code: string):Observable<BearerToken>{
    const params = new HttpParams({encoder: new CustomURLEncoder() })
        .set('username', username)
        .set('password',password)
        .set('client_id', "megadatosapi")
        .set('client_secret', "9BBBEDA7-426E-4350-A3CC-6B8A2680BD57")
        .set('scope', "openid roles profile email")
        .set('grant_type',"password")
        .set('remember',"false")
        .set('token',token)
        .set('code',code);

    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    };
    return this.http.post<BearerToken>(environment.api.url+'connect/mfa-token', params.toString(), httpOptions)
    .pipe(tap(bearer=>{localStorage.setItem('access_token', bearer.access_token);}),
    shareReplay())
  }

  public cleanSession(isSymptomReport?: boolean) {
    this.setUser(null);
    //this.api.headers = this.api.headers.delete('Authorization');
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('selectedAmin');
    //this.store.dispatch(new AuthUser.SetUser(null));
    if(isSymptomReport === undefined){ 
      this.router.navigateByUrl('/auth/login');  
    }
    // this.snackBService.openSnackBar('DESLOGUEADO CORRECTAMENTE!', 'X', 3000);
  }
  public getUser(): User {
    // get user in local storage ??
    return JSON.parse(localStorage.getItem("user")) as User;
  }

  public getCompany(): Company {
    // get user in local storage ??
    return JSON.parse(localStorage.getItem("selectedAmin")) as Company;
  }

  public IsSuperUser(): boolean {
    let is = false;
    let user = JSON.parse(localStorage.getItem("user")) as User;
    if(user !== undefined) {
      if(user.IsSuperUser === true) {
        is = true;
      }
    }
    return is;
  }

  protected setUser(user: User) {
    this._currentUser = user;
    // save user in local storage ??
    // localStorage.setItem('_user', JSON.stringify(user));
    // ? TODO: bind to user WebSocket channel
  }
  isAuthenticated(){
    return !!localStorage.getItem('access_token')
  }
  
  //Sesion para la encuenta del personal
  AddSessionSymptomReport(clientId, docnumber, pin) {
    this.cleanSession(true);
    return this.http.post(this.apiService.getUrl(`api/SurveySymptoms/ValidateLink?clientId=${clientId}&docnumber=${docnumber}&pin=${pin}`), null);
  }

  getAccountMessages(){
    return this.http.get(this.apiService.getUrl(`api/User/AccountMessages`));
  }

  //Apis register create user login
  postRegisterUser(model) {
    return this.http.post(this.api.getUrl(`api/User/WithOuthPersonal`), model);
  }

  putRegisterUserPhoto(id, document) {
    return this.http.put(this.api.getUrl(`api/User/${id}/UpdatePhoto`), document);
  }

}



export class CustomURLEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
      return encodeURIComponent(key); 
  }
  encodeValue(key: string): string { 
      return encodeURIComponent(key); 
  }
  decodeKey(key: string): string { 
      return decodeURIComponent(key); 
   }
  decodeValue(key: string) {
      return decodeURIComponent(key); 
   }
}