<div class="box">
  <div class="header">
    <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{data?.title || 'N/A'}}</div>
  </div>
  <div class="content-card list-card">
    <div class="subTitle">Seleccione el tipo de retiro </div>
    <div class="fields-group">
      <app-select-search *ngIf="data"
                         class="selected"
                         [_dataSource]="data.data"
                         [textInput]="data?.optionsCardSelect?.textInput"
                         [dataShow]="data.optionsCardSelect?.dataShow"
                         [otherFilter]="data.optionsCardSelect?.otherFilter"
                         [dataValue]="data?.optionsCardSelect?.dataValue"
                         [isCard]="data?.optionsCardSelect?.isCard"
                         [isCardImage]="data?.optionsCardSelect?.isCardImage"
                         [isDescription]="data?.optionsCardSelect?.isDescription"

                         [card]="{
                    img: data?.optionsCardSelect?.card?.img,
                    title: data?.optionsCardSelect?.card?.title,
                    subTitle: data?.optionsCardSelect?.card?.subTitle,
                    finalLeft: data?.optionsCardSelect?.card?.finalLeft,
                    finalRight: data?.optionsCardSelect?.card?.finalRight,
                    itemKey: data?.optionsCardSelect?.card?.itemKey,
                    itemValue: data?.optionsCardSelect?.card?.itemValue
                }"
                         (onSelectionChange)="selected($event); changeSelect($event)"
                         [siteCtrl]="controlSelected"
                         [inputType]="'text'"
                         [selectOpen]="true"
                         [placeholder]="'Seleccione...'">
      </app-select-search>
    </div>

    <div *ngIf=" showSelect">
      <div class="subTitle-Date" >Seleccione la fecha de fin de acceso </div>
      <br>
      <div class="fields-group-date-in">
        <div class="field-group-date" style="margin-top: -20px;">
          <div class="input-medium-datetime" matTooltip="FIN DEL ACCESO">
            <mat-form-field appearance="none">
              <input matInput type="datetime-local"
                     style="max-width: 140px !important; height: 20px !important; display: flex; flex-direction: column;"
                     #inputFirstDate
                     [value]="this.fechasService.getDateHours(endDate)"
                     (change)="changeFirstDate(inputFirstDate?.value)"
                     [disabled]="true"
              >
            </mat-form-field>
          </div>
        </div>
        <div class="check">
          <mat-checkbox color="warn" [(ngModel)]="checkbox1" (change)="onCheckboxChange('checkbox1')"></mat-checkbox>
        </div>
      </div>

      <div class="group-field-date">
        <div class="date-fields-group" style="margin-top: 0px;">
          <div class="input-field-medium-date-datetime" matTooltip="FIN DEL ACCESO">
            <mat-form-field appearance="outline">
              <input matInput type="datetime-local"
                     style="max-width: 140px !important; height: 20px !important; display: flex; flex-direction: column;"
                     #inputSecondDate
                     [min]="minDateVigenciaContract"
                     (change)="changeSecondDate(inputSecondDate?.value)"
                     [value]="this.fechasService.getDateNow()"
              >
            </mat-form-field>
          </div>
        </div>
        <div class="check-2">
          <mat-checkbox color="warn"  [(ngModel)]="checkbox2" (change)="onCheckboxChange('checkbox2')"></mat-checkbox>
        </div>
      </div>

      <div class="subTitle-commentary">Comentario de Inicio </div>
      <div class="commentary">
        <mat-form-field class="select-icon" appearance="outline">
          <textarea matInput [(ngModel)]="commentary" rows="5" maxlength="{{280}}"></textarea>
          <mat-error>Requerido</mat-error>
        </mat-form-field>
      </div>
    </div>

    <br>
    <div class="actions-dialog"style="margin: 3px;">
      <button mat-button class="btn-cancelar" *ngIf="data?.btnClose === true" (click)="close()" >{{data?.textClose}}</button>&nbsp;&nbsp;
      <button mat-button class="btn-generar" *ngIf="data?.btnConfirm === true"  (click)="save()">{{data?.textConfirm}}</button>
    </div>
  </div>

</div>

