import { Component, OnInit, Inject, VERSION } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { ModelSaveCarnetPrintResponse } from '../../../interfaces/interfaces';
import { PersonalService } from '../../../services/modules/personal.service';
import { PersonalInfoContract } from 'src/app/core/models/Personal/personalInfoContract';

export interface DialogData {
  print: string;
  personalInfo: PersonalInfoContract;
}

@Component({
  selector: 'app-dialog-print-pre-carnet',
  templateUrl: './dialog-print-pre-carnet.component.html',
  styleUrls: ['./dialog-print-pre-carnet.component.scss']
})
export class DialogPrintPreCarnetComponent implements OnInit {
  s3Bucket: string;
  position = 'horizontal';
  print = true;

  personalSelected: any;

  // CODEQR
  name = 'ANALITYCO CARNET' + VERSION.major;
  elementType = NgxQrcodeElementTypes.CANVAS;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  valueQR = '';

  constructor(
    public dialogRef: MatDialogRef<DialogPrintPreCarnetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private personalService: PersonalService
  ) {
    // console.log('data', data);
    this.personalSelected = data.personalInfo;
    if (this.data.print === 'IMPRIMIR') {
      this.valueQR = data.personalInfo.CarnetCode;
    }
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
  }

  rePrintCarnet(){
    if (this.data.print === 'GENERAR') {
      if (this.data.personalInfo.CarnetId === null || this.data.personalInfo.CarnetId === undefined) {
        this.personalService.printSaveCarnet({
          EmployerId: this.data.personalInfo.EmployerId === null ?
            this.data.personalInfo.SubContractorId : this.data.personalInfo.EmployerId,
          IsActive: this.data.personalInfo.IsActive,
          PersonalCompanyInfoId: this.data.personalInfo.PersonalCompanyInfoId,
        }).subscribe((modelSaveCarnetPrintResponse: ModelSaveCarnetPrintResponse) => {
          this.valueQR = modelSaveCarnetPrintResponse.Code;
          this.print = false;
          setTimeout(function(){
          window.print();
          }, 500);
        });
      } else {
        this.personalService.printSaveCarnetPATCH(this.data.personalInfo.CarnetId).subscribe(() => {
          this.personalService.printSaveCarnet({
            EmployerId: this.data.personalInfo.EmployerId === null ?
              this.data.personalInfo.SubContractorId : this.data.personalInfo.EmployerId,
            IsActive: this.data.personalInfo.IsActive,
            PersonalCompanyInfoId: this.data.personalInfo.PersonalCompanyInfoId,
          }).subscribe((modelSaveCarnetPrintResponse: ModelSaveCarnetPrintResponse) => {
            this.valueQR = modelSaveCarnetPrintResponse.Code;
            this.print = false;
            setTimeout(function(){
            window.print();
            }, 500);
          });
        });
      }
    } else if (this.data.print === 'IMPRIMIR') {
      this.print = false;
      setTimeout(function(){
      window.print();
      }, 500);
    }
  }

  onNoClick(): void {
    if (this.data.print === 'GENERAR' && this.valueQR !== ''){
      this.dialogRef.close('GENERADO');
    } else if (this.valueQR === ''){
      this.dialogRef.close();
    } else {
      this.dialogRef.close();
    }
  }

}
