<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'INSUMOS' || 'N/A'}}</div>
    </div>
    
    <div class="content-card" autocomplete="off">
        <form [formGroup]="form">
            <mat-form-field class="input-field" appearance="outline">
                <label><strong>DESCRIPCIÓN</strong></label>
                <input matInput type="text" formControlName="Name">
                <mat-error>Requerido</mat-error>
            </mat-form-field>
            <app-select-search class="select-component-medium"
                            [appearance]="'outline'"
                            [_dataSource]="supplyGroup" [textInput]="'GRUPO DE INSUMO'" [dataShow]="'Abbreviation'" [otherFilter]="'Name'" [dataValue]="'Id'" [siteCtrl]="form.get('SupplyGroupId')"
                            [inputType]="'text'">
            </app-select-search>
            <div class="form-group">
                <mat-form-field class="input-field-50" appearance="outline">
                    <label><strong>ORDEN</strong></label>
                    <input matInput type="number" formControlName="Order"
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">
                    <mat-error>Requerido</mat-error>
                </mat-form-field>
                <div class="inputTogle">
                    <mat-slide-toggle formControlName="IsActive"></mat-slide-toggle>&nbsp;
                    <label>ESTADO</label>
                </div>
            </div>
        </form>
    </div>

    <div class="btn-actions"style="margin: 3px;">
        <button mat-button class="btn-cancelar" (click)="close()">{{'CANCELAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" (click)="save()">{{data.Id !== undefined ? 'ACTUALIZAR': 'GUARDAR'}}</button>
    </div>
</div>
