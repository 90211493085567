import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpClient } from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

    constructor(
        private api: ApiService,
        private _http: HttpClient
    ) { }

    /**
     * Permite obtener los proyectos
     */
    getProjects() {
        return this._http.get<any>(this.api.getUrl(`api/Project`));
    }

    /**
     * Permite obtene datos del proyecto por Id
     */
     getProjectId(Id) {
        return this._http.get<any>(this.api.getUrl(`api/Project/${Id}`));
    }

    /**
     * Permite obtener los proyectos
     */
     getProjectCount() {
        return this._http.get<any>(this.api.getUrl(`api/Project/Count`));
    }

    /**
     * Permite obtener los usuario del proyecto
     */
     getProjectsUsers(ProjectId) {
        return this._http.get<any>(this.api.getUrl(`api/Project/${ProjectId}/Users`));
    }

    /**
     * Permite actualizar projecto por id
     */
    putProject(ProjectId, model) {
        return this._http.put<any>(this.api.getUrl(`api/Project/${ProjectId}`),model);
    }

    /**
     * Permite CREAR PROJECT
     */
    postProject(model) {
        return this._http.post<any>(this.api.getUrl(`api/Project`),model);
    }

    /**
     * Permite actualizar logo project
     */
    putProjectUpdateLogo(ProjectId, formData) {
        return this._http.put<any>(this.api.getUrl(`api/Project/UpdateLogo/${ProjectId}`),formData);
    }

    /**
     * Permite obtener roles por royecto
     */
    getRolesProject(ProjectId) {
        return this._http.get<any>(this.api.getUrl(`api/Project/${ProjectId}/Roles`));
    }

    postVincularUserProject(ProjectId, userId, model) {
        return this._http.post<any>(this.api.getUrl(`api/Project/${ProjectId}/Users${userId === '' ? '': '?Id='+userId}`), model);
    }

    deleteVincularUserProject(ProjectId, userId) {
        return this._http.delete<any>(this.api.getUrl(`api/Project/${ProjectId}/Users/${userId}`));
    }

    deleteCompanyProject(id, companyId) {
        return this._http.delete<any>(this.api.getUrl(`api/Project/${id}/JoinCompanies/${companyId}`));
    }

    postProjectRequirements(ProjectId, model) {
        return this._http.post<any>(this.api.getUrl(`api/Project/${ProjectId}/Requirements`), model);
    }

    postAddPerfilProject(ProjectId, Id, model) {
        return this._http.post<any>(this.api.getUrl(`api/Project/${ProjectId}/Roles/${Id}`), model);
    }

    deletePerfilProject(ProjectId, Id) {
        return this._http.delete<any>(this.api.getUrl(`api/Project/${ProjectId}/Roles/${Id}`));
    }

    putProjectStage(Id, model) {
        return this._http.put<any>(this.api.getUrl(`api/ProjectStage/${Id}`), model);
    }

    deleteProjectStage(Id) {
        return this._http.delete<any>(this.api.getUrl(`api/ProjectStage/${Id}`));
    }

    postProjectStage(model) {
        return this._http.post<any>(this.api.getUrl(`api/ProjectStage`), model);
    }

    getProjectJoinCompanies(ProjectId) {
        return this._http.get<any>(this.api.getUrl(`api/Project/${ProjectId}/JoinCompanies`));
    }

    postProjectJoinCompanies(ProjectId, model) {
        return this._http.post<any>(this.api.getUrl(`api/Project/${ProjectId}/JoinCompanies`), model);
    }

    // Horario para entrada y salida apis
    getProjectSchedule(ProjectId) {
        return this._http.get<any>(this.api.getUrl(`api/Project/Schedule/${ProjectId}`));
    }

    postProjectSchedule(model) {
        return this._http.post<any>(this.api.getUrl(`api/Project/Schedule`), model);
    }

  //work shift

  postWorkShift(model) {
    return this._http.post(this.api.getUrl('api/WorkShift'), model);
  }
  getWorkShift(projectId) {
    return this._http.get(this.api.getUrl(`api/WorkShift/Project/${projectId}`));
  }
  updateWorkShift(id, model) {
    return this._http.put(this.api.getUrl(`api/WorkShift/${id}`), model);
  }

  getFaceRecognitionProject(ProjectId): Observable<any[]>{
    return this._http.get<any[]>(this.api.getUrl(`api/FaceRecognition/Project/${ProjectId}`));
  }

  getFaceRecognitionFromAdmin(adminInfoId): Observable<any[]>{
    return this._http.get<any[]>(this.api.getUrl(`api/FaceRecognition/${adminInfoId}`));
  }

  postFaceRecognition(body) {
    return this._http.post(this.api.getUrl(`api/FaceRecognition/Project`), body);
  }

}
