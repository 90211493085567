import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
  title: string;
  message: string;
  btnCancel: string;
}

@Component({
  selector: 'app-dialog-contract-add-transfer-personnel',
  templateUrl: './dialog-contract-add-transfer-personnel.component.html',
  styleUrls: ['./dialog-contract-add-transfer-personnel.component.scss']
})
export class DialogContractAddTransferPersonnelComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogContractAddTransferPersonnelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
    console.log('DialogContractAddTransferPersonnelComponent');
  }

  onCancel(): void {
    this.dialogRef.close(true);
  }

}
