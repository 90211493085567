<div class="box">
    <div class="header">
      <div class="content greendAnalityco">{{'PROYECTOS VINCULADOS' || 'N/A'}}</div>
    </div>
    <div class="content-card-employer" >
      <form [formGroup]="formVincularProyect">
        <div class="form-group-list" >
          <app-select-search
            style="margin-top: 20px;"
            [_dataSource]="contractProjects" [textInput]="'PROYECTO'" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formVincularProyect.get('ProjectId')"
            [inputType]="'text'" (onSelectionChange)="changeSelectedProyect($event)">
          </app-select-search>

          <mat-label class="multiChips-title">ETAPAS DEL PROYECTO</mat-label>
          <mat-form-field class="chip-list"  appearance="outline" style="margin-top: 0px !important;">
            <mat-chip-list #chipListeconomicActivity aria-label="Fruit selection">
              <mat-chip
                *ngFor="let values of viewValueseconomicActivity"
                [selectable]="true"
                [removable]="true"
                (removed)="removeActivity(values)">
                {{values}}
                <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
              </mat-chip>
              <input
                placeholder="+Seleccionar"
                #InputControleconomicActivity
                [formControl]="controleconomicActivity"
                [matAutocomplete]="autoeconomicActivity"
                [matChipInputFor]="chipListeconomicActivity"
                (matChipInputTokenEnd)="addActivity($event)"
              >
            </mat-chip-list>
            <mat-autocomplete #autoeconomicActivity="matAutocomplete" (optionSelected)="selectedActivity($event)">
              <mat-option *ngFor="let item of economicActivityObs" [value]="item.Id">
                {{item.Name || ''}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <div class="fields-group" >
            <div class="fields-group" [ngClass]="calculateIfDateIsGreaterThanToday()">
              <div class="input-field-medium-date" >
                <mat-label>Inicio</mat-label>
                <mat-icon (click)="pickerStart.open()">today</mat-icon>
                <mat-form-field>
                  <input matInput [matDatepicker]="pickerStart" formControlName="StartDate" (click)="pickerStart.open()">
                  <mat-datepicker #pickerStart></mat-datepicker>
                  <mat-error>Requerido</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="fields-group" >
              <div class="input-field-medium-date">
                <mat-label>Fin</mat-label>
                <mat-icon (click)="pickerFinist.open()">today</mat-icon>
                <mat-form-field>
                  <input matInput [matDatepicker]="pickerFinist" [min]="minDateNow" formControlName="FinishDate" (click)="pickerFinist.open()">
                  <mat-datepicker #pickerFinist></mat-datepicker>
                  <mat-error>Requerido</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="actions-dialog"style="margin: 3px;">
            <button mat-button class="btn-cancelar" (click)="cancel()" type="button">CANCELAR</button>&nbsp;&nbsp;
            <button mat-button type="button" class="btn-generar" (click)="saveProyectVinc()" >GUARDAR</button>
          </div>
        </div>
      </form>

    </div>
</div>



