<title-dialog [title]="'GESTOR DE DOCUMENTOS'" [color]="'greendAnalityco'"></title-dialog>
<div class="content-dialog">
    <div class="content-show">
        <ngx-dropzone (change)="onSelect($event)" class="dropzone" *ngIf="data.typeOperator === 'showDelete'" [multiple]="false">
            <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
        </ngx-dropzone>
        <div class="list">
                <div class="item" *ngFor="let file of files; let i = index">
                    <div *ngIf="file">{{file.name || 'SIN NOMBRE'}}</div>
                </div>
                <div class="item" *ngIf="files.length === 0 && url !== ''">
                    <div >{{getName() || 'SIN NOMBRE'}}</div>
                    <mat-icon class="mat-icon-show" *ngIf="url !== '' && (data.typeOperator === 'show' || data.typeOperator === 'showDelete') && !isXLSX(url)" (click)="openImgVideo(url)">visibility</mat-icon>
                    <a *ngIf="url !== '' && (data.typeOperator === 'showDelete') && isXLSX(url)" [href]="getUrlFile(url)" target="_blank"><mat-icon class="mat-icon-show">visibility</mat-icon></a>
                </div>
            <div class="item" *ngIf="url === '' && files.length === 0">{{'0 DOCUMETOS GUARDADOS'}}</div>
        </div>
    </div>

    <div class="actions-dialog">
        <button mat-button class="btn-cancelar" (click)="onNoClick()" >{{'CERRAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" *ngIf="files.length > 0" type="button" (click)="updatePhoto()">GUARDAR</button>
    </div>
</div>