import {Component, Inject, OnInit} from '@angular/core';
import {OptionsCardSelect} from '../../../interfaces/interfaces';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackBarService} from '../../../services/utils/snackBar.service';
import {FechasService} from '../../../services/utils/fechas.service';
import {FormControl, FormGroup} from '@angular/forms';

export interface DialogData {
  optionsCardSelect: OptionsCardSelect;
  title: string;
  data: any[];
  disableClose: boolean;
  btnClose: boolean;
  btnConfirm: boolean;
  textClose: string;
  textConfirm: string;
  autoClose: boolean;
  message: string;
  colorMessage: string;
  endDate: string;
}

@Component({
  selector: 'app-dialog-selected-control-withdrawals',
  templateUrl: './dialog-selected-control-withdrawals.component.html',
  styleUrls: ['./dialog-selected-control-withdrawals.component.scss']
})
export class DialogSelectedControlWithdrawalsComponent implements OnInit {

  endDate = '';
  commentary = '';

  formDates: FormGroup;

  checkbox1 = true;
  checkbox2 = false;
  showSelect = false;

  datasource: any[] = [];

  controlSelected = new FormControl();
  minDateVigenciaContract = this.fechasService.getDateNow();

  constructor(
    public dialogRef: MatDialogRef<DialogSelectedControlWithdrawalsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBService: SnackBarService,
    public fechasService: FechasService,
  ) {
    if (data.data !== null && data.data.length !== 0){
      this.datasource =  this.data.data;
      this.endDate = this.data.endDate;

      if (this.endDate === null) {
        this.endDate = this.fechasService.getDateNow();
      }else{
        if (this.endDate < this.fechasService.getDateNow()){
          this.endDate = this.fechasService.getDateNow();
        }
      }
    }

    this.formDates = new FormGroup({
      firstDate: new FormControl(this.endDate),
      secondDate: new FormControl(this.fechasService.getDateNow())
    });
  }

  ngOnInit(): void {
    this.onCheckboxChange('checkbox1');
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    if (this.controlSelected.value !== '' && this.controlSelected.value !== null) {
      this.dialogRef.close({controlSelected: this.controlSelected.value,
        commentary: this.commentary,
        firstDate: this.formDates.get('firstDate').value,
        checkbox1: this.checkbox1,
        secondDate: this.formDates.get('secondDate').value,
        checkbox2: this.checkbox2});
    } else {
      this.snackBService.openSnackBar('Debes seleccionar alguna opción para continuar.', 'X', 4000);
    }
  }

  selected(e) {
    if (this.data.autoClose === undefined) {
      this.dialogRef.close(e);
    }
  }

  onCheckboxChange(checkbox: string): void {
    if (checkbox === 'checkbox1') {
      this.checkbox2 = false;
    } else if (checkbox === 'checkbox2') {
      this.checkbox1 = false;
    }
  }

  changeFirstDate(date: any){
    this.formDates.get('firstDate').setValue(date);
  }

  changeSecondDate(date: any){
    this.formDates.get('secondDate').setValue(date);
  }

  changeSelect(requirement?) {
    this.showSelect = true;
  }

}
