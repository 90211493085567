import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { environment } from 'src/environments/environment';

export interface DialogData {
  Id: string;
}

@Component({
  selector: 'app-dialog-occupation',
  templateUrl: './dialog-occupation.component.html',
  styleUrls: ['./dialog-occupation.component.scss']
})
export class DialogOccupationComponent implements OnInit {
  s3Bucket:string;

  form: FormGroup;
  imgCropperResult: string;
  
  constructor(public dialogRef: MatDialogRef<DialogOccupationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonService: CommonService,
    private fb: FormBuilder) { 
      this.form = this.fb.group({
        Code: new FormControl('',Validators.required),
        Name: new FormControl('',Validators.required),
      });
      if(this.data.Id !== undefined) {
        this.commonService.getJobId(this.data.Id).subscribe((rest: any) => {
          this.form.get('Code').setValue(rest.Code);
          this.form.get('Name').setValue(rest.Name);
        });
      }
    }

  ngOnInit(): void {
    this.s3Bucket=environment.api.s3Bucket;
  }


  close() {
    this.dialogRef.close(false);
  }

  save() {
    if(this.form.status === 'VALID')  {
      if(this.data.Id !== undefined) {
        let model = {
          ...this.form.value,
          Id: this.data.Id
        }
        this.commonService.putJobId(this.data.Id, model).subscribe(rest => {
          this.dialogRef.close(true);
        })
      } else {
        this.commonService.postJob(this.form.value).subscribe((rest: any) => {
          this.dialogRef.close(true);
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

}
