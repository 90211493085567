import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MinutasService {

  constructor(
        private api: ApiService,
        private _http: HttpClient
    ) { }

    /**
     * Permite obtener las minutas por proyecto
     */
    getMinutasPersonal(projectId, date, api) {
        return this._http.get<any>(this.api.getUrl(`api/${api}/Memorandum/${projectId}?date=`+date));
    }

    /**
     * Permite obtener las Empresa o Proveedor
     */
    getCompanyOrSupplier() {
      return this._http.get<any>(this.api.getUrl(`api/Company/Companies/All?v2=true`));
    }

    /**
     * Permite obtener los insumos
     */
    getSupplies() {
      return this._http.get<any>(this.api.getUrl(`api/Company/Supply?IsActive=true`));
    }

    /**
     * Permite guardar los cambios en la minuta
     */
    putSaveMinutesChanges(body) {
      return this._http.put<any>(this.api.getUrl(`api/Company/Supply/Report`), body);
    }
}


