import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss']
})
export class ImgComponent implements OnInit {

  @Input() text: string;
  @Input() type: string;
  @Input() color: string;
  @Input() width: string;
  @Input() height: string;
  @Input() IsBlocked: any;

  imgs: any[] = [
    {type: 'file-negro', url: 'https://analityco-acceso.s3.amazonaws.com/assets/images/file-negro.png'},
    {type: 'file-negro-ojo', url: 'https://analityco-acceso.s3.amazonaws.com/assets/images/file-negro-ojo.png'},
    {type: 'file-orange', url: 'https://analityco-acceso.s3.amazonaws.com/assets/images/docrecor.png'},
    {type: 'file-orange-ojo', url: 'https://analityco-acceso.s3.amazonaws.com/assets/images/docrecyeyor.png'},
    {type: 'file-rojo', url: 'https://analityco-acceso.s3.amazonaws.com/assets/images/file-rojo.png'},
    {type: 'file-rojo-ojo', url: 'https://analityco-acceso.s3.amazonaws.com/assets/images/file-rojo-ojo.png'},
    {type: 'file-greend', url: 'https://analityco-acceso.s3.amazonaws.com/assets/images/docreq.png'},
    {type: 'file-greend-ojo', url: 'https://analityco-acceso.s3.amazonaws.com/assets/images/docreqeye.png'},
    {type: 'file-azul', url: 'https://analityco-acceso.s3.amazonaws.com/assets/images/file-azul.png'},
  ];

  constructor() { }

  ngOnInit(): void {
  }
  
  getUrl(type): string {
    return this.imgs.find(x => x.type === type).url;
  }

}
