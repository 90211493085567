import { FormControl, FormGroup, Validators } from '@angular/forms';

export class SocialSecPersonalList {
  AFP: string;
  EPS: string;
  ARL: string;
  ArlId: number;  
  AfpId: number; 
  EpsId: number; 
  ARLRisk: string;
  CCF: string;
  CCFDays: number;
  CCFId: number;
  DocumentNumber: string;
  Employer: string;
  IBCAFP: number;
  IBCARL: number;
  IBCCCF: number;
  IBCEPS: number;
  Name: string;
  LastName: string;
  PersonalCompanyInfoId: number;
  PersonalGuidId: string;
  PersonalId: string;
  Photo: string;
  DocumentType: string;
  StartDate: string;
  TypeARL: string;
  DescEPS: string;
  IsActive: boolean;
  NameAFP: string;
  NameEPS: string; 
  UserId: string;
  UserName: string;
  CreatedDate: string;
  EPSDays: number;   
  AFPDays: number;  
  ARLDays: number;  
  NameARL: string;  
  CurrentAfpId: number;
  CurrentDescAFP: string;
  CurrentDescEPS: string;
  CurrentEpsId: number;
  CurrentNameAFP: string;
  CurrentNameEPS: string;
  DescriptionAFP: string;
  SocialSecurity2Id: number;
  Salary?: number;
  SocialSecurityId: number; 
  ing?: boolean;
  ret?: boolean;
  No?: number;
  Extracted?: boolean;
  IsExempt?: boolean;
  DaysWithEntries?: number;

    static asFormGroup(personal: SocialSecPersonalList): FormGroup {
        const fg = new FormGroup({
          No: new FormControl(0),
          Extracted: new FormControl(false),
          AFP: new FormControl(personal.EPS, Validators.required),
          EPS: new FormControl(personal.EPS, Validators.required),
          ARL: new FormControl(personal.ARL, Validators.required),
          ArlId: new FormControl(personal.ArlId ),
          AfpId: new FormControl(personal.AfpId, Validators.required),
          EpsId: new FormControl(personal.EpsId, Validators.required),
          ARLRisk: new FormControl(personal.ARLRisk, Validators.required),
          CCF: new FormControl(personal.CCF, Validators.required),
          CCFDays: new FormControl(personal.CCFDays, Validators.required),
          CCFId: new FormControl(personal.CCFId, Validators.required),
          DocumentNumber: new FormControl(personal.DocumentNumber, Validators.required),
          Employer: new FormControl(personal.Employer, Validators.required),
          IBCAFP: new FormControl(personal.IBCAFP, Validators.required),
          IBCARL: new FormControl(personal.IBCARL, Validators.required),
          IBCCCF: new FormControl(personal.IBCCCF, Validators.required),
          IBCEPS: new FormControl(personal.IBCEPS, Validators.required),
          Name: new FormControl(personal.Name, Validators.required),
          LastName: new FormControl(personal.LastName, Validators.required),
          PersonalCompanyInfoId: new FormControl(personal.PersonalCompanyInfoId, Validators.required),
          PersonalGuidId: new FormControl(personal.PersonalGuidId, Validators.required),
          PersonalId: new FormControl(personal.PersonalId, Validators.required),
          Photo: new FormControl(personal.Photo, Validators.required),
          DocumentType: new FormControl(personal.DocumentType, Validators.required),
          StartDate: new FormControl(personal.StartDate, Validators.required),
          TypeARL: new FormControl(personal.TypeARL, Validators.required),
          DescEPS: new FormControl(personal.DescEPS, Validators.required),
          IsActive: new FormControl(personal.IsActive, Validators.required),
          NameAFP: new FormControl(personal.NameAFP, Validators.required),
          NameEPS: new FormControl(personal.NameEPS, Validators.required),
          UserId: new FormControl(personal.UserId, Validators.required),
          UserName: new FormControl(personal.UserName, Validators.required),
          CreatedDate: new FormControl(personal.CreatedDate, Validators.required),
          EPSDays: new FormControl(personal.EPSDays, Validators.required),
          AFPDays: new FormControl(personal.AFPDays, Validators.required),
          ARLDays: new FormControl(personal.ARLDays, Validators.required),
          NameARL: new FormControl(personal.NameARL, Validators.required),
          CurrentAfpId: new FormControl(personal.CurrentAfpId, Validators.required),
          CurrentDescAFP: new FormControl(personal.CurrentDescAFP, Validators.required),
          CurrentDescEPS: new FormControl(personal.CurrentDescEPS, Validators.required),
          CurrentEpsId: new FormControl(personal.CurrentEpsId, Validators.required),
          CurrentNameAFP: new FormControl(personal.CurrentNameAFP, Validators.required),
          CurrentNameEPS: new FormControl(personal.CurrentNameEPS, Validators.required),
          DescriptionAFP: new FormControl(personal.DescriptionAFP, Validators.required),
          SocialSecurity2Id: new FormControl(personal.SocialSecurity2Id, Validators.required),
          Salary: new FormControl(personal.Salary, Validators.required),
          SocialSecurityId: new FormControl(personal.SocialSecurityId, Validators.required),
          ing: new FormControl(personal.ing, Validators.required),
          ret: new FormControl(personal.ret, Validators.required),
          IsExempt: new FormControl(personal.IsExempt, Validators.required),
          DaysWithEntries: new FormControl(personal.DaysWithEntries, Validators.required),
        });
        return fg;
      }
}