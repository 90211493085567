<header class="header">
    <div class="ctn-logo">
        <button (click)="menuTogle = !menuTogle" type="button" id="btn-menu" class="btn-menu">
          <i class="fa fa-bars"></i>
        </button>
        <a class="logo">
            <span><img src="./assets/images/LOGO.png" alt="" width="150" height="50"></span>
        </a>
    </div>
    <div class="ctn-btns-header">

        <nav class="dropdownmenu">
            <ul>
                <li>
                    <a>
                        <div *ngIf="userAuth.avatar" class="user-photo-email">
                            <img src={{s3Bucket+userAuth.avatar}} class="photo" />
                            <div>{{ userAuth.username || 'Sin UserName'}}</div>
                        </div>
                        <div *ngIf="!userAuth.avatar">
                            <i class="fa fa-user-circle fa-2x"></i> {{ userAuth.username || 'Sin UserName'}}
                        </div>
                    </a>
                    <ul id="submenu">
                        <li disabled><a>Perfil</a></li>
                        <li (click)="logOut()"><a>Cerrar sesión</a></li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</header>