import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DetailsSurveySymptoms } from 'src/app/core/interfaces/interfaces';
import { SymptomReportService } from 'src/app/core/services/modules/symptomReport.service';

export interface DialogData {
  Id: string;
}

@Component({
  selector: 'app-details-survey-symptoms',
  templateUrl: './details-survey-symptoms.component.html',
  styleUrls: ['./details-survey-symptoms.component.scss']
})
export class DetailsSurveySymptomsComponent implements OnInit {

  detailsSurveySymptoms: DetailsSurveySymptoms;
  constructor(
    public dialogRef: MatDialogRef<DetailsSurveySymptomsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private symptomReportService: SymptomReportService
  ) { 
    this.symptomReportService.getDetailsSurveySymptoms(data.Id).subscribe((rest: DetailsSurveySymptoms) => {
      this.detailsSurveySymptoms = rest;
    });
  }

  ngOnInit(): void {
  }

}
