import {Component, Input, NgModule, OnInit, ViewChild} from '@angular/core';
import {environment} from 'src/environments/environment';

import {
  Back,
  ChangeTransferEMployerDocument,
  City,
  ConfigCard,
  DaneCity,
  EmployerChangeDocument,
  FirePersonal,
  ModelDocumentsShow,
  ModelSaveCarnetPrintResponse,
  PersonalBlackList,
  Poll,
  SecurityReference
} from 'src/app/core/interfaces/interfaces';
import {CommonOptions} from '../../../interfaces/interfaces';
import {CommonService} from '../../../services/modules/common.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NumberCountry} from '../../../constants/number-city.constant';
import {FechasService} from '../../../services/utils/fechas.service';
import {PersonalService} from '../../../services/modules/personal.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PersonalContractProyect, ProjectContracts} from '../../../models/Personal/PersonalContractProyect';
import {InfoPersonalCompanyPersonalId} from '../../../models/Personal/InfoPersonalCompanyPersonalId';
import {SnackBarService} from '../../../services/utils/snackBar.service';
import {DialogService} from '../../../services/utils/dialog.service';
import {
  Certification,
  ContractsOffline,
  Entry,
  putEmployerPersonalRequirement,
  ReplaceEPSorAFP,
  SocialSecurity,
  Surveys
} from 'src/app/core/models/Personal/ContractsOffline';
import {EmployerService} from 'src/app/core/services/modules/employer.service';
import {TypeSecurityReference} from 'src/app/core/constants/type-security-references';
import {ContratingService} from 'src/app/core/services/modules/contrating.service';
import {PersonalInfoContract} from 'src/app/core/models/Personal/personalInfoContract';
import {CommonMetodService} from 'src/app/core/services/utils/commonMetod.service';
import {User} from 'src/app/core/models/User';
import {AuthService} from 'src/app/core/services/modules/auth.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ContractService} from 'src/app/core/services/modules/contract.service';
import {CurrencyPipe} from '@angular/common';
import {Positions} from 'src/app/core/models/IndividualContract/interfacesIndividualContract';
import {RequestService} from '../../../services/modules/request.service';
import {DialogDataConfirm} from 'src/app/modules/employer/components/planilla/planilla.component';
import {ProjectService} from 'src/app/core/services/modules/project.service';
import {TerminationService} from 'src/app/core/services/modules/termination-service';
import {CompaniesService} from 'src/app/core/services/modules/companies.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cards-personal',
  templateUrl: './cards-personal.component.html',
  styleUrls: ['./cards-personal.component.scss']
})
export class CardsPersonalComponent implements OnInit {
  production = environment;

  @ViewChild('pickerSocialAFP') pickerSocialAFP: any;

  s3Bucket: string;
  personalCompanyInfoId: string;

  userAuth: User;
  flagListFInfo: any;
  configCard: ConfigCard;
  formVaccine: FormGroup;
  formPersonal: FormGroup;
  formVigencia: FormGroup;
  formEmpleador: FormGroup;
  formPersonalCV: FormGroup;
  formOtrosDatos: FormGroup;
  formDatosContrato: FormGroup;
  formVigenciaArlExigida: FormGroup;
  contractSelected: ContractsOffline;
  personalInfo: PersonalInfoContract;
  exitPersonalEmployerByPersonalPOSTPUT: boolean;
  personal: InfoPersonalCompanyPersonalId;
  personalContractSelected: ContractsOffline;
  employerChangeDocument: EmployerChangeDocument;
  personalContractProyectSelected: PersonalContractProyect;
  infoPersonalCompanyPersonalId: InfoPersonalCompanyPersonalId;

  maxDate: any;
  restPersonalCV: any;
  contractLaborSelected: any;
  formDataStudyCertificate: any;
  formDataExperienceCertificate: any;
  urlDataStudyCertificate: any = null;
  urlDataExperienceCertificate: any = null;

  controEps = new FormControl();
  controAfp = new FormControl();
  controHistory = new FormControl();
  controOccupationalRisk = new FormControl();
  controlExpeditionDate = new FormControl();
  controlExpeditionPlace = new FormControl();

  history: [] = [];
  entry: Entry[] = [];
  surveys: Surveys[] = [];
  rh: CommonOptions[] = [];
  daneCity: DaneCity[] = [];
  sex: CommonOptions[] = [];
  states: CommonOptions[] = [];
  afp: SecurityReference[] = [];
  eps: SecurityReference[] = [];
  citiesNacimiento: City[] = [];
  citiesExpedicion: City[] = [];
  countrys: CommonOptions[] = [];
  dateLabel: CommonOptions[] = [];
  lastStages: CommonOptions[] = [];
  nationality: CommonOptions[] = [];
  vaccineType: CommonOptions[] = [];
  contracts: ContractsOffline[] = [];
  filesStudyCertificate: File[] = [];
  causalesData: CommonOptions[] = [];
  maritalStatus: CommonOptions[] = [];
  socialStratum: CommonOptions[] = [];
  certification: Certification[] = [];
  socialSecurity: SocialSecurity[] = [];
  educationalLevel: CommonOptions[] = [];
  occupationalRisk: CommonOptions[] = [];
  filesExperienceCertificate: File[] = [];
  vaccinationStatus: CommonOptions[] = [];
  companyDocumentType: CommonOptions[] = [];
  contractsOffline: ContractsOffline[] = [];
  replaceEPSorAFPdata: ReplaceEPSorAFP[] = [];
  individualContractsType: CommonOptions[] = [];
  positionsIndividualContract: Positions[] = [];
  personalContractProyect: PersonalContractProyect[] = [];
  dateSocialSecurityUpload: putEmployerPersonalRequirement[] = [];
  docsUploadIniadoSocialSecurity: putEmployerPersonalRequirement[] = [];

  jobs: any = [];
  town: any[] = [];
  projects: any[] = [];
  workShift: any[] = [];
  allEmployers: any[] = [];
  contractsLabor: any[] = [];
  permissions: string[] = [];
  securityReferences: any = [];
  personalContractPosition: any[] = [];

  positionSujester: string[] = [];
  permissionsCompany: string[] = [];


  HasExtraTimeList: any[] = [
    {value: true, name: 'SI'},
    {value: false, name: 'NO'},
  ];

  indexSelectContracLabor = 0;
  min = 0;
  max = 0;
  indexSelect = 0;

  isActive: boolean;
  hasDocuments: boolean;
  flagListF = true;
  edadRange = false;
  IsValidedARL = false;
  changeEmployerId = false;
  isCreateAppPersonal = false;
  exitContractsOffline = false;
  flagChangeContratista = false;
  disabledByPermissionUpdate = false;

  email = '';
  employerName = '';
  personalStatus = '';
  biometricMaxMin = '';
  companyIdUserLogin = '';

  // tslint:disable-next-line:variable-name
  btn_menuHistory = false;
  listMenuHistory = [
    { name: 'VINCULADOS A LOS CONTRATOS Y PROYECTOS', id: 1}
  ];

  // Variables para primer paso de change in formularios
  flagChangeDataBtnSave = false;
  changeAfterfillFormPersonal = true;
  changeAfterfillFormVaccine = false;
  changeAfterfillFormVigencia = false;
  changeAfterfillFormPersonalCV = false;
  changeAfterfillFormOtrosDatos = false;
  changeAfterfillFormVigenciaArlExigida = false;
  changeAfterfillFormContractIndividual = false;

  @Input() set eventButon(value: any) {
    if (value !== undefined) {
      this.eventSave();
    }
  }

  date = new Date();
  minDateSocialSecurity = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
  maxDateSocialSecurity = new Date(this.date.getFullYear(), this.date.getMonth() , this.date.getDate() + 60);

  minDateVigenciaContract = this.fechasService.getDateNow();

  personalId: number;

  positions: any = [];

  bancks: Back[] = [];
  bankAccountType: CommonOptions[] = [];

  arrayDOSIS = [{value: 1, name: 'PRIMERA'},
    {value: 2, name: 'SEGUNDA'},
    {value: 3, name: 'TERCERA'}
  ];

  @Input() set _configCard(value: ConfigCard) {
    this.configCard = value;
    if (value.typeCard === 'information') {
      this.getIndividualContractsType();
      this.personalId = value.Id;
      this.getPersonalContracts();
      this.getServicesPersonalCV();

      this.getContractPersonal();
      this.getServices();
      this.getVaccine(value.Id);
      this.servicesPersonal();

      this.controHistory.setValue(1);
      this.commonService.getHistory(this.personalId).subscribe(rest => {
        this.history = rest.sort((a, b) => a.UpdateDate < b.UpdateDate ? 1 : -1);
      });
    }
  }

  @Input() set _permissions(value: string[]) {
    if (value.length > 0) {
      this.permissions = value;
    }
  }

  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    public fechasService: FechasService,
    private personalService: PersonalService,
    private ar: ActivatedRoute,
    private snackBService: SnackBarService,
    private dialogService: DialogService,
    private employerService: EmployerService,
    private commonServices: CommonService,
    private contratingService: ContratingService,
    public commonMetodService: CommonMetodService,
    private authService: AuthService,
    private contractService: ContractService,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private requestService: RequestService,
    private projectService: ProjectService,
    private terminationService: TerminationService,
    private companiesService: CompaniesService,
  ) {
    this.s3Bucket = environment.api.s3Bucket;
    this.ar.params.subscribe((parametros) => {
      this.personalCompanyInfoId = parametros.Id;
    });

    this.userAuth = this.authService.getUser();
    this.email = this.userAuth?.Email !== null && this.userAuth?.Email !== undefined ? this.userAuth?.Email : '';
    const capital = this.userAuth?.Companies.find(x => x.Id === 'f3dfc3f6-7da3-423b-bb55-5631f6517d31' || x.Id === 'f783fe09-bf7a-497b-a3f2-ce4e20969d2e');
    // tslint:disable-next-line:no-unused-expression
    capital !== undefined ? this.companyIdUserLogin = capital.Id : '';
    this.formPersonal = this.fb.group({
      Nationality: new FormControl('', Validators.required),
      DocumentNumber: new FormControl(''),
      DocumentType: new FormControl(''),
      Name: new FormControl('', Validators.required),
      LastName: new FormControl('', Validators.required),
      BirthDate: new FormControl('', [Validators.required, Validators.minLength(8)]),
      Sex: new FormControl('', Validators.required),
      RH: new FormControl('', Validators.required),
      contryNalcionality: new FormControl('', Validators.required),
      CityCode: '',
      CityOfBirthCode: new FormControl('', Validators.required),
      EmergencyContact: new FormControl(''),
      EmergencyContactPhone: new FormControl(''),
      RelationshipWithContact: new FormControl(''),
      Address: '',
      PhoneNumber: '',
      TownId: '',
      Email: new FormControl('', [Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      Photo: '',
      EpsId: '',
      AfpId: '',
      // Datos de hoja de vida
      JobCode: ''
    });
    this.formVigencia = this.fb.group({
      StartDateContract: '',
      FinishDateContract: '',
      Position: '',
      EmployerName: '',
      EmployerItemId: '',
      EmployerId: '',
      EmployerLogo: '',
      ContractReview: '',
      DescriptionPersonalType: '',
      EmployerDocumentType: '',
      EmployerDocumentNumber: '',
      CompanyInfoParentId: '',
      IsActive: '',
      HasDocuments: '',
      // Seach Cambiar empleador
      SearchEmpleador: ''
    });
    this.formVigenciaArlExigida = this.fb.group({
      TypeARL: ''
    });
    this.formEmpleador = this.fb.group({
      employer: new FormControl({ value: null, disabled: true })
    });
    this.formPersonalCV = this.fb.group({
      PersonalCompanyInfoId: '',
      EducationalLevelId: '',
      ExperienceYears: 0,
      ExperienceMonths: 0,
      JobCode: '',

      MaritalStatusId: '',
      NumberOfChilds: 0,
      NumberOfDependants: 0,
      SocialStratumId: '',
      Medicines: ''
    });
    this.formVaccine = this.fb.group({
      VaccineStatusId: 0,
      PersonalCompanyInfoId: 0,
      VaccineTypeId: 0,
      AppliedDose: 0,
      VaccineObservations: ''
    });

    this.formDatosContrato = this.fb.group({
      IndividualContractTypeId: new FormControl(''),
      PersonalEmployerInfoId: new FormControl(''),
      // PositionId: new FormControl(''),
      PositionName: new FormControl(''),
      Salary:  ['', Validators.min(1)],
      AuxiliaryTransport:  ['', Validators.min(1)],
      TotalEarnings: ['', Validators.min(1)],
      PayPeriodId: new FormControl(''),
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      contry: new FormControl('0'),
      ProjectId: new FormControl(''),
      ContractCityId: new FormControl(''),
      ContractId: '',
      examType: '',
      EmployerInfoId: '',
      EmployerName: '',
      EmployerDocumentType: '',
      EmployerDocumentNumber: '',
      EmployerLogo: '',
      // Datos bancarios
      BankAccount: '',
      BankId: '',
      BankAccountTypeId: '',
      ContractNumber: '',
      ContractTypeId: '',
      // Datos Prenomina
      WorkShiftId: '',
      HasExtraTime: ''
    });
    this.formOtrosDatos = this.fb.group({
      BankAccount: new FormControl('', Validators.required),
      BankId: new FormControl('', Validators.required),
      BankAccountTypeId: new FormControl('', Validators.required)
    });
    this.formDatosContrato.valueChanges.subscribe(form => {
      if (form.Salary && this.commonMetodService._validarDateMoney(form.Salary)
        ) {
        this.formDatosContrato.patchValue({
          Salary: this.currencyPipe.transform(form.Salary.toString().replace(/\D/g, '').replace(/^0+/, ''),
            'USD', 'symbol', '1.0-0').replace(/,/gi, '.')
        }, {emitEvent: false});
      }
      if (form.AuxiliaryTransport && this.commonMetodService._validarDateMoney(form.AuxiliaryTransport)
      ) {
        this.formDatosContrato.patchValue({
          AuxiliaryTransport: this.currencyPipe.transform(form.AuxiliaryTransport.toString().replace(/\D/g, '').replace(/^0+/, ''),
            'USD', 'symbol', '1.0-0').replace(/,/gi, '.')
        }, {emitEvent: false});
      }
      if (form.TotalEarnings && this.commonMetodService._validarDateMoney(form.TotalEarnings)
      ) {
        this.formDatosContrato.patchValue({
          TotalEarnings: this.currencyPipe.transform(form.TotalEarnings.toString().replace(/\D/g, '').replace(/^0+/, ''),
            'USD', 'symbol', '1.0-0').replace(/,/gi, '.')
        }, {emitEvent: false});
      }

    });


    // Cambios en Formulario Activar boton  SAVE
    this.formPersonal.valueChanges.subscribe(value => {
      if (this.changeAfterfillFormPersonal === false) {
        if (this.exitPermissionCompany('personals.update')) {
          this.flagChangeDataBtnSave = true;
        }
      }
    });
    this.formVigenciaArlExigida.valueChanges.subscribe(value => {
      if (this.changeAfterfillFormVigenciaArlExigida === true) {
        if (this.exitPermissionCompany('personals.update')) {
          this.flagChangeDataBtnSave = true;
        }
      }
    });
    this.formVigencia.valueChanges.subscribe(value => {
      if (this.changeAfterfillFormVigencia === true) {
        if (this.exitPermissionCompany('personals.update')) {
          this.flagChangeDataBtnSave = true;
        }
      }
    });
    this.formPersonalCV.valueChanges.subscribe(value => {
      if (this.changeAfterfillFormPersonalCV === true) {
        if (this.exitPermissionCompany('personals.update')) {
          this.flagChangeDataBtnSave = true;
        }
      }
    });
    this.formVaccine.valueChanges.subscribe(value => {
      if (this.changeAfterfillFormVaccine === true) {
        if (this.exitPermissionCompany('personals.update')) {
          this.flagChangeDataBtnSave = true;
        }
      }
    });
    this.formDatosContrato.valueChanges.subscribe(value => {
      if (this.changeAfterfillFormContractIndividual === true) {
        if (this.exitPermissionCompany('personals.update')) {
          this.flagChangeDataBtnSave = true;
        }
      }
    });
    this.formOtrosDatos.valueChanges.subscribe(value => {
      if (this.changeAfterfillFormOtrosDatos === true) {
        if (this.exitPermissionCompany('personals.update')) {
          this.flagChangeDataBtnSave = true;
        }
      }
    });
  }

  ngOnInit(): void { }

  getHistory() {
    this.btn_menuHistory = true;
    this.commonService.getHistory(this.personalId).subscribe(rest => {
      this.history = rest.sort((a, b) => a.UpdateDate < b.UpdateDate ? 1 : -1);
    });
  }

  validateListF(documentType, documentNumber, CompanyInfoId) {
    this.contratingService.getPersonalBlackListInfo(documentType, documentNumber, CompanyInfoId).subscribe((rest: any) => {
      this.flagListFInfo = rest;
      // tslint:disable-next-line:no-non-null-assertion
      rest!.BlackList === true ? this.flagListF = false : this.flagListF = true;
      this.disabledFormularios();
    });
  }

  getPersonalContracts() {
    this.personalService.getPersonalContracts(this.personalCompanyInfoId).subscribe((rest: any) => {
      if (rest !== undefined && rest !== null){
        this.personalContractPosition = rest;
        this.getSelectedContractsPosition(false);
      }
    });
  }

  getSelectedContractsPosition(changeContract) {
      if (changeContract === true) {
        this.positionSujester = [];
      }
      this.personalContractPosition.forEach(x => {
        if (x.EmployerId === this.personalContractSelected?.EmployerId) {
          if (this.positionSujester.length === 0) {
            this.positionSujester.push(x.Position);
          }
          if (this.positionSujester.length > 0 && this.exitPositionSujester(x.Position) === false) {
            this.positionSujester.push(x.Position);
          }
        }
      });
  }

  exitPositionSujester(Position): boolean {
    let exit: boolean;
    const data = this.positionSujester.find(x => x === Position);
    data !== undefined ? exit = true : exit = false;
    return exit;
  }

  getServicesPersonalCV() {
    this.commonService.getCommonOptions('EducationalLevel').subscribe((data: CommonOptions[]) => {
      this.educationalLevel = data.sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.commonService.getCommonOptions('MaritalStatus').subscribe((data: CommonOptions[]) => {
      this.maritalStatus = data.sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.commonService.getCommonOptions('SocialStratum').subscribe((data: CommonOptions[]) => {
      this.socialStratum = data;
    });
    this.commonService.getJobs().subscribe(jobs => {
      this.jobs = jobs;
    });
    this.getPersonalCV();
  }

  getPersonalCV() {
    this.personalService.getPersonalCV(this.personalId).subscribe((restPersonalCV: any) => {
      this.formPersonalCV.get('PersonalCompanyInfoId').setValue(restPersonalCV.PersonalCompanyInfoId);
      this.formPersonalCV.get('EducationalLevelId').setValue(
        restPersonalCV.EducationalLevelId !== null ? restPersonalCV.EducationalLevelId : this.getEducationlLevendesconocido());
      this.formPersonalCV.get('ExperienceYears').setValue(restPersonalCV.ExperienceYears !== null ? restPersonalCV.ExperienceYears : 0 );
      this.formPersonalCV.get('ExperienceMonths').setValue(restPersonalCV.ExperienceMonths !== null ? restPersonalCV.ExperienceMonths : 0);
      this.urlDataStudyCertificate = restPersonalCV.StudyCertificate;
      this.urlDataExperienceCertificate = restPersonalCV.ExperienceCertificate;
      this.restPersonalCV = restPersonalCV;
      setTimeout(() => {
        this.changeAfterfillFormPersonalCV = true;
      }, 4000);
  }, (error) => {
    setTimeout(() => {
      this.changeAfterfillFormPersonalCV = true;
    }, 4000);
  });
  }

  getRefrestHistory() {
    this.controHistory.setValue(1);
    this.commonService.getHistory(this.personalId).subscribe(rest => {
      this.history = rest.sort((a, b) => a.UpdateDate < b.UpdateDate ? 1 : -1);
    });
  }

  getEducationlLevendesconocido(): number {
    let id: number;
    const data = this.educationalLevel.find(x => x.Description === 'DESCONOCIDO');
    data !== undefined ? id = data.Id : id = null;
    return id;
  }

  getMaritalStatusdesconocido(): number {
    let id: number;
    const data = this.maritalStatus.find(x => x.Description === 'DESCONOCIDO');
    data !== undefined ? id = data.Id : id = null;
    return id;
  }

  getSocialStratumdesconocido(): number {
    let id: number;
    const data = this.socialStratum.find(x => x.Description === 'Desconocido');
    data !== undefined ? id = data.Id : id = null;
    return id;
  }
  openDocumentsPersonalCV(type: string) {
    if (type === 'StudyCertificate') {
      this.dialogService.openDialogChangeOneFile( 'Complete',
        this.urlDataStudyCertificate !== undefined && this.urlDataStudyCertificate !== '' ? this.urlDataStudyCertificate : '',
        (this.flagListF === false || !this.exitPermissionCompany('personalstudiescert.view') ? 'show' : 'showDelete'),

        // Esta parte es para el segundo caso si se cargar u narchivo cierra y vuelve estan los Files guardados
        this.filesStudyCertificate.length > 0 ? this.filesStudyCertificate : []
        ).afterClosed().subscribe(restDialog => {
          if (restDialog !== undefined) {
            this.filesStudyCertificate = [];
            this.formDataStudyCertificate = undefined;
            this.formDataStudyCertificate = restDialog.FormData;
            this.filesStudyCertificate = restDialog.File;
          }
      });
    } else if (type === 'ExperienceCertificate') {
      this.dialogService.openDialogChangeOneFile('Complete', this.urlDataExperienceCertificate !== undefined
        && this.urlDataExperienceCertificate !== '' ? this.urlDataExperienceCertificate : '',
        (this.flagListF === false || !this.exitPermissionCompany('personalespiriencecert.view') ? 'show' : 'showDelete'),
        this.filesExperienceCertificate.length > 0 ? this.filesExperienceCertificate : []
        ).afterClosed().subscribe(restDialog => {
          if (restDialog !== undefined) {
            this.formDataExperienceCertificate = undefined;
            this.filesExperienceCertificate = [];
            this.formDataExperienceCertificate = restDialog.FormData;
            this.filesExperienceCertificate = restDialog.File;
          }
      });
    }

  }

  servicesPersonal() {
    this.commonService.getCommonOptions('Nationality').subscribe((data: CommonOptions[]) => {
      this.nationality = data;
    });
    this.commonService.getCommonOptions('RH').subscribe((data: CommonOptions[]) => {
      this.rh = data.sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.commonService.getCommonOptions('CompanyDocumentType').subscribe((data: CommonOptions[]) => {
      const nationality = this.formPersonal.get('Nationality').value;
      if (nationality === '0') {

        this.companyDocumentType = data
          .filter(x => x.Value === 'CC')
          .sort((a, b) => (a.OrderNum != null ? a.OrderNum : Infinity) - (b.OrderNum != null ? b.OrderNum : Infinity));
      } else {
        this.companyDocumentType = data
          .filter(x => x.Value === 'PE' || x.Value === 'CE' || x.Value === 'PPT')
          .sort((a, b) => (a.OrderNum != null ? a.OrderNum : Infinity) - (b.OrderNum != null ? b.OrderNum : Infinity));
      }
    });

    this.commonService.getCommonOptions('Sex').subscribe((data: CommonOptions[]) => {
      this.sex = data.sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.commonService.getCommonOptions('Country').subscribe((data: CommonOptions[]) => {
      this.countrys = data;
    });
    this.commonService.getCommonOptions('VaccineType').subscribe((data: CommonOptions[]) => {
      this.vaccineType = data;
    });
    this.commonService.getCommonOptions('VaccinationStatus').subscribe((data: CommonOptions[]) => {
      this.vaccinationStatus = data;
    });
    this.employerService.getSecurityReferences().subscribe(data => {
      this.securityReferences = data;
      this.eps = this.securityReferences.filter(x => x.Type === TypeSecurityReference.EPS).sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
      this.afp = this.securityReferences.filter(x => x.Type === TypeSecurityReference.AFP).sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    // esta api solo se llama para llenar lugar de expedicion solo para colombia
    this.commonService.getCities(0).subscribe((data: City[]) => {
      this.citiesExpedicion = data;
    });
    this.getDaneCity();
  }


  getDaneCity() {
    this.commonService.getDaneCity().subscribe((daneCity: DaneCity[]) => {
      this.daneCity = daneCity;
    });
  }

  getCitiesNacimiento(numberContry: NumberCountry, change?){
    if (numberContry !== '' && numberContry !== undefined && numberContry !== null) {
      this.commonService.getCities(numberContry).subscribe((data: City[]) => {
        this.citiesNacimiento = data;
        // Si existe un cambio en la ciudad de nacimiento tiene que seleccionar ciudad desconocida
        if (change !== undefined) {
          const cityDesconocido = this.citiesNacimiento.find(x => x.Name === 'DESCONOCIDO');
          // tslint:disable-next-line:no-unused-expression
          cityDesconocido !== undefined ? this.formPersonal.get('CityOfBirthCode').setValue(cityDesconocido.Code) : '';
        }
      });
    }
  }

  getVaccine(Id) {
    this.personalService.getVaccine(Id).subscribe((rest: any) => {
      // this.vaccineType = rest;
      if ( rest.VaccineStatusId === '' || rest.VaccineStatusId === null || rest.VaccineStatusId === 0) {
        this.formVaccine.get('VaccineStatusId').setValue(this.getIdStatus('No Informa'));
      } else {
        this.formVaccine.get('VaccineStatusId').setValue(rest.VaccineStatusId);
      }
      this.formVaccine.get('VaccineTypeId').setValue(rest.VaccineTypeId);
      this.formVaccine.get('AppliedDose').setValue(rest.AppliedDose);
      this.formVaccine.get('VaccineObservations').setValue(rest.VaccineObservations);

      this.personalStatus = rest?.VaccineStatus?.Description;
      setTimeout(() => {
        this.changeAfterfillFormVaccine = true;
      }, 3000);
    });
  }

  getIdStatus(desciption): number {
    let id: number;
    const status = this.vaccinationStatus.find(x => x.Description === desciption);
    status !== undefined ? id = status.Id : id = 0;
    return id;
  }

  getDescriptionStatus(id): string {
    let description: string;
    const status = this.vaccinationStatus.find(x => x.Id === id);
    status !== undefined ? description = status.Description : description = '';
    return description;
  }


  getTown(CodeCity) {
    if (CodeCity !== '' && CodeCity !== null && CodeCity !== undefined) {
      this.contratingService.getTownIdCity(CodeCity).subscribe((data: any) => {
        this.town = data;
      });
    }
  }

  getCalculateStateDateFromIcon(date): string {
    let response: string;
    response = this.fechasService.calculateStateDateFromIcon(date);
    return response;
  }

  fillFormPersonal() {
      // Person
    this.formPersonal.get('Name').setValue(this.personal.Name);
    this.formPersonal.get('LastName').setValue(this.personal.LastName);
    this.formPersonal.get('Nationality').setValue( this.personal.Nationality.toString());
    this.formPersonal.get('DocumentType').setValue(this.personal.DocumentType);
    this.formPersonal.get('DocumentNumber').setValue(this.personal.DocumentNumber);
    this.personal.DocumentRaw === null || this.personal.DocumentRaw === 'null' ?
      this.isCreateAppPersonal = false : this.isCreateAppPersonal = true;
    // this.getCitiesNacimiento(1);
    this.validateListF(this.personal.DocumentType, this.personal.DocumentNumber, this.personal.CompanyInfoId);
    this.formPersonal.get('CityOfBirthCode').setValue(this.personal.CityOfBirthCode !== null ? this.personal.CityOfBirthCode : '');
    // tslint:disable-next-line:no-unused-expression
    this.personal.CityOfBirthCode !== null ? this.getCitiesNacimiento(this.personal.CityOfBirthCode.substring(0, 1) ) : '';
    this.formPersonal.get('contryNalcionality').setValue(this.personal.CityOfBirthCode !== null ?
      this.personal.CityOfBirthCode.substring(0, 1) : '');
    this.formPersonal.get('RH').setValue(this.personal.RH);
    this.formPersonal.get('Sex').setValue(this.personal.Sex);
    this.formPersonal.get('BirthDate').setValue(this.fechasService.formatBirthDate(this.personal.BirthDate));
    // PersonalEmployerInfo
    this.formPersonal.get('CityCode').setValue(this.personal.CityCode);
    this.getTown(this.personal.CityCode);
    this.formPersonal.get('TownId').setValue(this.personal.TownId);
    this.formPersonal.get('Address').setValue(this.personal.Address);
    this.formPersonal.get('PhoneNumber').setValue(this.personal.PhoneNumber);
    this.formPersonal.get('EmergencyContact').setValue(this.personal.EmergencyContact);
    this.formPersonal.get('EmergencyContactPhone').setValue(this.personal.EmergencyContactPhone);
    this.formPersonal.get('RelationshipWithContact').setValue(this.personal.RelationshipWithContact);
    this.formPersonal.get('Photo').setValue(this.personal.Photo);
    this.formPersonal.get('Email').setValue(this.personal.Email?.toLowerCase());
    this.formPersonal.get('AfpId').setValue(this.personal.AfpId);
    this.formPersonal.get('EpsId').setValue(this.personal.EpsId);

    // datos expedicion document
    this.controlExpeditionDate.setValue(this.personal.ExpeditionDate);
    this.controlExpeditionPlace.setValue(this.personal.ExpeditionPlaceCode);
    this.formPersonalCV.get('JobCode').setValue(this.personal.JobCode);
    this.formPersonalCV.get('MaritalStatusId').setValue(this.personal.MaritalStatusId !== null ?
      this.personal.MaritalStatusId : this.getMaritalStatusdesconocido());
    this.formPersonalCV.get('NumberOfChilds').setValue(this.personal.NumberOfChilds !== null ? this.personal.NumberOfChilds : 0);
    this.formPersonalCV.get('NumberOfDependants').setValue(this.personal.NumberOfDependants !== null ?
      this.personal.NumberOfDependants : 0);
    this.formPersonalCV.get('SocialStratumId').setValue(this.personal.SocialStratumId !== null ?
      this.personal.SocialStratumId : this.getSocialStratumdesconocido());
    this.formPersonalCV.get('Medicines').setValue(this.personal.Medicines);
    // tslint:disable-next-line:radix
    this.formPersonal.get('TownId').setValue(parseInt(this.personal.TownId));

    this.getEmployerAll();
    // Cambia variable al primer llenado del formulario de Personal
    setTimeout(() => {
      this.changeAfterfillFormPersonal = false;
    }, 1000);

  }


  getEmployerAll() {
    this.commonService.getAllEmployersCompanyId(this.personal.CompanyInfoId).subscribe((data: any) => {
      this.allEmployers = data.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
    });
  }

  onSelectedAllEmployer(id) {
    this.formVigencia.get('EmployerId').setValue(id);
    // Change EMployer para preguntar si copiar documentos del antirior empleador
    this.changeEmployerId = true;
    const employerNew = this.allEmployers.find(x => x.Id === id);
    this.formVigencia.get('EmployerName').setValue(employerNew.Name);
    this.formVigencia.get('EmployerId').setValue(employerNew.Id);
  }

  eventSave() {
    if ((this.companyIdUserLogin === 'f3dfc3f6-7da3-423b-bb55-5631f6517d31' || this.companyIdUserLogin === 'f783fe09-bf7a-497b-a3f2-ce4e20969d2e') && this.exitPersonalEmployerByPersonalPOSTPUT === undefined) {
      this.snackBService.openSnackBar('Por favor crear contrato laboral para continuar', '', 5000);
      this.postIndividualContractLabor();
    } else {
      if (this.formPersonal.status === 'VALID') {
        this.putPersonalFull();
        this.putIndividualContractLabor();
        this.postVaccice();

        const lastDateFinish = new Date(this.personalInfo.FinishDate);
        const dateFormFinish = new Date(this.formVigencia.get('FinishDateContract').value);
        lastDateFinish.setSeconds(0, 0);
        dateFormFinish.setSeconds(0, 0);

        const isDifferentFinish = lastDateFinish.getTime() !== dateFormFinish.getTime();
        if (isDifferentFinish){
          // Is contract move translado
          this.contractService.getIsContractPersonalCompanyInfoId(
            this.contractSelected.ContractId, this.personalId).subscribe(Iscontract => {
            this.flowChangeState();
          }, (error: HttpErrorResponse) => {
            error.status === 400 ?
              this.dialogService.openDialogMovePersonalInfo(true, error, 'Desea trasladarlo?',
                false).afterClosed().subscribe(restDialog => {
                if (restDialog === true) {
                  const pesonalCompany = {
                    AfpId: this.formPersonal.get('AfpId').value,
                    ContractId: this.contractSelected.ContractId,
                    EmployerId: null,
                    EpsId: this.formPersonal.get('EpsId').value,
                    PersonalCompanyInfoId: this.personalId,
                    Position: this.formVigencia.get('Position').value,
                    TypeARL: this.formVigenciaArlExigida.get('TypeARL').value,
                    StartDate:  this.formVigencia.get('StartDateContract').value,
                    FinishDate: this.formVigencia.get('FinishDateContract').value,
                  };
                  this.contractService.postMovePersonalInfoContract(this.contractSelected.ContractId,
                    pesonalCompany).subscribe((restMOvePersonal: any) => {
                    this.flowChangeState();
                  });
                } else {
                  this.flowChangeState();
                }
              })
              // tslint:disable-next-line:no-unused-expression
              : '';
          });
        }else{
          this.flowChangeState();
        }
      } else {
        this.snackBService.openSnackBar('Complete la información requerida del personal', '', 3000);
        this.formPersonal.markAllAsTouched();
      }
    }
    // this.getContractPersonal();
  }

  // Contratos VINCULADOS A LOS CONTRATOS Y PROYECTOS
  changeinputPositionContract(value) {
    this.formVigencia.get('Position').setValue(value);
  }

  changeDateFinishDateContractTime(date, isFinishDate) {
    if (date !== undefined && date !== '' && date !== null){
      if (isFinishDate === true) {
        this.formVigencia.get('FinishDateContract').setValue(date);
      } else {
        this.formVigencia.get('StartDateContract').setValue(date);
      }
    }
  }

  // contratos CONTRATOS LABORALES
    changeDateFinishDateContractLabor(date, isFinishDate, index) {
    if (isFinishDate === false) {
      const ordate = this.fechasService.getDateFormatDatePiker(this.fechasService.formatDateYYYYMMDDdatePiker(date));
      this.contractsLabor[index].StartDate = ordate;
      this.formDatosContrato.get('StartDate').setValue(ordate);
    } else {
      const ordate = this.fechasService.getDateFormatDatePiker(this.fechasService.formatDateYYYYMMDDdatePiker(date));
      this.contractsLabor[index].EndDate = ordate;
      this.formDatosContrato.get('EndDate').setValue(ordate);
    }
  }

  changeinputDurationContractLabor(value, index, i: number) {
    this.contractsLabor[index].Duration = value;
    this.formDatosContrato.get('Duration').setValue(value);
  }

  changeinputContractLaborContractNumber(value, index) {
    this.contractsLabor[index].ContractNumber = value;
    this.formDatosContrato.get('ContractNumber').setValue(value);
  }

  changeinputContractLaborPositionName(value, index) {
    this.contractsLabor[index].PositionName = value;
    this.formDatosContrato.get('PositionName').setValue(value);
  }

  onSelectedEmployerInfoId(value, index) {
    this.contractsLabor[index].EmployerInfoId = value;
    this.formDatosContrato.get('EmployerInfoId').setValue(value);
  }

  onSelectedContractTypeId(value, index) {
    this.contractsLabor[index].ContractTypeId = value;
    this.formDatosContrato.get('ContractTypeId').setValue(value);
  }

  onSelectedBankId(value, index) {
    this.contractsLabor[index].BankId = value;
    this.formDatosContrato.get('BankId').setValue(value);
  }

  onSelectedBankAccountTypeId(value, index) {
    this.contractsLabor[index].BankAccountTypeId = value;
    this.formDatosContrato.get('BankAccountTypeId').setValue(value);
  }

  onSelectedProjectId(value, index) {
    this.getPersonalWorkShiftrprojectId(value);
    this.contractsLabor[index].ProjectId = value;
    this.formDatosContrato.get('ProjectId').setValue(value);
  }

  onSelectedWorkShift(value, index) {
    this.contractsLabor[index].WorkShiftId = value;
    this.formDatosContrato.get('WorkShiftId').setValue(value);
  }

  onSelectedHasExtraTime(value, index) {
    this.contractsLabor[index].HasExtraTime = value;
    this.formDatosContrato.get('HasExtraTime').setValue(value);
  }

  getPersonalWorkShiftrprojectId(ProjectId) {
    this.personalService.getPersonalWorkShiftrprojectId(ProjectId).subscribe((rest: any) => {
      this.workShift = rest;
    });
  }

  getProjects() {
    this.projectService.getProjects().subscribe((rest: any) => {
      this.projects = rest;
    });
  }

  changeinputContractLaborSalary(value, index) {
    let setValue = '0';
    if (value !== '$0' && value !== '0' && value !== '' && value !== 0 && value !== null && value !== undefined){
      this.contractsLabor[index].Salary = this.currencyPipe.transform(value.toString().replace(/\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '1.0-0').replace(/,/gi, '.');
      setValue = this.currencyPipe.transform(value.toString().replace(/\D/g, '').replace(/^0+/, ''),
        'USD', 'symbol', '1.0-0').replace(/,/gi, '.');
    }
    this.formDatosContrato.get('Salary').setValue(setValue);
  }

  changeinputAuxiliaryTransport(value, index) {
    let setValue = '0';
    if (value !== '$0' && value !== '0' && value !== '' && value !== 0 && value !== null && value !== undefined){
      this.contractsLabor[index].TransportationAllowance = this.currencyPipe.transform(value.toString().replace(
        /\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '1.0-0').replace(/,/gi, '.');
      setValue = this.currencyPipe.transform(value.toString().replace(/\D/g, '').replace(/^0+/, ''),
        'USD', 'symbol', '1.0-0').replace(/,/gi, '.');
    }
    this.formDatosContrato.get('AuxiliaryTransport').setValue(setValue);
  }

  changeinputTotalEarnings(value: string, index: number) {
    let setValue = '0';
    if (value && value !== '$0' && value !== '0' && value !== '') {
      const numericValue = value.toString().replace(/\D/g, '');
      const formattedValue = this.currencyPipe.transform(numericValue, 'COP', 'symbol', '1.0-0').replace(/,/g, '.');

      this.contractsLabor[index].TotalEarnings = formattedValue;
      setValue = formattedValue;
    }

    this.formDatosContrato.get('TotalEarnings').setValue(setValue);
  }

  formatCurrency(value: number): string {
    if (value === null || value === undefined) {
      return '';
    }
    return this.currencyPipe.transform(value, 'COP', '$', '1.0-0').replace(/,/g, '.');
  }

  changeinputContractLaborBankAccount(value, index) {
    this.contractsLabor[index].BankAccount = value;
    this.formDatosContrato.get('BankAccount').setValue(value);
  }

  flowChangeState() {
    this.personalService.getContractPersonalInfoId(this.contractSelected.ContractId, this.personalId).subscribe(
      (personalInfoContract: PersonalInfoContract) => {
      const model = {
        ...this.personalInfo,
        EpsId: this.formPersonal.get('EpsId').value,
        StartDate:  this.formVigencia.get('StartDateContract').value,
        FinishDate: this.formVigencia.get('FinishDateContract').value,
        EmployerId: this.formVigencia.get('EmployerId').value,
        Position: this.formVigencia.get('Position').value,
        TypeARL: this.formVigenciaArlExigida.get('TypeARL').value,
        Requirements: personalInfoContract.Requirements,
        tillEndOfDay: false,
        Module: 'Personal'
      };
      this.personalService.postContractPersonalInfoId(this.contracts[this.indexSelect]?.ContractId,
                                                      this.contracts[this.indexSelect]?.PersonalContractId,
                                                      model
      ).subscribe((rest: any) => {
        this.flagChangeContratista = false;
        if (rest?.CarnetCode === null || rest?.CarnetCode === undefined) {
          // this.newGenerateCarnet();
          this.flagChangeContratista = false;
        }
        if (this.dateSocialSecurityUpload.length !== 0 || this.replaceEPSorAFPdata.length !== 0) {
          for (let index = 0; index < this.dateSocialSecurityUpload.length; index++) {
            if (this.dateSocialSecurityUpload[index].Model === 'EmployerPersonalRequirement') {
              this.personalService.putEmployerPersonalRequirement( this.dateSocialSecurityUpload[index].Id,
                this.dateSocialSecurityUpload[index]).subscribe(putRequierement => {
                if (index === this.dateSocialSecurityUpload.length - 1) {
                  this.generateDocumentComentant();
                }
              });
            } else if (this.dateSocialSecurityUpload[index].Model ===  'PersonalRequirement') {
              this.personalService.putPersonalRequirement(this.dateSocialSecurityUpload[index].Id,
                this.dateSocialSecurityUpload[index]).subscribe(putRequierement => {
                if (index === this.dateSocialSecurityUpload.length - 1) {
                  this.generateDocumentComentant();
                }
              });
            } else if (this.dateSocialSecurityUpload[index].Model === undefined) {
              this.personalService.putPersonalRequirementPlanilla(this.dateSocialSecurityUpload[index].Id, this.personalCompanyInfoId,
                this.contractSelected.EmployerId, this.dateSocialSecurityUpload[index].Date).subscribe(putRequierement => {
                if (index === this.dateSocialSecurityUpload.length - 1) {
                  this.generateDocumentComentant();
                }
              });
            }
          }
          for (let index = 0; index < this.replaceEPSorAFPdata.length; index++) {
            this.personalService.changeEpsAFpPATCH(this.contractsOffline[this.indexSelect].PersonalGuidId,
              this.replaceEPSorAFPdata[index].path, this.replaceEPSorAFPdata[index].value).subscribe(putRequierement => {
              if (index === this.replaceEPSorAFPdata.length - 1) {
                this.replaceEPSorAFPdata = [];
                this.getContractPersonal();
                this.getRefrestHistory();
                this.getPersonalInfoId(this.contracts[this.indexSelect].ContractId);
              }
            });
          }
        } else {
          // this.getContractsOffline(this.indexSelect);
          if (this.changeEmployerId === true && (this.employerChangeDocument.EmployerId !== this.formVigencia.get('EmployerId').value)) {
            this.dialogService.openDialogConfirm('¿Desea copiar los documentos del empleado ' + this.employerChangeDocument.EmployerName + ' al empleador ' + this.formVigencia.get('EmployerName').value + '?', true, true, 'NO', 'SI').afterClosed().subscribe(restDialog => {
              if (restDialog !== false) {
                // tslint:disable-next-line:no-shadowed-variable
                const model = {
                  personalCompanyInfo: this.personalId as number,
                  prevEmployer: this.employerChangeDocument.EmployerId,
                  newEmployer: this.formVigencia.get('EmployerId').value,
                };
                this.personalService.postTransferDocumentRequirement(model).subscribe(() => {
                  this.changeEmployerId = false;
                  this.getContractPersonal();
                  this.getPersonalInfoId(this.contracts[this.indexSelect].ContractId);
                  this.getRefrestHistory();
                });
              } else {
                this.getContractPersonal();
                this.getRefrestHistory();
                this.getPersonalInfoId(this.contracts[this.indexSelect].ContractId);
              }
            });
          } else {
            this.getContractPersonal();
            this.getRefrestHistory();
          }
        }
      });
    }, (error: HttpErrorResponse) => {
      error.status === 400 ?
        this.dialogService.openDialogMovePersonalInfo(true, error, 'Desea trasladarlo?', false).afterClosed().subscribe(restDialog => {
          if (restDialog === true) {
            const pesonalCompany = {
              AfpId: this.formPersonal.get('AfpId').value,
              ContractId: this.contractSelected.ContractId,
              EmployerId: null,
              EpsId: this.formPersonal.get('EpsId').value,
              PersonalCompanyInfoId: this.personalId,
              Position: this.formVigencia.get('Position').value,
              TypeARL: this.formVigenciaArlExigida.get('TypeARL').value,
              StartDate:  this.formVigencia.get('StartDateContract').value,
              FinishDate: this.formVigencia.get('FinishDateContract').value,
              tillEndOfDay: false
            };
            this.contractService.postMovePersonalInfoContract(this.contractSelected.ContractId,
              pesonalCompany).subscribe((restMOvePersonal: any) => {
              this.getRefrestHistory();

            });
          }
        })
        // tslint:disable-next-line:no-unused-expression
      : '';
    });
    setTimeout(() => {
      this.flagChangeDataBtnSave = false;
      this.changeAfterfillFormPersonal = false;
      this.changeAfterfillFormVigenciaArlExigida = false;
      this.changeAfterfillFormVigencia = false;
      this.changeAfterfillFormPersonalCV = false;
      this.changeAfterfillFormVaccine = false;
      this.changeAfterfillFormContractIndividual = false;
      this.changeAfterfillFormOtrosDatos = false;
    }, 2000);

  }

  generateDocumentComentant() {
    if (this.dateSocialSecurityUpload.length !== 0) {
      for (let index = 0; index < this.dateSocialSecurityUpload.length; index++) {
        if (this.dateSocialSecurityUpload[index].Model === 'EmployerPersonalRequirement' ) {
            if (index === this.dateSocialSecurityUpload.length - 1) {
              if (this.changeEmployerId === true) {
                this.dialogService.openDialogConfirm('¿Desea copiar los documentos del empleado ' + this.employerChangeDocument.EmployerName
                  + ' al empleador ' + this.formVigencia.get('EmployerName').value + '?', true, true,
                  'NO', 'SI').afterClosed().subscribe(restDialog => {
                  if (restDialog !== false) {
                    const model: ChangeTransferEMployerDocument = {
                      personalCompanyInfo: this.personalId,
                      prevEmployer: this.employerChangeDocument.EmployerId,
                      newEmployer: this.formVigencia.get('EmployerId').value,
                    };
                    this.personalService.postTransferDocumentRequirement(model).subscribe(rest => {
                      this.changeEmployerId = false;
                      this.getContractPersonal();
                      this.getPersonalInfoId(this.contracts[this.indexSelect].ContractId);
                      this.dateSocialSecurityUpload = [];
                    });
                  } else {
                    this.getContractPersonal();
                    this.getPersonalInfoId(this.contracts[this.indexSelect].ContractId);
                    this.dateSocialSecurityUpload = [];
                  }
                });
              } else {
                this.getContractPersonal();
                this.getPersonalInfoId(this.contracts[this.indexSelect].ContractId);
              }
            }
          // })
        } else if (this.dateSocialSecurityUpload[index].Model === 'PersonalRequirement') {
            if (index === this.dateSocialSecurityUpload.length - 1) {
              if (this.changeEmployerId === true) {
                this.dialogService.openDialogConfirm('¿Desea copiar los documentos del empleado ' + this.employerChangeDocument.EmployerName
                  + ' al empleador ' + this.formVigencia.get('EmployerName').value + '?', true, true,
                  'NO', 'SI').afterClosed().subscribe(restDialog => {
                  if (restDialog !== false) {
                    const model: ChangeTransferEMployerDocument = {
                      personalCompanyInfo: this.personalId,
                      prevEmployer: this.employerChangeDocument.EmployerId,
                      newEmployer: this.formVigencia.get('EmployerId').value,
                    };
                    this.personalService.postTransferDocumentRequirement(model).subscribe(rest => {
                      this.changeEmployerId = false;
                      this.getContractPersonal();
                      this.getPersonalInfoId(this.contracts[this.indexSelect].ContractId);
                      this.dateSocialSecurityUpload = [];
                    });
                  } else {
                    this.getContractPersonal();
                    this.getPersonalInfoId(this.contracts[this.indexSelect].ContractId);
                    this.dateSocialSecurityUpload = [];
                  }
                });
              } else {
                this.getContractPersonal();
                this.getPersonalInfoId(this.contracts[this.indexSelect].ContractId);
                this.dateSocialSecurityUpload = [];
              }
            }
          // });
        }
      }
    } else {
      if (this.changeEmployerId === true) {
        this.dialogService.openDialogConfirm('¿Desea copiar los documentos del empleado ' + this.employerChangeDocument.EmployerName + ' al empleador ' + this.formVigencia.get('EmployerName').value + '?', true, true, 'NO', 'SI').afterClosed().subscribe(restDialog => {
          if (restDialog !== false) {
            const model: ChangeTransferEMployerDocument = {
              personalCompanyInfo: this.personalId,
              prevEmployer: this.employerChangeDocument.EmployerId,
              newEmployer: this.formVigencia.get('EmployerId').value,
            };
            this.personalService.postTransferDocumentRequirement(model).subscribe(rest => {
              this.changeEmployerId = false;
              this.getContractPersonal();
              this.getPersonalInfoId(this.contracts[this.indexSelect].ContractId);
              this.dateSocialSecurityUpload = [];
            });
          }
        });
      }
    }

  }

  newGenerateCarnet() {
    this.personalService.printSaveCarnet({
      EmployerId: this.formVigencia.get('EmployerId').value,
      IsActive: this.contracts[this.indexSelect].IsActive,
      PersonalCompanyInfoId: this.contracts[this.indexSelect].PersonalCompanyInfoId,
    }).subscribe((modelSaveCarnetPrintResponse: ModelSaveCarnetPrintResponse) => {
    });
  }

  postVaccice() {
    let model;
    if (this.getDescriptionStatus(this.formVaccine.get('VaccineStatusId').value) === 'Vacunado' || this.getDescriptionStatus(
      this.formVaccine.get('VaccineStatusId').value) === 'Parcialmente Vacunado') {
      model = {
        PersonalCompanyInfoId: this.personalId,
        VaccineStatusId: this.formVaccine.get('VaccineStatusId').value,
        VaccineTypeId: this.formVaccine.get('VaccineTypeId').value,
        AppliedDose: this.formVaccine.get('AppliedDose').value,
        VaccineObservations: this.formVaccine.get('VaccineObservations').value
      };
    } else {
      model = {
        PersonalCompanyInfoId: this.personalId,
        VaccineStatusId: this.formVaccine.get('VaccineStatusId').value,
        VaccineObservations: this.formVaccine.get('VaccineObservations').value
      };
    }
    this.personalService.postVaccine(model).subscribe((rest: any) => {
      this.personalStatus = rest?.VaccineStatus?.Description;
    });
  }

  putPersonalFull() {
    if (this.formPersonal.status === 'VALID' && this.formPersonalCV.status === 'VALID') {
      let birthDate: string;
      if (typeof this.formPersonal.get('BirthDate').value === 'object') {
        birthDate = this.fechasService.getDateFormatDayMontYear(this.formPersonal.get('BirthDate').value);
      } else {
        birthDate = this.formPersonal.get('BirthDate').value.toString().replace(/-/g, '').substr(0, 8);
      }
      const modelPersonalPut = { ...this.personal,
        // tslint:disable-next-line:radix
        Nationality: parseInt(this.formPersonal.get('Nationality').value),
        DocumentNumber: this.formPersonal.get('DocumentNumber').value,
        DocumentType: this.formPersonal.get('DocumentType').value,
        Name: this.formPersonal.get('Name').value,
        LastName: this.formPersonal.get('LastName').value,
        BirthDate: birthDate,
        Sex: this.formPersonal.get('Sex').value,
        RH: this.formPersonal.get('RH').value,
        CityCode: this.formPersonal.get('CityCode').value,
        EmergencyContact: this.formPersonal.get('EmergencyContact').value,
        EmergencyContactPhone: this.formPersonal.get('EmergencyContactPhone').value,
        RelationshipWithContact: this.formPersonal.get('RelationshipWithContact').value,
        Address: this.formPersonal.get('Address').value,
        PhoneNumber: this.formPersonal.get('PhoneNumber').value,
        Email: this.formPersonal.get('Email').value,
        // Change Ciudad de nacimineto
        CityBirthCode: this.formPersonal.get('CityOfBirthCode').value,
        AfpId: this.formPersonal.get('AfpId').value,
        EpsId: this.formPersonal.get('EpsId').value,
        TownId: this.formPersonal.get('TownId').value,
        // Datos de hoja de vida
        JobCode: this.formPersonalCV.get('JobCode').value,
        MaritalStatusId: this.formPersonalCV.get('MaritalStatusId').value,
        NumberOfChilds: this.formPersonalCV.get('NumberOfChilds').value,
        NumberOfDependants: this.formPersonalCV.get('NumberOfDependants').value,
        SocialStratumId: this.formPersonalCV.get('SocialStratumId').value,
        Medicines: this.formPersonalCV.get('Medicines').value,
        // //datos de expedicion
        ExpeditionDate: this.controlExpeditionDate.value ? this.controlExpeditionDate.value : null,
        ExpeditionPlaceCode: this.controlExpeditionPlace.value ? this.controlExpeditionPlace.value : null
      };
      this.personalService.putPersonalFull(this.personalId, modelPersonalPut).subscribe(restPersonal => {
        const modelPersonalCV = {
          PersonalCompanyInfoId: this.personalId,
          EducationalLevelId: this.formPersonalCV.get('EducationalLevelId').value,
          ExperienceYears: this.formPersonalCV.get('ExperienceYears').value,
          ExperienceMonths: this.formPersonalCV.get('ExperienceMonths').value
        };
        this.personalService.postPersonalCV(modelPersonalCV).subscribe((restPOSTPersonalCV: any) => {
          if ((this.urlDataStudyCertificate === null || this.urlDataStudyCertificate === '')
            && this.formDataStudyCertificate !== undefined){
            this.personalService.updatePhotoAddStudyCertificate(restPOSTPersonalCV.Id,
              this.formDataStudyCertificate).subscribe(restUpdateFileStudy => {

            });
          }
          if ((this.urlDataExperienceCertificate === null || this.urlDataExperienceCertificate === '')
            && this.formDataExperienceCertificate !== undefined){
            this.personalService.updatePhotoAddExperienceCertificate(restPOSTPersonalCV.Id,
              this.formDataExperienceCertificate).subscribe(restUpdateFileStudy => {

            });
          }
          this.snackBService.openSnackBar('Empleado actualizado correctamente', '', 3000);
        });

      });
    } else {
      this.snackBService.openSnackBar('Formulario del empleado incompleto', '', 3000);
    }
  }

   // Servicios antes de generar la Planilla
   getServices() {
     this.employerService.getSecurityReferences().subscribe(data => {
        this.securityReferences = data;
        this.eps = this.securityReferences.filter(x => x.Type === TypeSecurityReference.EPS).sort((a, b) =>
          a.OrderNum > b.OrderNum ? 1 : -1);
        this.afp = this.securityReferences.filter(x => x.Type === TypeSecurityReference.AFP).sort((a, b) =>
          a.OrderNum > b.OrderNum ? 1 : -1);
        // this.arl = this.securityReferences.filter(x => x.Type === TypeSecurityReference.ARL).sort((a, b)
      // => a.OrderNum > b.OrderNum ? 1 : -1);

    });
     this.commonServices.getCommonOptions('OccupationalRisk').subscribe((data: CommonOptions[]) => {
       this.occupationalRisk = data;
    });

     this.commonService.getCommonOptions('BlacklistReason').subscribe((data: CommonOptions[]) => {
        this.causalesData = data.sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });

     this.contratingService.getBanks().subscribe((rest: any) => {
        this.bancks = rest;
      });
     this.commonService.getCommonOptions('BankAccountType').subscribe(rest => {
        this.bankAccountType = rest;
      });
    // }
     this.commonService.getCommonOptions('DateLabel').subscribe((data: CommonOptions[]) => {
       this.dateLabel = data;
    });

     this.getProjects();
  }

  etImg(logo) {
    return (logo === null ? 'assets/images/personblue.png' : logo);
  }

  disabledFormularios() {
    if (this.flagListF === false) {
      this.formVigenciaArlExigida.disable();
      this.formPersonalCV.disable();
      this.formVigencia.disable();
      this.formVaccine.disable();
      this.formPersonal.disable();
      this.formDatosContrato.disable();
      this.formOtrosDatos.disable();
    } else {
      this.formVigenciaArlExigida.enable();
      this.formPersonalCV.enable();
      this.formVigencia.enable();
      this.formVaccine.enable();
      this.formPersonal.enable();
      this.formDatosContrato.enable();
      this.formOtrosDatos.enable();
    }
  }

  certifIsValition(certif): boolean {
    let a = true;
    if (certif.Type === 'CCD' || certif.Type === 'PEP'){
      if (certif.Type === 'CCD' && this.formPersonal.get('Nationality').value !== '0'){
        a = false;
      }
      if (certif.Type === 'PEP' && this.formPersonal.get('Nationality').value === '0'){
        a = false;
      }
    }
    return a;
  }
  getContractPersonal() {
    const newPersonalCompany: PersonalContractProyect[] = [];
    this.personalService.getInfoPersonalCompanyPersonalId(this.personalCompanyInfoId).subscribe(
      (infoPersonalCompanyPersonalId: InfoPersonalCompanyPersonalId) => {
      // Formulario Personal
      this.personal = infoPersonalCompanyPersonalId;
      this.permissionsCompany = this.userAuth.Companies.find(x => x.Id === this.personal.CompanyInfoId).Permissions;

      this.fillFormPersonal();
      // fim formulario Personal
      this.infoPersonalCompanyPersonalId = infoPersonalCompanyPersonalId;
      this.exitContractsOffline = false;

      setTimeout(() => {
        this.disabledFormsByPermissions();
      }, 1500);

      this.personalService.getContractsOfflinePersonalCompanyInfoId(this.personalCompanyInfoId).subscribe(
        (contractsOffline: ContractsOffline[]) => {
        const arrayNewContractModifica = [];
        contractsOffline.forEach((x) => {
          // tslint:disable-next-line:new-parens
          arrayNewContractModifica.push({...x, Control: new FormControl});
        });
        this.contracts = arrayNewContractModifica
        .sort((x, y) => x.IsActive === true && y.Expiry === true && -new Date(x.FinishDate) + -new Date(y.FinishDate))
        .sort((a, b) =>
        (a.IsActive === true && a.Expiry === true && this.getCalculateStateDateFromIcon(a.FinishDate) === 'person-orange') ? -1
        : (a.IsActive === true && a.Expiry === false && this.getCalculateStateDateFromIcon(a.FinishDate) === 'person-blue' ? -1 : 1));
        this.configContractOffice(this.indexSelect, contractsOffline);
        contractsOffline.forEach(infoPersonalContrac => {
          JSON.parse(infoPersonalContrac.ProjectContracts).forEach((projectContracts: ProjectContracts) => {
            newPersonalCompany.push({PersonalProyect: projectContracts, PersonalContract: infoPersonalContrac});
          });
        });

        this.personalContractProyect = newPersonalCompany
        .sort((x, y) => x.PersonalContract.IsActive === true &&
          y.PersonalContract.Expiry === true && -new Date(x.PersonalContract.FinishDate) + -new Date(y.PersonalContract.FinishDate))
        .sort((a, b) =>
        (a.PersonalContract.IsActive === true && a.PersonalContract.Expiry === true &&
          this.getCalculateStateDateFromIcon(a.PersonalContract.FinishDate) === 'person-orange') ? -1
        : (a.PersonalContract.IsActive === true && a.PersonalContract.Expiry === false &&
          this.getCalculateStateDateFromIcon(a.PersonalContract.FinishDate) === 'person-blue' ? -1 : 1));

        setTimeout(() => {
          this.changeAfterfillFormVigenciaArlExigida = true;
          this.changeAfterfillFormVigencia = true;
          this.changeAfterfillFormPersonalCV = true;
          this.changeAfterfillFormVaccine = true;
          this.changeAfterfillFormContractIndividual = true;
          this.changeAfterfillFormOtrosDatos = true;
          // si viene del modulo solicitudes para vincular mas contratos
          if (this.requestService.dataContract !== undefined) {
            this.associateContrac(this.requestService.dataContract);
          }
        }, 2000);
        this.selectContract(this.indexSelect, true);
      });
    });

  }

  disabledFormsByPermissions(){
    if (!this.exitPermissionCompany('personalsemployercontract.update')){
      this.formDatosContrato.disable();
    }
    if (!this.exitPermissionCompany('personals.update')) {
      this.disabledByPermissionUpdate = true;
      this.formPersonal.disable();
      this.formPersonalCV.disable();
      this.formVigencia.disable();
      this.formVaccine.disable();
    }
  }

  putIndividualContractLabor() {
    if (this.formDatosContrato.get('EndDate').value === 'NaN-0NaN-0NaNT00:00:00') {
      this.formDatosContrato.get('EndDate').setValue(null);
    }
    if (this.formDatosContrato.get('StartDate').value === 'NaN-0NaN-0NaNT00:00:00') {
      this.formDatosContrato.get('StartDate').setValue(null);
    }
    if (this.exitPersonalEmployerByPersonalPOSTPUT !== undefined) {
      const model = {
        Salary: this.formDatosContrato.get('Salary').value?.toString()?.replace(/\$/g, '')?.replace(/\./g, ''),
        StartDate: this.formDatosContrato.get('StartDate').value,
        EndDate: this.formDatosContrato.get('EndDate').value,
        // PositionId: this.formDatosContrato.get('PositionId').value,
        PositionName: this.formDatosContrato.get('PositionName').value,
        BankAccount: this.formDatosContrato.get('BankAccount').value,
        ContractNumber: this.formDatosContrato.get('ContractNumber').value,
        ContractTypeId: this.formDatosContrato.get('ContractTypeId').value,
        BankId: this.formDatosContrato.get('BankId').value,
        BankAccountTypeId: this.formDatosContrato.get('BankAccountTypeId').value,
        TransportationAllowance: this.formDatosContrato.get('AuxiliaryTransport').value?.toString()?.replace(/\$/g, '')?.replace(/\./g, ''),
        TotalEarnings: this.formDatosContrato.get('TotalEarnings').value?.toString()?.replace(/\$/g, '')?.replace(/\./g, ''),
        IsActive: true,
        // IMPORTAN
        EmployerInfoId: this.formDatosContrato.get('EmployerInfoId').value,
        PersonalCompanyInfoId: this.personalCompanyInfoId,
        // datos pre nomina
        WorkShiftId: this.formDatosContrato.get('WorkShiftId').value,
        HasExtraTime: this.formDatosContrato.get('HasExtraTime').value,
        ProjectId: this.formDatosContrato.get('ProjectId').value
      };
      if (this.contractLaborSelected !== undefined) {
        this.personalService.putPersonalEmployer(this.contractLaborSelected.Id, model).subscribe(postPersonalEmployer => {
        });
      }
    }
  }

  // FLujo Contrato individual
  postIndividualContractLabor() {
    this.dialogService.openDialogSelectedControlSearchList(
      {textInput: 'Seleccione empleador', isCard: false, isCardImage: false, dataShow: 'Name', otherFilter: 'DocumentNumber', dataValue: 'Id', isDescription: true}, 'CONTRATO LABORAL', this.allEmployers, true, false, false, '', 'GUARDAR'
    ).afterClosed().subscribe(rest => {
      if (rest !== false) {
        this.personalService.getPersonalEmployerByPersonalAndEmployer(this.personalCompanyInfoId, rest).subscribe(
          (restExitContract: any) => {
          this.snackBService.openSnackBar('Ya existe un contrato laboral con el empleador ' + restExitContract.EmployerName, 'X', 4000);
        }, error => {
          this.dialogService.openDialogCreateContractLabor(this.personalCompanyInfoId, rest,
            // tslint:disable-next-line:no-shadowed-variable
            this.personal.CompanyInfoId, this.companyIdUserLogin).afterClosed().subscribe(rest => {
            if (rest !== false) {
              this.getPersonalEmployerInfoExit();
            }
          });
        });
      }
    });
  }

  getIndividualContractsType() {
    this.commonService.getCommonOptions('IndividualContractsType').subscribe(rest => {
      this.individualContractsType = rest;
    });
    this.commonService.getCommonOptions('IndividualContractsStageType').subscribe(rest => {
      this.states = rest;
    });
  }
  deleteContractLabor(Id, ContractNumber) {
    if (Id !== null) {
      this.dialogService.openDialogConfirm('¿Esta seguro de eliminar el contrato laboral ' +
        (ContractNumber === null ? '?' : ContractNumber + '?')).afterClosed().subscribe((result: DialogDataConfirm) => {
        if (result.confirm === true) {
          this.personalService.deletePersonalEmployer(Id).subscribe(restDelete => {
            this.getPersonalEmployerInfoExit();
          });
        }
      });
    }
  }

  getPositionEmployerId(CompanyId) {
    this.employerService.getPositionEmployerId(CompanyId).subscribe((positions: Positions[]) => {
      this.positionsIndividualContract = positions;
    });
  }

  changePositionsIndividualContract() {
    const data: any = this.personal.Employers.find((x: any) => x.EmployerId === this.contractSelected.EmployerId);
    if (data !== undefined) {
      this.getPositionEmployerId(data.CompanyId);
    }
  }
  getPersonalEmployerInfoExit() {
    const data: any = this.personal.Employers.find((x: any) => x.EmployerId === this.contractSelected.EmployerId);
    if (data !== undefined) {
      // NUEVO
      this.personalService.getPersonalEmployerByPersonal(this.personalCompanyInfoId).subscribe((exitPersonalEmployerinfo: any) => {
        if (exitPersonalEmployerinfo !== null && exitPersonalEmployerinfo !== undefined) {
          const arrayNewContractLaborModifica = [];
          exitPersonalEmployerinfo.forEach((x) => {
            if (x.Salary !== 0 && x.Salary !== null && x.Salary !== undefined){
              x.Salary = this.currencyPipe.transform(x.Salary.toString().replace(/\D/g, '').replace(/^0+/, ''),
                'USD', 'symbol', '1.0-0').replace(/,/gi, '.');
            }
            if (x.TransportationAllowance !== 0 && x.TransportationAllowance !== null && x.TransportationAllowance !== undefined){
              x.TransportationAllowance = this.currencyPipe.transform(x.TransportationAllowance.toString().replace(/\D/g, '').replace(/^0+/, ''),
                'USD', 'symbol', '1.0-0').replace(/,/gi, '.');
            }
            arrayNewContractLaborModifica.push({...x,
              ControlEmployerInfoId: new FormControl,
              ControlContractTypeId: new FormControl,
              ControlBankId: new FormControl,
              ControlBankAccountTypeId: new FormControl,
              ControlProjectId: new FormControl,
              ControlWorkShift: new FormControl,
              ControlHasExtraTime: new FormControl
              }
              );
          });
          this.contractsLabor = arrayNewContractLaborModifica;
          if (exitPersonalEmployerinfo.length !== 0) {
            this.formDatosContrato.get('ContractTypeId').setValue(exitPersonalEmployerinfo[0].ContractTypeId);
            this.formDatosContrato.get('ContractNumber').setValue(exitPersonalEmployerinfo[0].ContractNumber);
            this.formDatosContrato.get('EmployerInfoId').setValue(exitPersonalEmployerinfo[0].EmployerInfoId);
            this.formDatosContrato.get('EmployerName').setValue(exitPersonalEmployerinfo[0].EmployerName);
            this.formDatosContrato.get('EmployerDocumentNumber').setValue(exitPersonalEmployerinfo[0].EmployerDocumentNumber);
            this.formDatosContrato.get('EmployerDocumentType').setValue(exitPersonalEmployerinfo[0].EmployerDocumentType);
            this.formDatosContrato.get('EmployerLogo').setValue(exitPersonalEmployerinfo[0].EmployerLogo);
            this.formDatosContrato.get('PositionName').setValue(exitPersonalEmployerinfo[0].PositionName);
            this.formDatosContrato.get('Salary').setValue(exitPersonalEmployerinfo[0].Salary === 0 ? 0 :
              exitPersonalEmployerinfo[0].Salary);

            this.formDatosContrato.get('AuxiliaryTransport').setValue(exitPersonalEmployerinfo[0].TransportationAllowance ===
                                                                        0 ? 0 : exitPersonalEmployerinfo[0].TransportationAllowance);
            this.formDatosContrato.get('TotalEarnings').setValue(exitPersonalEmployerinfo[0].TotalEarnings ===
                                                                  0 ? 0 : exitPersonalEmployerinfo[0].TotalEarnings);
              // Datos bancarios
            this.formDatosContrato.get('BankAccount').setValue(exitPersonalEmployerinfo[0].BankAccount);
            this.formDatosContrato.get('BankId').setValue(exitPersonalEmployerinfo[0].BankId);
            this.formDatosContrato.get('BankAccountTypeId').setValue(exitPersonalEmployerinfo[0].BankAccountTypeId);
            // Se inicializa para saber si exite data en personalEmployerByPersonal 06022023 CAP

            // Datos lugar y turno de trabajo Prenomina

            this.formDatosContrato.get('ProjectId').setValue(exitPersonalEmployerinfo[0].ProjectId);
            if (exitPersonalEmployerinfo[0].ProjectId !== null && exitPersonalEmployerinfo[0].ProjectId !== undefined) {
              this.getPersonalWorkShiftrprojectId(exitPersonalEmployerinfo[0].ProjectId);
            }
            this.formDatosContrato.get('WorkShiftId').setValue(exitPersonalEmployerinfo[0].WorkShiftId);
            this.formDatosContrato.get('HasExtraTime').setValue(exitPersonalEmployerinfo[0].HasExtraTime);
            this.exitPersonalEmployerByPersonalPOSTPUT = exitPersonalEmployerinfo;
          }
          setTimeout(() => {
              this.changeAfterfillFormContractIndividual = true;
          }, 3000);
        } else {
          // this.formDatosContrato.get('PositionId').setValue('');
          this.formDatosContrato.get('PositionName').setValue('');
          this.formDatosContrato.get('Salary').setValue(0);
          this.formDatosContrato.get('StartDate').setValue('');
          this.formDatosContrato.get('EndDate').setValue('');
        }
        this.selectContractLabor(this.indexSelectContracLabor, true);
      }, error => {
        // this.formDatosContrato.get('PositionId').setValue('');
        this.formDatosContrato.get('PositionName').setValue('');
        this.formDatosContrato.get('Salary').setValue(0);
        this.formDatosContrato.get('StartDate').setValue('');
        this.formDatosContrato.get('EndDate').setValue('');
        this.exitPersonalEmployerByPersonalPOSTPUT = false;
      });
    }

  }
    // FLujo Contrato individual FIjn

  getNamesProyect(Proyects: string): any[] {
    const project = [];
    const parseo = JSON.parse(Proyects);
    parseo.forEach(element => {
        this.infoPersonalCompanyPersonalId.Projects.forEach(info => {
          if (element.ProjectId === info.ProjectId) {
            project.push({Name: info.Name, FinishDate: info.FinishDate});
          }
        });
      });
    return project;
  }
  getLastStageId(): number {
    let idStage = 0;
    this.lastStages.forEach((stage: CommonOptions) => {
      if (stage.Value === 'INI') {
        idStage = stage.Id;
      }
    });
    return idStage;
  }

  getLastStages() {
    this.commonService.getCommonOptions('PersonalContractStage').subscribe((data: CommonOptions[]) => {
      this.lastStages = data;
    });
  }

  startRetiro(contrato) {
    const flagEmpty = true;
    if (this.formDatosContrato.get('Salary').value === 0 ||
      this.formDatosContrato.get('Salary').value === null ||
      this.formDatosContrato.get('Salary').value === undefined ||
      this.formDatosContrato.get('Salary').value === '' ||
      this.formDatosContrato.get('AuxiliaryTransport').value === 0 ||
      this.formDatosContrato.get('AuxiliaryTransport').value === null ||
      this.formDatosContrato.get('AuxiliaryTransport').value === undefined ||
      this.formDatosContrato.get('AuxiliaryTransport').value === '') {
      this.snackBService.openSnackBar('DILIGENCIAR EL CAMPO DE SALARIO Y AUXILIO DE TRANSPORTE', 'X', 4000);
      return;
    }
    this.getLastStages();
    this.companiesService.getPersonalTerminationRequestTerminationType(this.userAuth.Companies[0].Id).subscribe(types => {
      this.dialogService.openDialogSelectedControlSearchList({textInput: '', isCard: false, isCardImage: false, dataShow: 'Name',
          otherFilter: 'Description', dataValue: 'Id', isDescription: true},
        'SELECCIONE TIPO DE RETIRO', types.filter(x => x.IsActive === true), true, true, true, 'Cancelar', 'Iniciar', true, 'Al iniciar esta solicitud, se inhabilitara el acceso del empleado en los proyectos.', 'RED'
      ).afterClosed().subscribe(rest => {
        if (rest !== false) {
          const model = {
            PersonalEmployerId: contrato.Id,
            TerminationStateId: this.getLastStageId(),
            TerminationTypeId: rest,
            TerminationDate: this.fechasService.getDateFormatDatePikerDATEHORANOW(),
            removeAccess: true
          };
          this.terminationService.postPersonalTerminationRequest(model, false).subscribe((restExitContract: any) => {
            this.snackBService.openSnackBar('Solicitud creada correctamente!', 'X', 4000);
            this.requestId(restExitContract.PersonalCompanyInfoId, restExitContract.Id);
          }, (error: HttpErrorResponse) => {
            if (error.status === 418) {
              // Se utlizara el redireonamiento de si exite la solitud de retiro.
              this.dialogService.openDialogConfirm('Ya existe una solicitud de retiro en tramite', true,
                true, 'CERRAR', 'ABRIR SOLICITUD').afterClosed().subscribe(rest => {
                if (rest !== false) {
                  this.requestId(error.error.PersonalCompanyInfoId, error.error.PersonalTerminationRequestId);
                }
              });
            }
          });
        }
      });
    });
  }

  requestId(PersonalCompanyInfoId, IdRequest) {
    if (this.commonMetodService._getExitPermissionModel('termination') === true) {
      this.router.navigate(['/admin/termination/termination', PersonalCompanyInfoId , IdRequest]);
    }

  }

  getContractsOffline(indexSelect) {
    this.exitContractsOffline = false;
    this.personalService.getContractsOfflinePersonalCompanyInfoId(this.personalCompanyInfoId).subscribe(
      (contractsOffline: ContractsOffline[]) => {
      contractsOffline.filter(x => x.IsActive === true);
      this.configContractOffice(indexSelect, contractsOffline, this.contractSelected);
    });
  }

  configContractOffice(indexSelect, contractsOffline: ContractsOffline[], contractSelected?) {
    this.contractsOffline = contractsOffline;
    this.exitContractsOffline = true;
    if (contractSelected !== undefined) {
      const contractSeletedData = contractsOffline.find(x => x.ContractId === contractSelected.ContractId);
      this.min = contractSeletedData?.Reqs?.Biometric[0]?.MinValue;
      this.max = contractSeletedData?.Reqs?.Biometric[0]?.MaxValue;
      this.biometricMaxMin = this.min + ' ≤ Edad < ' + this.max;
      const edad = this.fechasService.calcularEdad(this.fechasService.formatBirthDate(contractSeletedData?.BirthDate));
      this.edadRange = edad >= this.min
        && edad <= this.max;
      this.socialSecurity = [];
      this.certification = [];

      this.socialSecurity = contractSeletedData.Reqs.SocialSecurity;
      this.certification = contractSeletedData.Reqs.Certification;
      this.surveys = contractSeletedData.Reqs.Surveys;
      this.entry = contractSeletedData.Reqs.Entry;

      this.socialSecurity.forEach(x => {
        if (this.exitDocumentDocsUploadIniadoSocialSecurity(x.Id) === false) {
        this.docsUploadIniadoSocialSecurity.push(
          { Date: x.ValidityDate,
            Id: x.Id,
            Model: 'EmployerPersonalRequirement',
            TypeARL: x.TypeARL
          });
        }
      });
      this.certification.forEach(x => {
        if (this.exitDocumentDocsUploadIniadoSocialSecurity(x.Id) === false) {
        this.docsUploadIniadoSocialSecurity.push(
          { Date: x.Date,
            Id: x.Id,
            Model: 'PersonalRequirement'
          });
        }
      });
      this.surveys.forEach(x => {
        if (this.exitDocumentDocsUploadIniadoSocialSecurity(x.SurveyId) === false) {
        this.docsUploadIniadoSocialSecurity.push(
          { Date: x.Date,
            Id: x.SurveyId,
            Model: 'PersonalRequirement'
          });
        }
      });
      this.entry.forEach(x => {
        if (this.exitDocumentDocsUploadIniadoSocialSecurity(x.Id) === false) {
        this.docsUploadIniadoSocialSecurity.push(
          { Date: '',
            Id: x.Id,
            Model: 'PersonalRequirement'
          });
        }
      });
      this.controEps.setValue(contractSeletedData.EpsId);
      this.controAfp.setValue(contractSeletedData.AfpId);
      this.controOccupationalRisk.setValue(contractSeletedData.Reqs?.TypeARL?.PersonalTypeARL);
      this.IsValidedARL = contractSeletedData.Reqs?.TypeARL?.IsValided;
      this.hasDocuments = contractSeletedData.HasDocuments;
      this.isActive = contractSeletedData.IsActive;

    }
  }

  exitDocumentDocsUploadIniadoSocialSecurity(Id: any): boolean {
    let exit = false;
    const data = this.docsUploadIniadoSocialSecurity.find(x => x.Id === Id);
    if (data !== undefined) {
      exit = true;
    }
    return exit;
  }

  exitDocumentDateSocialSecurityUpload(Id: any): boolean {
    let exit = false;
    const data = this.dateSocialSecurityUpload.find(x => x.Id === Id);
    if (data !== undefined) {
      exit = true;
    }
    return exit;
  }

  getshowDocumentos(data, color): string {
    let operatordocument: string;
    if (this.exitPermissionCompany('personalsrequirements.updatebyposition') && !this.exitPermissionCompany('personaldocument.delete') &&
      ((data.Model !== undefined || this.getCalculateStateDate(this.getDateDocsUploadIniadoSocialSecurity(data.Id)) === 'DateRed') ||
        this.flagListF !== false) && (data.Model !== 'EmployerRequirement')) {
      // operatordocument = 'showUpdate';
      if (color === 'negro') {
        operatordocument = 'file-negro';
      } else {
        operatordocument = 'file-rojo';
      }
    } else if (this.exitPermissionCompany('personaldocument.delete') && (data.Model !== undefined ||
        this.getCalculateStateDate(this.getDateDocsUploadIniadoSocialSecurity(data.Id)) === 'DateRed') &&
      this.flagListF !== false && (data.Model !== 'EmployerRequirement')) {
      // operatordocument = 'showDelete';
      if (color === 'negro') {
        operatordocument = 'file-negro';
      } else {
        operatordocument = 'file-rojo';
      }
    } else {
      // operatordocument = 'show';
      if (color === 'negro') {
        operatordocument = 'file-negro-ojo';
      } else {
        operatordocument = 'file-rojo-ojo';
      }
    }

    if (!this.exitPermissionCompany('personalsrequirements.updatebyposition')){
      if (color === 'negro') {
        operatordocument = 'file-negro-ojo';
      } else {
        operatordocument = 'file-rojo-ojo';
      }
    }
    return operatordocument;
  }

  showDocumentos(docs: any, Id, data, reqName: string) {
    const docsShow: ModelDocumentsShow[] = [];
    if (docs !== undefined && docs !== null && docs !== '' && docsShow.length === 0) {
      const array = Object.entries(JSON.parse(docs));
      array.forEach((doc: any) => {
            docsShow.push({ name: doc[0], urlDoc: doc[1]});
      });
    }

    let operatordocument: string;
    if (this.exitPermissionCompany('personalsrequirements.updatebyposition') && !this.exitPermissionCompany('personaldocument.delete') &&
        ((data.Model !== undefined || this.getCalculateStateDate(this.getDateDocsUploadIniadoSocialSecurity(Id)) === 'DateRed') ||
          this.flagListF !== false) && (data.Model !== 'EmployerRequirement')) {
        operatordocument = 'showUpdate';
      } else if (this.exitPermissionCompany('personaldocument.delete') && (data.Model !== undefined ||
        this.getCalculateStateDate(this.getDateDocsUploadIniadoSocialSecurity(Id)) === 'DateRed') && this.flagListF !== false &&
        (data.Model !== 'EmployerRequirement')) {
        operatordocument = 'showDelete';
      } else {
        operatordocument = 'show';
      }
    if (!this.exitPermissionCompany('personalsrequirements.updatebyposition')) { operatordocument = 'show'; }
    this.dialogService.openDialogGestorImg('GESTOR DE DOCUMENTOS', docsShow, operatordocument, 0, undefined,
      true, data.Desc, this.permissionsCompany, true).afterClosed().subscribe((restDialog: any) => {
      if (restDialog !== undefined) {
        const contractSeletedData = this.contractsOffline.find(x => x.ContractId === this.contractSelected.ContractId);
        if (data.Model === 'EmployerPersonalRequirement') {
          if (Object.keys(restDialog.DocsJSON).length !== 0) {
            const model = {
              DocsJSON: restDialog.DocsJSON,
              Id,
              Model: 'EmployerPersonalRequirement'
            };
            this.personalService.putDELETEEmployerPersonalRequirement(Id, model).subscribe((restDelete: any) => {
              if (restDialog.files.length !== 0){
                for (let i = 0; i < restDialog.files.length; i++) {
                  const formData = new FormData();
                  if (restDialog.commentary !== '' && restDialog.commentary !== undefined) {
                    formData.append('commentary', restDialog.commentary);
                  }
                  formData.append('File', restDialog.files[i]);
                  this.personalService.putUPDATEFILEEmployerPersonalRequirement(Id, formData).subscribe((restUpdate: any) => {
                    if (i === restDialog.files.length - 1) {
                      if (reqName === 'SocialSecurity'){
                        contractSeletedData.Reqs.SocialSecurity.forEach(social => {
                          if (data.Id === social.Id) {
                            social.DocsJSON = restUpdate.DocsJSON;
                          }
                        });
                      } else if (reqName === 'Certification'){
                        contractSeletedData.Reqs.Certification.forEach(social => {
                          if (data.Id === social.Id) {
                            social.DocsJSON = restUpdate.DocsJSON;
                          }
                        });
                      }
                    }
                  });
                }
              } else {
                if (reqName === 'SocialSecurity'){
                  contractSeletedData.Reqs.SocialSecurity.forEach(social => {
                    if (data.Id === social.Id) {
                      social.DocsJSON = restDelete.DocsJSON;
                    }
                  });
                } else if (reqName === 'Certification'){
                  contractSeletedData.Reqs.Certification.forEach(social => {
                    if (data.Id === social.Id) {
                      social.DocsJSON = restDelete.DocsJSON;
                    }
                  });
                }
              }
            });
          } else if (restDialog.files.length !== 0){
            for (let i = 0; i < restDialog.files.length; i++) {
              const formData = new FormData();
              if (restDialog.commentary !== '' && restDialog.commentary !== undefined) {
                formData.append('commentary', restDialog.commentary);
              }
              formData.append('File', restDialog.files[i]);
              this.personalService.putUPDATEFILEEmployerPersonalRequirement(Id, formData).subscribe((restUpdate: any) => {
                if (i === restDialog.files.length - 1) {
                  if (reqName === 'SocialSecurity'){
                    contractSeletedData.Reqs.SocialSecurity.forEach(social => {
                      if (data.Id === social.Id) {
                        social.DocsJSON = restUpdate.DocsJSON;
                      }
                    });
                  } else if (reqName === 'Certification'){
                    contractSeletedData.Reqs.Certification.forEach(social => {
                      if (data.Id === social.Id) {
                        social.DocsJSON = restUpdate.DocsJSON;
                      }
                    });
                  }
                }
              });
            }
          } else if (restDialog.commentary !== '' && restDialog.commentary !== undefined) {
            this.personalService.putEmployerPersonalRequirementUpdateComment(Id, restDialog.commentary).subscribe((restUpdate: any) => {
              if (reqName === 'SocialSecurity'){
                contractSeletedData.Reqs.SocialSecurity.forEach(social => {
                  if (data.Id === social.Id) {
                    social.DocsJSON = restUpdate.DocsJSON;
                  }
                });
              } else if (reqName === 'Certification'){
                contractSeletedData.Reqs.Certification.forEach(social => {
                  if (data.Id === social.Id) {
                    social.DocsJSON = restUpdate.DocsJSON;
                  }
                });
              }
            });
          }
        } else if (data.Model === 'PersonalRequirement') {
          if (Object.keys(restDialog.DocsJSON).length !== 0) {
            const model = {
              DocsJSON: restDialog.DocsJSON,
              Id,
              Model: 'PersonalRequirement'
            };
            this.personalService.putDELETEPersonalRequirement(Id, model).subscribe((restDelete: any) => {
              if (restDialog.files.length !== 0){
                for (let i = 0; i < restDialog.files.length; i++) {
                  const formData = new FormData();
                  if (restDialog.commentary !== '' && restDialog.commentary !== undefined) {
                    formData.append('commentary', restDialog.commentary);
                  }
                  formData.append('File', restDialog.files[i]);
                  this.personalService.putUPDATEFILEPersonalRequirement(Id, formData).subscribe((restUpdate: any) => {
                    if (i === restDialog.files.length - 1) {
                      if (reqName === 'SocialSecurity'){
                        contractSeletedData.Reqs.SocialSecurity.forEach(social => {
                          if (data.Id === social.Id) {
                            social.DocsJSON = restUpdate.DocsJSON;
                          }
                        });
                      } else if (reqName === 'Certification'){
                        contractSeletedData.Reqs.Certification.forEach(social => {
                          if (data.Id === social.Id) {
                            social.DocsJSON = restUpdate.DocsJSON;
                          }
                        });
                      }
                    }
                  });
                }
              } else {
                if (reqName === 'SocialSecurity'){
                  contractSeletedData.Reqs.SocialSecurity.forEach(social => {
                    if (data.Id === social.Id) {
                      social.DocsJSON = restDelete.DocsJSON;
                    }
                  });
                } else if (reqName === 'Certification'){
                  contractSeletedData.Reqs.Certification.forEach(social => {
                    if (data.Id === social.Id) {
                      social.DocsJSON = restDelete.DocsJSON;
                    }
                  });
                }
              }
            });
          } else if (restDialog.files.length !== 0){
            for (let i = 0; i < restDialog.files.length; i++) {
              const formData = new FormData();
              if (restDialog.commentary !== '' && restDialog.commentary !== undefined) {
                formData.append('commentary', restDialog.commentary);
              }
              formData.append('File', restDialog.files[i]);
              this.personalService.putUPDATEFILEPersonalRequirement(Id, formData).subscribe((restUpdate: any) => {
                if (i === restDialog.files.length - 1) {
                  if (reqName === 'SocialSecurity'){
                    contractSeletedData.Reqs.SocialSecurity.forEach(social => {
                      if (data.Id === social.Id) {
                        social.DocsJSON = restUpdate.DocsJSON;
                      }
                    });
                  } else if (reqName === 'Certification'){
                    contractSeletedData.Reqs.Certification.forEach(social => {
                      if (data.Id === social.Id) {
                        social.DocsJSON = restUpdate.DocsJSON;
                      }
                    });
                  }
                }
              });
            }
          } else if (restDialog.commentary !== '' && restDialog.commentary !== undefined) {
            this.personalService.putPersonalRequirementUpdateComment(Id, restDialog.commentary).subscribe((rest: any) => {
              if (reqName === 'SocialSecurity'){
                contractSeletedData.Reqs.SocialSecurity.forEach(social => {
                  if (data.Id === social.Id) {
                    social.DocsJSON = rest.DocsJSON;
                  }
                });
              } else if (reqName === 'Certification'){
                contractSeletedData.Reqs.Certification.forEach(social => {
                  if (data.Id === social.Id) {
                    social.DocsJSON = rest.DocsJSON;
                  }
                });
              }
            });
          }
        } else if (data.Model === undefined) {
          if (Object.keys(restDialog.DocsJSON).length !== 0) {
            const model = {
              DocsJSON: restDialog.DocsJSON,
              Id
            };
            this.personalService.putDELETEEmployerPersonalRequirement(data.RequirementId, model).subscribe((restDelete: any) => {
              if (restDialog.files.length !== 0){
                for (let i = 0; i < restDialog.files.length; i++) {
                  const formData = new FormData();
                  if (restDialog.commentary !== '' && restDialog.commentary !== undefined) {
                    formData.append('commentary', restDialog.commentary);
                  }
                  formData.append('File', restDialog.files[i]);
                  this.personalService.putUPDATEFILEEmployerPersonalRequirementPlanilla(data.RequirementId, this.personalCompanyInfoId,
                    this.contractSelected.EmployerId , formData).subscribe((restUpdate: any) => {
                    if (i === restDialog.files.length - 1) {
                      if (reqName === 'SocialSecurity'){
                        contractSeletedData.Reqs.SocialSecurity.forEach(social => {
                          if (data.Id === social.Id) {
                            social.DocsJSON = restUpdate.DocsJSON;
                          }
                        });
                      } else if (reqName === 'Certification'){
                        contractSeletedData.Reqs.Certification.forEach(social => {
                          if (data.Id === social.Id) {
                            social.DocsJSON = restUpdate.DocsJSON;
                          }
                        });
                      }
                    }
                  });
                }
              } else {
                if (reqName === 'SocialSecurity'){
                  contractSeletedData.Reqs.SocialSecurity.forEach(social => {
                    if (data.Id === social.Id) {
                      social.DocsJSON = restDelete.DocsJSON;
                    }
                  });
                } else if (reqName === 'Certification'){
                  contractSeletedData.Reqs.Certification.forEach(social => {
                    if (data.Id === social.Id) {
                      social.DocsJSON = restDelete.DocsJSON;
                    }
                  });
                }
              }
            });
          } else if (restDialog.files.length !== 0){
            for (let i = 0; i < restDialog.files.length; i++) {
              const formData = new FormData();
              if (restDialog.commentary !== '' && restDialog.commentary !== undefined) {
                formData.append('commentary', restDialog.commentary);
              }
              formData.append('File', restDialog.files[i]);
              this.personalService.putUPDATEFILEEmployerPersonalRequirementPlanilla(data.RequirementId, this.personalCompanyInfoId,
                this.contractSelected.EmployerId , formData).subscribe((restUpdate: any) => {
                if (i === restDialog.files.length - 1) {
                  if (reqName === 'SocialSecurity'){
                    contractSeletedData.Reqs.SocialSecurity.forEach(social => {
                      if (data.Id === social.Id) {
                        social.DocsJSON = restUpdate.DocsJSON;
                      }
                    });
                  } else if (reqName === 'Certification'){
                    contractSeletedData.Reqs.Certification.forEach(social => {
                      if (data.Id === social.Id) {
                        social.DocsJSON = restUpdate.DocsJSON;
                      }
                    });
                  }
                }
              });
            }
          }
        }
      }
    });
  }

  goContract(id) {
    if (id !== undefined) {
      this.router.navigate(['/admin/contract/contract', id]);
    }
  }

  getDateFormatR(date): string {
    let response = '';
    if (date !== null) {
      response = this.fechasService.getDateFormatR(date);
    }
    return response;
  }

  postPersonalBlackList() {
    this.dialogService.openDialogConfirm('Esta seguro de agregarlo a la lista').afterClosed().subscribe(rest => {
      if (rest !== false) {
        this.dialogService.openDialogSelectedCausal({textInput: 'Motivo o Causal', isCard: false, isCardImage: false,
          dataShow: 'Name', otherFilter: 'Description', dataValue: 'Id', isDescription: true}, 'La persona quedara inhabilitada para ingresar a los proyectos.',
          this.causalesData, true, true, true, 'CANCELAR', 'CONFIRMAR').afterClosed().subscribe(res => {
          if (res !== false){
            const personalBlack: PersonalBlackList =
              { CompanyInfoId: this.personal.CompanyInfoId,
                DocumentType: this.formPersonal.get('DocumentType').value,
                DocumentNumber: this.formPersonal.get('DocumentNumber').value,
                StartDate: this.fechasService.getDateFormatDatePiker(new Date()),
                ReasonId: res,
              };
            this.contratingService.postPersonalBlackList(personalBlack).subscribe(() => {
                const firePersonal: FirePersonal =
                {
                  personalCompanyInfoId: this.contractsOffline[this.indexSelect].PersonalCompanyInfoId,
                };
                // FirePersonal
                this.contratingService.postFirePersonal(firePersonal).subscribe(response => {
                  this.flagListF = false;
                  this.disabledFormularios();
                  setTimeout(() => {
                    this.validateListF(this.personal.DocumentType, this.personal.DocumentNumber, this.personal.CompanyInfoId);
                  }, 4000);
                });
            });
          }
        });



      }
    });
  }
  deletePersonalBlackList() {
        this.dialogService.openDialogConfirm('Esta seguro de quitarlo de la lista?').afterClosed().subscribe(rest => {
          if (rest !== false) {
            this.contratingService.deletePersonalBlackList(this.personalCompanyInfoId).subscribe( () => {
              this.snackBService.openSnackBar('Se a quitado de la lista.', 'X', 4000);
              this.flagListF = true;
              this.disabledFormularios();
              this.validateListF(this.personal.DocumentType, this.personal.DocumentNumber, this.personal.CompanyInfoId);
            });
          }
        });
  }

  selectContract(index: number, startOnInit?) {
    if (startOnInit === true) {
      this.changeSelectedContract(index);
    } else if (index !== this.indexSelect) {
      this.changeSelectedContract(index);
    }
  }

  changeSelectedContract(index) {
      this.indexSelect = index;
      this.personalContractSelected = undefined;
      this.personalContractProyectSelected = undefined;
      this.contractSelected = undefined;

      this.contractSelected = this.contracts[this.indexSelect];
      this.personalContractSelected = this.contracts[this.indexSelect];

      this.hasDocuments = this.personalContractSelected?.HasDocuments;
      this.isActive = this.personalContractSelected?.IsActive;

      this.formVigencia.get('Position').setValue(this.personalContractSelected?.Position);
      this.formVigencia.get('IsActive').setValue(this.personalContractSelected?.IsActive);
      this.formVigencia.get('HasDocuments').setValue(this.personalContractSelected?.HasDocuments);
      this.formVigencia.get('DescriptionPersonalType').setValue(this.personalContractSelected?.DescriptionPersonalType);
      this.formVigencia.get('CompanyInfoParentId').setValue(this.personalContractSelected?.CompanyInfoParentId);
      this.formVigencia.get('SearchEmpleador').setValue('');

      this.configContractOffice(index, this.contractsOffline, this.contractSelected);
      this.getPersonalInfoId(this.contracts[this.indexSelect]?.ContractId);
      this.getSelectedContractsPosition(true);
      this.changePositionsIndividualContract();
      this.getPersonalEmployerInfoExit();

      // ChangeNameEmpleadorName
      this.getNamePersonalEmployerNameSelected(this.contractSelected?.EmployerId);

      // Lis employer documentos
      this.getDocumentRequirementForContractAndPersonal(this.contractSelected?.Id);
  }

  getDocumentRequirementForContractAndPersonal(contractId){
    this.personalService.getDocumentRequirementForContractAndPersonal(contractId, this.personalCompanyInfoId).subscribe(rest => {

    });
  }

  getRequirementsUptadeInfo(e?) {
    if (1 === 1) {
      const nationality = this.formPersonal.get('Nationality').value;
      this.commonService.getCommonOptions('CompanyDocumentType').subscribe((data: CommonOptions[]) => {
        if (nationality === '0') {
          this.companyDocumentType = data.filter(x => x.Value === 'CC')
            .sort((a, b) => (a.OrderNum != null ? a.OrderNum : Infinity) - (b.OrderNum != null ? b.OrderNum : Infinity));
          this.formPersonal.get('DocumentType').setValue('CC');
        } else {
          this.companyDocumentType = data.filter(x => x.Value === 'PE' || x.Value === 'CE' || x.Value === 'PPT')
            .sort((a, b) => (a.OrderNum != null ? a.OrderNum : Infinity) - (b.OrderNum != null ? b.OrderNum : Infinity));
          this.formPersonal.get('DocumentType').setValue('PE');
        }
      });
    }
  }

  selectContractLabor(index: number, startOnInit?) {
    if (startOnInit === true) {
      this.changeSelectedContractLabor(index);
    } else if (index !== this.indexSelect) {
      this.changeSelectedContractLabor(index);
    }
  }

  changeSelectedContractLabor(index) {
      this.indexSelectContracLabor = index;
      this.contractLaborSelected = undefined;

      this.contractLaborSelected = this.contractsLabor[this.indexSelectContracLabor];

      this.formDatosContrato.get('ContractNumber').setValue(this.contractLaborSelected?.ContractNumber);
      this.formDatosContrato.get('EmployerInfoId').setValue(this.contractLaborSelected?.EmployerInfoId);
      this.formDatosContrato.get('ContractTypeId').setValue(this.contractLaborSelected?.ContractTypeId);
      this.formDatosContrato.get('PositionName').setValue(this.contractLaborSelected?.PositionName);
      this.formDatosContrato.get('Salary').setValue(this.contractLaborSelected?.Salary);
      this.formDatosContrato.get('AuxiliaryTransport').setValue(this.contractLaborSelected?.TransportationAllowance);
      this.formDatosContrato.get('TotalEarnings').setValue(this.contractLaborSelected?.TotalEarnings);
      this.formDatosContrato.get('StartDate').setValue(this.contractLaborSelected?.StartDate);
      this.formDatosContrato.get('EndDate').setValue(this.contractLaborSelected?.EndDate);
      this.formDatosContrato.get('BankId').setValue(this.contractLaborSelected?.BankId);
      this.formDatosContrato.get('BankAccount').setValue(this.contractLaborSelected?.BankAccount);
      this.formDatosContrato.get('BankAccountTypeId').setValue(this.contractLaborSelected?.BankAccountTypeId);
      // datos prenomina
      this.formDatosContrato.get('ProjectId').setValue(this.contractLaborSelected?.ProjectId);
      this.formDatosContrato.get('WorkShiftId').setValue(this.contractLaborSelected?.WorkShiftId);
      this.formDatosContrato.get('HasExtraTime').setValue(this.contractLaborSelected?.HasExtraTime);
  }

  getNamePersonalEmployerNameSelected(EmployerId){
    const data = this.allEmployers.find(x => x.Id === EmployerId);
    data !== undefined ? this.employerName = data.Name : '';
  }

  validateIfEmployerContratista(EmployerId, EmployerName): string{
    let is = '';
    const data = this.allEmployers.find(x => x.Id === EmployerId);
    if (data !== undefined) {
      if (EmployerName === data.Name) {
        is = 'Contratista';
      } else {
        is = 'Subcontratista';
      }
    }
    return is;
  }

  getPersonalInfoId(ContractId) {
    this.personalService.getContractPersonalInfoId(ContractId, this.personalId).subscribe(
      (restContractPErsonaInfo: PersonalInfoContract) => {
      this.personalInfo = undefined;
      this.personalInfo = restContractPErsonaInfo;
      this.formVigencia.get('StartDateContract').setValue(this.fechasService.getDateHours(restContractPErsonaInfo.StartDate));
      this.formVigencia.get('FinishDateContract').setValue(this.fechasService.getDateHours(restContractPErsonaInfo.FinishDate));
      this.maxDate = this.personalContractSelected.FinishDateContract;
      this.formVigencia.get('Position').setValue(restContractPErsonaInfo.Position);
      this.formVigencia.get('IsActive').setValue(restContractPErsonaInfo.IsActive);
      this.formVigencia.get('EmployerName').setValue(restContractPErsonaInfo.EmployerName);
      this.formVigencia.get('EmployerItemId').setValue(restContractPErsonaInfo.EmployerId === null ?
        restContractPErsonaInfo.SubContractorId : restContractPErsonaInfo.EmployerId);
      this.formVigencia.get('EmployerId').setValue(restContractPErsonaInfo.EmployerId);
      // Se guarda el primer employer para despues cambiar los requisitos en otro Employer
      this.employerChangeDocument = { EmployerName: restContractPErsonaInfo.EmployerName,
        EmployerId: restContractPErsonaInfo.EmployerId === null ?
          restContractPErsonaInfo.SubContractorId : restContractPErsonaInfo.EmployerId};
      this.formVigencia.get('EmployerLogo').setValue(restContractPErsonaInfo.EmployerLogo);
      // this.formVigencia.get('ContractReview').setValue(restContractPErsonaInfo.ContractReview);
      // this.formVigencia.get('DescriptionPersonalType').setValue(restContractPErsonaInfo.DescriptionPersonalType);
      this.formVigencia.get('EmployerDocumentType').setValue(restContractPErsonaInfo.EmployerDocumentType);
      this.formVigencia.get('EmployerDocumentNumber').setValue(restContractPErsonaInfo.EmployerDocumentNumber);
      this.formVigenciaArlExigida.get('TypeARL').setValue(restContractPErsonaInfo.TypeARL);
    });
  }

  changeBirthDateCalculate() {
    const edad = this.fechasService.calcularEdad(this.formPersonal.get('BirthDate').value);
    this.edadRange = edad >= this.min
      && edad <= this.max;
  }

  generateCarnet() {
    this.dialogService.openDialogConfirm(
      '¿Deseas Generar e imprimir un nuevo carnet? Al imprimir un nuevo carnet, se anulará el anterior').afterClosed()
      .subscribe(rest => {
        if ( rest !== false) { this.dialogService.openDialogPrintPreCarnet('GENERAR', this.personalInfo).afterClosed()
          // tslint:disable-next-line:no-shadowed-variable
        .subscribe( rest => {
          if ( rest === 'GENERADO') { window.location.reload(); }
        });
        }
      });
  }
  printCarnet() {
    this.dialogService.openDialogConfirm('¿Deseas imprimir el carnet?').afterClosed()
      .subscribe(rest => {
        if ( rest !== false) { this.dialogService.openDialogPrintPreCarnet('IMPRIMIR', this.personalInfo); }
      });
  }

  associateContrac(dataModuleRequest?) {
    this.dialogService.openDialogPersonAssociateContracts(this.personalCompanyInfoId, this.personalContractProyect,
      this.infoPersonalCompanyPersonalId.Projects, dataModuleRequest, false, this.personal.CompanyInfoId).afterClosed().subscribe(rest => {
      if (rest === true) {
        this.getContractPersonal();
        this.requestService.setEmployerIdPersonalId(undefined);
      } else {
        this.requestService.setEmployerIdPersonalId(undefined);
      }
    });
  }

  inactiveContrac() {
    this.dialogService.openDialogSelectDateDisabledComponent(
      'Inhabilitar el acceso en todos los contratos').afterClosed().subscribe(rest => {
      if (rest !== false) {
            const firePersonal: FirePersonal =
            {
              personalCompanyInfoId: this.contractsOffline[this.indexSelect].PersonalCompanyInfoId,
              endDate: rest.date };
            // FirePersonal
            this.contratingService.postFirePersonal(firePersonal).subscribe(response => {
              this.personalService.getContractPersonalInfoId(this.contracts[this.indexSelect].ContractId,
                this.personalId).subscribe((restContractPErsonaInfo: PersonalInfoContract) => {
                this.contracts.forEach(contract => {
                  if (contract.IsActive === true) {
                    contract.FinishDate = restContractPErsonaInfo.FinishDate;
                  }
                });
                this.formVigencia.get('FinishDateContract').setValue(this.fechasService.getDateHours(restContractPErsonaInfo.FinishDate));
                this.flagChangeDataBtnSave = false;
              });
            });
          }
    });
  }

  openAcceso() {
    this.dialogService.openDialogAdminAccesoPersonal(this.contractSelected, 'Personal' ).afterClosed().subscribe(rest => {
      if (rest === true) {
        this.getContractsOffline(this.indexSelect);
      }
    });
  }

  openDialogPhotoPersonal() {
    this.dialogService.openDialogGestorImgPersonal('CARGAR IMAGEN', [] , 'editPhoto', 0,
      'true', true).afterClosed().subscribe(formDataPhoto => {
      // rest !== undefined ? this.updatePhotoPersonal(rest) : this.formDataPhoto = this.formDataPhoto;
      if (formDataPhoto !== undefined) {
        this.personalService.updatePhotoCompanyInfo(this.personalCompanyInfoId, formDataPhoto.FormData).subscribe(rest => {
          this.getInfoPersonalCompanyPersonalIdUpdatePhoto();
          this.snackBService.openSnackBar('Foto Actualizada', 'X', 4000);
        });
      }
    });
  }

  openDialogDetailsSurveySymptoms(SurveyId) {
    if (SurveyId !== undefined && SurveyId !== null) {
      this.dialogService.openDialogDetailsSurveySymptoms(SurveyId).afterClosed().subscribe(restDialog => {

      });
    } else {
      this.snackBService.openSnackBar('No tiene reporte de sintomas diario', 'X', 3000);
    }

  }

  getContractNumber(contracts): string {
    let contractsList: string;
    if (contracts.split(']')[1] !== undefined) {
      // tslint:disable-next-line:no-eval
      contractsList = eval(contracts);
    } else {
      contractsList = contracts;
    }
    return contractsList;
  }

  getInfoPersonalCompanyPersonalIdUpdatePhoto() {
    this.personalService.getInfoPersonalCompanyPersonalId(this.personalCompanyInfoId).subscribe(
      (infoPersonalCompanyPersonalId: InfoPersonalCompanyPersonalId) => {
      // Formulario Personal
      this.personal.Photo = infoPersonalCompanyPersonalId.Photo;
      this.formPersonal.get('Photo').setValue(this.personal.Photo);
    });
  }

  changeTypeRiesgo(TypeARL) {
    this.personalInfo.TypeARL = TypeARL;
    if (this.docsUploadIniadoSocialSecurity.length > 0) {
      const exitDocArl = this.docsUploadIniadoSocialSecurity.find(x => (x.TypeARL !== undefined && x.TypeARL !== null && x.TypeARL !== ''));
      if (exitDocArl !== undefined) {
        const value = this.commonMetodService._compareNumberRomans(exitDocArl.TypeARL, TypeARL);
        if (value === '=' || value === '>') {
          this.IsValidedARL = true;
        } else if (value === '<') {
          this.IsValidedARL = false;
        }
      }
    }
  }

  changeDateRequirement(Model, IdDocument, Fecha, TypeARL?) {
    this.flagChangeDataBtnSave = true;
    if (TypeARL !== undefined){
      if (this.personalInfo.TypeARL !== null) {
        const value = this.commonMetodService._compareNumberRomans(TypeARL, this.personalInfo.TypeARL);
        if (value === '=' || value === '>') {
          this.IsValidedARL = true;
        } else if (value === '<') {
          this.IsValidedARL = false;
        }
      }
    }
    let date = null;
    if (Fecha !== undefined && Fecha !== null) {
      date = this.fechasService.getDateFormatDatePiker23(this.fechasService.formatDateYYYYMMDDdatePiker23(Fecha));

    }
    const obj = {
      Date: date,
      Id: IdDocument,
      Model,
      TypeARL
    };
    // Documentos carga iniciado APi
    if (this.docsUploadIniadoSocialSecurity.length === 0) {
      this.docsUploadIniadoSocialSecurity.push(obj);
    } else {
      if (this.exitDocumentDocsUploadIniadoSocialSecurity(IdDocument) === true){
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.docsUploadIniadoSocialSecurity.length; i++) {
          if (IdDocument === this.docsUploadIniadoSocialSecurity[i].Id) {
            if (date !== null && date !== undefined) {
              this.docsUploadIniadoSocialSecurity[i].Date = date;
            } else if (TypeARL !== null && TypeARL !== undefined) {
              this.docsUploadIniadoSocialSecurity[i].TypeARL = TypeARL;
            }
          }
        }
      } else {
        this.docsUploadIniadoSocialSecurity.push(obj);
      }
    }

    if (this.dateSocialSecurityUpload.length === 0) {
      if (obj.Date === null || obj.Date === undefined) {
        const uploaddocument = this.docsUploadIniadoSocialSecurity.find(x => IdDocument === x.Id);
        uploaddocument !== undefined ? obj.Date = uploaddocument.Date : obj.Date = obj.Date;
        this.dateSocialSecurityUpload.push(obj);
      } else if (obj.TypeARL === undefined || obj.TypeARL === null) {
        const uploaddocument = this.docsUploadIniadoSocialSecurity.find(x => IdDocument === x.Id);
        uploaddocument !== undefined ? obj.TypeARL = uploaddocument.TypeARL : obj.TypeARL = obj.TypeARL;
        this.dateSocialSecurityUpload.push(obj);
      } else {
        this.dateSocialSecurityUpload.push(obj);
      }
    } else {
      if (this.exitDocumentDateSocialSecurityUpload(IdDocument) === true){
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.dateSocialSecurityUpload.length; i++) {
          if (IdDocument === this.dateSocialSecurityUpload[i].Id) {
            if (date !== null && date !== undefined) {
              this.dateSocialSecurityUpload[i].Date = date;
            } else if (TypeARL !== null && TypeARL !== undefined) {
              this.dateSocialSecurityUpload[i].TypeARL = TypeARL;
            }
          }
        }
      } else {
        if (obj.Date === null || obj.Date === undefined) {
          const uploaddocument = this.docsUploadIniadoSocialSecurity.find(x => IdDocument === x.Id);
          uploaddocument !== undefined ? obj.Date = uploaddocument.Date : obj.Date = obj.Date;
          this.dateSocialSecurityUpload.push(obj);
        } else if (obj.TypeARL === undefined || obj.TypeARL === null) {
          const uploaddocument = this.docsUploadIniadoSocialSecurity.find(x => IdDocument === x.Id);
          uploaddocument !== undefined ? obj.TypeARL = uploaddocument.TypeARL : obj.TypeARL = obj.TypeARL;
          this.dateSocialSecurityUpload.push(obj);
        } else {
          this.dateSocialSecurityUpload.push(obj);
        }
      }
    }
  }

  changeExpeditionDate(Fecha) {
    let date;
    if (Fecha !== undefined && Fecha !== null) {
      date = this.fechasService.getDateFormatDatePiker(this.fechasService.formatDateYYYYMMDDdatePiker(Fecha));
      this.controlExpeditionDate.setValue(date);
      this.flagChangeDataBtnSave = true;
    }
  }

  changeControlExpeditionPlace() {
    this.flagChangeDataBtnSave = true;
  }


  exitDocument(Id: any): boolean {
    let exit = false;
    const data = this.docsUploadIniadoSocialSecurity.find(x => x.Id === Id);
    if (data !== undefined) {
      exit = true;
    }
    return exit;
  }

  getCalculateStateDate(date, id?): string {
    let response: string;
    response = this.fechasService.calculateStateDate(date, id);
    return response;
  }

  getCalculateStateDateWithHORA(date, id?): string {
    let response: string;
    response = this.fechasService.calculateStateDateWithHORA(date, id);
    return response;
  }

  getCalculateStateDateAndHourDescription(date, id?): string{
    let response = '';
    if (this.fechasService.calculateStateDateWithHORA(date, id) === 'DateRed'){
      response = 'INACTIVO';
    }else if (this.fechasService.calculateStateDateWithHORA(date, id) === 'DateOrange'){
      response = 'POR VENCER';
    }
    return response;
  }

  getIsOutDate(date, id?): boolean{
    let response = false;
    if (this.fechasService.calculateStateDateWithHORA(date, id) === 'DateRed'){
      response = true;
    }else if (this.fechasService.calculateStateDateWithHORA(date, id) === 'DateOrange'){
      response = true;
    }
    return response;
  }

  getCalculateStateDateMore(date, id?): string {
    let response: string;
    response = this.fechasService.calculateStateDateMore(date, id);
    return response;
  }

  getCalculateStateDateMoreLabel(date, id?): string {
    let response = '';
    let stateStyle: string;
    stateStyle = this.fechasService.calculateStateDateMore(date, id);

    if (stateStyle === 'DateRed'){
      response = 'INACTIVO';
    }
    return response;
  }

  getCalculateStateDateString(date, id?): string {
    let response: string;
    response = this.fechasService.calculateStateDate(date, id) + '-string';
    return response;
  }

  getDateDocsUploadIniadoSocialSecurity(Id): string {
    let date: string;
    const newDate = this.docsUploadIniadoSocialSecurity.find(x => x.Id === Id);
    newDate !== undefined && newDate !== null ? date = newDate.Date : date = '';
    return date;
  }

  replaceEPSorAFP(data: ReplaceEPSorAFP) {
    if (this.replaceEPSorAFPdata.length === 0) {
      this.replaceEPSorAFPdata.push(data);
    } else {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.replaceEPSorAFPdata.length; i++) {
        if (this.replaceEPSorAFPdata[i].path === data.path){
          this.replaceEPSorAFPdata[i].value = data.value;
        } else {
          this.replaceEPSorAFPdata.push(data);
        }
      }
    }
    if (data.path === '/EpsId') {
      this.formPersonal.get('EpsId').setValue(data.value);
      this.flagChangeContratista = true;
    } else if (data.path === '/AfpId') {
      this.formPersonal.get('AfpId').setValue(data.value);
      this.flagChangeContratista = true;
    }
  }

  changeEPSAFP(e, type) {
    if (type === 'AFP') {
      if (this.replaceEPSorAFPdata.length === 0) {
        this.replaceEPSorAFPdata.push({path: '/AfpId', value: e});
        this.controAfp.setValue(e);
        this.flagChangeContratista = true;
      } else {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.replaceEPSorAFPdata.length; i++) {
          if (this.replaceEPSorAFPdata[i].path === '/AfpId'){
            this.replaceEPSorAFPdata[i].value = e;
            this.controAfp.setValue(e);
            this.flagChangeContratista = true;
          } else {
            this.replaceEPSorAFPdata.push({path: '/AfpId', value: e});
            this.controAfp.setValue(e);
            this.flagChangeContratista = true;
          }
        }
      }

    } else if (type === 'EPS') {
      if (this.replaceEPSorAFPdata.length === 0) {
        this.replaceEPSorAFPdata.push({path: '/EpsId', value: e});
        this.controEps.setValue(e);
        this.flagChangeContratista = true;
      } else {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.replaceEPSorAFPdata.length; i++) {
          if (this.replaceEPSorAFPdata[i].path === '/EpsId'){
            this.replaceEPSorAFPdata[i].value = e;
            this.controEps.setValue(e);
            this.flagChangeContratista = true;
          } else {
            this.replaceEPSorAFPdata.push({path: '/EpsId', value: e});
            this.controEps.setValue(e);
            this.flagChangeContratista = true;
          }
        }
      }
    }
    this.changeAfterfillFormPersonal = true;
  }

  getDateRequirement(Id): string {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.docsUploadIniadoSocialSecurity.length; i++) {
      if (Id === this.docsUploadIniadoSocialSecurity[i].Id) {
        return this.docsUploadIniadoSocialSecurity[i].Date;
      }
    }
  }

  getResponsePollPersonalCompanyInfoId() {
    this.personalService.getPollPersonalCompanyInfoId(this.personalId).subscribe((rest: any) => {
      if (rest !== null){
        if (rest.length === 0) {
          this.snackBService.openSnackBar('Empleado sin encuesta', 'X', 4000);
        } else {
          this.personalService.getPoll().subscribe((poll: Poll[]) => {
            poll[0].Questions.sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
            rest.sort((a, b) => a.CreateDate < b.CreateDate ? 1 : -1);
            this.dialogService.openDialogPoll(true, poll[0], rest[0].content).afterClosed().subscribe(model => {

            });
          });
        }
      } else {
        this.snackBService.openSnackBar('Empleado sin encuesta', 'X', 4000);
      }
    });
  }

  openPoll() {
    this.personalService.getPoll().subscribe((poll: Poll[]) => {
        poll[0].Questions.sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
      // this.personalService.getPollId(poll.Id).subscribe((restPoll: Poll) => {
        this.dialogService.openDialogPoll(false, poll[0], null).afterClosed().subscribe(model => {
          if (model !== undefined){
            this.personalService.postPollPersonalCompanyInfoId(poll[0].Id, this.personalId, model).subscribe(restPollEncuesta => {
              this.snackBService.openSnackBar('Encuesta guardada correctamente', 'X', 4000);
            });
          }
        });
      // });
    });
  }
  convertirAMayusculas(event: any) {
    const input = event.target.value;
    event.target.value = input.toUpperCase();
  }

  exitPermissionCompany(permiso: string): boolean {
    let exit: boolean;
    const permisionsUserInfo  = this.permissionsCompany.find(x => x === permiso);
    permisionsUserInfo !== undefined ? exit = true : exit = false;
    return exit;
  }

  validatePersonalAlert(): boolean{
    let isValided = false;

    if (this.hasDocuments === true && this.isActive === true){
      isValided = true;
    }

    return isValided;
  }

  subTitlePersonalAlert(): string{
    let message = 'En el contrato ' + this.contracts[this.indexSelect]?.ContractNumber + '';

    if (this.hasDocuments === true && this.isActive === true){
      message += 'cumple los requisitos';
    }else if (this.hasDocuments === false && this.isActive === true){
      message += 'no cumple los requisitos';
    }else if (this.hasDocuments === true && this.isActive === false){
      message += 'esta inactivo';
    }else if (this.hasDocuments === false && this.isActive === false){
      message += 'no cumple los requisitos y esta inactivo';
    }
    return message;
  }

}
