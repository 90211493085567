<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'HORARIO DE TRABAJO' || 'N/A'}}</div>
    </div>
    
    <div class="content-card list-card" autocomplete="off">
        <form [formGroup]="form" *ngIf="Schedule">
            <table >
                <thead>
                    <tr>
                        <td style="text-align: center; border: hidden; font-weight: 600;">Día</td>
                        <td style="text-align: center; border: hidden; font-weight: 600;">Hora inicio</td>
                        <td style="text-align: center; border: hidden; font-weight: 600;">Hora final</td>
                        <td style="text-align: center; border: hidden; font-weight: 600;">Activar</td>
                    </tr>
                </thead>
                <tbody >
                    <tr  *ngFor="let item of horario">
                        <td style="text-align: center;">
                            <div class="Name" style="width:74px;">{{item.Name}}</div>
                            <!-- <mat-icon >close</mat-icon> -->
                        </td>
                        <td style="text-align: center;" class="day-delete">
                            <mat-icon *ngIf="form.get(item.start).value !== null || form.get(item.finisht).value !== null" (click)="clear(item.start, item.finisht)">close</mat-icon>
                            <mat-form-field class="input-field" appearance="outline">
                                <input matInput type="time" formControlName="{{item.start}}" [required]="form.get(item.active).value === true ? true: false">
                            </mat-form-field>
                            <!-- {{item.start}} -->
                        </td>
                        <td style="text-align: center;">
                            <mat-form-field class="input-field" appearance="outline">
                                <input matInput type="time" formControlName="{{item.finisht}}" [required]="form.get(item.active).value === true ? true: false">
                            </mat-form-field>
                            <!-- {{item.finisht}} -->
                        </td>
                        <td style="text-align: center;">
                            <mat-checkbox class="example-margin" formControlName="{{item.active}}"></mat-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>

    <div class="btn-actions"style="margin: 3px;">
        <button mat-button class="btn-cancelar" (click)="close()">{{'CANCELAR'}}</button>&nbsp;&nbsp;
        <button mat-button class="btn-generar" (click)="save()">{{'GUARDAR'}}</button>
    </div>
</div>
