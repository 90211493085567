<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{data.title || 'Sin Title'}}</div>
    </div>
    <div class="content-card list-card" >

        <div class="sub-header" *ngIf="data.url !== ''">
            <div class="sub-content greendAnalityco">{{'Verificar orden de contratación' || 'Sin nombre'}}</div>
        </div>
        <div class="documento" *ngIf="data.url !== ''">
            <div>{{data.name || 'Sin nombre'}}</div>
            <mat-icon (click)="show()">visibility</mat-icon>
        </div>
        <div class="input-field" *ngIf="data.isRechazo">
            <label><strong>COMENTARIO</strong></label>
            <textarea [(ngModel)]="comment" maxlength="280" rows="7"></textarea>
        </div>
        <form [formGroup]="form" autocomplete="off" style="width: 100%;">
            <mat-form-field class="input-field" appearance="outline" *ngIf="this.data.isRetiro !== undefined">
                <label><strong>VALOR REVISADO</strong></label>
                <input matInput #moneySalary formControlName="Severance" style="text-align: right;" >
            </mat-form-field>
        </form>
        <br>
        <div class="actions-dialog"style="margin: 3px;">
            <button mat-button class="btn-cancelar" (click)="onNoClick()" >CANCELAR</button>&nbsp;&nbsp;
            <button mat-button class="btn-generar" (click)="acept()">{{data.aceptText}}</button>
        </div>
    </div>
    <!-- SUB-SUBTITLE 3 ITEMS -->

    <!-- pre-loading -->
    <!-- <div class="content-card list-card pre-content" *ngIf="!personalContractProyect">
        <div class="form-group-list" *ngFor="let item of production.pre_loading">
            <div class="card">
                <div class="relational-info">
                    <div class="desc flex" style="width: 18px;">
                        <div class="icon-pre pre-loading"></div>
                        <div class="title-pre pre-loading"></div>
                    </div>
                    <div class="icon-pre pre-loading"></div>
                </div>
                <div class="card-content">
                    <div class="pre-img pre-loading"></div>
                    <div class="content-information">
                        <div class="text-pre pre-loading"></div>
                        <div class="text-pre pre-loading"></div>
                        <div class="text-pre pre-loading"></div>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div> -->

</div>
