import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CommonService} from '../../../services/modules/common.service';

export interface DialogData {
  confirmar: boolean;
  data?: any;
  question?: string;
  isRequest?: boolean;
  title?: string;
  isFromVehicle?: boolean;
}

@Component({
  selector: 'app-dialog-move-personal-info',
  templateUrl: './dialog-move-personal-info.component.html',
  styleUrls: ['./dialog-move-personal-info.component.scss']
})
export class DialogMovePersonalInfoComponent implements OnInit {

  description: any;
  permissionsCompany: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogMovePersonalInfoComponent>,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    if (this.data.isFromVehicle === true){
      this.description = data.data.error;
    }else{
      this.description = JSON.parse(data.data.error.Error[0]);
    }
  }

  ngOnInit(): void {
    this.commonService.getUserInfo().subscribe(userinfo => {
      //this.permissionsCompany = userinfo.Companies.find(x => x.Id === this.data.CompanyInfoId).Permissions;
    });
  }

  close(){
    this.dialogRef.close(false);
  }

  confirmar() {
    this.dialogRef.close(true);
  }

  exitPermissionCompany(permiso: string): boolean {
    let exit: boolean = false;
    const permisionsUserInfo  = this.permissionsCompany.find(x => x === permiso);
    permisionsUserInfo !== undefined ? exit = true : exit = false;
    return exit;
  }

}
