import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as mapboxgl from 'mapbox-gl';
import * as mapbox from 'mapbox-gl';


import { environment } from 'src/environments/environment';

export interface DialogData {
  address: string;
  GeoFenceJson: string;
}

@Component({
  selector: 'app-dialog-map',
  templateUrl: './dialog-map.component.html',
  styleUrls: ['./dialog-map.component.scss']
})
export class DialogMapComponent implements OnInit {
  map: mapbox.Map;
  style = `mapbox://styles/mapbox/streets-v10`;
  // Coordenadas de la localización donde queremos centrar el mapa
  lat = -74.080833;
  lng = 4.598889;
  zoom = 3;
  GeoFenceJson;

  constructor(
    public dialogRef: MatDialogRef<DialogMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { 
    this.GeoFenceJson =this.data.GeoFenceJson;
  }

  ngOnInit(): void { }

  current_id;
  Draw;
  changeLocations;
  ngAfterViewInit(): void {
      this.map = new mapboxgl.Map({
        accessToken: environment.api.MapboxAccessToken,
        container: 'map-drag',
        style: 'mapbox://styles/mapbox/streets-v10',
        center: [-74.080833, 4.598889],
        zoom: 3
      });
    
      this.Draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
            polygon: true,
            trash: true
        }
      });
      
      this.map.addControl(this.Draw, 'top-right');
    
      this.map.on('load', (event) => {
        // ALL YOUR APPLICATION CODE
        // map.addSource('main', {
        //   type: 'geojson',
        //   data: 
        //   this.GeoFenceJson});
          this.current_id = this.Draw.add({
              type: 'Feature',
              geometry: this.GeoFenceJson,
              properties: {}
          });
          this.map.on('draw.delete', (event) => {
            this.Draw.changeMode(this.Draw.modes.DRAW_POLYGON);
          });

          this.map.on('draw.update', (event) => {
            this.changeLocations = undefined;
            this.changeLocations = event;
          });

          this.map.on('draw.create', (event) => {
            this.changeLocations = undefined;
            this.changeLocations = event;
          });

      });
  
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  save(){   
    if(this.changeLocations !== undefined) {
      let modelUpdate = {
        type: this.changeLocations?.features[0]?.geometry?.type,
        coordinates: this.changeLocations?.features[0]?.geometry?.coordinates,
      }
      this.dialogRef.close(JSON.stringify(modelUpdate));
    }
  }

}
