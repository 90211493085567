<div class="jobs">
    <div class="job-item" *ngFor="let obj of service?.jobs | keyvalue">
        <img src="../../../../assets/images/logo-secundary.png" alt="" width="100" height="105">
        <span class="job-title" *ngIf="obj?.value?.title">
          {{obj?.value?.title || ''}}
        </span>
        <span class="job-description" *ngIf="obj?.value?.description">
          {{obj?.value?.description || ''}}
        </span>
        <div class="sk-chase">
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
        </div>
    </div>

</div>