<div class="jobs">
    <div class="job-item">
        <img src="../../../../assets/images/logo-secundary.png" alt="" width="100" height="105">
        <span class="job-title" >
          {{'' || ''}}
        </span>
        <span class="job-description">
            {{'CARGANDO' || ''}}
        </span>
        <div class="sk-chase">
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
        </div>
    </div>

</div>