<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{data?.title ||     'N/A'}}</div>
    </div>
    <div class="content-card list-card" >
        <form [formGroup]="formRulesPayroll" class="docsCV" autocomplete="off">
            <div class="title-rules">
                Redondeos aplicados a las marcaciones para el HORARIO CALCULO
            </div>
            <div class="content-rules">
                <div class="rule">
                    <div class="desc-rule"><b>Th=</b> Tiempo de holgura para no considerar la llegada tarde o la salida temprano.</div>
                    <div class="input-rule">
                        <!-- <mat-form-field class="input-time" appearance="outline">
                            <input matInput type="time" formControlName="SlackTime">
                        </mat-form-field> -->
                        <div class="selected-horas">
                            <select formControlName="SlackTimeL">
                                <option [value]="t" *ngFor="let t of time">{{t}}</option>
                            </select>:
                            <select formControlName="SlackTimeR">
                                <option [value]="t" *ngFor="let t of time">{{t}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="rule">
                    <div class="desc-rule"><b>Te =</b> Tiempo despues del horario a partir del cual se calcula las horas extras.</div>
                    <div class="input-rule">
                        <!-- <mat-form-field class="input-time" appearance="outline">
                            <input matInput type="time" formControlName="ExtraTime">
                        </mat-form-field> -->
                        <div class="selected-horas">
                            <select formControlName="ExtraTimeL">
                                <option [value]="t" *ngFor="let t of time">{{t}}</option>
                            </select>:
                            <select formControlName="ExtraTimeR">
                                <option [value]="t" *ngFor="let t of time">{{t}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sub-title">
                Entradas con Hora de inicio
            </div>
            <div class="content-rules">
                <div class="rule" style="margin-bottom: 5px">
                    <div class="desc-rule">- Todas las marcaciones <b>{{'{<= Hora inicio}'}}</b> se igualaran a la <b>Hora inicio</b></div>
                    <app-select-search class="select-field-medium-short"
                                [appearance]="'outline'"
                            [_dataSource]="listYesOrNot" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formRulesPayroll.get('SetEarlyEntriesToStartTime')"
                            [inputType]="'text'" (onSelectionChange)="changeIsRoundDownStartHour($event)">
                    </app-select-search>
                </div>
                <div class="rule">
                    <div class="desc-rule">O se redondean al siguiente</div>
                    <div class="input-rule">
                        <app-select-search class="select-field-medium-short"
                                [appearance]="'outline'"
                            [_dataSource]="listHora" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formRulesPayroll.get('RoundUpEarlyEntries')"
                            [inputType]="'text'" [disabled]="formRulesPayroll.get('SetEarlyEntriesToStartTime').value === true ? true: false">
                        </app-select-search>
                    </div>
                </div>
                <div class="rule">
                    <div class="desc-rule">- Todas las marcaciones <b>{{'{> Hora inicio}'}} y {{'{<= Hora inicio +Th}'}}</b> se igualaran a la <b>Hora inicio</b></div>
                    <div class="input-rule">
                        <app-select-search class="select-field-medium-short"
                                [appearance]="'outline'"
                            [_dataSource]="listYesOrNot" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formRulesPayroll.get('IsRoundDownStartHourSlackTime')"
                            [inputType]="'text'">
                        </app-select-search>
                    </div>
                </div>
                <div class="rule">
                    <div class="desc-rule">- Todas las marcaciones <b>{{'{> Hora inicio +Th}'}}</b> se redondea al siguiente</div>
                    <div class="input-rule">
                        <app-select-search class="select-field-medium-short"
                                [appearance]="'outline'"
                            [_dataSource]="listHora" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formRulesPayroll.get('RoundUpStartHourSlackTime')"
                            [inputType]="'text'">
                        </app-select-search>
                    </div>
                </div>
            </div>
            <div class="sub-title">
                Salidas con Hora fin
            </div>
            <div class="content-rules">
                <div class="rule">
                    <div class="desc-rule">- Todas las marcaciones <b>{{'{>= Hora fin - Th} y {<= Hora fin}'}}</b> se igualaran a la <b>Hora fin</b></div>
                    <div class="input-rule">
                        <app-select-search class="select-field-medium-short"
                                [appearance]="'outline'"
                            [_dataSource]="listYesOrNot" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formRulesPayroll.get('IsRoundDownEndHourSlackTime')"
                            [inputType]="'text'">
                        </app-select-search>
                    </div>
                </div>
                <div class="rule">
                    <div class="desc-rule">- Todas las marcaciones <b>{{'{> Hora fin} y {<= Hora fin + Te}'}}</b> se igualaran a la <b>Hora fin</b></div>
                    <div class="input-rule">
                        <app-select-search class="select-field-medium-short"
                                [appearance]="'outline'"
                            [_dataSource]="listYesOrNot" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formRulesPayroll.get('SetLateLeavesToLeaveTime')"
                            [inputType]="'text'">
                        </app-select-search>
                    </div>
                </div>
                <div class="rule">
                    <div class="desc-rule">- Todas las marcaciones <b>{{'{< Hora fin -Th}'}}</b> se redondea al anterior</div>
                    <div class="input-rule">
                        <app-select-search class="select-field-medium-short"
                            [appearance]="'outline'"
                            [_dataSource]="listHora" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formRulesPayroll.get('RoundUpStartEndSlackTime')"
                            [inputType]="'text'">
                        </app-select-search>
                    </div>
                </div>
                <div class="rule">
                    <div class="desc-rule">- Todas las marcaciones <b>{{'{> Hora fin +Te}'}}</b>:resta =</div>
                    <div class="input-rule-other">
                        <!-- <mat-form-field class="input-time" appearance="outline">
                            <input matInput type="time" formControlName="ExtraTimeDiscount">
                        </mat-form-field> -->
                        <div class="selected-horas">
                            <select formControlName="ExtraTimeDiscountL">
                                <option [value]="t" *ngFor="let t of time">{{t}}</option>
                            </select>:
                            <select formControlName="ExtraTimeDiscountR">
                                <option [value]="t" *ngFor="let t of time">{{t}}</option>
                            </select>
                        </div>&nbsp;    
                        y redondear al anterior
                        <app-select-search class="select-field-medium-short"
                            [appearance]="'outline'"
                            [_dataSource]="listHora" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formRulesPayroll.get('RoundUpExtraTime')"
                            [inputType]="'text'">
                        </app-select-search>
                    </div>  
                </div>
            </div>
            <div class="sub-title">
                Entradas sin Hora de inicio
            </div>
            <div class="content-rules">
                <div class="rule">
                    <div class="desc-rule">- Todas las marcaciones se redondea al siguiente</div>
                    <div class="input-rule">
                        <app-select-search class="select-field-medium-short"
                            [appearance]="'outline'"
                            [_dataSource]="listHora" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formRulesPayroll.get('RoundUpTo')"
                            [inputType]="'text'">
                        </app-select-search>
                    </div>
                </div>
            </div>
            <div class="sub-title">
                Salida sin Hora fin
            </div>
            <div class="content-rules">
                <div class="rule">
                    <div class="desc-rule">- Todas las marcaciones :resta =</div>
                    <div class="input-rule-other">
                        <!-- <mat-form-field class="input-time" appearance="outline">
                            <input matInput type="time" formControlName="ExtraTimeDiscount">
                        </mat-form-field> -->
                        <div class="selected-horas">
                            <select formControlName="DiscountL">
                                <option [value]="t" *ngFor="let t of time">{{t}}</option>
                            </select>:
                            <select formControlName="DiscountR">
                                <option [value]="t" *ngFor="let t of time">{{t}}</option>
                            </select>
                        </div>&nbsp;    
                        y redondear al anterior
                        <app-select-search class="select-field-medium-short"
                            [appearance]="'outline'"
                            [_dataSource]="listHora" [textInput]="''" [dataShow]="'Name'" [dataValue]="'Id'" [siteCtrl]="formRulesPayroll.get('RoundDownTo')"
                            [inputType]="'text'">
                        </app-select-search>
                    </div>  
                </div>
            </div>
            <!-- <div class="title-rules">
                Configuración del tiempo de descanzo
            </div>
            <div class="content-rules">
                <div class="rule">
                    <div class="desc-rule"><b>Th=</b> Tiempo de holgura para no considerar la llegada tarde o la salida temprano.</div>
                    <div class="input-rule">
                        <div class="selected-horas">
                            <select formControlName="BreakTimeL">
                                <option [value]="t" *ngFor="let t of time">{{t}}</option>
                            </select>:
                            <select formControlName="BreakTimeR">
                                <option [value]="t" *ngFor="let t of time">{{t}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> -->
        </form>
        <br>
        <div class="actions-dialog"style="margin: 3px;">
            <button mat-button class="btn-cancelar" (click)="onNoClick()">CANCELAR</button>&nbsp;&nbsp;
            <button mat-button class="btn-generar" (click)="save()">{{this.data.payrollConfiguration !== undefined ? 'ACTUALIZAR': 'GUARDAR'}}</button>
        </div>
    </div>
</div>