import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import * as mapbox from 'mapbox-gl';

import { environment } from 'src/environments/environment';

export interface GeoFenceJson {
  coordinates: [];
  type: string;
}

@Component({
  selector: 'app-mapbox-view',
  templateUrl: './mapbox-view.component.html',
  styleUrls: ['./mapbox-view.component.scss']
})
export class MapboxViewComponent implements OnInit {
  geoFenceJson;
  map: mapbox.Map;
  mapEdit: mapbox.Map;
  mapCreateMap: mapbox.Map;

  City = '';
  Type = '';
  Address = '';

  @Input() set _geoFenceJson(value: any) {
    if (value !== undefined && value !== '') {
      this.geoFenceJson = value;
      if (this.Type === 'create') {
        this.mapCreate();
      }
    }
  }
  @Input() permissionEdit = false;
  @Input() set _Address(value: any) {
    if (value !== undefined && value !== '') {
      this.Address = value;
    }
  }
  @Input() set _City(value: any) {
    if (value !== undefined && value !== '') {
      this.City = value;
    }
  }
  @Input() set _Type(value: any) {
    if (value !== undefined && value !== '') {
      this.Type = value;
    }
  }
  @Output() clickOpcion1 = new EventEmitter();

  constructor() {}

  ngAfterViewInit(): void {
    if (this.Type === 'create') {
      this.mapCreate();
    } else {
      this.mapShow();
      this.mapEditShow();
    }
  }

  ngOnInit(): void {}

  clickOpc1() {
    this.clickOpcion1.emit();
  }

  // Función para eliminar una fuente y sus capas asociadas antes de agregarla nuevamente.
  private removeSourceAndLayer(map: mapbox.Map, sourceId: string, layerId: string) {
    if (map.getSource(sourceId)) {
      console.log(`Eliminando fuente existente: ${sourceId}`);
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
      if (map.getLayer(`${layerId}-outline`)) {
        map.removeLayer(`${layerId}-outline`);
      }
      map.removeSource(sourceId);
    }
  }

  mapShow() {
    this.map = new mapboxgl.Map({
      accessToken: environment.api.MapboxAccessToken,
      center: [-74.080833, 4.598889],
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v10',
      zoom: 3
    });

    this.map.on('load', () => {
      // Eliminamos la fuente y la capa si ya existen para evitar duplicados.
      this.removeSourceAndLayer(this.map, 'main', 'main');

      // Add a data source containing GeoJSON data.
      this.map.addSource('main', {
        type: 'geojson',
        data: this.geoFenceJson
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: 'main',
        type: 'fill',
        source: 'main', // reference the data source
        layout: {},
        paint: {
          'fill-color': '#0080ff', // blue color fill
          'fill-opacity': 0.5
        }
      });

      // Add a black outline around the polygon.
      this.map.addLayer({
        id: 'main-outline',
        type: 'line',
        source: 'main',
        layout: {},
        paint: {
          'line-color': '#5c5c5c',
          'line-width': 1
        }
      });
    });
  }

  mapEditShow() {
    this.mapEdit = new mapboxgl.Map({
      accessToken: environment.api.MapboxAccessToken,
      center: [-74.080833, 4.598889],
      container: 'mapEdit',
      style: 'mapbox://styles/mapbox/streets-v10',
      zoom: 3
    });

    this.mapEdit.on('load', () => {
      // Eliminamos la fuente y la capa si ya existen para evitar duplicados.
      this.removeSourceAndLayer(this.mapEdit, 'mainEdit', 'mainEdit');

      // Add a data source containing GeoJSON data.
      this.mapEdit.addSource('mainEdit', {
        type: 'geojson',
        data: this.geoFenceJson
      });

      // Add a new layer to visualize the polygon.
      this.mapEdit.addLayer({
        id: 'mainEdit',
        type: 'fill',
        source: 'mainEdit', // reference the data source
        layout: {},
        paint: {
          'fill-color': '#0080ff', // blue color fill
          'fill-opacity': 0.5
        }
      });

      // Add a black outline around the polygon.
      this.mapEdit.addLayer({
        id: 'mainEdit-outline',
        type: 'line',
        source: 'mainEdit',
        layout: {},
        paint: {
          'line-color': '#5c5c5c',
          'line-width': 1
        }
      });
    });
  }

  mapCreate() {
    this.mapCreateMap = new mapboxgl.Map({
      accessToken: environment.api.MapboxAccessToken,
      center: [-74.1574237, 4.6455668],
      container: 'mapCreate',
      style: 'mapbox://styles/mapbox/streets-v10',
      zoom: 3
    });

    this.mapCreateMap.on('load', () => {
      // Eliminamos la fuente y la capa si ya existen para evitar duplicados.
      this.removeSourceAndLayer(this.mapCreateMap, 'mapCreate', 'mapCreate');

      // Add a data source containing GeoJSON data.
      this.mapCreateMap.addSource('mapCreate', {
        type: 'geojson',
        data: this.geoFenceJson
      });

      // Add a new layer to visualize the polygon.
      this.mapCreateMap.addLayer({
        id: 'mapCreate',
        type: 'fill',
        source: 'mapCreate', // reference the data source
        layout: {},
        paint: {
          'fill-color': '#0080ff', // blue color fill
          'fill-opacity': 0.5
        }
      });

      // Add a black outline around the polygon.
      this.mapCreateMap.addLayer({
        id: 'mapCreate-outline',
        type: 'line',
        source: 'mapCreate',
        layout: {},
        paint: {
          'line-color': '#5c5c5c',
          'line-width': 1
        }
      });
    });
  }
}
