<div class="box">
    <title-dialog [title]="data.title || 'ARCHIVOS Y COMENTARIOS'" [color]="'greendAnalityco'"  [width]="'360'"></title-dialog>
    <div class="content-card">
        <div class="content-show" style="padding: 10px 12px 0px 12px;">
            <div *ngIf="this.data?.docs?.Id !== undefined" class="desc-grey">{{this.data?.docs?.RequirementName || ''}}</div>
            <div *ngIf="this.data?.docs?.Id === undefined" class="desc-grey">{{this.data?.docs?.Name || ''}}</div>
            <div class="subtitle" *ngIf="exitPermissionView() && exitPermission(this.permissionsModule.permissions.upload) && stateString !== 'APROBADO'">ACTUALIZAR</div>
               <div *ngIf="!data.isFromPersonal">
                 <ngx-dropzone *ngIf="exitPermissionView() && exitPermission(this.permissionsModule.permissions.upload) && stateString !== 'APROBADO' && files.length === 0" (change)="onSelect($event)" class="dropzone" [multiple]="false">
                   <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
                 </ngx-dropzone>
               </div>
               <div *ngIf="data.isFromPersonal">
                 <ngx-dropzone *ngIf="exitPermissionView() && exitPermissionCompany('documentinfopersonal.upload') && stateString !== 'APROBADO' && files.length === 0" (change)="onSelect($event)" class="dropzone" [multiple]="false">
                   <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
                 </ngx-dropzone>
               </div>
            <div class="list">
                <div class="item" *ngFor="let file of files; let i = index">
                    <div *ngIf="file" class="itemFile">{{file.name || 'SIN NOMBRE'}}</div>
                    <div class="red" *ngIf="!file">{{'Archivo Removido'}}</div>
                    <mat-icon *ngIf="file" (click)="removeFile(i)" style="cursor: pointer;">highlight_off</mat-icon>
                </div>
                <div class="documents" *ngFor="let doc of documentos; let i = index">
                    <div>
                        <div class="document-doc">
                            <div>Documento:&nbsp;</div>
                            <div class="isDocument" [matTooltip]="doc?.nameFile || ''">{{doc?.nameFile}}</div>
                        </div>
                        <div class="state" style="color: #a0a0a0 !important;">
                            <div class="state-description" style="min-width: 80px; width: 80px;">
                                <div>Cargado el</div>
                            </div>
                            <div class="state-description">
                                <div>{{doc?.date || 'Fecha no reportada' }}</div>
                            </div>
                            <div class="state-description">
                                <div>Por:</div>
                                <mat-icon
                                    matTooltipPosition="above"
                                    [matTooltip]="'Usuario: ' + (doc?.user !== undefined ? doc?.user : 'No reportado')"
                                    >account_circle</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!doc" [ngClass]="!doc ? 'red': ''">{{'Doc. Removido'}}</div>
                    <div class="operators">
                        <mat-icon class="mat-icon-delete" *ngIf="(data.typeOperator === 'delete' || data.typeOperator === 'showDelete' || (data.typeOperator === 'showUpdatePlanilla' && isEqualsUserRemoveFile(doc))) && doc" (click)="jsonUpdateDeleteDoc(doc, i)">delete</mat-icon>
                    </div>
                </div>
                <div class="item" *ngIf="data.docs.length === 0 && !data.typeGestor">{{'0 DOCUMETOS GUARDADOS'}}</div>
            </div>
              <div *ngIf="!data.isFromPersonal">
                <form [formGroup]="formFile" *ngIf="exitPermissionView() && exitPermission(this.permissionsModule.permissions.uploadFile) && stateString !== 'APROBADO' && (stateCurrent?.ByDate === true || stateCurrent?.RequirementByDate === true)">
                  <div class="datepikerInput" [ngClass]="getCalculateStateDateWithHORA((formFile.get('Date').value))">
                    <div class="getNameDay">NUEVO VENCIMIENTO</div>
                    <mat-form-field class="input-field-medium"  appearance="outline">
                      <input matInput [matDatepicker]="pickerFilter" #inputDateFilter (click)="pickerFilter.open()" formControlName="Date"
                             [readonly]="true" [min]="minDate">
                      <mat-datepicker #pickerFilter></mat-datepicker>
                      <mat-error>Requerido</mat-error>
                    </mat-form-field>
                  </div>
                </form>
              </div>
              <div *ngIf="data.isFromPersonal">
                <form [formGroup]="formFile" *ngIf="exitPermissionView() && exitPermissionCompany('documentinfopersonal.updatedate') && stateString !== 'APROBADO' && (stateCurrent?.ByDate === true || stateCurrent?.RequirementByDate === true)">
                  <div class="datepikerInput" [ngClass]="getCalculateStateDateWithHORA((formFile.get('Date').value))">
                    <div class="getNameDay">NUEVO VENCIMIENTO</div>
                    <mat-form-field class="input-field-medium"  appearance="outline">
                      <input matInput [matDatepicker]="pickerFilter" #inputDateFilter (click)="pickerFilter.open()" formControlName="Date"
                             [readonly]="true" [min]="minDate">
                      <mat-datepicker #pickerFilter></mat-datepicker>
                      <mat-error>Requerido</mat-error>
                    </mat-form-field>
                  </div>
                </form>
              </div>
          <div *ngIf="!data.isFromPersonal">
              <mat-form-field *ngIf="exitPermissionView() && exitPermission(this.permissionsModule.permissions.upload) && stateString !== 'APROBADO'" class="input-field">
                  <label><div>COMENTARIO</div></label>
                  <textarea matInput [(ngModel)]="commentary" maxlength="280" rows="5"></textarea>
                  <mat-error>Requerido</mat-error>
              </mat-form-field>
          </div>
          <div *ngIf="data.isFromPersonal">
            <mat-form-field *ngIf="exitPermissionView() && exitPermissionCompany('documentinfopersonal.upload')  && stateString !== 'APROBADO'" class="input-field">
              <label><div>COMENTARIO</div></label>
              <textarea matInput [(ngModel)]="commentary" maxlength="280" rows="5"></textarea>
              <mat-error>Requerido</mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- ESTADO ACTUAL  -->
        <!-- <div class="estadoActtual">Estado: </div> -->
        <div class="subtitle" *ngIf="this.data?.docs?.Id !== undefined" style="margin: 0px 12px 0px 12px;">ESTADO</div>
        <div class="state" style="color: #a0a0a0 !important;" *ngIf="this.data?.docs?.Id !== undefined">
            <div class="state-content">
                <div class="itemState"><div [ngClass]="stateCurrent?.StateDescription">{{stateCurrent?.StateDescription || ''}}</div>&nbsp;&nbsp;el:</div>
                <div class="itemState">{{getDateFormatR(stateCurrent?.UpdateDate) || 'Fecha no reportada' }} {{getDateFormatRMinutes(stateCurrent?.UpdateDate) || '' }} </div>
                <div class="itemStateIcon"> <div>Por:</div> <mat-icon matTooltipPosition="above" [matTooltip]="'Usuario: ' + (stateCurrent?.HistUserName !== undefined ? stateCurrent?.HistUserName : 'No reportado')">account_circle</mat-icon></div>
            </div>
            <div class="state-document" *ngIf="stateCurrent?.FileName !== '' && stateCurrent?.FileName !== null">
                <div class="itemState"><div>Documento:</div></div>
                <div class="itemState-nameFile" matTooltipPosition="above" [matTooltip]="stateCurrent.FileName">{{stateCurrent.FileName}}</div>
                <div class="itemStateIcon"><mat-icon class="iconView" (click)="openImgVideoUrl(stateCurrent.FilePath)">visibility</mat-icon></div>
            </div>
            <div class="state-date" *ngIf="stateCurrent.DocumentDate !== '' && stateCurrent.DocumentDate !== null">
                <div class="itemState" ><div>Vencimiento:</div></div>
                <div class="itemState">{{getDateFormatR(stateCurrent?.DocumentDate) || 'Fecha no reportada' }}</div>
                <div class="itemStateIcon"></div>
            </div>
            <div class="state-comment" *ngIf="stateCurrent.Comments !== '' && stateCurrent.Comments !== null">
                <div class="itemState-comment"><div>Comentario:</div></div>
                <div class="itemStateComment">{{stateCurrent.Comments}} </div>
                <!-- <div class="itemStateIcon"></div> -->
            </div>
        </div>
        <!-- ESTADO FIN -->
        <div class="actions-dialog" *ngIf="state === 'SOL'" style="padding: 0px 12px 0px 12px;">
             <div *ngIf="!data.isFromPersonal">
               <button mat-button class="btn-generar-rechazar" *ngIf="exitPermission(this.permissionsModule.permissions.approval)" (click)="rechazar()">RECHAZAR</button>&nbsp;&nbsp;
               <button mat-button class="btn-generar-aprobar"  *ngIf="exitPermission(this.permissionsModule.permissions.approval)" (click)="aprobar()">APROBAR</button>
             </div>
             <div *ngIf="data.isFromPersonal">
                <button mat-button class="btn-generar-rechazar" *ngIf="exitPermissionCompany('documentinfopersonal.approval')" (click)="rechazar()">RECHAZAR</button>&nbsp;&nbsp;
                <button mat-button class="btn-generar-aprobar"  *ngIf="exitPermissionCompany('documentinfopersonal.approval')" (click)="aprobar()">APROBAR</button>
             </div>
        </div>
        <div class="actions-dialog border-bottom-actions" style="padding: 0px 12px 8px 12px;" *ngIf="!data.typeGestor">
            <button mat-button class="btn-cancelar" (click)="onNoClick()" >{{(data.typeOperator === 'show')? 'CERRAR': 'CANCELAR'}}</button>&nbsp;&nbsp;
          <div *ngIf="!data.isFromPersonal">
            <button mat-button *ngIf="exitPermission(this.permissionsModule.permissions.requestapproval) && (state === 'REC' || state === 'INI' || state === undefined || state === null) && this.files.length !== 0" class="btn-generar-request" (click)="solicitar()">SOLICITAR APROBACIÓN</button>
            <button mat-button *ngIf="(state === 'REC' || state === 'INI' || state === undefined || state === null) && this.files.length === 0" class="btn-generar-request-disabled">SOLICITAR APROBACIÓN</button>
          </div>
          <div *ngIf="data.isFromPersonal">
            <button mat-button *ngIf="exitPermissionCompany('documentinfopersonal.requestapproval') && (state === 'REC' || state === 'INI' || state === undefined || state === null) && this.files.length !== 0" class="btn-generar-request" (click)="solicitar()">SOLICITAR APROBACIÓN</button>
            <button mat-button *ngIf="(state === 'REC' || state === 'INI' || state === undefined || state === null) && this.files.length === 0" class="btn-generar-request-disabled">SOLICITAR APROBACIÓN</button>
          </div>
        </div>
        <div *ngIf="this.data?.docs?.Id !== undefined" >
            <div class="subtitle">ACTUALIZACIONES</div>
            <div class="history-scroll" style="width: 100%; padding: 0px 0px 0px 12px;">
                <div class="history" *ngFor="let story of stories; let i = index">
                    <div class="state" style="color: #a0a0a0 !important;">
                        <div class="state-content">
                            <div class="itemState">{{story.StateDescription}} el:</div>
                            <div class="itemState">{{getDateFormatR(story?.UpdateDate) || 'Fecha no reportada' }} {{getDateFormatRMinutes(story?.UpdateDate) || '' }} </div>
                            <div class="itemStateIcon"> <div>Por:</div> <mat-icon matTooltipPosition="above" [matTooltip]="'Usuario: ' + (story?.HistUserName !== undefined ? story?.HistUserName : 'No reportado')">account_circle</mat-icon></div>
                        </div>
                        <div class="state-document" *ngIf="story?.FileName !== '' && story?.FileName !== null">
                            <div class="itemState"><div>Documento:</div></div>
                            <div class="itemState-nameFile" matTooltipPosition="above" [matTooltip]="stateCurrent.Name">{{story.FileName}}</div>
                            <div class="itemStateIcon"><mat-icon class="iconView" (click)="openImgVideoUrl(story.FilePath)">visibility</mat-icon></div>
                        </div>
                        <div class="state-date" *ngIf="story?.DocumentDate !== '' && story?.DocumentDate !== null">
                            <div class="itemState"><div>Vencimiento:</div></div>
                            <div class="itemState">{{getDateFormatR(story?.DocumentDate) || 'Fecha no reportada' }}</div>
                            <div class="itemStateIcon"></div>
                        </div>
                        <div class="state-comment" *ngIf="story?.Comments !== '' && story?.Comments !== null">
                            <div class="itemState-comment"><div>Comentario:</div></div>
                            <div class="itemStateComment">{{story?.Comments}} </div>
                            <!-- <div class="itemStateIcon"></div> -->
                        </div>
                    </div>
                </div>
            </div>
            <app-title-card-preview
                                    *ngIf="stories.length === 0"
                                    style="width: 100%;"
                                    [icon]="'help'"
                                    [text]="'Aqui se visualiza el historial'"
                                    [textTooltip]="'Aqui se visualiza el historial'"
            ></app-title-card-preview>
        </div>
    </div>
</div>
