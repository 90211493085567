<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'REPORTE DE SÍNTOMAS' || 'N/A'}}</div>
    </div>
    
    <div class="content-card list-card" *ngIf="detailsSurveySymptoms">
        <b>Hoy presento este sintoma</b>
        <div *ngFor="let Symptom of detailsSurveySymptoms.Symptoms" class="list">
            <div class="text">{{Symptom}}</div>
            <mat-icon class="icon">check_circle</mat-icon>
        </div>      
        <b>Contacto con:</b>
        <div class="list">
            <div class="text">{{'Ha tenido contacto con alguna persona con los síntomas anteriores.'}}</div>
            <div class="text">{{detailsSurveySymptoms.OtherPersonHasSymptoms === true ? 'SI': 'NO'}}</div>
        </div>        
        <div  class="list">
            <div class="text">{{'Ha tenido contacto con alguna persona sospechosa o diagnosticada de COVID-19.'}}</div>
            <div class="text">{{detailsSurveySymptoms.OtherPersonHasCOVID19 === true ? 'SI': 'NO'}}</div>
        </div>        
        <b>Medio de transporte:</b>
        <div *ngFor="let transp of detailsSurveySymptoms.Transport" class="list">
            <div class="text">{{transp}}</div>
            <mat-icon class="icon">check_circle</mat-icon>
        </div>     
        
        <div class="list">
            <b class="orange" *ngIf="detailsSurveySymptoms.OrangeResponse.length > 0">ESPERA EN EL INGRESO DE TU SITIO DE TRABAJO, VAMOS A INVESTIGAR MAS TU CASO</b>
            <b class="red" *ngIf="detailsSurveySymptoms.RedResponse.length > 0">QUEDATE EN CASA, INFORMA A TU JEFE INMEDIATO Y LLAMA A TU EPS</b>
        </div>
           
        <div class="actions-dialog"style="margin: 3px;">
            <button mat-button class="btn-cancelar" [mat-dialog-close]="data" >CANCELAR</button>
            <!-- <button (click)="_getContractNumber()">Hola</button> -->
        </div>
    </div>
   
    
</div>