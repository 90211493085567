import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonMetodService } from 'src/app/core/services/utils/commonMetod.service';
import { PersonalService } from 'src/app/core/services/modules/personal.service';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';

export interface DialogData {
  EmployerInfoId: any;
}

@Component({
  selector: 'app-dialog-add-personal-social-segurity',
  templateUrl: './dialog-add-personal-social-segurity.component.html',
  styleUrls: ['./dialog-add-personal-social-segurity.component.scss']
})
export class DialogAddPersonalSocialSegurityComponent implements OnInit {

  s3Bucket:string;
  formPersonal: FormGroup;
  personals: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogAddPersonalSocialSegurityComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private personalService: PersonalService,
    private snackBarService: SnackBarService
  ) {
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    this.formPersonal = this.fb.group({
      searchPersonal: new FormControl('',Validators.required),
    });
  }


  removeItemPersonalSelected(item: any): void {
    const index = this.personals.indexOf(item);
    for (let i = 0; i < this.personals.length; i++) {
      if(this.personals[i] === item) {
        this.personals.splice(index, 1);
      }
    }
  }

  searchPersonalFilter() {
    if(this.formPersonal.get('searchPersonal').value !== '' && this.exitPersonalSearch() === false) {
      this.personalService.getSocialSecurityPersonalUnlimited(this.data.EmployerInfoId, this.formPersonal.get('searchPersonal').value).subscribe((rest: any) => {
        if(rest !== undefined) {
          if(rest.length !== 0) {
            this.personals.push(rest[0]);
            this.formPersonal.get('searchPersonal').setValue(''); 
          }
        }
      }, error => {
        this.snackBarService.openSnackBar('VALIDAR INFORMACIÓN', '',3000);
      });
    } else {
      this.snackBarService.openSnackBar('DIGITAR ID O CEDULA DE LA PERSONA', '',3000);
    }
  }

  getImg(logo) {
    let img = (logo === null || logo === '' || logo === undefined ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg': logo);
    return img;
  }

  getImgEmp(logo) {
    let img = (logo === null || logo === '' || logo === undefined ? 'assets/images/personblue.png': logo);
    return img;
  }

  exitPersonalSearch(): boolean {
    let exit = false;
    let data = this.personals.find(x => x.DocumentNumber.toString() === this.formPersonal.get('searchPersonal').value.toString());
    data !== undefined ? exit = true : exit = false;
    return exit;
  }

  close() {
    this.dialogRef.close();
  }

  add() {
    if(this.personals.length !== 0) {
      this.dialogRef.close(this.personals);
    }
  }

}

