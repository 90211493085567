<div class="box" (keyup.enter)="search()">
  <div class="header">
    <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'Buscar Administradora' || 'N/A'}}</div>
  </div>
  <div class="content-card list-card" >

    <div class="card" *ngFor="let employer of employerSelected">
      <div class="card-content">
        <img src="{{s3Bucket+getImg(employer.Logo)}}" alt="">
        <div class="content-information">
          <div class="ellipsis" style="max-width: 200px;">{{employer.Name || ''}} {{employer.LastName || ''}}</div>
          <div class="ellipsis baseline">{{employer.DocumentType}}: {{employer.DocumentNumber || ''}}</div>
        </div>
      </div>
      <mat-icon class="icon-close" (click)="setValuePersonal()">close</mat-icon>
    </div>
    <div class="fields-group">
      <mat-form-field class="input-field-medium" appearance="fill" >
        <mat-label>TIPO</mat-label>
        <mat-select [(ngModel)]="documentType" (selectionChange)="cahngeMaxCaracter($event)">
          <mat-option *ngFor="let data of companyDocumentType" [value]="data.Value">
            {{data.Value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="input-field-100" appearance="fill">
        <mat-label>NÚMERO</mat-label>
        <input matInput type="text" [(ngModel)]="documentNumber"  maxlength="{{maxCaracter}}" (ngModelChange)="validateNit()">
      </mat-form-field>
      <div *ngIf="documentType === 'NIT'" class="nitSeparator">-</div>
      <mat-form-field class="input-field-short" *ngIf="documentType === 'NIT'" appearance="fill">
        <input matInput type="text" [(ngModel)]="digito" maxlength="1" (ngModelChange)="validateNit()">
      </mat-form-field>
    </div>
    <div class="NITinvalido"  *ngIf="documentType === 'NIT' && flagValidNit === false">
      NIT invalido
    </div>
    <br>
    <div class="actions-dialog"style="margin: 3px;">
      <button mat-button class="btn-cancelar" [mat-dialog-close]="data" >CANCELAR</button>&nbsp;&nbsp;
      <button mat-button
              (click)="search()"
              class="btn-generar">BUSCAR
      </button>
    </div>
  </div>
</div>

