import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, MinLengthValidator, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UpdatePassword } from 'src/app/core/interfaces/interfaces';
import { UserInfo } from 'src/app/core/models/User';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { UserService } from 'src/app/core/services/modules/users.service';
import { DialogService } from 'src/app/core/services/utils/dialog.service';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';

function equalsValidator(otherControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const value: any = control.value;
    const otherValue: any = otherControl.value;
    return otherValue === value ? null : { 'notEquals': { value, otherValue } };
  };
}

export const CustomValidators = {
  equals: equalsValidator
};

export interface DialogData {
  userChangeData: any;
  UpdatePasswordAnonymous: any;
}

@Component({
  selector: 'app-dialog-update-password',
  templateUrl: './dialog-update-password.component.html',
  styleUrls: ['./dialog-update-password.component.scss']
})
export class DialogUpdatePasswordComponent implements OnInit {
  formUpdate: FormGroup;
  user_validation_messages: any = [];
  userChangeData;
  UpdatePasswordAnonymous;

  password = true;
  ConfirmPassword = true;
  LastPassword = true;

  constructor(
    public dialogRef: MatDialogRef<DialogUpdatePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private userService: UserService,
    private snackBService: SnackBarService
  ) {
    this.userChangeData = data.userChangeData;
    this.UpdatePasswordAnonymous = data.UpdatePasswordAnonymous;
  }

  ngOnInit(): void {
    this.user_validation_messages = {
      'password': [
          { type: 'required', message: 'Requerido' },
          { type: 'pattern', message: 'La contraseña debe tener letras en mayusculas (A-Z), minusculas (a-z), números (0-9) y caracteres especiales (*#,%.) no puede utilizar 3 caracteres consecutivos (123 0 abc) no se puede repetir 3 caracteres iguales (444 o ddd) mínimo 8 caracteres de longitud.' }
      ],
    }
    this.formUpdate = this.fb.group({
      LastPassword:  new FormControl('',this.userChangeData === undefined ? Validators.required: null),
      Password:  new FormControl('',Validators.compose([
        Validators.required,
        Validators.pattern(/(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/)
      ])),
      ConfirmPassword:  new FormControl('',Validators.required),
    });
    this.formUpdate.get('ConfirmPassword').setValidators(
      CustomValidators.equals(this.formUpdate.get('Password'))
    );
  }


  close(){
    this.dialogRef.close(false);
  }

  passwordsEquals():  boolean  {
    return this.formUpdate.get('ConfirmPassword').getError('notEquals');
  }

  lastPasswordIncorrect():  boolean  {
    return this.formUpdate.get('LastPassword').getError('incorrect');
  }

  validatePasswordEquals = false;
  passwordsEqualsLast():  boolean  {
    let x = false;
    if(this.formUpdate.get('Password').value === this.formUpdate.get('LastPassword').value && this.formUpdate.get('Password').value !== '') {
      this.validatePasswordEquals = true;
      x = true;
    } else {
      this.validatePasswordEquals = false;
      x = false;
    }
    return x;
  }

  update(){
    let model: UpdatePassword =
      {
        CurrentPassword: this.formUpdate.get('LastPassword').value,
        Password: this.formUpdate.get('Password').value,
        ConfirmPassword: this.formUpdate.get('ConfirmPassword').value,
      }

    this.userService.postUpdatePasswordUser(model).subscribe(restUpdate=>{
      this.dialogRef.close(true);
      this.snackBService.openSnackBar('Contraseña cambiada exitosamente.', 'X', 4000);
    }, (error: HttpErrorResponse) => {
      if(error.status === 400){
        this.formUpdate.get('LastPassword').setErrors({'incorrect':true});
      }else if(error.status === 200){
        this.dialogRef.close(true);
        this.snackBService.openSnackBar('Contraseña cambiada exitosamente.', 'X', 4000);
      }
    });
  }

  updateSuperUser() {
    let model: UpdatePassword =
              {
                ...this.userChangeData,
                Password: this.formUpdate.get('Password').value,
                ConfirmPassword: this.formUpdate.get('ConfirmPassword').value
              }
    this.userService.putUserWithoutPersonal(this.userChangeData.Id, model).subscribe(restUpdate=>{
      this.dialogRef.close(true);
      this.snackBService.openSnackBar('Contraseña cambiada exitosamente.', 'X', 4000);
    }, (error: HttpErrorResponse) => {
      if(error.status === 400){
        this.formUpdate.get('LastPassword').setErrors({'incorrect':true});
      }else if(error.status === 200){
        this.dialogRef.close(true);
        this.snackBService.openSnackBar('Contraseña cambiada exitosamente.', 'X', 4000);
      }
    });
  }

  updateError432(){
    let model: UpdatePassword =
      {
        token: this.data.UpdatePasswordAnonymous.token,
        CurrentPassword: this.formUpdate.get('LastPassword').value,
        Password: this.formUpdate.get('Password').value,
        ConfirmPassword: this.formUpdate.get('ConfirmPassword').value,
      }
    this.userService.postUpdatePasswordAnonymous(model).subscribe(restUpdate=>{
      this.dialogRef.close(true);
      this.snackBService.openSnackBar('Contraseña cambiada exitosamente.', 'X', 4000);
    }, (error: HttpErrorResponse) => {
      if(error.status === 400){
        this.formUpdate.get('LastPassword').setErrors({'incorrect':true});
        this.formUpdate.get('Password').setErrors({'incorrect':true});
        this.snackBService.openSnackBar(error?.error?.PasswordSequence, 'X', 4000);
      }else if(error.status === 200){
        this.dialogRef.close(true);
        this.snackBService.openSnackBar('Contraseña cambiada exitosamente.', 'X', 4000);
      }
    });
  }

}
