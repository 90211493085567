import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PersonalModel } from '../../models/Personal/personalModel';
import { IndividualContract } from '../../models/IndividualContract/individualContract';
import { IndividualContractId } from '../../models/IndividualContract/individualContractId';
import { DocumentI, DocumentIndividualPost, getDocumentIndividualContract } from '../../models/IndividualContract/document';
import { Back, FirePersonal, GetPersonalBlackList, PersonalBlackList } from '../../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ContratingService {

    constructor(
        private api: ApiService,
        private _http: HttpClient
    ) { }

    getIndividualContracts(): Observable<IndividualContractId[]> {
        return this._http.get<IndividualContractId[]>(this.api.getUrl(`api/IndividualContract`));
    }

     //GET personalDocuemnt ID
    getPersonalDocument(typeDocument: string, document: string){
        return this._http.get(this.api.getUrl(`api/Personal/ByDocument?documentType=${typeDocument}&documentNumber=${document}`));
    }

    getManagedEmployers() {
        return this._http.get(this.api.getUrl(`api/Company/ManagedEmployers`));
    }

    postPersonal(personal): Observable<PersonalModel> {
        return this._http.post<PersonalModel>(this.api.getUrl(`api/Personal`), personal);
    }

    postPersonalEmployerInfo(PersonalEmployerInfo) {
        return this._http.post(this.api.getUrl(`api/PersonalEmployerInfo`), PersonalEmployerInfo);
    }

    putPersonalEmployerInfo(Id, PersonalEmployerInfo) {
        return this._http.put(this.api.getUrl(`api/PersonalEmployerInfo/${Id}`), PersonalEmployerInfo);
    }

    // update photo Personal
    updatePhotoPersonal(id, file) {
        return this._http.put(this.api.getUrl('api/PersonalEmployerInfo/UpdatePhoto/'+id), file);
    }

    postIndividualContract(individualContract): Observable<IndividualContract> {
        return this._http.post<IndividualContract>(this.api.getUrl(`api/IndividualContract`), individualContract);
    }

    postIndividualContractPersonal(individualContract): Observable<IndividualContract> {
        return this._http.post<IndividualContract>(this.api.getUrl(`api/IndividualContract/Personal`), individualContract);
    }

    putIndividualContractPersonalId(individualContractId, model): Observable<IndividualContract> {
        return this._http.put<IndividualContract>(this.api.getUrl(`api/IndividualContract/Personal/${individualContractId}`), model);
    }


    deleteIndividualContract(individualContractId) {
        return this._http.delete(this.api.getUrl(`api/IndividualContract/${individualContractId}`));
    }

    getIndividualContractId(Id): Observable<IndividualContractId> {
        return this._http.get<IndividualContractId>(this.api.getUrl(`api/IndividualContract/${Id}`));
    }

    getPersonalId(Id): Observable<PersonalModel> {
        return this._http.get<PersonalModel>(this.api.getUrl(`api/Person/${Id}`));
    }

    getDocument(): Observable<DocumentI[]> {
        return this._http.get<DocumentI[]>(this.api.getUrl(`api/Document`));
    }


    getProyectSelect() {
        return this._http.get(this.api.getUrl(`api/Project/Select`));
    }

    getContributorType() {
        return this._http.get(this.api.getUrl(`api/SocialSecurityDocument/ContributorType`));
    }

    getDocuemntsIndividualContract(IdIndividualContract): Observable<getDocumentIndividualContract[]> {
        return this._http.get<getDocumentIndividualContract[]>(this.api.getUrl(`api/Document/IndividualContract/${IdIndividualContract}`));
    }

    postDocumentDataIndividual(documents: DocumentIndividualPost) {
        return this._http.post(this.api.getUrl(`api/Document/IndividualContract`), documents);
    }

    putDocumentFileIndividual(Id, FileData) {
        return this._http.put(this.api.getUrl(`api/Document/IndividualContract/Upload/`+Id), FileData);
    }

    putIndividualContract(Id, individualContract) {
        return this._http.put(this.api.getUrl(`api/IndividualContract/`+Id), individualContract);
    }

    getPersonalEmployerInfoExit(EmployerId, PersonalId){
        return this._http.get(this.api.getUrl(`api/PersonalEmployerInfo/EmployerPersonal?personalId=${PersonalId}&employerId=${EmployerId}`));
    }

    getPersonalEmployerInfoPersonalExit(EmployerId, PersonalId){
        return this._http.get(this.api.getUrl(`api/PersonalEmployerInfo/Personal/EmployerPersonal?personalId=${PersonalId}&employerId=${EmployerId}`));
    }

    postPersonalEmployerInfoPersonal(model){
        return this._http.post(this.api.getUrl(`api/PersonalEmployerInfo/Personal`), model);
    }

    putPersonalEmployerInfoPersonal(model, id){
        return this._http.put(this.api.getUrl(`api/PersonalEmployerInfo/Personal/${id}`), model);
    }

    //Apis Generate Solicitud
    postGenerateDocumentDatosContrato(Id) {
        return this._http.post(this.api.getUrl(`api/Document/Individualcontract/RequestHiringDocument/`+Id), Id);
    }

    postGenerateDocumentDatosContratoAproved(Id) {
        return this._http.post(this.api.getUrl(`api/Document/IndividualContract/RequestAprovedHiringDocument/`+Id), Id);
    }

    postGenerateDocumentIndividualContractRechazada(Id) {
        return this._http.post(this.api.getUrl(`api/Document/IndividualContract/RejectHiringRequestDocument/`+Id), Id);
    }
    //Apis Generate Solicitud Fin

    //Documentos generate Contrato
    postGenerateProjectContract(Id) {
        return this._http.post(this.api.getUrl(`api/Document/IndividualContract/GenerateProjectContract/`+Id), Id);
    }

    postGenerateDocumentMedicalAdviceAcknowledgement(Id) {
        return this._http.post(this.api.getUrl(`api/Document/IndividualContract/MedicalAdviceAcknowledgement/`+Id), Id);
    }

    postGenerateDocumentDataAuthorization(Id) {
        return this._http.post(this.api.getUrl(`api/Document/IndividualContract/DataAuthorization/`+Id), Id);
    }

    postGenerateDocumentAuthMedicalExam(Id) {
        return this._http.post(this.api.getUrl(`api/Document/IndividualContract/AuthMedicalExam/`+Id), Id);
    }
    //Documentos generate Contrato FIN

    getTownIdCity(CodeCity) {
        return this._http.get(this.api.getUrl(`api/Town/City/${CodeCity}`));
    }

    postGenerateDocumentExamenMedico(Id, examType) {
        return this._http.post(this.api.getUrl(`api/Document/IndividualContract/GenerateMedicalExamRequest/${Id}?examType=${examType}`), Id+examType);
    }

    getBanks(): Observable<Back[]>{
        return this._http.get<Back[]>(this.api.getUrl(`api/Bank`));
    }

    changeStateIndividualContract(model){
        return this._http.post(this.api.getUrl(`api/IndividualContract/AddStage`), model);
    }

    AuthMedicalExam(stateModel){
        return this._http.post(this.api.getUrl(`api/IndividualContract/AddStage`), stateModel);
    }

    getDocumentoGenerado(IdIndividualContract, abrv){
        return this._http.get<Back[]>(this.api.getUrl(`api/Document/IndividualContract/ByAbrv?individualContract=${IdIndividualContract}&abrv=${abrv}`));
    }
    // api/Document/IndividualContract?individualContract=5b9e7990-6974-4de2-9e29-b98b0b161bd5&abrv=SOLING
    // api/Document/IndividualContract/ByAbrv?individualContract=233d397a-bfd6-4204-9abb-92465ccb02fe&abrv=SOLING
    // api/Document/AuthMedicalExam/233d397a-bfd6-4204-9abb-92465ccb02fe

    getPersonalBlackList(DocumentType, DocumentNumber, CompanyInfoId): Observable<boolean>{
        return this._http.get<boolean>(this.api.getUrl(`api/PersonalBlackList/ByDoc?DocumentType=${DocumentType}&DocumentNumber=${DocumentNumber}${CompanyInfoId === ''? '': '&adminInfoId='+CompanyInfoId}`));
    }

    getPersonalBlackListInfo(DocumentType, DocumentNumber, CompanyInfoId): Observable<boolean>{
        return this._http.get<boolean>(this.api.getUrl(`api/PersonalBlackList/bydocinfo?DocumentType=${DocumentType}&DocumentNumber=${DocumentNumber}${CompanyInfoId === ''? '': '&adminInfoId='+CompanyInfoId}`));
    }

    getIndividualContractPersonalInforId(personalemployerinfoid) {
        return this._http.get(this.api.getUrl(`api/IndividualContract/GetByPersonalEnployerInfo/${personalemployerinfoid}`));
    }

    getIndividualContractPersonal(DocumentType, DocumentNumber): Observable<any[]>{
        return this._http.get<any[]>(this.api.getUrl(`api/IndividualContract/ByDocument?documentType=${DocumentType}&documentNumber=${DocumentNumber}`));
    }

    postPersonalBlackList(personalBlack: PersonalBlackList) {
        return this._http.post(this.api.getUrl(`api/PersonalBlackList`), personalBlack);
    }

    /**
     * Permite  desvincular el personal de todos los contratos
    */
    postFirePersonal(firePersonal : FirePersonal) {
        return this._http.put(this.api.getUrl(`api/Contract/FirePersonal`), firePersonal);
    }

    getAllPersonalBlackList(): Observable<GetPersonalBlackList[]> {
        return this._http.get<GetPersonalBlackList[]>(this.api.getUrl('api/PersonalBlackList'));
    }

    deletePersonalBlackList(personalBlackListId) {
        return this._http.delete(this.api.getUrl(`api/PersonalBlackList/${personalBlackListId}`));
    }

    getListSelectContracting(CompanyId) {
        return this._http.get(this.api.getUrl(`api/contract/selectcontracting/${CompanyId}`));
    }

    deleteDocumentId(Id) {
        return this._http.delete(this.api.getUrl(`api/Document/IndividualContract/${Id}`));
    }

    deleteOrdenData(IdIndividualContract) {
        return this._http.patch(this.api.getUrl(`api/IndividualContract/resetIndividualContract/${IdIndividualContract}`), IdIndividualContract);
    }








}
