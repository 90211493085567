import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title-card-preview',
  templateUrl: './title-card-preview.component.html',
  styleUrls: ['./title-card-preview.component.scss']
})
export class TitleCardPreviewComponent implements OnInit {

  @Input() icon: string;
  @Input() text: string;
  @Input() subText: string;
  @Input() textTooltip: string;
  @Input() isInfo:boolean = true;
  @Input() isAuthorized: boolean;
  
  constructor() { }

  ngOnInit(): void {
  }

  validateStyle():string{
    var contentStyle = ""

    if(this.isInfo == true){
      contentStyle = "contentCard"
    }else{
      if(this.isAuthorized){
        contentStyle = "contentCardAuthorized"
      }else{
        contentStyle = "contentCardUnauthorized"
      }
    }

    return contentStyle
  }

}
