<div class="filters" [ngClass]="production === false ? 'color-bg-pruebas-paginator': 'color-bg-pro-paginator'">
    <button class="ripple" 
           *ngFor="let filter of filterList; let i = index"
           [ngClass]="selectedItem === i ? 'activeBtn':'inactive'"
           (click)="onPropagar(filter.Value, i, filter.Id);"
           >
            <h5 *ngIf="filter.count === null && filter.icon !== ''">
                <mat-icon>{{filter.icon !== ''? filter.icon : 'label' }}</mat-icon>
                {{filter.Name}}
            </h5>
            <h5 *ngIf="filter.count === null && filter.icon === ''">
                {{filter.Name}}
            </h5>
            <h5 *ngIf="filter.count !== null"
                matBadge="{{filter.count || 0}}"
                matBadgeColor="warn"
                matBadgeSize="small">
                {{filter.Name}}
            </h5>
    </button>
   
</div>