import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { OptionsCardSelect } from 'src/app/core/interfaces/interfaces';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';

export interface DialogData {
  optionsCardSelect: OptionsCardSelect, title: string, data: any[], disableClose: boolean, btnClose: boolean, btnConfirm: boolean, textClose: string, textConfirm: string
}

@Component({
  selector: 'app-dialog-selected-causal',
  templateUrl: './dialog-selected-causal.component.html',
  styleUrls: ['./dialog-selected-causal.component.scss']
})
export class DialogSelectedCausalComponent implements OnInit {
  controlSelected = new FormControl();
  datasource: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogSelectedCausalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBService: SnackBarService,
  ) {
    if(data.data !== null && data.data.length !== 0){
      this.datasource =  this.data.data;
      this.controlSelected.setValue(this.datasource.map(x=>x.Id)[0]);
    }

   }

  ngOnInit(): void {

   }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    if(this.controlSelected.value !== '' && this.controlSelected.value !== null) {
      this.dialogRef.close(this.controlSelected.value);
    } else {
      this.snackBService.openSnackBar('Debes seleccionar alguna opción para continuar.', 'X', 4000);
    }
  }

  selected(e) {
//    this.dialogRef.close(e);
  }

}
