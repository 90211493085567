<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'VINCULACIÓN A CONTRATOS' || 'N/A'}}</div>
    </div>
    <div class="content-card " >
      <div class="scrollable-content">
        <!-- proyecto -->
        <div>
          <div class="sub-header" >
            <div class="sub-content greendAnalityco">{{'EMPRESAS' || 'N/A'}}</div>
          </div>
        </div>

        <br>
        <div class="fields-group">
            <app-select-search class="select-component"
                        [appearance]="'outline'"
                        [_dataSource]="employers" [textInput]="'EMPRESAS'" [inputType]="'text'"
                        [dataShow]="'Name'" [dataValue]="'Id'" [otherFilter]="'DocumentNumber'" [siteCtrl]="controlSearchEmployer"
                        (onSelectionChange)="onSelectedAllEmployer($event)"
                        [disabled]="progressQuery === true"
                        >
            </app-select-search>
        </div>
        <div class="sub-header" >
            <div class="sub-content greendAnalityco">{{'CONTRATOS' || 'N/A'}}</div>
        </div>
        <br>
        <div class="listContractSelected" *ngIf="contractsSelected.length !== 0">
            <div class="chip" *ngFor="let item of contractsSelected">
                 <div class="text-chip" matTooltip="{{item.ProjectStageContracts[0].ProjectName}},{{item.ContractNumber}}{{item.ContractReview}}"
                    [matTooltipPosition]="'right'">{{item.ProjectStageContracts[0].ProjectName}}, {{item.ContractNumber}}, {{item.ContractReview}}</div>
                <mat-icon style="cursor: pointer;" (click)="onRemoveSelectedContract(item.Id)" *ngIf="progressQuery !== true">cancel</mat-icon>
            </div>
        </div>
        <div class="fields-group">
            <button mat-button
                    *ngIf="employerSelected.length !== 0 && contracts.length !== 0"
                    (click)="openDialogSelectedVincularContracts()"
                    class="btn-seleccionar">SELECCIONAR CONTRATOS
            </button>
            <div *ngIf="contractsCopy.length === 0 && employerSelected.length !== 0">La empresa seleccionada no tiene contratos activos</div>
        </div>
        <div class="sub-header" *ngIf="contractsSelected.length !== 0">
            <div class="sub-content greendAnalityco" style="width: 220px !important;">{{'FUNCIÓN Y FECHAS DE ACCESO' || 'N/A'}}</div>
        </div>
        <div class="fields-group" *ngIf="contractsSelected.length !== 0 && progressQuery !== true">
            <div class="labeTimePiecker">
                <div class="classLabel" >Inicio de acceso</div>
                <div class="input-field-medium-date-datetime" matTooltip="INICIO DEL ACCESO">
                    <mat-form-field appearance="outline">
                        <input matInput type="datetime-local"
                            style="max-width: 175px !important;"
                            #inputDateStartDateContracttime
                            [formControl]="controlStartDate"
                            [min]="minDate"
                            (change)="changeDateFinishDateContractTime(inputDateStartDateContracttime?.value, false)"
                            >
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="fields-group" *ngIf="contractsSelected.length !== 0 && progressQuery !== true">
            <div class="labeTimePiecker">
                <div class="classLabel">Fin de acceso</div>
                <div class="input-field-medium-date-datetime" matTooltip="FIN DEL ACCESO">
                    <mat-form-field appearance="outline">
                        <input matInput type="datetime-local"
                            style="max-width: 175px !important;"
                            #inputDateFinishDateContracttime
                            [formControl]="controlFinisthDate"
                            [min]="minDate"
                            (change)="changeDateFinishDateContractTime(inputDateFinishDateContracttime?.value, true)"
                            >
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="fields-group" *ngIf="contractsSelected.length !== 0 && progressQuery !== true">
            <div class="labeTimePiecker">
                <div class="classLabel">Función</div>
                <div class="input-field-medium-date-datetime" matTooltip="Función en el contrato" style="width: 189px !important;">
                    <mat-form-field class="input-field-search-list" *ngIf="contractsSelected.length !== 0" appearance="outline" appearance="outline">
                        <input matInput
                            type="text"
                            [formControl]="controlPosition"
                            #inputPosition
                            (change)="changeinputPositionContract(inputPosition.value)"
                            [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOptionItem #auto="matAutocomplete">
                        <mat-option *ngFor="let option of positionSujester" [value]="option">
                            {{option || ''}}
                        </mat-option>
                        </mat-autocomplete>
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="sub-header" *ngIf="contractsSelected.length !== 0">
            <div class="sub-content greendAnalityco">{{'EMPLEADOR' || 'N/A'}}</div>
        </div>
        <br>
        <div *ngIf="contractsSelected.length !== 0">
            <div class="card" *ngFor="let employer of employerSelected">
                <div class="card-option">
                    <img src="{{s3Bucket+getImg(employer.Logo)}}" alt="">
                    <div class="card-content">
                        <div class="card-items">
                            <h3 class="title">{{employer.Name || ''}} {{employer.LastName || ''}}</h3>
                            <div class="field">{{employer.DocumentType}}: {{employer.DocumentNumber || ''}}</div>
                            <div class="field">{{employer.Address || ''}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-form-field class="input-field input-field-search" appearance="outline" *ngIf="contractsSelected.length !== 0 && progressQuery !== true">
            <input matInput type="text" [formControl]="controlSearchEmpleador" placeholder="Cambiar el empleador buscando aquí">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="getSearchEmployerIdCompanyInfoId()">
                <mat-icon class="icon-search">search</mat-icon>
            </button>
        </mat-form-field>
        <div class="actions-dialog"style="margin: 3px;" *ngIf="progressQuery !== true">
            <button mat-button class="btn-cancelar" (click)="close()">CANCELAR</button>&nbsp;&nbsp;
            <button mat-button
                    *ngIf="validFormContract() === true"
                    (click)="postNewPersonalInfoContract()"
                    class="btn-generar">GUARDAR
            </button>
        </div>
        <h4 *ngIf="progressQuery === true">TERMINANDO PROCESO DE VINCULACIÓN</h4>
        <mat-progress-bar mode="query" [value]="20" *ngIf="progressQuery === true"></mat-progress-bar>
      </div>
    </div>
</div>
