import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import {  FilterList } from 'src/app/core/interfaces/interfaces';
import { User } from 'src/app/core/models/User';
import { AuthGuardService } from 'src/app/core/services/guards/AuthGuardService';
import { PersonalService } from 'src/app/core/services/modules/personal.service';
import { FilterMultipleService } from 'src/app/core/services/utils/filter-multiple.service';
import { environment } from 'src/environments/environment';

export interface DialogData {
  dataList: any;
  path: string;
}

@Component({
  selector: 'app-contract-dialog-list',
  templateUrl: './dialog-contract-list.component.html',
  styleUrls: ['./dialog-contract-list.component.scss']
})
export class DialogContractListComponent implements OnInit {
  production:boolean = environment.production;
  
  list: any= [];
  listSlice: any= [];
  filterListValue: string = "";

  @ViewChild('paginatorList', {read: MatPaginator}) paginatorList: MatPaginator;

  filterList: FilterList[] = [
    { Value: '', Name: 'Todos', icon: '', count: null},
    { Value: 'IsActive', Name: 'Activos', icon: '', count: null},
    { Value: 'IsInactive', Name: 'Inactivos', icon: '', count: null}
  ];

  constructor(
    public dialogRef: MatDialogRef<DialogContractListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    private filters: FilterMultipleService,
  ) { 
      this.list = data.dataList;
      this.arreglo = data.dataList;
      this.listSlice = this.list.slice(0,32);
  }

  ngOnInit(): void {
    
  }

  getImg(logo) {
    let img = (logo === null ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg': logo); 
    return img;
  }

  personalId(Id) {
    this.router.navigate([this.data.path, Id]);
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  filterValue: string = '';
  searchFilter(filterValue) {
    this.filterValue = filterValue.Value;
    this.searchFilterPagination(this.filterListValue);
  }

  arreglo: any[] = [];
  searchFilterPagination(changeFilter: string){ 
    const startIndex = this.paginatorList.pageIndex * this.paginatorList.pageSize;
    let endIndex = startIndex + this.paginatorList.pageSize;
    // console.log('filterValue1 :>> ', this.filterValue);
    if(this.filterListValue === '' && changeFilter === '' && this.filterValue === '' ) {
      // console.log('this.filterValue2 :>> ', this.filterValue);
      this.arreglo = this.list;
    }else { 
      // console.log('this.filterValue3 :>> ', this.filterValue);
      this.filterListValue = changeFilter;
      this.arreglo = this.getPersonalFilter(this.filterListValue, this.filterValue);
    }

    if(endIndex > this.arreglo.length) {
      endIndex = this.arreglo.length;
    }
    this.listSlice = this.arreglo.slice(startIndex, endIndex);
  }

  getPersonalFilter(value: string, filter?: string): any[] {
    if(value === '' && (filter === '' || filter === undefined)) {
        return this.list;
    } else if(filter === 'IsActive') {
        return this.list.filter(x => {
            return (this.filters.exitFilter(x.ContractReview, value) ||
                    this.filters.exitFilter(x.ContractNumber, value)) && 
                    (x.IsActive === true);
        });
    } else if(filter === 'IsInactive') {
        return this.list.filter(x => {
            return (this.filters.exitFilter(x.ContractReview, value) ||
                    this.filters.exitFilter(x.ContractNumber, value)) && 
                    (x.IsActive === false);
        });
    } else  {
      return this.list.filter(x => {
        return (this.filters.exitFilter(x.ContractReview, value) ||
                this.filters.exitFilter(x.ContractNumber, value));
      });
    } 
  }

}
