<div class="box">
    <div class="header">
        <div class="content" [ngClass]="'greendAnalityco' || 'primary'">{{'ORDEN DE INGRESO' || 'N/A'}}</div>
    </div>
    <div class="content-card list-card" >
        <div class="sub-header">
            <div class="sub-content greendAnalityco">{{'EMPLEADOR O CONTRATANTE' || 'N/A'}}</div>
        </div>
        <div class="card" *ngFor="let employer of employerSelected">
            <div class="card-content">
                <img src="{{s3Bucket+getImg(employer.Logo)}}" alt="">
                <div class="content-information">
                    <h3 class="title" style="max-width: 200px;">{{employer.Name || ''}}</h3>
                    <div class="ellipsis baseline">{{employer.DocumentType}}: {{employer.DocumentNumber || ''}}</div>
                    <!-- <div class="ellipsis selected">{{'EMPLEADOR SELECCIONADO' || ''}}</div> -->
                </div>
            </div>
            <mat-icon class="icon-close" (click)="setValue()">close</mat-icon>
        </div>
        <app-select-search class="select-component" *ngIf="employerSelected.length !== 1"
                        [_dataSource]="employers" [textInput]="'Buscar empresa'" [inputType]="'text'" 
                        [dataShow]="'Name'" [dataValue]="'CompanyId'" [otherFilter]="'DocumentNumber'" [siteCtrl]="controlEmployer" 
                        [isCard]="true"
                        [card]="{img: 'Logo', title: 'Name', itemKey: 'DocumentType', itemValue:'DocumentNumber'}"
                        (onSelectionChange)="valueSelected($event)">
        </app-select-search>
        <div class="sub-header">
            <div class="sub-content greendAnalityco">{{'EMPLEADO O CONTRATADO' || 'N/A'}}</div>
        </div>
        <div class="card" *ngFor="let personal of personalSelected">
            <div class="card-content">
                <img src="{{s3Bucket+getImg(personal.Logo)}}" alt="">
                <div class="content-information">
                    <h3 class="title" style="max-width: 200px;">{{personal.Name || ''}} {{personal.LastName || ''}}</h3>
                    <div class="ellipsis baseline">{{personal.DocumentType}}: {{personal.DocumentNumber || ''}}</div>
                    <!-- <div class="ellipsis selected">{{'PERSONA SELECCIONADO' || ''}}</div> -->
                </div>
            </div>
            <mat-icon class="icon-close" (click)="setValuePersonal()">close</mat-icon>
        </div>
        <div class="fields-group" *ngIf="employerSelected.length === 1 && personalSelected.length !==1">
            <mat-form-field class="input-field-short">
                <label><strong>TIPO</strong></label>
                <mat-select [(ngModel)]="documentType" (selectionChange)="changeType($event.value)">
                    <mat-option *ngFor="let data of companyDocumentType" [value]="data.Value">
                        {{data.Value === 'CC' ? 'Cédula': (data.Value === 'PE' ? 'Permiso especial de permanecía': 'Cedula de extranjeria')}}
                    </mat-option>
                </mat-select>
            </mat-form-field> 
            <mat-form-field class="input-field-50">
                <label><strong>NÚMERO</strong></label>
                <input matInput type="text" [(ngModel)]="documentNumber" pattern="/^-?\d+\.?\d*$/" maxlength="{{maxCaracter}}">
                <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="setValuePersonal()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <div class="btn-save" (click)="getPersonalDocument()">
                <button mat-mini-fab color="primary" matTooltip="buscar" >
                  <mat-icon>search</mat-icon>
                </button>
            </div>
        </div>
        <div class="sub-header">
            <div class="sub-content greendAnalityco">{{'LUGAR DE TRABAJO' || 'N/A'}}</div>
        </div>
        <div class="card" *ngFor="let contract of contractsSelected">
            <div class="card-content">
                <img src="{{s3Bucket+getImg(contract.Logo)}}" alt="">
                <div class="content-information">
                    <h3 class="h3" style="max-width: 200px;">{{contract.Name || ''}}</h3>
                    <h3 class="ellipsis baseline">{{contract.ContractReview}}</h3>
                    <div class="final-items ellipsis">
                        <div>{{contract.ContractNumber}}</div>
                        <div>{{contract.FinishDate | date: 'dd-MM-yyyy'}}</div>
                    </div>
                    <!-- <div class="ellipsis selected">{{'EMPLEADOR SELECCIONADO' || ''}}</div> -->
                </div>
            </div>
            <mat-icon class="icon-close" (click)="setValueContract()">close</mat-icon>
        </div>
        <app-select-search class="select-component" *ngIf="personalSelected.length === 1 && contractsSelected.length !== 1"
                        [_dataSource]="contracts" [textInput]="'LUGAR DE TRABAJO'" [inputType]="'text'" 
                        [dataShow]="'Name'" [dataValue]="'ContractId'" [otherFilter]="'FullName'" [siteCtrl]="controlContractSelected" 
                        [isCard]="true"
                        [card]="{img: 'Logo', title: 'Name', subTitle: 'ContractReview', finalLeft: 'ContractNumber', finalRight: 'FinishDate'}"
                        (onSelectionChange)="onSelectionChangeContract($event)">
        </app-select-search> 
        <div class="actions-dialog"style="margin: 3px; margin-top: 10px">
            <button mat-button class="btn-cancelar" [mat-dialog-close]="data" >CANCELAR</button>&nbsp;&nbsp;
            <button mat-button 
                    (click)="startSolicitud()"
                    [ngClass]="getDisabled() === true ? 'btn-generar':'btn-disabled'"
                    [disabled]="!getDisabled()">INICIAR SOLICITUD
            </button>
            <!-- <button (click)="_getContractNumber()">Hola</button> -->
        </div>
    </div>
    
</div>