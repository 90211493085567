<div *ngIf="data.typeDialog === 'cargarFilePoliticasPrivacidad'" class="content-dialog">
  <h3>{{'CARGAR POLITICAS DE PRIVACIDAD' || 'Sin data' | uppercase}}</h3>
  <div class="estilo" matTooltip="AGREGAR ARCHIVO" matTooltipPosition="above">
      <div class="p_inputFile">
          <div for="">CARGAR ARCHIVO</div>
          <div *ngIf="_filePoliticas" class="nameFile">{{_filePoliticas.name || ''}}</div>
          <!-- <div *ngIf="!_file" class="nameFileRequired">REQUERIDO</div> -->
      </div>
      <input class="file" #fileInputPoliticas type="file"
            (change)="changeFilePiliticas(fileInputPoliticas.files)" />
  </div>
  <div class="actions-dialog">
    <button mat-button class="btn-cancelar" [mat-dialog-close]="data" >CANCELAR</button>&nbsp;&nbsp;
    <button mat-button class="btn-generar" (click)="updateFilePoliticas()" >GUARDAR</button>
  </div>
</div>


<div *ngIf="data.typeDialog === 'showVideoImage'" class="content-dialog">
    <h3>{{'CONFIGURAR IMAGEN O VIDEO AL INICIO' || 'Sin data' | uppercase}}</h3>
    <div class="active-togle"><div>{{'Activar video o imagen'}}</div><mat-slide-toggle [(ngModel)]="EnableAdStart" name="EnableAdStart"></mat-slide-toggle></div>
    <h4>{{'Cargar video o imagen'}}</h4>
      <div class="estilo" matTooltip="AGREGAR ARCHIVO" matTooltipPosition="above">
        <div class="p_inputFile">
            <div for="">Agregar</div>
            <div *ngIf="_file" class="nameFile">{{_file.name || ''}}</div>
            <!-- <div *ngIf="!_file" class="nameFileRequired">REQUERIDO</div> -->
        </div>
        <input class="file" #fileInput type="file"
              (change)="changeFile(fileInput.files, $event)" />
      </div>
    <div class="actions-dialog">
      <button mat-button class="btn-cancelar" [mat-dialog-close]="data" >CANCELAR</button>&nbsp;&nbsp;
      <button mat-button class="btn-generar" [mat-dialog-close]="data" (click)="saveShowVideo()" >GUARDAR</button>
    </div>
</div>


<div *ngIf="data.typeDialog === 'showLinkAndText'" class="content-dialog">
    <h3>{{'CONFIGURAR ANUNCIO Y LINK A FINAL' || 'Sin data' | uppercase}}</h3>
    <!-- <h4>{{'Escribir el texto del anuncio final'}}</h4>
    <input matInput type="text" [(ngModel)]="TextAdFinish" maxlength=50> -->

    <mat-form-field class="input-field">
        <label><strong>Escribir el texto del anuncio final</strong></label>
        <input matInput type="text" [(ngModel)]="TextAdFinish" maxlength=50>
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="TextAdFinish = ''">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>

    <mat-form-field class="input-field">
      <label><strong>Escribir el link del anuncio final</strong></label>
      <input matInput type="text" [(ngModel)]="LinkAdFinish">
      <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="LinkAdFinish = ''">
          <mat-icon>close</mat-icon>
      </button>
  </mat-form-field>

    <!-- <h4>{{'Escribir el link del anuncio final'}}</h4>
    <input matInput type="url" [(ngModel)]="LinkAdFinish"> -->

    <div class="active-togle"><div>{{'Activar anuncio final'}}</div><mat-slide-toggle [(ngModel)]="EnableAdFinish" name="EnableAdFinish"></mat-slide-toggle></div>
    <div class="actions-dialog">
      <button mat-button class="btn-cancelar" [mat-dialog-close]="data" >CANCELAR</button>&nbsp;&nbsp;
      <button mat-button class="btn-generar" (click)="saveLink()" >GUARDAR</button>
    </div>
</div>

<title-dialog *ngIf="data.typeDialog === 'cargarCondicionesServicio'" [title]="'CONDICIONES DE SERVICIO'" [color]="'greendAnalityco'"></title-dialog>
<div *ngIf="data.typeDialog === 'cargarCondicionesServicio'" class="content-dialog-condiciones">
  <div class="content-show">
    <form [formGroup]="formCompany">
    <mat-form-field class="input-field-condiciones">
        <label><strong>AREA MAX. DE GEOCERCA</strong></label>
        <input matInput type="text" formControlName="Geocerca" [maxLength]="18">
    </mat-form-field>
    <mat-form-field class="input-field-condiciones">
      <label><strong>ALMACENAMIENTO</strong></label>
      <input matInput [disabled]="true">
    </mat-form-field>
    <mat-form-field class="input-field-condiciones">
      <label><strong>USUARIOS MAXIMOS POR PROYECTO</strong></label>
      <input matInput [disabled]="true">
    </mat-form-field>
    <mat-form-field class="input-field-condiciones">
      <label><strong>CONSULTAS MAXIMAS DE PERSONAL POR MES</strong></label>
      <input matInput [disabled]="true">
    </mat-form-field>
  <div class="fields-group" style="margin-top: 6px; margin-bottom: 6px">
    <div class="input-field-medium-date">
        <mat-label>Inicio</mat-label>
        <mat-icon (click)="pickerStart.open()">today</mat-icon>
        <mat-form-field class="mat-field-condiciones" >
            <input matInput [matDatepicker]="pickerStart"
            formControlName="StartDate"
            [disabled]="true"
            (click)="pickerStart.open()">
            <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formVincularUser.get('StartDate').setValue('')">X</div> -->
            <mat-datepicker #pickerStart></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="input-field-medium-date">
        <mat-label>Fin</mat-label>
        <mat-icon (click)="pickerFinist.open()">today</mat-icon>
        <mat-form-field class="mat-field-condiciones" >
            <input matInput [matDatepicker]="pickerFinist"
            formControlName="FinishDate"
            (click)="pickerFinist.open()">
            <!-- <div class="btn-clear" mat-button matSuffix mat-icon-button (click)="formVincularUser.get('FinishDate').setValue('')">X</div> -->
            <mat-datepicker #pickerFinist></mat-datepicker>
            <mat-error>Requerido</mat-error>
        </mat-form-field>
    </div>
</div>
  <mat-slide-toggle style="padding: 8px 16px;"
    formControlName="EnableSurveySymptoms">
  </mat-slide-toggle>
  <mat-label>Servicio de reporte de Sintomas</mat-label>
</form>
</div>

    <div class="actions-dialog">
      <button mat-button class="btn-cancelar" (click)="onNoClick()" >CANCELAR</button>&nbsp;&nbsp;
      <button mat-button class="btn-generar" (click)="replaceSave()">GUARDAR</button>
    </div>
</div>

